import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import { BASE_URL } from '../../services/constant';
import { getLSItem } from '../../services/auth/encryptLogin';
import { COMMON_STRING } from '../../services/strings';
import PencileIcon from '../Icon/PencileIcon';

FileInput.propTypes = {
    accept: PropTypes.oneOf(['.png, .jpg, .jpeg', '.xslx', '.csv']).isRequired,
    maxCount: PropTypes.number,
    onChange: PropTypes.func,
    value: PropTypes.array,
    filelist: PropTypes.any,
    setfileList: PropTypes.any
};

function FileInput({ accept, filelist, setfileList, value, maxCount, onChange }) {
    const accessToken = getLSItem("pdollar-token");
    const fileInputProps = {
        name: 'file',
        headers: {
            authorization: `Bearer ${accessToken}`,
        },
        action: `${BASE_URL}upload-image`,
        fileList: filelist
    };

    const handleUpload = ({ fileList }) => {
        setfileList(fileList);
        const ids = fileList.map(obj => {
            if (obj.id) {
                return obj.id;
            }
            else if (obj.status === 'done') {
                return obj.response?.data?.media?.id;
            }
        });
        onChange(ids);
    };

    const beforeUpload = file => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            console.error('You can only upload JPG/PNG file!');
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            console.error('Image must smaller than 2MB!');
            return false;
        }
        return true;
    };

    const uploadButton = (
        <div className='uploadImageBtn'>
            <div><PencileIcon /></div>
            <span className='noFileText'>{COMMON_STRING.NO_FILE_CHOOSEN}</span>
        </div>
    );

    const alterBussinessImage = (data) => {
        onChange(value.filter(e => e !== data.id));
    }

    return (
        <div className="imagePickerDiv">
            <Upload
                name="businessProfiles"
                accept={accept}
                listType="picture"
                className="imageUploadInput"
                showUploadList={true}
                beforeUpload={beforeUpload}
                onChange={handleUpload}
                multiple={true}
                maxCount={maxCount ? maxCount : 1}
                onRemove={alterBussinessImage}
                {...fileInputProps}>
                {filelist.length >= (maxCount ? maxCount : 1) ? null : uploadButton}
            </Upload>
        </div>
    );
}

export default FileInput;