 import React from 'react';

const UnBlockIcon = (props) => {
    return (
        <div>   
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.22183 19.7782C5.24327 20.7996 6.4559 21.6099 7.79048 22.1627C9.12506 22.7155 10.5555 23 12 23C13.4445 23 14.8749 22.7155 16.2095 22.1627C17.5441 21.6099 18.7567 20.7996 19.7782 19.7782C20.7996 18.7567 21.6099 17.5441 22.1627 16.2095C22.7155 14.8749 23 13.4445 23 12C23 10.5555 22.7155 9.12506 22.1627 7.79048C21.6099 6.4559 20.7996 5.24327 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1C9.08262 1 6.28473 2.15893 4.22183 4.22183C2.15893 6.28473 1 9.08262 1 12C1 14.9174 2.15893 17.7153 4.22183 19.7782ZM6.87525 18.853C8.5231 20.0863 10.5603 20.6843 12.6133 20.5374C14.6663 20.3904 16.5976 19.5084 18.053 18.053C19.5084 16.5976 20.3904 14.6663 20.5374 12.6133C20.6843 10.5603 20.0863 8.5231 18.853 6.87525L15.4564 10.2718C15.2259 10.4944 14.9172 10.6176 14.5967 10.6148C14.2763 10.612 13.9697 10.4835 13.7431 10.2569C13.5165 10.0303 13.388 9.72375 13.3852 9.40329C13.3824 9.08282 13.5056 8.77409 13.7282 8.54358L17.1235 5.14704C15.4753 3.91702 13.4393 3.32168 11.388 3.46997C9.33681 3.61826 7.40758 4.50027 5.95343 5.95457C4.49928 7.40887 3.61747 9.33819 3.46939 11.3894C3.32131 13.4407 3.91686 15.4767 5.14704 17.1247L8.54358 13.7282C8.77409 13.5056 9.08282 13.3824 9.40329 13.3852C9.72375 13.388 10.0303 13.5165 10.2569 13.7431C10.4835 13.9697 10.612 14.2763 10.6148 14.5967C10.6176 14.9172 10.4944 15.2259 10.2718 15.4564L6.87525 18.853Z" fill="#324666"/>
        </svg> 
        </div>
    );
}

export default UnBlockIcon;
