import React from 'react';
import PropTypes from 'prop-types';
import RightNavIcon from '../Icon/RightNavIcon';

NewsCategoryList.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func
};

function NewsCategoryList({text, onClick}) {
    return (
        <div className='NewsCategoryListCard' onClick={onClick}>
            <span>{text}</span>
            <div className="navigateIcon"><RightNavIcon/></div>
        </div>
    );
}

export default NewsCategoryList;