import moment from 'moment';
import { methods, serviceMaker } from '../service';

const GET_PROPERTY_GOALS = (id) => serviceMaker(`property-goals`, methods.GET);

const CREATE_PROPERTY_GOALS = (payload) =>
  serviceMaker(`property-goals`, methods.POST, null, payload);

const PATCH_PROPERTY_GOALS = (id, payload) =>
  serviceMaker(`property-goals/${id}`, methods.PATCH, null, payload);

const PROPERTY_GOALS = {
  GET: GET_PROPERTY_GOALS,
  CREATE: CREATE_PROPERTY_GOALS,
  PATCH: PATCH_PROPERTY_GOALS
};

export { PROPERTY_GOALS };
