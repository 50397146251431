import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Switch, Tabs, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CalenderDarkIcon from '../../components/Icon/CalenderDarkIcon';
import PencileIcon from '../../components/Icon/PencileIcon';
import SelectArrow from '../../components/Icon/SelectArrow';
import { ADVERTISEMENT } from '../../services/Advertisement/Advertisement';
import { ADVERTISEMENTS_STRINGS, COMMON_ALERTS, COMMON_STRING, FILTER_STRING } from '../../services/strings';
import { getLSItem } from '../../services/auth/encryptLogin';
import { BASE_URL } from '../../services/constant';
import moment from 'moment';
import TabPane from 'antd/lib/tabs/TabPane';
import FormItem from 'antd/es/form/FormItem';

const AddEditAdvertisement = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const advetisementId = useLocation().state?.advetisementId;
    const [imageFileList, setImageFileList] = useState([]);
    const [excelFileList, setExcelFileList] = useState([]);
    const [AdTypeSelection, setAdTypeSelection] = useState('');
    const accessToken = getLSItem("pdollar-token");
    const [FilterStartDate, setFilterStartDate] = useState(null);
    const [FilterEndDate, setFilterEndDate] = useState(null);
    const [tabs, setTabs] = useState([]);
    const [SubTabs, setSubTabs] = useState([]);
    const [Subcategories, setSubcategories] = useState([]);
    const [currentTab, setCurrentTab] = useState('selectall');
    const [fileUploadUrl, setFileUploadUrl] = useState('upload-excel');

    function validateUrl(value) {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    }

    const uploadButton = (
        <div className='uploadImageBtn'>
            <div><PencileIcon /></div>
            <span className='noFileText'>{COMMON_STRING.NO_FILE_CHOOSEN}</span>
        </div>
    );

    const excelFileProps = (fileList, setFileList, accept = ".xlsx") => ({
        name: 'file',
        multiple: false,
        fileList,
        accept,
        action: BASE_URL+fileUploadUrl,
        headers: {
            authorization: accessToken,
        },
        beforeUpload(file) {
            if(file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                setFileUploadUrl('upload-excel');
            }
            else{
                setFileUploadUrl('upload-csv');
            }
            if (fileList.length > 1 || fileList.length === 1) {
                toast.warn(COMMON_ALERTS.FILE_EXISTS);
                return false;
            }
        },
        onChange(info) {
            if (info.fileList.length === 1) {
                const filename = info.fileList[0].name.split('.');
                if((filename.length > 0) && (filename[filename.length - 1] === 'xlsx' || filename[filename.length - 1] === 'csv')){
                    setFileList([...info.fileList]);
                }
                else toast.error('Invalid file name');
            }
            if (info.file.status === 'done') {
                if (info.file.response.status) {
                    form.setFieldValue('userFileId', info.file.response.data.media.id);
                }
                else {
                    setFileList([]);
                    toast.error(info.file.response.message);
                }
            } else if (info.file.status === 'error') {
                form.setFieldValue('userFileId', null);
                setFileList([]);
                toast.error(info?.file?.response?.message?? COMMON_ALERTS.FILE_UPLOAD_FAILED(info.file.name));
            }
        },
        async onRemove() {
            form.setFieldValue('userFileId', null);
            setFileList([]);
        },
    });

    const imageProps = {
        name: 'file',
        multiple: false,
        fileList: imageFileList,
        action: `${BASE_URL}upload-image`,
        headers: {
            authorization: accessToken,
        },
        beforeUpload() {
            if (imageFileList.length > 1 || imageFileList.length === 1) {
                toast.warn(COMMON_ALERTS.FILE_EXISTS);
                return false;
            }
        },
        onChange(info) {
            if (info.fileList.length === 1) {
                setImageFileList([...info.fileList]);
            }
            if (info.file.status === 'done') {
                if(info.file.response.data.media.id){
                    form.setFieldValue('mediaItemId', info.file.response.data.media.id);
                }
            } else if (info.file.status === 'error') {
                form.setFieldValue('mediaItemId', null);
                toast.error(COMMON_ALERTS.FILE_UPLOAD_FAILED(info.file.name));
            }
        },
        async onRemove() {
            form.setFieldValue('mediaItemId', null);
            setImageFileList([]);
        },
    };

    const changeAdType = (value) => {
        if(value === 'half' || value === 'full'){
            fetchTabs('banner');
        }
        else fetchTabs(value);
        setAdTypeSelection(value);
        form.validateFields();
        form.setFieldValue('tabId', null);
        form.setFieldValue('subtabId', null);
        form.setFieldValue('subcategoryId', null);
    }

    const onFinish = async (e) => {
        currentTab === 'upload'? Object.assign(e, {platform: []}): Object.assign(e, {userFileId: null});
        console.log(currentTab, e);
        const payload = {
            "title": e.title,
            "redirect_url": e.redirectUrl,
            "ad_type": e.adType,
            "isIOS": e.platform.includes('ios'),
            "isAndroid": e.platform.includes('android'),
            "isWeb": e.platform.includes('web'),
            "impression_count": e.impressionCount,
            "startDate": e.startDate,
            "endDate": e.endDate,
            "mediaItemId": e.mediaItemId,
            "userFileId": e.userFileId,
            "isActive": e.isActive,
            "status": e.isActive ? 'active' : 'inactive'
        };
        
        if(e.adType === 'full' || e.adType === 'half'){
            payload['subtabId'] = e?.subtabId;
            payload['subcategoryId'] = null;
        }
        else{
            payload['subcategoryId'] = e?.subcategoryId;
            payload['subtabId'] = null;
        }

        let res = null;
        let msg = '';

        if (advetisementId) {
            res = await ADVERTISEMENT.PATCH(advetisementId, payload);
            msg = COMMON_ALERTS.UPDATED_SUCCESS('Advertisement');
        }
        else {
            res = await ADVERTISEMENT.CREATE(payload);
            msg = COMMON_ALERTS.ADDED_SUCCESS('Advertisement');
        }

        if (res.status) {
            toast.success(msg);
            navigate('/advertisements');0
        }
        else {
            toast.error('Something got wrong.');
        }
    }

    const fetchTabs = async (adType = 'box') => {
        const tabs = await ADVERTISEMENT.TABS(adType);
        if (tabs.data && Array.isArray(tabs.data)) {
            setTabs(tabs.data);
        }
        form.setFieldValue('subtabId', null);
        form.setFieldValue('subcategoryId', null);
    }

    const fetchSubTabs = async (tabId) => {
        if (tabId) {
            const tabs = await ADVERTISEMENT.SUBTABS(tabId);
            if (tabs.data && Array.isArray(tabs.data)) {
                setSubTabs(tabs.data);
            }
        }
        setSubcategories([]);
        form.setFieldValue('subcategoryId', null);
    }

    const fetchSubCategories = async (subTabId) => {
        if (subTabId) {
            const tabs = await ADVERTISEMENT.SUBCATEGORIES(subTabId);
            if (tabs.data && Array.isArray(tabs.data)) {
                setSubcategories(tabs.data);
            }
        }
        else setSubcategories([]);
    }

    const fetchAdvertisement = async () => {
        const advertisement = await ADVERTISEMENT.FIND(advetisementId);
        if (advertisement.status) {
            const { data } = advertisement;
            const platforms = [];
            if (data.isAndroid) platforms.push('android');
            if (data.isIOS) platforms.push('ios');
            if (data.isWeb) platforms.push('web');
            if (data?.subcategory?.subtab?.tabId || data?.subtab?.tabId)
                await fetchSubTabs(data?.subcategory?.subtab?.tabId || data?.subtab?.tabId);
            if (data?.subcategory?.subtabId || data?.subtab)
                await fetchSubCategories(data?.subcategory?.subtabId || data?.subtabId)
            await fetchTabs(data?.ad_type === 'half' || data?.ad_type === 'full'? 'banner' : data?.ad_type );
            form.setFieldsValue({
                title: data?.title,
                redirectUrl: data?.redirect_url,
                adType: data?.ad_type,
                platform: platforms,
                impressionCount: data?.impression_count,
                startDate: data?.startDate? moment(data?.startDate) : null,
                endDate: data?.endDate? moment(data?.endDate) : null,
                isActive: data?.isActive,
                tabId: data?.subcategory?.subtab?.tabId || data?.subtab?.tabId,
                subtabId: data?.subcategory?.subtab?.id || data?.subtab?.id,
                subcategoryId: data?.subcategoryId,
                mediaItemId: data.mediaItemId,
                userFileId: data.userFileId
            })
            if (data.userFileId) {
                setExcelFileList([{ thumbUrl: BASE_URL.slice(0, -1) + data?.userFile?.image, name: data?.userFile?.path }]);
            }
            if (data.mediaItemId) {
                setImageFileList([{ thumbUrl: BASE_URL.slice(0, -1) + data?.media_item?.image }]);
                setAdTypeSelection(data.ad_type);
            }
            setCurrentTab(data.userFileId?'upload': 'selectall');
        }
    }

    useEffect(() => {
        if (advetisementId) {
            fetchAdvertisement();
        }
    }, []);

    return (
        <div className='addEditAdvertisementLayout commonLayout'>
            <Row className='fWidthShowBox'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <h2 className='h2title'>{!advetisementId ? ADVERTISEMENTS_STRINGS.ADD_EDIT.CREATE_LABEL : ADVERTISEMENTS_STRINGS.ADD_EDIT.EDIT_LABEL}</h2>
                    <Form
                        onFinish={onFinish}
                        form={form}
                        colon={false}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 8 }}
                        className="formBox">
                        <Form.Item
                            label={<span className="input-label">{ADVERTISEMENTS_STRINGS.ADD_EDIT.ADVERTISE_TITLE}</span>}
                            name="title"
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('title') }]}>
                            <Input className='input-control' />
                        </Form.Item>
                        <Form.Item
                            label={<span className="input-label">{ADVERTISEMENTS_STRINGS.ADD_EDIT.REDIRECT_URL}</span>}
                            name="redirectUrl"
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('redirect url') }, { validator: (_, value) => { if (value) { if (!validateUrl(value)) return Promise.reject(''); } return Promise.resolve(); }, message: 'Pleae enter valid url!' }]}>
                            <Input className='input-control' />
                        </Form.Item>
                        <Form.Item
                            label={<span className="input-label">{ADVERTISEMENTS_STRINGS.ADD_EDIT.ADVERTISEMENT_TYPE}</span>}
                            name="adType" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('advertisement type') }]}>
                            <Select className='input-control' onChange={(e) => changeAdType(e)} value={AdTypeSelection} suffixIcon={<SelectArrow />}>
                                <Select.Option value="box">{ADVERTISEMENTS_STRINGS.ADVERTISEMENT_TYPES.BOX}</Select.Option>
                                <Select.Option value="sticky">{ADVERTISEMENTS_STRINGS.ADVERTISEMENT_TYPES.STICKY}</Select.Option>
                                <Select.Option value="small">{ADVERTISEMENTS_STRINGS.ADVERTISEMENT_TYPES.SMALL}</Select.Option>
                                <Select.Option value="half">{ADVERTISEMENTS_STRINGS.ADVERTISEMENT_TYPES.HALF}</Select.Option>
                                <Select.Option value="full">{ADVERTISEMENTS_STRINGS.ADVERTISEMENT_TYPES.FULL}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={<span className="input-label">{ADVERTISEMENTS_STRINGS.ADD_EDIT.SELECT_TAB}</span>}
                            name="tabId"
                            rules={[{
                                validator: (_, value) => {
                                    if (!value) {
                                        return Promise.reject(new Error('abc'));
                                    }
                                    else {
                                        return Promise.resolve();
                                    }
                                }, message: COMMON_ALERTS.REQUIRED_INPUT('tab')
                            }]}>
                            <Select className='input-control' onChange={fetchSubTabs} suffixIcon={<SelectArrow />}><Select.Option></Select.Option>{tabs.map((e, i) => <Select.Option value={e.id} key={i}>{e.name}</Select.Option>)}</Select>
                        </Form.Item>
                        <Form.Item
                            label={<span className="input-label">{ADVERTISEMENTS_STRINGS.ADD_EDIT.SELECT_SUB_TAB}</span>}
                            name="subtabId"
                            rules={[{
                                validator: (_, value) => {
                                    if (!value) {
                                        return Promise.reject(new Error('abc'));
                                    }
                                    else {
                                        return Promise.resolve();
                                    }
                                }, message: COMMON_ALERTS.REQUIRED_SELECT('subtab')
                            }]}>
                            <Select className='input-control' onChange={fetchSubCategories} suffixIcon={<SelectArrow />}><Select.Option></Select.Option>{SubTabs.map((e, i) => <Select.Option value={e.id} key={i}>{e.name}</Select.Option>)}</Select>
                        </Form.Item>
                        <Form.Item
                            label={<span className="input-label">{ADVERTISEMENTS_STRINGS.ADD_EDIT.SELECT_SECTION}</span>}
                            name="subcategoryId"
                            rules={[{
                                validator: (_, value) => {
                                    if (!['half', 'full'].includes(AdTypeSelection) && (!value)) {
                                        return Promise.reject(new Error('abc'));
                                    }
                                    else {
                                        return Promise.resolve();
                                    }
                                }, message: COMMON_ALERTS.REQUIRED_SELECT('section')
                            }]}>
                            <Select className='input-control' disabled={['half', 'full'].includes(AdTypeSelection) ? true : false} suffixIcon={<SelectArrow />}><Select.Option></Select.Option>{Subcategories.map((e, i) => <Select.Option value={e.id} key={i}>{e.name}</Select.Option>)}</Select>
                        </Form.Item>
                        <Form.Item
                            label={<span className="input-label">{ADVERTISEMENTS_STRINGS.ADD_EDIT.ADD_IMAGE}</span>}
                            name="mediaItemId"
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('image') }]}>
                            <div className="imagePickerDiv">
                                <Upload accept=".png, .jpg, .jpeg" listType="picture" {...imageProps}>
                                    {imageFileList.length >= 1 ? null : uploadButton}
                                </Upload>
                            </div>
                        </Form.Item>
                        <Form.Item
                            label={<span className="input-label">{COMMON_STRING.ACTIVE}</span>}
                            valuePropName="checked"
                            name="isActive">
                            <Switch />
                        </Form.Item>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <h2 className='h2title'>{ADVERTISEMENTS_STRINGS.ADD_EDIT.SET_BUDGET_LABEL}</h2>
                            </Col>
                        </Row>
                        <Form.Item
                            label={<span className="input-label">{ADVERTISEMENTS_STRINGS.ADD_EDIT.IMPRESSION_COUNT}</span>}
                            name="impressionCount"
                            rules={[{
                                validator: (_, value) => {
                                    const startDate = form.getFieldValue('startDate');
                                    const endDate = form.getFieldValue('endDate');
                                    if ((!startDate) && (!endDate) && (!value)) {
                                        return Promise.reject('');
                                    }
                                    return Promise.resolve('');
                                }, message: COMMON_ALERTS.REQUIRED_INPUT('impression count')
                            }]}
                        >
                            <InputNumber type="number" className='input-control' />
                        </Form.Item>
                        <Row>
                            <Col xs={24} sm={24} md={{ offset: 8, span: 8 }} lg={{ offset: 8, span: 8 }} xl={{ offset: 8, span: 8 }}><span className="centerText">And/Or</span></Col>
                        </Row>
                        <Form.Item
                            label={<span className="input-label">{FILTER_STRING.START_DATE}</span>}
                            name="startDate"
                            disabledDate={d => !d || d.isAfter(form.getFieldValue('endDate') || null)}
                            rules={[{
                                validator: (_, value) => {
                                    const impressionCount = form.getFieldValue('impressionCount');
                                    if ((!impressionCount) && (!value)) {
                                        return Promise.reject('');
                                    }
                                    return Promise.resolve('');
                                }, message: COMMON_ALERTS.REQUIRED_INPUT('start date')
                            }]}

                        >
                            <DatePicker className='input-control' suffixIcon={<CalenderDarkIcon />} onChange={value => setFilterStartDate(value?.toString())} disabledDate={d => !d || d.isAfter(FilterEndDate)} />
                        </Form.Item>
                        <Form.Item
                            label={<span className="input-label">{FILTER_STRING.END_DATE}</span>}
                            name="endDate"
                            disabledDate={d => !d || d.isSameOrBefore(form.getFieldValue('startDate') || null)}
                            rules={[{
                                validator: (_, value) => {
                                    const impressionCount = form.getFieldValue('impressionCount');
                                    if ((!impressionCount) && (!value)) {
                                        return Promise.reject('');
                                    }
                                    return Promise.resolve('');
                                }, message: COMMON_ALERTS.REQUIRED_INPUT('end date')
                            }]}

                        >
                            <DatePicker className='input-control' suffixIcon={<CalenderDarkIcon />} onChange={value => setFilterEndDate(value?.toString())} disabledDate={d => !d || d.isSameOrBefore(FilterStartDate)} />
                        </Form.Item>
                        <Row className='fWidthShowBox'>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Tabs className='propertyDetailsTabs' color="#324666" onChange={setCurrentTab} defaultActiveKey={currentTab} activeKey={currentTab}>
                                    <TabPane tab={ADVERTISEMENTS_STRINGS.ADD_EDIT.SELECT_ALL}  key="selectall">
                                        <Form.Item name="platform" label={<span className="input-label">{ADVERTISEMENTS_STRINGS.ADD_EDIT.SELECT_PLATFORM}</span>} rules={[{ required: currentTab == 'selectall'? true: false, message: COMMON_ALERTS.REQUIRED_SELECT('atleast one platform') }]}>
                                            <Checkbox.Group>
                                                <Row>
                                                    <Col span={8}><Checkbox value="android" style={{ lineHeight: '32px' }}>{COMMON_STRING.ANDROID}</Checkbox></Col>
                                                    <Col span={8}><Checkbox value="ios" style={{ lineHeight: '32px' }}>{COMMON_STRING.IOS}</Checkbox></Col>
                                                    <Col span={8}><Checkbox value="web" style={{ lineHeight: '32px' }}>{COMMON_STRING.WEB}</Checkbox></Col>
                                                </Row>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </TabPane>
                                    <TabPane tab={ADVERTISEMENTS_STRINGS.ADD_EDIT.TARGET_USER} key="upload">
                                        <Row>
                                            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                                                <span className='inputTitle d-block text-end'>{COMMON_STRING.UPLOAD_FILE('Users via CSV / Excel')}</span>
                                            </Col>
                                            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                                                <FormItem
                                                    name="userFileId"
                                                    rules={[{ required: currentTab == 'upload'? true: false, message: COMMON_ALERTS.REQUIRED_SELECT('file') }]}>
                                                        <Upload listType="picture" {...excelFileProps(excelFileList, setExcelFileList, '.xlsx, .csv')}>
                                                            {excelFileList.length >= 1 ? null : uploadButton}
                                                        </Upload>
                                                </FormItem>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col xs={24} sm={24} md={{ offset: 8, span: 16 }} lg={{ offset: 8, span: 16 }} xl={{ offset: 8, span: 16 }} className="btnBox">
                                <Button onClick={() => navigate('/advertisements')} className="backBtn">{COMMON_STRING.BACK_BTN}</Button>
                                <button type="submit" className="createBtn">{advetisementId ? COMMON_STRING.UPDATE_BTN : COMMON_STRING.CREATE_BTN}</button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

export default AddEditAdvertisement;
