import React, { useState } from "react";
import PropTypes from 'prop-types';

const AppInput = ({ value, onChange, className }) => {
    return <input className={`appInput ${className}`} value={value} onChange={onChange} />;
}

AppInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string
};


export default AppInput;