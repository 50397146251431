import React from 'react';

function DiscardedIcon(props) {
    return (
        <span {...props} className='anticon anticon-discarded ant-menu-item-icon'>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.319444" y="0.319444" width="29.3611" height="29.3611" rx="3.51389" fill="#FFECEC"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M19.3778 11.4546C19.4324 11.4 19.4758 11.3353 19.5054 11.2639C19.535 11.1926 19.5502 11.1162 19.5503 11.0389C19.5503 10.9617 19.5352 10.8852 19.5057 10.8139C19.4762 10.7425 19.4329 10.6777 19.3783 10.623C19.3238 10.5684 19.259 10.525 19.1876 10.4955C19.1163 10.4659 19.0399 10.4506 18.9626 10.4506C18.8854 10.4505 18.809 10.4657 18.7376 10.4952C18.6662 10.5247 18.6014 10.568 18.5467 10.6225L15.0007 14.1686L11.4556 10.6225C11.3453 10.5122 11.1956 10.4502 11.0395 10.4502C10.8835 10.4502 10.7338 10.5122 10.6235 10.6225C10.5132 10.7329 10.4512 10.8825 10.4512 11.0386C10.4512 11.1946 10.5132 11.3443 10.6235 11.4546L14.1696 14.9997L10.6235 18.5447C10.5689 18.5994 10.5255 18.6642 10.496 18.7356C10.4664 18.807 10.4512 18.8835 10.4512 18.9608C10.4512 19.0381 10.4664 19.1146 10.496 19.186C10.5255 19.2573 10.5689 19.3222 10.6235 19.3768C10.7338 19.4872 10.8835 19.5492 11.0395 19.5492C11.1168 19.5492 11.1933 19.5339 11.2647 19.5044C11.3361 19.4748 11.401 19.4315 11.4556 19.3768L15.0007 15.8307L18.5467 19.3768C18.6571 19.487 18.8067 19.5489 18.9626 19.5488C19.1186 19.5487 19.2681 19.4867 19.3783 19.3763C19.4885 19.266 19.5504 19.1164 19.5503 18.9604C19.5502 18.8045 19.4882 18.655 19.3778 18.5447L15.8317 14.9997L19.3778 11.4546Z" fill="#D1293D"/>
                <rect x="0.319444" y="0.319444" width="29.3611" height="29.3611" rx="3.51389" stroke="#A5A8AD" strokeWidth="0.638889"/>
            </svg>
        </span>
    );
}

export default DiscardedIcon;