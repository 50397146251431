import { Button, Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { COMMON_ALERTS, COMMON_STRING, PROPERY_TYPE_STRINGS } from '../../services/strings';
import { PROPERTYPES } from '../../services/Property/PropertyTypes';

const AddEditPropertyType = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const propertyTypeId = useLocation().state?.propertTypeId;
    const [PropertyType, setPropertyType] = useState(null);
    const [isDataLoaded, setisDataLoaded] = useState(false);

    useEffect(() => {
        if (propertyTypeId !== null) {
            fetchPropertyType();
        }
    }, []);

    const fetchPropertyType = async () => {
        if (propertyTypeId) {
            const res = await PROPERTYPES.FIND(propertyTypeId);
            if (res.status) {
                setPropertyType(res.data);
                setisDataLoaded(true);
            }
        }
    }

    const onFinish = async (form) => {
        const payload = {
            name: form.name,
            percentage: form.percentage
        }
        let res = null;
        if (!propertyTypeId)
            res = await PROPERTYPES.CREATE(payload);
        else
            res = await PROPERTYPES.PATCH(propertyTypeId, payload);
        try {
            if (res.status) {
                toast.success(!propertyTypeId ? COMMON_ALERTS.ADDED_SUCCESS('Propety type'): COMMON_ALERTS.UPDATED_SUCCESS('Property type'));
                navigate('/property_type', { replace: true });
            }
        }
        catch (e) {
            // console.log(e);
        }
    }

    if ((!propertyTypeId) || isDataLoaded)
        return (
            <div>
                <div className="fWidthShowBox addNwDepScheduleContainer">
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <h2>{!propertyTypeId ? PROPERY_TYPE_STRINGS.ADD_TYPE_LABEL : PROPERY_TYPE_STRINGS.EDIT_TYPE_LABEL}</h2>
                        </Col>
                    </Row>
                    <Form name="complex-form" className="addformLayout" form={form} onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} colon={false}>
                        <Form.Item
                            label={<span className='inputTitle'>{PROPERY_TYPE_STRINGS.PROPERTY_TYPE}</span>}
                            name="name"
                            initialValue={PropertyType?.name}
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('property type') }]}
                        >
                            <Input className='textInput' />
                        </Form.Item>
                        <Row>
                            <Col xs={24} sm={24} md={{ span: 16, offset: 8 }} lg={{ span: 16, offset: 8 }} xl={{ span: 16, offset: 8 }} className="btnBox">
                                <Button className="cancelBtn" onClick={() => navigate('/property_type', { replace: true })}>{COMMON_STRING.BACK_BTN}</Button>
                                <Button type="submit" htmlType='submit' className="addBtn">{!propertyTypeId ? COMMON_STRING.UPDATE_BTN : COMMON_STRING.CREATE_BTN}</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>

            </div>
        );
}

export default AddEditPropertyType;
