import { Col, DatePicker, Empty, Form, Input, Row, Select, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import FilterToogleBtn from '../../../components/FilterToogleBtn';
import CalenderDarkIcon from '../../../components/Icon/CalenderDarkIcon';
import SelectArrow from '../../../components/Icon/SelectArrow';
import { APP_USERS } from '../../../services';
import { COMMON_STRING, FILTER_STRING, REPORTS_STRINGS } from '../../../services/strings';
import { GLOBAL_VALUES } from '../../../services/GlobalValues/GlobalValues';
import { PROPERTIES } from '../../../services/Property/Properties';
import { REPORTS } from '../../../services/Reports/ReportsService';
import { ExportExcel, ExportPdf, handleFileName } from '../../../utility/Export/Export';

const BudgetingTabReports = () => {
  const [form] = Form.useForm();
  const [isFilterShow, setIsFilterShow] = useState(true);
  const [taxData, setTaxData] = useState([]);
  const [SelectedPropertyId, setSelectedPropertyId] = useState('');
  const [SelectedAppUserId, setSelectedAppUserId] = useState('');
  const [SelectedYear, setSelectedYear] = useState('');
  const [Appusers, setAppusers] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [Columns, setColumns] = useState('');
  const [Properties, setProperties] = useState([]);
  const [InflationRate, setInflationRate] = useState(0);
  const csvLink = useRef();
  const [csv, setCsv] = useState([]);
  const [pdfColumns, setPdfColumns] = useState([]);
  const [YearsList, setYearsList] = useState([]);

  const fetchAppUsers = async () => {
    const res = await APP_USERS.GET(-1);
    if (res.status && Array.isArray(res.data)) {
      setAppusers(res.data);
    }
  };

  const fetchProperties = async (userId) => {
    if (userId) {
      const res = await PROPERTIES.GETBYUSERID(-1, 0, null, null, userId);
      if (Array.isArray(res.data)) {
        setProperties(res.data);
      }
    }
  };

  const fetchInflationRate = async () => {
    const res = await GLOBAL_VALUES.GET('inflation_rate');
    if(res.status){
      const data = Array.isArray(res) ? res : res.data;
      if (data.length) {
        setInflationRate(data[0]?.value);
      }
    }
  };

  const fetchYears = async () => {
    const res = await REPORTS.EXPENSES_YEARS();
    if (res.status) {
      setYearsList(res.data);
    }
  };

  const changeUser = (value) => {
    if (value) {
      fetchProperties(value);
    }
    else {
      setColumns([]);
    }
    setSelectedAppUserId(value);
    if (!value || value == '') {
      setSelectedPropertyId('');
    }
  };

  const fetchTaxLoadgement = async () => {
    const res = await REPORTS.BUDGETING_CASH(SelectedPropertyId, SelectedAppUserId, startDate ? startDate : '', endDate ? endDate : '', SelectedYear);
    if (res.status) {
      const totalExpense = {
        key: 'overralTotal',
        name: 'Total Expense This Year'
      };
      const expensesData = [];
      const rentRecieved = {
        key: 'rentRecieved',
        name: 'Rent recieved'
      };
      const totalRentRecieved = {
        key: 'overralTotal2',
        name: 'Total Income This Year'
      };
      const totalMonthlyCashFlow = {
        key: 'monthlyCashFlow',
        name: 'Total monthly cashflow:'
      };
      const inflationsRates = {
        key: 'inflationRate',
        name: 'Inflation rate adding in monthly cashflow:'
      };
      const totalCashFlow = {
        key: 'totalCashFlow',
        name: 'Cashflow excluding borrowing'
      };
      const columns = [{
        title: <span className="tableTitle">Expense Type</span>,
        dataIndex: 'name',
        key: 'name',
        render: text => <span className="tabletext">{text}</span>,
        fixed: 'left',
        width: 180
      }];

      const docsColumns = [];

      res.data.map(budgeting => {
        columns.push({
          title: <span className="tableTitle propertyNameReport">{budgeting?.propertyName}</span>,
          dataIndex: budgeting?.propertyId,
          key: budgeting?.propertyId,
          width: 180,
          render: text => <span className="tabletext">{text}</span>,
        });
        docsColumns.push(budgeting.propertyName);

        rentRecieved[budgeting?.propertyId] = '$' + budgeting?.totalIncome;
        totalRentRecieved[budgeting?.propertyId] = '$' + budgeting?.totalIncome;
        totalExpense[budgeting?.propertyId] = 0;

        budgeting?.expenses?.map(expense => {
          const objIndex = expensesData.findIndex(elem => elem.name == expense?.name);
          totalExpense[budgeting?.propertyId] = + expense.amount;
          if (objIndex < 0) {
            const exp = {
              'key': '' + expensesData.length + 1,
              'name': expense?.name
            };
            res.data.map(resData => exp[resData.propertyId] = '$0');
            exp[budgeting.propertyId] = '$' + expense?.amount;
            expensesData.push(exp);
          }
          else {
            if(isNaN(expensesData[objIndex][budgeting.propertyId])) expensesData[objIndex][budgeting.propertyId]=0;
            expensesData[objIndex][budgeting.propertyId] = parseInt(expensesData[objIndex][budgeting.propertyId]) + parseInt(expense?.amount);
          }

        });

        setPdfColumns(docsColumns);
        const cfInNo = (parseFloat(budgeting?.totalIncome) - parseFloat(totalExpense[budgeting?.propertyId])).toFixed(2); //Cash Flow in Number
        const tCFwithInf = (parseFloat(cfInNo) + (parseFloat(cfInNo) * parseFloat(InflationRate) / 100)); // Total Cash Flow with inflation
        totalMonthlyCashFlow[budgeting?.propertyId] = cfInNo < 0 ? ('($' + Math.abs(cfInNo)) + ')' : '$' + cfInNo;
        totalExpense[budgeting?.propertyId] = '$' + totalExpense[budgeting?.propertyId];
        inflationsRates[budgeting?.propertyId] = InflationRate + '%';
        totalCashFlow[budgeting?.propertyId] = tCFwithInf < 0 ? '($' + Math.abs(tCFwithInf) + ')' : '$' + tCFwithInf;
      });
      setColumns(columns);
      setTaxData([
        ...expensesData,
        totalExpense,
        rentRecieved,
        totalRentRecieved,
        totalMonthlyCashFlow,
        inflationsRates,
        totalCashFlow
      ]);
    }
    else {
      setColumns([]);
      setPdfColumns([]);
      setTaxData([]);
    }
  };

  useEffect(() => {
    fetchInflationRate();
    fetchAppUsers();
    fetchYears();
  }, []);

  useEffect(() => {
    fetchTaxLoadgement();
  }, [SelectedAppUserId, SelectedPropertyId, startDate, endDate, SelectedYear]);

  const headers = ['Expense Types'];
  pdfColumns.forEach((elem, index) => headers.push(elem));

  const handleDownloadCsv = async () => {
    const temp = taxData?.map((item) => {
      const keys = Object.keys(item);
      keys.splice(0, 1);
      return keys.map((key, index) => item[key]);
    });
    setCsv(temp);
    csvLink.current.link.click();
  };

  const generateExcel = async () => {
    const temp = taxData?.map((item) => {
      delete item.key;
      const keys = Object.keys(item);
      const data = {};
      keys.map((key, index) => {
        const d = [];
        if (index < 1)
          d[key] = item[key];
        else
          d[Properties[index].name] = item[key];
        Object.assign(data, d);
      });
      return data;
    });
    ExportExcel(temp, 'budgetReport');
  };

  const generatePDF = async () => {
    const pdftableColumn = ['Expense Types', ...pdfColumns];
    const pdftableRows = [];
    taxData?.map((item) => {
      const keys = Object.keys(item);
      const usersReportsListData = [];
      keys.forEach((elem, i) => {
        if (i > 0)
          usersReportsListData.push(item[elem]);
      });
      pdftableRows.push(usersReportsListData);
    });
    ExportPdf(pdftableColumn, pdftableRows, 'budgetReport');
  };

  const clearAll = () => {
    setSelectedAppUserId('');
    setSelectedPropertyId('');
    setSelectedYear('');
    setStartDate('');
    setEndDate('');
    form.resetFields();
  }

  return (
    <div className='userTabReportLayout'>
      <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} withmenu={true} />
      <Row className={isFilterShow ? 'filterBoxLayout mb-3' : 'filterBoxLayout d-none'}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form form={form}>
                <Form.Item
                  label={<span className="inputTitle">{FILTER_STRING.START_DATE}</span>}
                  colon={false}
                  name="startDate"
                >
                  <DatePicker className='w-100 inputField' disabledDate={d => !d || d.isAfter(endDate)} onChange={value => setStartDate(value)} placeholder="" allowClear suffixIcon={<CalenderDarkIcon />} />
                </Form.Item>
                <Form.Item
                  label={<span className="inputTitle">{FILTER_STRING.END_DATE}</span>}
                  colon={false}
                  name="endDate"
                >
                  <DatePicker className='w-100 inputField' disabledDate={d => !d || d.isSameOrBefore(startDate)} onChange={value => setEndDate(value)} placeholder="" allowClear suffixIcon={<CalenderDarkIcon />} />
                </Form.Item>
                <Form.Item
                  label={<span className="inputTitle">{FILTER_STRING.SEARCH}</span>}
                  colon={false}
                >
                  <Input placeholder={COMMON_STRING.SEARCH_STR + 'Cash Budgeting'} className='inputField searchField' />
                </Form.Item>
              </Form>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className={'dropdownBox'}>
              <div className="filterSelect">
                <Select value={SelectedAppUserId} onChange={(value) => changeUser(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value=''>{FILTER_STRING.USER}</Select.Option>{Appusers?.map((user, key) => <Select.Option key={key} value={user.id}>{user.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select value={SelectedPropertyId} onChange={(value) => setSelectedPropertyId(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value=''>{FILTER_STRING.PROPERTIES}</Select.Option>{Properties?.map((property, key) => <Select.Option key={key} value={property.id}>{property.name ? property.name : '-'}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select value={SelectedYear} onChange={(value) => setSelectedYear(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value="">{FILTER_STRING.YEAR}</Select.Option>{YearsList.map((year, key) => <Select.Option key={key} value={year.year}>{year.year}</Select.Option>)}</Select>
              </div>
              <div className="exportOuterBoxFlex">
                <div className="exportBox">
                <div className="exportTitle">Export</div>
                <div><div className="exportBtn" onClick={handleDownloadCsv}>{REPORTS_STRINGS.BUTTONS.EXPORT_CSV}</div>
                  <CSVLink
                    data={csv}
                    headers={headers}
                    filename={handleFileName('expensesReport', 'csv')}
                    ref={csvLink}
                    className='text-black font-weight-600'
                  ></CSVLink>
                </div>
                <div className="exportBtn" onClick={generateExcel}>{REPORTS_STRINGS.BUTTONS.EXPORT_EXCEL}</div>
                <div className="exportBtn" onClick={generatePDF}>{REPORTS_STRINGS.BUTTONS.EXPORT_PDF}</div>
                </div>
                <div className="filterSelect clearAll" onClick={() => clearAll()}>Clear all</div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {SelectedAppUserId ? (Columns?.length > 0 ? (<Table
            columns={Columns}
            dataSource={taxData}
            scroll={{ x: 200 }}
            pagination={false}
          />) : <Empty description="Properties not found for this user." />)
            : <Empty description="Please select user." />}
        </Col>
      </Row>
    </div>
  );
};

export default BudgetingTabReports;
