import { methods, serviceMaker } from '../service';

const GET_CITY = (id) => serviceMaker(`cities/${id}`, methods.GET);

const GET_CITIES = (
  limit,
  skip,
  search = null,
  sort = null,
  stateId = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/cities`, methods.GET, query);
  }

  if (stateId) {
    query['params']['stateId'] = stateId;
  }

  return serviceMaker(`cities`, methods.GET, query);
};

const DELETE_CITY = (id) => serviceMaker(`cities/${id}`, methods.DELETE);

const CREATE_CITY = (payload) =>
  serviceMaker(`cities`, methods.POST, null, payload);

const PATCH_CITY = (id, payload) =>
  serviceMaker(`cities/${id}`, methods.PATCH, null, payload);

const CITIES = {
  FIND: GET_CITY,
  GET: GET_CITIES,
  CREATE: CREATE_CITY,
  PATCH: PATCH_CITY,
  DELETE: DELETE_CITY
};

export { CITIES };
