import { methods, serviceMaker } from '../service';

const FIND_NEWSARTICLES = (id) => serviceMaker(`news-details/${id}`, methods.GET);

const GET_NEWSARTICLES = (
  limit,
  skip,
  search = null,
  sort = null,
  type = null,
  isTopNews = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/news-details`, methods.GET, query);
  }

  if (isTopNews) {
    query['params']['isTopNews'] = true;
  }

  if (type) {
    query['params']['type'] = type;
  }

  return serviceMaker(`news-details`, methods.GET, query);
};

const DELETE_NEWSARTICLES = (id) => serviceMaker(`news-details/${id}`, methods.DELETE);

const CREATE_NEWSARTICLES = (payload) =>
  serviceMaker(`news-details`, methods.POST, null, payload);

const PATCH_NEWSARTICLES = (id, payload) =>
  serviceMaker(`news-details/${id}`, methods.PATCH, null, payload);

const NEWSARTICLES = {
  FIND: FIND_NEWSARTICLES,
  GET: GET_NEWSARTICLES,
  CREATE: CREATE_NEWSARTICLES,
  PATCH: PATCH_NEWSARTICLES,
  DELETE: DELETE_NEWSARTICLES
};

export { NEWSARTICLES };
