import { methods, serviceMaker } from '../service';

const GET_SERVICES = (
    limit,
    skip,
    propertyId,
    search = null,
    sort = null
  ) => {
    const query = {
      params: {
        $limit: limit,
        $skip: skip
      },
    };
    if (sort) {
      query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
    } else {
      query['params']['$sort[createdAt]'] = -1;
    }
    if (search) {
      query['params']['search'] = search;
      return serviceMaker(`search/scheduled-service`, methods.GET, query);
    }
      return serviceMaker(`scheduled-service?propertyId=${propertyId}`, methods.GET, query);
  };

const DELETE_SERVICE = (id) => serviceMaker(`scheduled-service/${id}`, methods.DELETE);

const SCHEDULED_SERVICES= {
    GET: GET_SERVICES,
    DELETE: DELETE_SERVICE
  };
  export { SCHEDULED_SERVICES };
  