//Sidebar Strings
export const SIDEBAR_STRINGS = {
    DASHBOARD: 'Dashboard',
    USERS: 'Users',
    PROPERTY: 'Property',
    CONTACT_DETAILS: 'Contact Details',
    EXPENSES: 'Expenses',
    SERVICE_SCHEDULE: 'Service Schedule',
    EXTERNAL_SERVICE: 'External Service',
    PROPERTY_GOALS: 'Property Goals',
    CALCULATOR_INFO: 'Calculator Info',
    FHBH: 'F.H.B.G', //First home buyer goal
    CITY_STATE: 'City/State',
    PROPERTY_TYPE: 'Property Type',
    LENDERS: 'Lenders',
    SERVICE_CATEGORY: 'Service Category',
    REDEEM_REWARDS: 'Redeem Rewards',
    NEWS_ARTICLES: 'News and Articles',
    FAQ: 'FAQs',
    ROLE_MANAGE: 'Role Management',
    REPORT: 'Reports',
    AGENTS: 'Agents',
    REFERRALS: 'Referrals',
    ADVERTISEMENT: 'Advertisement',
    NOTIFICATION_CENTER: 'Notification Center',
    FEEDBACK: 'Feedback',
    AVM: 'AVM'
};

//Sidebar
export const SIDEBAR_MENUS = [
    SIDEBAR_STRINGS.DASHBOARD, 
    SIDEBAR_STRINGS.USERS,
    SIDEBAR_STRINGS.PROPERTY,
    SIDEBAR_STRINGS.CONTACT_DETAILS,
    SIDEBAR_STRINGS.EXPENSES,
    SIDEBAR_STRINGS.SERVICE_SCHEDULE,
    SIDEBAR_STRINGS.EXTERNAL_SERVICE,
    SIDEBAR_STRINGS.PROPERTY_GOALS,
    SIDEBAR_STRINGS.CALCULATOR_INFO,
    SIDEBAR_STRINGS.FHBH,
    SIDEBAR_STRINGS.CITY_STATE,
    SIDEBAR_STRINGS.PROPERTY_TYPE,
    SIDEBAR_STRINGS.LENDERS,
    SIDEBAR_STRINGS.SERVICE_CATEGORY,
    SIDEBAR_STRINGS.REDEEM_REWARDS,
    SIDEBAR_STRINGS.NEWS_ARTICLES,
    SIDEBAR_STRINGS.FAQ,
    SIDEBAR_STRINGS.ROLE_MANAGE,
    SIDEBAR_STRINGS.REPORT,
    SIDEBAR_STRINGS.AGENTS,
    SIDEBAR_STRINGS.AVM,
    SIDEBAR_STRINGS.REFERRALS,
    SIDEBAR_STRINGS.ADVERTISEMENT,
    SIDEBAR_STRINGS.NOTIFICATION_CENTER,
    SIDEBAR_STRINGS.FEEDBACK
];

//Common Strings 
export const COMMON_STRING = {
    CREATE_BTN: 'Create',
    SAVE_BTN: 'Save',
    ADD_BTN: 'Add',
    SET_BTN: 'Set',
    VALIDATE_BTN: 'Validate',
    BACK_BTN: 'Back',
    CANCEL_BTN: 'Cancel',
    UPDATE_BTN: 'Update',
    ADD_ROW_BTN: 'Add Row',
    NEXT_BTN: 'Next',
    SEARCH_STR: 'Start typing to search ',
    FILTER_BTN: 'Filter',
    COUNTRY: 'Country',
    STATE: 'State',
    CITY: 'City',
    SUBURB: 'Suburb',
    ANDROID: 'Android',
    IOS: 'iOS',
    WEB: 'Web',
    ACTIVE: 'Active',
    REFERRAL_PD: 'PD Per Referral',
    REFERREE_PD: 'PD Per Referree',
    UPLOAD_FILE : (filename='') => `Enter Multiple ${filename} file upload`,
    NO_FILE_CHOOSEN: 'No File Chosen'
};

export const COMMON_ALERTS = {
    SOMETHING_WENT_WRONG: 'Something went wrong!',
    LOGIN_SUCCESS: 'Login Successfully !',
    REQUIRED_INPUT: (column_name) => `${capitalize(column_name)} is required!`,
    REQUIRED_SELECT: (column_name) => `Please select ${column_name}!`,
    REQUIRED_CHOOSE: (column_name) => `Please choose ${column_name}!`,
    ADDED_SUCCESS: (string) => `${string} added successfully`,
    UPDATED_SUCCESS: (string) => `${capitalize(string)} updated successfully`,
    UPLOADED_SUCCESS: (string) => `${capitalize(string)} uploaded successfully`,
    DELETED_SUCCESS: (string) => `${capitalize(string)} deleted successfully`,
    INVALID_ERROR: (string) => `Please enter valid ${string.toLowerCase()}!`,
    ONLY_ALLOWED_ERROR: (extensions) => `Only ${extensions} files are allowed!`,
    ARE_YOU_WANT: (task) => `Are you sure you want to ${task}?`,
    DATA_NOT_FOUND: (dname, selected) => `No ${dname} data found in selected ${selected}}`,
    PLACEHOLDER_INPUT: (columnname) => `Please input ${columnname}`,
    USERNAME_NO_SPACE: 'Please enter username without space!',
    VALID_EMAIL: 'Please input valid email!',
    ALREADY_TOKEN_EMAIL: 'This email is already taken!',
    ALREADY_TOKEN_USERNAME: 'This username is already taken!',
    CONFIRM_PASS_NO_MATCH: 'Confirm password not match!',
    FILE_EXISTS: 'File already exists!.',
    FILE_UPLOAD_FAILED: (filename) => `${filename} file upload failed`,
    SELECT_IMAGE_FIRST: 'Please select image first.',
    USER_PASS_WRONG: 'Username Or Password is Wrong ! Please Try Again',
    NON_NEGETIVE: 'value shouldn\'t be less than 0.'
};

//Login Page
export const LOGINPAGE_STRINGS = {
    TITLE: 'Login',
    USERNAME_LABEL: 'Username',
    PASSWORD_LABEL: 'Password',
    LOGIN_BTN: 'Login'
};

//Dashboard
export const DASHBOARD_STRING = {
    TOTAL_USRER_PLTFM_WISE: 'Total Users - Platforms wise',
    USERS_DOWNLOAD: 'Users Download',
    NUM_OF_PROPERTIES: 'Number of Properties',
    STATEWISE_PROPERTIES: 'State Wise Property Counts',
    TOTAL_SERVICE_CONSUMED: 'Total Services Consumed',
    NUM_API_CALLS_CORELOGIC: 'Number of API calls made to Corelogic',
    NUM_ADDRESS_API_CALLS: 'Number of API calls for Address Verification',
    PDOLLAR_MANAGEMENT: 'Property Dollar Management',
    APP_REFERRALS: 'App Referrals',
    TOP_TEN_REFERRALS: 'Top 10 Referrals (Property wise)',
    TOTAL_REWARD_POINTS: 'Total Reward Points',
    TOTAL_PDOLLAR_SPEND: 'Property Dollar Spent'
};

//Common Table label
export const TABLE_STRING = {
    ACTION: 'Actions'
};

//All common string of filters
export const FILTER_STRING = {
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    SEARCH: 'Search',
    COUNTRY: 'Country',
    STATE: 'State',
    CITY: 'City',
    SUBURBS: 'Suburbs',
    YEAR: 'Select Year',
    MONTH: 'Select Month',
    EXPENSES: 'Expenses Type',
    SERVICE_TYPE: 'Service Type',
    USER: 'Select User',
    PROPERTIES: 'Select Property',
    PROPERTY_TYPE: 'Property Type',
    PLATFORM: 'Platform',
    STATUS: 'Status',
    REFER_BY: 'Referred by'
}

//User Details Page
export const USER_DETAILS_STRINGS = {
    TABS: {
        ADMIN_ADDED: 'Admin Added',
        USER_ADDED: 'User Added'
    },
    TABLE: {
        NAME: 'Name',
        EMAIL: 'Email Id',
        CONTACT: 'Contact No'
    },
    USER_DETAILS: {
        USER_DETAIL: 'User Details',
        FIRST_NAME: 'First Name',
        LAST_NAME: 'Last Name',
        POSTAL_ADDRESS: 'Postal Address',
        EMAIL: 'Email Id',
        CONTACT_NO: 'Contact No.'
    },
    USER_SAVED_CONTACT: 'User’s Saved Contacts',
    USERS_PROPERTY: 'User’s Property',
    PROFESSIONS: [
        "Plumber",
        "Electrician",
        "Cleaner",
        "Pool-maintenance",
        "Gardner",
        "Handyman",
        "Locksmith",
        "Real estate-agent",
        "Tenant",
        "Other"
    ],
    CONTACT_DETAILS: {
        CONTACT_NAME: 'Contact Name',
        CONTACT_MOBILE: 'Contact Mobile',
        CONTACT_OFFICE: 'Contact Office',
        CONTACT_OTHER: 'Contact Other',
        EMAIL: 'Email Id',
        PROFESSION: 'Profession'
    },
    PROPERTIES: {
        NAME: 'Property Name',
        TYPE: 'Property Type',
        PURCHASE_DATE: 'Purchase Date'
    }
};

//First Home Buyer Goal (FHBG)
export const FHBG_STRING = {
    TABLE: {
        STATE: 'State Name',
        INFO: 'Information Added',
        ADD_INFO: 'Add Inforomation'
    },
    DETAILS: {
        TITLE: 'Add/Edit Information'
    }
}

//Properties Page
export const PROPERTIES_STRING = {
    TABLE: {
        PROPERTY_LIST: 'Property List',
        PROPERTY_TYPE: 'Property Type',
        PURCHASE_DATE: 'Purchase Date',
        VALIDATE_PROPERTY: 'Validate Property',
        VALIDATE_STATUS: 'Validation Status',
        STATUS: 'Status'
    },
    FILTER: {
        PROPERTY_TYPE: 'Property Type',
        STATUS: 'Status',
        CAR_SPACE: 'Car Space',
        LAND_SPACE: 'Land Space',
        NO_OF_BEDS: 'No. of Beds',
        NO_OF_BATH: 'No. of Baths',
        COUNCIL: 'Council',
        PENDING_VALID_PROPERTY: 'Pending Validate Property'
    },
    VALIDATE_BTN: 'Validate',
    TABS: {
        DETAILS: 'Details',
        DEP_SCHEDULE: 'Depreciation Schedule',
        RENTAL_INCOME: 'Rental Income',
        EXPENSES: 'Expenses',
        CONTACTS: 'Contacts',
        SERVICES: 'Services'
    },
    PROPERTY_VALIDATION: {
        TITLE: 'Validate Property',
        PROPERTY_NAME: 'Property Name',
        PROPERTY_TYPE: 'Property Type',
        PURCHASE_DATE: 'Purchase Date',
        DOCUMENT_PREVIEW: 'Document Preview'
    },
    PROPERTY_DETAILS: {
        PROPERTY_NAME: 'Property Name',
        NO_OF_BEDS: 'Number of Bed',
        NO_OF_BATHS: 'Number of Bath',
        CAR_SPACE: 'Car Space',
        LAND_SPACE: 'Land Size',
        PROPERTY_TYPE: 'Property Type',
        COUNCIL: 'Council',
        PURCHASE_DATE: 'Purchase Date',
        ADDITIONAL_INFO: 'Additional Information',
        DEBT: 'Debt',
        EQUITY: 'Equity',
        MARKET_VAL: 'Market Value',
        RENT: 'Rent',
        MONTHLY_EXP: 'Monthly Expenses'
    },
    MORTGAGE: {
        MORTGAGE_DETAILS: 'Mortgage Details',
        LOAN_AMOUNT: 'Loan Amount',
        LENDER: 'Lender',
        INTEREST_RATE: 'Interest Rate',
        TERM: 'Loan Term',
        RATE_TYPE: 'Type Rate',
        INTEREST_TYPE: 'Type of Interest',
        REPAY_AMOUNT: 'Repayment Amount'
    },
    INSURANCE: {
        INSURANCE_DETAILS: 'Insurance Details',
        INSURANCE_TYPE: 'Insurance Type',
        INSURER: 'Insurer',
        PREMIUM_AMOUNT: 'Premium Amount',
        EXPIRATION_AMOUNT: 'Expiration Date'
    },
    VALUATION: {
        VALUATION_DETAILS: 'Valuation Details',
        EVALUATION_TYPE: 'Evaluation Type',
        DATE: 'Date',
        AMOUNT: 'Amount'
    },
    DEPRECIATION_SCHEDULE: {
        YEAR: 'Year',
        PLANT_AND_EQUIP: 'Plant and Equipment',
        DIVISION: 'Division 43',
        TOTAL: 'Total'
    },
    RENTAL_INCOME: {
        TOTAL_RENT_RECIEVED: 'Total Rent received',
        FREQUENCY: 'Frequency',
        START_DATE: 'Start Date',
        END_DATE: 'End Date'
    },
    EXPENSES: {
        TYPE: 'Type',
        ANNUAL_INT_PAID: 'Amount / Annual Interest Paid on Property',
        ELIGIBLE_TAX_LODGEMENT: 'Eligible in Tax Lodgement'
    },
    CONTACTS: {
        USERNAME: 'User Name',
        EMAIL: 'Contact Email',
        MOBILE: 'Contact No.'
    },
    SERVICES: {
        CONTACT_NAME: 'Contact Name',
        SERVICE_NAME: 'Service Name',
        SERVICE_SELECTION: 'Service Selection',
        CONTACT_NO: 'Contact No.',
        SERVICE_DATE: 'Date Of service'
    }
};


export const CONTACT_DETAILS_STRINGS = {
    TOOGLE: {
        ADMIN_ADDED: 'Admin Added',
        USER_ADDED: 'User Added'
    },
    TABLE: {
        CONTACT_NAME: 'Contact Name',
        CONTACT_NO: 'Contact No.',
        EMAIl: 'Email Id',
        LINKED_PROPERTY: 'Linked Property'
    },
    ADD_EDIT: {
        ADD_CONTACT: 'Add New Contact',
        EDIT_CONTACT: 'Edit Contact',
        CONTACT_NAME: 'Contact Name',
        EMAIL: 'Email Id',
        CONTACT_NO: 'Contact No.',
        PROFESSION: 'Profession',
        PROFILE_IMAGE: 'Profile Image',
        FURTHER_USE: 'Further Use'
    }
};


export const EXPENSES_STRINGS = {
    ADD_BTN: 'Add New Expense',
    EXPENSE_TYPE: 'Expense Type',
    TYPE_EXPENSES: 'Type Expense Name',
    IS_POPULAR: 'Popular',
    ADD_EXPENSE: 'Add New Expense',
    EDIT_EXPENSE: 'Edit Expense'
};

export const SERVICE_SCHEDULE_STRING = {
    TABLE: {
        SERVICE_TYPE: 'Service Type'
    },
    ADD_NEW_BTN: 'Add New Service Type',
    ADD_NEW_SERVICE: 'Add New Service Type',
    EDIT_SERVICE: 'Edit Service Type',
    SERVICE_TYPE_NAME: 'Service Type Name'
};

export const EXTERNAL_SERVICE_STRINGS = {
    ADD_BTN: 'Add New External Service',
    TABLE: {
        VENDOR_PROFILE: 'Vendor Profile',
        VENDOR_NAME: 'Vendor Name',
        BUSINESS_NAME: 'Bussiness Name',
        SERVICE_TYPE: 'Type of Service',
        CONTACT: 'Contact No.'
    },
    FILTER: {
        PLATFORM: 'Platform',
        SERVICE_TYPE: 'Type of Service',
        AVAILABLITY: 'Availability'
    },
    ADD_EDIT: {
        ADD_SERVICE_LABEL: 'Add New External Service Provider',
        EDIT_SERVICE_LABEL: 'Edit External Service Provider',
        VENDOR_NAME: 'Vendor Name',
        BUSINESS_NAME: 'Business Name',
        CONTACT_NO: 'Contact No.',
        SERVICE_TYPE: 'Type of Service',
        COUNTRY: 'Country',
        STATE: 'State',
        CITY: 'City',
        SUBURBS: 'Suburb',
        VENDOR_PROFILE: 'Vendor Profile',
        UPLOAD_IMAGES: 'Upload Images of Business (Upto 3)',
        SELECT_PARAMTER: 'Select Parameter',
        PARAMS_VALUES: 'Parameter Values',
        AVAILABILITY: 'Availability',
        MIN_VAL: 'Min Value',
        MAX_VAL: 'Max Value',
        RATE: 'Rate',
        AVG_RATE: 'Average Rate'
    },
    PARAMETERS: [
        "Area Wise",
        "Distance Wise"
    ]
};

export const CALCULATOR_INFO_STRING = {
    BUTTONS: {
        ADD_INFO: 'Add Inforomation',
        ADD_NEW_STAMP: 'Add New Stamp Duty'
    },
    TABLE: {
        COLUMNS: {
            CALCULATOR_NAME: 'Calculator Name',
            INFO_ADDED: 'Information Added'
        },
        ROWS: {
            STAMP_DUTY: 'Stamp Duty Calculator',
            BORROWING_CAPACITY: 'Borrowing Capacity',
            REPAYMENT: 'Repayment Calculator',
            EARLY_REPAYMENT: 'Early Repayment Calculator'
        }
    },
    STAMP_DUTY: {
        TABS: {
            CALCULATOR: 'Stamp Duty Calculator',
            MANAGEMENT: 'Stamp Duty Management'
        },
        DETAIL_INFO: {
            BORROWING_CAPACITY_LBL: 'Borrowing Capacity Information',
            EARLY_REPAYMENT_LBL: 'Early Repayment Calculator Information',
            REPAYMENT_LBL: 'Repayment Calculator Information',
            STAMP_DUTY_LBL: 'Stamp Duty Calculator'
        },
        STAMP_DUTIES_LABEL: 'Stamp Duty Management',
        STATE: 'State',
        P_VALUE: 'Property Value',
        PAYBLE_DUTY: 'Payable Duty',
        ADD_EDIT: {
            ADD_LABEL: 'Add New Stamp Duty',
            EDIT_LABEL: 'Edit Stamp Duty',
            STAMP_DUTY_INFO: 'Stamp Duty Information',
            SELECT_STATE: 'Select State Name',
            MIN_AMOUNT_LBL: 'Min Amount Property Value',
            MAX_AMOUNT_LBL: 'Max Amount Property Value',
            FIXED_CHARGE_LBL: 'Stamp Duty - Fixed Charge',
            LIMIT_EXCEED_LBL: 'Stamp Duty - Limit exceed charge',
            PERCENTAGE_LBL: 'Percentage',
            DUTIABLE_VALUE_LBL: 'Dutible value limit'
        }
    },
    ADD_EDIT_LABEL: 'Add/Edit Information'
};

export const CITY_STATE_STRINGS = {
    BUTTONS: {
        ADD_COUNTRY: 'Add New Country',
        ADD_STATE: 'Add New State',
        ADD_CITY: 'Add New City',
        ADD_SUBURBS: 'Add New Suburbs',
        ADD_COUNCIL: 'Add New Councils'
    },
    TABS: {
        COUNTRY: 'Country',
        STATE: 'State',
        CITY: 'City',
        SUBURBS: 'Suburbs',
        COUNCIL: 'Councils'
    },
    Table: {
        COUNTRY: 'Country',
        STATE: 'State',
        CITY: 'City',
        SUBURBS: 'Suburbs',
        COUNCIL: 'Councils'
    },
    COUNTRY_PAGE: {
        ADD_LBL: 'Add New Country',
        EDIT_LBL: 'Edit Country',
        COUNTRY_NAME: 'Country Name',
        FINANCIAL_START_DATE: 'Financial Year Start Date',
        FINANCIAL_END_DATE: 'Financial Year End Date',
    },
    STATE_PAGE: {
        ADD_LBL: 'Add New State',
        EDIT_LBL: 'Edit State',
        STATE_NAME: 'Enter Single State'
    },
    CITY_PAGE: {
        ADD_LBL: 'Add New City',
        EDIT_LBL: 'Edit City',
        CITY_NAME: 'Enter Single City'
    },
    SUBURBS_PAGE: {
        ADD_LBL: 'Add New Suburb',
        EDIT_LBL: 'Edit Suburb',
        SUBURB_NAME: 'Enter Single Suburb',
        UPLOAD_IMAGE: 'Upload Suburb Image',
        DESCRIPTION: 'Suburb Description',
        MEDIUN_PRICE: 'Median Pricess',
        AUCTION_RATE: 'Auction Rate',
        TOTAL_LISTING: 'Total Listing'
    },
    COUNCIL_PAGE: {
        ADD_LBL: 'Add New Council',
        EDIT_LBL: 'Edit Council',
        COUNCIL_NAME: 'Enter Single Council'
    },
    SELECT_COUNTRY: 'Select Country',
    SELECT_STATE: 'Select State',
    SELECT_CITY: 'Select City',
    SELECT_SUBURBS: 'Select Suburbs'
};

export const PROPERY_TYPE_STRINGS = {
    PROPERTY_TYPE_LABEL: 'Property Types',
    ADD_PROPERTY_TYPE_BTN: 'Add New Property Type',
    ADD_TYPE_LABEL: 'Add New Property Type',
    EDIT_TYPE_LABEL: 'Edit Property Type',
    PROPERTY_TYPE: 'Property Type',
    SET_PROERTY_TYPE_LABEL: 'Type Property Type Name'
};

export const AVM_STRING = {
    FILTER: {
        PROPERTY_TYPE: 'Property Type',
        PROPERTY_VALUE: 'Property Value Range'
    },
    TABLE: {
        PROPERTY_LIST: 'Property List',
        PROPERTY_TYPE: 'Property Type',
        RECIEVED_DATE: 'Received Date',
        UPDATED_DATE: 'Updated Date',
        VALUE: 'Value',
        ACTIONS: 'Actions'
    }
}

export const REDEEM_REWARDS_STRINGS = {
    BUTTONS: {
        CREATE_BTN: 'Create New Service'
    },
    TABLE: {
        SERVICE_TYPE: 'Service type',
        TOTAL_REEDEM: 'Total Redeemed',
        PD_AMOUNT: 'PD Amount',
        START_DATE: 'Start Date',
        END_DATE: 'End Date',
        REFERROR: 'Referror',
        REFEREE: 'Referee',
        REDEEM_CODE: 'Redeem Code',
        CONTACT: 'Contact No.',
        REDEEM_DATE: 'Redeemed Date',
        STATE: 'State'
    },
    FIlter: {
        PD_AMOUNT: 'PD Amount'
    },
    TABS: {
        SERVICE_HISTORY: 'Service History',
        REDEEM_HISTORY: 'User History'
    },
    ADD_EDIT: {
        ADD_NEW_LABEL: 'Create New Service',
        EDIT_LABEL: 'Edit Service',
        SERVICE_NAME: 'Service Name',
        SUB_TEXT: 'Service Sub Text',
        SERVICE_DESC: 'Service Short Description',
        CATEGORIES: 'Category',
        PD_AMOUNT: 'PD Amount',
        START_DATE: 'Start Date',
        END_DATE: 'End Date',
        COUNTRY: 'Country',
        STATE: 'State',
        CITY: 'City',
        SUBURB: 'Suburb',
        UPLOAD_IMAGES: 'Upload Service Images',
        ACTIVE: 'Active',
        IS_PROPERTY_RELATED: 'Related to Property',
        SELECT_PLATFORM: 'Select Platform',
        TOTAL_REDEEMED: 'Total Redeemed',
        CATEGORY_LIST: 'Category List'
    },
    REDEEMED: {
        TABLE: {
            USERNAME: 'User Name',
            UID: 'Mobile',
            PROPERTY_LIST: 'Property List',
            SERVICE_CATEGORY: 'Service Category',
            SERVICE_NAME: 'Service Name',
            QTY: 'Qty',
            PD_AMOUNT: 'PD Amount',
            REDEEM_DATE: 'Redeem Date',
            EXPIRY_DATE: 'Expiry Date',
            ACTION_BY: 'Action By'
        }
    }
};

export const REDEEM_CATEGORIES_STRING = {
    HEADING: 'Service Category',
    TABLE: {
        CATEGORY: 'Service Category'
    },
    ADD_BTN: 'Add New Service Category',
    ADD_CATEGORY: 'Add Service Category',
    EDIT_CATEGORY: 'Edit Service Category',
    CATEGORY_NAME: 'Category Type Name'
}

export const NEWS_ARTICLES_STRING = {
    BUTTONS: {
        ADD_BUTTON: 'Add New News/Article'
    },
    TABS: {
        NEWS: 'News',
        ARTICLES: 'Articles'
    },
    LABEL: {
        TOP_NEWS_LABEL: 'Top 10 News of The Day'
    },
    ERROR: {
        NOT_FOUND: 'No single news found'
    },
    ADD_EDIT: {
        ADD_LABEL: 'Add New News/Article',
        EDIT_LABEL: 'Edit News/Article',
        TOP_STORY_LABEL: 'Top Story',
        SELECT_CATEGORY_LABEL: 'Select Category',
        ADD_HEADLINE_LABEL: 'Add Headline',
        ADD_LINK_LABEL: 'Add Link',
        ADD_DEC_LABEL: 'Add Description',
        EDIT_HEADLINE_LABEL: 'Edit Headline',
        EDIT_LINK_LABEL: 'Edit Link',
        EDIT_DEC_LABEL: 'Edit Description',
        UPLOAD_IMAGES: 'Upload Image',
        ADD_IMAGE: 'Add Image'
    }
};


export const STORIES_STRING = {
    BUTTONS: {
        ADD_BUTTON: 'Add New story'
    },
    HEADING: 'Top Stories',
    ADD_EDIT: {
        ADD_LABEL: 'Add New Top Story',
        EDIT_LABEL: 'Edit Top Story',
        ADD_HEADLINE_LABEL: 'Add Top Story',
        ADD_LINK_LABEL: 'Add Link',
        ADD_DEC_LABEL: 'Add Description',
        EDIT_HEADLINE_LABEL: 'Edit Headline',
        EDIT_LINK_LABEL: 'Edit Link',
        EDIT_DEC_LABEL: 'Edit Description',
        UPLOAD_IMAGES: 'Upload Image',
        ADD_IMAGE: 'Add Image'
    }
};

export const FAQ_STRINGS = {
    ADD_FAQ_BTN: 'Add New FAQ',
    ADD_EDIT: {
        ADD_LABEL: 'Add New FAQ',
        EDIT_LABEL: 'Edit FAQ',
        ADD_QUESTION: 'Add a Question',
        EDIT_QUESTION: 'Update a Question',
        TYPE_ANSWER: 'Type an Answer',
        EDIT_ANSWER: 'Edit an Answer'
    }
};

export const ADMIN_USER_STRINGS = {
    BUTTONS: {
        CREATE_ADMIN_BTN: 'Create New Admin User',
        CREATE_ROLE_BTN: 'Add New Role',
        ROLE_MANAGE_BTN: 'Role Management'
    },
    TABLE: {
        NAME: 'Name',
        EMAIL: 'Email Id',
        ROLE: 'Role',
        USERS: 'Users',
        DESCRIPTION: 'Description'
    },
    ADD_EDIT: {
        ADD_USER_LABEL: 'Add New User',
        EDIT_USER_LABEL: 'Edit User',
        ADD_ROLE_LABEL: 'Add New Role',
        EDIT_ROLE_LABEL: 'Edit Role',
        FIRST_NAME: 'First Name',
        LAST_NAME: 'Last Name',
        USERNAME: 'Username',
        EMAIL: 'Email Id',
        NEW_PASSWORD: 'New Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        PROFILE_IMG: 'Profile Image',
        SELECT_ROLE: 'Select Role',
        ROLE_NAME: 'Role Name',
        ROLE_DESCRIPTION: 'Role Description',
        ADMIN_PERMISSION: 'Admin Permission',
        ROLE_PERMISSION: 'Role Permissions',
        SELECT_PERMISSION: 'Section Permission',
        MODIFY_PERMISSION: 'Modify Property',
        DELETE_PERMISSION: 'Delete Property'
    }
};

export const REPORTS_TABS_STRINGS = {
    PROPERTIES: 'Properties',
    USERS: 'Users',
    CONTACTS: 'Contacts',
    EXPENSES: 'Expense',
    SERVICES: 'Services',
    BUDGETING: 'Budgeting Cash',
    TAX_LODGEMENT: 'Tax Lodgment',
    FINANCE: 'Finance',
    REWARDS: 'Rewards',
    REFERALS: 'Referral'
};

export const REPORTS_STRINGS = {
    BUTTONS: {
        EXPORT_PDF: 'PDF',
        EXPORT_EXCEL: 'Excel',
        EXPORT_CSV: 'CSV'
    },
    PROPERTIES: {
        TABLE: {
            PROPERTY_LIST: 'Property List',
            PROPERTY_TYPE: 'Property Type',
            PURCHASE_DATE: 'Purchase Date',
            VALIDATE_PROPERTY: 'Validate Property',
            STATUS: 'Status'
        }
    },
    USERS: {
        TABLE: {
            NAME: 'Name',
            EMAIL: 'Email',
            CONTACT: 'Contact No.',
            INACTIVE_DAYS: 'Inactive Days',
            PENDING_VALIDATE: 'Pending Validation'
        },
        FILTER: {
            PLATFORM: 'Platform',
            AGE_CATEGORY: 'Age Category',
            INACTIVE_DAYS: 'Inactive Days',
            PENDING_VALIDATION: 'Pending Property Validation Days',
            PENDING_ACTIVATION: 'Pending Activation Email/SMS Days'
        }
    },
    SERVICES: {
        TABLE: {
            USER_ID: 'User Id',
            NAME: 'Name',
            PROPERTY: 'Property',
            LOCATION: 'Location Field',
            Date: 'Date',
            SERVICE_TYPE: 'Service Type'
        },
        FILTER: {
            SERVICE_TYPE: 'Service Type'
        }
    },
    FINANCE: {
        TABLE: {
            USERNAME: 'User Name',
            PROPERTY_NAME: 'Property Name',
            OS_LOAN_AMT: 'Outstanding Loan Amount',
            EQTY_VALUE_AMT: 'Equity Value',
            STATE: 'State',
            TAX_REPORT_AVAILAING: 'Tax Report Feature Availing',
            INT_RATE: 'Interest Rate'
        },
        FILTER: {
            OS_LOAN_AMT: 'Outstanding Loan Amount',
            EQTY_VALUE_AMT: 'Equity Value Amount',
            INT_RATE: 'Interest Rate'
        }
    },
    REWARDS: {
        TABLE: {
            USERNAME: 'Username',
            PHONE: 'Mob No',
            TOTAL_REWARDS: 'Total Rewards',
            PENDING_AMT: 'Pending Amount',
            REDEEMED_AMT: 'Redeemed Amount',
            DISCARDED_AMT: 'Discarded Points',
            EXPIRED_AMT: 'Expired Amount'
        },
        FILTER: {
            PENDING_POINTS: 'Pending Points',
            REWARD_TYPE: 'Reward Type',
            REDEEM_POINTS: 'Redeem Points',
            DISCARDED_POINTS: 'Discarded Points',
            EXPIRED_POINTS: 'Expired Points',
            STATUS: 'Status',
        }
    },
    REFERAL: {
        TABLE: {
            REF_NAME: 'Referral Name',
            PHONE: 'Mob No',
            EMAIL: 'Email Id',
            REFER_BY: 'Referred By',
            PD_REFERRAL: 'PD / Referral',
            PD_REFEREE: 'PD / Referee',
            REFER_DATE: 'Refer Date',
            COMPLETION_DATE: 'Completion Date',
            STATUS: 'Status',
            REFERRAL_TYPES: 'Type of Referral'
        },
        FILTER: {
            PD_REFERAL: 'PD / Referral Range',
            CATEGORY: 'Category',
            PD_REFEREE: 'PD / Referre Range',
            REF_TYPES: 'Type of Referral'
        }
    },
    EXPENSES: {
        TOTAL_TITLE: 'Total Expense This Year'
    }
};

export const REFERRALS_STRINGS = {
    TABS: {
        APP_REFERRAL: 'App Referral',
        CURRENT_OFFER_AND_REWARD: 'Current Offers and Reward',
        USER_OFFER_LIST: 'User’s Offer List',
        SELECT_ALL: 'Select All',
        TARGET_USER: 'Taregetted Users'
    },
    BUTTONS: {
        CREATE_OFFER_BTN: 'Create New Offer'
    },
    APP_REFERRAL: {
        TABLE: {
            REFERRAL_NAME: 'Referee Name',
            REFERRED_BY: 'Referred By',
            CODE: 'Code',
            REDEEM_DATE: 'Redeem Date',
            REFERRAL_PD: 'PD / Referral',
            REFERREE_PD: 'PD / Referee',
            PLATFORM: 'Platform'
        },
        FILTER: {
            REFERRAL_BY: 'Referral by',
            REFERRAL_NAME: 'Referral Name',
            PD_REFERRAL: 'PD / Referral Range',
            PD_REFEREEE: 'PD / Referre Range'
        },
        PD_AMOUNT_BTN: '$ Set PD Reffering Amount'
    },
    CURRENT_OFFER: {
        TABLE: {
            COVER_IMAGE: 'Cover Image',
            TITLE: 'Title',
            DESCRIPTION: 'Description',
            REFERRAL_PD: COMMON_STRING.REFERRAL_PD,
            REFERREE_PD: COMMON_STRING.REFERREE_PD,
        },
        FILTER: {
            STATUS: 'Status'
        }
    },
    ADD_EDIT_OFFER: {
        CREATE_OFFER_LABEL: 'Create New Offer',
        EDIT_OFFER_LABEL: 'Edit Offer',
        TITLE: 'Title',
        SUB_TITLE: 'Sub Title',
        DESCRIPTION: 'Description',
        START_DATE: 'Start Date',
        END_DATE: 'End Date',
        REFERRAL_PD: COMMON_STRING.REFERRAL_PD,
        REFERREE_PD: COMMON_STRING.REFERREE_PD,
        CREATED_FOR: 'Created For',
        COVER_IMAGE: 'Offer Image',
        FULL_IMAGE: 'Description Image',
        SELECT_CATEGORY: 'Select Category',
        INTERNAL_MAIL: 'Internal Mail',
        EXTERNAL_MAIL: 'External Mail'
    },
    AVAILED_OFFER: {
        Filter: {
            COUNTRY: 'Country',
            STATE: 'State',
            SUBURB: 'Suburbs',
            ACTION: 'Action',
            PD_REFERRAL: 'PD / Referral Range',
            PD_REFREE: 'PD / Referre Range',
            ACTIONTYPE: ['Completed', 'Cancelled']
        },
        TABLE: {
            REFERREE_NAME: 'Referee Name',
            MOBILE: 'Mob. No.',
            EMAIL: 'Email',
            REFER_BY: 'Referred By',
            OFFER_TITLE: 'Offer Title',
            REFER_DATE: 'Refer Date',
            COMPLITION_DATE: 'Completion Date',
            EXPIRY_DATE: 'Expiry Date',
            PD_REFERROR: 'PD / Referror',
            PD_REFEREE: 'PD / Referee',
            ACTION_BY: 'Action By'
        }
    }
};

export const ADVERTISEMENTS_STRINGS = {
    BUTTON: {
        CREATE_NEW_BTN: 'Create New Advertisement'
    },
    TABLE: {
        COVER_IMAGE: 'Cover Image',
        TITLE: 'Title',
        CLIENT_URL: 'Click URL',
        iOS: 'iOS',
        ANDROID: 'Android',
        WEB: 'Web',
        ACTIVE: 'Active',
        DISPLAYED: 'Displayed',
        CLICKED: 'Clicked'
    },
    VIEW: {
        ADVERTISE_TITLE: 'Advertise Title',
        REDIRECT_URL: 'Redirect URL',
        ADVERTISEMENT_TYPE: 'Advertisement Type',
        TAB: 'Tab',
        SUB_TAB: 'Sub-Tab',
        SECTION: 'Section',
        ADD_IMAGE: 'Add Image',
        PLATFORM: 'Platform',
        ACTIVE: 'Active',
        IMPRESSION_COUNT: 'Impression Count',
        START_DATE: 'Start Date',
        END_DATE: 'End Date'
    },
    ADD_EDIT: {
        CREATE_LABEL: 'Create New Advertise',
        EDIT_LABEL: 'Edit Advertise',
        SET_BUDGET_LABEL: 'Set Budget',
        ADVERTISE_TITLE: 'Advertise Title',
        REDIRECT_URL: 'Redirect URL',
        ADVERTISEMENT_TYPE: 'Select Advertisement Type',
        SELECT_TAB: 'Select Tab',
        SELECT_SUB_TAB: 'Select Sub-Tab',
        SELECT_SECTION: 'Select Section',
        ADD_IMAGE: 'Ad Image',
        SELECT_PLATFORM: 'Select Platform',
        ACTIVE: 'Active',
        IMPRESSION_COUNT: 'Impression Count',
        START_DATE: 'Start Date',
        END_DATE: 'End Date',
        SELECT_ALL: 'Select All',
        TARGET_USER: 'Taregetted Users',
        SELECT_CATEGORY: 'Select Category'
    },
    ADVERTISEMENT_TYPES: {
        BOX: 'Box Advertisement',
        STICKY: 'Sticky Banner',
        SMALL: 'Small Banner',
        HALF: 'Half Screen Advertisement',
        FULL: 'Full Screen Advertisement'
    }
};

export const NOTIFICATIONS_STRINGS = {
    BUTTON: {
        CREATE_NEW_BTN: 'Create New Notification',
        NOTIFICATION_FILTER: 'Notification Filter'
    },
    TABLE: {
        NOTIFICATION_TITLE: 'Notification Title',
        DATE: 'Date',
        TIME: 'Time',
        IOS: 'iOS',
        ANDROID: 'Android',
        WEB: 'Web',
        STATUS: 'Status'
    },
    FILTER: {
        SCHEDULED: 'Scheduled',
        PUBLISHED: 'Published'
    },
    STEP1: {
        SELECT_NOTIFICATION_TYPE: 'Select Notification Type',
        GENERAL: 'General',
        PROPERTY_UPDATE: 'Property Update',
        SERVICE_UPDATE: 'Service Update',
        GOAL_REMINDER: 'Goal Reminder'
    },
    STEP2: {
        TITLE_LABEL: 'Enter Title Details & Select Target',
        NOTIFICATION_TITLE: 'Notification Title',
        NOTIFICATION_TEXT: 'Notification Text',
        NOTIFICATION_IMAGE: 'Notification Image',
        WHICH_PLATEFORM: 'Which platform you want to send notifications?'
    },
    STEP3: {
        TARGET_USER: 'Target Users To Send Notifications',
        SELECT_ALL: 'Select All',
        UPLOAD: 'Upload',
        CSV: 'CSV',
        EXCEL: 'Excel'
    },
    STEP4: {
        SCHEDULE_PUBLISH: 'Schedule or Publish',
        PUBLISH_NOW: 'Publish Now',
        SCHEDULE: 'Schedule',
        SELECT_DATE: 'Select Date',
        SELECT_TIME: 'Select Time',
        TIMEZONE: 'Timezone'
    }
}

export const FEEDBACK_STRINGS = {
    TABLE: {
        FEEDBACK: 'Feedback',
        DATE: 'Date',
        RATINGS: 'Ratings',
        WHAT_IMPROVEMENT: 'What Can Be Improved?'
    },
    FILTER: {
        RATINGS: 'Ratings',
        WHAT_IMPROVEMENT: 'What Can Be Improved?',
        DATE_RANGE: 'Date Range'
    }
};


export const PROPERTIES_CARD_STRINGS = {
    PROPERTY_NAME: 'Property Name',
    PROPERTY_TYPE: 'Property Type',
    VALIDATE: 'Validate',
    PURCHASE_DATE: 'Purchase Date',
    NO_OF_BED: 'No. of Bed',
    NO_OF_BATH: 'No. of Bath',
    COUNCIL: 'Council',
    STATUS: 'Status'
};

export const PROPERTY_GOALS_STRINGS = {
    TITLE: 'Property Goals',
    STAMP_DUTY_PERCENTAGE: 'Stamp Duty Percentage',
    USER_SAVING_PERCENTAGE: '% of savings User has against the Property Value',
    REPAYMENT_PERCENTAGE: 'Repayment Percentage',
    ESTIMATE_RENT_COVER_RATE: 'Estimated weekly rent cover rate '
}

export const PD_REFERRING = {
    PD_REFERRAL: 'PD Per Referral',
    PD_REFEREE: 'PD Per Referral',
    EXPIRY_PERIOD: 'Period of expiry'
}

export const LENDERS_STRINGS = {
    LABEL: 'Lender',
    TABLE: {
        LENDERS: 'Lenders',
        EDIT_TOOLTIP: 'Edit Lender',
        DELETE_TOOLTIP: 'Delete Lender'
    },
    BTN: {
        ADD_NEW_LANDER: 'Add New Lender',
    },
    ADDEDIT: {
        ADD_LABEL: 'Add New Lender',
        EDIT_LABEL: 'Edit Lender',
        NAME: 'Lender Name'
    }
}

export const AGENTS_STRINGS = {
    LABEL: 'Label',
    FILTER: {
        BUSINESS_TYPE: 'Business Type'
    },
    BUSINESS_TYPES: ['Mortgage Broker', 'Real Estate Agent', 'Insurance Agent', 'Buyers Agent'],
    TABLE: {
        BUSINESS_TYPE: 'Business Type',
        ORGANIZATION_NAME: 'Organization Name',
        ADDRESS: 'Address',
        CONTACT: 'Contact No.',
        BUSINESS_UNIT_NAME: 'Business Unit Name',
        ACTION: 'Actions'
    },
    BTN:{
        ADD_AGENT:'+ Add Agent',
        EDIT_AGENT: 'Edit Agent',
        DELETE_AGENT: 'Delete Agent',
        VIEW_AGENT: 'View Agent',
    },
    ADD_EDIT: {
        BUSINESS_TYPES: ['Mortgage Broker', 'Real Estate Agent', 'Insurance Agent', 'Buyers Agent'],
        VIEW_LABEL: 'View Agent',
        ADD_LABEL: 'Add an Agent',
        EDIT_LABEL: 'Edit Agent',
        BUSINESS_TYPE: 'Business Type',
        ADD_MORE: '+ Add More',
        ORG_DETAIL: 'Organization details',
        ORG_NAME: 'Name',
        ORG_ABN: 'ABN',
        ORG_ADDRESS: 'Address',
        ORG_CONTACT: 'Contact No.',
        BUSN_UNT_DETAIL: 'Business Unit Details',
        BUSN_NAME: 'Name',
        BUSN_ADDRESS: 'Address',
        BUSN_CONTACT: 'Contact No.',
        BUSN_EMAIL: 'Email Id',
        BUSN_CONTRACT_START: 'Contract Start Date',
        BUSN_CONTRACT_END: 'Contract End Date',
        BUSN_NOTES: 'Notes',
        TEAM_DETAILS: 'Team Member',
        TEAM_NAME: 'Team Member',
        TEAM_EMAIL: 'Team Email',
        TEAM_LICENSE: 'How many license are allowed?',
        TEAM_DEFAULT_PASS: 'Default Password',
        TEAM_CONFIRM_PASS: 'Confirm Password'
    }
}

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);