import { methods, serviceMaker } from '../service';

const GET_RENTAL_INCOMES = (
  limit,
  skip,
  propertyId,
  search = null,
  sort = null,
  startDate = null,
  endDate = null,
  filterYear = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }

  if (startDate) {
    query['params']['startDate'] = new Date(startDate);
  }

  if (endDate) {
    query['params']['endDate'] = new Date(endDate);
  }

  if (filterYear) {
    query['params']['filterYear'] = filterYear;
  }

  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/rental-income?propertyId=${propertyId}`, methods.GET, query);
  }
  return serviceMaker(`rental-income?propertyId=${propertyId}`, methods.GET, query);
}

const POST_RENTAL_INCOMES = () => {
  //
}

const PATCH_RENTAL_INCOMES = () => {
  //
}

const DELETE_RENTAL_INCOMES = (rentalIncomeId) => {
  return serviceMaker(`rental-income/${rentalIncomeId}`, methods.DELETE);
}

const RENTAL_INCOMES = {
  GET: GET_RENTAL_INCOMES,
  POST: POST_RENTAL_INCOMES,
  PATCH: PATCH_RENTAL_INCOMES,
  DELETE: DELETE_RENTAL_INCOMES,
};
export { RENTAL_INCOMES };