import { methods, serviceMaker } from "../service";

const FIND_REDEEM_HISTORY = (
    limit,
    skip,
    search = null,
    sort = null
) => {
    const query = {
        params: {
            $limit: limit,
            $skip: skip
        },
    };

    if (sort) {
        query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
    } else {
        query['params']['$sort[createdAt]'] = -1;
    }

    if (search) {
        query['params']['search'] = search;
    }

    return serviceMaker(`redeemed`, methods.GET, query);
};

const PATCH_REDEEM_HISTORY = (id, payload) => serviceMaker(`redeemed/${id}`, methods.PATCH, null, payload);

const REDEEM_HISTORY = {
    FIND: FIND_REDEEM_HISTORY,
    PATCH: PATCH_REDEEM_HISTORY
}

export { REDEEM_HISTORY };