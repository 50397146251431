import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Form, Input, Row, Select, Table, Tooltip } from 'antd';
import { COMMON_ALERTS, COMMON_STRING, FILTER_STRING, REFERRALS_STRINGS, TABLE_STRING } from '../../services/strings';
import PaginationCom from '../../components/PaginationCom';
import { tableSortIconToggle } from '../../utility/tableSorting';
import DeleteModel from '../../components/DeleteModel';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import FormItem from 'antd/es/form/FormItem';
import PropTypes from 'prop-types';
import { OFFERES } from '../../services/offers/offerService';
import SelectArrow from '../../components/Icon/SelectArrow';
import CalenderDarkIcon from '../../components/Icon/CalenderDarkIcon';
import FilterToogleBtn from '../../components/FilterToogleBtn';
import AppButton from '../../components/AppButton';
import BackIcon from '../../components/Icon/BackIcon';
import PencileIcon from '../../components/Icon/PencileIcon';
import DeleteIcon from '../../components/Icon/DeleteIcon';
import { BASE_URL } from '../../services/constant';

const OfferListPage = ({ permissions }) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [Offers, setOffers] = useState(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [sort, setSort] = useState(null);
    const [tableDataLength, setTableDataLength] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState(null);
    const [tempSearch, setTempSearch] = useState(null);
    const [FilterStartDate, setFilterStartDate] = useState(null);
    const [FilterEndDate, setFilterEndDate] = useState(null);
    const [isFilterShow, setIsFilterShow] = useState(false);

    const onChangePage = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const onChange = (pagination, filters, sorter, extra) => {
        if (sorter?.order === 'ascend') {
            setSort({ [sorter.field]: 1 });
        } else if (sorter?.order === 'descend') {
            setSort({ [sorter.field]: -1 });
        } else {
            setSort({ createdAt: -1 });
        }
    };

    const deleteOffer = async () => {
        try {
            const res = await OFFERES.DELETE(deleteId);
            if (res.status) {
                toast.success(COMMON_ALERTS.DELETED_SUCCESS('Offer'));
                setIsDeleteModalVisible(false);
                fetchOffer(currentPage, pageSize, search, sort);
            }
        }
        catch (e) {
            // console.log(e);
        }
    }

    const fetchOffer = async (
        page = 1,
        pageSize = 10,
        search = null,
        sort = null
    ) => {
        setTableLoading(true);
        try {
            const res = await OFFERES.GET(
                pageSize,
                (page - 1) * pageSize,
                search,
                sort
            );
            if (res.status === 1 || res.status === '1') {
                setOffers(res);
                setTableDataLength(res?.data && res?.data.length);
            }
        } catch (err) {
            setTableLoading(false);
            toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
        } finally {
            setTableLoading(false);
        }
    };

    const SampleMenu = [
        {
            name: 'ABC'
        }
    ]
    // eslint-disable-next-line react/prop-types
    const OfferImg = ({ src }) => {
        return <div className='offerCoverImg' style={{ background: "#EBF3F5", borderRadius: "5px", height: '80px', width: '100px' }}><img src={src} /></div>;
    }

    useEffect(() => {
        fetchOffer(currentPage, pageSize, search, sort);
    }, [currentPage, pageSize, search, sort]);

    const columns = [
        {
            title: (column) => REFERRALS_STRINGS.CURRENT_OFFER.TABLE.COVER_IMAGE,
            dataIndex: 'coverImage',
            key: 'coverImage',
            align: 'left',
            sorter: false,
            width: 100,
            render: (value) => value? <OfferImg src={BASE_URL.slice(0, -1) + value?.image} />: null,
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.CURRENT_OFFER.TABLE.TITLE}</span>, 'Tidsafsaftle', column),
            dataIndex: 'title',
            key: 'Title',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            width: 200,
            render: (value) =>
                value ? <span className="tabletext">{value}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.CURRENT_OFFER.TABLE.DESCRIPTION}</span>, 'desc', column),
            dataIndex: 'subTitle',
            key: 'subTitle',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            width: 200,
            render: (value) =>
                value ? <span className="tabletext">{value}</span> : '-'
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.CURRENT_OFFER.TABLE.REFERRAL_PD}</span>, 'pdReferral', column),
            dataIndex: 'pdReferral',
            key: 'pdReferral',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">${value}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.CURRENT_OFFER.TABLE.REFERREE_PD} </span>, 'pdReferee', column),
            dataIndex: 'pdReferee',
            key: 'pdReferee',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">${value}</span> : '-',
        },
        {
            title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
            width: 150,
            key: 'Actions',
            align: 'center',
            dataIndex: 'Actions',
            render: (item, record) => {
                return (
                    <div className='actionCol'>
                        {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
                            <Tooltip title='Edit Offer'>
                                <PencileIcon
                                    onClick={() => navigate('/referrals/offer/edit', { state: { offerId: record.id } })}
                                    className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
                            </Tooltip>
                        )}
                        {(permissions?.isDelete || permissions?.isDelete === undefined) && (
                            <Tooltip title='Delete Offer'>
                                <DeleteIcon
                                    onClick={() => {
                                        setIsDeleteModalVisible(true);
                                        setDeleteId(record?.id);
                                    }}
                                    className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                                />
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <div className='commonWrapperLayout'>
            <div className="commonHeader">
                <div className="commonHeaderLeft">
                    <button className='iconBtn' onClick={() => navigate(-1)}><BackIcon /></button>
                </div>
                <div className="commonHeaderRight">
                    <AppButton className={'referralListBtn'} onClick={() => navigate('/referrals/offer/add')} text='Create New Offer' />
                    <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} />
                </div>
            </div>

            <div className={isFilterShow ? 'fWidthShowBox pRentalIncomePage mb-3' : 'fWidthShowBox pRentalIncomePage d-none'}>
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form name="complex-form" form={form} onFinish={{}} labelCol={{ span: 4 }} colon={false} wrapperCol={{ span: 16 }}>
                            <FormItem
                                label={<span className='inputTitle'>{FILTER_STRING.START_DATE}</span>}>
                                <DatePicker className='searchInpExpense' suffixIcon={<CalenderDarkIcon />} onChange={value => setFilterStartDate(value)} disabledDate={d => !d || d.isAfter(FilterEndDate)} />
                            </FormItem>
                            <FormItem
                                label={<span className='inputTitle'>{FILTER_STRING.END_DATE}</span>}>
                                <DatePicker className='searchInpExpense' suffixIcon={<CalenderDarkIcon />} onChange={value => setFilterEndDate(value)} disabledDate={d => !d || d.isSameOrBefore(FilterStartDate)} />
                            </FormItem>
                            <FormItem
                                label={<span className='inputTitle'>{FILTER_STRING.SEARCH}</span>}>
                                <Input className='searchInpExpense' placeholder={COMMON_STRING.SEARCH_STR + 'Referral'} />
                            </FormItem>
                        </Form>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <button onClick={() => navigate('/referrals/offer/add')} className="addPAgeNavigateBtn">{REFERRALS_STRINGS.BUTTONS.CREATE_OFFER_BTN}</button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="d-flex filterBox">
                                <div className="filterSelect">
                                    <Select defaultValue={FILTER_STRING.PLATFORM} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{SampleMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
                                </div>
                                <div className="filterSelect">
                                    <Select defaultValue={FILTER_STRING.STATE} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{SampleMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
                                </div>
                                <div className="filterSelect">
                                    <Select defaultValue={FILTER_STRING.STATUS} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{SampleMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table
                        columns={columns}
                        dataSource={Offers?.data}
                        pagination={false}
                        rowKey='id'
                        loading={tableLoading}
                        onChange={onChange}
                        scroll={{ x: 400 }}
                    />

                    {Offers?.total > 10 && (
                        <div className='text-end bg-white mt-7'>
                            <PaginationCom
                                onChange={onChangePage}
                                showSizeChanger={true}
                                current={currentPage}
                                showLessItems={true}
                                total={Offers?.total}
                            />
                        </div>
                    )}
                </Col>
            </Row>
            <DeleteModel
                isDeleteModalVisible={isDeleteModalVisible}
                deleteModel={deleteOffer}
                setIsDeleteModalVisible={setIsDeleteModalVisible}
            />
        </div>
    );
}

OfferListPage.propTypes = {
    permissions: PropTypes.any
}

export default OfferListPage;