import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Dropdown, Menu, Row, Table, Tooltip } from 'antd';
import { APP_USERS } from '../../services';
import { tableSortIconToggle } from '../../utility/tableSorting';
import { CONTACT_DETAILS } from '../../services/ContactDetails/ContactDetails';
import { PROPERTIES } from '../../services/Property/Properties';
import { COMMON_ALERTS, TABLE_STRING, USER_DETAILS_STRINGS } from '../../services/strings';
import { toast } from 'react-toastify';
import DeleteModel from '../../components/DeleteModel';
import DeleteIcon from '../../components/Icon/DeleteIcon';
import PaginationCom from '../../components/PaginationCom';
import InfoCircleIcon from '../../components/Icon/InfoCircleIcon';

const AppusersDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [appuser, setappuser] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleteContactModalVisible, setIsDeleteContactModalVisible] = useState(false);
  const [contactDeleteId, setContactDeleteId] = useState(null);
  const permissions = location.state?.permissions;
  const [ContactData, setContactData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [currentPageProperty, setcurrentPageProperty] = useState(1);
  const [PropertyData, setPropertyData] = useState([]);
  const [propertyTableLoading, setPropertyTableLoading] = useState(true);
  const [currentPageContact, setcurrentPageContact] = useState(1);
  const [PageSizeProperty, setPageSizeProperty] = useState(10);
  const [sortProperty, setSortProperty] = useState(null);
  const [PageSizeContact, setPageSizeContact] = useState(10);
  const [sortContacts, setSortContacts] = useState(null);

  useEffect(() => {
    fetchAppuser();
    return () => {
      setappuser(null);
    };
  }, []);

  const deleteProperties = async () => {
    try {
      const res = await PROPERTIES.DELETE(deleteId);

      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        toast.success(res?.message);
        fetchProperties();
      }
    } catch (err) {
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    }
  };

  const deleteContacts = async () => {
    const res = await CONTACT_DETAILS.DELETE(contactDeleteId);
    setTableLoading(null);
    if (res.status) {
      toast.success(COMMON_ALERTS.DELETED_SUCCESS('contact'))
      fetchContacts();
      setIsDeleteContactModalVisible(false);
      setTableLoading(false);
    }
  };

  const fetchAppuser = async () => {
    if (!location.state) {
      navigate('/users');
    }
    const appusr = await APP_USERS.GETID(location.state.userId);
    if (appusr && appusr.data) {
      setappuser(appusr.data);
    }
  };

  const fetchContacts = async (page = 1,
    pageSize = 10,
    search = null) => {
    const contacts = await CONTACT_DETAILS.GETBYUSERID(pageSize,
      (page - 1) * pageSize,
      search,
      sortContacts,
      location.state.userId);
    if (Array.isArray(contacts.data)) {
      setContactData(contacts);
    }
    setTableLoading(false);
  };

  const fetchProperties = async (page = 1,
    pageSize = 10,
    search = null,
    sort = null) => {
    const properties = await PROPERTIES.GETBYUSERID(
      pageSize,
      (page - 1) * pageSize,
      search,
      sort,
      location.state.userId);
    if (properties.data && properties.data.length) {
      setPropertyData(properties);
    }
    setPropertyTableLoading(false);
  };
  const menu = (record) => (
    <Menu
      items={[
        {
          label: (
            <span><b>{USER_DETAILS_STRINGS.CONTACT_DETAILS.CONTACT_NAME}</b> - {record.name}</span>
          ),
        },
        {
          label: (
            <span><b>{USER_DETAILS_STRINGS.CONTACT_DETAILS.CONTACT_MOBILE}</b> - {record.contact_mobile}</span>
          ),
        },
        {
          label: (
            <span><b>{USER_DETAILS_STRINGS.CONTACT_DETAILS.CONTACT_OFFICE} </b> - {record.contact_office}</span>
          ),
        },
        {
          label: (
            <span><b>{USER_DETAILS_STRINGS.CONTACT_DETAILS.CONTACT_OFFICE} </b> - {record.contact_other}</span>
          ),
        },
        {
          label: (
            <span><b>{USER_DETAILS_STRINGS.CONTACT_DETAILS.EMAIL}</b> - {record.email}</span>
          ),
        },
        {
          label: (
            <span><b>{USER_DETAILS_STRINGS.CONTACT_DETAILS.PROFESSION}</b> - {record?.professionType?.name}</span>
          ),
        },
      ]} />
  );

  const contactColumns = [
    {
      title: (column) => tableSortIconToggle(USER_DETAILS_STRINGS.CONTACT_DETAILS.CONTACT_NAME, 'name', column),
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tableTitleUser">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(USER_DETAILS_STRINGS.CONTACT_DETAILS.CONTACT_MOBILE, 'email', column),
      key: 'contact',
      dataIndex: 'contact_mobile',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? value : '-'),
    },
    {
      title: 'Actions',
      key: 'Actions',
      align: 'center',
      dataIndex: 'Actions',
      width: 120,
      render: (item, record) => {
        return (
          <div className='actionCol'>
            <Dropdown overlayStyle={{ backgroundColor: 'red' }} overlayClassName="userContactDetails" overlay={menu(record)}>
              <InfoCircleIcon
                className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
            </Dropdown>
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete User'>
                <DeleteIcon
                  onClick={() => {
                    setIsDeleteContactModalVisible(true);
                    setContactDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const propertyColumns = [
    {
      title: (column) => tableSortIconToggle(USER_DETAILS_STRINGS.PROPERTIES.NAME, 'name', column),
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? value.charAt(0).toUpperCase() + value.slice(1) : '-',
    },
    {
      title: (column) => tableSortIconToggle(USER_DETAILS_STRINGS.PROPERTIES.TYPE, 'property_type', column),
      key: 'property_type',
      dataIndex: 'property_type',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? value.name : '-'),
    },
    {
      title: (column) => tableSortIconToggle(USER_DETAILS_STRINGS.PROPERTIES.PURCHASE_DATE, 'purchaseDate', column),
      key: 'purchaseDate',
      dataIndex: 'purchaseDate',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? value : '-'),
    },
    {
      title: TABLE_STRING.ACTION,
      key: 'Actions',
      align: 'center',
      dataIndex: 'Actions',
      width: 70,
      render: (column, record) => {
        return (
          <div className='actionCol'>
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Propery'>
                <DeleteIcon
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const onChangePageContact = (page, pageSize) => {
    setcurrentPageContact(page);
    setPageSizeContact(pageSize);
  };

  const onChangeContact = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      setSortContacts({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSortContacts({ [sorter.field]: -1 });
    } else {
      setSortContacts({ createdAt: -1 });
    }
  };

  useEffect(() => {
    fetchContacts(currentPageContact, PageSizeContact, null, sortContacts);
  }, [currentPageContact, PageSizeContact, sortContacts]);

  useEffect(() => {
    fetchProperties(currentPageProperty, PageSizeProperty, null, sortProperty);
  }, [currentPageProperty, PageSizeProperty, sortProperty]);


  const onChangePageProperty = (page, pageSize) => {
    setcurrentPageProperty(page);
    setPageSizeProperty(pageSize);
  };

  const onChangeProperty = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      setSortProperty({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSortProperty({ [sorter.field]: -1 });
    } else {
      setSortProperty({ createdAt: -1 });
    }
  };


  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="userDetailsBox">
          <h2 className='font-weight-700 mb-0 title-header'>{USER_DETAILS_STRINGS.USER_DETAILS.USER_DETAIL}</h2>
          <Row>
            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 10, offset: 7 }} lg={{ span: 10, offset: 7 }} xl={{ span: 10, offset: 7 }} >
              <Row className='inputTitleBox'>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}><span className="inputTitle">{USER_DETAILS_STRINGS.USER_DETAILS.FIRST_NAME}</span></Col>
                <Col xs={24} sm={24} md={16} lg={16} xl={16}><input type="text" placeholder='First Name not set' className="detailInput" value={appuser?.firstname} readOnly /></Col>
              </Row>
              <Row className='inputTitleBox'>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}><span className="inputTitle">{USER_DETAILS_STRINGS.USER_DETAILS.LAST_NAME}</span></Col>
                <Col xs={24} sm={24} md={16} lg={16} xl={16}><input type="text" placeholder='Last Name not set' className="detailInput" value={appuser?.lastname} readOnly /></Col>
              </Row>
              <Row className='inputTitleBox'>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}><span className="inputTitle">{USER_DETAILS_STRINGS.USER_DETAILS.POSTAL_ADDRESS}</span></Col>
                <Col xs={24} sm={24} md={16} lg={16} xl={16}><input type="text" placeholder='Postal Address not set' className="detailInput" value={appuser?.currentAddress} readOnly /></Col>
              </Row>
              <Row className='inputTitleBox'>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}><span className="inputTitle">{USER_DETAILS_STRINGS.USER_DETAILS.EMAIL}</span></Col>
                <Col xs={24} sm={24} md={16} lg={16} xl={16}><input type="text" placeholder='Email Id not set' className="detailInput" value={appuser?.email} readOnly /></Col>
              </Row>
              <Row className='inputTitleBox'>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}><span className="inputTitle">{USER_DETAILS_STRINGS.USER_DETAILS.CONTACT_NO}</span></Col>
                <Col xs={24} sm={24} md={16} lg={16} xl={16}><input type="text" placeholder='Email Id not set' className="detailInput" value={appuser?.phoneNumber ? '+' + appuser?.countryCode + appuser?.phoneNumber : 'Phone not set'} readOnly /></Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="userDetailsBox">
          <h2 className='font-weight-700 mb-0 title-header'>{USER_DETAILS_STRINGS.USER_SAVED_CONTACT}</h2>
          <Table
            columns={contactColumns}
            dataSource={ContactData.data}
            pagination={false}
            rowKey='id'
            loading={tableLoading}
            onChange={onChangeContact}
            className="mt-3"
            scroll={{ x: 400 }}
          />
          {ContactData?.total > 10 && (
            <div className='text-end bg-white mt-7 c-details'>
              <PaginationCom
                onChange={onChangePageContact}
                showSizeChanger={true}
                current={currentPageContact}
                showLessItems={true}
                total={ContactData?.total}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="userDetailsBox">
          <h2 className='font-weight-700 mb-0 title-header'>{USER_DETAILS_STRINGS.USERS_PROPERTY}</h2>
          <Table
            columns={propertyColumns}
            dataSource={PropertyData.data}
            pagination={false}
            rowKey='id'
            loading={propertyTableLoading}
            onChange={onChangeProperty}
            className="mt-3"
            scroll={{ x: 400 }}
          />
          {PropertyData?.total > 10 && (
            <div className='text-end bg-white mt-7 c-details'>
              <PaginationCom
                onChange={onChangePageProperty}
                showSizeChanger={true}
                current={currentPageProperty}
                showLessItems={true}
                total={PropertyData?.total}
              />
            </div>
          )}
        </Col>
      </Row>
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteProperties}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
      <DeleteModel
        isDeleteModalVisible={isDeleteContactModalVisible}
        deleteModel={deleteContacts}
        setIsDeleteModalVisible={setIsDeleteContactModalVisible}
      />
    </div>
  );
};

export default AppusersDetails;
