import { Col, Row, Steps } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NOTIFICATIONS } from '../../../services/Notification/notificationService';
import EnterDetailNotification from './steps/EnterDetailNotification';
import NotificationType from './steps/NotificationType';
import PublishNotificationStep from './steps/PublishNotificationStep';
import TargetUsersStep from './steps/TargetUsersStep';
import { setLSItem } from '../../../services/auth/encryptLogin';
import { BASE_URL } from '../../../services/constant';
const { Step } = Steps;

const AddEditNotification = () => {
    const [current, setCurrent] = useState(0);
    const notificationId = useLocation().state?.notificationId;
    const [Notification, setNotification] = useState(null);

    const changeTab = (value) => {
        setCurrent(value);
    };

    const getNotification = async (notificationId) => {
        const res = await NOTIFICATIONS.FIND(notificationId);
        if (res.status) {
            const notification = res.data;
            setNotification(notification);
            const platform = [];
            if (notification?.plateform_android) platform.push('android');
            if (notification?.plateform_ios) platform.push('ios');
            if (notification?.plateform_web) platform.push('web');

            const targetUser = { "targetUser": ((notification?.excelFileId || notification?.csvFileId) ? "upload" : "all"), "excelFileId": notification?.excelFileId, "csvFileId": notification?.csvFileId, csvFileList: [], excelFileList: [] };

            if (notification.csvFile) {
                targetUser.csvFileList.push({ ...notification.csvFile, "uid": "rc-upload-1668434404511-6", "name": "CSV File" });
            }

            if (notification.excelFile) {
                targetUser.excelFileList.push({ ...notification.excelFile, "uid": "rc-upload-1668434404511-6", "name": "Excel File" });
            }
            
            notification.notificatonImage['file'] = notification?.notificatonImage.image;
            const imageExtension = notification?.notificatonImage?.path?.split('.');
            setLSItem('notification-step-1', JSON.stringify({ "notification_type": notification?.type }));
            setLSItem('notification-step-2', JSON.stringify({ "mediaItemId": notification?.notificatonImageId, "fileList": [{ ...notification?.notificatonImage, thumbUrl: await getBase64FromUrl(BASE_URL.slice(0, -1)+notification?.notificatonImage.image), name: 'Uploaded.'+imageExtension[1], response: { data: { media: notification?.notificatonImage } } }], "platform": platform, "text": notification?.text, "title": notification?.title }));
            setLSItem('notification-step-3', JSON.stringify(targetUser));
            setLSItem('notification-step-4', JSON.stringify({ "scheduled_at": notification.scheduled_date, timezone: notification.timezone }));
        }
    };

    const getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }


    useState(() => {
        if (notificationId)
            getNotification(notificationId);
    });

    const Tabs = [
        <NotificationType key={1} notification={Notification} changeTab={changeTab} />,
        <EnterDetailNotification key={2} changeTab={changeTab} />,
        <TargetUsersStep key={3} changeTab={changeTab} />,
        <PublishNotificationStep key={4} changeTab={changeTab} />
    ]

    return (
        <div className='addNotificationLayout'>
            <Row>
                <Col xs={24} sm={24} md={{ span: 18, offset: 3 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }} >
                    <div className="notificationSteps">
                        <Steps current={current} onChange={changeTab}>
                            <Step stepIcon={() => <>1</>} className='notificationSteps' title="Select Notification Type" stepNumber={1} />
                            <Step stepIcon={() => <>2</>} disabled={current < 1} className='notificationSteps' title="Enter Details" stepNumber={2} />
                            <Step stepIcon={() => <>3</>} disabled={current < 2} className='notificationSteps' title="Targetted Users" stepNumber={3} />
                            <Step stepIcon={() => <>4</>} disabled={current < 3} className='notificationSteps' title="Publish" stepNumber={4} />
                        </Steps>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="fWidthShowBox notificationContentBx">
                    {Tabs[current]}
                </Col>
            </Row>
        </div>
    );
}

export default AddEditNotification;
