import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Form, Input, Row, Select, Slider, Table, Tooltip } from 'antd';
import { COMMON_STRING, FILTER_STRING, REFERRALS_STRINGS, TABLE_STRING } from '../../services/strings';
import { tableSortIconToggle } from '../../utility/tableSorting';
import SelectArrow from '../../components/Icon/SelectArrow';
import CalenderDarkIcon from '../../components/Icon/CalenderDarkIcon';
import FilterToogleBtn from '../../components/FilterToogleBtn';
import AppButton from '../../components/AppButton';
import PaginationCom from '../../components/PaginationCom';
import { AVAIL_OFFERS } from '../../services/offers/offerReferrals';
import ApproveIcon from '../../components/Icon/ApproveIcon';
import DiscardIcon from '../../components/Icon/DiscardIcon';
import ApprovedIcon from '../../components/Icon/ApprovedIcon';
import DiscardedIcon from '../../components/Icon/DiscardedIcon';
import CommentModal from '../../components/Modal/CommentModal';
import { useNavigate } from 'react-router-dom';
import FormItem from 'antd/es/form/FormItem';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DROPDOWN } from '../../services/Dropdown/dropdowns';

const OfferAvailsListPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [AppAvails, setAppAvails] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [Filter, setFilter] = useState({ $limit: 10, $skip: 0, page: 1, search: null, sort: null, sDate: null, eDate: null, country: null, state: null, suburb: null, action: null, pd_referral: [0, 500], pd_referee: [0, 500] });
  const [tempSearch, setTempSearch] = useState(null);
  const [confirmId, setconfirmId] = useState(null);
  const [cancelId, setCancelId] = useState(null);
  const [Countries, setCountries] = useState([]);
  const [States, setStates] = useState([]);
  const [Suburbs, setSuburbs] = useState([]);
  const [isCommentModelOpen, setIsCommentModelOpen]  = useState(false);
  const marks = { 0: '$0', 500: '$500'};

  const onClickConfirm = (id) => {
    setconfirmId(id);
    setIsCommentModelOpen(true);
  }

  const onClickCancel = (id) => {
    setCancelId(id);
    setIsCommentModelOpen(true);
  }

  const onSubmitComment = (comment) => {
    if(confirmId){
      confirmAvail(comment);
    }
    else if(cancelId){
      cancelAvail(comment);
    }
    
  }

  const onChangePage = (page, pageSize) => {
    setFilter(prev => ({
      ...prev,
      $limit: pageSize,
      $skip: (page - 1) * pageSize,
      page
    }));
  };

  const onChange = (pagination, filters, sorter, extra) => {
    const sort = {};
    if (sorter?.order === 'ascend') {
      Object.assign(sort, { [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      Object.assign(sort, { [sorter.field]: -1 });
    } else {
      Object.assign(sort, { createdAt: 1 });
    }
    onFilterChange("sort", sort)
  };

  const fetchDropDowns = async () => {
    const countriesRes = await DROPDOWN.GET_COUNTRIES();
    if (countriesRes.status) {
      setCountries(countriesRes.data);
    }
    const statesRes = await DROPDOWN.GET_STATES();
    if (statesRes.status) {
      setStates(statesRes.data);
    }
    const suburbRes = await DROPDOWN.GET_SUBURBS();
    if (suburbRes.status) {
      setSuburbs(suburbRes.data);
    }
  }

  const onFilterChange = (name, val) => {
    setFilter(prev => ({
      ...prev,
      [name]: val === '' ? null : val
    }));
  }

  const confirmAvail = async (comment) => {
    const res = await AVAIL_OFFERS.PATCH(confirmId, {
      offerStatus: 'accepted',
      comment
    });
    if (res.status) {
      toast.success('Availed offer has been successfully confirmed!');
      fetchData();
      setCancelId(null);
      setconfirmId(null);
    }
    else {
      toast.error(res.message);
    }
  }

  const cancelAvail = async (comment) => {
    const res = await AVAIL_OFFERS.PATCH(cancelId, {
      offerStatus: 'cancelled',
      comment
    });
    if (res.status) {
      toast.success('Availed offer has been successfully cancelled!');
      fetchData();
      setCancelId(null);
      setconfirmId(null);
    }
    else {
      toast.error(res.message);
    }
  }

  const fetchData = async () => {
    const res = await AVAIL_OFFERS.FIND(Filter);
    setAppAvails(res);
    setTableLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [Filter]);

  useEffect(()=> {
    fetchDropDowns();
  }, [])

  const columns = [
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.AVAILED_OFFER.TABLE.REFERREE_NAME}</span>, 'appuser', column),
      dataIndex: 'referrorName',
      key: 'referrorName',
      align: 'left',
      sorter: AppAvails?.total > 1 ? true : false,
      width: 200, 
      sortDirections: ['ascend', 'descend'],
      render: (value, record) =>
      record?.appuser ? <span className="tabletext">{record?.appuser?.name}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.AVAILED_OFFER.TABLE.MOBILE}</span>, 'appuser', column),
      dataIndex: 'referorMobile',
      key: 'referorMobile',
      align: 'left',
      width: 200,
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) =>
        record?.appuser ? <span className="tabletext">{record?.appuser?.phoneNumber}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.AVAILED_OFFER.TABLE.EMAIL}</span>, 'appuser', column),
      dataIndex: 'appuserEmail',
      key: 'appuserEmail',
      align: 'left',
      width: 250,
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) =>
        record?.appuser ? <span className="tabletext">{record?.appuser?.email}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.AVAILED_OFFER.TABLE.REFER_BY}</span>, 'appuser', column),
      dataIndex: 'referBy',
      key: 'referBy',
      align: 'left',
      width: 200,
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) =>
        record?.referBy ? <span className="tabletext">{record?.referBy?.name}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.AVAILED_OFFER.TABLE.OFFER_TITLE}</span>, 'offer', column),
      dataIndex: 'offerName',
      key: 'offerName',
      align: 'left',
      width: 200,
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) =>
        record?.offer ? <span className="tabletext">{record?.offer?.title}</span> : '-',
    },
    
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.AVAILED_OFFER.TABLE.REFER_DATE}</span>, 'referDate', column),
      dataIndex: 'referDate',
      key: 'referDate',
      align: 'left',
      width: 200,
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{moment(value).format('DD/MM/YYYY')}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.AVAILED_OFFER.TABLE.COMPLITION_DATE}</span>, 'referedAt', column),
      dataIndex: 'referedAt',
      key: 'referedAt',
      align: 'left',
      width: 200,
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{moment(value).format('DD/MM/YYYY')}</span> : '-',
    },
    
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.AVAILED_OFFER.TABLE.EXPIRY_DATE}</span>, 'expireAt', column),
      dataIndex: 'expireAt',
      key: 'expireAt',
      align: 'left',
      width: 200,
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{moment(value).format('DD/MM/YYYY')}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.AVAILED_OFFER.TABLE.PD_REFEREE}</span>, 'pdReferee', column),
      dataIndex: 'pdReferee',
      key: 'pdReferee',
      align: 'left',
      width: 180,
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        <span className="tabletext">${value || 0}</span>,
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.AVAILED_OFFER.TABLE.PD_REFERROR}</span>, 'pdReferral', column),
      dataIndex: 'pdReferral',
      key: 'pdReferral',
      align: 'left',
      width: 180,
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        <span className="tabletext">${value || 0}</span>,
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.AVAILED_OFFER.TABLE.ACTION_BY}</span>, 'actionBy', column),
      dataIndex: 'actionBy',
      key: 'actionBy',
      align: 'left',
      width: 180,
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) =>
        record?.actionBy? <span className="tabletext">{record?.actionBy?.firstname+' '+record?.actionBy?.lastname}</span>: '-',
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      width: 130,
      key: 'Actions',
      align: 'center',
      fixed: 'right',
      dataIndex: 'Actions',
      render: (item, record) => {
        return (
          <div className='actionCol justify-center'>
            {record.offerStatus === null &&
              (<>
                <Tooltip title='Approve offer avail'><ApproveIcon onClick={() => onClickConfirm(record.id)} /></Tooltip>
                <Tooltip title='Discard offer avail'><DiscardIcon onClick={() => onClickCancel(record.id)} /></Tooltip>
              </>)
            }
            {record.offerStatus === 'accepted' && <Tooltip title='Approved'><ApprovedIcon /></Tooltip>}
            {record.offerStatus === 'cancelled' && <Tooltip title='Discarded'><DiscardedIcon /></Tooltip>}
          </div>
        );
      },
    },
  ];

  return (
    <div className='commonWrapperLayout'>
      <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} withmenu={true} />
      <AppButton className={'besideFilter'} onClick={() => navigate('/offers')} text='View Current Offers' withMenu={true} />
      <div className={isFilterShow ? 'fWidthShowBox pRentalIncomePage mb-3' : 'fWidthShowBox pRentalIncomePage d-none'}>
        <Form name="complex-form" form={form} onFinish={{}} labelCol={{ span: 6 }} colon={false} wrapperCol={{ span: 16 }}>
          <Row>
            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
              <FormItem
                label={<span className='inputTitle'>{FILTER_STRING.START_DATE}</span>}>
                <DatePicker className='searchInpExpense' suffixIcon={<CalenderDarkIcon />} onChange={value => onFilterChange('sDate', value)} disabledDate={d => !d || d.isAfter(Filter.eDate)} />
              </FormItem>
              <FormItem
                label={<span className='inputTitle'>{FILTER_STRING.END_DATE}</span>}>
                <DatePicker className='searchInpExpense' suffixIcon={<CalenderDarkIcon />} onChange={value => onFilterChange('eDate', value)} disabledDate={d => !d || d.isSameOrBefore(Filter.sDate)} />
              </FormItem>
              <Form.Item
                label={<span className="inputTitle">{FILTER_STRING.SEARCH}</span>}
                className="searchInpUserTab"
                colon={false}>
                <Input placeholder={COMMON_STRING.SEARCH_STR}
                  className='inputField searchInpExpense'
                  value={tempSearch}
                  onChange={(e) => setTempSearch(e.target.value)}
                  onKeyPress={(e) => {
                    if (e?.key === 'Enter' && e.target.value) {
                      onFilterChange('search', e.target.value)
                    }
                    else if (!e.target.value) {
                      onFilterChange('search', null);
                    }
                  }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="d-flex filterWrapper">
                  <div className="filterSelect">
                    <Select defaultValue={REFERRALS_STRINGS.AVAILED_OFFER.Filter.COUNTRY} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false} value={Filter.country} onChange={(value) => onFilterChange('country', value)}><Select.Option>{REFERRALS_STRINGS.AVAILED_OFFER.Filter.COUNTRY}</Select.Option>{Countries.map((country, key) => <Select.Option key={key} value={country.name}>{country.name}</Select.Option>)}</Select>
                  </div>
                  <div className="filterSelect">
                    <Select defaultValue={REFERRALS_STRINGS.AVAILED_OFFER.Filter.STATE} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false} value={Filter.state} onChange={(value) => onFilterChange('state', value)}><Select.Option>{REFERRALS_STRINGS.AVAILED_OFFER.Filter.STATE}</Select.Option>{States.map((state, key) => <Select.Option key={key} value={state.name}>{state.name}</Select.Option>)}</Select>
                  </div>
                  <div className="filterSlider extent">
                    <div className="sliderTitle">{REFERRALS_STRINGS.AVAILED_OFFER.Filter.PD_REFERRAL}</div>
                    <Slider trackStyle={{ background: "#879EC4" }} range marks={marks} onChange={value => onFilterChange('pd_referral', value)} value={Filter.pd_referral} max={600} defaultValue={[0, 500]} />
                  </div>
                  <div className="filterSelect">
                    <Select defaultValue={REFERRALS_STRINGS.AVAILED_OFFER.Filter.SUBURB} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false} value={Filter.suburb} onChange={(value) => onFilterChange('suburb', value)}><Select.Option>{REFERRALS_STRINGS.AVAILED_OFFER.Filter.SUBURB}</Select.Option>{Suburbs.map((suburb, key) => <Select.Option key={key} value={suburb.name}>{suburb.name}</Select.Option>)}</Select>
                  </div>
                  <div className="filterSelect">
                    <Select defaultValue={REFERRALS_STRINGS.AVAILED_OFFER.Filter.ACTION} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false} value={Filter.action} onChange={(value) => onFilterChange('action', value)}><Select.Option>{REFERRALS_STRINGS.AVAILED_OFFER.Filter.ACTION}</Select.Option>{REFERRALS_STRINGS.AVAILED_OFFER.Filter.ACTIONTYPE.map((action, key) => <Select.Option key={key} value={action}>{action}</Select.Option>)}</Select>
                  </div>
                  <div className="filterSlider extent">
                    <div className="sliderTitle">{REFERRALS_STRINGS.AVAILED_OFFER.Filter.PD_REFREE}</div>
                    <Slider trackStyle={{ background: "#879EC4" }} range marks={marks} onChange={value => onFilterChange('pd_referee', value)} value={Filter.pd_referee} max={600} defaultValue={[0, 500]} />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            columns={columns}
            dataSource={AppAvails?.data}
            pagination={false}
            rowKey='id'
            loading={tableLoading}
            onChange={onChange}
            scroll={{ x: 2000 }}
          />

          {AppAvails?.total > 10 && (
            <div className='text-end bg-white mt-7'>
              <PaginationCom
                onChange={onChangePage}
                showSizeChanger={true}
                current={Filter.page}
                showLessItems={true}
                total={AppAvails?.total}
              />
            </div>
          )}
        </Col>
      </Row>
      <CommentModal isOpen={isCommentModelOpen} setIsOpen={setIsCommentModelOpen} onSubmit={onSubmitComment} />
    </div>
  );
}

OfferAvailsListPage.propTypes = {
  permissions: PropTypes.any
}

export default OfferAvailsListPage;
