import { Col, Input, Row, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteModel from '../../components/DeleteModel';
import DeleteIcon from '../../components/Icon/DeleteIcon';
import PencileIcon from '../../components/Icon/PencileIcon';
import PaginationCom from '../../components/PaginationCom';
import { ADMINUSERS } from '../../services/AdminUsers/AdminUsers';
import { tableSortIconToggle } from '../../utility/tableSorting';
import PropTypes from 'prop-types';
import { ADMIN_USER_STRINGS, COMMON_ALERTS, TABLE_STRING } from '../../services/strings';

const AdminUsers = ({ permissions }) => {
  const navigate = useNavigate();
  const [Adminusers, setAdminusers] = useState(null);
  const [tableLoading, setTableLoading] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [sort, setSort] = useState(null);
  const [tableDataLength, setTableDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const deleteAdminUser = async () => {
    try {
      const res = await ADMINUSERS.DELETE(deleteId);
      if (res.status) {
        toast.success(COMMON_ALERTS.DELETED_SUCCESS('Role'));
        setIsDeleteModalVisible(false);
        fetchUsers(currentPage, pageSize, search, sort);
      }
    }
    catch (e) {
      // console.log(e);
    }
  }

  const fetchUsers = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    gender = null
  ) => {
    setTableLoading(true);
    try {
      const res = await ADMINUSERS.GET(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        gender
      );
      if (res.status === 1 || res.status === '1') {
        setAdminusers(res);
        setTableDataLength(res?.data && res?.data.length);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setTableLoading(false);
    }
  };

  const handleChangeOfSearch = (e) => {
    setTempSearch(e.target.value);
    if (!e.target.value) {
      setCurrentPage(1);
      setSearch(null);
      setTempSearch(null);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  useEffect(() => {
    fetchUsers(currentPage, pageSize, search, sort);
  }, [currentPage, pageSize, search, sort]);

  const columns = [
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{ADMIN_USER_STRINGS.TABLE.NAME}</span>, 'name', column),
      dataIndex: 'firstname',
      key: 'firstname',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1) + ' ' + record.lastname.charAt(0).toUpperCase() + record.lastname.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{ADMIN_USER_STRINGS.TABLE.EMAIL}</span>, 'email', column),
      dataIndex: 'email',
      key: 'email',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{ADMIN_USER_STRINGS.TABLE.ROLE}</span>, 'name', column),
      dataIndex: 'role',
      key: 'role',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.name.charAt(0).toUpperCase() + value.name.slice(1)}</span> : '-',
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      width: 150,
      key: 'Actions',
      align: 'center',
      dataIndex: 'Actions',
      render: (item, record) => {
        return (
          <div className='actionCol'>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit User'>
                <PencileIcon
                  onClick={() => navigate('/admin-users/edit', { state: { userId: record.id } })}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
              </Tooltip>
            )}
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete User'>
                <DeleteIcon
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className='adminUsersLayout'>
      <div className="inputHeader">
        <Input
          placeholder='Start Typing Search User'
          className='input-control searchInp'
          onChange={(e) => handleChangeOfSearch(e)}
          onKeyPress={(e) => {
            if (e?.key === 'Enter' && e.target.value) {
              handleSearch();
            }
          }}
        />
        <div className="addNewBtnBx">
          <div className="addPAgeNavigateBtn" onClick={() => navigate('/admin-users/add')} >{ADMIN_USER_STRINGS.BUTTONS.CREATE_ADMIN_BTN}</div>
          <div className="addPAgeNavigateBtn rollMgmtBtn" onClick={() => navigate('/admin-users/roles')}>{ADMIN_USER_STRINGS.BUTTONS.ROLE_MANAGE_BTN}</div>
        </div>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-3">
              <Table
                columns={columns}
                dataSource={Adminusers?.data}
                pagination={false}
                rowKey='id'
                loading={tableLoading}
                onChange={onChange}
                scroll={{ x: 400 }}
              />

              {Adminusers?.total > 10 && (
                <div className='text-end bg-white mt-7'>
                  <PaginationCom
                    onChange={onChangePage}
                    showSizeChanger={true}
                    current={currentPage}
                    showLessItems={true}
                    total={Adminusers?.total}
                  />
                </div>
              )}
            </Col>
          </Row>
          <DeleteModel
            isDeleteModalVisible={isDeleteModalVisible}
            deleteModel={deleteAdminUser}
            setIsDeleteModalVisible={setIsDeleteModalVisible}
          />
        </Col>
      </Row>
    </div>
  );
}

AdminUsers.propTypes = {
  permissions: PropTypes.any
}

export default AdminUsers;
