import React, { useEffect, useState } from 'react';
import { Col, Empty, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NEWSARTICLES } from '../../services/NewsDetails/newsDetailsService';
import { COMMON_ALERTS, STORIES_STRING } from '../../services/strings';
import DeleteModel from '../../components/DeleteModel';
import PaginationCom from '../../components/PaginationCom';
import LeftNavIcon from '../../components/Icon/LeftNavIcon';
import AppButton from '../../components/AppButton';
import PropTypes from 'prop-types';
import { STORIES } from '../../services/NewsDetails/storiesService';
import StoryTile from '../../components/NewsAndArticles/StoryTile';

const TopStoriesPage = ({ permissions }) => {
    const navigate = useNavigate();
    const [Stories, setStories] = useState(null);
    const [currentPage, setcurrentPage] = useState(1);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const fetchStories = async (currpg) => {
        const stories = await STORIES.GET(null, ((currpg - 1) * 10), null, null);
        if (Array.isArray(stories.data)) {
            setStories(stories);
            setIsLoaded(true);
        }
    }

    const onChangePage = (e) => {
        setcurrentPage(e);
        fetchStories(e);
    }

    const deleteStory = async () => {
        const res = await STORIES.DELETE(deleteId);
        if (res.status) {
            toast.success(COMMON_ALERTS.DELETED_SUCCESS('Story'));
            fetchStories(currentPage);
            setIsDeleteModalVisible(false);
        }
        else {
            toast.error('Something wrong happened.');
        }
    }

    useEffect(() => { fetchStories(1); }, []);

    return (
        <div className='commonWrapperLayout'>
            <div className="backNavHeader">
                <div className="backNavText" onClick={() => navigate(-1)}>
                    <LeftNavIcon />
                    <span>{STORIES_STRING.HEADING}</span>
                </div>
                <AppButton text={STORIES_STRING.BUTTONS.ADD_BUTTON} onClick={() => navigate('/top-stories/add')} />
            </div>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='newsArticleListWrapper'>
                    {
                        isLoaded && Stories?.data?.length ?
                            Stories?.data.map((story, key) =>
                                <StoryTile
                                    key={key}
                                    data={story}
                                    setIsDeleteModalVisible={setIsDeleteModalVisible}
                                    setDeleteId={setDeleteId}
                                    permissions={permissions} />) :
                            <Empty description={"No single story found"} />
                    }
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {Stories?.total > 10 && (
                        <div className='text-end bg-white mt-7'>
                            <PaginationCom
                                onChange={onChangePage}
                                showSizeChanger={false}
                                current={currentPage}
                                showLessItems={true}
                                total={Stories?.total}
                            />
                        </div>
                    )}
                </Col>
            </Row>

            <DeleteModel
                isDeleteModalVisible={isDeleteModalVisible}
                deleteModel={deleteStory}
                setIsDeleteModalVisible={setIsDeleteModalVisible}
            />
        </div>
    );
}

TopStoriesPage.propTypes = {
    permissions: PropTypes.any
};

export default TopStoriesPage;
