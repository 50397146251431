import React, { useEffect, useState } from 'react';
import { Col, Form, Radio, Row, Upload } from 'antd';
import { COMMON_ALERTS, NOTIFICATIONS_STRINGS } from '../../../../services/strings';
import { getLSItem, setLSItem } from '../../../../services/auth/encryptLogin';
import { BASE_URL } from '../../../../services/constant';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const TargetUsersStep = ({ changeTab }) => {
    const [form] = Form.useForm();
    const [userSelection, setuserSelection] = useState(null);
    const accessToken = getLSItem("pdollar-token");
    const [ExcelFileId, setExcelFileId] = useState(null);
    const [CSVFileId, setCSVFileId] = useState(null);
    const [excelFileList, setexcelFileList] = useState([]);
    const [csvFileList, setcsvFileList] = useState([]);

    const onChange = (e) => {
        setexcelFileList([]);
        setcsvFileList([]);
        setCSVFileId(null);
        setExcelFileId(null);
        setuserSelection(e.target.value);
    }

    useEffect(() => {
        const ex_data_str = getLSItem('notification-step-3');
        if (ex_data_str !== null) {
            const ex_data = JSON.parse(ex_data_str);
            form.setFieldValue('targetUser', ex_data?.targetUser);
            setexcelFileList(ex_data?.excelFileList);
            setcsvFileList(ex_data?.csvFileList);
            setCSVFileId(ex_data?.csvFileId);
            setExcelFileId(ex_data?.excelFileId);
        }
    }, []);

    const onFinish = (formData) => {
        const payload = {
            ...formData,
            excelFileId: ExcelFileId,
            csvFileId: CSVFileId,
            excelFileList: excelFileList,
            csvFileList: csvFileList
        };
        setLSItem('notification-step-3', JSON.stringify(payload));
        changeTab(3);
    }

    const ExcelProps = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        action: BASE_URL + 'upload-excel',
        accept: ".xlsx",
        disabled: CSVFileId ? true : false,
        fileList: excelFileList,
        headers: {
            authorization: accessToken,
        },
        beforeUpload(file) {
            if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                toast.warn(COMMON_ALERTS.REQUIRED_SELECT('correct file type'));
                return false;
            }
        },
        onChange({ file, fileList }) {
            if (fileList.length === 1) {
                setexcelFileList([...fileList]);
            }
            if (file.status === 'done') {
                setExcelFileId(file.response.data?.media?.id);
            } else if (file.status === 'error') {
                setexcelFileList([]);
                toast.error(file?.response?.message?? COMMON_ALERTS.FILE_UPLOAD_FAILED(file.name));
            }
        },
        async onRemove() {
            setExcelFileId(null);
            setexcelFileList([]);
        },
    }

    const CsvProps = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        disabled: ExcelFileId ? true : false,
        action: BASE_URL + 'upload-csv',
        accept: ".csv",
        fileList: csvFileList,
        headers: {
            authorization: accessToken,
        },
        beforeUpload(file) {
            if (file.type !== 'text/csv') {
                toast.warn(COMMON_ALERTS.REQUIRED_SELECT('correct file type'));
                return false;
            }
        },
        onChange({ file, fileList }) {
            if (fileList.length === 1) {
                setcsvFileList([...fileList]);
            }
            if (file.status === 'done') {
                setCSVFileId(file.response.data?.media?.id);
            } else if (file.status === 'error') {
                setcsvFileList([]);
                toast.error(file?.response?.message?? COMMON_ALERTS.FILE_UPLOAD_FAILED(file.name));
            }
        },
        async onRemove() {
            setCSVFileId(null);
            setcsvFileList([]);
        },
    }

    return (
        <div>
            <Row>
                <span className="titleText">{NOTIFICATIONS_STRINGS.STEP3.TARGET_USER}</span>
            </Row>
            <Form form={form} onFinish={onFinish} colon={false}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="targetUserBox">
                            <Form.Item name="targetUser" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_CHOOSE('aleast one') }, {
                                message: COMMON_ALERTS.REQUIRED_CHOOSE('aleast one file'),
                                validator: () => {
                                    if ((userSelection === 'upload') && (!ExcelFileId && !CSVFileId)) {
                                        return Promise.reject('');
                                    }
                                    else {
                                        return Promise.resolve();
                                    }
                                }
                            }]}>
                                <Radio.Group onChange={onChange} value={userSelection}>
                                    <Radio value={"all"}><div className="radioBtnTxt">{NOTIFICATIONS_STRINGS.STEP3.SELECT_ALL}</div></Radio>
                                    <Radio value={"upload"}><div className="radioBtnTxt">
                                        {NOTIFICATIONS_STRINGS.STEP3.UPLOAD}
                                        <Upload {...CsvProps}>
                                            <div className="uploadFileBtn">{NOTIFICATIONS_STRINGS.STEP3.CSV}</div>
                                        </Upload>
                                        <Upload {...ExcelProps}>
                                            <div className="uploadFileBtn">{NOTIFICATIONS_STRINGS.STEP3.EXCEL}</div>
                                        </Upload>
                                    </div>
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="btnBox">
                        <div className="backBtn" onClick={() => changeTab(1)}>Back</div>
                        <button type="submit" className="createBtn">Next</button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

TargetUsersStep.propTypes = {
    changeTab: PropTypes.func
}

export default TargetUsersStep;
