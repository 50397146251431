import { methods, serviceMaker } from '../service';

const GET_FEEDBACK = (id) => serviceMaker(`feedbacks/${id}`, methods.GET);

const GET_FEEDBACKS = (
  limit,
  skip,
  search = null,
  sort = null,
  ratings = null,
  improvement = null,
  country = null,
  state = null,
  city = null,
  suburb = null,
  DateRangeStart = null,
  DateRangeEnd = null,
  FilterDateRange = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }

  if (ratings && ratings !== 0) {
    query['params'][`rating`] = ratings;
  }

  if (improvement) {
    query['params'][`feebackFactorName`] = improvement;
  }

  if (country) {
    query['params'][`country`] = country;
  }

  if (state) {
    query['params'][`state`] = state;
  }

  if (city) {
    query['params'][`city`] = city;
  }

  if (suburb) {
    query['params'][`suburb`] = suburb;
  }

  if (DateRangeStart) {
    query['params'][`startDate`] = new Date(DateRangeStart);
  }

  if (DateRangeEnd) {
    query['params'][`endDate`] = new Date(DateRangeEnd);
  }

  if (FilterDateRange) {
    query['params'][`filterDateRange`] = FilterDateRange;
  }

  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/feedback`, methods.GET, query);
  }

  return serviceMaker(`feedbacks`, methods.GET, query);
};

const DELETE_FEEDBACK = (id) => serviceMaker(`feedbacks/${id}`, methods.DELETE);

const CREATE_FEEDBACK = (payload) =>
  serviceMaker(`feedbacks`, methods.POST, null, payload);

const PATCH_FEEDBACK = (id, payload) =>
  serviceMaker(`feedbacks/${id}`, methods.PATCH, null, payload);

const DISTCOLS_FEEDBACK = (column_name) =>
  serviceMaker(`feedbacks?distict=${column_name}`, methods.GET);

const FEEDBACKS = {
  FIND: GET_FEEDBACK,
  GET: GET_FEEDBACKS,
  CREATE: CREATE_FEEDBACK,
  PATCH: PATCH_FEEDBACK,
  DELETE: DELETE_FEEDBACK,
  DISTCOLS: DISTCOLS_FEEDBACK
};

export { FEEDBACKS };
