import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import SelectArrow from '../Icon/SelectArrow';

const SearchInput = (props) => {
  return (
    <Select
      showSearch
      value={props.value}
      className={'searchInpExpense'}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={props.onSearch}
      onChange={props.onChange}
      notFoundContent={null}
      options={(props.data || []).map((d) => ({
        value: d[props.valueData || 'id'],
        label: d[props.label || 'name']
      }))}
      allowClear={props.allowClear || false}
      suffixIcon={<SelectArrow />}
    />
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  style: PropTypes.any,
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  allowClear: PropTypes.bool,
  data: PropTypes.array,
  onSearch: PropTypes.func,
  valueData: PropTypes.string,
  label: PropTypes.string
};

export default SearchInput;