import { methods, serviceMaker } from '../service';

const GET_AGENT = (id) => serviceMaker(`agents/${id}`, methods.GET);

const GET_AGENTS = (
  limit,
  skip,
  search = null,
  sort = null,
  businessType = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    }
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }

  if (businessType && businessType != '') {
    query['params']['businessType'] = businessType;
  }

  if (search) {
    query['params']['search'] = search;
  }

  return serviceMaker(`agents`, methods.GET, query);
};

const DELETE_AGENTS = (id) => serviceMaker(`agents-business/${id}`, methods.DELETE);

const CREATE_AGENTS = (payload) =>
  serviceMaker(`agents`, methods.POST, null, payload);

const PATCH_AGENTS = (id, payload) =>
  serviceMaker(`agents/${id}`, methods.PATCH, null, payload);

const VERIFY_ORGNAMe = (name, id) => serviceMaker(`agents/validateOrgName`, methods.GET, { params: { id, name } });

const VALIDATE_TEAM_EMAIL = (email, id) => serviceMaker(`agent-teams/validateAgentEmail`, methods.GET, { params: { id, email } });

const AGENTS = {
  FIND: GET_AGENT,
  GET: GET_AGENTS,
  CREATE: CREATE_AGENTS,
  PATCH: PATCH_AGENTS,
  DELETE: DELETE_AGENTS,
  VERIFY: VERIFY_ORGNAMe,
  VALIDATE_TEAM_EMAIL
};

export { AGENTS };
