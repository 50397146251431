import { methods, serviceMaker } from '../service';

const GET_DASHBOARD = (search) => {
    if (search) {
        const query = {
            params: {
                search: search
            },
        };
        return serviceMaker(`dashboard/home`, methods.GET, query);
    }
    return serviceMaker(`dashboard/home`, methods.GET);
}

const DASHBOARD = {
    GET: GET_DASHBOARD
};

export { DASHBOARD };
