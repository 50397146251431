import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout, Menu } from 'antd';
import DashboardIcon from '../Icon/DashboardIcon';
import AdvertiseIcon from '../Icon/AdvertiseIcon';
import ReportsIcon from '../Icon/ReportsIcon';
import NotificationsIcon from '../Icon/NotificationsIcon';
import UserHeader from '../Icon/UserHeaderIcon';
import HomeIcon from '../Icon/HomeIcon';
import ContactIcon from '../Icon/ContactIcon';
import ExpensesIcon from '../Icon/ExpensesIcon';
import ServiceScheduleIcon from '../Icon/ServiceScheduleIcon';
import ExternalServiceIcon from '../Icon/ExternalServiceIcon';
import CalculatorIcon from '../Icon/CalculatorIcon';
import CityStateIcon from '../Icon/CityStateIcon';
import PropertyTypeIcon from '../Icon/PropertyTypeIcon';
import RedeemRewardIcon from '../Icon/RedeemRewrardIcon';
import NewsArticlesIcon from '../Icon/NewsArticlesIcon';
import FaqsIcon from '../Icon/FaqsIcon';
import RoleManageIcon from '../Icon/RoleManageIcon';
import ReferralIcon from '../Icon/ReferralIcon';
import FeedbackIcon from '../Icon/FeedbackIcon';
import PropTypes from 'prop-types';
import { SIDEBAR_MENUS, SIDEBAR_STRINGS } from '../../services/strings';
import FhbdIcon from '../Icon/FhbdIcon';
import PropertyGoalIcon from '../Icon/PropertyGoalIcon';
import ServCategoryIcon from '../Icon/ServCategoryIcon';
import AVMIcon from '../Icon/AVMIcon';
import AgentsIcon from '../Icon/AgentsIcon';
import LendersIcon from '../Icon/LendersIcon';

const { Sider } = Layout;

const SideMenu = ({ setIsShowSidebar }) => {
  const { userValue } = useSelector((state) => state.user);
  const location = useLocation();
  const menuList = [];
  const newMenuList = [];

  const routing = () => {
    if (userValue?.role?.isAdmin) {
      menuList.push(
        {
          key: 'dashboard',
          icon: <DashboardIcon />,
          name: SIDEBAR_STRINGS.DASHBOARD,
          url: '/dashboard',
        },
        {
          key: 'users',
          icon: <UserHeader />,
          name: SIDEBAR_STRINGS.USERS,
          url: '/users',
        },
        {
          key: 'properties',
          icon: <HomeIcon />,
          name: SIDEBAR_STRINGS.PROPERTY,
          url: '/properties',
        },
        {
          key: 'contacts',
          icon: <ContactIcon />,
          name: SIDEBAR_STRINGS.CONTACT_DETAILS,
          url: '/contacts',
        },
        {
          key: 'expense',
          icon: <ExpensesIcon />,
          name: SIDEBAR_STRINGS.EXPENSES,
          url: '/expense',
        },
        {
          key: 'service_schedule',
          icon: <ServiceScheduleIcon />,
          name: SIDEBAR_STRINGS.SERVICE_SCHEDULE,
          url: '/service_schedule',
        },
        {
          key: 'external_Service',
          icon: <ExternalServiceIcon />,
          name: SIDEBAR_STRINGS.EXTERNAL_SERVICE,
          url: '/external_Service',
        },
        {
          key: 'property_goals',
          icon: <PropertyGoalIcon />,
          name: SIDEBAR_STRINGS.PROPERTY_GOALS,
          url: '/property_goals',
        },
        {
          key: 'calculator_info',
          icon: <CalculatorIcon />,
          name: SIDEBAR_STRINGS.CALCULATOR_INFO,
          url: '/calculator_info',
        },
        {
          key: 'fhbgoal',
          icon: <FhbdIcon />,
          name: SIDEBAR_STRINGS.FHBH,
          url: '/fhbgoal',
        },
        {
          key: 'city_state',
          icon: <CityStateIcon />,
          name: SIDEBAR_STRINGS.CITY_STATE,
          url: '/city_state',
        },
        {
          key: 'property_type',
          icon: <PropertyTypeIcon />,
          name: SIDEBAR_STRINGS.PROPERTY_TYPE,
          url: '/property_type',
        },
        {
          key: 'lenders',
          icon: <LendersIcon />,
          name: SIDEBAR_STRINGS.LENDERS,
          url: '/lenders',
        },
        {
          key: 'redeem_categories',
          icon: <ServCategoryIcon />,
          name: SIDEBAR_STRINGS.SERVICE_CATEGORY,
          url: '/redeem_categories',
        },
        {
          key: 'redeem_rewards',
          icon: <RedeemRewardIcon />,
          name: SIDEBAR_STRINGS.REDEEM_REWARDS,
          url: '/redeem_rewards',
        },
        {
          key: 'news_articles',
          icon: <NewsArticlesIcon />,
          name: SIDEBAR_STRINGS.NEWS_ARTICLES,
          url: '/news_articles',
        },
        {
          key: 'faqs',
          icon: <FaqsIcon />,
          name: SIDEBAR_STRINGS.FAQ,
          url: '/faqs',
        },
        {
          key: 'admin-users',
          icon: <RoleManageIcon />,
          name: SIDEBAR_STRINGS.ROLE_MANAGE,
          url: '/admin-users',
        },
        {
          key: 'reports',
          icon: <ReportsIcon />,
          name: SIDEBAR_STRINGS.REPORT,
          url: '/reports',
        },
        {
          key: 'agents',
          icon: <AgentsIcon />,
          name: SIDEBAR_STRINGS.AGENTS,
          url: '/agents',
        },
        {
          key: 'avm',
          icon: <AVMIcon />,
          name: SIDEBAR_STRINGS.AVM,
          url: '/avm',
        },
        {
          key: 'referrals',
          icon: <ReferralIcon />,
          name: SIDEBAR_STRINGS.REFERRALS,
          url: '/referrals',
        },
        {
          key: 'advertisements',
          icon: <AdvertiseIcon />,
          name: SIDEBAR_STRINGS.ADVERTISEMENT,
          url: '/advertisements',
        },
        {
          key: 'notifications',
          icon: <NotificationsIcon />,
          name: SIDEBAR_STRINGS.NOTIFICATION_CENTER,
          url: '/notifications',
        },
        {
          key: 'feedback',
          icon: <FeedbackIcon />,
          name: SIDEBAR_STRINGS.FEEDBACK,
          url: '/feedback',
        }
      );
    } else {
      userValue?.role?.permissions?.forEach((item) => {
        if (item.page.value === 'dashboard') {
          menuList.push({
            key: 'dashboard',
            icon: <DashboardIcon />,
            name: SIDEBAR_STRINGS.DASHBOARD,
            url: '/dashboard',
          },);
        }
        if (item.page.value === 'users') {
          menuList.push({
            key: 'users',
            icon: <UserHeader />,
            name: SIDEBAR_STRINGS.USERS,
            url: '/users',
          });
        }
        if (item.page.value === 'property') {
          menuList.push({
            key: 'properties',
            icon: <HomeIcon />,
            name: SIDEBAR_STRINGS.PROPERTY,
            url: '/properties',
          });
        }
        if (item.page.value === 'contacts') {
          menuList.push({
            key: 'contacts',
            icon: <ContactIcon />,
            name: SIDEBAR_STRINGS.CONTACT_DETAILS,
            url: '/contacts',
          });
        }
        if (item.page.value === 'expenses') {
          menuList.push({
            key: 'expenses',
            icon: <ExpensesIcon />,
            name: SIDEBAR_STRINGS.EXPENSES,
            url: '/expenses',
          });
        }
        if (item.page.value === 'service_schedule') {
          menuList.push({
            key: 'service_schedule',
            icon: <ServiceScheduleIcon />,
            name: SIDEBAR_STRINGS.SERVICE_SCHEDULE,
            url: '/service_schedule',
          });
        }
        if (item.page.value === 'external_Service') {
          menuList.push({
            key: 'external_Service',
            icon: <ExternalServiceIcon />,
            name: SIDEBAR_STRINGS.EXTERNAL_SERVICE,
            url: '/external_Service',
          });
        }
        if (item.page.value === 'external_Service') {
          menuList.push({
            key: 'property_goals',
            icon: <PropertyGoalIcon />,
            name: SIDEBAR_STRINGS.PROPERTY_GOALS,
            url: '/property_goals',
          });
        }
        if (item.page.value === 'calculator_info') {
          menuList.push({
            key: 'calculator_info',
            icon: <CalculatorIcon />,
            name: SIDEBAR_STRINGS.CALCULATOR_INFO,
            url: '/calculator_info',
          });
        }
        if (item.page.value === 'fhbgoal') {
          menuList.push({
            key: 'fhbgoal',
            icon: <FhbdIcon />,
            name: SIDEBAR_STRINGS.FHBH,
            url: '/fhbgoal',
          });
        }
        if (item.page.value === 'city_state') {
          menuList.push({
            key: 'city_state',
            icon: <CityStateIcon />,
            name: 'City/State',
            url: '/city_state',
          });
        }
        if (item.page.value === 'property_type') {
          menuList.push({
            key: 'property_type',
            icon: <PropertyTypeIcon />,
            name: SIDEBAR_STRINGS.PROPERTY_TYPE,
            url: '/property_type',
          });
        }
        if (item.page.value === 'lenders') {
          menuList.push({
            key: 'lenders',
            icon: <LendersIcon />,
            name: SIDEBAR_STRINGS.LENDERS,
            url: '/lenders',
          });
        }
        if (item.page.value === 'redeem_categories') {
          menuList.push({
            key: 'redeem_categories',
            icon: <ServCategoryIcon />,
            name: SIDEBAR_STRINGS.SERVICE_CATEGORY,
            url: '/redeem_categories',
          },);
        }
        if (item.page.value === 'redeem_rewards') {
          menuList.push({
            key: 'redeem_rewards',
            icon: <RedeemRewardIcon />,
            name: 'Redeem Rewards',
            url: '/redeem_rewards',
          });
        }
        if (item.page.value === 'news_articles') {
          menuList.push({
            key: 'news_articles',
            icon: <NewsArticlesIcon />,
            name: SIDEBAR_STRINGS.NEWS_ARTICLES,
            url: '/news_articles',
          });
        }
        if (item.page.value === 'faq') {
          menuList.push({
            key: 'faqs',
            icon: <FaqsIcon />,
            name: SIDEBAR_STRINGS.FAQ,
            url: '/faqs',
          });
        }
        if (item.page.value === 'role_management') {
          menuList.push({
            key: 'admin-users',
            icon: <RoleManageIcon />,
            name: SIDEBAR_STRINGS.ROLE_MANAGE,
            url: '/admin-users',
          });
        }
        if (item.page.value === 'reports') {
          menuList.push({
            key: 'reports',
            icon: <ReportsIcon />,
            name: SIDEBAR_STRINGS.REPORT,
            url: '/reports',
          });
        }
        if (item.page.value === 'avm') {
          menuList.push({
            key: 'avm',
            icon: <AVMIcon />,
            name: SIDEBAR_STRINGS.AVM,
            url: '/avm',
          });
        }
        if (item.page.value === 'referrals') {
          menuList.push({
            key: 'referrals',
            icon: <ReferralIcon />,
            name: SIDEBAR_STRINGS.REFERRALS,
            url: '/referrals',
          });
        }
        if (item.page.value === 'agents') {
          menuList.push({
            key: 'agents',
            icon: <AgentsIcon />,
            name: SIDEBAR_STRINGS.REPORT,
            url: '/agents',
          });
        }
        if (item.page.value === 'advertisement') {
          menuList.push({
            key: 'advertisement',
            icon: <AdvertiseIcon />,
            name: SIDEBAR_STRINGS.ADVERTISEMENT,
            url: '/advertisement',
          });
        }
        if (item.page.value === 'notifications') {
          menuList.push({
            key: 'notifications',
            icon: <NotificationsIcon />,
            name: SIDEBAR_STRINGS.NOTIFICATION_CENTER,
            url: '/notifications',
          });
        }
        if (item.page.value === 'feedback') {
          menuList.push({
            key: 'feedback',
            icon: <FeedbackIcon />,
            name: SIDEBAR_STRINGS.FEEDBACK,
            url: '/feedback',
          });
        }
      });
    }

    SIDEBAR_MENUS.map((menuStr) => {
      const res = menuList.filter((menu) => menu.name === menuStr);
      if (res.length)
        newMenuList.push(res[0]);
    });
  };
  routing();

  const matchPathnameWithKey = () => {
    const splitPathname = location.pathname.split('/');
    const actualPathname = splitPathname[1];
    if (actualPathname === '') {
      return ['dashboard'];
    }
    const returnVal = [actualPathname];
    return returnVal;
  };

  return (
    <Sider
      collapsible
      reverseArrow={true}
      onCollapse={(collapsed) => {
        setIsShowSidebar(collapsed);
      }}
      breakpoint='md'
      width={'256px'}
      // theme='#324666'
      style={{
        // overflow: 'auto',
        position: 'fixed',
        left: 0,
        top: '60px',
        bottom: '48px',
        width: '256px',
        minWidth: '256px',
        backgroundColor: '#324666',
        color: '#324666',
        colorScheme: '#324666',
        columnRuleColor: '#324666',
      }}
      className='box-shadow-2'
    >
      <Menu color='#324666' mode='vertical' selectedKeys={matchPathnameWithKey()}>
        {newMenuList?.map((item) => {
          return (
            <Menu.Item key={item.key}>
              {item.icon}
              <span className='title'>{item.name}</span>
              <Link to={`${item.url}`}></Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </Sider>
  );
};

SideMenu.propTypes = {
  setIsShowSidebar: PropTypes.func
}

export default SideMenu;
