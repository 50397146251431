import FormItem from 'antd/lib/form/FormItem';
import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NEWSARTICLES } from '../../services/NewsDetails/newsDetailsService';
import { COMMON_ALERTS, COMMON_STRING, NEWS_ARTICLES_STRING } from '../../services/strings';
import { BASE_URL } from '../../services/constant';
import CkEditor from '../../components/Inputs/CkEditor';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FileInput from '../../components/Inputs/FileInput';

const AddEditNewsArticle = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const dNewsArticle = useLocation().state?.dNewsArticle;
    const [CheckBox1, setCheckBox1] = useState(dNewsArticle ? dNewsArticle.type === 'news' : false);
    const [CheckBox2, setCheckBox2] = useState(dNewsArticle ? dNewsArticle.type === 'article' : false);
    const [newsImageList, setNewImageList] = useState([]);

    function validateUrl(value) {
        return /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    }

    const checkboxChange = (e) => {
        if (e === 'news') { if (CheckBox1) setCheckBox1(false); else setCheckBox1(true); setCheckBox2(false); }
        else if (e === 'article') { if (CheckBox2) setCheckBox2(false); else setCheckBox2(true); setCheckBox1(false); }
        else { setCheckBox1(false); setCheckBox2(false); }
    }

    const onFinish = async (e) => {
        let res;
        let msg = '';
        let payload = {
            headline: e.title,
            link: e.link,
            desc: e.description,
            type: CheckBox1 ? 'news' : 'article',
            mediaItemId: e.newsImage[0]
        };

        if (!dNewsArticle) {
            res = await NEWSARTICLES.CREATE(payload);
            msg = COMMON_ALERTS.ADDED_SUCCESS(CheckBox1 ? 'news' : 'articles');
        }
        else {
            res = await NEWSARTICLES.PATCH(dNewsArticle.id, payload);
            msg = COMMON_ALERTS.UPDATED_SUCCESS(CheckBox1 ? 'news' : 'articles');
        }

        if (res.status) {
            toast.success(msg);
            navigate(-1);
        }
    }

    useEffect(() => {
        if (dNewsArticle) {
            console.log(dNewsArticle);
            form.setFieldsValue({
                title: dNewsArticle.headline,
                link: dNewsArticle.link,
                description: dNewsArticle.desc,
                newsImage: [dNewsArticle.mediaItemId]
            });
            dNewsArticle.type === 'news'? setCheckBox1(true): setCheckBox2(true);
            if (dNewsArticle?.media_item) {
                setNewImageList([
                    {
                        uid: '-1',
                        name: 'cover image',
                        status: 'done',
                        url: BASE_URL.slice(0, -1) + dNewsArticle?.media_item?.image,
                        thubmUrl: BASE_URL.slice(0, -1) + dNewsArticle?.media_item?.image,
                    },
                ]);
            }
        }
    }, []);

    return (
        <div>
            <div className="fWidthShowBox addNewsArticleLayout">
                <h2>{dNewsArticle ? NEWS_ARTICLES_STRING.ADD_EDIT.EDIT_LABEL : NEWS_ARTICLES_STRING.ADD_EDIT.ADD_LABEL}</h2>
                <Form form={form} onFinish={onFinish} colon={false} requiredMark={false}>
                    <Row className='categorySelectBox'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <FormItem name="type" label={<span className='inputTitleCategory'>{NEWS_ARTICLES_STRING.ADD_EDIT.SELECT_CATEGORY_LABEL}</span>} colon={false} rules={[{
                                validator: () => {
                                    if (CheckBox1 || CheckBox2) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject('');
                                    }
                                }, message: COMMON_ALERTS.REQUIRED_INPUT('category')
                            }]}>
                                <Checkbox key={1} value="news" className='chkBox' checked={CheckBox1} onChange={(e) => checkboxChange(e.target.value)}><span className='checkBxTitle'>News</span></Checkbox>
                                <Checkbox key={2} value="article" className='chkBox' checked={CheckBox2} onChange={(e) => checkboxChange(e.target.value)}><span className='checkBxTitle'>Article</span></Checkbox>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className='inputsBox'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="inpBx">
                            <FormItem name="title" label={<div className="inputTitleBx"><span className='inputTitle'>{dNewsArticle ? NEWS_ARTICLES_STRING.ADD_EDIT.EDIT_HEADLINE_LABEL : NEWS_ARTICLES_STRING.ADD_EDIT.ADD_HEADLINE_LABEL}</span></div>} colon={false} rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('headline') }]}>
                                <Input className='inputC' />
                            </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="inpBx">
                            <FormItem name="link" label={<div className="inputTitleBx"><span className='inputTitle'>{dNewsArticle ? NEWS_ARTICLES_STRING.ADD_EDIT.EDIT_LINK_LABEL : NEWS_ARTICLES_STRING.ADD_EDIT.ADD_LINK_LABEL}</span></div>} colon={false} rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('link') }, { validator: (_, value) => { if (value === undefined || value === '' || validateUrl(value)) return Promise.resolve(); else return Promise.reject(''); }, message: 'Pleae enter valid url!' }]}>
                                <Input className='inputC' />
                            </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="inpBx">
                            <Form.Item
                                label={<div className="inputTitleBx"><span className='inputTitle'>{NEWS_ARTICLES_STRING.ADD_EDIT.UPLOAD_IMAGES}</span></div>}
                                name="newsImage"
                                rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('news image') }]}
                                colon={false}>
                                <FileInput filelist={newsImageList} setfileList={setNewImageList} accept='.png, .jpg, .jpeg' maxCount={1} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <Form.Item name="description" labelCol={24} wrapperCol={24} required
                                rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('description') }]}
                                className="inpBx inpDescBx" label={<div className="inputTitleBx">
                                    <span className="inputTitle">{dNewsArticle ? NEWS_ARTICLES_STRING.ADD_EDIT.EDIT_DEC_LABEL : NEWS_ARTICLES_STRING.ADD_EDIT.ADD_DEC_LABEL}</span></div>}>
                                <CkEditor />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='adCancelBtnBx'>
                            <div className="cancleBtn" onClick={() => navigate(-1)}>{COMMON_STRING.BACK_BTN}</div>
                            <button type="submit" className="addBtn">{dNewsArticle ? COMMON_STRING.UPDATE_BTN : COMMON_STRING.CREATE_BTN}</button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
}

export default AddEditNewsArticle;
