import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { PROPERTIES } from '../../services/Property/Properties';
import PropTypes from 'prop-types';
import { PROPERTIES_STRING } from '../../services/strings';
import { formatter } from '../../services/helpers';

const PropertyDetail = ({ permissions }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const propertyId = location.state?.propertyId;
    const [Property, setProperty] = useState(null);

    if (!propertyId) {
        <Navigate to="/properties" />
    }

    const fetchProperty = async () => {

        try {
            const property = await PROPERTIES.SINGLE(propertyId);
            if (property.status) setProperty(property.data);
            else navigate('/properties');

        }
        catch (e) {
            navigate('/properties');
        }
    }

    useEffect(() => {
        fetchProperty();
    }, []);

    return (
        <>
            <div className='propertyDetails fWidthShowBox'>
                <Row className='dataRow'>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.PROPERTY_DETAILS.PROPERTY_NAME}</span></Col>
                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.name}</div></Col>
                </Row>
                <Row className='dataRow bucket'>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol">
                        <span className="detailTitle">{PROPERTIES_STRING.PROPERTY_DETAILS.NO_OF_BEDS}</span>
                        <div className="detailInput noUnit">{Property?.bedroom}</div>
                    </Col>
                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm">
                        <span className="detailTitle">{PROPERTIES_STRING.PROPERTY_DETAILS.NO_OF_BATHS}</span>
                        <div className="detailInput noUnit">{Property?.bathroom}</div>
                    </Col>
                </Row>
                <Row className='dataRow bucket'>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} className="datatTitleCol">
                        <span className="detailTitle">{PROPERTIES_STRING.PROPERTY_DETAILS.CAR_SPACE}</span>
                        <div className="detailInput noUnit">{Property?.carSpaces} sqft</div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={14} xl={14} className="inpColm">
                        <span className="detailTitle">{PROPERTIES_STRING.PROPERTY_DETAILS.LAND_SPACE}</span>
                        <div className="detailInput noUnit">{Property?.landsize} sqft</div>
                    </Col>
                </Row>
                <Row className='dataRow'>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.PROPERTY_DETAILS.PROPERTY_TYPE}</span></Col>
                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.property_type?.name}</div></Col>
                </Row>
                <Row className='dataRow'>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.PROPERTY_DETAILS.COUNCIL}</span></Col>
                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.council?.name}</div></Col>
                </Row>
                <Row className='dataRow'>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.PROPERTY_DETAILS.COUNCIL}</span></Col>
                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{moment(Property?.purchaseDate).format("DD/MM/YYYY")}</div></Col>
                </Row>
                <h2 className='h2Title'>{PROPERTIES_STRING.PROPERTY_DETAILS.ADDITIONAL_INFO}</h2>
                <Row className='dataRow'>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.PROPERTY_DETAILS.DEBT}</span></Col>
                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.mortagage ? formatter.format(Property?.mortagage?.loanAmount) : '-'}</div></Col>
                </Row>
                <Row className='dataRow'>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.PROPERTY_DETAILS.EQUITY}</span></Col>
                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.purchasePrice ? formatter.format(Property?.purchasePrice) : '-'}</div></Col>
                </Row>
                <Row className='dataRow'>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.PROPERTY_DETAILS.MARKET_VAL}</span></Col>
                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.valuation ? formatter.format(Property?.valuation?.value) : '-'}</div></Col>
                </Row>
                <Row className='dataRow'>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.PROPERTY_DETAILS.RENT}</span></Col>
                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.rentAmt ? formatter.format(Property?.rentAmt) : '-'}</div></Col>
                </Row>
                <Row className='dataRow'>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.PROPERTY_DETAILS.MONTHLY_EXP}</span></Col>
                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.purchaseDate}</div></Col>
                </Row>
            </div>
            <Row className='mortatgeDetailContainer'>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className='propertyDetails mortatgeDetail fWidthShowBox'>
                        <h2>{PROPERTIES_STRING.MORTGAGE.MORTGAGE_DETAILS}</h2>
                        <Row className='dataRow'>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.MORTGAGE.LOAN_AMOUNT}</span></Col>
                            <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.mortagage ? formatter.format(Property.mortagage?.loanAmount) : '-'}</div></Col>
                        </Row>
                        <Row className='dataRow'>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.MORTGAGE.LENDER}</span></Col>
                            <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.mortagage ? Property.mortagage?.lenderName : '-'}</div></Col>
                        </Row>
                        <Row className='dataRow'>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.MORTGAGE.INTEREST_RATE}</span></Col>
                            <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.mortagage ? Property?.mortagage?.rate + '% pa' : '-'} </div></Col>
                        </Row>
                        <Row className='dataRow'>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.MORTGAGE.TERM}</span></Col>
                            <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.mortagage ? Property.mortagage?.terms + ' years' : '-'}</div></Col>
                        </Row>
                        <Row className='dataRow'>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.MORTGAGE.RATE_TYPE}</span></Col>
                            <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.mortagage ? Property.mortagage?.rateType : '-'}</div></Col>
                        </Row>
                        <Row className='dataRow'>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.MORTGAGE.INTEREST_TYPE}</span></Col>
                            <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.mortagage ? Property?.mortagage?.interestType : '-'}</div></Col>
                        </Row>
                        <Row className='dataRow'>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.MORTGAGE.REPAY_AMOUNT}</span></Col>
                            <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.mortagage ? formatter.format(Property?.mortagage?.repayAmount) : '-'} {Property?.mortagage?.repayType}</div></Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                    <Row className='insuaranceDetailContainer'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div className='propertyDetails insuaranceDetail fWidthShowBox'>
                                <h2>{PROPERTIES_STRING.INSURANCE.INSURANCE_DETAILS}</h2>
                                <Row className='dataRow'>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.INSURANCE.INSURANCE_TYPE}</span></Col>
                                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.insurance ? Property?.insurance?.insurance_type?.name : '-'}</div></Col>
                                </Row>
                                <Row className='dataRow'>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.INSURANCE.INSURER}</span></Col>
                                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.insurance ? Property?.insurance?.insurer : '-'}</div></Col>
                                </Row>
                                <Row className='dataRow'>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.INSURANCE.PREMIUM_AMOUNT}</span></Col>
                                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.insurance ? (formatter.format(Property?.insurance?.premiumAmt) + '/' + Property?.insurance?.frequency) : '-'} </div></Col>
                                </Row>
                                <Row className='dataRow'>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.INSURANCE.EXPIRATION_AMOUNT}</span></Col>
                                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.insurance ? moment(Property?.insurance?.expiryDate).format("DD/MM/YYYY") : '-'}</div></Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-3">
                            <div className='propertyDetails valuationDetail fWidthShowBox'>
                                <h2>{PROPERTIES_STRING.VALUATION.VALUATION_DETAILS}</h2>
                                <Row className='dataRow'>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.VALUATION.EVALUATION_TYPE}</span></Col>
                                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.valuation ? Property?.valuation?.type : '-'}</div></Col>
                                </Row>
                                <Row className='dataRow'>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.VALUATION.DATE}</span></Col>
                                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.valuation ? moment(Property?.valuation?.valuationDate).format("DD/MM/YYYY") : '-'}</div></Col>
                                </Row>
                                <Row className='dataRow'>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="datatTitleCol"><span className="detailTitle">{PROPERTIES_STRING.VALUATION.AMOUNT}</span></Col>
                                    <Col xs={24} sm={24} md={16} lg={14} xl={14} className="inpColm"><div className="detailInput">{Property?.valuation ? formatter.format(Property?.valuation?.value) : '-'}</div></Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </>
    );
}

PropertyDetail.propTypes = {
    permissions: PropTypes.any
}

export default PropertyDetail;
