import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Form, Input, Row } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import FileInput from '../../components/Inputs/FileInput';
import CkEditor from '../../components/Inputs/CkEditor';
import { STORIES } from '../../services/NewsDetails/storiesService';
import { BASE_URL } from '../../services/constant';
import { COMMON_ALERTS, COMMON_STRING, STORIES_STRING } from '../../services/strings';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function AddEditStory() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dStory = useLocation().state?.dStory;
    const [FileList, setFileList] = useState([]);
    console.log(dStory);
    
    function validateUrl(value) {
        return /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    }

    const onFinish = async (e) => {
        console.log(e);
        let res;
        let msg = '';
        let payload = {
            mediaItemId: e.newsImage[0],
            headline: e.title,
            link: e.link,
            desc: e.description
        };

        if (e.newsImage.length) {
            Object.assign(payload, { mediaItemId: e.newsImage[0] });
        }
        if(!dStory){
            res = await STORIES.CREATE(payload);
        }
        else{
            res = await STORIES.PATCH(dStory.id, payload);
        }
        
        msg = COMMON_ALERTS.ADDED_SUCCESS('story');

        if (res.status) {
            toast.success(msg);
            navigate('/top-stories');
        }
    }

    useEffect(() => {
        if (dStory) {
            form.setFieldsValue({
                title: dStory.headline,
                link: dStory.link,
                description: dStory.desc,
                newsImage: [dStory.mediaItemId]
            });
            if(dStory.media_item)
            setFileList([
                {
                    uid: '-1',
                    name: 'cover image',
                    status: 'done',
                    url: BASE_URL.slice(0, -1) + dStory.media_item.image,
                    thubmUrl: BASE_URL.slice(0, -1) + dStory.media_item.image,
                }
            ]);
        }
    }, []);

    return (
        <div>
            <div className="fWidthShowBox addNewsArticleLayout">
                <h2>{dStory? STORIES_STRING.ADD_EDIT.EDIT_HEADLINE_LABEL: STORIES_STRING.ADD_EDIT.ADD_HEADLINE_LABEL}</h2>
                <Form form={form} onFinish={onFinish} colon={false} requiredMark={false}>
                    <Row className='inputsBox'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="inpBx">
                            <Form.Item
                                label={<div className="inputTitleBx"><span className='inputTitle'>{STORIES_STRING.ADD_EDIT.ADD_IMAGE}</span></div>}
                                name="newsImage"
                                required={true}
                                rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('headline') }]}
                                colon={false}>
                                <FileInput
                                    accept='.png, .jpg, .jpeg'
                                    filelist={FileList}
                                    setfileList={setFileList}
                                    maxCount={1} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="inpBx">
                            <FormItem name="title" label={<div className="inputTitleBx"><span className='inputTitle'>{dStory ? STORIES_STRING.ADD_EDIT.EDIT_HEADLINE_LABEL : STORIES_STRING.ADD_EDIT.ADD_HEADLINE_LABEL}</span></div>} colon={false} rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('headline') }]}>
                                <Input className='inputC' />
                            </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="inpBx">
                            <FormItem 
                                name="link" 
                                label={<div className="inputTitleBx"><span className='inputTitle'>{dStory ? STORIES_STRING.ADD_EDIT.EDIT_LINK_LABEL : STORIES_STRING.ADD_EDIT.ADD_LINK_LABEL}</span></div>} 
                                colon={false} 
                                rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('link') }, { validator: (_, value) => { if (value === undefined || value === '' || validateUrl(value)) return Promise.resolve(); else return Promise.reject(''); }, 
                                message: 'Pleae enter valid url!' }]}>
                                <Input className='inputC' />
                            </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <Form.Item name="description" 
                                labelCol={24} 
                                wrapperCol={24}
                                className="inpBx inpDescBx" 
                                rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('description') }]}
                                label={<div className="inputTitleBx"><span className="inputTitle">{dStory ? STORIES_STRING.ADD_EDIT.EDIT_DEC_LABEL : STORIES_STRING.ADD_EDIT.ADD_DEC_LABEL}</span></div>}>
                                <CkEditor />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='adCancelBtnBx'>
                            <div className="cancleBtn" onClick={() => navigate(-1)}>{COMMON_STRING.BACK_BTN}</div>
                            <button type="submit" className="addBtn">{dStory ? COMMON_STRING.UPDATE_BTN : COMMON_STRING.CREATE_BTN}</button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
}

export default AddEditStory;