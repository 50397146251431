import { Drawer, Image, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { tableSortIconToggle } from '../../utility/tableSorting';
import DeleteModel from '../DeleteModel';
import PaginationCom from '../PaginationCom';
import { DeleteOutlined } from '@ant-design/icons';
import { CONTACT_DETAILS } from '../../services/ContactDetails/ContactDetails';
import {  PROPERTIES_STRING, TABLE_STRING } from '../../services/strings';
import InfoCircleIcon from '../Icon/InfoCircleIcon';
import PropTypes from 'prop-types';
import FallbackImage from '../Icon/FallbackImage';
import { BASE_URL } from '../../services/constant';
import { toast } from 'react-toastify';

const Pcontacts = ({ permissions }) => {

    const location = useLocation();
    const propertyId = location.state?.propertyId;
    const [deleteId, setDeleteId] = useState(null);
    const [sort, setSort] = useState(null);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [contacts, setcontacts] = useState(null);
    const [tableLoading, setTableLoading] = useState(null);
    const [tableDataLength, setTableDataLength] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [viewContractVisible, setviewContractVisible] = useState(false);
    const [search, setSearch] = useState(null);
    const [ContactDetail, setContactDetail] = useState(null);

    if (!propertyId) {
        <Navigate to="/properties" />
    }

    const onCotractsDetails = async (id) => {
        const res = await CONTACT_DETAILS.SINGLE(id);
        if (res && res.data) {
            setContactDetail(res.data)
        }
        setviewContractVisible(true);
    }

    const deleteContacts = async () => {
        const res = await CONTACT_DETAILS.DELETE(deleteId);
        setTableLoading(null);
        if (res.status) {
            toast.success("Contact deleted successfully.")
            fetchContacts();
            setIsDeleteModalVisible();
            setTableLoading(false);
        }
    };

    const fetchContacts = async () => {
        setTableLoading(false);
        const contactsData = await CONTACT_DETAILS.GETBYPROPERTY(pageSize,
            (currentPage - 1) * pageSize,
            search,
            sort,
            propertyId);
        if (contactsData.status && Array.isArray(contactsData.data)) {
            setcontacts(contactsData);
            setTableDataLength(contactsData.total);
        }
    }

    const onChangePage = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const onChange = (pagination, filters, sorter, extra) => {
        if (sorter?.order === 'ascend') {
            setSort({ [sorter.field]: 1 });
        } else if (sorter?.order === 'descend') {
            setSort({ [sorter.field]: -1 });
        } else {
            setSort({ createdAt: -1 });
        }
    };

    useEffect(() => {
        fetchContacts(currentPage, pageSize, search, sort);
    }, [currentPage, pageSize, search, sort]);


    const columns = [
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{PROPERTIES_STRING.CONTACTS.USERNAME}</span>, 'username', column),
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className='tableRowData'>{value}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{PROPERTIES_STRING.CONTACTS.EMAIL}</span>, 'frequency', column),
            key: 'email',
            dataIndex: 'email',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (value ? <span className='tableRowData'>{value}</span> : '-'),
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{PROPERTIES_STRING.CONTACTS.MOBILE}</span>, 'contact_mobile', column),
            key: 'contact_mobile',
            dataIndex: 'contact_mobile',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => <span className='tableRowData'>{value}</span>,
        },
        {
            title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
            key: 'Actions',
            align: 'center',
            dataIndex: 'Actions',
            width: 150,
            render: (item, record) => {
                return (
                    <div className='actionCol justify-center'>
                        <Tooltip title='View Contact'>
                            <InfoCircleIcon
                                onClick={() => onCotractsDetails(record.id)}
                                className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
                        </Tooltip>
                        {(permissions?.isDelete || permissions?.isDelete === undefined) && (
                            <Tooltip title='Delete Contact'>
                                <DeleteOutlined
                                    onClick={() => {
                                        setIsDeleteModalVisible(true);
                                        setDeleteId(record?.id);
                                    }}
                                    className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                                />
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            <div className='rentalTableBox'>
                <Table
                    columns={columns}
                    dataSource={contacts?.data}
                    pagination={false}
                    rowKey='id'
                    loading={tableLoading}
                    onChange={onChange}
                    scroll={{ x: 400 }}
                />
            </div>
            {contacts?.total > 10 && (
                <div className='text-end bg-white mt-7'>
                    <PaginationCom
                        onChange={onChangePage}
                        showSizeChanger={true}
                        current={currentPage}
                        showLessItems={true}
                        total={contacts?.total}
                    />
                </div>
            )}
            <DeleteModel
                isDeleteModalVisible={isDeleteModalVisible}
                deleteModel={deleteContacts}
                setIsDeleteModalVisible={setIsDeleteModalVisible}
            />
            {/* Drawer Add Edit */}
            <Drawer
                width={400}
                title="Contact Info"
                placement="right"
                onClose={
                    () => setviewContractVisible(false)
                }
                open={viewContractVisible}
            >
                <div className="drawerProfileImg">
                    <Image crossOrigin="anonymous" src={BASE_URL + ContactDetail?.media_item?.image} alt="Profile Image" fallback={FallbackImage} />
                </div>
                <table className="userDetailDrawerTable">
                    <tr>
                        <td><span className="sideDrawDetailsTitle">Name</span></td><td>{ContactDetail?.name}</td>
                    </tr>
                    <tr>
                        <td className="sideDrawDetailsTitle">Email</td><td>{ContactDetail?.email}</td>
                    </tr>
                    <tr>
                        <td className="sideDrawDetailsTitle">Mob. No. (Personal)</td><td>{ContactDetail?.contact_mobile}</td>
                    </tr>
                    <tr>
                        <td className="sideDrawDetailsTitle">Mob. No. (Office)</td><td>{ContactDetail?.contact_office}</td>
                    </tr>
                    <tr>
                        <td className="sideDrawDetailsTitle">Mob. No. (Other)</td><td>{ContactDetail?.contact_other}</td>
                    </tr>
                    <tr>
                        <td className="sideDrawDetailsTitle">Profession</td><td>{ContactDetail?.profession_type}</td>
                    </tr>
                    <tr>
                        <td className="sideDrawDetailsTitle">Suburbs</td><td>{ContactDetail?.suburb?.name}</td>
                    </tr>
                </table>
            </Drawer>
        </div>
    );
}

Pcontacts.propTypes = {
    permissions: PropTypes.any
}

export default Pcontacts;
