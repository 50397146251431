import { methods, serviceMaker } from '../service';

const GET_CALCULATOR = (id) => serviceMaker(`calculators/${id}`, methods.GET);
const GET_CALCULATOR_BY_NAME = (name) => serviceMaker(`calculators?name=${name}`, methods.GET);

const GET_CALCULATORS = (
  limit,
  skip,
  search = null,
  sort = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/calculators`, methods.GET, query);
  }

  return serviceMaker(`calculators`, methods.GET, query);
};

const DELETE_CALCULATOR = (id) => serviceMaker(`calculators/${id}`, methods.DELETE);

const CREATE_CALCULATOR = (payload) =>
  serviceMaker(`calculators`, methods.POST, null, payload);

const PATCH_CALCULATOR = (id, payload) =>
  serviceMaker(`calculators/${id}`, methods.PATCH, null, payload);

const CALCULATOR = {
  FIND: GET_CALCULATOR,
  GET: GET_CALCULATORS,
  CREATE: CREATE_CALCULATOR,
  PATCH: PATCH_CALCULATOR,
  DELETE: DELETE_CALCULATOR,
  BYNAME: GET_CALCULATOR_BY_NAME
};

export { CALCULATOR };
