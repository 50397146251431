import { Col, DatePicker, Row, Select, Table, Tooltip, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import CalenderDarkIcon from '../Icon/CalenderDarkIcon';
import { tableSortIconToggle } from '../../utility/tableSorting';
import { DeleteOutlined } from '@ant-design/icons';
import DeleteModel from '../DeleteModel';
import PaginationCom from '../PaginationCom';
import { Navigate, useLocation } from 'react-router-dom';
import { EXPENSES_TYPES } from '../../services/Expenses/ExpensesTypes';
import { EXPENSES } from '../../services/Expenses/Expenses';
import PropTypes from 'prop-types';
import SelectArrow from '../Icon/SelectArrow';
import FilterToogleBtn from '../FilterToogleBtn';
import { FILTER_STRING, TABLE_STRING } from '../../services/strings';

const PExpenses = ({ permissions }) => {
    const [Years, setYears] = useState([]);
    const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'Sepetmber', 'October', 'November', 'December'];
    const [ExpensesTypes, setExpensesTypes] = useState([]);
    const [FilterStartDate, setFilterStartDate] = useState(null);
    const [FilterEndDate, setFilterEndDate] = useState(null);
    const [FilterYear, setFilterYear] = useState('');
    const [FilterMonth, setFilterMonth] = useState('');
    const [FilterExpensesType, setFilterExpensesType] = useState('');
    const [deleteId, setDeleteId] = useState(null);
    const [sort, setSort] = useState(null);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [expenses, setexpenses] = useState(null);
    const [tableLoading, setTableLoading] = useState(null);
    const [tableDataLength, setTableDataLength] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState(null);
    const [tempSearch, setTempSearch] = useState(null);
    const location = useLocation();
    const [isFilterShow, setIsFilterShow] = useState(false);
    const propertyId = location.state?.propertyId;
    const [form] = Form.useForm();

    if (!propertyId) {
        <Navigate to="/properties" />;
    }

    const deleteExpenses = async () => {
        const res = await EXPENSES.DELETE(deleteId);
        setTableLoading(null);
        if (res.status) {
            fetchExpenses();
            setIsDeleteModalVisible();
            setTableLoading(false);
        }
    };

    const fetchExpenses = async () => {
        setTableLoading(false);
        const rentalIncomeData = await EXPENSES.GET(pageSize,
            (currentPage - 1) * pageSize,
            propertyId,
            search,
            sort,
            FilterStartDate,
            FilterEndDate,
            FilterYear,
            FilterMonth,
            FilterExpensesType);
        if (rentalIncomeData.status && Array.isArray(rentalIncomeData.data)) {
            setexpenses(rentalIncomeData);
            setTableDataLength(rentalIncomeData.total);
        }
    };

    const fetchExpensesTypes = async () => {
        const res = await EXPENSES_TYPES.GET(-1, 0);
        setExpensesTypes(res.data);
    };

    const onChangePage = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const onChange = (pagination, filters, sorter) => {
        if (sorter?.order === 'ascend') {
            setSort({ [sorter.field]: 1 });
        } else if (sorter?.order === 'descend') {
            setSort({ [sorter.field]: -1 });
        } else {
            setSort({ createdAt: -1 });
        }
    };

    const handleChangeOfSearch = (e) => {
        setTempSearch(e.target.value);
        if (!e.target.value) {
            setCurrentPage(1);
            setSearch(null);
            setTempSearch(null);
        }
    };

    const handleSearch = () => {
        setCurrentPage(1);
        setSearch(tempSearch);
    };


    const fetchYears = async () => {
        const res = await EXPENSES.YEARS();
        if (res.status && Array.isArray(res.data)) {
            setYears(res.data);
        }
    };

    const clearAll = () => {
        form.resetFields();
        setFilterStartDate(null);
        setFilterEndDate(null);
        setSearch(null);
        setFilterMonth('');
        setFilterYear('');
        setFilterExpensesType('');
    };

    useEffect(() => {
        fetchExpenses(currentPage, pageSize, search, sort);
    }, [currentPage, pageSize, search, sort, FilterStartDate, FilterEndDate, FilterYear, FilterMonth, FilterExpensesType]);

    useEffect(() => {
        fetchExpenses();
        fetchYears();
        fetchExpensesTypes();
    }, []);

    const columns = [
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">Type</span>, 'type', column),
            dataIndex: 'expenseType',
            key: 'expenseType',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className='tableRowData'>{value}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">Amount / Annual Interest Paid on Property</span>, 'frequency', column),
            key: 'annualInterest',
            dataIndex: 'amount',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (value ? <span className='tableRowData'>{'$' + value}</span> : '-'),
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">Eligible in Tax Lodgement</span>, 'startDate', column),
            key: 'taxLodgement',
            dataIndex: 'taxEligible',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => <span className='tableRowData'>{(value ? 'Yes' : 'No')}</span>,
        },
        {
            title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
            key: 'Actions',
            align: 'center',
            dataIndex: 'Actions',
            width: 80,
            render: (item, record) => {
                return (
                    <div className='actionCol justify-center'>
                        {(permissions?.isDelete || permissions?.isDelete === undefined) && (
                            <Tooltip title='Delete Expense'>
                                <DeleteOutlined
                                    onClick={() => {
                                        setIsDeleteModalVisible(true);
                                        setDeleteId(record?.id);
                                    }}
                                    className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                                />
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            <FilterToogleBtn withmenu={true} onClick={() => setIsFilterShow(!isFilterShow)} />
            <div className={isFilterShow ? 'fWidthShowBox pRentalIncomePage mb-3' : 'fWidthShowBox pRentalIncomePage d-none'}>
                <Form form={form} labelCol={8} wrapperCol={16} colon={false} labelAlign={'right'}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                            <Form.Item
                                label={<span className="detailTitle">{FILTER_STRING.START_DATE}</span>}
                                name="startDate"
                            >
                                <DatePicker className='selectDateInput'
                                    placeholder=""
                                    suffixIcon={<CalenderDarkIcon />}
                                    allowClear
                                    onChange={value => setFilterStartDate(value)}
                                    disabledDate={d => !d || d.isAfter(FilterEndDate)}
                                    picker="date" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={{ span: 11, offset: 1 }} xl={{ span: 11, offset: 1 }}>
                            <Form.Item
                                label={<span className="detailTitle">{FILTER_STRING.END_DATE}</span>}
                                name="endDate"
                            >
                                <DatePicker className='selectDateInput'
                                    placeholder=""
                                    suffixIcon={<CalenderDarkIcon />}
                                    allowClear
                                    onChange={value => setFilterEndDate(value)}
                                    disabledDate={d => !d || d.isSameOrBefore(FilterStartDate)}
                                    picker="date" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className='prentalRow'>
                        <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                            <Form.Item
                                label={<span className="detailTitle">{FILTER_STRING.SEARCH}</span>} name="search">
                                <Input className='selectDateInput'
                                    placeholder="Start Typing To Search Expenses"
                                    value={tempSearch}

                                    allowClear
                                    onChange={(e) => handleChangeOfSearch(e)}
                                    onKeyPress={(e) => {
                                        if (e?.key === 'Enter' && e.target.value) {
                                            handleSearch();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={{ span: 11, offset: 1 }} xl={{ span: 11, offset: 1 }} className='select_collection_box'>
                            <Select defaultValue={FILTER_STRING.YEAR} value={FilterYear} onChange={(value) => setFilterYear(value)} suffixIcon={<SelectArrow/>} className="cursor-pointer filterDropdown" bordered={false}>
                                <Select.Option value='' >{FILTER_STRING.YEAR}</Select.Option>
                                {Years.map((arr, key) => <Select.Option key={key} value={arr.year}>Year ({arr.year})</Select.Option>)}
                            </Select>
                            <Select defaultValue={FILTER_STRING.MONTH} value={FilterMonth} onChange={(value) => setFilterMonth(value)} suffixIcon={<SelectArrow/>} className="cursor-pointer filterDropdown" bordered={false}>
                                <Select.Option value=''>{FILTER_STRING.MONTH}</Select.Option>
                                {Months.map((arr, key) => <Select.Option key={key} value={arr}>{arr}</Select.Option>)}
                            </Select>
                            <Select defaultValue={FILTER_STRING.EXPENSES} value={FilterExpensesType} onChange={(value) => setFilterExpensesType(value)} suffixIcon={<SelectArrow/>} className="cursor-pointer filterDropdown" bordered={false}>
                                <Select.Option value=''>{FILTER_STRING.EXPENSES}</Select.Option>
                                {ExpensesTypes.map((type, key) => <Select.Option key={key} value={type.id}>{type.name}</Select.Option>)}
                            </Select>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <span className="clearAll" ><span onClick={() => clearAll()} style={{float: "right"}}>Clear All</span></span>
                    </Col>
                </Row>
            </div>
            <div className='rentalTableBox'>
                <Table
                    columns={columns}
                    dataSource={expenses?.data}
                    pagination={false}
                    rowKey='id'
                    loading={tableLoading}
                    onChange={onChange}
                    scroll={{ x: 400 }}
                />
            </div>
            {expenses?.total > 10 && (
                <div className='text-end bg-white mt-7'>
                    <PaginationCom
                        onChange={onChangePage}
                        showSizeChanger={true}
                        current={currentPage}
                        showLessItems={true}
                        total={expenses?.total}
                    />
                </div>
            )}
            <DeleteModel
                isDeleteModalVisible={isDeleteModalVisible}
                deleteModel={deleteExpenses}
                setIsDeleteModalVisible={setIsDeleteModalVisible}
            />
        </div>
    );
};

PExpenses.propTypes = {
    permissions: PropTypes.any
};

export default PExpenses;
