import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { toast } from 'react-toastify';
import LogOutIcon from '../Icon/LogOutIcon';
import LogOutModal from '../Modal/LogOutModal';
import { LOGOUT } from '../../redux/features/userSlice';
import HeaderProfileIcon from '../Icon/HeaderProfileIcon';
import { LOGOUT_API } from '../../services/login/login';
import { ClockCircleOutlined } from '@ant-design/icons';

const Header = () => {
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const { userValue } = useSelector((state) => state.user);
  const [isLoginSession, setLoginSession] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogOut = async () => {
    const res = await LOGOUT_API();
    if (res.status) {
      localStorage.clear();
      dispatch(LOGOUT());
      toast.success('Logout Successfully !');
      navigate('/login');
    }
  };

  const routingPath = (path) => {
    if (path) {
      if (path === 'app_users') {
        return '/app-users';
      } else if (path === 'admin_users') {
        return '/admin-users';
      } else {
        return `/${path}`;
      }
    } else {
      return '/dashboard';
    }
  };

  const matchPathnameWithKey = () => {
    const splitPathname = location.pathname.split('/');
    const actualPathname = splitPathname[1];
    if (actualPathname === '') {
      return ['dashboard'];
    }
    const returnVal = [actualPathname];
    return returnVal;
  };

  const isLoginSessions = () =>{
    if(userValue?.role?.isAdmin)
      setLoginSession(true);
    else
    userValue?.role?.permissions?.map((item) => {
      if (item.page.value === 'login_sessions') {
        setLoginSession(true);
      }
    });
  }

  useEffect(()=>{isLoginSessions();}, []);

  return (

    <>
      <div className='header d-flex align-items-center justify-content-between px-2'>
        <div className='d-flex align-items-center'>
          <Link to={routingPath(userValue?.role?.permissions[0]?.page?.value)}>
            <span className="logoTitleHome">Property Dollar</span>
          </Link>
        </div>
        <div className='d-flex align-items-center'>
          <div>
            <Dropdown
              arrow={{
                pointAtCenter: true,
              }}
              placement='bottom'
              trigger={['click']}
              overlay={
                <Menu
                  theme='light'
                  mode='vertical'
                  selectedKeys={matchPathnameWithKey()}
                >
                  {(isLoginSession) && <Menu.Item key='1' onClick={() => navigate('/loginsessions')}>
                    <div className='d-flex align-items-center'>
                      <ClockCircleOutlined width={25} height={25} style={{
                        width: '25px',
                        height: '25px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'inline-flex'
                      }} />
                      <span className='font-size-15 mb-0 ml-1 font-weight-500'>Login Session</span>
                    </div>
                  </Menu.Item>}
                  <Menu.Item key='2' onClick={() => setVisible(true)}>
                    <div className='d-flex align-items-center' onClick={() => setVisible(true)}>
                      <LogOutIcon />
                      <span className='font-size-15 mb-0 ml-1 font-weight-500'>Logout</span>
                    </div>
                  </Menu.Item>
                </Menu>
              }
            >
              <div
                className='d-flex cursor-pointer'
                onClick={(e) => e.preventDefault()}>
                <HeaderProfileIcon />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>

      {/* LogOut Modal */}
      <LogOutModal
        visible={visible}
        setVisible={setVisible}
        handleLogOut={handleLogOut}
      />
    </>
  );
};

export default Header;
