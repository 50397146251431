import React from "react";
import { RadialBarChart, ResponsiveContainer, RadialBar } from "recharts";

const data = [
  { uv: 160, fill: "#fff" },
  { uv: 100, fill: "#F44840" },
];

const DollarSpendChart = () => {
  return (
    <div>
      <ResponsiveContainer width={76} height={76}>
        <RadialBarChart
          className="radial"
          // cx="56%"
          // cy="40%"
          innerRadius="80%"
          outerRadius="130%"
          data={data}
        >

          <RadialBar minAngle={15} background={{ fill: '#F8918C' }} clockWise={false} dataKey="uv" />
        </RadialBarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DollarSpendChart;
