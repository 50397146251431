import { methods, serviceMaker } from '../service';

const FIND_ADMINLOGINS = (id) => serviceMaker(`admin-logins/${id}`, methods.GET);

const GET_ADMINLOGINS = (
  limit,
  skip,
  search = null,
  sort = null,
  type = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/admin-logins`, methods.GET, query);
  }

  if (type) {
    query['params']['type'] = type;
  }

  return serviceMaker(`admin-logins`, methods.GET, query);
};

const DELETE_ADMINLOGINS = (id) => serviceMaker(`admin-logins/${id}`, methods.DELETE);

const CREATE_ADMINLOGINS = (payload) =>
  serviceMaker(`admin-logins`, methods.POST, null, payload);

const PATCH_ADMINLOGINS = (id, payload) =>
  serviceMaker(`admin-logins/${id}`, methods.PATCH, null, payload);

const LOGOUT = (token) => serviceMaker(`logout/me?token=${token}`, methods.GET);

const ADMINLOGINS = {
  FIND: FIND_ADMINLOGINS,
  GET: GET_ADMINLOGINS,
  CREATE: CREATE_ADMINLOGINS,
  PATCH: PATCH_ADMINLOGINS,
  DELETE: DELETE_ADMINLOGINS,
  LOGOUT
};

export { ADMINLOGINS };
