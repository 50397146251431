import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteModel from '../../components/DeleteModel';
import FAQListWidget from '../../components/FAQ/FAQListWidget';
import PaginationCom from '../../components/PaginationCom';
import { FAQ } from '../../services/FAQ/faqService';
import PropTypes from 'prop-types';
import { COMMON_ALERTS, FAQ_STRINGS } from '../../services/strings';

const FaqPage = ({ permissions }) => {
    const navigate = useNavigate();
    const [FAQs, setFAQs] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const fetchFAQs = async () => {
        const res = await FAQ.GET(null, ((currentPage - 1) * 10));
        if (res.status) {
            setFAQs(res);
        }
    }

    const onChangePage = (e) => {
        setCurrentPage(e);
    }

    useEffect(() => {
        fetchFAQs();
    }, [currentPage]);


    const deleteFaq = async () => {
        try {
            const res = await FAQ.DELETE(deleteId);
            if (res.status) {
                toast.success(COMMON_ALERTS.DELETED_SUCCESS('FAQ'));
                setIsDeleteModalVisible(false);
                fetchFAQs();
            }
        }
        catch (e) {
            // console.log(e);
        }
    }

    return (
        <div className="faqPageLayout">
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="addPageNAvBtn">
                        <button onClick={() => navigate('/faqs/add')} className="addPAgeNavigateBtn">{FAQ_STRINGS.ADD_FAQ_BTN}</button>
                    </div>
                </Col>
            </Row>
            {FAQs?.data.map((faq, key) => <FAQListWidget key={key} faq={faq} setDeleteId={setDeleteId} setIsDeleteModalVisible={setIsDeleteModalVisible} permissions={permissions} />)}
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-3">
                    {FAQs?.total > 10 && (
                        <div className='text-end bg-white mt-7'>
                            <PaginationCom
                                onChange={onChangePage}
                                showSizeChanger={true}
                                current={currentPage}
                                showLessItems={true}
                                total={FAQs?.total}
                            />
                        </div>
                    )}
                </Col>
            </Row>
            <DeleteModel
                isDeleteModalVisible={isDeleteModalVisible}
                deleteModel={deleteFaq}
                setIsDeleteModalVisible={setIsDeleteModalVisible}
            />
        </div>
    );
}

FaqPage.propTypes = {
    permissions: PropTypes.any
}

export default FaqPage;
