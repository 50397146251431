import { Col, DatePicker, Empty, Form, Input, Row, Select, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import CalenderDarkIcon from '../../../components/Icon/CalenderDarkIcon';
import SelectArrow from '../../../components/Icon/SelectArrow';
import { EXPENSES_TYPES } from '../../../services/Expenses/ExpensesTypes';
import { ExpensesChart } from '../../../components/Expenses/ExpensesChart';
import FilterToogleBtn from '../../../components/FilterToogleBtn';
import { REPORTS } from '../../../services/Reports/ReportsService';
import { CSVLink } from 'react-csv';
import { ExportExcel, ExportPdf, handleFileName } from '../../../utility/Export/Export';
import { COMMON_STRING, FILTER_STRING, REPORTS_STRINGS } from '../../../services/strings';
import { DROPDOWN } from '../../../services/Dropdown/dropdowns';

const ExpensesTabReports = () => {
  const [form] = Form.useForm();
  const [FilterStartDate, setFilterStartDate] = useState(null);
  const [FilterEndDate, setFilterEndDate] = useState(null);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [Properties, setProperties] = useState([]);
  const [PropertiesFilter, setPropertiesFilter] = useState([]);
  const [FinalData, setFinalData] = useState({});
  const [ExpensesTypes, setExpensesTypes] = useState([]);
  const [isFilterShow, setIsFilterShow] = useState(true);
  const [ExpensesChartData, setExpensesChartData] = useState([]);
  const [Appusers, setAppusers] = useState([]);
  const [SelectedAppUserId, setSelectedAppUserId] = useState("");
  const [SelectedPropertyId, setSelectedPropertyId] = useState("");
  const [ExpensesTypeList, setExpensesTypeList] = useState([]);
  const [SelectedExpenseTypeId, setSelectedExpenseTypeId] = useState("");
  const [YearsList, setYearsList] = useState([]);
  const [SelectedYear, setSelectedYear] = useState("");
  const [SelectedMonth, setSelectedMonth] = useState("");
  const csvLink = useRef();
  const [csv, setCsv] = useState([]);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const MonthsList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'Sepetmber', 'October', 'December'];

  const fetchProperties = async (userId = null, selectedPropertyId = null) => {
    
    if (userId) {
      let res = null;
      if (selectedPropertyId) {
        res = await DROPDOWN.GET_PROPERTIES(null, SelectedPropertyId);
      }
      else {
        res = await DROPDOWN.GET_PROPERTIES(userId);
        let properties = Array.isArray(res) ? res : res.data;
        setPropertiesFilter(properties);
      }
      
      const properties = Array.isArray(res) ? res : res.data;
      setProperties(properties);
      

      const columnData = [{
        title: <span className="tableTitle">Expense Type</span>,
        dataIndex: 'name',
        key: 'name',
        render: text => <span className="tabletext">{text}</span>,
        fixed: 'left',
        width: 300,
      }];

      properties.map(elem => {
        columnData.push({
          title: <span className="tableTitle">{elem?.name}</span>,
          dataIndex: `pid${elem?.id}`,
          key: `pid${elem?.id}`,
          width: 150,
          render: amount => <span className="tabletext">{amount ? `$${amount}` : '$0.00'}</span>,
        });
      });
      setColumns(columnData);
      await fetchRowData();
    }
  };

  const fetchAppUsers = async () => {
    const res = await DROPDOWN.GET_APPUSERS();
    if (res.status && Array.isArray(res.data)) {
      setAppusers(res.data);
    }
  };

  const fetchExpenseYears = async () => {
    const res = await REPORTS.EXPENSES_YEARS();
    if (res.status) {
      setYearsList(res.data);
    }
  };

  const fetchExpesesTypes = async () => {
    const res = await DROPDOWN.EXPENSES_TYPES()
    if (res.status) {
      setExpensesTypeList(res.data);
    }
  };

  const changeUser = (value) => {
    if (value) {
      fetchProperties();
    }
    else {
      setColumns([]);
    }
    setSelectedAppUserId(value);
    if(!value || value == ''){
      setSelectedPropertyId('');
    }
  };

  const clearAll = () => {
    form.resetFields();
    setFilterEndDate('');
    setFilterStartDate('');
    setSelectedAppUserId('');
    setSelectedExpenseTypeId('');
    setSelectedMonth('');
    setSelectedPropertyId('');
    setSelectedYear('');
  }

  const fetchRowData = async () => {
    setIsTableLoading(true);
    const res = await EXPENSES_TYPES.REPORT(search, SelectedPropertyId, SelectedMonth, SelectedYear, SelectedExpenseTypeId, FilterStartDate, FilterEndDate);
    const expenses_types = Array.isArray(res) ? res : res.data;
    const rowData = [];
    const sumData = {};
    const expenses_types_arr = [];
    const exp_values = [];
    setExpensesTypes(res.data);

    expenses_types?.map((exp_type, exp_key) => {
      expenses_types_arr.push(exp_type?.name);
      exp_values[exp_key] = 0;
      const iData = { key: exp_key, name: exp_type?.name };
      exp_type?.expenses?.forEach((elem, i) => {
        exp_values[exp_key] = parseFloat(exp_values[exp_key]) + parseFloat(elem?.amount).toFixed(2);
        if (!iData['pid' + elem?.propertyId])
          iData['pid' + elem?.propertyId] = parseFloat(elem?.amount).toFixed(2);
        else
          iData['pid' + elem?.propertyId] = parseFloat(parseFloat(iData['pid' + elem?.propertyId]) + parseFloat(elem?.amount)).toFixed(2);
        sumData['pid' + elem?.propertyId] = iData['pid' + elem?.propertyId];
      });
      rowData.push(iData);
    });

    
    if (expenses_types.length) {
      const expenseChart = [];
      expenseChart['columns'] = ExpensesTypeList.map(expType => expType.name);
      expenseChart['values'] = ExpensesTypeList.map(expType => expenses_types_arr.map((exType, index) => (exType == expType.name) ? parseFloat(exp_values[index]) : 0)[0]);
      setData(rowData);
      setExpensesChartData(expenseChart);
      setFinalData(sumData);

    }
    else {
      setData([]);
      const expenseChart = [];
      expenseChart['columns'] = ExpensesTypeList.map(expType => expType.name);
      expenseChart['values'] = ExpensesTypeList.map(() => 0);
      setExpensesChartData(expenseChart);
      setIsTableLoading(false);
    }

  };

  useEffect(() => {
    if (SelectedAppUserId)
      fetchRowData(SelectedAppUserId);
    else
      setProperties([]);
  }, [search, SelectedPropertyId, SelectedExpenseTypeId, SelectedYear, SelectedMonth, FilterStartDate, FilterEndDate]);


  useEffect(() => {
    if (data.length) {
      setIsTableLoading(false);
    }
  }, [data]);

  useEffect(() => {
    fetchProperties();
    fetchAppUsers();
    fetchExpenseYears();
    fetchExpesesTypes();
  }, []);

  useEffect(() => {
    fetchProperties(SelectedAppUserId);
  }, [SelectedAppUserId]);

  useEffect(() => {
    fetchProperties(SelectedAppUserId, SelectedPropertyId);
  }, [SelectedPropertyId]);

  const headers = [
    { key: 'id', label: 'Expenses Types', }
  ];

  Properties.map(property => headers.push({ label: property.name, key: property.id }));

  const handleDownloadCsv = async () => {
    const pdftableRows = [];
    ExpensesTypes.forEach((expense) => {
      const expensesReportsListData = {};
      expensesReportsListData['id'] = expense.name;
      Properties.map((property, Pelem) => {
        expensesReportsListData[property.id] = 0;
        expense?.expenses.map((exp, exIndex) => {
          if (exp.propertyId == property.id) {
            expensesReportsListData[property.id] = parseInt(expensesReportsListData[property.id]) + parseInt(exp.amount);
          }
        });
        expensesReportsListData[property.id] = '$' + expensesReportsListData[property.id];
      });
      pdftableRows.push(expensesReportsListData);
    });
    setCsv(pdftableRows);
    csvLink.current.link.click();
  };

  const generateExcel = async () => {
    const pdftableRows = [];
    ExpensesTypes.forEach((expense) => {
      const expensesReportsListData = {};
      expensesReportsListData['id'] = expense.name;
      Properties.map((property, Pelem) => {
        expensesReportsListData[property.name] = 0;
        expense?.expenses.map((exp, exIndex) => {
          if (exp.propertyId == property.id) {
            expensesReportsListData[property.name] = parseInt(expensesReportsListData[property.name]) + parseInt(exp.amount);
          }
        });
        expensesReportsListData[property.name] = '$' + expensesReportsListData[property.name];
      });
      pdftableRows.push(expensesReportsListData);
    });
    ExportExcel(pdftableRows, 'ExpensesReport');
  };

  const generatePDF = async () => {
    const pdftableColumn = [
      'Expenses Type'
    ];
    Properties.map(property => pdftableColumn.push(property.name?property.name: '-'));
    const pdftableRows = [];
    ExpensesTypes.forEach((expense) => {
      const expensesReportsListData = [];
      expensesReportsListData[0] = expense.name;
      Properties.map((property, Pelem) => {
        expensesReportsListData[Pelem + 1] = 0;
        expense?.expenses.map((exp, exIndex) => {
          if (exp.propertyId == property.id) {
            expensesReportsListData[Pelem + 1] = parseInt(expensesReportsListData[Pelem + 1]) + parseInt(exp.amount);
          }
        });
        expensesReportsListData[Pelem + 1] = '$' + expensesReportsListData[Pelem + 1];
      });
      pdftableRows.push(expensesReportsListData);
    });
    ExportPdf(pdftableColumn, pdftableRows, 'ExpensesReport');
  };

  return (
    <div className='userTabReportLayout'>
      <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} withmenu={true} />
      <Row className={isFilterShow ? 'filterBoxLayout mb-3' : 'filterBoxLayout d-none'}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form form={form}>
                <Form.Item
                  label={<span className="inputTitle">{FILTER_STRING.START_DATE}</span>}
                  name="startdate"
                  colon={false}>
                  <DatePicker
                    className='w-100 inputField'
                    placeholder=""
                    suffixIcon={<CalenderDarkIcon />}
                    onChange={value => setFilterStartDate(value)}
                    disabledDate={d => !d || d.isAfter(FilterEndDate)}
                    picker="date" />
                </Form.Item>
                <Form.Item
                  label={<span className="inputTitle">{FILTER_STRING.END_DATE}</span>}
                  name="lastname"
                  colon={false}
                >
                  <DatePicker
                    className='w-100 inputField'
                    placeholder=""
                    suffixIcon={<CalenderDarkIcon />}
                    onChange={value => setFilterEndDate(value)}
                    disabledDate={d => !d || d.isSameOrBefore(FilterStartDate)}
                    picker="date" />
                </Form.Item>
                <Form.Item
                  label={<span className="inputTitle">{FILTER_STRING.SEARCH}</span>}
                  name="serchInput"
                  colon={false}
                >
                  <Input placeholder={COMMON_STRING.SEARCH_STR + 'Contact'}
                    className='inputField searchField'
                    value={tempSearch}
                    onChange={(e) => setTempSearch(e.target.value)}
                    onKeyPress={(e) => {
                      if (e?.key === 'Enter' && e.target.value) {
                        setSearch(e.target.value);
                      }
                      else if (!e.target.value) {
                        setSearch(null);
                      }
                    }} />
                </Form.Item>
              </Form>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className={'dropdownBox'}>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.USER} value={SelectedAppUserId} onChange={(value) => changeUser(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value="">Select User</Select.Option>{Appusers?.map((user, key) => <Select.Option key={key} value={user.id}>{(user.firstname || '') + ' ' + (user.lastname || '')}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.PROPERTIES} value={SelectedPropertyId} onChange={(value) => setSelectedPropertyId(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value="">Select Property</Select.Option>{PropertiesFilter?.map((property, key) => <Select.Option key={key} value={property.id}>{property.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.MONTH} value={SelectedMonth} onChange={(value) => setSelectedMonth(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value="">Month</Select.Option>{MonthsList.map((month, key) => <Select.Option key={key} n value={month}>{month}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.YEAR} value={SelectedYear} onChange={(value) => setSelectedYear(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value="">Years</Select.Option>{YearsList.map((year, key) => <Select.Option key={key} value={year.year}>{year.year}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.EXPENSES} value={SelectedExpenseTypeId}  onChange={(value) => setSelectedExpenseTypeId(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value="">Expense Type</Select.Option>{ExpensesTypeList.map((expenseType, key) => <Select.Option key={key} value={expenseType.id}>{expenseType.name}</Select.Option>)}</Select>
              </div>
              <div className="exportOuterBoxFlex">
                <div className="exportBox">
                  <div className="exportTitle">Export</div>
                  <div><div className="exportBtn" onClick={handleDownloadCsv}>{REPORTS_STRINGS.BUTTONS.EXPORT_CSV}</div>
                    <CSVLink
                      data={csv}
                      headers={headers}
                      filename={handleFileName('expensesReport', 'csv')}
                      ref={csvLink}
                      className='text-black font-weight-600'
                    ></CSVLink>
                  </div>
                  <div className="exportBtn" onClick={generateExcel}>{REPORTS_STRINGS.BUTTONS.EXPORT_EXCEL}</div>
                  <div className="exportBtn" onClick={generatePDF}>{REPORTS_STRINGS.BUTTONS.EXPORT_PDF}</div>
                </div>
                <div className="filterSelect clearAll" onClick={() => clearAll()}>Clear all</div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {(SelectedAppUserId) ? ((Properties.length > 0) ? (<><Row>
        <Col className='expensesChartDiv' xs={24} sm={24} md={24} lg={24} xl={24}>
          <ExpensesChart expensesChartData={ExpensesChartData} />
        </Col>
      </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {(Properties.length > 0) ? (<Table
              columns={columns}
              dataSource={data}
              scroll={{ x: 500 }}
              pagination={false}
              loading={isTableLoading}
              summary={pageData => {
                return (
                  <>
                    <Table.Summary.Row className='footerRow'>
                      <Table.Summary.Cell index={0}>{REPORTS_STRINGS.EXPENSES.TOTAL_TITLE}</Table.Summary.Cell>
                      {Properties.map((elem, i) => <Table.Summary.Cell key={i} index={i + 1}>{FinalData['pid' + elem.id] ? '$' + FinalData['pid' + elem.id] : '$0'}</Table.Summary.Cell>)}
                    </Table.Summary.Row>
                  </>
                );
              }}
            />) : <></>}
          </Col>
        </Row></>) : <Empty description="Properties not found for this user." />) : <Empty description="Please select user." />}
    </div>
  );
};

export default ExpensesTabReports;
