import { Tabs } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { REPORTS_TABS_STRINGS } from '../../services/strings';
import BudgetingTabReports from './Tabs/BudgetingTabReports';
import ContactTabReports from './Tabs/ContactTabReports';
import ExpensesTabReports from './Tabs/ExpensesTabReports';
import FinanceTabReports from './Tabs/FinanceTabReports';
import PropertiesTabReports from './Tabs/PropertiesTabReports';
import ServicesTabReports from './Tabs/ServicesTabReports';
import TaxLodgementsTabReports from './Tabs/TaxLodgementsTabReports';
import UsersTabReports from './Tabs/UsersTabReports';
import ReferalTabReport from './Tabs/ReferalTabReport';
import RewardsTabReport from './Tabs/RewardsTabReport';
const { TabPane } = Tabs;

const ReportsPage = ({ permissions }) => {
    return (
        <div>
            <Tabs className='propertyDetailsTabs' defaultActiveKey="1" color="#324666">
                <TabPane tab={REPORTS_TABS_STRINGS.PROPERTIES} key="Properties">
                    <PropertiesTabReports permissions={permissions}/>
                </TabPane>
                <TabPane tab={REPORTS_TABS_STRINGS.USERS} key="Users">
                    <UsersTabReports permissions={permissions} />
                </TabPane>
                <TabPane tab={REPORTS_TABS_STRINGS.CONTACTS} key="contacts">
                    <ContactTabReports permissions={permissions}/>
                </TabPane>
                <TabPane tab={REPORTS_TABS_STRINGS.EXPENSES} key="expense" >
                    <ExpensesTabReports />
                </TabPane>
                <TabPane tab={REPORTS_TABS_STRINGS.SERVICES} key="services" >
                    <ServicesTabReports />
                </TabPane>
                <TabPane tab={REPORTS_TABS_STRINGS.BUDGETING} key="budgeting" >
                    <BudgetingTabReports />
                </TabPane>
                <TabPane tab={REPORTS_TABS_STRINGS.TAX_LODGEMENT} key="taxlodgment" >
                    <TaxLodgementsTabReports />
                </TabPane>
                <TabPane tab={REPORTS_TABS_STRINGS.FINANCE} key="finance" >
                    <FinanceTabReports />
                </TabPane>
                <TabPane tab={REPORTS_TABS_STRINGS.REWARDS} key="rewards" >
                    <RewardsTabReport />
                </TabPane>
                <TabPane tab={REPORTS_TABS_STRINGS.REFERALS} key="referral" >
                    <ReferalTabReport />
                </TabPane>
            </Tabs>
        </div>
    );
};

ReportsPage.propTypes = {
  permissions: PropTypes.any
}

export default ReportsPage;
