 import React from 'react';
const InfoCircleIcon = (props) => {
    return (<svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#324666" strokeWidth="2"/>
        <path d="M12 7H12.01" stroke="#324666" strokeWidth="2" strokeLinecap="round"/>
        <path d="M10 11H12V16" stroke="#324666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 16H14" stroke="#324666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>);
}

export default InfoCircleIcon;
