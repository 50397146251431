import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { COMMON_ALERTS, COMMON_STRING, EXPENSES_STRINGS } from '../../services/strings';
import { EXPENSES_TYPES } from '../../services/Expenses/ExpensesTypes';

const AddEditExpenses = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const expenseId = useLocation().state?.expenseId;
    const [Expense, setExpense] = useState(null);
    const [isDataLoaded, setisDataLoaded] = useState(false);
    const [isPopularChecked, setIsPopularChecked] = useState(false);

    useEffect(() => {
        if (expenseId !== null) {
            fetchExpense();
        }
    }, []);

    const fetchExpense = async () => {
        if (expenseId) {
            const res = await EXPENSES_TYPES.FIND(expenseId);
            if (res.status) {
                setExpense(res.data);
                setIsPopularChecked(res?.data?.popular || false);
                setisDataLoaded(true);
            }
        }
    }

    const onFinish = async (form) => {
        const payload = { name: form.name, popular: isPopularChecked };
        let res = null;
        if (!expenseId)
            res = await EXPENSES_TYPES.CREATE(payload);
        else
            res = await EXPENSES_TYPES.PATCH(expenseId, payload);
        try {
            if (res.status) {
                toast.success(!expenseId ? COMMON_ALERTS.ADDED_SUCCESS('Expenses') : COMMON_ALERTS.UPDATED_SUCCESS('Expenses'));
                navigate('/expense', { replace: true });
            }
        }
        catch (e) {
            // console.log(e);
        }
    }

    if ((!expenseId) || isDataLoaded)
        return (
            <div>
                <div className="fWidthShowBox addNwDepScheduleContainer">
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <h2>{!expenseId ? EXPENSES_STRINGS.ADD_EXPENSE : EXPENSES_STRINGS.EDIT_EXPENSE}</h2>
                        </Col>
                    </Row>
                    <Form name="complex-form" className="addformLayout" form={form} onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} colon={false}>
                        <Form.Item
                            label={<span className='inputTitle'>{EXPENSES_STRINGS.TYPE_EXPENSES}</span>}
                            name="name"
                            initialValue={Expense?.name}
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('expense name') }]}
                        >
                            <Input className='textInput' />
                        </Form.Item>
                        <Form.Item
                            label={<span className='inputTitle'>{EXPENSES_STRINGS.IS_POPULAR}</span>}
                            name="popular"
                            initialValue={Expense?.popular}
                        >
                            <Checkbox onChange={(e) => setIsPopularChecked(e.target.checked)} checked={isPopularChecked} />
                        </Form.Item>
                        <Row>
                            <Col xs={24} sm={24} md={{ span: 16, offset: 8 }} lg={{ span: 16, offset: 8 }} xl={{ span: 16, offset: 8 }} className="btnBox">
                                <Button className="cancelBtn" onClick={() => navigate('/expense', { replace: true })}>{COMMON_STRING.BACK_BTN}</Button>
                                <Button type="submit" htmlType='submit' className="addBtn">{!expenseId ? COMMON_STRING.CREATE_BTN : COMMON_STRING.UPDATE_BTN}</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>

            </div>
        );
}

export default AddEditExpenses;
