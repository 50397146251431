import React from 'react';

const AgentsIcon = () => {
  return (
    <>
        <span className='anticon anticon-agents ant-menu-item-icon'>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5007 5.41667V11.6667H15.834V6.25L11.6673 3.33333L7.50065 6.25V7.5H5.83398V5.41667L11.6673 1.25L17.5007 5.41667ZM12.9173 5.83333H12.084V6.66667H12.9173V5.83333ZM11.2507 5.83333H10.4173V6.66667H11.2507V5.83333ZM12.9173 7.5H12.084V8.33333H12.9173V7.5ZM11.2507 7.5H10.4173V8.33333H11.2507V7.5ZM15.834 13.3333H14.1673C14.1673 12.3333 13.5423 11.4333 12.609 11.0833L7.47565 9.16667H0.833984V18.3333H5.83398V17.1333L11.6673 18.75L18.334 16.6667V15.8333C18.334 14.45 17.2173 13.3333 15.834 13.3333ZM2.50065 16.6667V10.8333H4.16732V16.6667H2.50065ZM11.6423 17.0083L5.83398 15.4V10.8333H7.17565L12.0257 12.6417C12.309 12.75 12.5007 13.025 12.5007 13.3333C12.5007 13.3333 10.8423 13.2917 10.584 13.2083L8.60065 12.55L8.07565 14.1333L10.059 14.7917C10.484 14.9333 10.9257 15.0083 11.3757 15.0083H15.834C16.159 15.0083 16.4507 15.2 16.584 15.475L11.6423 17.0083Z" fill="#3E567E"/>
            </svg>
        </span>
    </>
  );
};

export default AgentsIcon;
