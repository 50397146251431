import React, { useEffect, useState } from 'react';
import { Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Switch, Tabs, Upload } from 'antd';
import CalenderDarkIcon from '../../components/Icon/CalenderDarkIcon';
import PencileIcon from '../../components/Icon/PencileIcon';
import SelectArrow from '../../components/Icon/SelectArrow';
import { COMMON_ALERTS, COMMON_STRING, REFERRALS_STRINGS } from '../../services/strings';
import { getLSItem } from '../../services/auth/encryptLogin';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../services/constant';
import { DROPDOWN } from '../../services/Dropdown/dropdowns';
import { OFFERES } from '../../services/offers/offerService';
import FormItem from 'antd/es/form/FormItem';
import { toast } from 'react-toastify';
import moment from 'moment';
const { TabPane } = Tabs;

const AddEditOffer = () => {
    const [form] = Form.useForm();
    const [coverImageList, setCoverImageList] = useState([]);
    const [fullImageList, setFullImageList] = useState([]);
    const accessToken = getLSItem('pdollar-token');
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [suburbs, setSuburbs] = useState([]);
    const [currentTab, setCurrentTab] = useState('selectall');
    const [fileUploadUrl, setFileUploadUrl] = useState('upload-excel');
    const [excelFileList, setExcelFileList] = useState([]);
    const location = useLocation().state;
    const navigate = useNavigate();

    const uploadButton = (
        <div className='uploadImageBtn'>
            <div>
                <PencileIcon />
            </div>
            <span className='noFileText'>{COMMON_STRING.NO_FILE_CHOOSEN}</span>
        </div>
    );

    const imageProps = (fileList, setFileList, accept = ".png, .jpg, .jpeg", name) => ({
        name: 'file',
        multiple: false,
        fileList,
        accept,
        action: `${BASE_URL}upload-image`,
        headers: {
            authorization: accessToken,
        },
        beforeUpload() {
            if (fileList.length > 1 || fileList.length === 1) {
                toast.warn(COMMON_ALERTS.FILE_EXISTS);
                return false;
            }
        },
        onChange(info) {
            if (info.fileList.length === 1) {
                setFileList([...info.fileList]);
            }
            if (info.file.status === 'done') {
                if(name) form.setFieldValue(name, info.file.response?.data?.media?.id);
            } else if (info.file.status === 'error') {
                if(name) form.setFieldValue(name, null);
                toast.error(COMMON_ALERTS.FILE_UPLOAD_FAILED(info.file.name));
            }
        },
        async onRemove() {
            setFileList([]);
            if(name) form.setFieldValue(name, null);
        },
    });

    const excelFileProps = (fileList, setFileList, accept = ".xlsx") => ({
        name: 'file',
        multiple: false,
        fileList,
        accept,
        action: BASE_URL+fileUploadUrl,
        headers: {
            authorization: accessToken,
        },
        beforeUpload(file) {
            if(file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                setFileUploadUrl('upload-excel');
            }
            else{
                setFileUploadUrl('upload-csv');
            }
            if (fileList.length > 1 || fileList.length === 1) {
                toast.warn(COMMON_ALERTS.FILE_EXISTS);
                return false;
            }
        },
        onChange(info) {
            if (info.fileList.length === 1) {
                const filename = info.fileList[0].name.split('.');
                if((filename.length > 0) && (filename[filename.length - 1] === 'xlsx' || filename[filename.length - 1] === 'csv')){
                    setFileList([...info.fileList]);
                }
                else toast.error('Invalid file name');
            }
            if (info.file.status === 'done') {
                if (info.file.response.status) {
                    form.setFieldValue('excelFileId', info.file.response.data.media.id);
                }
                else {
                    setFileList([]);
                    toast.error(info.file.response.message);
                }
            } else if (info.file.status === 'error') {
                form.setFieldValue('excelFileId', null);
                setFileList([]);
                toast.error(info?.file?.response?.message?? COMMON_ALERTS.FILE_UPLOAD_FAILED(info.file.name));
            }
        },
        async onRemove() {
            form.setFieldValue('excelFileId', null);
            setFileList([]);
        },
    });

    const fetchOffer = async () =>{
        const res = await OFFERES.FIND(location?.offerId); 
        if(res.status){
            const e = res.data;
            const platform = [];
            if(e.platform_android) platform.push('android');
            if(e.platform_web) platform.push('Web');
            if(e.platform_ios) platform.push('iOS');
            if(!e?.suburbId) setCurrentTab('upload');
            const payload = {
                "title": e.title,
                "subtitle": e.subTitle,
                "description": e.description,
                "createdFor": e.createdFor,
                "startDate": moment(e.startDate),
                "endDate": moment(e.endDate),
                "pdReferral": e.pdReferral,
                "pdReferee": e.pdReferee,
                "coverImage": e.coverImageId,
                "fullImage": e.fullImageId,
                "excelFile": e.excelFileId,
                "suburbId": e.suburbId,
                "cityId": e.suburb?.cityId,
                "stateId": e.suburb?.city? e.suburb?.city?.stateId: e.suburb?.stateId,
                "countryId": e.suburb?.city? e.suburb?.city?.state?.countryId: e?.suburb?.state?.countryId,
                "excelFileId": e.excelFileId,
                "platform": platform,
                "isActive": e.isActive,
                "internalMail": e.internalMail,
                "externalMail": e.externalMail
            }
            fetchStates(payload.countryId);
            if(payload.cityId){
                fetchCities(payload.stateId);
                fetchSuburbs(null, payload.cityId);
            }
            else{
                fetchSuburbs(payload.stateId);
            }
            
            form.setFieldsValue(payload);
            setCurrentTab(e?.excelFileId? 'current': 'selectall');
            if(e?.coverImage)
            setCoverImageList([
                {
                    uid: '-1',
                    name: 'cover image',
                    status: 'done',
                    url: BASE_URL.slice(0, -1) + e?.coverImage?.image,
                    thubmUrl: BASE_URL.slice(0, -1) + e?.coverImage?.image,
                },
            ]);
            if(e?.fullImage)
            setFullImageList([
                {
                    uid: '-1',
                    name: 'cover image',
                    status: 'done',
                    url: BASE_URL.slice(0, -1) + e?.fullImage?.image,
                    thubmUrl: BASE_URL.slice(0, -1) + e?.fullImage?.image,
                },
            ]);
            if(e?.excelFile)
            setExcelFileList([
                {
                    uid: '-1',
                    name: 'cover image',
                    status: 'done',
                    url: BASE_URL.slice(0, -1) + e?.excelFile?.image,
                    thubmUrl: BASE_URL.slice(0, -1) + e?.excelFile?.image,
                },
            ]);
        }
    }

    const fetchCounties = async () => {
        const countries = await DROPDOWN.GET_COUNTRIES();
        if (countries.data && Array.isArray(countries.data)) {
            setCountries(countries.data);
        }
    }

    const fetchStates = async (countryId) => {
        const states = await DROPDOWN.GET_STATES(countryId);
        if (states.data && Array.isArray(states.data)) {
            setStates(states.data);
        }
    }

    const fetchCities = async (stateId) => {
        const cities = await DROPDOWN.GET_CITY(stateId);
        if (cities.data && Array.isArray(cities.data)) {
            setCities(cities.data);
        }
        fetchSuburbs(stateId);
    }

    const fetchSuburbs = async (stateId, cityId) => {
        const suburbs = await DROPDOWN.GET_SUBURBS(cityId, stateId);
        if (suburbs.data && Array.isArray(suburbs.data)) {
            setSuburbs(suburbs.data);
        }
    }

    useEffect(() => {
        fetchCounties();
        if(location?.offerId){
            fetchOffer(location.offerId);
        }
    }, []);
    

    const onSubmit = async (e) => {
        console.log(e);
        const payload = {
            "title": e.title,
            "subTitle": e.subtitle,
            "description": e.description,
            "createdFor": e.createdFor,
            "startDate": moment(e.startDate).format('YYYY-MM-DD'),
            "endDate": moment(e.endDate).format('YYYY-MM-DD'),
            "pdReferral": e.pdReferral,
            "pdReferee": e.pdReferee,
            "coverImageId": e.coverImage,
            "fullImageId": e.fullImage,
            "excelFileId": currentTab === 'current'? e.excelFileId: null,
            "suburbId": currentTab === 'selectall'? e.suburbId: null,
            "platform_android": e.platform.includes("android"),
            "platform_web": e.platform.includes("Web"),
            "platform_ios": e.platform.includes("iOS"),
            "isActive": e.isActive,
            "internalMail": e.internalMail,
            "externalMail": e.externalMail
        };
        const res =  location?.offerId? await OFFERES.PATCH(location.offerId, payload): await OFFERES.CREATE(payload);
        if (res.status) {
            toast.success(location?.offerId? COMMON_ALERTS.UPDATED_SUCCESS('Offer'): COMMON_ALERTS.ADDED_SUCCESS('Offer'));
            navigate(-1);
        }
        else {
            toast.error(res.message);
        }
    }

    return (
        <div className='fWidthShowBox commonLayout'>
            <Row>
                <Col xs={24} sm={24} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                    <h2 className="h2title">{location?.offerId? REFERRALS_STRINGS.ADD_EDIT_OFFER.EDIT_OFFER_LABEL: REFERRALS_STRINGS.ADD_EDIT_OFFER.CREATE_OFFER_LABEL}</h2>
                </Col>
            </Row>
            <Form name="complex-form" form={form} onFinish={onSubmit} labelCol={{ span: 8 }} colon={false} wrapperCol={{ span: 8 }}>
                <FormItem
                    name='title'
                    required
                    rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(REFERRALS_STRINGS.ADD_EDIT_OFFER.TITLE) }]}
                    label={<span className='inputTitle'>{REFERRALS_STRINGS.ADD_EDIT_OFFER.TITLE}</span>}>
                    <Input className="input-control" maxLength={100} />
                </FormItem>
                <FormItem
                    name='subtitle'
                    required
                    rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(REFERRALS_STRINGS.ADD_EDIT_OFFER.SUB_TITLE) }]}
                    label={<span className='inputTitle'>{REFERRALS_STRINGS.ADD_EDIT_OFFER.SUB_TITLE}</span>}>
                    <Input className="input-control" maxLength={100}/>
                </FormItem>
                <FormItem
                    name="description"
                    required
                    rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(REFERRALS_STRINGS.ADD_EDIT_OFFER.DESCRIPTION) }]}
                    label={<span className='inputTitle'>{REFERRALS_STRINGS.ADD_EDIT_OFFER.DESCRIPTION}</span>}>
                    <Input className="input-control" />
                </FormItem>
                <FormItem
                    name="startDate"
                    required
                    rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(REFERRALS_STRINGS.ADD_EDIT_OFFER.START_DATE) }]}
                    label={<span className='inputTitle'>{REFERRALS_STRINGS.ADD_EDIT_OFFER.START_DATE}</span>}>
                    <DatePicker className="input-control" suffixIcon={<CalenderDarkIcon />} disabledDate={d => !d || d.isAfter(form.getFieldValue('endDate') || null) || d.isBefore(moment().format('YYYY-MM-DD'))} />
                </FormItem>
                <FormItem
                    name="endDate"
                    required
                    rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(REFERRALS_STRINGS.ADD_EDIT_OFFER.END_DATE) }]}
                    label={<span className='inputTitle'>{REFERRALS_STRINGS.ADD_EDIT_OFFER.END_DATE}</span>}>
                    <DatePicker className="input-control" suffixIcon={<CalenderDarkIcon />} disabledDate={d => !d || d.isSameOrBefore(form.getFieldValue('startDate') || null) || d.isBefore(moment().format('YYYY-MM-DD'))} />
                </FormItem>
                <FormItem
                    name="pdReferral"
                    required
                    rules={[
                        { required: true, message: COMMON_ALERTS.REQUIRED_INPUT(REFERRALS_STRINGS.ADD_EDIT_OFFER.REFERRAL_PD) },
                        { validator: (_, val)=> (val && val < 0)? Promise.reject('Value should not be negative'): Promise.resolve() }
                    ]}
                    label={<span className='inputTitle'>{REFERRALS_STRINGS.ADD_EDIT_OFFER.REFERRAL_PD}</span>}>
                    <InputNumber className="input-control" />
                </FormItem>
                <FormItem
                    name="pdReferee"
                    required
                    rules={[
                        { required: true, message: COMMON_ALERTS.REQUIRED_INPUT(REFERRALS_STRINGS.ADD_EDIT_OFFER.REFERREE_PD) },
                        { validator: (_, val)=> (val && val < 0)? Promise.reject('Value should not be negative'): Promise.resolve() }
                    ]}
                    label={<span className='inputTitle'>{REFERRALS_STRINGS.ADD_EDIT_OFFER.REFERREE_PD}</span>}>
                    <InputNumber className="input-control" />
                </FormItem>
                <FormItem
                    name="internalMail"
                    required
                    rules={[
                        { 
                            required: true, 
                            message: COMMON_ALERTS.REQUIRED_INPUT(REFERRALS_STRINGS.ADD_EDIT_OFFER.INTERNAL_MAIL) 
                        },
                        {
                            type: "email",
                            message: 'Invalid Email!'
                        }
                    ]}
                    label={<span className='inputTitle'>{REFERRALS_STRINGS.ADD_EDIT_OFFER.INTERNAL_MAIL}</span>}>
                    <Input className="input-control" maxLength={50} />
                </FormItem>
                <FormItem
                    name="externalMail"
                    required
                    rules={[
                        { 
                            required: true, 
                            message: COMMON_ALERTS.REQUIRED_INPUT(REFERRALS_STRINGS.ADD_EDIT_OFFER.EXTERNAL_MAIL) 
                        },
                        {
                            type: "email",
                            message: 'Invalid Email!'
                        }
                    ]}
                    label={<span className='inputTitle'>{REFERRALS_STRINGS.ADD_EDIT_OFFER.EXTERNAL_MAIL}</span>}>
                    <Input className="input-control" maxLength={50} />
                </FormItem>
                <FormItem
                    name="createdFor"
                    required
                    rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(REFERRALS_STRINGS.ADD_EDIT_OFFER.CREATED_FOR) }]}
                    label={<span className='inputTitle'>{REFERRALS_STRINGS.ADD_EDIT_OFFER.CREATED_FOR}</span>}>
                    <Input className="input-control" />
                </FormItem>
                <FormItem
                    name="coverImage"
                    required
                    rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(REFERRALS_STRINGS.ADD_EDIT_OFFER.COVER_IMAGE) }]}
                    label={<span className='inputTitle'>{REFERRALS_STRINGS.ADD_EDIT_OFFER.COVER_IMAGE}</span>}>
                    <div className="imagePickerDiv"><Upload accept=".png, .jpg, .jpeg" listType="picture" {...imageProps(coverImageList, setCoverImageList, null, 'coverImage')}>
                        {coverImageList.length >= 1 ? null : uploadButton}
                    </Upload>
                    </div>
                </FormItem>
                <FormItem
                    name="fullImage"
                    required
                    rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(REFERRALS_STRINGS.ADD_EDIT_OFFER.FULL_IMAGE) }]}
                    label={<span className='inputTitle'>{REFERRALS_STRINGS.ADD_EDIT_OFFER.FULL_IMAGE}</span>}>
                    <div className="imagePickerDiv">
                        <Upload accept=".png, .jpg, .jpeg" listType="picture" {...imageProps(fullImageList, setFullImageList, null, 'fullImage')}>
                            {fullImageList.length >= 1 ? null : uploadButton}
                         </Upload>
                    </div>
                </FormItem>
                <Row className='fWidthShowBox'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Tabs className='propertyDetailsTabs' activeKey={currentTab} defaultActiveKey={currentTab} onChange={(val)=>setCurrentTab(val)} color="#324666">
                            <TabPane tab={REFERRALS_STRINGS.TABS.SELECT_ALL} key="selectall" >
                                <FormItem
                                    name="countryId"
                                    rules={[{ required: currentTab == 'selectall', message: COMMON_ALERTS.REQUIRED_INPUT(COMMON_STRING.COUNTRY) }]}
                                    label={<span className='inputTitle'>{COMMON_STRING.COUNTRY}</span>}>
                                    <Select className="input-control" onChange={(value) => fetchStates(value)} suffixIcon={<SelectArrow />}><Select.Option></Select.Option>{countries.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
                                </FormItem>
                                <FormItem
                                    name="stateId"
                                    rules={[{ required: currentTab == 'selectall', message: COMMON_ALERTS.REQUIRED_INPUT(COMMON_STRING.STATE) }]}
                                    label={<span className='inputTitle'>{COMMON_STRING.STATE}</span>}>
                                    <Select className="input-control" onChange={(value) => fetchCities(value)} suffixIcon={<SelectArrow />}>{states.map((state, key) => <Select.Option key={key} value={state.id}>{state.name}</Select.Option>)}</Select>
                                </FormItem>
                                <FormItem
                                    name="cityId"
                                    label={<span className='inputTitle'>{COMMON_STRING.CITY}</span>}>
                                    <Select className="input-control" onChange={(value) => fetchSuburbs(value)} suffixIcon={<SelectArrow />}>{cities.map((city, key) => <Select.Option key={key} value={city.id}>{city.name}</Select.Option>)}</Select>
                                </FormItem>
                                <FormItem
                                    name="suburbId"
                                    rules={[{ required: currentTab == 'selectall', message: COMMON_ALERTS.REQUIRED_INPUT(COMMON_STRING.SUBURB) }]}
                                    label={<span className='inputTitle'>{COMMON_STRING.SUBURB}</span>}>
                                    <Select className="input-control" suffixIcon={<SelectArrow />}>{suburbs.map((suburb, key) => <Select.Option key={key} value={suburb.id}>{suburb.name}</Select.Option>)}</Select>
                                </FormItem>
                            </TabPane>
                            <TabPane tab={REFERRALS_STRINGS.TABS.TARGET_USER} key="upload">
                                <Row>
                                    <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                                        <span className='inputTitle d-block text-end'>{COMMON_STRING.UPLOAD_FILE('Users via CSV / Excel')}</span>
                                    </Col>
                                    <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                                        <FormItem
                                            name="excelFileId"
                                            rules={[{ required: currentTab == 'current'? true: false, message: COMMON_ALERTS.REQUIRED_SELECT('file') }]}>
                                                <Upload listType="picture" {...excelFileProps(excelFileList, setExcelFileList, '.xlsx, .csv')}>
                                                    {excelFileList.length >= 1 ? null : uploadButton}
                                                </Upload>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </TabPane>
                        </Tabs>
                        <FormItem
                            required
                            name="platform"
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('category') }]}
                            label={<span className='inputTitle'>{REFERRALS_STRINGS.ADD_EDIT_OFFER.SELECT_CATEGORY}</span>}>
                            <Checkbox.Group>
                                <Checkbox value={'android'}><span className='inputTitle'>{COMMON_STRING.ANDROID}</span></Checkbox>
                                <Checkbox value={'iOS'}><span className='inputTitle'>{COMMON_STRING.IOS}</span></Checkbox>
                                <Checkbox value={'Web'}><span className='inputTitle'>{COMMON_STRING.WEB}</span></Checkbox>
                            </Checkbox.Group>
                        </FormItem>
                        <FormItem
                            required
                            name='isActive'
                            initialValue={true}
                            valuePropName='checked'
                            label={<span className='inputTitle'>{COMMON_STRING.ACTIVE}</span>}>
                            <Switch />
                        </FormItem>
                    </Col>
                </Row>
                <Row justify='center'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="btnBoxMulti">
                        <button type='button' className="cancelBtnMulti" onClick={() => navigate(-1)}>{COMMON_STRING.BACK_BTN}</button>
                        <button type='submit' className="addBtnMulti">{location?.offerId? COMMON_STRING.UPDATE_BTN :COMMON_STRING.CREATE_BTN}</button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default AddEditOffer;
