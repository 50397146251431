import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Modal, Row, Select, Table, Tooltip } from 'antd';
import PaginationCom from '../../components/PaginationCom';
import { tableSortIconToggle } from '../../utility/tableSorting';
import { toast } from 'react-toastify';
import { APP_USERS } from '../../services';
import DeleteModel from '../../components/DeleteModel';
import { useNavigate } from 'react-router-dom';
import SelectArrow from '../../components/Icon/SelectArrow';
import InfoCircleIcon from '../../components/Icon/InfoCircleIcon';
import DeleteIcon from '../../components/Icon/DeleteIcon';
import BlockIcon from '../../components/Icon/BlockIcon';
import { COMMON_ALERTS, COMMON_STRING, FILTER_STRING, TABLE_STRING, USER_DETAILS_STRINGS } from '../../services/strings';
import UnBlockIcon from '../../components/Icon/UnBlockIcon';
import { DROPDOWN } from '../../services/Dropdown/dropdowns';

function Appusers({ permissions }) {
  const navigate = useNavigate();
  const [appUsers, setAppUsers] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [blockUserModalVisible, setBlockUserModalVisible] = useState(false);
  const [blockInputValue, setBlockInputValue] = useState('');
  const [blockModalData, setBlockModalData] = useState({ title: 'Block', id: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [sort, setSort] = useState(null);
  const [tableDataLength, setTableDataLength] = useState(0);
  const [CountriesMenu, setCountriesMenu] = useState([]);
  const [StatesMenu, setStatesMenu] = useState([]);
  const [CitiesMenu, setCitiesMenu] = useState([]);
  const [SuburbsMenu, setSuburbsMenu] = useState([]);
  const [FilterSuburb, setFilterSuburb] = useState(null);
  const [FilterCity, setFilterCity] = useState(null);
  const [FilterState, setFilterState] = useState(null);
  const [FilterCountry, setFilterCountry] = useState(null);

  const fetchAppUsers = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    country = null,
    state = null,
    city = null,
    suburb = null
  ) => {
    setTableLoading(true);
    try {
      const res = await APP_USERS.GET(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        country,
        state,
        city,
        suburb
      );
      if (res.status === 1 || res.status === '1') {
        setAppUsers(res);
        setTableDataLength(res?.data && res?.data.length);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setTableLoading(false);
    }
  };

  const fetchLocations = async () => {
    const countryRes = await DROPDOWN.GET_COUNTRIES();
    if (countryRes.status) {
      setCountriesMenu(countryRes.data);
    }
    const stateRes = await DROPDOWN.GET_STATES();
    if (stateRes.status) {
      setStatesMenu(stateRes.data);
    }

    const citiesRes = await DROPDOWN.GET_CITY();
    if (citiesRes.status) {
      setCitiesMenu(citiesRes.data);
    }

    const suburbsRes = await DROPDOWN.GET_SUBURBS();
    if (suburbsRes.status) {
      setSuburbsMenu(suburbsRes.data);
    }
  };

  useEffect(() => { fetchLocations(); }, []);

  const deleteAppUser = async () => {
    try {
      const res = await APP_USERS.DELETE(deleteId);
      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        toast.success(res?.message);
        //If this page only have one element so we need to reduce the page size
        let page = currentPage;
        if (appUsers?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          fetchAppUsers(page, pageSize, search, sort, FilterCountry, FilterState, FilterCity, FilterSuburb);
        }
      }
    } catch (err) {
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    }
  };

  const blockUnblockUser = async () => {
    const payload = {
      userBlock: !blockModalData.userBlock,
      blockReason: blockInputValue,
    };
    try {
      const res = await APP_USERS.BLOCK(blockModalData?.id, payload);
      if (res.status === 1) {
        toast.success(`user ${blockModalData?.title} successfully`);
        fetchAppUsers(currentPage, pageSize, search, sort, FilterCountry, FilterState, FilterCity, FilterSuburb);
      }
    } catch (err) {
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setBlockInputValue(null);
      setBlockUserModalVisible(false);
    }
  };


  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (_pagination, _filters, sorter, _extra) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  useEffect(() => {
    fetchAppUsers(currentPage, pageSize, search, sort, FilterCountry, FilterState, FilterCity, FilterSuburb);
  }, [currentPage, pageSize, search, sort, FilterCountry, FilterState, FilterCity, FilterSuburb]);

  const columns = [
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{USER_DETAILS_STRINGS.TABLE.NAME}</span>, 'name', column),
      dataIndex: 'firstname',
      key: 'name',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{USER_DETAILS_STRINGS.TABLE.EMAIL}</span>, 'email', column),
      key: 'email',
      dataIndex: 'email',
      align: 'left',
      width: 250,
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? <span className="tabletext">{value}</span> : '-'),
    },
    {
      title: (column) => <span className="tableTitle">{USER_DETAILS_STRINGS.TABLE.CONTACT}</span>,
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'center',
      sort: false,
      width: 200,
      render: (value, record) => (value ? <span className="tabletext">+{record.countryCode + ' ' + record.phoneNumber}</span> : '-'),
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      key: 'Actions',
      align: 'center',
      dataIndex: 'Actions',
      width: 200,
      render: (item, record) => {
        return (
          <div className='actionCol'>
            <InfoCircleIcon
              onClick={() => goUserDetails(record.id)}
              className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip
                title={record?.userBlock ? 'Unblock User' : ' Block User'}
              >
                {record?.userBlock ? (
                  <UnBlockIcon
                    className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                    onClick={() => {
                      setBlockModalData({
                        title: 'Unblock',
                        id: record?.id,
                        userBlock: record?.userBlock || false,
                        blockReason: record?.blockReason,
                      });
                      setBlockUserModalVisible(true);
                    }}
                  />
                ) : (
                  <BlockIcon
                    className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                    onClick={() => {
                      setBlockModalData({
                        title: 'Block',
                        id: record?.id,
                        userBlock: record?.userBlock || false,
                        blockReason: record?.blockReason,
                      });
                      setBlockUserModalVisible(true);
                    }}
                  />
                )}
              </Tooltip>
            )}
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete User'>
                <DeleteIcon
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const goUserDetails = (userId) => {
    navigate('/users/details', {
      state: {
        userId: userId,
        permissions: permissions
      }
    });
  };

  if (permissions?.isUpdate === false && permissions?.isDelete === false) {
    delete columns[7];
  }
  return (
    <div className='appuserLayout'>
      {/* Search Box */}
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="filterWithSearchRow">
          <Form className='searchForm'>
            <label className="searchInputLabel" htmlFor="searchInput">Search</label>
            <div className="searchBox">
              <Input
                type='search'
                placeholder={COMMON_STRING.SEARCH_STR + 'Service'}
                className='input-control searchInpGlobal'
                value={tempSearch}
                onChange={(e) => setTempSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e?.key === 'Enter' && e.target.value) {
                    setSearch(e.target.value);
                  }
                  else if (!e.target.value) {
                    setSearch(null);
                  }
                }}
              />
            </div>
          </Form>
          <div className="selectBoxWithSearch">
            <div className="filterSelect">
              <Select defaultValue={FILTER_STRING.COUNTRY} suffixIcon={<SelectArrow />} className="m-auto cursor-pointer filterDropdown" bordered={false} onChange={e => { setCurrentPage(1); setFilterCountry(e); }}><Select.Option value=''>Country</Select.Option>{CountriesMenu.map((country, key) => <Select.Option key={key} value={country.name}>{country.name}</Select.Option>)}</Select>
            </div>
            <div className="filterSelect">
              <Select defaultValue={FILTER_STRING.STATE} suffixIcon={<SelectArrow />} className="m-auto cursor-pointer filterDropdown" bordered={false} onChange={e => { setCurrentPage(1); setFilterState(e); }}><Select.Option>State</Select.Option>{StatesMenu.map((country, key) => <Select.Option key={key} value={country.name}>{country.name}</Select.Option>)}</Select>
            </div>
            <div className="filterSelect">
              <Select defaultValue={FILTER_STRING.CITY} suffixIcon={<SelectArrow />} className="m-auto cursor-pointer filterDropdown" bordered={false} onChange={e => { setCurrentPage(1); setFilterCity(e); }}><Select.Option>City</Select.Option>{CitiesMenu.map((country, key) => <Select.Option key={key} value={country.name}>{country.name}</Select.Option>)}</Select>
            </div>
            <div className="filterSelect">
              <Select defaultValue={FILTER_STRING.SUBURBS} suffixIcon={<SelectArrow />} className="m-auto cursor-pointer filterDropdown" bordered={false} onChange={e => { setCurrentPage(1); setFilterSuburb(e); }}><Select.Option>Country</Select.Option>{SuburbsMenu.map((country, key) => <Select.Option key={key} value={country.name}>{country.name}</Select.Option>)}</Select>
            </div>
          </div>
        </Col>
      </Row>
      {/* Table  */}
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-3">
          <Table
            columns={columns}
            dataSource={appUsers?.data}
            pagination={false}
            rowKey='id'
            loading={tableLoading}
            onChange={onChange}
            scroll={{ x: 400 }}
            showSorterTooltip={false}
          />

          {appUsers?.total > 10 && (
            <div className='text-end bg-white mt-7'>
              <PaginationCom
                onChange={onChangePage}
                showSizeChanger={true}
                current={currentPage}
                showLessItems={true}
                total={appUsers?.total}
              />
            </div>
          )}
        </Col>
      </Row>
      <Modal
        centered='true'
        okText={blockModalData?.title}
        cancelText='cancel'
        style={{ textAlign: 'center' }}
        title={blockModalData?.title}
        open={blockUserModalVisible}
        visible={blockUserModalVisible}
        onOk={blockUnblockUser}
        okButtonProps={{
          style: {
            backgroundColor: '#324666'
          }
        }}
        onCancel={() => setBlockUserModalVisible(false)}>
        <h3>Why want to {blockModalData?.title}</h3>
        {blockModalData?.blockReason && (
          <p>
            {blockModalData?.userBlock ? 'Block' : 'Unblock'} Reason :{' '}
            {blockModalData?.blockReason}
          </p>
        )}
        <Input
          placeholder={`Why want to ${blockModalData?.title}`}
          className='input-control'
          value={blockInputValue}
          onChange={(e) => setBlockInputValue(e?.target?.value)}
        ></Input>
      </Modal>

      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteAppUser}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </div>
  );
}

Appusers.propTypes = {
  permissions: PropTypes.any
};

export default Appusers;