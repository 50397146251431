import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { DASHBOARD_STRING } from '../../services/strings';

const data = [
  {
    name: 'Jan',
    Android: 4000,
    iOS: 2400,
    Web: 2400,
    amt: 2400,
  },
  {
    name: 'Feb',
    Android: 3000,
    iOS: 1398,
    Web: 2400,
    amt: 2210,
  },
  {
    name: 'Mar',
    Android: 2000,
    iOS: 9800,
    Web: 2400,
    amt: 2290,
  },
  {
    name: 'Apr',
    Android: 2780,
    iOS: 3908,
    Web: 2400,
    amt: 2000,
  },
  {
    name: 'May',
    Android: 1890,
    iOS: 4800,
    Web: 2400,
    amt: 2181,
  },
  {
    name: 'Jun',
    Android: 3490,
    iOS: 4300,
    Web: 2400,
    amt: 2100,
  },
  {
    name: 'Jul',
    Android: 2390,
    iOS: 3800,
    Web: 2400,
    amt: 2500,
  },
  {
    name: 'Aug',
    Android: 4000,
    iOS: 2400,
    Web: 2400,
    amt: 2400,
  },
  {
    name: 'Sep',
    Android: 3000,
    iOS: 1398,
    Web: 2400,
    amt: 2210,
  },
  {
    name: 'Oct',
    Android: 2000,
    iOS: 9800,
    Web: 2400,
    amt: 2290,
  },
  {
    name: 'Nov',
    Android: 2780,
    iOS: 3908,
    Web: 2400,
    amt: 2000,
  },
  {
    name: 'May',
    Android: 1890,
    iOS: 4800,
    Web: 2400,
    amt: 2181,
  },
  {
    name: 'Dec',
    Android: 2390,
    iOS: 3800,
    Web: 2400,
    amt: 2500,
  }
];

export default class ReferralChart extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/stacked-bar-chart-s47i2';

  render() {
    return (
      <div className='bg-white box-shadow-3 border-radius-10 p-2 dashboardCards propertyCount_card'>
        <h2 className='font-weight-700 mb-0'>{DASHBOARD_STRING.APP_REFERRALS}</h2>
        <div className='total-media d-flex align-items-center justify-content-center mb-4'></div>
        <BarChart
          width={400}
          height={250}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="0" stroke='false' />
          <XAxis dataKey="name" />
          {/* <YAxis /> */}
          <Tooltip />
          <Legend height={26} width={26} iconSize={26} iconType={'square'} />
          <Bar dataKey="Android" stackId="a" fill="#FBB6B3" />
          <Bar dataKey="iOS" stackId="a" fill="#F66D66" />
          <Bar dataKey="Web" stackId="a" fill="#AA110A" />
        </BarChart>
      </div>
    );
  }
}
