import { Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { DASHBOARD_STRING } from '../../services/strings';
import PropTypes from 'prop-types';

const TotalDownloads = (props) => {
  const MonthsList = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.data?.selectedPeriod) {
      const data = MonthsList.map(month => {
        const obj = { name: month };
        const arr = props.data?.selectedPeriod.filter(period => period.months == month);
        obj['Android'] = 0; obj['Web'] = 0; obj['iOS'] = 0;
        arr?.map((pageObj, index) => {
          if (pageObj?.signUpPlateform === 'android')
            obj['Android'] = pageObj.total_users ? pageObj.total_users : 0;
          if (pageObj?.signUpPlateform === 'web')
            obj['Web'] = pageObj.total_users ? pageObj.total_users : 0;
          if (arr[0]?.signUpPlateform === 'ios')
            obj['iOS'] = pageObj.total_users ? pageObj.total_users : 0;
        });

        return obj;
      });
      setData(data);
    }
  }, [props.data]);


  return (
    <Col xs={24} sm={24} md={24} lg={15} xl={15} className='mt-4 gutter-row'>
      <div className='bg-white box-shadow-3 border-radius-10 p-2 dashboardCards total_downloads_card'>
        <h2 className='font-weight-700 mb-0'>{DASHBOARD_STRING.USERS_DOWNLOAD}</h2>
        <div className='total-media d-flex align-items-center justify-content-center mb-4'></div>
        <ResponsiveContainer width="100%" height="80%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 10
            }}
          >
            <XAxis stroke='#324666' dataKey="name" height={60} />
            <YAxis stroke='#324666' />
            <Tooltip />
            <Legend iconSize={26} formatter={(value) => <span className='lagendText'>{value}</span>} iconType={'square'} />
            <Line type="monotone" dataKey="Android" stroke="#FBB6B3" strokeWidth={2} />
            <Line type="monotone" dataKey="iOS" stroke="#F44840" strokeWidth={2} />
            <Line type="monotone" dataKey="Web" stroke="#610A06" strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Col>
  );
};

TotalDownloads.propTypes = {
  'data': PropTypes.any
};

export default TotalDownloads;