import { Checkbox, Col, DatePicker, Form, Input, Row, Select, Slider, Space, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import CalenderDarkIcon from '../../components/Icon/CalenderDarkIcon';
import PaginationCom from '../../components/PaginationCom';
import { tableSortIconToggle } from '../../utility/tableSorting';
import { PROPERTIES } from '../../services/Property/Properties';
import { toast } from 'react-toastify';
import moment from 'moment';
import ValidateIcon from '../../components/Icon/ValidateIcon';
import DeleteModel from '../../components/DeleteModel';
import { useNavigate } from 'react-router-dom';
import SelectArrow from '../../components/Icon/SelectArrow';
import InfoCircleIcon from '../../components/Icon/InfoCircleIcon';
import PropTypes from 'prop-types';
import DeleteIcon from '../../components/Icon/DeleteIcon';
import FilterToogleBtn from '../../components/FilterToogleBtn';
import { COMMON_ALERTS, FILTER_STRING, PROPERTIES_STRING, TABLE_STRING } from '../../services/strings';
import { DROPDOWN } from '../../services/Dropdown/dropdowns';

const PropertiesPage = ({ permissions }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [properties, setproperties] = useState(null);
  const [tableLoading, setTableLoading] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [sort, setSort] = useState(null);
  const [tableDataLength, setTableDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [CountriesMenu, setCountriesMenu] = useState([{ id: "", name: "Country" }]);
  const [StatesMenu, setStatesMenu] = useState([{ id: "", name: "State" }]);
  const [SuburbsMenu, setSuburbsMenu] = useState([{ id: "", name: "Suburbs" }]);
  const [PropertypeMenu, setPropertypeMenu] = useState([{ id: "", name: "Property Type" }]);
  const [CitiesMenu, setCitiesMenu] = useState([{ id: "", name: "City" }]);
  const [CouncileMenu, setCouncileMenu] = useState([{ id: "", name: "Council" }]);
  const [FilterStartDate, setFilterStartDate] = useState(null);
  const [FilterEndDate, setFilterEndDate] = useState(null);
  const [FilterCountry, setFilterCountry] = useState('');
  const [FilterState, setFilterState] = useState('');
  const [FilterCity, setFilterCity] = useState('');
  const [FilterSuburb, setFilterSuburb] = useState('');
  const [FilterPropertyType, setFilterPropertyType] = useState('');
  const [FilterStatus, setFilterStatus] = useState('');
  const [FilterCarSpace, setFilterCarSpace] = useState('0-0');
  const [FilterBeds, setFilterBeds] = useState('');
  const [FilterBaths, setFilterBaths] = useState('');
  const [FilterLandSpace, setFilterLandSpace] = useState('0-0');
  const [FilterCouncil, setFilterCouncil] = useState('');
  const [FilterPending, setFilterPending] = useState('');
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [validateCheckbox, setValidateCheckbox] = useState(false);

  const fetchCountries = async () => {
    const countriesData = await DROPDOWN.GET_COUNTRIES();
    if (countriesData.status && Array.isArray(countriesData.data)) {
      setCountriesMenu([...CountriesMenu, ...countriesData.data]);
    }
  }

  const fetchStates = async () => {
    const statesData = await DROPDOWN.GET_STATES()
    if (statesData.status && Array.isArray(statesData.data)) {
      setStatesMenu([...StatesMenu, ...statesData.data]);
    }
  }

  const fetchSuburbs = async () => {
    const suburbsData = await DROPDOWN.GET_SUBURBS()
    if (suburbsData.status && Array.isArray(suburbsData.data)) {
      setSuburbsMenu([...SuburbsMenu, ...suburbsData.data]);
    }
  }

  const fetchCities = async () => {
    const citiesData = await DROPDOWN.GET_CITY()
    if (citiesData.status && Array.isArray(citiesData.data)) {
      setCitiesMenu([...CitiesMenu, ...citiesData.data]);
    }
  }

  const fetchPropertyTypes = async () => {
    const pTypeData = await DROPDOWN.GET_PROPERTY_TYPES();
    if (pTypeData.status && Array.isArray(pTypeData.data)) {
      setPropertypeMenu([...PropertypeMenu, ...pTypeData.data]);
    }
  }

  const fetchCounciles = async () => {
    const councilesData = await DROPDOWN.GET_COUNCILS();
    if (councilesData.status && Array.isArray(councilesData.data)) {
      setCouncileMenu([...CouncileMenu, ...councilesData.data]);
    }
  }

  useEffect(() => {
    fetchCountries();
    fetchStates();
    fetchSuburbs();
    fetchCities();
    fetchPropertyTypes();
    fetchCounciles();
  }, []);

  const goPropertyDetails = (id) => {
    navigate('/properties/details', {
      state: {
        propertyId: id
      }
    });
  }

  const fetchProperties = async (
    page, pageSize, search, sort,
    FilterStartDate, FilterEndDate, FilterCountry, FilterState, FilterCity,
    FilterSuburb, FilterCouncil, FilterPropertyType, FilterStatus, FilterCarSpace = '0-500',
    FilterBaths, FilterBeds, FilterLandSpace = '0-50000', FilterPending) => {
    setTableLoading(true);
    const res = await PROPERTIES.GET(pageSize,
      (page - 1) * pageSize,
      search,
      sort,
      FilterStartDate, FilterEndDate, FilterCountry, FilterState, FilterCity,
      FilterSuburb, FilterCouncil, FilterBeds, FilterBaths, FilterPropertyType, FilterCarSpace, FilterLandSpace, FilterPending, FilterStatus, validateCheckbox);
    try {
      if (res.status === 1 || res.status === '1') {
        setproperties(res);
        setTableDataLength(res?.data && res?.data.length);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setTableLoading(false);
    }
  }

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const deleteProperties = async () => {
    try {
      const res = await PROPERTIES.DELETE(deleteId);

      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        toast.success(res?.message);
        //If this page only have one element so we need to reduce the page size
        let page = currentPage;
        if (properties?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          fetchProperties(page, pageSize, search, sort);
        }
      }
    } catch (err) {
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    }
  };

  const carSpaces = {
    0: '0 sqft',
    500: '500 sqft'
  };

  const landSpaceMarks = {
    0: '0 sqft',
    50000: '50000 sqft'
  };

  const validateProperty = async (id) => {
    navigate('/properties/validate', {
      state: {
        propertyId: id
      }
    });
  }

  const handleChangeOfSearch = (e) => {
    setTempSearch(e.target.value);
    if (!e.target.value) {
      setCurrentPage(1);
      setSearch(null);
      setTempSearch(null);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  const clearAll = () => {
    form.resetFields();
    setFilterStartDate(null);
    setFilterEndDate(null);
    setSearch(null);
    setFilterCountry('');
    setFilterState('');
    setFilterCity('');
    setFilterSuburb('');
    setFilterPropertyType('');
    setFilterStatus('');
    setFilterCarSpace('0-300');
    setFilterBeds('');
    setFilterBaths('');
    setFilterLandSpace('0-40000');
    setFilterCouncil('');
    setFilterPending(false);
  }

  useEffect(() => {
    fetchProperties(currentPage, pageSize, search, sort,
      FilterStartDate, FilterEndDate, FilterCountry, FilterState, FilterCity,
      FilterSuburb, FilterCouncil, FilterPropertyType, FilterStatus, FilterCarSpace,
      FilterBaths, FilterBeds, FilterLandSpace, FilterPending);

  }, [currentPage, null, pageSize, search, sort,
    FilterStartDate, FilterEndDate, FilterCountry, FilterState, FilterCity,
    FilterSuburb, FilterCouncil, FilterPropertyType, FilterStatus, FilterCarSpace,
    FilterBaths, FilterBeds, FilterLandSpace, FilterPending, validateCheckbox]);

  const columns = [
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{PROPERTIES_STRING.TABLE.PROPERTY_LIST}</span>, 'name', column),
      dataIndex: 'name',
      key: 'propertList',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{PROPERTIES_STRING.TABLE.PROPERTY_TYPE}</span>, 'property_type', column),
      key: 'property_type',
      dataIndex: 'property_type',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? <span className="tabletext">{value.name}</span> : '-'),
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{PROPERTIES_STRING.TABLE.PURCHASE_DATE}</span>, 'purchaseDate', column),
      key: 'purchaseDate',
      dataIndex: 'purchaseDate',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? <span className="tabletext">{moment(new Date(value)).format("DD/MM/YYYY")}</span> : '-'),
    },
    {
      title: (column) => (<div className='pValidateColumn'><span className="tableTitle">{PROPERTIES_STRING.TABLE.VALIDATE_PROPERTY} </span><Checkbox checked={validateCheckbox} onChange={() => setValidateCheckbox(!validateCheckbox)} /></div>),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'center',
      sort: false,
      render: (item, record) => {
        if (record.isValidate) {
          return (<ValidateIcon />);
        }
        else {
          return (<span onClick={() => validateProperty(record.id)} className='validateButton'>{PROPERTIES_STRING.VALIDATE_BTN}</span>);
        }
      },
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">Status</span>, 'propertyStatus', column),
      key: 'propertyStatus',
      dataIndex: 'propertyStatus',
      align: 'center',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? <span className="tabletext">{value}</span> : '-'),
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      key: 'Actions',
      align: 'center',
      dataIndex: 'Actions',
      width: 130,
      render: (item, record) => {
        return (
          <div className='actionCol'>
            <Tooltip title='View Property'>
            <InfoCircleIcon
              onClick={() => goPropertyDetails(record.id)}
              className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
            </Tooltip>
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Property'>
                <DeleteIcon
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className='propertiesLayout'>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} />
        </Col>
      </Row>
      <Row className={isFilterShow ? 'mt-3' : 'd-none'}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row className="fWidthShowBox filterLayout">
            <Col xs={24} sm={24} md={24} lg={11} xl={8}>
              <Form form={form} colon={false} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                <Form.Item label={<span className="inputTitle">{FILTER_STRING.START_DATE}</span>} name='startDate'>
                  <DatePicker className='selectDateInput'
                    placeholder=""
                    suffixIcon={<CalenderDarkIcon />}
                    allowClear
                    onChange={value => setFilterStartDate(value)}
                    disabledDate={d => !d || d.isAfter(FilterEndDate)}
                    picker="date" />
                </Form.Item>
                <Form.Item label={<span className="inputTitle">{FILTER_STRING.END_DATE}</span>} name='endDate'>
                  <DatePicker className='selectDateInput'
                    placeholder=""
                    suffixIcon={<CalenderDarkIcon />}
                    allowClear
                    onChange={value => setFilterEndDate(value)}
                    disabledDate={d => !d || d.isSameOrBefore(FilterStartDate)}
                    picker="date" />
                </Form.Item>
                <Form.Item label={<span className="inputTitle">{FILTER_STRING.SEARCH}</span>} name="search">
                  <Input className='selectDateInput'
                    placeholder="Start Typing To Search Property"
                    value={tempSearch}
                    allowClear
                    onChange={(e) => handleChangeOfSearch(e)}
                    onKeyPress={(e) => {
                      if (e?.key === 'Enter' && e.target.value) {
                        handleSearch();
                      }
                    }}
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col xs={24} sm={24} md={24} lg={13} xl={16} className="filterDropDownBox">
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.COUNTRY} onChange={value => setFilterCountry(value)} value={FilterCountry} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{CountriesMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.STATE} onChange={value => setFilterState(value)} value={FilterState} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{StatesMenu.map((state, key) => <Select.Option key={key} value={state.id}>{state.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.SUBURBS} onChange={value => setFilterSuburb(value)} value={FilterSuburb} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{SuburbsMenu.map((suburb, key) => <Select.Option key={key} value={suburb.id}>{suburb.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.CITY} onChange={value => setFilterCity(value)} value={FilterCity} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{CitiesMenu.map((city, key) => <Select.Option key={key} value={city.id}>{city.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={PROPERTIES_STRING.FILTER.PROPERTY_TYPE} onChange={value => setFilterPropertyType(value)} value={FilterPropertyType} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{PropertypeMenu.map((type, key) => <Select.Option key={key} value={type.id}>{type.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select onChange={value => setFilterStatus(value)} defaultValue={PROPERTIES_STRING.FILTER.STATUS} value={FilterStatus} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>
                  <Select.Option value=''>Status</Select.Option>
                  <Select.Option value='completed'>Completed</Select.Option>
                  <Select.Option value='under completed'>Under Completed</Select.Option>
                </Select>
              </div>
              <div className="filterSelect carspaces">
                <label className="title">{PROPERTIES_STRING.FILTER.CAR_SPACE}</label>
                <Slider dots={false} onChange={value => setFilterCarSpace(value[0] + '-' + value[1])} value={FilterCarSpace.split('-')} style={{ width: "147px" }} max={500} handleStyle={{ background: "#FFFFFF", border: "1.34997px solid #FFFFFF", boxShadow: "0px 0px 2.69994px rgba(0, 0, 0, 0.06), 0px 1.79996px 3.59992px rgba(0, 0, 0, 0.08)" }} trackStyle={{ background: "#879EC4" }} marks={carSpaces} range defaultValue={[0, 300]} />
              </div>
              <div className="filterSelect ">
                <Select onChange={value => setFilterBeds(value)} value={FilterBeds} defaultValue={''} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>
                  <Select.Option value=''>{PROPERTIES_STRING.FILTER.NO_OF_BEDS}</Select.Option>
                  {[...Array(10).keys()].map((arr, key) => <Select.Option key={key} value={arr + 1}>{PROPERTIES_STRING.FILTER.NO_OF_BEDS} ({arr + 1})</Select.Option>)}
                </Select>
              </div>
              <div className="filterSelect">
                <Select onChange={value => setFilterBaths(value)} value={FilterBaths} defaultValue={''} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>
                  <Select.Option value=''>{PROPERTIES_STRING.FILTER.NO_OF_BATH}</Select.Option>
                  {[...Array(10).keys()].map((arr, key) => <Select.Option key={key} value={arr + 1}>{PROPERTIES_STRING.FILTER.NO_OF_BATH} ({arr + 1})</Select.Option>)}
                </Select>
              </div>
              <div className="filterSelect landspace">
                <label className="title">{PROPERTIES_STRING.FILTER.LAND_SPACE}</label>
                <Slider dots={false} onChange={value => setFilterLandSpace(value[0] + '-' + value[1])} value={FilterLandSpace.split('-')} style={{ width: "147px" }} max={50000} handleStyle={{ background: "#FFFFFF", border: "1.34997px solid #FFFFFF", boxShadow: "0px 0px 2.69994px rgba(0, 0, 0, 0.06), 0px 1.79996px 3.59992px rgba(0, 0, 0, 0.08)" }} trackStyle={{ background: "#879EC4" }} marks={landSpaceMarks} range defaultValue={[0, 40000]} />
              </div>
              <div className="filterSelect">
                <Select onChange={value => setFilterCouncil(value)} value={FilterCouncil} defaultValue={PROPERTIES_STRING.FILTER.COUNCIL} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{CouncileMenu.map((council, key) => <Select.Option key={key} value={council.id}>{council.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect pendingValidate">
                <Space className='spacedrpdown'><label className="title" htmlFor="checkValidate">{PROPERTIES_STRING.FILTER.PENDING_VALID_PROPERTY}</label><Checkbox id="checkValidate" onClick={() => setFilterPending(!FilterPending)} checked={FilterPending} /></Space>
              </div>
              <div className="filterSelect clearAll" onClick={() => clearAll()}>Clear all</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-3">
          <Table
            columns={columns}
            dataSource={properties?.data}
            pagination={false}
            rowKey='id'
            loading={tableLoading}
            onChange={onChange}
            scroll={{ x: 400 }}
          />

          {properties?.total > 10 && (
            <div className='text-end bg-white mt-7'>
              <PaginationCom
                onChange={onChangePage}
                showSizeChanger={true}
                current={currentPage}
                showLessItems={true}
                total={properties?.total}

              />
            </div>
          )}
        </Col>
      </Row>
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteProperties}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </div>
  );
}

PropertiesPage.propTypes = {
  permissions: PropTypes.any
}

export default PropertiesPage;
