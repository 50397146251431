import { methods, serviceMaker } from '../service';

const GET_FHBGOALS = (
    limit,
    skip,
    search = null,
    sort = null
) => {
    const query = {
        params: {
            $limit: limit,
            $skip: skip
        },
    };
    if (sort) {
        query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
    } else {
        query['params']['$sort[createdAt]'] = -1;
    }
    if (search) {
        query['params']['search'] = search;
        return serviceMaker(`search/fhbgoal`, methods.GET, query);
    }
    return serviceMaker(`fhbgoal`, methods.GET, query);
};

const GET_FHBGOAL = (id) => {
    return serviceMaker(`fhbgoal/${id}`, methods.GET);
}

const DELETE_FHBGOAL = (id) => serviceMaker(`fhbgoal/${id}`, methods.DELETE);

const CREATE_FHBGOAL = (payload) =>
    serviceMaker(`fhbgoal`, methods.POST, null, payload);

const PATCH_FHBGOAL = (id, payload) =>
    serviceMaker(`fhbgoal/${id}`, methods.PATCH, null, payload);

const FHBGOALS = { //Depreciation Schedules
    ALL: GET_FHBGOALS,
    GET: GET_FHBGOAL,
    DELETE: DELETE_FHBGOAL,
    CREATE: CREATE_FHBGOAL,
    PATCH: PATCH_FHBGOAL
};
export { FHBGOALS };
