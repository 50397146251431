import { methods, serviceMaker } from '../service';

const GET_AVAIL_OFFERS = (Filters) => {
    const payload = {
        ...Filters,
        pd_referral: Filters.pd_referral.join('_'),
        pd_referee: Filters.pd_referee.join('_'),
        sDate: Filters.sDate? new Date(Filters.sDate): null,
        eDate: Filters.eDate? new Date(Filters.eDate): null
    }
    delete payload.page;

    const query = {
    params: payload
    };

    if (Filters.sort) {
    query['params'][`$sort[${Object.keys(Filters.sort)[0]}]`] = Object.values(Filters.sort)[0];
    } else {
    query['params']['$sort[createdAt]'] = -1;
    }

    return serviceMaker(`avail-offer`, methods.GET, query);
};

const PATCH_AVAIL_OFFERS = (id, payload) =>
  serviceMaker(`avail-offer/${id}`, methods.PATCH, null, payload);

const AVAIL_OFFERS = {
    FIND: GET_AVAIL_OFFERS,
    PATCH: PATCH_AVAIL_OFFERS
};



export { AVAIL_OFFERS };
