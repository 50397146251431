import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ADMIN_USER_STRINGS, COMMON_ALERTS, COMMON_STRING } from '../../services/strings';
import { PAGE_MODULES } from '../../services/RoleManagement/pageModulesService';
import { ROLES } from '../../services/Roles/Roles';

const AddEditAdminRole = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const roleId = useLocation().state?.roleId;
    const [Roles, setRoles] = useState(null);
    const [isDataLoaded, setisDataLoaded] = useState(false);
    const [PageModules, setPageModules] = useState(null);
    const [isAdminCheckbox, setisAdminCheckbox] = useState(false);

    useEffect(() => {
        fetchPageModules();
    }, []);

    const fetchRoles = async () => {
        if (roleId) {
            const res = await ROLES.FIND(roleId);
            if (res.status) {
                setRoles(res.data);
                setisAdminCheckbox(res.data.isAdmin);
                const permissions = [];
                const role_permissions = [];
                res.data?.permissions?.map(permission => permissions.push(permission.page.id));
                if (res.data?.permissions.length) {
                    if (res.data?.permissions[0].isUpdate) role_permissions.push('modify');
                    if (res.data?.permissions[0].isDelete) role_permissions.push('delete');
                }
                form.setFieldsValue({
                    'name': res.data?.name,
                    'desc': res.data?.desc,
                    'permissions': permissions,
                    'role_permission': role_permissions
                });
            }

        }
        setisDataLoaded(true);
    }

    const fetchPageModules = async () => {
        const res = await PAGE_MODULES.GET(-1, null, null, { "createdAt": 1 });
        if (Array.isArray(res.data)) {
            setPageModules(res);
            if (roleId !== null) {
                fetchRoles();
            }
        }
    }

    const onFinish = async (e) => {
        const permissions = [];
        e.permissions?.map(permissionId =>
            permissions.push({
                pageModuleId: permissionId,
                isView: true,
                isCreate: e.role_permission.includes("modify"),
                isUpdate: e.role_permission.includes("modify"),
                isDelete: e.role_permission.includes("delete")
            }));

        const payload = {
            name: e.name,
            isAdmin: isAdminCheckbox,
            desc: e.desc
        };

        if(permissions.length){
            Object.assign(payload, {permissions});
        }

        let res;
        if (!roleId) res = await ROLES.CREATE(payload);
        else res = await ROLES.PATCH(roleId, payload);

        try {
            if (res.status) {
                toast.success(!roleId ? COMMON_ALERTS.ADDED_SUCCESS('Rule') : COMMON_ALERTS.UPDATED_SUCCESS('Rule'));
                navigate('/admin-users/roles', { replace: true });
            }
        }
        catch (e) {
            // console.log(e);
        }
    }

    const adminCheckBoxChange = (e) => {
        setisAdminCheckbox(!isAdminCheckbox);
        form.setFieldValue('permissions', []);
    }

    if (isDataLoaded)
        return (
            <div>
                <div className="fWidthShowBox addNwDepScheduleContainer">
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <h2>{!roleId ? ADMIN_USER_STRINGS.ADD_EDIT.ADD_ROLE_LABEL: ADMIN_USER_STRINGS.ADD_EDIT.EDIT_ROLE_LABEL}</h2>
                        </Col>
                    </Row>
                    <Form name="complex-form" className="addformLayout" form={form} onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} colon={false}>
                        <Form.Item
                            label={<span className='inputTitle'> {ADMIN_USER_STRINGS.ADD_EDIT.ROLE_NAME}</span>}
                            name="name"
                            initialValue={Roles?.name}
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('role name') }]}
                        >
                            <Input className='textInput' />
                        </Form.Item>
                        <Form.Item
                            label={<span className='inputTitle'> {ADMIN_USER_STRINGS.ADD_EDIT.ROLE_DESCRIPTION}</span>}
                            name="desc"
                            initialValue={Roles?.desc}
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('role description') }]}
                        >
                            <Input className='textInput' />
                        </Form.Item>
                        <Form.Item
                            label={<span className='inputTitle'> {ADMIN_USER_STRINGS.ADD_EDIT.ADMIN_PERMISSION}</span>}
                            name="isAdmin"
                            initialValue={false}
                        >
                            <span className="inputTitle mr-1" onClick={e => adminCheckBoxChange(true)}>Admin</span><Checkbox value={true} checked={isAdminCheckbox} onChange={e => adminCheckBoxChange(e.target.value)} className={'ml-3'} />
                        </Form.Item>
                        <Form.Item
                            label={<span className='inputTitle'> {ADMIN_USER_STRINGS.ADD_EDIT.ROLE_DESCRIPTION}</span>}
                            name="role_permission">
                            <Checkbox.Group className='w-100' disabled={isAdminCheckbox}>
                                <Checkbox value="modify" style={{ lineHeight: '32px' }}><span className="inputTitle">{ADMIN_USER_STRINGS.ADD_EDIT.MODIFY_PERMISSION}</span></Checkbox>
                                <Checkbox value="delete" style={{ lineHeight: '32px' }}><span className="inputTitle">{ADMIN_USER_STRINGS.ADD_EDIT.DELETE_PERMISSION}</span></Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                        <Form.Item
                            label={<span className='inputTitle'> {ADMIN_USER_STRINGS.ADD_EDIT.SELECT_PERMISSION}</span>}
                            name="permissions"
                            rules={[ { required: !isAdminCheckbox, message: COMMON_ALERTS.REQUIRED_SELECT('section permission') }]}>
                            <Checkbox.Group className='w-100' disabled={isAdminCheckbox}>
                                <Row className='roleChipBox'>{PageModules?.data.map((page, i) => <div className='roleChip' key={i}><Checkbox value={page.id} style={{ lineHeight: '32px' }}>{page.name}</Checkbox></div>)}</Row>
                            </Checkbox.Group>
                        </Form.Item>
                        <Row>
                            <Col xs={24} sm={24} md={{ span: 16, offset: 8 }} lg={{ span: 16, offset: 8 }} xl={{ span: 16, offset: 8 }} className="btnBox">
                                <Button className="cancelBtn" onClick={() => navigate('/admin-users/roles', { replace: true })}>{COMMON_STRING.BACK_BTN}</Button>
                                <Button type="submit" htmlType='submit' className="addBtn">{roleId ? COMMON_STRING.UPDATE_BTN: COMMON_STRING.CREATE_BTN}</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>

            </div>
        );
}

export default AddEditAdminRole;
