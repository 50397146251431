 import React from 'react';

const NotificationsIcon = () => {
  return (
    <>
      <span className='anticon anticon-dashboard ant-menu-item-icon'>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.34167 17.5087C8.34167 18.4253 9.08333 19.167 10 19.167C10.9167 19.167 11.6583 18.4253 11.6583 17.5087H8.34167ZM15.7333 14.017V9.16699C15.7333 6.45866 13.8583 4.19199 11.325 3.59199V2.99199C11.325 2.25866 10.7333 1.66699 10 1.66699C9.26667 1.66699 8.675 2.25866 8.675 2.99199V3.59199C6.14167 4.19199 4.26667 6.45866 4.26667 9.16699V14.017L2.5 15.7837V16.667H17.5V15.7837L15.7333 14.017Z" fill="#B7C5DC"/>
        </svg>
      </span>
    </>
  );
};

export default NotificationsIcon;
