import Axios from 'axios';
import {  BASE_URL } from './constant';
import {  COMMON_ALERTS } from './strings';
import { store } from '../redux/store';
import { LOGOUT } from '../redux/features/userSlice';
import { getLSItem } from './auth/encryptLogin';

export const PDOLLAR = Axios.create({
  baseURL: `${BASE_URL}`,
});

PDOLLAR.interceptors.request.use((config) => {
  let accessToken = getLSItem('pdollar-token');
  config.headers.Authorization = `Bearer ${accessToken}`;
  config.headers['Content-Type'] = 'application/json';
  return config;
});

export const methods = {
  GET: 'GET',
  GET_CONFIG: 'GET_CONFIG',
  POST: 'POST',
  POST_CONFIG: 'POST_CONFIG',
  PUT: 'PUT',
  PUT_CONFIG: 'PUT_CONFIG',
  DELETE: 'DELETE',
  PATCH_CONFIG: 'PATCH_CONFIG',
  PATCH: 'PATCH',
};

export const serviceMaker = async (
  url,
  method,
  params = null,
  data = {},
  config = {}
) => {
  try {
    let result;
    let APIInstance = PDOLLAR;

    switch (method) {
      case 'GET': {
        result = await APIInstance.get(url, params);
        break;
      }
      case 'GET_CONFIG': {
        result = await APIInstance.get(url, config);
        break;
      }
      case 'POST': {
        result = await APIInstance.post(url, data);
        break;
      }
      case 'POST_CONFIG': {
        result = await APIInstance.post(url, data, config);
        break;
      }
      case 'PUT': {
        result = await APIInstance.put(url, data);
        break;
      }
      case 'PUT_CONFIG': {
        result = await APIInstance.put(url, data, config);
        break;
      }
      case 'DELETE': {
        result = await APIInstance.delete(url);
        break;
      }
      case 'PATCH_CONFIG': {
        result = await APIInstance.patch(url, data, config);
        break;
      }
      case 'PATCH': {
        result = await APIInstance.patch(url, data);
        break;
      }
      default: {
        // eslint-disable-next-line no-throw-literal
        throw 'InvalidMethod';
      }
    }
    return result?.data;
  } catch (err) {
    if (err?.response?.status === 401) {
      localStorage.clear();
      store.dispatch(LOGOUT());
      throw Error(COMMON_ALERTS.USER_PASS_WRONG);
    }
    throw err;
  }
};
