 import React from 'react';
import './App.css';
import Routes from './router/router';
import Toast from './components/Toast';

function App() {
  return (
    <div className="App">
        <Routes />
        <Toast />
    </div>
  );
}

export default App;
