 import React from "react";
const UserHeader = () => {
    return <span className='anticon anticon-user ant-menu-item-icon'>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99154 1.66699C5.39154 1.66699 1.6582 5.40033 1.6582 10.0003C1.6582 14.6003 5.39154 18.3337 9.99154 18.3337C14.5915 18.3337 18.3249 14.6003 18.3249 10.0003C18.3249 5.40033 14.5915 1.66699 9.99154 1.66699ZM12.9999 6.95033C13.8915 6.95033 14.6082 7.66699 14.6082 8.55866C14.6082 9.45033 13.8915 10.167 12.9999 10.167C12.1082 10.167 11.3915 9.45033 11.3915 8.55866C11.3832 7.66699 12.1082 6.95033 12.9999 6.95033ZM7.99987 5.63366C9.0832 5.63366 9.96654 6.51699 9.96654 7.60033C9.96654 8.68366 9.0832 9.56699 7.99987 9.56699C6.91654 9.56699 6.0332 8.68366 6.0332 7.60033C6.0332 6.50866 6.9082 5.63366 7.99987 5.63366ZM7.99987 13.242V16.367C5.99987 15.742 4.41654 14.2003 3.71654 12.2337C4.59154 11.3003 6.77487 10.8253 7.99987 10.8253C8.44154 10.8253 8.99987 10.892 9.5832 11.0087C8.21654 11.7337 7.99987 12.692 7.99987 13.242ZM9.99154 16.667C9.76654 16.667 9.54987 16.6587 9.3332 16.6337V13.242C9.3332 12.0587 11.7832 11.467 12.9999 11.467C13.8915 11.467 15.4332 11.792 16.1999 12.4253C15.2249 14.9003 12.8165 16.667 9.99154 16.667Z" fill="#B7C5DC"/>
    </svg>
    </span>;
}

export default UserHeader;

