import { methods, serviceMaker } from "../service";

const GET_SCHEDULED_SERVICES = (
  limit,
  skip,
  search = null,
  sort = null,
  startDate = null,
  endDate = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    //   query['params']['$sort[createdAt]'] = -1;
  }

  if (startDate) {
    query['params']['startDate'] = new Date(startDate);
  }

  if (endDate) {
    query['params']['endDate'] = new Date(endDate);
  }

  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`reports/scheduled-service`, methods.GET, query);
  }

  return serviceMaker(`reports/scheduled-service`, methods.GET, query);
};

const SCHEDULED_SERVICES = {
  REPORT: GET_SCHEDULED_SERVICES
};

export { SCHEDULED_SERVICES };