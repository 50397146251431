import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SelectArrow from '../../../components/Icon/SelectArrow';
import { STAMPDUTYS } from '../../../services/Calculators/StampDuty';
import { CALCULATOR_INFO_STRING, COMMON_ALERTS, COMMON_STRING } from '../../../services/strings';
import { DROPDOWN } from '../../../services/Dropdown/dropdowns';

const AddEditStampButy = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [States, setStates] = useState([]);
    const dStateData = useLocation().state?.dstate;
    const fetchStates = async () => {
        const res = await DROPDOWN.GET_STATES();
        if (res.status) {
            setStates(res.data);
        }
    }

    useEffect(() => {
        fetchStates();
        if (dStateData) {
            form.setFieldValue('stateId', dStateData.stateId);
            const stamp_duties = dStateData.state.stamp_duties;
            const data = [];
            stamp_duties.map((elem) => {
                data.push({
                    stateId: elem.stateId,
                    minAmount: elem.minAmt,
                    maxAmount: elem.maxAmt,
                    fixCharge: elem.fixedAmt,
                    percentage: elem.percentage,
                    dutbleValue: elem.dutiableValue,
                    id: elem.id
                });
            });
            if (!data.length) form.setFieldValue('stampDutyValues', [""]);
            else form.setFieldValue('stampDutyValues', data);
        }
    }, []);

    const onFinish = async (formData) => {
        const payload = [];
        formData?.stampDutyValues.map(value => {
            let obj = {
                "stateId": formData.stateId,
                "minAmt": value.minAmount,
                "maxAmt": value.maxAmount,
                "fixedAmt": value.fixCharge,
                "percentage": value.percentage,
                "dutiableValue": value.dutbleValue,
                "id": value.id
            };
            payload.push(obj);
        });
        let res = null;
        if (!dStateData) {
            res = await STAMPDUTYS.CREATE(payload);
        }
        else {
            res = await STAMPDUTYS.PATCH(dStateData.id, payload);
        }

        if (res.status) {
            toast.success(!dStateData ? COMMON_ALERTS.ADDED_SUCCESS('Stamp duty') : COMMON_ALERTS.UPDATED_SUCCESS('Stamp duty'));
            navigate('/calculator_info/stamp_duty/management');
        }
    }

    return (
        <div className='addEditStampLayout'>
            <Row className='fWidthShowBox commonLayout'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <h2 className="h2title">{dStateData ? CALCULATOR_INFO_STRING.STAMP_DUTY.ADD_EDIT.EDIT_LABEL : CALCULATOR_INFO_STRING.STAMP_DUTY.ADD_EDIT.ADD_LABEL}</h2>
                    <Form form={form} onFinish={onFinish} colon={false} initialValues={{ stampDutyValues: [""] }}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item className='form-itemr' name="stateId" label={<span className="input-label">{CALCULATOR_INFO_STRING.STAMP_DUTY.ADD_EDIT.SELECT_STATE}</span>} rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('state') }]}>
                                    <Select className='input-control' suffixIcon={<SelectArrow />}>{States.map((state, key) => <Select.Option key={key} value={state.id}>{state.name}</Select.Option>)}</Select>
                                </Form.Item>
                            </Col>
                            <Form.List name="stampDutyValues">
                                {(fields, { add, remove }) => (
                                    <>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="btnBoxMulti justify-end">
                                            <button type='button' className="addBtnMulti" onClick={() => add()}>{COMMON_STRING.ADD_ROW_BTN}</button>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            {fields.map((field, fieldIndex) => (
                                                <div key={field.key}>
                                                    <Row>
                                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                            <h2 className="h2Subtitle">{CALCULATOR_INFO_STRING.STAMP_DUTY.ADD_EDIT.STAMP_DUTY_INFO}</h2>
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                                            <Row>
                                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                                    <Form.Item {...field} name={[field.name, 'minAmount']} labelAlign={'left'}
                                                                        className='form-itemr flex-column minMaxLabel'
                                                                        label={<span className="label-mr-0 input-label">{CALCULATOR_INFO_STRING.STAMP_DUTY.ADD_EDIT.MIN_AMOUNT_LBL}</span>}
                                                                        required
                                                                        rules={[{
                                                                            validator: (_, value) => {
                                                                                var maxValue = form.getFieldValue('stampDutyValues')[fieldIndex]?.maxAmount;
                                                                                if (value) {
                                                                                    var minVal = parseInt(value);
                                                                                    if (minVal < 0) {
                                                                                        return Promise.reject(COMMON_ALERTS.NON_NEGETIVE);
                                                                                    }
                                                                                    //0 represents infinite value in max value
                                                                                    if (maxValue != 0 && maxValue && maxValue < parseInt(minVal)) {
                                                                                        return Promise.reject('Min value shouldn\'t be greater than max value.');
                                                                                    }
                                                                                    return Promise.resolve();
                                                                                }
                                                                                return Promise.reject(COMMON_ALERTS.REQUIRED_SELECT('min amount'));
                                                                            }
                                                                        }]}>
                                                                        <InputNumber type={'number'} className='input-control' controls={false} />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                                    <Form.Item name={[field.name, 'maxAmount']}
                                                                        className='form-itemr flex-column minMaxLabel'
                                                                        required
                                                                        labelAlign={'left'} label={<span className="label-mr-0 input-label">{CALCULATOR_INFO_STRING.STAMP_DUTY.ADD_EDIT.MAX_AMOUNT_LBL}</span>}
                                                                        rules={[{
                                                                            validator: (_, value) => {
                                                                                var minValue = form.getFieldValue('stampDutyValues')[fieldIndex]?.minAmount;
                                                                                var maxVal = parseInt(value);
                                                                                if (maxVal || (maxVal === 0)) {

                                                                                    if (maxVal < 0) {
                                                                                        return Promise.reject(COMMON_ALERTS.NON_NEGETIVE);
                                                                                    }

                                                                                    //'0 will represent as infinite in max value!'
                                                                                    if (minValue && maxVal != 0 && parseInt(minValue) > maxVal) {
                                                                                        return Promise.reject('Max value shouldn\'t be less than min value.');
                                                                                    }
                                                                                    return Promise.resolve();
                                                                                }
                                                                                return Promise.reject(COMMON_ALERTS.REQUIRED_SELECT('max amount'));
                                                                            }
                                                                        }]}>
                                                                        <InputNumber type={'number'} className='input-control' controls={false} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={24} sm={24} md={24} lg={18} xl={12}>
                                                            <Row>
                                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                    <Form.Item {...field} name={[field.name, 'fixCharge']} labelAlign={'left'} className='form-itemr' label={<span className="input-label">{CALCULATOR_INFO_STRING.STAMP_DUTY.ADD_EDIT.FIXED_CHARGE_LBL}</span>}
                                                                        required
                                                                        rules={[{
                                                                            validator: (_, value) => {
                                                                                if (value) {
                                                                                    if (parseInt(value) < 0) {
                                                                                        return Promise.reject(COMMON_ALERTS.NON_NEGETIVE);
                                                                                    }
                                                                                    return Promise.resolve();
                                                                                }
                                                                                return Promise.reject(COMMON_ALERTS.REQUIRED_SELECT('fixed charge'));
                                                                            }
                                                                        }]}>
                                                                        <InputNumber type={'number'} className='input-control' controls={false} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Row>
                                                                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                                                    <div className="singleInpLabelBx">
                                                                        <span className="input-label">{CALCULATOR_INFO_STRING.STAMP_DUTY.ADD_EDIT.LIMIT_EXCEED_LBL}</span>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                                                                    <Form.Item {...field} name={[field.name, 'percentage']} labelAlign={'left'} className='form-itemr' label={<span className="input-label">{CALCULATOR_INFO_STRING.STAMP_DUTY.ADD_EDIT.PERCENTAGE_LBL} </span>} 
                                                                        required
                                                                        rules={[{
                                                                        validator: (_, value) => {
                                                                            if (!value && value!=0) {
                                                                                return Promise.reject(COMMON_ALERTS.REQUIRED_INPUT('percentage'));
                                                                            }
                                                                            if (parseInt(value) > 100) {
                                                                                return Promise.reject(COMMON_ALERTS.INVALID_ERROR('percentage'));
                                                                            }
                                                                            if (parseInt(value) < 0) {
                                                                                return Promise.reject(COMMON_ALERTS.NON_NEGETIVE);
                                                                            }

                                                                            return Promise.resolve();
                                                                        }
                                                                    }]}>
                                                                        <InputNumber type={'number'} className='input-control' controls={false} />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                                                                    <Form.Item {...field} name={[field.name, 'dutbleValue']} labelAlign={'left'}
                                                                        className='form-itemr' label={<span className="input-label">{CALCULATOR_INFO_STRING.STAMP_DUTY.ADD_EDIT.DUTIABLE_VALUE_LBL} </span>}
                                                                        required
                                                                        rules={[{
                                                                            validator: (_, value) => {
                                                                                if (value || parseInt(value) === 0) {
                                                                                    if (parseInt(value) < 0) {
                                                                                        return Promise.reject(COMMON_ALERTS.NON_NEGETIVE);
                                                                                    }
                                                                                    return Promise.resolve();
                                                                                }
                                                                                return Promise.reject(COMMON_ALERTS.REQUIRED_SELECT('dutible value limit'));
                                                                            }
                                                                        }]}>
                                                                        <InputNumber type={'number'} className='input-control' controls={false} />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Form.Item {...field} name={[field.name, 'id']} hidden={true}>
                                                                    <Input hidden={true} />
                                                                </Form.Item>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="btnBoxMulti stamp_duty_edit_boxes justify-left">
                                            <button type='button' onClick={() => navigate('/calculator_info/stamp_duty/management')} className="cancelBtnMulti">{COMMON_STRING.BACK_BTN}</button>
                                            <button type='submit' className="addBtnMulti">{dStateData ? COMMON_STRING.UPDATE_BTN : COMMON_STRING.CREATE_BTN}</button>
                                        </Col>
                                    </>
                                )}
                            </Form.List>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

export default AddEditStampButy;
