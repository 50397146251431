import { Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CITY_STATE_STRINGS, COMMON_ALERTS, COMMON_STRING } from '../../../../services/strings';
import { COUNTRIES } from '../../../../services/Locations/Countries';
import moment from 'moment';
import SelectArrow from '../../../../components/Icon/SelectArrow';

const AddEditCountry = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const country = useLocation().state?.country;

    const formSubmit = async (e) => {
        const startMonth = form.getFieldValue('startMonth');
        const startDate = form.getFieldValue('startDate');
        const financeStartDate = moment(`2023-${startMonth}-${startDate}`).format('YYYY-MM-DD');
        const payload = { name: e.country_name, financeStartDate };

        const res = country? await COUNTRIES.PATCH(country.id, payload): await COUNTRIES.CREATE(payload);
        const msg = country? COMMON_ALERTS.UPDATED_SUCCESS('Country'): COMMON_ALERTS.ADDED_SUCCESS('Country');

        if (res.status) {
            toast.success(`${msg}`);
            navigate('/city_state', { state: { tabState: 'Country' } });
        }
        else {
            toast.error(COMMON_ALERTS.SOMETHING_WENT_WRONG);
        }
    }

    const updateEndDate = () => {
        const startMonth = form.getFieldValue('startMonth');
        let startDate = form.getFieldValue('startDate');
        if(startDate === 31 && [4,6, 9, 11].includes(startMonth)){
            form.setFieldsValue({startDate : 30});
            startDate = 30;
        }
        else if(startDate > 28 && [2].includes(startMonth)){
            form.setFieldsValue({startDate : 28});
            startDate = 28;
        }
        const date = moment(`2023-${startMonth}-${startDate}`).subtract(1, 'd')
        form.setFieldsValue({
            endDate: date.format('D'),
            endMonth: date.format("MMM")
        });
    }

    useEffect(()=> {
        console.log(country? moment(country.financeStartDate || 1).format('M'): 1);
        form.setFieldsValue({ startDate: country? moment(country.financeStartDate || 1).format('D'): 1, startMonth: parseInt(country? moment(country?.financeStartDate).format('M'): '1') });
        updateEndDate();
    }, [])

    return (
        <div className='addCountryLayout'>
            <Row className='fWidthShowBox commonLayout'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="">
                    <h2 className='h2title'>{country ? CITY_STATE_STRINGS.COUNTRY_PAGE.EDIT_LBL : CITY_STATE_STRINGS.COUNTRY_PAGE.ADD_LBL}</h2>
                    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 6 }} colon={false} form={form} onFinish={formSubmit}>
                        <Form.Item name="country_name" label={<span className='input-label' >{CITY_STATE_STRINGS.COUNTRY_PAGE.COUNTRY_NAME}</span>}
                            initialValue={country?.name}
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('country name') }]}>
                            <Input className="input-control" />
                        </Form.Item>
                        <Form.Item label={<span className='input-label' >{CITY_STATE_STRINGS.COUNTRY_PAGE.FINANCIAL_START_DATE}</span>}
                            className='doubleInput'>
                            <Form.Item name="startDate">
                                <Select
                                    style={{ width: 120 }}
                                    onChange={updateEndDate}
                                    suffixIcon={<SelectArrow />}
                                    options={Array.from(Array(31)).map((num, i) => ({ value: i+1, label: i+1 }))}
                                />
                            </Form.Item>
                            <Form.Item name="startMonth">
                                <Select
                                    defaultValue="Jan"
                                    style={{ width: 120 }}
                                    onChange={updateEndDate}
                                    suffixIcon={<SelectArrow />}
                                    options={Array.from(Array(12)).map((num, i) => ({ value: i+1, label: moment(`2023-${i+1}-01`).format('MMM') }))}
                                />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label={<span className='input-label' >{CITY_STATE_STRINGS.COUNTRY_PAGE.FINANCIAL_END_DATE}</span>}
                            className='doubleInput'>
                            <Form.Item name="endDate">
                                <Input className="input-control" disabled/>
                            </Form.Item>
                            <Form.Item name="endMonth">
                                <Input className="input-control" disabled/>
                            </Form.Item>
                        </Form.Item>
                        <Row>
                            <Col xs={24} sm={24} md={{ offset: 8, span: 16 }} lg={{ offset: 8, span: 16 }} xl={{ offset: 8, span: 16 }} className="btnBox">
                                <button type="button" className="backBtn" onClick={() => navigate('/city_state', { state: { tabState: 'Country' } })}>{COMMON_STRING.BACK_BTN}</button>
                                <button type='submit' className="createBtn">{country ? COMMON_STRING.UPDATE_BTN : COMMON_STRING.CREATE_BTN}</button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

export default AddEditCountry;
