import React, { useState } from 'react';
import { Select, Space } from 'antd';
const { Option } = Select;
import PropTypes from 'prop-types';

const MultipleSelection = ({options, value, onChange, placeholder}) => {
  const selectProps = {
    mode: 'multiple',
    style: {
      width: '100%',
    },
    value,
    onChange: onChange,
    placeholder: placeholder? placeholder: 'Select Item...',
    maxTagCount: 'responsive',
    optionFilterProp: 'children',
    filterOption: (input, option) =>  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
  };
  
  return (
    <Space
      className="input-control"
      direction="vertical"
      style={{
        width: '100%',
      }}
    >
      <Select {...selectProps}>
        {options.map(option => (
          <Option key={option.id} value={option.id} label={option.name}>
            {option.name}
          </Option>
        ))}
      </Select>
    </Space>
  );
};

MultipleSelection.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

export default MultipleSelection;