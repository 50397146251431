import { methods, serviceMaker } from '../service';

const GET_CONTACTS_BY_ADD = (
  limit,
  skip,
  search = null,
  sort = null,
  addedBy
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
      addedBy: addedBy
    },
  };
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/property`, methods.GET, query);
  }
  return serviceMaker(`contact-details`, methods.GET, query);
};

const GET_CONTACTS_BY_USER = (
  limit,
  skip,
  search = null,
  sort = null,
  appuserId
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
      appuserId: appuserId
    },
  };
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/property`, methods.GET, query);
  }
  return serviceMaker(`contact-details`, methods.GET, query);
};

const GET_CONTACTS = (
  limit,
  skip,
  search = null,
  sort = null,
  addedBy = null,
  startDate = null,
  endDate = null,
  countryId = null,
  stateId = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (addedBy) {
    query['params']['addedBy'] = addedBy;
  }
  if (startDate && (startDate != 0) && (startDate != null)) {
    query['params']['startDate'] = new Date(startDate);
  }
  if (endDate && (endDate != 0) && (endDate != null)) {
    query['params']['endDate'] = new Date(endDate);
  }
  if (countryId && (countryId != 0) && (countryId != null)) {
    query['params']['country'] = countryId;
  }
  if (stateId && (stateId != 0) && (stateId != null)) {
    query['params']['state'] = stateId;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/property`, methods.GET, query);
  }
  return serviceMaker(`contact-details`, methods.GET, query);
};

const GET_CONTACTS_BY_PROPERTY = (
  limit,
  skip,
  search = null,
  sort = null,
  propertyId
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
      propertyId: propertyId
    },
  };
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }

  if (search) {
    //
  }

  return serviceMaker(`contact-details`, methods.GET, query);
};

const EXPORT = () => serviceMaker(`SEARCH/contact-details`, methods.GET, {
  params: {
    $limit: -1,
    $skip: 0
  },
});

const SINGLE_CONTACT = (contactId) => {
  return serviceMaker(`contact-details/${contactId}`, methods.GET);
}

const DELETE_CONTACT = (id) => {
  return serviceMaker(`contact-details/${id}`, methods.DELETE);
}

const CREATE_CONTACT = (payload) =>
  serviceMaker(`contact-details`, methods.POST, null, payload);

const PATCH_CONTACT = (id, payload) =>
  serviceMaker(`contact-details/${id}`, methods.PATCH, null, payload);

const CONTACT_DETAILS = {
  GETADDEDBY: GET_CONTACTS_BY_ADD,
  GETBYUSERID: GET_CONTACTS_BY_USER,
  GETBYPROPERTY: GET_CONTACTS_BY_PROPERTY,
  GET: GET_CONTACTS,
  DELETE: DELETE_CONTACT,
  SINGLE: SINGLE_CONTACT,
  CREATE: CREATE_CONTACT,
  PATCH: PATCH_CONTACT
};

export { CONTACT_DETAILS };
