import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import moment from 'moment';
import PaginationCom from '../../components/PaginationCom';
import { Col, Form, Input, Row, Select, Slider, Table, Tooltip } from 'antd';
import { AVM_STRING, COMMON_ALERTS, FILTER_STRING, TABLE_STRING } from '../../services/strings';
import { tableSortIconToggle } from '../../utility/tableSorting';
import { AVM } from '../../services/AVM/AVMService';
import PencileIcon from '../../components/Icon/PencileIcon';
import DeleteIcon from '../../components/Icon/DeleteIcon';
import DeleteModel from '../../components/DeleteModel';
import ValuationModal from '../../components/Modal/ValuationModel';
import FilterToogleBtn from '../../components/FilterToogleBtn';
import SelectArrow from '../../components/Icon/SelectArrow';
import { DROPDOWN } from '../../services/Dropdown/dropdowns';

AVMPage.propTypes = {
    permissions: PropTypes.any
};

function AVMPage({ permissions }) {
    const [form] = Form.useForm();
    const [AVMs, setAVMs] = useState([]);
    const [Countries, setCountries] = useState([]);
    const [States, setStates] = useState([]);
    const [Suburbs, setSuburbs] = useState([]);
    const [PropertyTypes, setPropertyTypes] = useState([]);
    const [tableLoading, setTableLoading] = useState(null);
    const [isFilterShow, setIsFilterShow] = useState(false);
    const [tempSearch, setTempSearch] = useState(null);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [isOpenValuationModel, setIsOpenValuationModel] = useState(false);
    const [editId, setEditId] = useState(null);
    const [Filters, setFilters] = useState({ $limit: 10, $skip: 0, page: 1, search: null, sort: null, country: null, state: null, suburb: null, propertyType: null });


    const onChangePage = (page, pageSize) => {
        setFilters(prev => ({
            ...prev,
            $limit: pageSize,
            $skip: (page - 1) * pageSize,
            page
        }));
    };

    const onChange = (pagination, filters, sorter) => {
        const sort = {};
        if (sorter?.order === 'ascend') {
            Object.assign(sort, { [sorter.field]: 1 });
        } else if (sorter?.order === 'descend') {
            Object.assign(sort, { [sorter.field]: -1 });
        } else {
            Object.assign(sort, { receivedAt: -1 });
        }
        setFilters(prev => ({
            ...prev,
            sort
        }));
    };

    const onFilterChange = (name, val) => {
        setFilters(prev => ({
            ...prev,
            [name]: val === '' ? null : val
        }));
    }

    const deleteAVM = async () => {
        try {
            const res = await AVM.DELETE(deleteId);
            if (res.status) {
                toast.success(COMMON_ALERTS.DELETED_SUCCESS('avm'));
                setIsDeleteModalVisible(false);
                fetchAVMs();
            }
        }
        catch (e) {
            toast.error(COMMON_ALERTS.SOMETHING_WENT_WRONG);
        }
    }

    const submitValuation = async (value) => {
        try{
            const res = await AVM.PATCH(editId, {
                currentValue: value
            });
            fetchAVMs();
            if (res?.status) {
                toast.success(COMMON_ALERTS.ADDED_SUCCESS('AVM'))
            }
            else {
                toast.error(COMMON_ALERTS.SOMETHING_WENT_WRONG);
            }
        }
        catch(e){
            toast.error(e?.response?.data?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
        }
    }

    const fetchAVMs = async () => {
        setTableLoading(true);
        try {
            const res = await AVM.GET(Filters);
            if (res.status === 1 || res.status === '1') {
                setAVMs(res);
            }
        } catch (err) {
            setTableLoading(false);
            toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
        } finally {
            setTableLoading(false);
        }
    };

    const handleChangeOfSearch = (e) => {
        setTempSearch(e.target.value);
        if (!e.target.value) {
            onFilterChange('search', null);
            setTempSearch(null);
        }
    };

    const handleSearch = () => {
        onFilterChange('search', tempSearch);
    };

    const fetchDropDowns = async () => {
        const [countriesRes, statesRes, suburbRes, propertyTypesRes] = await Promise.all([DROPDOWN.GET_COUNTRIES(), DROPDOWN.GET_STATES(), DROPDOWN.GET_SUBURBS(), DROPDOWN.GET_PROPERTY_TYPES()]);
        if (countriesRes.status) {
            setCountries(countriesRes.data);
        }
        if (statesRes.status) {
            setStates(statesRes.data);
        }
        if (suburbRes.status) {
            setSuburbs(suburbRes.data);
        }
        if (propertyTypesRes.status) {
            setPropertyTypes(propertyTypesRes.data);
        }
    }


    useEffect(() => {
        fetchAVMs();
    }, [Filters]);

    useEffect(() => {
        fetchDropDowns();
    }, []);

    const clearAll = () => {
        setTempSearch('');
        setFilters({ $limit: 10, $skip: 0, page: 1, search: null, sort: null, country: null, state: null, suburb: null, propertyType: null });
    }

    const columns = [
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{AVM_STRING.TABLE.PROPERTY_LIST}</span>, 'propertyName', column),
            dataIndex: 'propertyName',
            key: 'propertyName',
            align: 'left',
            sorter: AVMs?.data?.length > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value, record) =>
                record ? <span className="tabletext">{record.property.name.charAt(0).toUpperCase() + record.property.name.slice(1)}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{AVM_STRING.TABLE.PROPERTY_TYPE}</span>, 'propertyType', column),
            dataIndex: 'propertyType',
            key: 'propertyType',
            align: 'left',
            sorter: AVMs?.data?.length > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value, record) =>
                record ? <span className="tabletext">{record.property.property_type.name.charAt(0).toUpperCase() + record.property.property_type.name.slice(1)}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{AVM_STRING.TABLE.RECIEVED_DATE}</span>, 'name', column),
            dataIndex: 'receivedAt',
            key: 'receivedAt',
            align: 'left',
            sorter: AVMs?.data?.length > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{moment(value).format('DD/MM/YYYY')}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{AVM_STRING.TABLE.UPDATED_DATE}</span>, 'name', column),
            dataIndex: 'modifiedAt',
            key: 'modifiedAt',
            align: 'left',
            sorter: AVMs?.data?.length > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{moment(value).format('DD/MM/YYYY')}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{AVM_STRING.TABLE.VALUE}</span>, 'name', column),
            dataIndex: 'currentValue',
            key: 'currentValue',
            align: 'left',
            sorter: AVMs?.data?.length > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">${value}</span> : '-',
        },
        {
            title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
            width: 150,
            key: 'Actions',
            align: 'center',
            dataIndex: 'Actions',
            render: (item, record) => {
                return (
                    <div className='actionCol'>
                        {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
                            <Tooltip title='Edit AVM'>
                                <PencileIcon
                                    onClick={() => {
                                        setEditId(record.id);
                                        setIsOpenValuationModel(true);
                                    }}
                                    className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
                            </Tooltip>
                        )}
                        {(permissions?.isDelete || permissions?.isDelete === undefined) && (
                            <Tooltip title='Delete AVM'>
                                <DeleteIcon
                                    onClick={() => {
                                        setIsDeleteModalVisible(true);
                                        setDeleteId(record?.id);
                                    }}
                                    className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                                />
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <div className='propertiesLayout'>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} />
                </Col>
            </Row>
            <Row className={isFilterShow ? 'mt-3' : 'd-none'}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row className="fWidthShowBox filterLayout">
                        <Col xs={24} sm={24} md={24} lg={11} xl={10}>
                            <Form form={form} colon={false} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                                <Form.Item label={<span className="inputTitle">{FILTER_STRING.SEARCH}</span>} name="search">
                                    <Input className='selectDateInput'
                                        placeholder="Start Typing To Search Property"
                                        value={tempSearch}
                                        allowClear
                                        onChange={(e) => handleChangeOfSearch(e)}
                                        onKeyPress={(e) => {
                                            if (e?.key === 'Enter' && e.target.value) {
                                                handleSearch();
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={13} xl={14} className="filterDropDownBox">
                            <div className="filterSelect">
                                <Select defaultValue={AVM_STRING.FILTER.PROPERTY_TYPE} onChange={val => onFilterChange('propertyType', val)} value={Filters.propertyType} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option>{FILTER_STRING.PROPERTY_TYPE}</Select.Option>{PropertyTypes.map((type, key) => <Select.Option key={key} value={type.id}>{type.name}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={FILTER_STRING.COUNTRY} onChange={val => onFilterChange('country', val)} suffixIcon={<SelectArrow />} className="mr-1 m-auto cursor-pointer filterDropdown" bordered={false} value={Filters.country}><Select.Option>{FILTER_STRING.COUNTRY}</Select.Option>{Countries?.map((country, key) => <Select.Option key={key} value={country.name}>{country.name}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={FILTER_STRING.STATE} onChange={val => onFilterChange('state', val)} value={Filters.state} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option>{FILTER_STRING.STATE}</Select.Option>{States.map((state, key) => <Select.Option key={key} value={state.state_code}>{state.name}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={FILTER_STRING.SUBURBS} onChange={val => onFilterChange('suburb', val)} value={Filters.suburb} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option>{FILTER_STRING.SUBURBS}</Select.Option>{Suburbs.map((suburb, key) => <Select.Option key={key} value={suburb.name}>{suburb.name}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSelect clearAll" onClick={() => clearAll()}>Clear all</div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-3">
                    <Table
                        columns={columns}
                        dataSource={AVMs?.data}
                        pagination={false}
                        rowKey='id'
                        loading={tableLoading}
                        onChange={onChange}
                        scroll={{ x: 400 }}
                    />

                    {AVMs?.total > 10 && (
                        <div className='text-end bg-white mt-7'>
                            <PaginationCom
                                onChange={onChangePage}
                                showSizeChanger={true}
                                current={Filters.page}
                                showLessItems={true}
                                total={AVMs?.total}
                            />
                        </div>
                    )}
                </Col>
            </Row>
            <DeleteModel
                isDeleteModalVisible={isDeleteModalVisible}
                deleteModel={deleteAVM}
                setIsDeleteModalVisible={setIsDeleteModalVisible}
            />
            <ValuationModal
                isOpen={isOpenValuationModel}
                setIsOpen={setIsOpenValuationModel}
                onSubmit={submitValuation}
            />
        </div>
    );
}

export default AVMPage;