import React, { useEffect, useState } from 'react';
import { REDEEM_REWARDS_STRINGS } from '../../services/strings';
import RedeemRewards from './RedeemRewards';
import TabSwitch from '../../components/TabSwitch';
import RedeemHistoryPage from './RedeemHistoryPage';
import { useLocation } from 'react-router-dom';

function RedeemRewardsHome() {
    const locationState = useLocation().state;
    const tabs = [
        {
            name: REDEEM_REWARDS_STRINGS.TABS.REDEEM_HISTORY,
            component: <RedeemHistoryPage/>
        },
        {
            name: REDEEM_REWARDS_STRINGS.TABS.SERVICE_HISTORY,
            component: <RedeemRewards/>
        }
    ];

    return (
        <div className='pagelayout'>
            <TabSwitch tabStates={tabs} selected={locationState?.selectedTab?? 0}/>
        </div>
    );
}

export default RedeemRewardsHome;