import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AdminLayout from "../components/AdminLayout";
import Login from '../views/Login/Login';
import { useSelector } from 'react-redux';
import Dashboard from '../views/Dashboard/Dashboard';
import Appusers from "../views/Appusers/Appusers";
import AppusersDetails from "../views/Appusers/AppusersDetails";
import PropertiesPage from "../views/Properties/PropertiesPage";
import PropertiesValidate from "../views/Properties/PropertiesValidate";
import PropertyDetails from "../views/Properties/PropertyDetails";
import ContactDetails from "../views/ContactDetails/ContactDetails";
import ExpensesPage from "../views/Expenses/ExpensesPage";
import AddEditExpenses from "../views/Expenses/AddEditExpenses";
import ScheduleService from "../views/Schedule Service/ScheduleService";
import AddEditServicesSchedule from "../views/Schedule Service/AddEditServiceSchedule";
import ExternalService from "../views/ExternalService/ExternalService";
import AddEditExternalService from "../views/ExternalService/AddEditExternalService";
import CalculatorsInfo from "../views/CalculatorsInfo/CalculatorsInfo";
import PropertyTypes from "../views/PropertyTypes/PropertyTypes";
import AddEditPropertyType from "../views/PropertyTypes/AddEditPropertyType";
import RedeemRewards from "../views/Redeem Rewards/RedeemRewards";
import NewsArticlesPage from "../views/News and Articles/NewsArticlesPage";
import NewsListPage from "../views/News and Articles/NewsListPage";
import AddEditNewsArticle from "../views/News and Articles/AddEditNewsArticle";
import FaqPage from "../views/FAQ/FaqPage";
import EditAddFAQPage from "../views/FAQ/EditAddFAQPage";
import AdminUsers from "../views/AdminUsers/AdminUsers";
import AddEditAdminuser from "../views/AdminUsers/AddEditAdminuser";
import RolesPage from "../views/AdminUsers/RolesPage";
import AddEditAdminRole from "../views/AdminUsers/AddEditAdminRole";
import ReferalsPage from "../views/Referals/ReferalsPage";
import Advertisements from "../views/Advertisements/Advertisements";
import NotificationsPage from "../views/Notifications/NotificationsPage";
import FeedbackPage from "../views/Feedback/FeedbackPage";
import CityStatePage from "../views/CityState/CityStatePage";
import ReportsPage from "../views/Reports/ReportsPage";
import AddEditNotification from "../views/Notifications/AddEditNotification/AddEditNotification";
import AddEditAdvertisement from "../views/Advertisements/AddEditAdvertisement";
import AddEditCountry from "../views/CityState/Tabs/Country/AddEditCountry";
import AddEditState from "../views/CityState/Tabs/State/AddEditState";
import AddEditCity from "../views/CityState/Tabs/City/AddEditCity";
import AddEditSuburbs from "../views/CityState/Tabs/Suburbs/AddEditSuburbs";
import StampDutyDetails from "../views/CalculatorsInfo/DetailsPages/StampDutyDetails";
import BorrowingCapacityPage from "../views/CalculatorsInfo/DetailsPages/BorrowingCapacityPage";
import EarlyRepaymentCalculator from "../views/CalculatorsInfo/DetailsPages/EarlyRepaymentCalculator";
import RepaymentCalculatorPage from "../views/CalculatorsInfo/DetailsPages/RepaymentCalculatorPage";
import StampDutyManagement from "../views/CalculatorsInfo/StampDutyParams/StampDutyManagement";
import AddEditStampButy from "../views/CalculatorsInfo/StampDutyParams/AddEditStampButy";
import AddEditOffer from "../views/Referals/AddEditOffer";
import AddEditReedemableServices from "../views/Redeem Rewards/AddEditReedemableServices";
import AddEditCouncile from '../views/CityState/Tabs/Councile/AddEditCouncile';
import AddEditContactDetails from '../views/ContactDetails/AddEditContactDetails';
import PropertiesPrint from '../views/Reports/print/PropertiesPrint';
import LoginSessions from '../views/LoginSessions/LoginSessions';
import ContactProperties from '../views/ContactDetails/ContactProperties';
import FHBGoalPage from '../views/FHBG/FirstHomeBuyerGoalPage';
import EditFHBGoalPage from '../views/FHBG/EditFHBGoalPage';
import RedeemCategoriesPage from '../views/Redeem Rewards/category/RedeemCategoriesPage';
import AddEditRedeemCategory from '../views/Redeem Rewards/category/AddEditRedeemCategories';
import PdReferralPage from '../views/Referals/PdReferralPage';
import PropertyGoalsPage from '../views/Property Goals/PropertyGoals';
import OfferListPage from '../views/Referals/OfferListPage';
import RedeemRewardsHome from '../views/Redeem Rewards/RedeemRewardsHome';
import TopNewsPage from '../views/News and Articles/TopNewsPage';
import TopStoriesPage from '../views/News and Articles/TopStoriesPage';
import AddEditStory from '../views/News and Articles/AddEditStory';
import AVMPage from '../views/AVM/AVMPage';
import LendersPage from '../views/Lenders/Lenders';
import AddEditLenders from '../views/Lenders/AddEditLenders';
import AgentsPage from '../views/Agents/AgentsPage';
import AddEditAgentPage from '../views/Agents/AddEditAgentPage';

const Routing = () => {
  const { userValue, token } = useSelector((state) => state.user);
  const routing = [];
  const roleRouting = () => {
    if (userValue?.role?.isAdmin) {
      routing.push(
        { url: '/dashboard', components: <Dashboard /> },
        { url: '/users', components: <Appusers /> },
        { url: '/users/details', components: <AppusersDetails /> },
        { url: '/properties', components: <PropertiesPage /> },
        { url: '/properties/validate', components: <PropertiesValidate /> },
        { url: '/properties/details', components: <PropertyDetails /> },
        { url: '/contacts', components: <ContactDetails /> },
        { url: '/contacts/properties', components: <ContactProperties /> },
        { url: '/contacts/add', components: <AddEditContactDetails /> },
        { url: '/contacts/edit', components: <AddEditContactDetails /> },
        { url: '/expense', components: <ExpensesPage /> },
        { url: '/expense/add', components: <AddEditExpenses /> },
        { url: '/expense/edit', components: <AddEditExpenses /> },
        { url: '/service_schedule', components: <ScheduleService /> },
        { url: '/service_schedule/edit', components: <AddEditServicesSchedule /> },
        { url: '/service_schedule/add', components: <AddEditServicesSchedule /> },
        { url: '/external_service', components: <ExternalService /> },
        { url: '/external_service/add', components: <AddEditExternalService /> },
        { url: '/external_service/edit', components: <AddEditExternalService /> },
        { url: '/calculator_info', components: <CalculatorsInfo /> },
        { url: '/calculator_info/stamp_duty', components: <StampDutyDetails /> },
        { url: '/calculator_info/repayment_calc', components: <RepaymentCalculatorPage /> },
        { url: '/calculator_info/early_payment_calc', components: <EarlyRepaymentCalculator /> },
        { url: '/calculator_info/borrowing_capacity', components: <BorrowingCapacityPage /> },
        { url: '/calculator_info/stamp_duty/management', components: <StampDutyManagement /> },
        { url: '/calculator_info/stamp_duty/add', components: <AddEditStampButy /> },
        { url: '/calculator_info/stamp_duty/edit', components: <AddEditStampButy /> },
        { url: '/fhbgoal', components: <FHBGoalPage /> },
        { url: '/fhbgoal/edit', components: <EditFHBGoalPage /> },
        { url: '/city_state', components: <CityStatePage /> },
        { url: '/city_state/country/add', components: <AddEditCountry /> },
        { url: '/city_state/country/edit', components: <AddEditCountry /> },
        { url: '/city_state/state/add', components: <AddEditState /> },
        { url: '/city_state/state/edit', components: <AddEditState /> },
        { url: '/city_state/city/add', components: <AddEditCity /> },
        { url: '/city_state/city/edit', components: <AddEditCity /> },
        { url: '/city_state/suburbs/add', components: <AddEditSuburbs /> },
        { url: '/city_state/suburbs/edit', components: <AddEditSuburbs /> },
        { url: '/city_state/council/add', components: <AddEditCouncile /> },
        { url: '/city_state/council/edit', components: <AddEditCouncile /> },
        { url: '/property_type', components: <PropertyTypes /> },
        { url: '/property_type/add', components: <AddEditPropertyType /> },
        { url: '/property_type/edit', components: <AddEditPropertyType /> },
        { url: '/lenders', components: <LendersPage /> },
        { url: '/lenders/add', components: <AddEditLenders /> },
        { url: '/lenders/edit', components: <AddEditLenders /> },
        { url: '/redeem_rewards', components: <RedeemRewardsHome /> },
        { url: '/redeem_rewards/add', components: <AddEditReedemableServices /> },
        { url: '/redeem_rewards/edit', components: <AddEditReedemableServices /> },
        { url: '/redeem_categories', components: <RedeemCategoriesPage /> },
        { url: '/redeem_categories/add', components: <AddEditRedeemCategory /> },
        { url: '/redeem_categories/edit', components: <AddEditRedeemCategory /> },
        { url: '/referrals/referralpd', components: <PdReferralPage />},
        { url: '/news_articles', components: <NewsArticlesPage /> },
        { url: '/news_articles/add', components: <AddEditNewsArticle /> },
        { url: '/news_articles/edit', components: <AddEditNewsArticle /> },
        { url: '/news', components: <NewsListPage/>},
        { url: '/top-news', components: <TopNewsPage/>},
        { url: '/top-stories', components: <TopStoriesPage/>},
        { url: '/top-stories/add', components: <AddEditStory/>},
        { url: '/top-stories/edit', components: <AddEditStory/>},
        { url: '/faqs', components: <FaqPage /> },
        { url: '/faqs/add', components: <EditAddFAQPage /> },
        { url: '/faqs/edit', components: <EditAddFAQPage /> },
        { url: '/admin-users', components: <AdminUsers /> },
        { url: '/admin-users/edit', components: <AddEditAdminuser /> },
        { url: '/admin-users/add', components: <AddEditAdminuser /> },
        { url: '/admin-users/roles', components: <RolesPage /> },
        { url: '/admin-users/roles/add', components: <AddEditAdminRole /> },
        { url: '/admin-users/roles/edit', components: <AddEditAdminRole /> },
        { url: '/referrals', components: <ReferalsPage /> },
        { url: '/offers', components: <OfferListPage /> },
        { url: '/referrals/offer/add', components: <AddEditOffer /> },
        { url: '/referrals/offer/edit', components: <AddEditOffer /> },
        { url: '/advertisements', components: <Advertisements /> },
        { url: '/advertisements/add', components: <AddEditAdvertisement /> },
        { url: '/advertisement/edit', components: <AddEditAdvertisement /> },
        { url: '/notifications', components: <NotificationsPage /> },
        { url: '/notifications/add', components: <AddEditNotification /> },
        { url: '/notifications/edit', components: <AddEditNotification /> },
        { url: '/feedback', components: <FeedbackPage /> },
        { url: '/reports', components: <ReportsPage /> },
        { url: '/reports/properties', components: <PropertiesPrint /> },
        { url: '/agents', components: <AgentsPage /> },
        { url: '/agents/view', components: <AddEditAgentPage /> },
        { url: '/agents/add', components: <AddEditAgentPage /> },
        { url: '/agents/edit', components: <AddEditAgentPage /> },
        { url: '/loginsessions', components: <LoginSessions /> },
        {url: '/property_goals', components: <PropertyGoalsPage/>},
        {url: '/avm', components: <AVMPage/>}
      );
    }
    else {
      userValue?.role?.permissions?.forEach((item) => {
        if (item.page.value === 'dashboard') {
          routing.push({
            url: '/dashboard',
            components: <Dashboard permissions={item} />,
          });
        }
        if (item.page.value === 'users') {
          routing.push(
            { url: '/users', components: <Appusers permissions={item} /> },
            { url: '/users/details', components: <AppusersDetails permissions={item} /> }
          );
          if (item.isCreate) {
            routing.push({
              url: 'q/users/add/:id',
              components: <Appusers />,
            });
          }
          if (item.isUpdate) {
            routing.push({
              url: '/users/edit/:id',
              components: <Appusers />,
            });
          }
        }
        if (item.page.value === 'notifications') {
          routing.push(
            { url: '/notifications', components: <NotificationsPage permissions={item} /> }
          );
          if (item.isCreate) {
            routing.push({
              url: '/notifications/add',
              components: <AddEditNotification />,
            });
          }
          if (item.isUpdate) {
            routing.push({
              url: '/notifications/edit',
              components: <AddEditNotification />,
            });
          }
        }
        if (item.page.value === 'news_articles') {
          routing.push(
            { url: '/news_articles', components: <NewsArticlesPage permissions={item} /> }
          );
          if (item.isCreate) {
            routing.push({
              url: '/news_articles/add',
              components: <AddEditNewsArticle />,
            });
          }
          if (item.isUpdate) {
            routing.push({
              url: '/news_articles/edit',
              components: <AddEditNewsArticle />,
            });
          }
        }
        if (item.page.value === 'contacts') {
          routing.push(
            { url: '/contacts', components: <ContactDetails permissions={item} /> }
          );
          if (item.isCreate) {
            routing.push({
              url: '/contacts/add',
              components: <AddEditContactDetails />,
            });
          }
          if (item.isUpdate) {
            routing.push({
              url: '/contacts/edit',
              components: <AddEditContactDetails />,
            });
          }
        }
        if (item.page.value === 'external_Service') {
          routing.push(
            { url: '/external_Service', components: <ExternalService permissions={item} /> }
          );
          if (item.isCreate) {
            routing.push({
              url: '/external_Service/add',
              components: <AddEditExternalService />,
            });
          }
          if (item.isUpdate) {
            routing.push({
              url: '/external_Service/edit',
              components: <AddEditExternalService />,
            });
          }
        }

        if (item.page.value === 'calculator_info') {
          routing.push(
            { url: '/calculator_info', components: <CalculatorsInfo permissions={item} /> },

          );
          if (item.isCreate) {
            routing.push({ url: '/calculator_info/stamp_duty', components: <StampDutyDetails /> },
              { url: '/calculator_info/repayment_calc', components: <RepaymentCalculatorPage /> },
              { url: '/calculator_info/early_payment_calc', components: <EarlyRepaymentCalculator /> },
              { url: '/calculator_info/borrowing_capacity', components: <BorrowingCapacityPage /> },
              { url: '/calculator_info/stamp_duty/management', components: <StampDutyManagement /> },
              { url: '/calculator_info/stamp_duty/add', components: <AddEditStampButy /> });
          }
          if (item.isUpdate) {
            routing.push({ url: '/calculator_info/stamp_duty', components: <StampDutyDetails /> },
              { url: '/calculator_info/repayment_calc', components: <RepaymentCalculatorPage /> },
              { url: '/calculator_info/early_payment_calc', components: <EarlyRepaymentCalculator /> },
              { url: '/calculator_info/borrowing_capacity', components: <BorrowingCapacityPage /> },
              { url: '/calculator_info/stamp_duty/management', components: <StampDutyManagement /> },
              { url: '/calculator_info/stamp_duty/add', components: <AddEditStampButy /> });
          }
        }
        if (item.page.value === 'expenses') {
          routing.push(
            { url: '/expenses', components: <ExpensesPage permissions={item} /> }
          );
          if (item.isCreate) {
            routing.push({
              url: '/expenses/add',
              components: <AddEditExpenses />,
            });
          }
          if (item.isUpdate) {
            routing.push({
              url: '/expenses/edit',
              components: <AddEditExpenses />,
            });
          }
        }

        if (item.page.value === 'service_schedule') {
          routing.push(
            { url: '/service_schedule', components: <ScheduleService permissions={item} /> }
          );
          if (item.isCreate) {
            routing.push({
              url: '/service_schedule/add',
              components: <AddEditServicesSchedule />,
            });
          }
          if (item.isUpdate) {
            routing.push({
              url: '/service_schedule/edit',
              components: <AddEditServicesSchedule />,
            });
          }
        }

        if (item.page.value === 'property_type') {
          routing.push(
            { url: '/property_type', components: <PropertyTypes permissions={item} /> }
          );
          if (item.isCreate) {
            routing.push({
              url: '/property_type/add',
              components: <AddEditPropertyType />,
            });
          }
          if (item.isUpdate) {
            routing.push({
              url: '/property_type/edit',
              components: <AddEditPropertyType />,
            });
          }
        }

        if (item.page.value === 'lenders') {
          routing.push(
            { url: '/lenders', components: <LendersPage permissions={item} /> }
          );
          if (item.isCreate) {
            routing.push({
              url: '/lenders/add',
              components: <AddEditPropertyType />,
            });
          }
          if (item.isUpdate) {
            routing.push({
              url: '/lenders/edit',
              components: <AddEditPropertyType />,
            });
          }
        }

        if (item.page.value === 'city_state') {
          routing.push(
            { url: '/city_state', components: <CityStatePage permissions={item} /> }
          );
          if (item.isCreate) {
            routing.push({
              url: '/city_state/country/add',
              components: <AddEditCountry />
            }),
              routing.push({
                url: '/city_state/state/add',
                components: <AddEditState />
              }),
              routing.push({
                url: '/city_state/city/add',
                components: <AddEditCity />
              }),
              routing.push({
                url: '/city_state/suburbs/add',
                components: <AddEditSuburbs />
              }),
              routing.push({
                url: '/city_state/council/add',
                components: <AddEditCouncile />
              });
          }
          if (item.isUpdate) {
            routing.push({
              url: '/city_state/country/edit',
              components: <AddEditCountry />
            }),
              routing.push({
                url: '/city_state/state/edit',
                components: <AddEditState />
              }),
              routing.push({
                url: '/city_state/city/edit',
                components: <AddEditCity />
              }),
              routing.push({
                url: '/city_state/suburbs/edit',
                components: <AddEditSuburbs/>
              }),
              routing.push({
                url: '/city_state/council/edit',
                components: <AddEditCouncile />
              });
          }
        }
        if (item.page.value === 'redeem_rewards') {
          routing.push(
            { url: '/redeem_rewards', components: <RedeemRewards permissions={item} /> }
          );
          if (item.isCreate) {
            routing.push({
              url: '/redeem_rewards/add',
              components: <AddEditReedemableServices />,
            });
          }
          if (item.isUpdate) {
            routing.push({
              url: '/redeem_rewards/edit',
              components: <AddEditReedemableServices />,
            });
          }
        }
        if (item.page.value === 'agents') {
          routing.push(
            { url: '/agents', components: <AgentsPage permissions={item} /> }
          );
          if (item.isView) {
            routing.push({
              url: '/agents/view',
              components: <AddEditAgentPage />,
            });
          }
          
          if (item.isCreate) {
            routing.push({
              url: '/agents/add',
              components: <AddEditAgentPage />,
            });
          }
          if (item.isUpdate) {
            routing.push({
              url: '/agents/edit',
              components: <AddEditAgentPage />,
            });
          }
        }
        if (item.page.value === 'faq') {
          routing.push(
            { url: '/faqs', components: <FaqPage permissions={item} /> }
          );
          if (item.isCreate) {
            routing.push({
              url: '/faqs/add',
              components: <EditAddFAQPage />,
            });
          }
          if (item.isUpdate) {
            routing.push({
              url: '/faqs/edit',
              components: <EditAddFAQPage />,
            });
          }
        }

        if (item.page.value === 'role_management') {
          routing.push(
            { url: '/admin-users/roles', components: <RolesPage permissions={item} /> },
            { url: '/admin-users', components: <AdminUsers permissions={item} /> }
          );
          if (item.isCreate) {
            routing.push({
              url: '/admin-users/roles/add',
              components: <AddEditAdminRole />,
            });
            routing.push({
              url: '/admin-users/add',
              components: <AddEditAdminuser />,
            });
          }
          if (item.isUpdate) {
            routing.push({
              url: '/admin-users/roles/edit',
              components: <AddEditAdminRole />,
            });
            routing.push({
              url: '/admin-users/edit',
              components: <AddEditAdminuser />,
            });
          }
        }

        if (item.page.value === 'referrals') {
          routing.push(
            { url: '/referrals', components: <ReferalsPage permissions={item} /> }
          );
          if (item.isCreate) {
            routing.push({
              url: '/referrals/offer/add',
              components: <AddEditOffer />,
            });
          }
        }

        if (item.page.value === 'reports') {
          routing.push(
            { url: '/reports', components: <ReportsPage permissions={item} /> }
          );
          routing.push(
            { url: '/reports/properties', components: <PropertiesPrint/> }
          );
        }

        if (item.page.value === 'advertisement') {
          routing.push(
            { url: '/advertisement', components: <Advertisements permissions={item} /> }
          );
          if (item.isCreate) {
            routing.push({
              url: '/advertisements/add',
              components: <AddEditAdvertisement />,
            });
          }
          if (item.isUpdate) {
            routing.push({
              url: '/advertisement/edit',
              components: <AddEditAdvertisement />,
            });
          }
        }
        if (item.page.value === 'feedback') {
          routing.push(
            { url: '/feedback', components: <FeedbackPage permissions={item} /> }
          );
        }
        if (item.page.value === 'login_sessions') {
          routing.push(
            { url: '/loginsessions', components: <LoginSessions permissions={item} /> }
          );
        }
        if (item.page.value === 'property') {
          routing.push(
            { url: '/properties', components: <PropertiesPage permissions={item} /> }
          );
          routing.push(
            { url: '/properties/details', components: <PropertyDetails /> },
          );
          if (item.isUpdate) {
            routing.push({
              url: '/properties/validate',
              components: <PropertiesValidate />,
            });
          }
        }
      });
    }

  }
  roleRouting();

  return (
    <Routes>
      <Route element={<AdminLayout />}>
        {routing.map((item, i) => {
          if (item?.children && item?.children.length) {
            <Route path={item.url} element={item.components} key={i} >
              {item.children.map((elem, ind) => {
                <Route path={elem.url} element={elem.components} key={ind} />
              })}
            </Route>
          }
          else return <Route path={item.url} element={item.components} key={i} />;
        })}
      </Route>
      {!token && <Route path='/login' element={<Login />}></Route>}
      <Route
        path='*'
        element={<Navigate to={token ? `${routing[0]?.url}` : '/login'} />}
      ></Route>
    </Routes>
  );
};

export default Routing;