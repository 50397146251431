 import React from "react";
const HeaderProfileIcon = () => {
    return <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#324666"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.2776 14.9996C15.2776 12.3916 17.3918 10.2773 19.9998 10.2773C22.6078 10.2773 24.722 12.3916 24.722 14.9996C24.722 17.6076 22.6078 19.7218 19.9998 19.7218C17.3918 19.7218 15.2776 17.6076 15.2776 14.9996ZM19.9998 11.944C18.3122 11.944 16.9442 13.312 16.9442 14.9996C16.9442 16.6871 18.3122 18.0551 19.9998 18.0551C21.6873 18.0551 23.0553 16.6871 23.0553 14.9996C23.0553 13.312 21.6873 11.944 19.9998 11.944Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.5553 23.0551C14.1746 23.0551 13.0553 24.1744 13.0553 25.5551V26.8754C13.0553 26.8955 13.0699 26.9127 13.0897 26.9159C17.6661 27.6631 22.3334 27.6631 26.9098 26.9159C26.9297 26.9127 26.9442 26.8955 26.9442 26.8754V25.5551C26.9442 24.1744 25.8249 23.0551 24.4442 23.0551H24.0655C24.0362 23.0551 24.0071 23.0598 23.9793 23.0688L23.0176 23.3829C21.0567 24.0232 18.9429 24.0232 16.982 23.3829L16.0203 23.0688C15.9925 23.0598 15.9634 23.0551 15.9341 23.0551H15.5553ZM11.3887 25.5551C11.3887 23.2539 13.2542 21.3885 15.5553 21.3885H15.9341C16.1391 21.3885 16.3428 21.4209 16.5376 21.4845L17.4993 21.7985C19.1241 22.3291 20.8755 22.3291 22.5002 21.7985L23.4619 21.4845C23.6568 21.4209 23.8605 21.3885 24.0655 21.3885H24.4442C26.7454 21.3885 28.6109 23.2539 28.6109 25.5551V26.8754C28.6109 27.7124 28.0044 28.426 27.1784 28.5608C22.4241 29.337 17.5754 29.337 12.8212 28.5608C11.9952 28.426 11.3887 27.7124 11.3887 26.8754V25.5551Z" fill="white"/>
    </svg> 
}

export default HeaderProfileIcon;

