 import React from 'react';

const BlockIcon = (props) => {
    return (
        <>   
        <svg {...props} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="#324666" strokeWidth="2"/>
        <path d="M4 18L18 4" stroke="#324666" strokeWidth="2"/>
        </svg>
        </>
    );
}

export default BlockIcon;
