import React from 'react';

function ApprovedIcon(props) {
    return (
        <span {...props} className='anticon anticon-dashboard ant-menu-item-icon'>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.319444" y="0.319444" width="29.3611" height="29.3611" rx="3.51389" fill="#EAFFE7"/>
                    <path d="M9.96875 15.7181L13.3229 19.0723L20.0312 11.8848" stroke="#359766" strokeWidth="1.27778" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.319444" y="0.319444" width="29.3611" height="29.3611" rx="3.51389" stroke="#A5A8AD" strokeWidth="0.638889"/>
            </svg>
        </span>
    );
}

export default ApprovedIcon;