import { Col, Form, Input, Row, Select, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PencileIcon from '../../../../components/Icon/PencileIcon';
import SelectArrow from '../../../../components/Icon/SelectArrow';
import {  CITY_STATE_STRINGS, COMMON_ALERTS, COMMON_STRING } from '../../../../services/strings';
import { COUNTRIES } from '../../../../services/Locations/Countries';
import { STATES } from '../../../../services/Locations/States';
import { getLSItem } from '../../../../services/auth/encryptLogin';
import { BASE_URL } from '../../../../services/constant';

const AddEditState = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dstate = useLocation().state?.dstate;
  const [fileList, setFileList] = useState([]);
  const [uploadLink, setuploadLink] = useState('');
  const [MediaItemId, setMediaItemId] = useState(null);
  const [MediaType, setMediaType] = useState('');
  const [stateInpVal, setStateInpVal] = useState('');
  const accessToken = getLSItem("pdollar-token");
  const [Countries, setCountries] = useState([]);
  const [isDataLoaded, setisDataLoaded] = useState(false);

  const onFinish = async (e) => {
    let res;
    let payload = {
      countryId: e.countryId
    };
    let msg = '';

    if (MediaItemId) {
      if (MediaType === 'excel') payload["excelFileId"] = MediaItemId;
      if (MediaType === 'csv') payload["csvFileId"] = MediaItemId;
    }
    else payload["name"] = e.name;

    if (!dstate) {
      res = await STATES.CREATE(payload);
      msg = COMMON_ALERTS.ADDED_SUCCESS('State');
    }
    else {
      res = await STATES.PATCH(dstate?.id, payload);
      msg = COMMON_ALERTS.UPDATED_SUCCESS('State');
    }

    if (res.status) {
      toast.success(msg);
      navigate('/city_state', { state: { tabState: 'State' } });
    }
    else {
      toast.error(COMMON_ALERTS.SOMETHING_WENT_WRONG)
    }
  }

  const uploadButton = (
    <div className='uploadImageBtn'>
      <div><PencileIcon /></div>
      <span className='noFileText'>{COMMON_STRING.NO_FILE_CHOOSEN}</span>
    </div>
  );

  const imageProps = {
    name: "file",
    multiple: false,
    fileList: fileList,
    action: `${BASE_URL + uploadLink}`,
    style: {
      width: "127px",
      height: "127px"
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
    beforeUpload(file) {
      if (fileList.length > 1 || fileList.length === 1) {
        toast.warn(COMMON_ALERTS.FILE_EXISTS);
        return false;
      }

      const type =
        file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "text/csv";
      if (!type) {
        toast.error(COMMON_ALERTS.ONLY_ALLOWED_ERROR('CSV or Excel'));
      }

      if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        setMediaType('excel');
        setuploadLink('upload-excel')
      }
      else if (file.type === "text/csv") {
        setMediaType('csv');
        setuploadLink('upload-csv')
      }
      form.setFieldValue("name", null);
      return type || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.fileList.length === 1) {
        setFileList([...info.fileList]);
      }
      if (info.file.status === "done") {
        setMediaItemId(info.file.response?.data?.media.id);
      } else if (info.file.status === "error") {
        toast.error(COMMON_ALERTS.FILE_UPLOAD_FAILED(info.file.name));
      }
    },
    async onRemove() {
      setFileList([]);
      setMediaItemId(null);
    },
  };

  const fetchCountries = async () => {
    const countries = await COUNTRIES.GET();
    if (countries.status && countries.data.length) {
      setCountries(countries.data);
      setisDataLoaded(true);
    }

  }

  const changeStateInp = (value) => {
    setStateInpVal(value);
    if (value && fileList.length) {
      setFileList([]);
      setMediaItemId(null);
    }
  }

  useEffect(() => {
    fetchCountries();
  }, []);

  const formInitialValue = { countryId: dstate ? dstate?.countryId : Countries[0]?.id };
  if (dstate)
    Object.assign(formInitialValue, { name: dstate?.name })

  if (isDataLoaded)
    return (
      <div className='addCityStateLayout'>
        <Row className='commonLayout'>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form form={form} onFinish={onFinish} initialValues={formInitialValue} labelCol={{ md: 11, lg: 8 }} wrapperCol={{ md: 13, lg: 6 }} colon={false}>
              <Form.Item className='fWidthShowBox' name="countryId" label={<span className='input-label'>{CITY_STATE_STRINGS.SELECT_COUNTRY}</span>} rules={
                [
                  { required: true, message: COMMON_ALERTS.REQUIRED_SELECT('country') }
                ]
              }>
                <Select className="input-control" suffixIcon={<SelectArrow />} >{Countries.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
              </Form.Item>
              <h2 className='h2title addStateTitle'>{dstate ? CITY_STATE_STRINGS.STATE_PAGE.EDIT_LBL : CITY_STATE_STRINGS.STATE_PAGE.ADD_LBL}</h2>
              <Row className='fWidthShowBox'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="name" label={<span className='input-label'>{CITY_STATE_STRINGS.STATE_PAGE.STATE_NAME}</span>} rules={[{ required: !MediaItemId ? true : false, message: COMMON_ALERTS.REQUIRED_SELECT('state name') }]}>
                    <Input className="input-control" placeholder='Enter Manually State' defaultValue={dstate ? dstate.name : ''} onChange={e => changeStateInp(e.target.value)} />
                  </Form.Item>
                  {!dstate && <><span className="orText">Or</span><Form.Item name={'file'} label={<span className='input-label'>{COMMON_STRING.UPLOAD_FILE('state via CSV / Excel')}</span>}>
                    <Upload disabled={stateInpVal.length ? true : false} className='imageUploadInput' {...imageProps}>
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  </Form.Item></>}
                  <Row>
                    <Col xs={24} sm={24} md={{ offset: 8, span: 16 }} lg={{ offset: 8, span: 16 }} xl={{ offset: 8, span: 16 }} className="btnBox">
                      <button type='button' className="backBtn" onClick={() => navigate('/city_state', { state: { tabState: 'State' } })}>{COMMON_STRING.BACK_BTN}</button>
                      <button type='submit' className="createBtn">{dstate ? COMMON_STRING.UPDATE_BTN : COMMON_STRING.CREATE_BTN}</button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
}

export default AddEditState;
