import { Modal } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { COMMON_ALERTS } from '../services/strings';

const DeleteModel = ({
  isDeleteModalVisible,
  deleteModel,
  setIsDeleteModalVisible,
  recordId
}) => {

  return (
    <>
      {/* Delete Model */}
      <Modal
        centered="true"
        okText="Yes"
        cancelText="No"
        style={{ textAlign: 'center' }}
        okButtonProps={{
          style: {
            backgroundColor: "#324666"
          }
        }}
        title="Delete"
        open={isDeleteModalVisible}
        visible={isDeleteModalVisible}
        onOk={() => deleteModel(recordId)}
        onCancel={() => setIsDeleteModalVisible(false)}
      >
        <p>{COMMON_ALERTS.ARE_YOU_WANT('delete this')}</p>
      </Modal>
    </>
  );
};

DeleteModel.propTypes = {
  isDeleteModalVisible: PropTypes.bool,
  deleteModel: PropTypes.func,
  setIsDeleteModalVisible: PropTypes.func,
  recordId: PropTypes.any
};


export default DeleteModel;
