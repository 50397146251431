 import { Col, DatePicker, Form, Radio, Row, Select, TimePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import CalenderDarkIcon from '../../../../components/Icon/CalenderDarkIcon';
import moment from 'moment-timezone';
import moments from 'moment';
import SelectArrow from '../../../../components/Icon/SelectArrow';
import FormItem from 'antd/es/form/FormItem';
import ClockDarkIcon from '../../../../components/Icon/ClockDarkIcon';
import { NOTIFICATIONS } from '../../../../services/Notification/notificationService';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import timezones from '../../../../services/Timezones';
import { useLocation, useNavigate } from 'react-router-dom';
import { COMMON_ALERTS, NOTIFICATIONS_STRINGS } from '../../../../services/strings';
import { getLSItem, removeLSItem } from '../../../../services/auth/encryptLogin';

const PublishNotificationStep = ({changeTab}) => {
    const [form]= Form.useForm();
    const navigate = useNavigate();
    const notificationId = useLocation().state?.notificationId;
    const [publishSelection, setPublishSelection] = useState('publish');
    const onChange = (e) => {
        setPublishSelection(e.target.value);
    };

    const combineDateAndTime = function(date, time) {
        const timeString = time.getHours() + ':' + time.getMinutes() + ':00';
        var year = date.getFullYear();
        var month = date.getMonth() + 1; // Jan is 0, dec is 11
        var day = date.getDate();
        var dateString = '' + year + '-' + month + '-' + day;
        var combined = new Date(dateString + ' ' + timeString);
        return combined;
    };

    useEffect(() => {
       const ex_data_str = getLSItem('notification-step-4');
       if(ex_data_str !== null){
        setPublishSelection('schedule');
        const ex_data = JSON.parse(ex_data_str);
        console.log(ex_data.timezone);
        form.setFieldsValue({
            "sdate": moment(ex_data.scheduled_at), 
            "sdtime": moment(ex_data.scheduled_at), 
            "stimezone": ex_data.timezone, 
            "publish_type": "schedule"});
       }
    }, []);
    

    const onFinish = async (e) =>{
        console.log(e);
        const step1_data = JSON.parse(getLSItem('notification-step-1'));
        const step2_data = JSON.parse(getLSItem('notification-step-2'));
        const step3_data = JSON.parse(getLSItem('notification-step-3'));
        const payload = {
            type: step1_data.notification_type,
            title: step2_data.title,
            text: step2_data.text,
            plateform_android: step2_data.platform.includes('android'),
            plateform_ios: step2_data.platform.includes('ios'),
            plateform_web: step2_data.platform.includes('web'),
            notificatonImageId: step2_data.mediaItemId,
            timezone: e.stimezone,
            isPublished: true
        }

        console.log('e.stimezone : ', e.stimezone);

        if(publishSelection === 'schedule'){
            const sDateTime = moment(combineDateAndTime(new Date(e.sdate), new Date(e.sdtime))).format('YYYY-MM-DD HH:mm:ss');
            Object.assign(payload, {scheduled_date: sDateTime, isPublished: false});
        }

        if(step3_data.csvFileId){
            Object.assign(payload, {csvFileId: step3_data.csvFileId});
        }
        else if(step3_data.excelFileId){
            Object.assign(payload, {excelFileId: step3_data.excelFileId});
        }

        let res = null;
        let adType = "";
        if(notificationId){
            res = await NOTIFICATIONS.PATCH(notificationId, payload);
            adType =  COMMON_ALERTS.UPDATED_SUCCESS('Notification '+publishSelection);
        }
        else{
            res = await NOTIFICATIONS.CREATE(payload);
            adType =  COMMON_ALERTS.ADDED_SUCCESS('Notification '+publishSelection);
        }
        if(res?.status){
            toast.success(adType);
            removeLSItem('notification-step-1');
            removeLSItem('notification-step-2');
            removeLSItem('notification-step-3');
            navigate('/notifications');
            changeTab(0);
        }
    }

    return (
        <div>
            <Row><span className="titleText">{NOTIFICATIONS_STRINGS.STEP4.SCHEDULE_PUBLISH}</span></Row>
            <Row>
                <Col xs={24} sm={24} md={{span: 16, offset: 4}} lg={{span: 16, offset: 4}} xl={{span: 16, offset: 4}}>
                    <Form form={form} initialValues={{publish_type: 'publish'}} onFinish={onFinish} colon={false} >
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <FormItem colon={false} name='publish_type' rules={[{required: true, message: COMMON_ALERTS.REQUIRED_SELECT('publish type')}]}>
                                    <Radio.Group onChange={onChange} value={publishSelection} className="publishSchedule">
                                        <Radio value={"publish"}><div className="radioBtnTxt">{NOTIFICATIONS_STRINGS.STEP4.PUBLISH_NOW}</div></Radio>
                                        <Radio value={"schedule"}><div className="radioBtnTxt">{NOTIFICATIONS_STRINGS.STEP4.SCHEDULE}</div></Radio>
                                    </Radio.Group>
                                </FormItem>
                                {publishSelection === 'schedule' && (<Form className='scheduleForm' form={form} onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                    <Form.Item colon={false} name='sdate' label={<span className='inputTitle'>{NOTIFICATIONS_STRINGS.STEP4.SELECT_DATE}</span>} rules={[{required: true, message: COMMON_ALERTS.REQUIRED_SELECT('date')}]} >
                                        <DatePicker className='w-100 inpControlDatepicker' suffixIcon={<CalenderDarkIcon/>}/>
                                    </Form.Item>
                                    <Form.Item colon={false} name='sdtime' label={<span className='inputTitle'>{NOTIFICATIONS_STRINGS.STEP4.SELECT_TIME}</span>} rules={[{required: true, message: COMMON_ALERTS.REQUIRED_SELECT('time')}]}>
                                        <TimePicker className='w-100 inpControlDatepicker' suffixIcon={<ClockDarkIcon/>} />
                                    </Form.Item>
                                    <Form.Item colon={false} name='stimezone' label={<span className='inputTitle'>{NOTIFICATIONS_STRINGS.STEP4.TIMEZONE}</span>} rules={[{required: true, message: COMMON_ALERTS.REQUIRED_SELECT('timezone')}]}>
                                        <Select showSearch className='selectorTimexon' placeholder="Select Timezone" suffixIcon={<SelectArrow style={{color: "#324666"}} className="filterDropdown"/>}>{timezones.map((tz, key) => <Select.Option key={key} value={tz.value}>{tz.text}</Select.Option>)}</Select>
                                    </Form.Item>
                                </Form>)}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="btnBox publish_btns">
                                <div className="backBtn" onClick={()=>changeTab(2)}>Back</div>
                                <button type="submit" className="createBtn">{publishSelection==='publish'?'Publish':'Schedule'}</button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            
        </div>
    );
}

PublishNotificationStep.propTypes = {
    changeTab: PropTypes.func
}

export default PublishNotificationStep;
