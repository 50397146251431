import React, { useEffect, useRef, useState } from 'react';
import { Col, DatePicker, Form, Input, Row, Select, Slider, Table } from 'antd';
import CalenderDarkIcon from '../../../components/Icon/CalenderDarkIcon';
import SelectArrow from '../../../components/Icon/SelectArrow';
import PaginationCom from '../../../components/PaginationCom';
import { tableSortIconToggle } from '../../../utility/tableSorting';
import FilterToogleBtn from '../../../components/FilterToogleBtn';
import { REPORTS } from '../../../services/Reports/ReportsService';
import { ExportExcel, ExportPdf, handleFileName } from '../../../utility/Export/Export';
import { COMMON_STRING, FILTER_STRING, REPORTS_STRINGS } from '../../../services/strings';
import { DROPDOWN } from '../../../services/Dropdown/dropdowns';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const RewardsTabReport = () => {
    const [Rewards, setRewards] = useState([]);
    const [Countries, setCountries] = useState([]);
    const [States, setStates] = useState([]);
    const [Suburbs, setSuburbs] = useState([]);
    const [tableLoading, setTableLoading] = useState(null);
    const [isFilterShow, setIsFilterShow] = useState(false);
    const [tempSearch, setTempSearch] = useState(null);
    const [csv, setCsv] = useState([]);
    const csvLink = useRef();
    const RewardsTypes = ['product', 'app'];
    const RedeemStatues = ['Pending', 'Completed', 'Discarded'];
    const marks = { 0: '$0', 500: '$500' };
    const [Filters, setFilters] = useState({ $limit: 10, $skip: 0, page: 1, search: null, sort: null, startDate: null, endDate: null, country: null, state: null, suburb: null, rewardType: null, status: null, total_availed: [0, 500], total_pending: [0, 500], total_redeemed: [0, 500], total_discard: [0, 500], total_expired: [0, 500] });

    const onChangePage = (page, pageSize) => {
        setFilters(prev => ({
            ...prev,
            $limit: pageSize,
            $skip: (page - 1) * pageSize,
            page
        }));
    };

    const onChange = (pagination, filters, sorter) => {
        const sort = {};
        if (sorter?.order === 'ascend') {
            Object.assign(sort, { [sorter.field]: 1 });
        } else if (sorter?.order === 'descend') {
            Object.assign(sort, { [sorter.field]: -1 });
        } else {
            Object.assign(sort, { createdAt: -1 });
        }
        setFilters(prev => ({
            ...prev,
            sort
        }));
    };

    const onFilterChange = (name, val) => {
        setFilters(prev => ({
            ...prev,
            [name]: val === '' ? null : val
        }));
    }

    useEffect(() => {
        fetchReportRewards(Filters);
    }, [Filters]);

    const headers = [
        { label: 'User Name', key: 'id' },
        { label: 'Property Name', key: 'username' },
        { label: 'Outstanding Loan Amount', key: 'phoneNumber' },
        { label: 'Equity Value', key: 'total_availed' },
        { label: 'State', key: 'total_pending' },
        { label: 'Tax Report Feature Availing', key: 'total_redeemed' },
        { label: 'Interest Rate', key: 'total_expired' },
        { label: 'Interest Rate', key: 'total_discard' }
    ];

    const handleDownloadCsv = async () => {
        const rewards = await REPORTS.REWARDS({ ...Filters, $limit: -1 });
        if (rewards.status) {
            const temp = rewards?.data.map((item) => ({
                uid: item['id'],
                username: item['username'],
                phoneNumber: item['phoneNumber'],
                total_availed: item['total_availed'],
                total_pending: item['total_pending'],
                total_redeemed: item['total_redeemed'],
                total_expired: item['total_expired'],
                total_discard: item['total_discard']
            }));
            setCsv([...temp]);
            csvLink.current.link.click();
        }
        else {
            toast.success('Something Went wrong');
        }
    };

    const generateExcel = async () => {
        const rewards = await REPORTS.REWARDS({ ...Filters, $limit: -1 });
        if (rewards.status) {
            const temp = rewards?.data?.map((item) => {
                return {
                    uid: item['id'],
                    username: item['username'],
                    phoneNumber: item['phoneNumber'],
                    total_availed: item['total_availed'],
                    total_pending: item['total_pending'],
                    total_redeemed: item['total_redeemed'],
                    total_expired: item['total_expired'],
                    total_discard: item['total_discard']
                };
            });
            ExportExcel(temp, 'RewardsTabReports');
        }
    };

    const generatePDF = async () => {
        const rewards = await REPORTS.REWARDS({ ...Filters, $limit: -1 });
        if (rewards.status) {
            const pdftableColumn = [
                'User ID',
                'Username',
                'Phone No.',
                'Total Availed',
                'Total Pending',
                'Total Redeemed',
                'Total Expired',
                'Total Discarded',
            ];
            const pdftableRows = [];
            rewards?.data.forEach((item) => {
                const ReportsListData = [
                    item['id'],
                    item['username'],
                    item['phoneNumber'],
                    item['total_availed'],
                    item['total_pending'],
                    item['total_redeemed'],
                    item['total_expired'],
                    item['total_discard']
                ];
                pdftableRows.push(ReportsListData);
            });
            ExportPdf(pdftableColumn, pdftableRows, 'RewardsTabReports', '"l", "mm", "a4"');
        }
    };

    const fetchDropDowns = async () => {
        const countriesRes = await DROPDOWN.GET_COUNTRIES();
        if (countriesRes.status) {
            setCountries(countriesRes.data);
        }
        const statesRes = await DROPDOWN.GET_STATES();
        if (statesRes.status) {
            setStates(statesRes.data);
        }
        const suburbRes = await DROPDOWN.GET_SUBURBS();
        if (suburbRes.status) {
            setSuburbs(suburbRes.data);
        }
    }

    const fetchReportRewards = async () => {
        const res = await REPORTS.REWARDS(Filters);
        if (res.status) {
            setRewards(res);
            setTableLoading(false);
        }
        else {
            toast.error('Error in fetching data');
        }
    }

    useEffect(() => {
        fetchDropDowns()
    }, []);



    const handleChangeOfSearch = (e) => {
        setTempSearch(e.target.value);
        if (!e.target.value) {
            onFilterChange('search', null);
            setTempSearch(null);
        }
    };

    const handleSearch = () => {
        onFilterChange('search', tempSearch);
    };

    const clearAll = () => {
        setTempSearch('');
        setFilters({ $limit: 10, $skip: 0, page: 1, search: null, sort: null, startDate: null, endDate: null, country: null, state: null, suburb: null, rewardType: null, status: null, total_availed: [0, 500], total_pending: [0, 500], total_redeemed: [0, 500], total_discard: [0, 500], total_expired: [0, 500] });
    };

    const columns = [
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REWARDS.TABLE.USERNAME}</span>, 'username', column),
            dataIndex: 'username',
            key: 'username',
            align: 'left',
            width: 250,
            sorter: Rewards?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value, record) =>
                value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REWARDS.TABLE.PHONE}</span>, 'username', column),
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            align: 'left',
            width: 180,
            sorter: Rewards?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{value}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REWARDS.TABLE.TOTAL_REWARDS}</span>, 'username', column),
            dataIndex: 'total_availed',
            key: 'total_availed',
            align: 'left',
            width: 220,
            sorter: Rewards?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                <span className="tabletext center">{value}</span>,
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REWARDS.TABLE.REDEEMED_AMT}</span>, 'username', column),
            dataIndex: 'total_pending',
            key: 'total_pending',
            align: 'left',
            width: 250,
            sorter: Rewards?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                <span className="tabletext center">{value}</span>
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REWARDS.TABLE.REDEEMED_AMT}</span>, 'username', column),
            dataIndex: 'total_redeemed',
            key: 'total_redeemed',
            align: 'left',
            width: 250,
            sorter: Rewards?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                <span className="tabletext center">{value}</span>
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REWARDS.TABLE.DISCARDED_AMT}</span>, 'username', column),
            dataIndex: 'total_discard',
            key: 'total_discard',
            align: 'left',
            width: 250,
            sorter: Rewards?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                <span className="tabletext center">{value}</span>
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REWARDS.TABLE.EXPIRED_AMT}</span>, 'username', column),
            dataIndex: 'total_expired',
            key: 'total_expired',
            align: 'left',
            width: 250,
            sorter: Rewards?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                <span className="tabletext center">{value}</span>
        }
    ];

    return (
        <div className='userTabReportLayout'>
            <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} withmenu={true} />
            <Row className={isFilterShow ? 'filterBoxLayout mb-3' : 'filterBoxLayout d-none'}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Form.Item
                                label={<span className="inputTitle">{FILTER_STRING.START_DATE}</span>}
                                colon={false}>
                                <DatePicker
                                    className='w-100 inputField'
                                    placeholder=""
                                    value={Filters.startDate}
                                    onChange={val => onFilterChange('startDate', val)}
                                    disabledDate={d => !d || d.isAfter(Filters.endDate)}
                                    suffixIcon={<CalenderDarkIcon />} />
                            </Form.Item>
                            <Form.Item
                                label={<span className="inputTitle">{FILTER_STRING.END_DATE}</span>}
                                colon={false}>
                                <DatePicker
                                    className='w-100 inputField'
                                    placeholder=""
                                    value={Filters.endDate}
                                    onChange={val => onFilterChange('endDate', val)}
                                    disabledDate={d => !d || d.isSameOrBefore(Filters.startDate)}
                                    suffixIcon={<CalenderDarkIcon />} />
                            </Form.Item>
                            <Form.Item
                                label={<span className="inputTitle">{FILTER_STRING.SEARCH}</span>}
                                className='searchInpUserTab'
                                colon={false}>
                                <Input className='inputField searchField'
                                    placeholder={COMMON_STRING.SEARCH_STR + 'Reward Info'}
                                    value={tempSearch}
                                    allowClear
                                    onChange={(e) => handleChangeOfSearch(e)}
                                    onKeyPress={(e) => {
                                        if (e?.key === 'Enter' && e.target.value) {
                                            handleSearch();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16} className={'dropdownBox'}>
                            <div className="filterSelect">
                                <Select defaultValue={FILTER_STRING.COUNTRY} onChange={val => onFilterChange('country', val)} suffixIcon={<SelectArrow />} className="mr-1 m-auto cursor-pointer filterDropdown" bordered={false} value={Filters.country}><Select.Option>{FILTER_STRING.COUNTRY}</Select.Option>{Countries?.map((country, key) => <Select.Option key={key} value={country.name}>{country.name}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={FILTER_STRING.STATE} onChange={val => onFilterChange('state', val)} suffixIcon={<SelectArrow />} className="mr-1 m-auto cursor-pointer filterDropdown" bordered={false} value={Filters.state}><Select.Option>{FILTER_STRING.STATE}</Select.Option>{States?.map((state, key) => <Select.Option key={key} value={state.name}>{state.name}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={FILTER_STRING.SUBURBS} onChange={val => onFilterChange('suburb', val)} suffixIcon={<SelectArrow />} className="mr-1 m-auto cursor-pointer filterDropdown" bordered={false} value={Filters.suburb}><Select.Option>{FILTER_STRING.SUBURBS}</Select.Option>{Suburbs?.map((suburb, key) => <Select.Option key={key} value={suburb.name}>{suburb.name}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={REPORTS_STRINGS.REWARDS.FILTER.REWARD_TYPE} onChange={val => onFilterChange('rewardType', val)} suffixIcon={<SelectArrow />} className="mr-1 m-auto cursor-pointer filterDropdown" bordered={false} value={Filters.rewardType}><Select.Option>{REPORTS_STRINGS.REWARDS.FILTER.REWARD_TYPE}</Select.Option>{RewardsTypes?.map((type, key) => <Select.Option key={key} value={type}>{type}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={REPORTS_STRINGS.REWARDS.FILTER.STATUS} /*onChange={val => onFilterChange('status', val)}*/ suffixIcon={<SelectArrow />} className="mr-1 m-auto cursor-pointer filterDropdown" bordered={false} value={Filters.status}><Select.Option>{REPORTS_STRINGS.REWARDS.FILTER.STATUS}</Select.Option>{RedeemStatues.map((value, key) => <Select.Option key={key} value={value}>{value}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSlider">
                                <div className="sliderTitle">{REPORTS_STRINGS.REWARDS.FILTER.PENDING_POINTS}</div>
                                <Slider trackStyle={{ background: "#879EC4" }} range marks={marks} onAfterChange={val => onFilterChange('total_pending', val)} max={600} defaultValue={[0, 500]} />
                            </div>
                            <div className="filterSlider">
                                <div className="sliderTitle">{REPORTS_STRINGS.REWARDS.FILTER.REDEEM_POINTS}</div>
                                <Slider trackStyle={{ background: "#879EC4" }} range marks={marks} onAfterChange={val => onFilterChange('total_redeemed', val)} max={600} defaultValue={[0, 500]} />
                            </div>
                            <div className="filterSlider">
                                <div className="sliderTitle">{REPORTS_STRINGS.REWARDS.FILTER.DISCARDED_POINTS}</div>
                                <Slider trackStyle={{ background: "#879EC4" }} range marks={marks} onAfterChange={val => onFilterChange('total_discard', val)} max={600} defaultValue={[0, 500]} />
                            </div>
                            <div className="filterSlider">
                                <div className="sliderTitle">{REPORTS_STRINGS.REWARDS.FILTER.EXPIRED_POINTS}</div>
                                <Slider trackStyle={{ background: "#879EC4" }} range marks={marks} onAfterChange={val => onFilterChange('total_expired', val)} max={600} defaultValue={[0, 500]} />
                            </div>
                            <div className="exportOuterBoxFlex">
                                <div className="exportBox">
                                    <div className="exportTitle">Export</div>
                                    <div><div className="exportBtn" onClick={handleDownloadCsv}>{REPORTS_STRINGS.BUTTONS.EXPORT_CSV}</div>
                                        <CSVLink
                                            data={csv}
                                            headers={headers}
                                            filename={handleFileName('FinanceReport', 'csv')}
                                            ref={csvLink}
                                            className='text-black font-weight-600'
                                        ></CSVLink>
                                    </div>
                                    <div className="exportBtn" onClick={generateExcel}>{REPORTS_STRINGS.BUTTONS.EXPORT_EXCEL}</div>
                                    <div className="exportBtn" onClick={generatePDF}>{REPORTS_STRINGS.BUTTONS.EXPORT_PDF}</div>
                                </div>
                                <div className="filterSelect clearAll" onClick={() => clearAll()}>Clear all</div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table
                        columns={columns}
                        dataSource={Rewards?.data}
                        pagination={false}
                        rowKey='id'
                        loading={tableLoading}
                        onChange={onChange}
                        scroll={{ x: 1200 }}
                    />
                    {Rewards?.total > 10 && (
                        <div className='text-end bg-white mt-7'>
                            <PaginationCom
                                onChange={onChangePage}
                                showSizeChanger={true}
                                current={Filters.page}
                                showLessItems={true}
                                total={Rewards?.total}
                            />
                        </div>)}
                </Col>
            </Row>
        </div>
    );
};

RewardsTabReport.propTypes = {
    permissions: PropTypes.any
};

export default RewardsTabReport;
