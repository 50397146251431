import { methods, serviceMaker } from '../service';

const GET_STAMPDUTY = (id) => serviceMaker(`stamp-duty/${id}`, methods.GET);

const GET_STdAMPDUTYS = (
  limit,
  skip,
  search = null,
  sort = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }

  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/stamp-duty`, methods.GET, query);
  }

  return serviceMaker(`stampduty-states`, methods.GET, query);
};

const GET_STAMPDUTYS = () => serviceMaker(`stampduty-states`, methods.GET, {
  params: {
    $limit: -1,
    $skip: 0
  },
});

const DELETE_STAMPDUTY = (id) => serviceMaker(`stamp-duty/${id}`, methods.DELETE);

const DELETE_STAMPDUTY_STATE = (id) => serviceMaker(`stampduty-states/${id}`, methods.DELETE);

const CREATE_STAMPDUTY = (payload) =>
  serviceMaker(`stamp-duty`, methods.POST, null, payload);

const PATCH_STAMPDUTY = (id, payload) =>
  serviceMaker(`stamp-duty/${id}`, methods.PATCH, null, payload);

const DISTCOLS_STAMPDUTY = (column_name) =>
  serviceMaker(`stamp-duty?distict=${column_name}`, methods.GET);

const STAMPDUTYS = {
  FIND: GET_STAMPDUTY,
  GET: GET_STAMPDUTYS,
  CREATE: CREATE_STAMPDUTY,
  PATCH: PATCH_STAMPDUTY,
  DELETE: DELETE_STAMPDUTY,
  DELETE_STATE: DELETE_STAMPDUTY_STATE,
  DISTCOLS: DISTCOLS_STAMPDUTY
};

export { STAMPDUTYS };
