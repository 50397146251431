 import React from "react";
const ReferralIcon = () => {
    return <span className='anticon anticon-referrals ant-menu-item-icon'>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.49984 10.8337C9.34079 10.8337 10.8332 9.34128 10.8332 7.50033C10.8332 5.65938 9.34079 4.16699 7.49984 4.16699C5.65889 4.16699 4.1665 5.65938 4.1665 7.50033C4.1665 9.34128 5.65889 10.8337 7.49984 10.8337Z" fill="#B7C5DC"/>
    <path d="M7.50016 12.5003C5.27516 12.5003 0.833496 13.617 0.833496 15.8337V17.5003H14.1668V15.8337C14.1668 13.617 9.72516 12.5003 7.50016 12.5003ZM13.9668 4.46699L12.5668 5.87533C13.2668 6.85866 13.2668 8.13366 12.5668 9.11699L13.9668 10.5253C15.6502 8.84199 15.6502 6.30033 13.9668 4.46699ZM16.7252 1.66699L15.3668 3.02533C17.6752 5.54199 17.6752 9.32533 15.3668 11.9753L16.7252 13.3337C19.9752 10.092 19.9835 5.04199 16.7252 1.66699Z" fill="#B7C5DC"/>
    </svg>
    </span>
}

export default ReferralIcon;