import React from 'react';
import CanvasJSReact from '../../assets/js/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
import { Col } from 'antd';
import PropTypes from 'prop-types';
import { DASHBOARD_STRING } from '../../services/strings';

const TotalUsers = (props) => {
  const { totalUser } = props;
  const totalRatio = totalUser ? (parseInt(totalUser.android) + parseInt(totalUser.web) + parseInt(totalUser.ios)) : 0;
  const androidRatio = (totalUser && totalRatio > 0) ? (parseInt(totalUser.android) * 100 / totalRatio) : 0;
  const webRatio = (totalUser && totalRatio > 0) ? (parseInt(totalUser.web) * 100 / totalRatio) : 0;
  const iosRatio = (totalUser && totalRatio > 0) ? (parseInt(totalUser.ios) * 100 / totalRatio) : 0;

  const options = {
    animationEnabled: true,
    height: 340,
    axisX: {
      labelFontColor: '#324666',
    },
    backgroundColor: '#ffffff00',
    data: [{
      legendMarkerType: 'square',
      type: 'doughnut',
      showInLegend: true,
      indexLabel: '{name}: {y}',
      yValueFormatString: '#,###'%'',
      dataPoints: [
        { name: 'iOS', y: iosRatio, color: '#F66D66'},
        { name: 'Web', y: webRatio, color: '#FDDAD9'},
        { name: 'Android', y: androidRatio, color: '#F1190F'}
      ]
    }]
  };

  return (
    <Col xs={24} sm={24} md={24} lg={9} xl={9} className='mt-4 gutter-row'>
      <div className='bg-white box-shadow-3 border-radius-10 p-2 dashboardCards total_user_card'>
        <h2 className='font-weight-700 mb-0'>{DASHBOARD_STRING.TOTAL_USRER_PLTFM_WISE}</h2>
        <h2 className='font-weight-700 mb-0 mt-2'>{totalRatio}</h2>
        <div className='dashboard_users_canvarJs'><CanvasJSChart options={options} /></div>
      </div>
    </Col>
  );
};

TotalUsers.propTypes = {
  'totalUser': PropTypes.object
};

export default TotalUsers;