import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

const InsideHeader = ({ children, className, style }) => {
  return (
    <Row className={`bg-white pt-1 box-shadow-3 border-radius-5 ${className}`} style={style}>
      <Col span={24}>{children}</Col>
    </Row>
  );
};

InsideHeader.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  style: PropTypes.any
}

export default InsideHeader;
