import React, { useEffect, useState } from 'react';
import moment from 'moment';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { COMMON_ALERTS, COMMON_STRING, REDEEM_REWARDS_STRINGS } from '../../services/strings';
import { Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Switch, Upload } from 'antd';
import CalenderDarkIcon from '../../components/Icon/CalenderDarkIcon';
import PencileIcon from '../../components/Icon/PencileIcon';
import SelectArrow from '../../components/Icon/SelectArrow';
import { REDEEM_SERVICES } from '../../services/RedeemRewards/RedeemServices';
import { getLSItem } from '../../services/auth/encryptLogin';
import { BASE_URL } from '../../services/constant';
import { DROPDOWN } from '../../services/Dropdown/dropdowns';
import { REDEEM_CATEGORIES } from '../../services/RedeemRewards/RedeemCategories';
import MultipleSelection from '../../components/Inputs/MultipleSelection';
import FileInput from '../../components/Inputs/FileInput';

const AddEditReedemableServices = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [MediaItem1Id, setMediaItem1Id] = useState(null);
    const [MediaItem2Id, setMediaItem2Id] = useState(null);
    const [MediaItem3Id, setMediaItem3Id] = useState(null);
    const [fileList1, setFileList1] = useState([]);
    const [fileList2, setFileList2] = useState([]);
    const [fileList3, setFileList3] = useState([]);
    const accessToken = getLSItem("pdollar-token");
    const [Countries, setCountries] = useState([]);
    const [States, setStates] = useState([]);
    const [Categories, setCategories] = useState([]);
    const [Suburbs, setSuburbs] = useState([]);
    const dRService = useLocation().state?.serviceId;

    const uploadButton = (
        <div className='uploadImageBtn'>
            <div>
                <PencileIcon />
            </div>
            <span className='noFileText'>{COMMON_STRING.NO_FILE_CHOOSEN}</span>
        </div>
    );

    const onFinish = async (e) => {
        let res;
        let msg = '';
        const payload = {
            name: e.serviceName,
            subText: e.subText,
            desc: e.serviceDesc,
            pdAmount: e.pdAmount,
            startDate: moment(e.startDate).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(e.endDate).format('YYYY-MM-DD HH:mm:ss'),
            isIOS: e.platform.includes('ios'),
            isAndroid: e.platform.includes('android'),
            isWeb: e.platform.includes('web'),
            suburbIds: e.suburbIds,
            countryIds: e.countryIds,
            stateIds: e.stateIds,
            categoryId: e.categoryId,
            businessMediaItem1Id: MediaItem1Id,
            businessMediaItem2Id: MediaItem2Id,
            businessMediaItem3Id: MediaItem3Id,
            isActive: e.isActive,
            isPropertyRelated: e.isPropertyRelated
        };

        if (!dRService) {
            res = await REDEEM_SERVICES.CREATE(payload);
            msg = COMMON_ALERTS.ADDED_SUCCESS('Redeem Service');
        }
        else {
            res = await REDEEM_SERVICES.PATCH(dRService, payload);
            msg = COMMON_ALERTS.UPDATED_SUCCESS('Redeem Service');
        }

        if (res.status) {
            toast.success(msg);
            navigate('/redeem_rewards', { state: { selectedTab: 1 } });
        }
    }

    const imageProps = (FileList, setFileList, setMediaItemId) => ({
        name: 'file',
        multiple: false,
        fileList: FileList,
        action: `${BASE_URL}upload-image`,
        headers: {
            authorization: accessToken,
        },
        beforeUpload() {
            if (FileList.length > 1 || FileList.length === 1) {
                toast.warn(COMMON_ALERTS.FILE_EXISTS);
                return false;
            }
        },
        onChange(info) {
            if (info.fileList.length === 1) {
                setFileList([...info.fileList]);
            }
            if (info.file.status === 'done') {
                setMediaItemId(info.file.response.data.media.id);
            } else if (info.file.status === 'error') {
                toast.error(COMMON_ALERTS.FILE_UPLOAD_FAILED(info.file.name));
            }
        },
        async onRemove() {
            setFileList([]);
            setMediaItemId(null);
        },
    });

    const fetchCountries = async () => {
        const countries = await DROPDOWN.GET_COUNTRIES();
        if (countries.status) {
            setCountries(countries.data);
        }
    }

    const fetchState = async (countryId) => {
        const states = await DROPDOWN.GET_STATES();
        if (Array.isArray(states.data))
            setStates(states.data);
    }

    const fetchSuburbs = async (cityId) => {
        form.setFieldsValue({
            suburbsId: ''
        });
        const suburbs = await DROPDOWN.GET_SUBURBS(cityId);
        if (Array.isArray(suburbs.data))
            setSuburbs(suburbs.data);
    }

    const fetchCategory = async () => {
        const res = await REDEEM_CATEGORIES.GET();
        if (res.status && Array(res.data)) {
            setCategories(res.data);
        }
    }

    const fetchService = async () => {
        const res = await REDEEM_SERVICES.FIND(dRService);
        if (res.status) {
            const data = res.data;
            const platform = [];
            if (data.isIOS) platform.push('ios');
            if (data.isAndroid) platform.push('android');
            if (data.isWeb) platform.push('web');

            const q = {
                serviceName: data.name,
                subText: data.subText,
                serviceDesc: data.desc,
                pdAmount: data.pdAmount,
                startDate: moment(data.startDate),
                endDate: moment(data.endDate),
                platform: platform,
                suburbIds: data.suburbIds,
                countryIds: data.countryIds,
                stateIds: data.stateIds,
                categoryId: data.categoryId,
                isActive: data.isActive,
                isPropertyRelated: data.isPropertyRelated
            };

            if (data.businessMediaItem1) {
                setMediaItem1Id(data.businessMediaItem1.id);
                setFileList1([
                    {
                        uid: '-1',
                        name: 'Image1',
                        status: 'done',
                        url: BASE_URL.slice(0, -1) + data?.businessMediaItem1?.image
                    }
                ]);
            }

            if (data.businessMediaItem2) {
                setMediaItem2Id(data.businessMediaItem2.id);
                setFileList2([
                    {
                        uid: '-1',
                        name: 'Image 2',
                        status: 'done',
                        url: BASE_URL.slice(0, -1) + data?.businessMediaItem2?.image
                    }
                ]);
            }

            if (data.businessMediaItem3) {
                setMediaItem3Id(data.businessMediaItem3.id);
                setFileList3([
                    {
                        uid: '-1',
                        name: 'Image 3',
                        status: 'done',
                        url: BASE_URL.slice(0, -1) + data?.businessMediaItem3?.image
                    }
                ]);
            }

            form.setFieldsValue(q);
        }
    }

    useEffect(() => {
        fetchCountries();
        fetchState();
        fetchSuburbs();
        fetchCategory();
        if (dRService) {
            fetchService();
        }
    }, []);


    return (
        <div className='fWidthShowBox commonLayout'>
            <Row>
                <Col xs={24} sm={24} md={{ span: 8, offset: 6 }} lg={{ span: 8, offset: 6 }} xl={{ span: 8, offset: 6 }}>
                    <h2 className="h2title">{dRService ? REDEEM_REWARDS_STRINGS.ADD_EDIT.EDIT_LABEL : REDEEM_REWARDS_STRINGS.ADD_EDIT.ADD_NEW_LABEL}</h2>
                </Col>
            </Row>
            <Form className="addformLayout" name="complex-form" form={form} onFinish={onFinish} labelCol={{ span: 6 }} colon={false} wrapperCol={{ span: 8 }}>
                <FormItem
                    label={<span className='inputTitle'>{REDEEM_REWARDS_STRINGS.ADD_EDIT.SERVICE_NAME}</span>} name="serviceName" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(REDEEM_REWARDS_STRINGS.ADD_EDIT.SERVICE_NAME) }]}>
                    <Input className="input-control" />
                </FormItem>
                <FormItem
                    label={<span className='inputTitle'>{REDEEM_REWARDS_STRINGS.ADD_EDIT.SUB_TEXT}</span>} name="subText" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(REDEEM_REWARDS_STRINGS.ADD_EDIT.SUB_TEXT) }]}>
                    <TextArea rows={4} className="input-textarea" />
                </FormItem>
                <FormItem
                    label={<span className='inputTitle'>{REDEEM_REWARDS_STRINGS.ADD_EDIT.SERVICE_DESC}</span>} name="serviceDesc" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(REDEEM_REWARDS_STRINGS.ADD_EDIT.SERVICE_DESC) }]}>
                    <TextArea rows={4} className="input-textarea" />
                </FormItem>
                <FormItem
                    label={<span className='inputTitle'>{REDEEM_REWARDS_STRINGS.ADD_EDIT.CATEGORIES}</span>} name="categoryId" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(REDEEM_REWARDS_STRINGS.ADD_EDIT.CATEGORIES) }]}>
                    <Select className="input-control" suffixIcon={<SelectArrow />}>
                        {Categories.map(category => <Select.Option key={category.id} value={category.id}>{category.name}</Select.Option>)}
                    </Select>
                </FormItem>
                <FormItem
                    label={<span className='inputTitle'>{REDEEM_REWARDS_STRINGS.ADD_EDIT.PD_AMOUNT}</span>} name="pdAmount" rules={[
                        { required: true, message: COMMON_ALERTS.REQUIRED_INPUT(REDEEM_REWARDS_STRINGS.ADD_EDIT.PD_AMOUNT) },
                        { validator: (_, val)=> (val && val < 0)? Promise.reject('Value should not be negative'): Promise.resolve() }
                    ]}>
                    <InputNumber controls={false} className="input-control" />
                </FormItem>
                <FormItem
                    label={<span className='inputTitle'>{REDEEM_REWARDS_STRINGS.ADD_EDIT.START_DATE}</span>} name="startDate" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('start date') }]}>
                    <DatePicker
                        className="input-control"
                        suffixIcon={<CalenderDarkIcon />}
                        disabledDate={d => !d || d.isAfter(form.getFieldValue('endDate') || null)}
                    />
                </FormItem>
                <FormItem
                    label={<span className='inputTitle'>{REDEEM_REWARDS_STRINGS.ADD_EDIT.END_DATE}</span>} name="endDate" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('end date') }]}>
                    <DatePicker
                        className="input-control"
                        suffixIcon={<CalenderDarkIcon />}
                        disabledDate={d => !d || d.isSameOrBefore(form.getFieldValue('startDate') || null)}
                    />
                </FormItem>
                <FormItem
                    label={<span className='inputTitle'>{REDEEM_REWARDS_STRINGS.ADD_EDIT.COUNTRY}</span>} name="countryIds" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('country') }]}>
                    <MultipleSelection options={Countries} placeholder='Select Countries' />
                </FormItem>
                <FormItem
                    label={<span className='inputTitle'>{REDEEM_REWARDS_STRINGS.ADD_EDIT.STATE}</span>}
                    name="stateIds"
                    rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('state') }]}>
                    <MultipleSelection options={States} placeholder='Select States' />
                </FormItem>
                <FormItem
                    label={<span className='inputTitle'>{REDEEM_REWARDS_STRINGS.ADD_EDIT.SUBURB}</span>} name="suburbIds" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('suburbs') }]}>
                    <MultipleSelection options={Suburbs} placeholder='Select Suburbs' />
                </FormItem>
                <FormItem label={<span className='inputTitle'>{REDEEM_REWARDS_STRINGS.ADD_EDIT.UPLOAD_IMAGES}</span>} className='imageUploadBoxxs' name="image" required
                    rules={[
                        // {required: true}
                        {
                            validator: () => {
                                if ((!MediaItem1Id) && (!MediaItem2Id) && (!MediaItem3Id))
                                    return Promise.reject(COMMON_ALERTS.REQUIRED_CHOOSE('aleast one image'));
                                else return Promise.resolve();
                            }
                        }
                    ]}
                >
                    {/* <FileInput accept=".png, .jpg, .jpeg" filelist={fileList1} setfileList={setFileList1} maxCount={3}/> */}
                    <div className="imagePickerDiv"><Upload accept=".png, .jpg, .jpeg" listType="picture" {...imageProps(fileList1, setFileList1, setMediaItem1Id)}>
                        {fileList1.length >= 1 ? null : uploadButton}
                    </Upload>
                    </div>
                    <div className="imagePickerDiv"><Upload accept=".png, .jpg, .jpeg" listType="picture" {...imageProps(fileList2, setFileList2, setMediaItem2Id)}>
                        {fileList2.length >= 1 ? null : uploadButton}
                    </Upload>
                    </div>
                    <div className="imagePickerDiv"><Upload accept=".png, .jpg, .jpeg" listType="picture" {...imageProps(fileList3, setFileList3, setMediaItem3Id)}>
                        {fileList3.length >= 1 ? null : uploadButton}
                    </Upload>
                    </div>
                </FormItem>
                <FormItem
                    name="isActive"
                    valuePropName='checked'
                    initialValue={true}
                    label={<span className='inputTitle'>{REDEEM_REWARDS_STRINGS.ADD_EDIT.ACTIVE}</span>}>
                    <Switch />
                </FormItem>
                <FormItem
                    name="isPropertyRelated"
                    valuePropName='checked'
                    label={<span className='inputTitle'>{REDEEM_REWARDS_STRINGS.ADD_EDIT.IS_PROPERTY_RELATED}</span>}>
                    <Switch />
                </FormItem>
                <FormItem
                    name="platform" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_CHOOSE('platforms') }]}
                    label={<span className='inputTitle'>{REDEEM_REWARDS_STRINGS.ADD_EDIT.SELECT_PLATFORM}</span>}>
                    <Checkbox.Group>
                        <Checkbox value='android'>{COMMON_STRING.ANDROID}</Checkbox>
                        <Checkbox value='ios'>{COMMON_STRING.IOS}</Checkbox>
                        <Checkbox value='web'>{COMMON_STRING.WEB}</Checkbox>
                    </Checkbox.Group>
                </FormItem>
                <Row>
                    <Col xs={24} sm={24} md={{ offset: 2, span: 16 }} lg={{ offset: 2, span: 16 }} xl={{ offset: 2, span: 16 }} className="btnBoxMulti">
                        <button type='button' className="cancelBtnMulti" onClick={() => navigate('/redeem_rewards', { state: { selectedTab: 1 } })}>{COMMON_STRING.BACK_BTN}</button>
                        <button type='submit' className="addBtnMulti">{dRService ? COMMON_STRING.UPDATE_BTN : COMMON_STRING.CREATE_BTN}</button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default AddEditReedemableServices;
