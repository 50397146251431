import { Col, DatePicker, Form, Input, Row, Select, Table, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import PaginationCom from '../../components/PaginationCom';
import { tableSortIconToggle } from '../../utility/tableSorting';
import DeleteModel from '../../components/DeleteModel';
import { toast } from 'react-toastify';
import PencileIcon from '../../components/Icon/PencileIcon';
import DeleteIcon from '../../components/Icon/DeleteIcon';
import { useNavigate } from 'react-router-dom';
import SelectArrow from '../../components/Icon/SelectArrow';
import { REDEEM_SERVICES } from '../../services/RedeemRewards/RedeemServices';
import moment from 'moment';
import FormItem from 'antd/es/form/FormItem';
import CalenderDarkIcon from '../../components/Icon/CalenderDarkIcon';
import PropTypes from 'prop-types';
import FilterToogleBtn from '../../components/FilterToogleBtn';
import { COMMON_ALERTS, COMMON_STRING, FILTER_STRING, REDEEM_REWARDS_STRINGS, TABLE_STRING } from '../../services/strings';
import { DROPDOWN } from '../../services/Dropdown/dropdowns';
import AppButton from '../../components/AppButton';

const RedeemRewards = ({ permissions }) => {
  const navigate = useNavigate();
  const searchInp = useRef();
  const [form] = Form.useForm();
  const [Services, setService] = useState(null);
  const [tableLoading, setTableLoading] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [sort, setSort] = useState(null);
  const [tableDataLength, setTableDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [CountriesMenu, setCountriesMenu] = useState([{ id: "", name: "Country" }]);
  const [StatesMenu, setStatesMenu] = useState([{ id: "", name: "State" }]);
  const [SuburbsMenu, setSuburbsMenu] = useState([{ id: "", name: "Suburbs" }]);
  const [CitiesMenu, setCitiesMenu] = useState([{ id: "", name: "City" }]);
  const [pdAmounts, setPdAmounts] = useState([]);
  const [FilterStartDate, setFilterStartDate] = useState("");
  const [FilterEndDate, setFilterEndDate] = useState("");
  const [FIlterCountryId, setFIlterCountryId] = useState("");
  const [FilterStateId, setFilterStateId] = useState("");
  const [FilterCityId, setFilterCityId] = useState("");
  const [FilterSuburbId, setFilterSuburbId] = useState("");
  const [FilterPdAmount, setFilterPdAmount] = useState("");
  const [isFilterShow, setIsFilterShow] = useState(false);

  const fetchCountries = async () => {
    const countriesData = await DROPDOWN.GET_COUNTRIES();
    if (countriesData.status && Array.isArray(countriesData.data)) {
      setCountriesMenu([...CountriesMenu, ...countriesData.data]);
    }
  }

  const fetchStates = async () => {
    const statesData = await DROPDOWN.GET_STATES();
    if (statesData.status && Array.isArray(statesData.data)) {
      setStatesMenu([...StatesMenu, ...statesData.data]);
    }
  }

  const fetchSuburbs = async () => {
    const suburbsData = await DROPDOWN.GET_SUBURBS();
    if (suburbsData.status && Array.isArray(suburbsData.data)) {
      setSuburbsMenu([...SuburbsMenu, ...suburbsData.data]);
    }
  }

  const fetchCities = async () => {
    const citiesData = await DROPDOWN.GET_CITY();
    if (citiesData.status && Array.isArray(citiesData.data)) {
      setCitiesMenu([...CitiesMenu, ...citiesData.data]);
    }
  }

  const fetchDistPdAmount = async () => {
    const pdAmounts = await REDEEM_SERVICES.DISTCOLS('pdAmount');
    if (pdAmounts.status && Array.isArray(pdAmounts.data)) {
      setPdAmounts(pdAmounts.data);
    }
  }

  useEffect(() => {
    fetchCountries();
    fetchStates();
    fetchSuburbs();
    fetchCities();
    fetchDistPdAmount();
  }, []);

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const deleteService = async () => {
    try {
      const res = await REDEEM_SERVICES.DELETE(deleteId);
      if (res.status) {
        toast.success(COMMON_ALERTS.DELETED_SUCCESS('Service'));
        setIsDeleteModalVisible(false);
        fetchService(currentPage, pageSize, search, sort);
      }
    }
    catch (e) {
      // console.log(e);
    }
  }

  const fetchService = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    filterStartDate = null,
    filterEndDate = null,
    countryId = null,
    stateId = null,
    cityId = null,
    suburbId = null,
    pdAmount = null
  ) => {
    setTableLoading(true);
    try {
      const res = await REDEEM_SERVICES.GET(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        filterStartDate,
        filterEndDate,
        countryId,
        stateId,
        cityId,
        suburbId,
        pdAmount
      );
      if (res.status === 1 || res.status === '1') {
        setService(res);
        setTableDataLength(res?.data && res?.data.length);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setTableLoading(false);
    }
  };

  const handleChangeOfSearch = (e) => {
    setTempSearch(e.target.value);
    if (!e.target.value) {
      setCurrentPage(1);
      setSearch(null);
      setTempSearch(null);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  const clearAll = () => {
    form.resetFields();
    setTempSearch("");
    setFIlterCountryId("");
    setFilterCityId("");
    setFilterEndDate("");
    setFilterPdAmount("");
    setFilterStartDate("");
    setFilterStateId("");
    setFilterSuburbId("");
  }


  useEffect(() => {
    fetchService(currentPage, pageSize, search, sort, FilterStartDate, FilterEndDate, FIlterCountryId, FilterStateId, FilterCityId, FilterSuburbId, FilterPdAmount);
  }, [currentPage, pageSize, search, sort, FilterStartDate, FilterEndDate, FIlterCountryId, FilterStateId, FilterCityId, FilterSuburbId, FilterPdAmount]);

  const columns = [
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REDEEM_REWARDS_STRINGS.TABLE.SERVICE_TYPE}</span>, 'service_type', column),
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REDEEM_REWARDS_STRINGS.TABLE.TOTAL_REEDEM}</span>, 'total_redeems', column),
      dataIndex: 'total_redeems',
      key: 'total_redeems',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REDEEM_REWARDS_STRINGS.TABLE.PD_AMOUNT}</span>, 'pdamount', column),
      dataIndex: 'pdAmount',
      key: 'pdAmount',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REDEEM_REWARDS_STRINGS.TABLE.START_DATE}</span>, 'startDate', column),
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{moment(value).format('DD-MMM-YYYY')}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REDEEM_REWARDS_STRINGS.TABLE.END_DATE}</span>, 'endDate', column),
      dataIndex: 'endDate',
      key: 'endDate',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{moment(value).format('DD-MMM-YYYY')}</span> : '-',
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      width: 150,
      key: 'Actions',
      align: 'center',
      dataIndex: 'Actions',
      render: (item, record) => {
        return (
          <div className='actionCol'>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit Reedem Reward'>
                <PencileIcon
                  onClick={() => navigate('/redeem_rewards/edit', { state: { serviceId: record.id } })}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
              </Tooltip>
            )}
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Reedem Reward'>
                <DeleteIcon
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className='commonWrapperLayout redeemRewards'>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="headerNwServieBtnBox">
          <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} withmenu={true} />
          <div className="descheduleBtnBox">
            <AppButton className={'besideFilter'} onClick={() => navigate('/redeem_rewards/add')} text={REDEEM_REWARDS_STRINGS.BUTTONS.CREATE_BTN} withMenu={true}/>
          </div>
        </Col>
      </Row>
      <Row className={isFilterShow ? 'filterCard' : 'd-none filterCard'}>
        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
          <Form name="complex-form" form={form} labelCol={{ md: { span: 6 }, lg: { span: 5 } }} wrapperCol={{ md: { span: 18 }, lg: { span: 17 } }} colon={false}>
            <FormItem
              name="startDate"
              label={<span className='inputTitle'>{FILTER_STRING.START_DATE}</span>}>
              <DatePicker className='searchInpExpense' suffixIcon={<CalenderDarkIcon />} onChange={value => setFilterStartDate(value)} disabledDate={d => !d || d.isAfter(FilterEndDate)} />
            </FormItem>

            <FormItem
              label={<span className='inputTitle'>{FILTER_STRING.SEARCH}</span>}>
              <Input
                ref={searchInp}
                name="search"
                type='search'
                placeholder={COMMON_STRING.SEARCH_STR + 'Redeem rewards'}
                className='input-control searchInpExpense'
                value={tempSearch}
                allowClear
                onChange={(e) => handleChangeOfSearch(e)}
                onKeyPress={(e) => {
                  if (e?.key === 'Enter' && e.target.value) {
                    handleSearch();
                  }
                }}
              />
            </FormItem>
          </Form>
        </Col>
        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
          <FormItem
            colon={false}
            label={<span className='inputTitle'>{FILTER_STRING.END_DATE}</span>}>
            <DatePicker 
              className='searchInpExpense' 
              suffixIcon={<CalenderDarkIcon />} 
              value={FilterEndDate}
              onChange={value => setFilterEndDate(value)} disabledDate={d => !d || d.isSameOrBefore(FilterStartDate)} />
          </FormItem>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="filterSelectBox redeemRewards">
              <div className="filterSelect">
                <Select defaultValue={REDEEM_REWARDS_STRINGS.FIlter.PD_AMOUNT} value={FilterPdAmount} onChange={value => setFilterPdAmount(value)} suffixIcon={<SelectArrow style={{ color: "#324666" }} />} className="mr-2 m-auto cursor-pointer filterDropdown ml-0" bordered={false}><Select.Option value="">{REDEEM_REWARDS_STRINGS.FIlter.PD_AMOUNT}</Select.Option>{pdAmounts.map((obj, key) => <Select.Option key={key} value={obj.pdAmount}>{obj.pdAmount}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.COUNTRY} value={FIlterCountryId} onChange={value => setFIlterCountryId(value)} suffixIcon={<SelectArrow style={{ color: "#324666" }} />} className="mr-2 m-auto cursor-pointer filterDropdown ml-0" bordered={false}>{CountriesMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.STATE} value={FilterStateId} onChange={value => setFilterStateId(value)} suffixIcon={<SelectArrow style={{ color: "#324666" }} />} className="mr-2 m-auto cursor-pointer filterDropdown ml-0" bordered={false}>{StatesMenu.map((state, key) => <Select.Option key={key} value={state.id}>{state.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.SUBURBS} value={FilterSuburbId} onChange={value => setFilterSuburbId(value)} suffixIcon={<SelectArrow style={{ color: "#324666" }} />} className="mr-2 m-auto cursor-pointer filterDropdown ml-0" bordered={false}>{SuburbsMenu.map((suburb, key) => <Select.Option key={key} value={suburb.id}>{suburb.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.CITY} value={FilterCityId} onChange={value => setFilterCityId(value)} suffixIcon={<SelectArrow style={{ color: "#324666" }} />} className="mr-2 m-auto cursor-pointer filterDropdown ml-0" bordered={false}>{CitiesMenu.map((city, key) => <Select.Option key={key} value={city.id}>{city.name}</Select.Option>)}</Select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span className="clearAll" ><span onClick={() => clearAll()} style={{ float: "right" }}>Clear All</span></span>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className={isFilterShow? 'mt-3':''}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            columns={columns}
            dataSource={Services?.data}
            pagination={false}
            rowKey='id'
            loading={tableLoading}
            onChange={onChange}
            scroll={{ x: 400 }} />

          {Services?.total > 10 && (
            <div className='text-end bg-white mt-7'>
              <PaginationCom
                onChange={onChangePage}
                showSizeChanger={true}
                current={currentPage}
                showLessItems={true}
                total={Services?.total}
              />
            </div>
          )}
        </Col>
      </Row>
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteService}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </div>
  );
}

RedeemRewards.propTypes = {
  permissions: PropTypes.any
}

export default RedeemRewards;
