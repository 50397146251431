import { methods, serviceMaker } from '../service';

const FIND_STORIES = (id) => serviceMaker(`stories/${id}`, methods.GET);

const GET_STORIES = (
  limit,
  skip,
  search = null,
  sort = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
  }
  return serviceMaker(`stories`, methods.GET, query);
};

const DELETE_STORIES = (id) => serviceMaker(`stories/${id}`, methods.DELETE);

const CREATE_STORIES = (payload) =>
  serviceMaker(`stories`, methods.POST, null, payload);

const PATCH_STORIES = (id, payload) =>
  serviceMaker(`stories/${id}`, methods.PATCH, null, payload);

const STORIES = {
  FIND: FIND_STORIES,
  GET: GET_STORIES,
  CREATE: CREATE_STORIES,
  PATCH: PATCH_STORIES,
  DELETE: DELETE_STORIES
};

export { STORIES };
