import { Col, DatePicker, Form, Input, Row, Select, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DeleteModel from '../../components/DeleteModel';
import CalenderDarkIcon from '../../components/Icon/CalenderDarkIcon';
import DeleteIcon from '../../components/Icon/DeleteIcon';
import SelectArrow from '../../components/Icon/SelectArrow';
import PaginationCom from '../../components/PaginationCom';
import { FEEDBACKS } from '../../services/Feedbacks/Feedback';
import { tableSortIconToggle } from '../../utility/tableSorting';
import PropTypes from 'prop-types';
import { FEEDBACKS_FACTOR } from '../../services/Feedbacks/FeedbackFactors';
import moment from 'moment';
import FilterToogleBtn from '../../components/FilterToogleBtn';
import { COMMON_ALERTS, COMMON_STRING, FEEDBACK_STRINGS, FILTER_STRING, TABLE_STRING } from '../../services/strings';
import { DROPDOWN } from '../../services/Dropdown/dropdowns';

const FeedbackPage = ({ permissions }) => {
  const [Feedbacks, setFeedbacks] = useState(null);
  const [tableLoading, setTableLoading] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [sort, setSort] = useState(null);
  const [tableDataLength, setTableDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [MenuCountries, setMenuCountries] = useState([]);
  const [MenuStates, setMenuStates] = useState([]);
  const [MenuCities, setMenuCities] = useState([]);
  const [MenuSuburbs, setMenuSuburbs] = useState([]);
  const [MenuRatings, setMenuRatings] = useState([]);
  const [MenuImorovements, setMenuImorovements] = useState([]);
  const [FilterRatings, setFilterRatings] = useState(null);
  const [FilterImprovement, setFilterImprovement] = useState(null);
  const [FilterCountry, setFilterCountry] = useState(null);
  const [FilterState, setFilterState] = useState(null);
  const [FilterCity, setFilterCity] = useState(null);
  const [FilterSuburb, setFilterSuburb] = useState(null);
  const [DateRangeStart, setDateRangeStart] = useState(null);
  const [DateRangeEnd, setDateRangeEnd] = useState(null);
  const [FilterDateRange, setFilterDateRange] = useState(null);
  const [isFilterShow, setIsFilterShow] = useState(false);

  const DateRanges = [{ id: null, name: 'Date Range' },
  { id: '1days', name: 'Last days' },
  { id: '7days', name: 'Last 7 days' },
  { id: '30days', name: 'Last 30 days' }];

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const deleteFeedbacks = async () => {
    try {
      const res = await FEEDBACKS.DELETE(deleteId);
      if (res.status) {
        toast.success(COMMON_ALERTS.DELETED_SUCCESS('Feedback'));
        setIsDeleteModalVisible(false);
        fetchFeedbacks(currentPage, pageSize, search, sort);
      }
    }
    catch (e) {
      // console.log(e);
    }
  }

  const fetchFeedbacks = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    ratings = null,
    improvements = null,
    country = null,
    state = null,
    city = null,
    suburb = null,
    DateRangeStart = null,
    DateRangeEnd = null,
    FilterDateRange = null
  ) => {
    setTableLoading(true);
    try {
      const res = await FEEDBACKS.GET(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        ratings,
        improvements,
        country,
        state,
        city,
        suburb,
        DateRangeStart,
        DateRangeEnd,
        FilterDateRange
      );
      if (res.status === 1 || res.status === '1') {
        setFeedbacks(res);
        setTableDataLength(res?.data && res?.data.length);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setTableLoading(false);
    }
  };

  const handleChangeOfSearch = (e) => {
    setTempSearch(e.target.value);
    if (!e.target.value) {
      setCurrentPage(1);
      setSearch(null);
      setTempSearch(null);
    }
  };


  const fetchCountries = async () => {
    const countriesData = await DROPDOWN.GET_COUNTRIES();
    if (countriesData.status && Array.isArray(countriesData.data)) {
      setMenuCountries(countriesData.data);
    }
  }

  const fetchStates = async () => {
    const statesData = await DROPDOWN.GET_STATES();
    if (statesData.status && Array.isArray(statesData.data)) {
      setMenuStates(statesData.data);
    }
  }

  const fetchSuburbs = async () => {
    const suburbsData = await DROPDOWN.GET_SUBURBS();
    if (suburbsData.status && Array.isArray(suburbsData.data)) {
      setMenuSuburbs(suburbsData.data);
    }
  }

  const fetchCities = async () => {
    const citiesData = await DROPDOWN.GET_CITY();
    if (citiesData.status && Array.isArray(citiesData.data)) {
      setMenuCities(citiesData.data);
    }
  }

  const fetchDistinctRatings = async () => {
    const ratings = await FEEDBACKS.DISTCOLS('rating');
    if (ratings.status && Array.isArray(ratings.data)) {
      const ratinggs = [];
      ratings.data.map(rating => ratinggs.push(rating.rating));
      setMenuRatings(ratinggs);
    }
  }

  const fetchImprovements = async () => {
    const improvements = await FEEDBACKS_FACTOR.GET(-1);
    if (improvements.status && Array.isArray(improvements.data)) {
      setMenuImorovements(improvements.data);
    }
  }

  useEffect(() => {
    fetchCountries();
    fetchStates();
    fetchSuburbs();
    fetchCities();
    fetchDistinctRatings();
    fetchImprovements();
  }, []);


  const handleSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  useEffect(() => {
    fetchFeedbacks(currentPage, pageSize, search, sort, FilterRatings, FilterImprovement, FilterCountry, FilterState, FilterCity, FilterSuburb, DateRangeStart, DateRangeEnd, FilterDateRange);
  }, [currentPage, pageSize, search, sort, FilterRatings, FilterImprovement, FilterCountry, FilterState, FilterCity, FilterSuburb, DateRangeStart, DateRangeEnd, FilterDateRange]);

  const columns = [
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{FEEDBACK_STRINGS.TABLE.FEEDBACK}</span>, 'Feedback', column),
      dataIndex: 'desc',
      key: 'feedback',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle"> {FEEDBACK_STRINGS.TABLE.DATE}</span>, 'date', column),
      dataIndex: 'postedOn',
      key: 'date',
      align: 'center',
      width: 180,
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{moment(value).format('DD/MM/YYYY')}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{FEEDBACK_STRINGS.TABLE.RATINGS}</span>, 'ratings', column),
      dataIndex: 'rating',
      key: 'ratings',
      align: 'center',
      width: 150,
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? value : '-',
    },
    {
      title: (column) => <span className="tableTitle">{FEEDBACK_STRINGS.TABLE.WHAT_IMPROVEMENT}</span>,
      dataIndex: 'feedbackselects',
      key: 'feedbackFactorId',
      align: 'left',
      width: 250,
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>{
        const stringArr = value.map(elem => elem.name);
        value = stringArr.join(', ');
        return value ? <span className="tabletext noLineClamp">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-';
      }
        
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      width: 150,
      key: 'Actions',
      align: 'center',
      dataIndex: 'Actions',
      render: (item, record) => {
        return (
          <div className='actionCol'>
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Feedback'>
                <DeleteIcon
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className='feedbackLayout'>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} />
            </Col>
          </Row>
          <Row className={isFilterShow ? 'fWidthShowBox pRentalIncomePage mt-3' : 'fWidthShowBox pRentalIncomePage d-none'}>
            <Col xs={24} sm={24} md={24} lg={11} xl={8}>
              <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <Form.Item className='form_item' label={<span className='formInpTitle'>{FILTER_STRING.START_DATE}</span>} colon={false}>
                  <DatePicker onChange={value => setDateRangeStart(value)} suffixIcon={<CalenderDarkIcon />} disabledDate={d => !d || d.isAfter(DateRangeEnd)} />
                </Form.Item>
                <Form.Item className='form_item' label={<span className='formInpTitle'>{FILTER_STRING.END_DATE}</span>} colon={false}>
                  <DatePicker onChange={value => setDateRangeEnd(value)} suffixIcon={<CalenderDarkIcon />} disabledDate={d => !d || d.isSameOrBefore(DateRangeStart)} />
                </Form.Item>
                <Form.Item className='form_item' label={<span className='formInpTitle'>{FILTER_STRING.SEARCH}</span>} colon={false}>
                  <Input
                    className='searchInp'
                    placeholder={COMMON_STRING.SEARCH_STR + 'Referral'}
                    value={tempSearch}
                    allowClear
                    onChange={(e) => handleChangeOfSearch(e)}
                    onKeyPress={(e) => {
                      if (e?.key === 'Enter' && e.target.value) {
                        handleSearch();
                      }
                    }} />
                </Form.Item>
              </Form>
            </Col>
            <Col xs={24} sm={24} md={24} lg={13} xl={16} className="filterDropDownBox">
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.COUNTRY} onChange={value => setFilterCountry(value)} suffixIcon={<SelectArrow/>} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value=''>{FILTER_STRING.COUNTRY}</Select.Option>{MenuCountries.map((country, key) => <Select.Option key={key} value={country.name}>{country.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.STATE} onChange={value => setFilterState(value)} suffixIcon={<SelectArrow/>} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value=''>{FILTER_STRING.STATE}</Select.Option>{MenuStates.map((state, key) => <Select.Option key={key} value={state.name}>{state.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.CITY} onChange={value => setFilterCity(value)} suffixIcon={<SelectArrow/>} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value=''>{FILTER_STRING.CITY}</Select.Option>{MenuCities.map((city, key) => <Select.Option key={key} value={city.name}>{city.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.SUBURBS} onChange={value => setFilterSuburb(value)} suffixIcon={<SelectArrow/>} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value=''>{FILTER_STRING.SUBURBS}</Select.Option>{MenuSuburbs.map((suburbs, key) => <Select.Option key={key} value={suburbs.name}>{suburbs.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FEEDBACK_STRINGS.FILTER.RATINGS} onChange={value => setFilterRatings(value)} suffixIcon={<SelectArrow/>} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value=''>{FEEDBACK_STRINGS.FILTER.RATINGS}</Select.Option>{MenuRatings.map((rating, key) => <Select.Option key={key} value={rating}>{rating}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FEEDBACK_STRINGS.FILTER.WHAT_IMPROVEMENT} onChange={value => setFilterImprovement(value)} suffixIcon={<SelectArrow/>} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value=''>{FEEDBACK_STRINGS.FILTER.WHAT_IMPROVEMENT}</Select.Option>{MenuImorovements.map((improvememnt, key) => <Select.Option key={key} value={improvememnt.id}>{improvememnt.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FEEDBACK_STRINGS.FILTER.DATE_RANGE} onChange={value => setFilterDateRange(value)} suffixIcon={<SelectArrow/>} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{DateRanges.map((obj, key) => <Select.Option key={key} value={obj.id}>{obj.name}</Select.Option>)}</Select>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-3">
          <Table
            columns={columns}
            dataSource={Feedbacks?.data}
            pagination={false}
            rowKey='id'
            loading={tableLoading}
            onChange={onChange}
            scroll={{ x: 400 }}
          />

          {Feedbacks?.total > 10 && (
            <div className='text-end bg-white mt-7'>
              <PaginationCom
                onChange={onChangePage}
                showSizeChanger={true}
                current={currentPage}
                showLessItems={true}
                total={Feedbacks?.total}
              />
            </div>
          )}
        </Col>
      </Row>
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteFeedbacks}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </div>
  );
}

FeedbackPage.propTypes = {
  permissions: PropTypes.any
}

export default FeedbackPage;
