import { Checkbox, Col, DatePicker, Form, Input, Row, Table, Tooltip } from 'antd';
 import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteModel from '../../components/DeleteModel';
import CalenderDarkIcon from '../../components/Icon/CalenderDarkIcon';
import DeleteIcon from '../../components/Icon/DeleteIcon';
import PencileIcon from '../../components/Icon/PencileIcon';
import PaginationCom from '../../components/PaginationCom';
import { tableSortIconToggle } from '../../utility/tableSorting';
import PropTypes from 'prop-types';
import { NOTIFICATIONS } from '../../services/Notification/notificationService';
import { toast } from 'react-toastify';
import moment from 'moment';
import { COMMON_ALERTS, COMMON_STRING, FILTER_STRING, NOTIFICATIONS_STRINGS, TABLE_STRING } from '../../services/strings';
import { removeLSItem } from '../../services/auth/encryptLogin';

const NotificationsPage = ({permissions}) => {
    const navigate = useNavigate();
    const searchInp = useRef();
    const [Notifications, setNotifications] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [IsFilterShow, setIsFilterShow] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [sort, setSort] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState(null);
    const [tempSearch, setTempSearch] = useState(null);
    const [FilterStartDate, setFilterStartDate] = useState(null);
    const [FilterEndDate, setFilterEndDate] = useState(null);
    const [FilterScheduled, setFilterScheduled] = useState(false); //In this we need to call api on become 'true';
    const [FIlterPublished, setFIlterPublished] = useState(false);//In this we need to call api on become 'true';

    const handleSearch = () => {
      setCurrentPage(1);
      setSearch(tempSearch);
    };
      
    const handleChangeOfSearch = (e) => {
      setTempSearch(e.target.value);
      if (!e.target.value) {
      setCurrentPage(1);
      setSearch(null);
      setTempSearch(null);
      }
    };

    useEffect(() => {
      removeLSItem('notification-step-1');
      removeLSItem('notification-step-2');
      removeLSItem('notification-step-3');
      removeLSItem('notification-step-4');
    }, []);

    const fetchNotifications = async (
      page = 1,
      pageSize = 10,
      search = null,
      sort = null,
      FilterStartDate  = null,
      FilterEndDate = null, 
      FilterScheduled = null,
      FIlterPublished = null
    ) => {
      setTableLoading(true);
      try {
        const res = await NOTIFICATIONS.GET(
          pageSize,
          (page - 1) * pageSize,
          search,
          sort,
          FilterStartDate,
          FilterEndDate,
          FilterScheduled,
          FIlterPublished
        );
        if (res.status === 1 || res.status === '1') {
          setNotifications(res);
        }
      } catch (err) {
        setTableLoading(false);
        toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
      } finally {
        setTableLoading(false);
      }
    };

    useEffect(() => {
      fetchNotifications(currentPage, pageSize, search, sort, FilterStartDate, FilterEndDate, FilterScheduled, FIlterPublished);
    }, [currentPage, pageSize, search, sort, FilterStartDate, FilterEndDate, FilterScheduled, FIlterPublished]);

    const columns = [
        {
          title: (column) => tableSortIconToggle(<span className="tableTitle">{NOTIFICATIONS_STRINGS.TABLE.NOTIFICATION_TITLE}</span>, 'title', column),
          dataIndex: 'title',
          key: 'title',
          align: 'left',
          sorter: true,
          width: 400,
          sortDirections: ['ascend', 'descend'],
          render: (value) =>
            value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
        },
        {
          title: (column) => tableSortIconToggle(<span className="tableTitle"> {NOTIFICATIONS_STRINGS.TABLE.DATE}</span>, 'createdAt', column),
          dataIndex: 'createdDate',
          key: 'createdDate',
          align: 'left',
          sorter: true,
          sortDirections: ['ascend', 'descend'],
          render: (value) =>
            value ? <span className="tabletext">{moment(value).format('DD/MM/yyyy')}</span> : '-',
        },
        {
          title: (column) => tableSortIconToggle(<span className="tableTitle"> {NOTIFICATIONS_STRINGS.TABLE.TIME}</span>, 'createdAt', column),
          dataIndex: 'createdDate',
          key: 'createdDate',
          align: 'left',
          sorter: true,
          sortDirections: ['ascend', 'descend'],
          render: (value) =>
            value ? <span className="tabletext">{moment(value).format('hh:mm A')}</span> : '-',
        },
        {
          title: (column) => tableSortIconToggle(<span className="tableTitle">{NOTIFICATIONS_STRINGS.TABLE.IOS}</span>, 'plateform_ios', column),
          dataIndex: 'plateform_ios',
          key: 'platform_ios',
          align: 'center',
          sorter: true,
          sortDirections: ['ascend', 'descend'],
          render: (value) => <Checkbox checked={value} />,
        },
        {
          title: (column) => tableSortIconToggle(<span className="tableTitle">{NOTIFICATIONS_STRINGS.TABLE.ANDROID}</span>, 'platform_android', column),
          dataIndex: 'plateform_android',
          key: 'platform_android',
          align: 'center',
          sorter: true,
          sortDirections: ['ascend', 'descend'],
          render: (value) => <Checkbox checked={value} />,
        },  
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{NOTIFICATIONS_STRINGS.TABLE.WEB}</span>, 'plateform_web', column),
            dataIndex: 'plateform_web',
            key: 'platform_web',
            align: 'center',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (value) => <Checkbox checked={value} />,
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{NOTIFICATIONS_STRINGS.TABLE.STATUS}</span>, 'isPublished', column),
            dataIndex: 'isPublished',
            key: 'isPublished',
            align: 'left',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            render: (value) => <span className="labelTag">{value?'Published':'Scheduled'}</span>
        },
        {
          title: <span  className="tableTitle">{TABLE_STRING.ACTION}</span>,
          width: 150,
          key: 'Actions',
          align: 'center',
          dataIndex: 'Actions',
          render: (item, record) => {
            return (
              <div className='actionCol'>
                {((permissions?.isUpdate || permissions?.isUpdate === undefined) && !record.isPublished) && (
                  <Tooltip title='Edit Notification'>
                    <PencileIcon
                      onClick={()=>navigate('/notifications/edit', {state: {notificationId: record.id}})}
                      className='font-size-20 delete-btn-hover cursor-pointer mx-1'/>
                  </Tooltip>
                )}
                {(permissions?.isDelete || permissions?.isDelete === undefined) && (
                  <Tooltip title='Delete Notification'>
                    <DeleteIcon
                      onClick={() => {
                        setIsDeleteModalVisible(true);
                        setDeleteId(record?.id);
                      }}
                      className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                    />
                  </Tooltip>
                )}
              </div>
            );
          },
        },
    ];

    const deleteNotification = async () => {
      try {
        const res = await NOTIFICATIONS.DELETE(deleteId);
  
        if (res.status === 1 || res.status === '1') {
          setDeleteId(null);
          setIsDeleteModalVisible(false);
          toast.success(res?.message);
          //If this page only have one element so we need to reduce the page size
          let page = currentPage;
          if (Notifications?.length === 1 && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            page = page - 1;
          } else {
            fetchNotifications(page, pageSize, search, sort);
          }
        }
      } catch (err) {
        toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
      }
    }

    const onChange = (pagination, filters, sorter) => {
      if (sorter?.order === 'ascend') {
        setSort({ [sorter.field]: 1 });
      } else if (sorter?.order === 'descend') {
        setSort({ [sorter.field]: -1 });
      } else {
        setSort({ createdAt: -1 });
      }
    };

    const onChangePage = (page, pageSize) => {
      setCurrentPage(page);
      setPageSize(pageSize);
    };

    return (
        <div className='commonWrapperLayout notificationPage'>
            <div className="headerContain">
            <div className="searchBox">
                <label className='searchInpLabel' htmlFor={searchInp}>{FILTER_STRING.SEARCH}</label>
                <Input
                    ref={searchInp}
                    type='search'
                    placeholder={COMMON_STRING.SEARCH_STR + 'notification'}
                    className='input-control searchInpExpense'
                    value={tempSearch}
                    allowClear
                    onChange={(e) => handleChangeOfSearch(e)}
                    onKeyPress={(e) => {
                        if (e?.key === 'Enter' && e.target.value) {
                        handleSearch();
                        }
                    }}
                    />
                </div>
                <div className="descheduleBtnBox">
                    <button onClick={()=>navigate('/notifications/add')} className="addDepScheduleBtn">{NOTIFICATIONS_STRINGS.BUTTON.CREATE_NEW_BTN}</button>
                    <button className="addDepScheduleBtn" onClick={()=>setIsFilterShow(!IsFilterShow)}>{NOTIFICATIONS_STRINGS.BUTTON.NOTIFICATION_FILTER}</button>
                </div>
            </div>
            <Row className={IsFilterShow?'checkBoxHeaderBar':'d-none checkBoxHeaderBar'}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="shadowBoxNotification">
                    <Form.Item label={FILTER_STRING.START_DATE} colon={false}>
                        <DatePicker suffixIcon={<CalenderDarkIcon/>} onChange={value => setFilterStartDate(value)} disabledDate={d => !d || d.isAfter(FilterEndDate)}/>
                    </Form.Item>
                    <Form.Item label={FILTER_STRING.END_DATE} colon={false}>
                        <DatePicker suffixIcon={<CalenderDarkIcon/>} onChange={value => setFilterEndDate(value)} disabledDate={d => !d || d.isSameOrBefore(FilterStartDate)}/>
                    </Form.Item>
                    <Form.Item name="checkbox-group"  colon={false}>
                       <Checkbox value={true} style={{ lineHeight: '32px' }} onChange={()=>setFilterScheduled(!FilterScheduled)} >{NOTIFICATIONS_STRINGS.FILTER.SCHEDULED}</Checkbox>
                       <Checkbox value={true} style={{ lineHeight: '32px' }} onChange={()=>setFIlterPublished(!FIlterPublished)}>{NOTIFICATIONS_STRINGS.FILTER.PUBLISHED}</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-3">
                    <Table
                    columns={columns}
                    dataSource={Notifications?.data}
                    pagination={false}
                    rowKey='id'
                    loading={tableLoading}
                    onChange={onChange}
                    scroll={{ x: 400 }}
                    />

                    {Notifications?.total > 10 && (
                    <div className='text-end bg-white mt-7'>
                        <PaginationCom
                        onChange={onChangePage}
                        showSizeChanger={true}
                        current={currentPage}
                        showLessItems={true}
                        total={Notifications?.total}
                        />
                    </div>
                    )}
                </Col>
            </Row>
            <DeleteModel
                isDeleteModalVisible={isDeleteModalVisible}
                deleteModel={deleteNotification}
                setIsDeleteModalVisible={setIsDeleteModalVisible}
            />
        </div>
    );
}

NotificationsPage.propTypes = {
  permissions: PropTypes.any
}

export default NotificationsPage;
