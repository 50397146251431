import React, { useEffect, useState } from 'react';
import InsideHeader from '../../components/InsideHeader/InsideHeader';
import { Col, Row } from 'antd';
import SelectDate from '../../components/SelectDate';
import TotalUsers from '../../components/Dashboard/TotalUsers';
import TotalUserDownloads from '../../components/Dashboard/UserDownloads';
import IncreaseIcon from '../../components/Icon/IncreaseIcon';
import PropertyCountChart from '../../components/Dashboard/PropertyCountChart';
import DollarSpendChart from '../../components/Dashboard/DollarSpendChart';
import ReferralChart from '../../components/Dashboard/ReferralChart';
import { DASHBOARD } from '../../services/Dashboard/dashboard';
import { DASHBOARD_STRING } from '../../services/strings';

function Dashboard() {
  const [DashboardData, setDashboardData] = useState(null);
  const [SelectedPeriod, setSelectedPeriod] = useState('');

  const optionsForTotalUser = {
    pieHole: 0.55,
    is3D: false,
    legend: { position: 'bottom' },
    pieSliceText: 'value',
    backgroundColor: 'transparent',
    slices: {
      0: { color: '#F1190F' },
      1: { color: '#F66D66' },
      2: { color: '#FDDAD9' },
    },
  };

  function intToString(value) {
    var suffixes = ['', 'k', 'm', 'b', 't'];
    var suffixNum = Math.floor(('' + value).length / 3);
    var shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(2));
    if (shortValue % 1 != 0) {
      shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
  }

  const refCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const top10referrals = refCount.map(num => ({
    id: num,
    name: num + '. Property Name',
    number: 5000 + (num * 100)
  }));

  const fetchData = async () => {
    const res = await DASHBOARD.GET(SelectedPeriod);
    if (res?.status) {
      setDashboardData(res.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, [SelectedPeriod]);

  const count_current_properties = DashboardData?.properties?.curret_properties?.total_property;
  const changeIn_properties = !DashboardData?.properties?.periodic_properties?.total_property == 0 ? 0 : (parseInt(DashboardData?.properties?.periodic_properties?.total_property) * 100 / parseInt(DashboardData?.properties?.curret_properties?.total_property));

  return (
    <>
      {/* Header */}
      <InsideHeader>
        <Row>
          <Col xs={24}>
            <SelectDate onChange={(value) => setSelectedPeriod(value)} />
          </Col>
        </Row>
        {/* Total users graph  */}
        <Row justify='space-between' gutter={25}>
          <TotalUsers totalUser={DashboardData?.platform_user}
            optionsForTotalUser={optionsForTotalUser} />
          <TotalUserDownloads data={DashboardData?.userDownloads} />
        </Row>
        <Row justify='space-between' gutter={30}>
          <Col xs={24} sm={24} md={24} lg={9} xl={9} gutter={30}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className='mt-3 gutter-row dashboardCards countCardDashboard'>
                <span className="cardTitleText">{DASHBOARD_STRING.NUM_OF_PROPERTIES} </span>
                <div className='cardDecDiv'>
                  <span className="cout_count">{intToString(parseInt(count_current_properties))}</span>
                  <span className='cout_count_per'><IncreaseIcon /> {parseFloat(changeIn_properties).toFixed(2)} %</span>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className='mt-3 gutter-row dashboardCards countCardDashboard'>
                <span className="cardTitleText">{DASHBOARD_STRING.TOTAL_SERVICE_CONSUMED} </span>
                <div className='cardDecDiv'>
                  <span className="cout_count">5.3K</span>
                  <span className='cout_count_per'><IncreaseIcon /> 23.5 %</span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={15} xl={15} gutter={30} className='mt-3 gutter-row pl-0'>
            <PropertyCountChart data={DashboardData?.property_by_months} />
          </Col>
        </Row>
        <Row justify='space-between' gutter={30}>
          <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className='mt-3 gutter-row dashboardCards countCardDashboard'>
                <span className="cardTitleText">{DASHBOARD_STRING.NUM_API_CALLS_CORELOGIC} </span>
                <div className='cardDecDiv'>
                  <span className="cout_count">2.1K</span>
                  <span className='cout_count_per'><IncreaseIcon /> 29.7 %</span>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className='mt-3 gutter-row dashboardCards countCardDashboard'>
                <span className="cardTitleText">{DASHBOARD_STRING.NUM_ADDRESS_API_CALLS} </span>
                <div className='cardDecDiv'>
                  <span className="cout_count">2.1K</span>
                  <span className='cout_count_per'><IncreaseIcon /> 29.7 %</span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={15} xl={15} className='mt-3 gutter-row pdollarMngtBox pdollarMngtBoxRow'>
            <div className='bg-white box-shadow-3 border-radius-10 p-2 dashboardCards rewardsBox'>
              <h2 className='font-weight-700 mb-0'>{DASHBOARD_STRING.PDOLLAR_MANAGEMENT}</h2>
              <div className="rewardShowBoxCover">
                <div className="rewardShowBox">
                  <div className="title">{DASHBOARD_STRING.TOTAL_REWARD_POINTS}</div>
                  <div className="couniconbox">
                    <div className="count">8.1K</div>
                    <div className="incdescover">
                      <IncreaseIcon />
                      <div className="incredecreTxt">36.9 %</div>
                    </div>
                  </div>
                </div>
                <div style={{ justifyContent: 'center', alignItems: 'center' }} className="rewardShowBox d-flex align-center">
                  <DollarSpendChart />
                  <div className="title">
                    <span>Total 3.7k</span>
                    <span>{DASHBOARD_STRING.TOTAL_PDOLLAR_SPEND}</span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row justify='space-between' gutter={30}>
          <Col xs={24} sm={24} md={24} lg={9} xl={9} className='mt-3 gutter-row dashboardCards referralChart'>
            <ReferralChart />
          </Col>
          <Col xs={24} sm={24} md={24} lg={15} xl={15} className='mt-3 gutter-row'>
            <div className='bg-white box-shadow-3 border-radius-10 p-2 dashboardCards referraltop10'>
              <h2 className='font-weight-700 mb-0'>{DASHBOARD_STRING.TOP_TEN_REFERRALS}</h2>
              <table>
                <thead>
                  <tr>
                    <th><span>Name</span></th>
                    <th><span>Number</span></th>
                  </tr>
                </thead>
                <tbody>
                  {top10referrals.map((elem, key) => <tr key={key}>
                    <td><span>{elem.name}</span></td>
                    <td><span>{elem.number}</span></td>
                  </tr>)}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </InsideHeader>
    </>
  );
}

export default Dashboard;