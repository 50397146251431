import { Checkbox, Col, Row, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import PaginationCom from '../../components/PaginationCom';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import InfoCircleIcon from '../../components/Icon/InfoCircleIcon';
import { PROPERTIES } from '../../services/Property/Properties';
import { toast } from 'react-toastify';
import { COMMON_ALERTS, PROPERTIES_STRING, TABLE_STRING } from '../../services/strings';
import { tableSortIconToggle } from '../../utility/tableSorting';
import ValidateIcon from '../../components/Icon/ValidateIcon';
import moment from 'moment';

const ContactProperties = () => {
  const navigate = useNavigate();
  const [properties, setproperties] = useState(null);
  const [sort, setSort] = useState(null);
  const [tableLoading, setTableLoading] = useState(null);
  const [tableDataLength, setTableDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const location = useLocation().state;
  
  if(!useLocation().state?.contactId){
    navigate('/contacts', {replace: true});
  }

  const goPropertyDetails = (id) => {
    navigate('/properties/details', {
      state: {
        propertyId: id
      }
    });
  }

  const validateProperty = async (id) => {
    navigate('/properties/validate', {
      state: {
        propertyId: id
      }
    });
  }

  const fetchProperties = async (page, pageSize, search, sort, contactId) => {
    setTableLoading(true);
    const res = await PROPERTIES.GET_BY_CONTACT(pageSize,
      (page - 1) * pageSize,
      search,
      sort, location.contactId);
    try {
      if (res.status === 1 || res.status === '1') {
        setproperties(res);
        setTableDataLength(res?.data && res?.data.length);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setTableLoading(false);
    }
  }

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  useEffect(() => {
    fetchProperties(currentPage, pageSize, null, sort);
  }, [currentPage, pageSize, null, sort]);

  const columns = [
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{PROPERTIES_STRING.TABLE.PROPERTY_LIST}</span>, 'name', column),
      dataIndex: 'name',
      key: 'propertList',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{PROPERTIES_STRING.TABLE.PROPERTY_TYPE}</span>, 'property_type', column),
      key: 'property_type',
      dataIndex: 'property_type',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? <span className="tabletext">{value.name}</span> : '-'),
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{PROPERTIES_STRING.TABLE.PURCHASE_DATE}</span>, 'purchaseDate', column),
      key: 'purchaseDate',
      dataIndex: 'purchaseDate',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? <span className="tabletext">{moment(new Date(value)).format("DD/MM/YYYY")}</span> : '-'),
    },
    {
      title: (column) => (<div className='pValidateColumn'><span className="tableTitle">{PROPERTIES_STRING.TABLE.VALIDATE_STATUS} </span></div>),
      dataIndex: 'isValidate',
      key: 'isValidate',
      align: 'center',
      sort: false,
      render: (item, record) => {
        if (record.isValidate) {
          return (<ValidateIcon />);
        }
        else {
          return (<span onClick={() => validateProperty(record.id)} className='validateButton'>{PROPERTIES_STRING.VALIDATE_BTN}</span>);
        }
      },
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">Status</span>, 'propertyStatus', column),
      key: 'propertyStatus',
      dataIndex: 'propertyStatus',
      align: 'center',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? <span className="tabletext">{value}</span> : '-'),
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      key: 'Actions',
      align: 'center',
      dataIndex: 'Actions',
      width: 130,
      render: (item, record) => {
        return (
          <div className='actionCol'>
            <Tooltip title='View Property'>
            <InfoCircleIcon
              onClick={() => goPropertyDetails(record.id)}
              className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div className='propertiesLayout'>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-3">
          <Table
            columns={columns}
            dataSource={properties?.data}
            pagination={false}
            rowKey='id'
            loading={tableLoading}
            onChange={onChange}
            scroll={{ x: 400 }}
          />

          {properties?.total > 10 && (
            <div className='text-end bg-white mt-7'>
              <PaginationCom
                onChange={onChangePage}
                showSizeChanger={true}
                current={currentPage}
                showLessItems={true}
                total={properties?.total}

              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

ContactProperties.propTypes = {
  permissions: PropTypes.any
}

export default ContactProperties;
