 import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {useNavigate } from 'react-router-dom';
import { CALCULATOR } from '../../../services/Calculators/Calculators';
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { convertToHTML } from 'draft-convert';
import { toast } from 'react-toastify'; 
import { CALCULATOR_INFO_STRING, COMMON_ALERTS, COMMON_STRING } from '../../../services/strings';

const EarlyRepaymentCalculator = () => {
    const navigate = useNavigate();
    const [Calculator, setCalculator] = useState(null);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
    const  [convertedContent, setConvertedContent] = useState(null);
    const [errorText, setErrorText] = useState("");
    const calculatorName = 'Early Repayment Calculator';

    const fetchCalculatorDetails = async () => {
        const calc_details = await CALCULATOR.BYNAME(calculatorName);
        if(calc_details.status){
            if(calc_details.data.length){
                setCalculator(calc_details.data[0]);
                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(calc_details.data[0]?.desc))));
            }
        }
    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
      }

    useEffect(() => {
       fetchCalculatorDetails();
    }, []);

    useEffect(()=>{
        convertContentToHTML();
    }, [editorState]);

    const changeEditTExt = (e) => {
        setEditorState(e);
    }

    const updateCalculatorDetails = async () => {
        if(convertedContent=="<p></p>") { setErrorText("Please enter borrowing capacity information!"); return false;}
        else setErrorText("");
        
        if(Calculator)
        {
            const res = await CALCULATOR.PATCH(Calculator.id, {
                desc: convertedContent
            });

            if(res.status){
                toast.success(COMMON_ALERTS.UPDATED_SUCCESS('Calculator details'))
            }
        }
        else{
            const res = await CALCULATOR.CREATE({
                name: calculatorName,
                desc: convertedContent
            });

            if(res.status){
                setCalculator(res.data);
                toast.success(COMMON_ALERTS.ADDED_SUCCESS('Calculator details'))
            }
        }
    }

    return (
        <div className='calculator_detailsLayout'>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                    <h2 className="h2Title">{CALCULATOR_INFO_STRING.STAMP_DUTY.DETAIL_INFO.EARLY_REPAYMENT_LBL}</h2>
                </Col>
            </Row>
            <Row className='fWidthShowBox calculator_detail_box'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                        <span className="detailBxTitle">{CALCULATOR_INFO_STRING.ADD_EDIT_LABEL}</span>
                        <Editor
                            onEditorStateChange={(e)=>changeEditTExt(e)}
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="calculator_detail_inputArea"
                            editorClassName="calculator_detail_editInput"/>
                            <span className="errorTxt">{errorText}</span>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="btnBoxMulti">
                                <button className="cancelBtnMulti" onClick={()=>navigate('/calculator_info')}>{COMMON_STRING.BACK_BTN}</button>
                                <button className="addBtnMulti" onClick={()=>updateCalculatorDetails()}>{COMMON_STRING.CREATE_BTN}</button>
                            </Col>
                        </Row>
                </Col>
            </Row>
        </div>
    );
}

export default EarlyRepaymentCalculator;
