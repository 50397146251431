import { Button, Col, Row } from 'antd';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { COMMON_STRING, FILTER_STRING } from '../../../services/strings';
import { PROPERTIES } from '../../../services/Property/Properties';
import PropertyCard from './PropertyCard';

function PropertiesPrint() {
    const [Properties, setProperties] = useState([]);
    const locationData = useLocation().state;
    const printRef = React.useRef();
    const [PrintTxt, setPrintTxt] = useState('Generate Pdf');
    const navigate = useNavigate();

    const fetchProperties = async () => {
        const res = await PROPERTIES.REPORT(locationData.startDate, locationData.endDate, locationData?.country?.id, locationData.propertyType, locationData.status);
        const properties = Array.isArray(res) ? res : res.data;
        setProperties(properties);
    }

    useEffect(() => {
        fetchProperties();
    }, []);

    const printPdf = async () => {
        setPrintTxt('Generating....');
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
        (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 7, 7, pdfWidth, pdfHeight);
        pdf.save(`Properties-${moment().format('DD_MMMM_YYYY')}.pdf`);
        setPrintTxt('Generate Pdf');
    }

    return <div className="properties_print_page" ref={printRef}>
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="control_boxs">
                <div className="control_box">
                    <div className="control_label">{FILTER_STRING.START_DATE}</div>
                    <div className="control_value">{locationData?.startDate ? moment(locationData?.startDate).format("DD/MM/YYYY") : ''}</div>
                </div>
                <div className="control_box">
                    <div className="control_label">{FILTER_STRING.END_DATE}</div>
                    <div className="control_value">{locationData?.endDate ? moment(locationData?.endDate).format("DD/MM/YYYY") : ''}</div>
                </div>
                <div className="control_box">
                    <div className="control_label">{FILTER_STRING.PROPERTY_TYPE} - </div>
                    <div className="control_value">{locationData?.propertyType}</div>
                </div>
                <div className="control_box">
                    <div className="control_label">{FILTER_STRING.COUNTRY} - </div>
                    <div className="control_value">{locationData?.country?.name}</div>
                </div>
                <div className="control_box">
                    <div className="control_label">{FILTER_STRING.STATUS} - </div>
                    <div className="control_value">{locationData?.status}</div>
                </div>
                <div className="control_box">
                    <button className="commonBtn" onClick={()=>printPdf()}>{PrintTxt}</button>
                    <button className="commonBtn" onClick={()=>navigate(-1)}>{COMMON_STRING.BACK_BTN}</button>
                </div>
            </Col>
        </Row>
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}  className="properties_card_boxes">
                {Properties.map((property, key) => <PropertyCard key={key} property={property} />)}
            </Col>
        </Row>
    </div>;
}

export default PropertiesPrint;