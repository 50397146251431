import { methods, serviceMaker } from '../service';

const GET_ADMINUSER = (id) => serviceMaker(`users/${id}`, methods.GET);

const GET_CHECKUSER = (username, id = null) => serviceMaker(`users/checkusername`, methods.GET, { params: { username, except: id?? undefined } });

const GET_CHECKEMAIL = (email, id = null) => serviceMaker(`users/checkemail`, methods.GET, { params: { email, except: id?? undefined } });

const GET_ADMINUSERS = (
  limit,
  skip,
  search = null,
  sort = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/users`, methods.GET, query);
  }

  return serviceMaker(`users`, methods.GET, query);
};

const DELETE_ADMINUSER = (id) => serviceMaker(`users/${id}`, methods.DELETE);

const CREATE_ADMINUSER = (payload) =>
  serviceMaker(`users`, methods.POST, null, payload);

const PATCH_ADMINUSER = (id, payload) =>
  serviceMaker(`users/${id}`, methods.PATCH, null, payload);

const ADMINUSERS = {
  FIND: GET_ADMINUSER,
  GET: GET_ADMINUSERS,
  CREATE: CREATE_ADMINUSER,
  PATCH: PATCH_ADMINUSER,
  DELETE: DELETE_ADMINUSER,
  CHECKUSER: GET_CHECKUSER,
  CHECKEMAIL: GET_CHECKEMAIL
};

export { ADMINUSERS };
