import React, { useEffect, useRef, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { tableSortIconToggle } from '../../../utility/tableSorting';
import { ExportExcel, handleFileName } from '../../../utility/Export/Export';
import { CSVLink } from 'react-csv';
import { Checkbox, Col, DatePicker, Form, Input, Row, Select, Slider, Table, Tooltip } from 'antd';
import { COMMON_ALERTS, COMMON_STRING, FILTER_STRING, PROPERTIES_STRING, REPORTS_STRINGS, TABLE_STRING } from '../../../services/strings';
import { DROPDOWN } from '../../../services/Dropdown/dropdowns';
import { PROPERTIES } from '../../../services/Property/Properties';
import ValidateIcon from '../../../components/Icon/ValidateIcon';
import CalenderDarkIcon from '../../../components/Icon/CalenderDarkIcon';
import SelectArrow from '../../../components/Icon/SelectArrow';
import PaginationCom from '../../../components/PaginationCom';
import DeleteModel from '../../../components/DeleteModel';
import FilterToogleBtn from '../../../components/FilterToogleBtn';
import InfoCircleIcon from '../../../components/Icon/InfoCircleIcon';
import moment from 'moment';
import PropTypes from 'prop-types';

const PropertiesTabReports = ({ permissions }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [properties, setproperties] = useState(null);
  const [tableLoading, setTableLoading] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [sort, setSort] = useState(null);
  const [tableDataLength, setTableDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [CountriesMenu, setCountriesMenu] = useState([{ id: '', name: 'Country' }]);
  const [StatesMenu, setStatesMenu] = useState([{ id: '', name: 'State' }]);
  const [SuburbsMenu, setSuburbsMenu] = useState([{ id: '', name: 'Suburbs' }]);
  const [PropertypeMenu, setPropertypeMenu] = useState([{ id: '', name: 'Property Type' }]);
  const [CitiesMenu, setCitiesMenu] = useState([{ id: '', name: 'City' }]);
  const [CouncileMenu, setCouncileMenu] = useState([{ id: '', name: 'Council' }]);
  const [FilterStartDate, setFilterStartDate] = useState(null);
  const [FilterEndDate, setFilterEndDate] = useState(null);
  const [FilterCountry, setFilterCountry] = useState('');
  const [FilterState, setFilterState] = useState('');
  const [FilterStatus, setFilterStatus] = useState('');
  const [FilterCarSpace, setFilterCarSpace] = useState('0-0');
  const [FilterBeds, setFilterBeds] = useState('');
  const [FilterBaths, setFilterBaths] = useState('');
  const [FilterLandSpace, setFilterLandSpace] = useState('0-0');
  const [FilterCouncil, setFilterCouncil] = useState('');
  const [ValidateCheckkBx, setValidateCheckkBx] = useState('');
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [csv, setCsv] = useState([]);
  const csvLink = useRef();

  const fetchCountries = async () => {
    const countriesData = await DROPDOWN.GET_COUNTRIES();
    if (countriesData.status && Array.isArray(countriesData.data)) {
      setCountriesMenu([...CountriesMenu, ...countriesData.data]);
    }
  };

  const fetchStates = async () => {
    const statesData = await DROPDOWN.GET_STATES()
    if (statesData.status && Array.isArray(statesData.data)) {
      setStatesMenu([...StatesMenu, ...statesData.data]);
    }
  };

  const fetchSuburbs = async () => {
    const suburbsData = await DROPDOWN.GET_SUBURBS();
    if (suburbsData.status && Array.isArray(suburbsData.data)) {
      setSuburbsMenu([...SuburbsMenu, ...suburbsData.data]);
    }
  };

  const fetchCities = async () => {
    const citiesData = await DROPDOWN.GET_CITY();
    if (citiesData.status && Array.isArray(citiesData.data)) {
      setCitiesMenu([...CitiesMenu, ...citiesData.data]);
    }
  };

  const fetchPropertyTypes = async () => {
    const pTypeData = await DROPDOWN.GET_PROPERTY_TYPES();
    if (pTypeData.status && Array.isArray(pTypeData.data)) {
      setPropertypeMenu([...PropertypeMenu, ...pTypeData.data]);
    }
  };

  const fetchCounciles = async () => {
    const councilesData = await DROPDOWN.GET_COUNCILS();
    if (councilesData.status && Array.isArray(councilesData.data)) {
      setCouncileMenu([...CouncileMenu, ...councilesData.data]);
    }
  };

  useEffect(() => {
    fetchCountries();
    fetchStates();
    fetchSuburbs();
    fetchCities();
    fetchPropertyTypes();
    fetchCounciles();
  }, []);

  const goPropertyDetails = (id) => {
    navigate('/properties/details', {
      state: {
        propertyId: id
      }
    });
  };

  const fetchProperties = async (
    page, pageSize, search, sort,
    FilterStartDate, FilterEndDate, FilterCountry, FilterState, FilterCity,
    FilterSuburb, FilterCouncil, FilterPropertyType, FilterStatus, FilterCarSpace = '0-500',
    FilterBaths, FilterBeds, FilterLandSpace = '0-50000') => {
    setTableLoading(true);
    const res = await PROPERTIES.GET(pageSize,
      (page - 1) * pageSize,
      search,
      sort,
      FilterStartDate, FilterEndDate, FilterCountry, FilterState, FilterCity,
      FilterSuburb, FilterCouncil, FilterBeds, FilterBaths, FilterPropertyType, FilterCarSpace, FilterLandSpace, ValidateCheckkBx ? 'validated' : false, FilterStatus);
    try {
      if (res.status === 1 || res.status === '1') {
        setproperties(res);
        setTableDataLength(res?.data && res?.data.length);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setTableLoading(false);
    }
    setTableLoading(false);
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const deleteProperties = async () => {
    try {
      const res = await PROPERTIES.DELETE(deleteId);

      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        toast.success(res?.message);
        //If this page only have one element so we need to reduce the page size
        let page = currentPage;
        if (properties?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          fetchProperties(page, pageSize, search, sort);
        }
      }
    } catch (err) {
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    }
  };

  const carSpaces = {
    0: '0 sqft',
    500: '500 sqft'
  };

  const landSpaceMarks = {
    0: '0 sqft',
    50000: '50000 sqft'
  };

  const validateProperty = async (id) => {
    navigate('/properties/validate', {
      state: {
        propertyId: id
      }
    });
  };

  const headers = [
    { label: 'Property Id', key: 'id' },
    { label: 'Property Name', key: 'name' },
    { label: 'Property Type', key: 'property_type' },
    { label: 'Purchase Date', key: 'purchaseDate' },
    { label: 'Validated', key: 'isValidate' },
    { label: 'Status', key: 'status' }
  ];

  const handleDownloadCsv = async () => {
    const res = await PROPERTIES.EXPORT();
    if (res.status === 1 || res.status === '1') {
      const temp = res?.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          property_type: item.property_type.name,
          purchaseDate: item?.purchaseDate,
          isValidate: item.isValidate,
          status: item.propertyStatus
        };
      });
      setCsv(temp);
    }
  };

  useEffect(() => {
    if (csv?.length)
      csvLink.current.link.click();
  }, [csv]);

  const generateExcel = async () => {
    const res = await PROPERTIES.EXPORT();
    if (res.status === 1 || res.status === '1') {
      const temp = res?.data?.map((item) => {
        return {
          id: item.id,
          name: item.name,
          property_type: item.property_type.name,
          purchaseDate: item?.purchaseDate,
          isValidate: item.isValidate ? 'true' : 'false',
          status: item.propertyStatus
        };
      });
      ExportExcel(temp, 'propertiesReport');
    }
  };

  const generatePDF = async () => {
    const fcountry = CountriesMenu.filter(elem => elem.id === FilterCountry);
    navigate('/reports/properties', {
      state: {
        startDate: FilterStartDate,
        endDate: FilterEndDate,
        propertyType: null,
        country: fcountry.length ? fcountry[0] : null,
        status: FilterStatus
      }
    });
  };

  const clearAll = () => {
    form.resetFields();
    setFilterStartDate(null);
    setFilterEndDate(null);
    setSearch(null);
    setFilterCountry('');
    setFilterState('');
    setFilterBaths('');
    setFilterBeds('');
    setFilterCarSpace('0-4000');
    setFilterCouncil('');
    setFilterLandSpace('0-40000');
    setFilterStatus('');
  };

  useEffect(() => {
    fetchProperties(currentPage, pageSize, search, sort,
      FilterStartDate, FilterEndDate, FilterCountry, FilterState, null,
      null, FilterCouncil, null, FilterStatus, FilterCarSpace,
      FilterBaths, FilterBeds, FilterLandSpace, ValidateCheckkBx);

  }, [currentPage, pageSize, search, sort,
    FilterStartDate, FilterEndDate, FilterCountry, FilterState, null,
    null, FilterCouncil, null, FilterStatus, FilterCarSpace,
    FilterBaths, FilterBeds, FilterLandSpace, ValidateCheckkBx]);

  const columns = [
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.PROPERTIES.TABLE.PROPERTY_LIST}</span>, 'name', column),
      dataIndex: 'name',
      key: 'propertList',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.PROPERTIES.TABLE.PROPERTY_TYPE}</span>, 'property_type', column),
      key: 'property_type',
      dataIndex: 'property_type',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? <span className="tabletext">{value.name}</span> : '-'),
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.PROPERTIES.TABLE.PURCHASE_DATE}</span>, 'purchaseDate', column),
      key: 'purchaseDate',
      dataIndex: 'purchaseDate',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? <span className="tabletext">{moment(new Date(value)).format('DD/MM/YYYY')}</span> : '-'),
    },
    {
      title: () => (<div className='pValidateColumn' onClick={() => setValidateCheckkBx(!ValidateCheckkBx)}><span className='tableTitle'>{REPORTS_STRINGS.PROPERTIES.TABLE.VALIDATE_PROPERTY} </span><Checkbox checked={ValidateCheckkBx} /></div>),
      dataIndex: 'validate',
      key: 'validate',
      align: 'center',
      sort: false,
      render: (item, record) => {
        if (record.isValidate) {
          return (<ValidateIcon />);
        }
        else {
          return (<span onClick={() => validateProperty(record.id)} className='validateButton '>Validate</span>);
        }
      },
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">Status</span>, 'propertyStatus', column),
      key: 'propertyStatus',
      dataIndex: 'propertyStatus',
      align: 'center',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? <span className="tabletext">{value}</span> : '-'),
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      key: 'Actions',
      align: 'center',
      dataIndex: 'Actions',
      // width: 140,
      render: (item, record) => {
        return (
          <div className='actionCol'>
            <Tooltip title='View Property'>
              <InfoCircleIcon
                onClick={() => goPropertyDetails(record.id)}
                className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
            </Tooltip>
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Property'>
                <DeleteOutlined
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className='propertiesLayout'>
      <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} withmenu={true} />
      <Row className={isFilterShow ? 'mb-3' : 'd-none'}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row className="fWidthShowBox filterLayout reportTab filterBoxLayout">
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form form={form}>
                <Form.Item label={<span className="inputTitle">{FILTER_STRING.START_DATE}</span>} colon={false} name="startDate">
                  <DatePicker className='selectDateInput'
                    placeholder=""
                    suffixIcon={<CalenderDarkIcon />}
                    allowClear
                    onChange={value => setFilterStartDate(value)}
                    disabledDate={d => !d || d.isAfter(FilterEndDate)}
                    picker="date" />
                </Form.Item>
                <Form.Item label={<span className="inputTitle">{FILTER_STRING.END_DATE}</span>} colon={false} name="endDate">
                  <DatePicker className='selectDateInput'
                    placeholder=""
                    suffixIcon={<CalenderDarkIcon />}
                    allowClear
                    onChange={value => setFilterEndDate(value)}
                    disabledDate={d => !d || d.isSameOrBefore(FilterStartDate)}
                    picker="date" />
                </Form.Item>
                <Form.Item
                  label={<span className="inputTitle">{FILTER_STRING.SEARCH}</span>}
                  name="search"
                  colon={false}>
                  <Input placeholder={COMMON_STRING.SEARCH_STR + 'User'}
                    className='inputField searchField'
                    value={tempSearch}
                    onChange={(e) => setTempSearch(e.target.value)}
                    onKeyPress={(e) => {
                      if (e?.key === 'Enter' && e.target.value) {
                        setSearch(e.target.value);
                      }
                      else if (!e.target.value) {
                        setSearch(null);
                      }
                    }} />
                </Form.Item>
              </Form>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className="filterDropDownBox">
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.COUNTRY} value={FilterCountry} onChange={value => setFilterCountry(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{CountriesMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.STATE} value={FilterState} onChange={value => setFilterState(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{StatesMenu.map((state, key) => <Select.Option key={key} value={state.id}>{state.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect carspaces">
                <label className="title">Car Space</label>
                <Slider onChange={value => setFilterCarSpace(value[0] + '-' + value[1])} value={FilterCarSpace.split('-')} style={{ width: '147px' }} max={500} handleStyle={{ background: '#FFFFFF', border: '1.34997px solid #FFFFFF', boxShadow: '0px 0px 2.69994px rgba(0, 0, 0, 0.06), 0px 1.79996px 3.59992px rgba(0, 0, 0, 0.08)' }} trackStyle={{ background: '#879EC4' }} marks={carSpaces} range defaultValue={[0, 300]} />
              </div>
              <div className="filterSelect ">
                <Select onChange={value => setFilterBeds(value)} value={FilterBeds} defaultValue={PROPERTIES_STRING.FILTER.NO_OF_BEDS} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>
                  <Select.Option value=''>{PROPERTIES_STRING.FILTER.NO_OF_BEDS}</Select.Option>
                  {[...Array(10).keys()].map((arr, key) => <Select.Option key={key} value={arr + 1}>{PROPERTIES_STRING.FILTER.NO_OF_BEDS} ({arr + 1})</Select.Option>)}
                </Select>
              </div>
              <div className="filterSelect">
                <Select onChange={value => setFilterBaths(value)} value={FilterBaths} defaultValue={PROPERTIES_STRING.FILTER.NO_OF_BATH} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>
                  <Select.Option value=''>{PROPERTIES_STRING.FILTER.NO_OF_BATH}</Select.Option>
                  {[...Array(10).keys()].map((arr, key) => <Select.Option key={key} value={arr + 1}>{PROPERTIES_STRING.FILTER.NO_OF_BATH} ({arr + 1})</Select.Option>)}
                </Select>
              </div>
              <div className="filterSelect landspace">
                <label className="title">{PROPERTIES_STRING.FILTER.LAND_SPACE}</label>
                <Slider onAfterChange={value => setFilterLandSpace(value[0] + '-' + value[1])} value={FilterLandSpace.split('-')} style={{ width: '147px' }} max={50000} handleStyle={{ background: '#FFFFFF', border: '1.34997px solid #FFFFFF', boxShadow: '0px 0px 2.69994px rgba(0, 0, 0, 0.06), 0px 1.79996px 3.59992px rgba(0, 0, 0, 0.08)' }} trackStyle={{ background: '#879EC4' }} marks={landSpaceMarks} range defaultValue={[0, 40000]} />
              </div>
              <div className="filterSelect">
                <Select onChange={value => setFilterCouncil(value)} value={FilterCouncil} defaultValue={PROPERTIES_STRING.FILTER.COUNCIL} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{CouncileMenu.map((council, key) => <Select.Option key={key} value={council.id}>{council.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select onChange={value => setFilterStatus(value)} value={FilterStatus} defaultValue={PROPERTIES_STRING.FILTER.STATUS} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>
                  <Select.Option value=''>Status</Select.Option>
                  <Select.Option value='completed'>Completed</Select.Option>
                  <Select.Option value='under completed'>Under Completed</Select.Option>
                </Select>
              </div>
              <div className="exportOuterBoxFlex">
                <div className="filterExport">
                  <span className="exportTitle">Export </span>
                  <div><button className="exportBtn" onClick={handleDownloadCsv}>{REPORTS_STRINGS.BUTTONS.EXPORT_CSV}</button>
                    <CSVLink
                      data={csv}
                      headers={headers}
                      filename={handleFileName('propertiesReport', 'csv')}
                      ref={csvLink}
                      className='text-black font-weight-600'
                    ></CSVLink>
                  </div>
                  <button className="exportBtn" onClick={generateExcel}>{REPORTS_STRINGS.BUTTONS.EXPORT_EXCEL}</button>
                  <button className="exportBtn" onClick={generatePDF}>{REPORTS_STRINGS.BUTTONS.EXPORT_PDF}</button>
                </div>
                <div className="filterSelect clearAll" onClick={() => clearAll()}>Clear all</div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            columns={columns}
            dataSource={properties?.data}
            pagination={false}
            rowKey='id'
            loading={tableLoading}
            onChange={onChange}
            scroll={{ x: 400 }}
          />

          {properties?.total > 10 && (
            <div className='text-end bg-white mt-7'>
              <PaginationCom
                onChange={onChangePage}
                showSizeChanger={true}
                current={currentPage}
                showLessItems={true}
                total={properties?.total}
              />
            </div>
          )}
        </Col>
      </Row>
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteProperties}
        setIsDeleteModalVisible={setIsDeleteModalVisible} />
    </div>
  );
};

PropertiesTabReports.propTypes = {
  permissions: PropTypes.any
};

export default PropertiesTabReports;
