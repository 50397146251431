import { methods, serviceMaker } from '../service';

const GET_APP_USERS = (
  limit,
  skip,
  search = null,
  sort = null,
  country = null,
  state = null,
  city = null,
  suburb = null,
  ageGroupId = null,
  platform = null,
  startDate = null,
  endDate = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
    },
  };
  // if (search) {
  //   query['params']['search'] = search;
  // }
  if (country && country != 0) {
    query['params']['country'] = country;
  }
  if (state && state != 0) {
    query['params']['state'] = state;
  }
  if (city && city != 0) {
    query['params']['city'] = city;
  }
  if (suburb && suburb != 0) {
    query['params']['suburb'] = suburb;
  }

  if (startDate && startDate != 0) {
    query['params']['startDate'] = new Date(startDate);
  }

  if (endDate && endDate != 0) {
    query['params']['endDate'] = new Date(endDate);
  }

  if (ageGroupId && ageGroupId != 0) {
    query['params']['ageGroupId'] = ageGroupId;
  }

  // if (platform && platform!=0) {
  //   query['params']['platform'] = platform;
  // }

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (!search) {
    return serviceMaker(`appusers`, methods.GET, query);
  }
  else {
    query['params']['search'] = search;
    return serviceMaker(`search/appusers`, methods.GET, query);
  }
};

const GET_APP_USER = (
  appusers
) => {
  const query = {
    params: {
      $limit: 1,
      $skip: 0,
    },
  };
  // query['params']['id'] = appusers;
  return serviceMaker(`appusers/${appusers}`, methods.GET, query);
};

const DELETE_APP_USERS = (id) => serviceMaker(`appusers/${id}`, methods.DELETE);

const BLOCK_APP_USERS = (id, payload) =>
  serviceMaker(`appusers/${id}`, methods.PATCH, null, payload);

const EXPORT_APP_USERS = () => serviceMaker(`search/appusers`, methods.GET, {
  params: {
    $limit: -1,
    $skip: 0,
  },
});

const APP_USERS = {
  GET: GET_APP_USERS,
  GETID: GET_APP_USER,
  DELETE: DELETE_APP_USERS,
  BLOCK: BLOCK_APP_USERS,
  EXPORT: EXPORT_APP_USERS
};
export { APP_USERS };
