const timezones =[
    {
       "value": '-12:00',
       "text": "(GMT -12:00) Eniwetok, Kwajalein"
    },
    {
       "value": '-11:00',
       "text": "(GMT -11:00) Midway Island, Samoa"
    },
    {
       "value": '-10:00',
       "text": "(GMT -10:00) Hawaii"
    },
    {
       "value": '-9:00',
       "text": "(GMT -9:00) Alaska"
    },
    {
       "value": '-8:00',
       "text": "(GMT -8:00) Pacific Time (US & Canada)"
    },
    {
       "value": '-7:00',
       "text": "(GMT -7:00) Mountain Time (US & Canada)"
    },
    {
       "value": '-6:00',
       "text": "(GMT -6:00) Central Time (US & Canada), Mexico City"
    },
    {
       "value": '-5:00',
       "text": "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima"
    },
    {
       "value": '-4:00',
       "text": "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz"
    },
    {
       "value": '-3:30',
       "text": "(GMT -3:30) Newfoundland"
    },
    {
       "value": '-3:00',
       "text": "(GMT -3:00) Brazil, Buenos Aires, Georgetown"
    },
    {
       "value": '-2:00',
       "text": "(GMT -2:00) Mid-Atlantic"
    },
    {
       "value": '-1:00',
       "text": "(GMT -1:00) Azores, Cape Verde Islands"
    },
    {
       "value": '0',
       "text": "(GMT) Western Europe Time, London, Lisbon, Casablanca"
    },
    {
       "value": '+1',
       "text": "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris"
    },
    {
       "value":'+2',
       "text": "(GMT +2:00) Kaliningrad, South Africa"
    },
    {
       "value": '+3:00',
       "text": "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg"
    },
    {
       "value": '+3:30',
       "text": "(GMT +3:30) Tehran"
    },
    {
       "value": '4:00',
       "text": "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi"
    },
    {
       "value": '4:30',
       "text": "(GMT +4:30) Kabul"
    },
    {
       "value": '+5:00',
       "text": "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent"
    },
    {
       "value": '+5:30',
       "text": "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi"
    },
    {
       "value": '+5:45',
       "text": "(GMT +5:45) Kathmandu"
    },
    {
       "value": '+6:00',
       "text": "(GMT +6:00) Almaty, Dhaka, Colombo"
    },
    {
       "value": '+7:00',
       "text": "(GMT +7:00) Bangkok, Hanoi, Jakarta"
    },
    {
       "value": '+8:00',
       "text": "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong"
    },
    {
       "value": '+9:00',
       "text": "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk"
    },
    {
       "value": '+9:30',
       "text": "(GMT +9:30) Adelaide, Darwin"
    },
    {
       "value": '+10:00',
       "text": "(GMT +10:00) Eastern Australia, Guam, Vladivostok"
    },
    {
       "value": '+11:00',
       "text": "(GMT +11:00) Magadan, Solomon Islands, New Caledonia"
    },
    {
       "value": '+12:00',
       "text": "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka"
    }
 ];

 export default timezones;