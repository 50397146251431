import { Col, Form, Input, Row, Select, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PencileIcon from '../../../../components/Icon/PencileIcon';
import SelectArrow from '../../../../components/Icon/SelectArrow';
import { CITY_STATE_STRINGS, COMMON_ALERTS, COMMON_STRING } from '../../../../services/strings';
import { CITIES } from '../../../../services/Locations/Cities';
import { COUNTRIES } from '../../../../services/Locations/Countries';
import { STATES } from '../../../../services/Locations/States';
import { SUBURBS } from '../../../../services/Locations/Suburbs';
import { getLSItem } from '../../../../services/auth/encryptLogin';
import { BASE_URL } from '../../../../services/constant';
import TextArea from 'antd/lib/input/TextArea';

const AddEditSuburbs = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dsuburbs = useLocation().state?.dsuburbs;
  const [fileList, setFileList] = useState([]);
  const [imageFileList, setImageFileList] = useState([]);
  const [MediaItemId, setMediaItemId] = useState(null);
  const [ImageId, setImageId] = useState([]);
  const [MediaType, setMediaType] = useState('');
  const accessToken = getLSItem("pdollar-token");
  const [Countries, setCountries] = useState([]);
  const [States, setStates] = useState([]);
  const [Cities, setCities] = useState([]);
  const [isDataLoaded, setisDataLoaded] = useState(false);
  console.log('dsuburbs : ', dsuburbs);
  const onFinish = async (e) => {
    let res;
    let msg = '';
    let payload = {};

    if (MediaItemId) {
      if (MediaType === 'excel') payload["excelFileId"] = MediaItemId;
      if (MediaType === 'csv') payload["csvFileId"] = MediaItemId;
    }
    else payload["name"] = e.name;
    payload["name"] = e.name;
    if (e.cityId)
      payload["cityId"] = e.cityId;
    else
      payload["stateId"] = e.stateId;

    Object.assign(payload, {
      mediaItemId: ImageId,
      description: e.description,
      medianPrice: e.medianPrice,
      auctionRate: e.auctionRate,
      totalListing: e.auctionRate
    });

    if (!dsuburbs) {
      res = await SUBURBS.CREATE(payload);
      msg = COMMON_ALERTS.ADDED_SUCCESS('Suburb')
    }
    else {
      res = await SUBURBS.PATCH(dsuburbs.id, payload);
      msg = COMMON_ALERTS.UPDATED_SUCCESS('Suburb');
    }
    if (res.status) {
      toast.success(msg);
      navigate('/city_state', { state: { tabState: 'Suburbs' } })
    }
  }

  const uploadButton = (
    <div className='uploadImageBtn'>
      <div>
        <PencileIcon />
      </div>
      <span className='noFileText'>{COMMON_STRING.NO_FILE_CHOOSEN}</span>
    </div>
  );

  const imageProps = (fileList, setFileList, setMediaItemId, accept = ".png, .jpg, .jpeg") => ({
    name: 'file',
    multiple: false,
    fileList,
    accept,
    action: `${BASE_URL}upload-image`,
    headers: {
      authorization: accessToken,
    },
    beforeUpload() {
      if (fileList.length > 1 || fileList.length === 1) {
        toast.warn(COMMON_ALERTS.FILE_EXISTS);
        return false;
      }
    },
    onChange(info) {
      if (info.fileList.length === 1) {
        setFileList([...info.fileList]);
      }
      if (info.file.status === 'done') {
        setMediaItemId(info.file.response.data.media.id);
      } else if (info.file.status === 'error') {
        toast.error(COMMON_ALERTS.FILE_UPLOAD_FAILED(info.file.name));
      }
    },
    async onRemove() {
      setFileList([]);
      setMediaItemId(null);
      return null;
    },
  });

  const fetchCountries = async () => {
    const countries = await COUNTRIES.GET();
    if (countries.status) {
      setCountries(countries.data);
      if (countries.data.length) {
        const states = await STATES.GET(-1, 0, null, null, dsuburbs ? (dsuburbs?.city?.state?.countryId) : (countries.data[0]?.id));
        if (states.status) {
          setStates(states.data);
          if (dsuburbs && states.data.length) {
            const cities = await CITIES.GET(-1, 0, null, null, dsuburbs ? dsuburbs?.city?.stateId : states.data[0]?.id);
            if (cities.status)
              setCities(cities.data);
          }
        }
      }
    }
    setisDataLoaded(true);
  }

  const changeCountry = async (countryID) => {
    form.setFieldsValue({ stateId: '' });
    form.setFieldsValue({ cityId: '' });
    const states = await STATES.GET(-1, 0, null, null, countryID);
    if (states.status) {
      setStates(states.data);
    }
  }

  const changeState = async (stateID) => {
    form.setFieldsValue({ cityId: '' });
    const cities = await CITIES.GET(-1, 0, null, null, stateID);
    if (cities.status && cities.data.length)
      setCities(cities.data);
  }

  useEffect(() => {
    fetchCountries();
    if (dsuburbs) {
      const payload = {
        countryId: dsuburbs?.city ? dsuburbs?.city?.state?.countryId : dsuburbs?.state?.countryId,
        stateId: dsuburbs?.city ? dsuburbs?.city?.stateId : dsuburbs?.stateId,
        cityId: dsuburbs?.cityId,
        name: dsuburbs?.name,
        description: dsuburbs.description,
        auctionRate: dsuburbs.auctionRate,
        medianPrice: dsuburbs.medianPrice,
        totalListing: dsuburbs.totalListing,
        suburbImage: dsuburbs.mediaItemId? {fileList: [{
          medianItemId: dsuburbs.mediaItemId
        }]}: null
      };
      form.setFieldsValue(payload);
      setImageId(dsuburbs?.mediaItemId);
      setImageFileList([
        {
          uid: '-1',
          name: 'Suburbs Image',
          status: 'done',
          url: BASE_URL.slice(0, -1) + dsuburbs?.media_item?.image,
          thubmUrl: BASE_URL.slice(0, -1) + dsuburbs?.media_item?.image,
        },
      ]);
    }
  }, []);

  return (
    <div className='addCityStateLayout'>
      <Row className='commonLayout'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="">
          <Form form={form} onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 6 }} colon={false}>
            <Row className='fWidthShowBox' >
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name='countryId' label={<span className='input-label'>{CITY_STATE_STRINGS.SELECT_COUNTRY}</span>} rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('country') }]}>
                  <Select className="input-control" onChange={(e) => changeCountry(e)} suffixIcon={<SelectArrow />}>{Countries.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
                </Form.Item>
                <Form.Item name='stateId' label={<span className='input-label'>{CITY_STATE_STRINGS.SELECT_STATE}</span>} rules={
                  [
                    { required: true, message: COMMON_ALERTS.REQUIRED_SELECT('state') }
                  ]
                }>
                  <Select className="input-control" onChange={e => changeState(e)} notFoundContent={COMMON_ALERTS.DATA_NOT_FOUND('state', 'country')} suffixIcon={<SelectArrow />}>{States.map((state, key) => <Select.Option key={key} value={state.id}>{state.name}</Select.Option>)}</Select>
                </Form.Item>
                <Form.Item name='cityId' label={<span className='input-label'>{CITY_STATE_STRINGS.SELECT_CITY}</span>}>
                  <Select className="input-control" notFoundContent={COMMON_ALERTS.DATA_NOT_FOUND('cities', 'state')} suffixIcon={<SelectArrow />}>{Cities.map((city, key) => <Select.Option key={key} value={city.id}>{city.name}</Select.Option>)}</Select>
                </Form.Item>
              </Col>
            </Row>
            <h2 className='h2title addStateTitle'>{dsuburbs ? CITY_STATE_STRINGS.SUBURBS_PAGE.EDIT_LBL : CITY_STATE_STRINGS.SUBURBS_PAGE.ADD_LBL}</h2>
            <Row className='fWidthShowBox'>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="name" label={<span className='input-label'>{CITY_STATE_STRINGS.SUBURBS_PAGE.SUBURB_NAME}</span>}
                  rules={[{ required: !fileList.length, message: COMMON_ALERTS.REQUIRED_INPUT('suburb') }]}
                  rulses={
                    [{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('suburb') }]}>
                  <Input className="input-control" placeholder={COMMON_ALERTS.PLACEHOLDER_INPUT('suburb')} />
                </Form.Item>
                {!dsuburbs && <><span className="orText">Or</span>
                  <Form.Item name='excelfile' label={<span className='input-label'>{COMMON_STRING.UPLOAD_FILE('suburbs via CSV / Excel')}</span>}>
                    <Upload className='imageUploadInput' {...imageProps(fileList, setFileList, setMediaItemId, '.xlsx, .xls, .csv')} >
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  </Form.Item></>}
                <Form.Item name="suburbImage" required label={<span className='input-label'>{CITY_STATE_STRINGS.SUBURBS_PAGE.UPLOAD_IMAGE}</span>} rules={[{
                  validator: (_, value) => {
                    if (value && Array.isArray(value.fileList) && value.fileList.length) {
                      console.log(value.fileList.length);
                      return Promise.resolve();
                    }
                    else {
                      return Promise.reject(COMMON_ALERTS.REQUIRED_INPUT('Suburbs image'));
                    }
                  }
                }]}>
                  <Upload className='imageUploadInput' {...imageProps(imageFileList, setImageFileList, setImageId, '.png, .jpeg, .jpg')} >
                    {imageFileList.length >= 1 ? null : uploadButton}
                  </Upload>
                </Form.Item>
                <Form.Item name='description' label={<span className='input-label'>{CITY_STATE_STRINGS.SUBURBS_PAGE.DESCRIPTION}</span>}
                  rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(CITY_STATE_STRINGS.SUBURBS_PAGE.DESCRIPTION) }]}>
                  <TextArea rows={5} />
                </Form.Item>
                <Form.Item name="medianPrice" label={<span className='input-label'>{CITY_STATE_STRINGS.SUBURBS_PAGE.MEDIUN_PRICE}</span>}
                  rules={
                    [{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(CITY_STATE_STRINGS.SUBURBS_PAGE.MEDIUN_PRICE) }]}>
                  <Input type='number' className="input-control" placeholder={COMMON_ALERTS.PLACEHOLDER_INPUT(CITY_STATE_STRINGS.SUBURBS_PAGE.MEDIUN_PRICE)} />
                </Form.Item>
                <Form.Item name="auctionRate" label={<span className='input-label'>{CITY_STATE_STRINGS.SUBURBS_PAGE.AUCTION_RATE}</span>}
                  rules={
                    [{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(CITY_STATE_STRINGS.SUBURBS_PAGE.AUCTION_RATE) }]}>
                  <Input type='number' className="input-control" placeholder={COMMON_ALERTS.PLACEHOLDER_INPUT(CITY_STATE_STRINGS.SUBURBS_PAGE.AUCTION_RATE)} />
                </Form.Item>
                <Form.Item name="totalListing" label={<span className='input-label'>{CITY_STATE_STRINGS.SUBURBS_PAGE.TOTAL_LISTING}</span>}
                  rules={
                    [{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(CITY_STATE_STRINGS.SUBURBS_PAGE.TOTAL_LISTING) }]}>
                  <Input type='number' className="input-control" placeholder={COMMON_ALERTS.PLACEHOLDER_INPUT(CITY_STATE_STRINGS.SUBURBS_PAGE.TOTAL_LISTING)} />
                </Form.Item>
                <Row>
                  <Col xs={24} sm={24} md={{ offset: 8, span: 16 }} lg={{ offset: 8, span: 16 }} xl={{ offset: 8, span: 16 }} className="btnBox">
                    <button type='button' className="backBtn" onClick={() => navigate('/city_state', { state: { tabState: 'Suburbs' } })}>{COMMON_STRING.BACK_BTN}</button>
                    <button type='submit' className="createBtn">{dsuburbs ? COMMON_STRING.UPDATE_BTN : COMMON_STRING.CREATE_BTN}</button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default AddEditSuburbs;
