import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '../Icon/DeleteIcon';
import EllipseIcon from '../Icon/EllipseIcon';
import PencileIcon from '../Icon/PencileIcon';
import PropTypes from 'prop-types';

const FAQListWidget = ({ faq, setDeleteId, setIsDeleteModalVisible, permissions }) => {
    const navigate = useNavigate();
    const navigateToEdit = () => {
        navigate('/faqs/edit', { state: { dFaq: faq } })
    }

    const deleteList = async () => {
        setDeleteId(faq.id);
        setIsDeleteModalVisible(true);
    }

    return (
        <div className='FAQListWidgetLayout'>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="TitleBtnBx">
                        <div className="bulletBox"><EllipseIcon /></div>
                        <div className="titleBox">
                            <span>{faq.question}</span>
                        </div>
                        <div className="adeditBx">
                            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
                                <div className="editFBtn" onClick={navigateToEdit}><Tooltip title='Edit FAQ'><PencileIcon /></Tooltip></div>
                            )}
                            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
                                <div className="deleteFBtn" onClick={() => deleteList()}><Tooltip title='Delete FAQ'><DeleteIcon /></Tooltip></div>
                            )}
                        </div>
                    </div>
                    <div className="descBox">
                        <div className="desc" dangerouslySetInnerHTML={{ __html: faq.desc }} />
                    </div>
                </Col>
            </Row>
        </div>
    );
}


FAQListWidget.propTypes = {
    faq: PropTypes.any,
    setDeleteId: PropTypes.any,
    setIsDeleteModalVisible: PropTypes.bool,
    permissions: PropTypes.any
}

export default FAQListWidget;
