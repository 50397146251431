import { methods, serviceMaker } from '../service';

const GET_SUBURB = (id) => serviceMaker(`suburbs/${id}`, methods.GET);

const GET_SUBURBS = (
  limit,
  skip,
  search = null,
  sort = null,
  cityId = null,
  stateId = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/suburbs`, methods.GET, query);
  }

  if (cityId) {
    query['params']['cityId'] = cityId;
  }

  if (stateId) {
    query['params']['stateId'] = stateId;
  }

  return serviceMaker(`suburbs`, methods.GET, query);
};

const DELETE_SUBURB = (id) => serviceMaker(`suburbs/${id}`, methods.DELETE);

const CREATE_SUBURB = (payload) =>
  serviceMaker(`suburbs`, methods.POST, null, payload);

const PATCH_SUBURB = (id, payload) =>
  serviceMaker(`suburbs/${id}`, methods.PATCH, null, payload);

const SUBURBS = {
  FIND: GET_SUBURB,
  GET: GET_SUBURBS,
  CREATE: CREATE_SUBURB,
  PATCH: PATCH_SUBURB,
  DELETE: DELETE_SUBURB
};

export { SUBURBS };
