import { methods, serviceMaker } from '../service';

const GET_OFFER = (id) => serviceMaker(`offers/${id}`, methods.GET);

const GET_OFFERS = (
  limit,
  skip,
  search = null,
  sort = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/offers`, methods.GET, query);
  }

  return serviceMaker(`offers`, methods.GET, query);
};

const DELETE_OFFER = (id) => serviceMaker(`offers/${id}`, methods.DELETE);

const CREATE_OFFER = (payload) =>
  serviceMaker(`offers`, methods.POST, null, payload);

const PATCH_OFFER = (id, payload) =>
  serviceMaker(`offers/${id}`, methods.PATCH, null, payload);

const OFFERES = {
  FIND: GET_OFFER,
  GET: GET_OFFERS,
  CREATE: CREATE_OFFER,
  PATCH: PATCH_OFFER,
  DELETE: DELETE_OFFER
};

export { OFFERES };
