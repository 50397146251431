import { Col, DatePicker, Form, Input, Row, Select, Slider, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import CalenderDarkIcon from '../../../components/Icon/CalenderDarkIcon';
import SelectArrow from '../../../components/Icon/SelectArrow';
import PaginationCom from '../../../components/PaginationCom';
import { tableSortIconToggle } from '../../../utility/tableSorting';
import PropTypes from 'prop-types';
import FilterToogleBtn from '../../../components/FilterToogleBtn';
import { REPORTS } from '../../../services/Reports/ReportsService';
import { CSVLink } from 'react-csv';
import { ExportExcel, ExportPdf, handleFileName } from '../../../utility/Export/Export';
import { FILTER_STRING, REPORTS_STRINGS } from '../../../services/strings';
import { DROPDOWN } from '../../../services/Dropdown/dropdowns';
import moment from 'moment';
import { toast } from 'react-toastify';

const ReferalTabRepor = () => {
    const [Referrals, setReferrals] = useState([]);
    const [Countries, setCountries] = useState([]);
    const [States, setStates] = useState([]);
    const [Suburbs, setSuburbs] = useState([]);
    const [tableLoading, setTableLoading] = useState(null);
    const [isFilterShow, setIsFilterShow] = useState(false);
    const [tempSearch, setTempSearch] = useState(null);
    const [csv, setCsv] = useState([]);
    const csvLink = useRef();
    const marks = { 0: '$0', 500: '$500' };
    const [Filters, setFilters] = useState({ $limit: 10, $skip: 0, page: 1, search: null, sort: null, startDate: null, endDate: null, country: null, state: null, suburb: null, referType: null, category: null, pd_referror: [0, 500], pd_referee: [0, 500] });

    const onChangePage = (page, pageSize) => {
        setFilters(prev => ({
            ...prev,
            $limit: pageSize,
            $skip: (page - 1) * pageSize,
            page
        }));
    };

    const onChange = (pagination, filters, sorter) => {
        const sort = {};
        if (sorter?.order === 'ascend') {
            Object.assign(sort, { [sorter.field]: 1 });
        } else if (sorter?.order === 'descend') {
            Object.assign(sort, { [sorter.field]: -1 });
        } else {
            Object.assign(sort, { completedAt: -1 });
        }
        setFilters(prev => ({
            ...prev,
            sort
        }));
    };

    const onFilterChange = (name, val) => {
        setFilters(prev => ({
            ...prev,
            [name]: val === '' ? null : val
        }));
    }

    useEffect(() => {
        fetchReportReferrals(Filters);
    }, [Filters]);

    const headers = [
        { label: 'User Id', key: 'userId' },
        { label: 'Referror Name', key: 'referror_name' },
        { label: 'Referror Phone', key: 'referror_mobile' },
        { label: 'Referror Email', key: 'referror_email' },
        { label: 'Refedss', key: 'refree_name' },
        { label: 'State', key: 'pdReferee' },
        { label: 'Tax Report Feature Availing', key: 'pdReferral' }
    ];

    const handleDownloadCsv = async () => {
        const Resdata = await REPORTS.REFERRALS({ ...Filters, $limit: -1 });
        const temp = Resdata?.data.map((item) => ({
            userId: item['referorId'],
            referror_name: item['referror_name'],
            referror_mobile: item['referror_mobile'],
            referror_email: item['referror_email'],
            refree_name: item['refree_name'],
            pdReferee: item['pdReferee'],
            pdReferral: item['pdReferral']
        }));
        setCsv([...temp]);
        csvLink.current.link.click();
    };

    const generateExcel = async () => {
        const Resdata = await REPORTS.REFERRALS({ ...Filters, $limit: -1 });
        const temp = Resdata?.data?.map((item) => {
            return {
                userId: item['referorId'],
                referror_name: item['referror_name'],
                referror_mobile: item['referror_mobile'],
                referror_email: item['referror_email'],
                refree_name: item['refree_name'],
                pdReferee: item['pdReferee'],
                pdReferral: item['pdReferral']
            };
        });
        ExportExcel(temp, 'Referral Report');
    };

    const generatePDF = async () => {
        const pdftableColumn = [
            'Referor Id',
            'Referor Name',
            'Referor Mobile',
            'Referor Email',
            'Referee Name',
            'PD Referee',
            'PD Referor'
        ];
        const Resdata = await REPORTS.REFERRALS({ ...Filters, $limit: -1 });
        const pdftableRows = [];
        Resdata?.data.forEach((item) => {
            const ReportsListData = [
                item['referorId'],
                item['referror_name'],
                item['referror_mobile'],
                item['referror_email'],
                item['refree_name'],
                item['pdReferee'],
                item['pdReferral']
            ];
            pdftableRows.push(ReportsListData);
        });
        ExportPdf(pdftableColumn, pdftableRows, 'Referral Report');
    };

    const fetchDropDowns = async () => {
        const countriesRes = await DROPDOWN.GET_COUNTRIES();
        if (countriesRes.status) {
            setCountries(countriesRes.data);
        }
        const statesRes = await DROPDOWN.GET_STATES();
        if (statesRes.status) {
            setStates(statesRes.data);
        }
        const suburbRes = await DROPDOWN.GET_SUBURBS();
        if (suburbRes.status) {
            setSuburbs(suburbRes.data);
        }
    }

    const fetchReportReferrals = async () => {
        setTableLoading(true);
        const res = await REPORTS.REFERRALS(Filters);
        if (res.status) {
            setReferrals(res);
            setTableLoading(false);
        }
        else {
            toast.error('Error in fetching data');
        }
        setTableLoading(false);
    }

    useEffect(() => {
        fetchDropDowns()
    }, []);



    const handleChangeOfSearch = (e) => {
        setTempSearch(e.target.value);
        if (!e.target.value) {
            onFilterChange('search', null);
            setTempSearch(null);
        }
    };

    const handleSearch = () => {
        onFilterChange('search', tempSearch);
    };

    const clearAll = () => {
        setTempSearch('');
        setFilters({ $limit: 10, $skip: 0, page: 1, search: null, sort: null, startDate: null, endDate: null, country: null, state: null, suburb: null, referType: null, category: null, pd_referror: [0, 500], pd_referee: [0, 500] });
    };

    const columns = [
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REFERAL.TABLE.REF_NAME}</span>, 'referror_name', column),
            dataIndex: 'referror_name',
            key: 'referror_name',
            align: 'left',
            width: 180,
            sorter: Referrals?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REFERAL.TABLE.PHONE}</span>, 'referror_mobile', column),
            dataIndex: 'referror_mobile',
            key: 'referror_mobile',
            align: 'left',
            width: 180,
            sorter: Referrals?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                <span className="tabletext center">{value}</span>
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REFERAL.TABLE.EMAIL}</span>, 'referror_email', column),
            dataIndex: 'referror_email',
            key: 'referror_email',
            align: 'left',
            width: 220,
            sorter: Referrals?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext center">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REFERAL.TABLE.REFER_BY}</span>, 'refree_name', column),
            dataIndex: 'refree_name',
            key: 'refree_name',
            align: 'left',
            width: 220,
            sorter: Referrals?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext center">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REFERAL.TABLE.PD_REFERRAL}</span>, 'pdReferral', column),
            dataIndex: 'pdReferral',
            key: 'pdReferral',
            align: 'left',
            width: 200,
            sorter: Referrals?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                <span className="tabletext center">{value}</span>
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REFERAL.TABLE.PD_REFEREE}</span>, 'pdReferee', column),
            dataIndex: 'pdReferee',
            key: 'pdReferee',
            align: 'left',
            width: 200,
            sorter: Referrals?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                <span className="tabletext center">{value}</span>
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REFERAL.TABLE.REFER_DATE}</span>, 'referedAt', column),
            dataIndex: 'referedAt',
            key: 'referedAt',
            align: 'left',
            width: 220,
            sorter: Referrals?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext center">{moment(value).format('DD/MM/YYYY')}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REFERAL.TABLE.COMPLETION_DATE}</span>, 'completedAt', column),
            dataIndex: 'completedAt',
            key: 'completedAt',
            align: 'left',
            width: 240,
            sorter: Referrals?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext center">{moment(value).format('DD/MM/YYYY')}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REFERAL.TABLE.STATUS}</span>, 'status', column),
            dataIndex: 'status',
            key: 'status',
            align: 'left',
            width: 220,
            sorter: Referrals?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext center">{value}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.REFERAL.TABLE.REFERRAL_TYPES}</span>, 'type', column),
            dataIndex: 'type',
            key: 'type',
            align: 'left',
            width: 240,
            sorter: Referrals?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext center">{value}</span> : '-',
        }
    ];

    return (
        <div className='userTabReportLayout'>
            <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} withmenu={true} />
            <Row className={isFilterShow ? 'filterBoxLayout mb-3' : 'filterBoxLayout d-none'}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Form.Item
                                label={<span className="inputTitle">{FILTER_STRING.START_DATE}</span>}
                                colon={false}>
                                <DatePicker
                                    className='w-100 inputField'
                                    placeholder=""
                                    value={Filters.startDate}
                                    onChange={val => onFilterChange('startDate', val)}
                                    disabledDate={d => !d || d.isAfter(Filters.endDate)}
                                    suffixIcon={<CalenderDarkIcon />} />
                            </Form.Item>
                            <Form.Item
                                label={<span className="inputTitle">{FILTER_STRING.END_DATE}</span>}
                                colon={false}>
                                <DatePicker
                                    className='w-100 inputField'
                                    placeholder=""
                                    value={Filters.endDate}
                                    onChange={val => onFilterChange('endDate', val)}
                                    disabledDate={d => !d || d.isSameOrBefore(Filters.startDate)}
                                    suffixIcon={<CalenderDarkIcon />} />
                            </Form.Item>
                            <Form.Item
                                label={<span className="inputTitle">{FILTER_STRING.SEARCH}</span>}
                                className='searchInpUserTab'
                                colon={false}>
                                <Input className='inputField searchField'
                                    placeholder="Start Typing To Search Finance Info."
                                    value={tempSearch}
                                    allowClear
                                    onChange={(e) => handleChangeOfSearch(e)}
                                    onKeyPress={(e) => {
                                        if (e?.key === 'Enter' && e.target.value) {
                                            handleSearch();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16} className={'dropdownBox'}>
                            <div className="filterSelect">
                                <Select defaultValue={FILTER_STRING.COUNTRY} onChange={val => onFilterChange('country', val)} suffixIcon={<SelectArrow />} className="mr-1 m-auto cursor-pointer filterDropdown" bordered={false} value={Filters.country}><Select.Option>{FILTER_STRING.COUNTRY}</Select.Option>{Countries?.map((country, key) => <Select.Option key={key} value={country.name}>{country.name}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={FILTER_STRING.STATE} onChange={val => onFilterChange('state', val)} suffixIcon={<SelectArrow />} className="mr-1 m-auto cursor-pointer filterDropdown" bordered={false} value={Filters.state}><Select.Option>{FILTER_STRING.STATE}</Select.Option>{States?.map((state, key) => <Select.Option key={key} value={state.name}>{state.name}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSlider">
                                <div className="sliderTitle">{REPORTS_STRINGS.REFERAL.FILTER.PD_REFERAL}</div>
                                <Slider trackStyle={{ background: "#879EC4" }} range marks={marks} onAfterChange={value => onFilterChange('pd_referror', value)} max={600} defaultValue={[0, 500]} />
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={FILTER_STRING.SUBURBS} onChange={val => onFilterChange('suburb', val)} suffixIcon={<SelectArrow />} className="mr-1 m-auto cursor-pointer filterDropdown" bordered={false} value={Filters.suburb}><Select.Option>{FILTER_STRING.SUBURBS}</Select.Option>{Suburbs?.map((suburb, key) => <Select.Option key={key} value={suburb.name}>{suburb.name}</Select.Option>)}</Select>
                            </div>
                            {/* <div className="filterSelect">
                                <Select defaultValue={REPORTS_STRINGS.REFERAL.FILTER.CATEGORY} onChange={value => onFilterChange('category', value)} suffixIcon={<SelectArrow />} className="mr-1 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value='app'>App</Select.Option><Select.Option value='offer'>Offer</Select.Option></Select>
                            </div> */}
                            <div className="filterSlider">
                                <div className="sliderTitle">{REPORTS_STRINGS.REFERAL.FILTER.PD_REFEREE}</div>
                                <Slider trackStyle={{ background: "#879EC4" }} range marks={marks} onAfterChange={value => onFilterChange('pd_referee', value)} max={600} defaultValue={[0, 500]} />
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={REPORTS_STRINGS.REFERAL.FILTER.REF_TYPES} onChange={value => onFilterChange('type', value)} suffixIcon={<SelectArrow />} className="mr-1 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option>{REPORTS_STRINGS.REFERAL.FILTER.REF_TYPES}</Select.Option><Select.Option value='App'>App</Select.Option><Select.Option value='Offer'>Offer</Select.Option></Select>
                            </div>
                            <div className="exportOuterBoxFlex">
                                <div className="exportBox">
                                    <div className="exportTitle">Export</div>
                                    <div><div className="exportBtn" onClick={handleDownloadCsv}>{REPORTS_STRINGS.BUTTONS.EXPORT_CSV}</div>
                                        <CSVLink
                                            data={csv}
                                            headers={headers}
                                            filename={handleFileName('Referral Report', 'csv')}
                                            ref={csvLink}
                                            className='text-black font-weight-600'
                                        ></CSVLink>
                                    </div>
                                    <div className="exportBtn" onClick={generateExcel}>{REPORTS_STRINGS.BUTTONS.EXPORT_EXCEL}</div>
                                    <div className="exportBtn" onClick={generatePDF}>{REPORTS_STRINGS.BUTTONS.EXPORT_PDF}</div>
                                </div>
                                <div className="filterSelect clearAll" onClick={() => clearAll()}>Clear all</div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table
                        columns={columns}
                        dataSource={Referrals?.data}
                        pagination={false}
                        rowKey='id'
                        loading={tableLoading}
                        onChange={onChange}
                        scroll={{ x: 1800 }}
                    />
                    {Referrals?.total > 10 && (
                        <div className='text-end bg-white mt-7'>
                            <PaginationCom
                                onChange={onChangePage}
                                showSizeChanger={true}
                                current={Filters.page}
                                showLessItems={true}
                                total={Referrals?.total}
                            />
                        </div>)}
                </Col>
            </Row>
        </div>
    );
};

ReferalTabRepor.propTypes = {
    permissions: PropTypes.any
};

export default ReferalTabRepor;
