import React from 'react';
import PropTypes from 'prop-types';
import TabSwitch from '../../components/TabSwitch';
import NewsArticleMenuPage from './NewsArticleMenuPage';
import { NEWS_ARTICLES_STRING } from '../../services/strings';
import ArticlesListPage from './ArticlesListPage';
import AppButton from '../../components/AppButton';
import { useNavigate } from 'react-router-dom';

const NewsArticlesPage = ({ permissions }) => {
    const navigate = useNavigate();
    const tabs = [
        {
            name: NEWS_ARTICLES_STRING.TABS.NEWS,
            component: <NewsArticleMenuPage/>
        },
        {
            name: NEWS_ARTICLES_STRING.TABS.ARTICLES,
            component: <ArticlesListPage/>
        }
    ];
    return (
        <div className='pagelayout'>
            <TabSwitch tabStates={tabs}>
                <AppButton text={NEWS_ARTICLES_STRING.BUTTONS.ADD_BUTTON} onClick={() => navigate('/news_articles/add')}/>
            </TabSwitch>
        </div>
    );
}

NewsArticlesPage.propTypes = {
    permissions: PropTypes.any
};

export default NewsArticlesPage;
