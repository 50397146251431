import { Col, Form, Input, Row, Select, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PencileIcon from '../../../../components/Icon/PencileIcon';
import {  CITY_STATE_STRINGS, COMMON_ALERTS, COMMON_STRING } from '../../../../services/strings';
import { CITIES } from '../../../../services/Locations/Cities';
import { COUNTRIES } from '../../../../services/Locations/Countries';
import { STATES } from '../../../../services/Locations/States';
import { getLSItem } from '../../../../services/auth/encryptLogin';
import { BASE_URL } from '../../../../services/constant';

const AddEditCity = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dcity = useLocation().state?.dcity;
  const [fileList, setFileList] = useState([]);
  const [uploadLink, setuploadLink] = useState('');
  const [MediaItemId, setMediaItemId] = useState(null);
  const [MediaType, setMediaType] = useState('');
  const accessToken = getLSItem("pdollar-token");
  const [Countries, setCountries] = useState([]);
  const [States, setStates] = useState([]);
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [cityInpVal, setCityInpVal] = useState(null);


  const onFinish = async (e) => {
    let res;
    let msg = '';

    let payload = {
      countryId: e.countryId,
      stateId: e.stateId
    };

    if (MediaItemId) {
      if (MediaType === 'excel') payload["excelFileId"] = MediaItemId;
      if (MediaType === 'csv') payload["csvFileId"] = MediaItemId;
    }
    else payload["name"] = e.name;

    if (!dcity) {
      res = await CITIES.CREATE(payload);
      msg = COMMON_ALERTS.ADDED_SUCCESS('City');
    }
    else {
      res = await CITIES.PATCH(dcity?.id, payload);
      msg = COMMON_ALERTS.UPDATED_SUCCESS('City');
    }

    if (res && res.status) {
      toast.success(msg);
      navigate('/city_state', { state: { tabState: 'City' } });
    }
  }

  const uploadButton = (
    <div className='uploadImageBtn'>
      <div>
        <PencileIcon />
      </div>
      <span className='noFileText'>{COMMON_STRING.NO_FILE_CHOOSEN}</span>
    </div>
  );

  const imageProps = {
    name: "file",
    multiple: false,
    fileList: fileList,
    action: `${BASE_URL + uploadLink}`,
    style: {
      width: "127px",
      height: "127px"
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
    beforeUpload(file) {
      if (fileList.length > 1 || fileList.length === 1) {
        toast.warn(COMMON_ALERTS.FILE_EXISTS);
        return false;
      }
      const type =
        file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "text/csv";
      if (!type) {
        toast.error(COMMON_ALERTS.ONLY_ALLOWED_ERROR('CSV or Excel'));
      }

      if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        setMediaType('excel');
        setuploadLink('upload-excel')
      }
      else if (file.type === "text/csv") {
        setMediaType('csv');
        setuploadLink('upload-csv')
      }

      form.setFieldValue("name", null);

      return type || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.fileList.length === 1) {
        setFileList([...info.fileList]);
      }
      if (info.file.status === "done") {
        setMediaItemId(info.file.response?.data?.media.id);
      } else if (info.file.status === "error") {
        toast.error(COMMON_ALERTS.FILE_UPLOAD_FAILED(info.file.name));
      }
    },
    async onRemove() {
      setFileList([]);
      setMediaItemId(null);
    },
  };

  const fetchCountries = async () => {
    const countries = await COUNTRIES.GET();
    if (countries.status) {
      setCountries(countries.data);
      if (countries.data.length) {
        const states = await STATES.GET(-1, 0, null, null, dcity ? dcity.state?.countryId : countries.data[0].id);
        if (Array.isArray(states.data)) {
          setStates(states.data);
        }
      }
    }
    setisDataLoaded(true);
  }

  useEffect(() => {
    fetchCountries();
  }, []);

  const formInitialValue = {
    countryId: Countries[0]?.id,
    stateId: States[0]?.id
  };

  if (dcity) {
    Object.assign(formInitialValue, {
      countryId: dcity?.state?.countryId,
      stateId: dcity?.stateId,
      name: dcity?.name
    });
  }

  const changeCountry = async (countryId) => {
    form.setFieldsValue({ stateId: '' });
    const states = await STATES.GET(-1, 0, null, null, countryId);
    if (Array.isArray(states.data)) {
      setStates(states.data);
    }
  }

  const changeCityInp = (value) => {
    setCityInpVal(value);
    if (value && fileList.length) {
      setFileList([]);
      setMediaItemId(null);
    }
  }

  if (isDataLoaded)
    return (
      <div className='addCityStateLayout'>
        <Row className='commonLayout'>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="">
            <Form form={form} initialValues={formInitialValue} onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 6 }} colon={false}>
              <Row className='fWidthShowBox'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="countryId" label={<span className='input-label'>{CITY_STATE_STRINGS.SELECT_COUNTRY}</span>} rules={[
                    { required: true, message: COMMON_ALERTS.REQUIRED_SELECT('country') }
                  ]}>
                    <Select className="input-control" onChange={(e) => changeCountry(e)}>{Countries.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
                  </Form.Item>
                  <Form.Item name="stateId" label={<span className='input-label'>{CITY_STATE_STRINGS.SELECT_STATE}</span>} rules={[
                    { required: true, message: COMMON_ALERTS.REQUIRED_SELECT('state') }
                  ]}>
                    <Select notFoundContent={COMMON_ALERTS.DATA_NOT_FOUND('state', 'country')} className="input-control">{States.map((state, key) => <Select.Option key={key} value={state.id}>{state.name}</Select.Option>)}</Select>
                  </Form.Item>
                </Col>
              </Row>
              <h2 className='h2title addStateTitle'>{dcity ? CITY_STATE_STRINGS.CITY_PAGE.EDIT_LBL : CITY_STATE_STRINGS.CITY_PAGE.ADD_LBL}</h2>
              <Row className='fWidthShowBox'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="name" label={<span className='input-label'>{CITY_STATE_STRINGS.CITY_PAGE.CITY_NAME}</span>} rules={[
                    { required: !fileList.length, message: COMMON_ALERTS.REQUIRED_INPUT('city name') }
                  ]}>
                    <Input className="input-control" onChange={e => changeCityInp(e.target.value)} placeholder={COMMON_ALERTS.PLACEHOLDER_INPUT('city')} />
                  </Form.Item>
                  {!dcity && <><span className="orText">Or</span>
                  <Form.Item label={<span className='input-label'>{COMMON_STRING.UPLOAD_FILE('council via CSV / Excel')}</span>} required name="file">
                    <Upload disabled={cityInpVal ? true : false} className='imageUploadInput' {...imageProps}>
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  </Form.Item></>}
                  <Row>
                    <Col xs={24} sm={24} md={{ offset: 8, span: 16 }} lg={{ offset: 8, span: 16 }} xl={{ offset: 8, span: 16 }} className="btnBox">
                      <button type="button" className="backBtn" onClick={() => navigate('/city_state', { state: { tabState: 'City' } })}>{COMMON_STRING.BACK_BTN}</button>
                      <button type="submit" className="createBtn">{dcity ? COMMON_STRING.UPDATE_BTN : COMMON_STRING.CREATE_BTN}</button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
}

export default AddEditCity;
