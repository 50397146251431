import { methods, serviceMaker } from '../service';

const GET_EXPENSE = (id) => serviceMaker(`expenses/${id}`, methods.GET);

const GET_EXPENSES = (
  limit,
  skip,
  propertyId,
  search = null,
  sort = null,
  startDate = null,
  endDate = null,
  filterYear = null,
  filterMonth = null,
  filterExpensesType = null,
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }

  if (startDate) {
    query['params']['startDate'] = new Date(startDate);
  }

  if (endDate) {
    query['params']['endDate'] = new Date(endDate);
  }

  if (filterYear) {
    query['params']['filterYear'] = filterYear;
  }

  if (filterMonth) {
    query['params']['filterMonth'] = filterMonth;
  }

  if (filterExpensesType) {
    query['params']['expensesTypeId'] = filterExpensesType;
  }

  if (propertyId) {
    query['params']['propertyId'] = propertyId;
  }

  if (search) {
    query['params']['search'] = search;
    // return serviceMaker(`search/expenses`, methods.GET, query);
  }

  return serviceMaker(`expenses`, methods.GET, query);
};

const DELETE_EXPENSES = (id) => serviceMaker(`expenses/${id}`, methods.DELETE);

const CREATE_EXPENSES = (payload) =>
  serviceMaker(`expenses`, methods.POST, null, payload);

const PATCH_EXPENSES = (id, payload) =>
  serviceMaker(`expenses/${id}`, methods.PATCH, null, payload);

const DISTINCT_YEARS = () => {
  return serviceMaker(`expenses/years`, methods.GET, null);
}

const EXPENSES = {
  FIND: GET_EXPENSE,
  GET: GET_EXPENSES,
  CREATE: CREATE_EXPENSES,
  PATCH: PATCH_EXPENSES,
  DELETE: DELETE_EXPENSES,
  YEARS: DISTINCT_YEARS
};
export { EXPENSES };
