import { Col, DatePicker, Form, Input, Modal, Row, Select, Table, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CalenderDarkIcon from '../../../components/Icon/CalenderDarkIcon';
import SelectArrow from '../../../components/Icon/SelectArrow';
import PaginationCom from '../../../components/PaginationCom';
import { APP_USERS } from '../../../services';
import { tableSortIconToggle } from '../../../utility/tableSorting';
import {
  DeleteOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import InfoCircleIcon from '../../../components/Icon/InfoCircleIcon';
import PropTypes from 'prop-types'; import {
  ExportExcel,
  ExportPdf,
  handleFileName,
} from '../../../utility/Export/Export';
import { CSVLink } from 'react-csv';
import FilterToogleBtn from '../../../components/FilterToogleBtn';
import { COMMON_ALERTS, COMMON_STRING, FILTER_STRING, REPORTS_STRINGS, TABLE_STRING } from '../../../services/strings';
import DeleteModel from '../../../components/DeleteModel';
import { DROPDOWN } from '../../../services/Dropdown/dropdowns';

const UsersTabReports = ({ permissions }) => {
  const [form] = Form.useForm();
  const [CountriesMenu, setCountriesMenu] = useState([]);
  const [StateMenu, setStateMenu] = useState([]);
  const [AgeCategoriesMenu, setAgeCategoriesMenu] = useState([]);
  const [InactiveDaysMenu, setInactiveDaysMenu] = useState([]);
  const [PendingValidationDaysMenu, setPendingValidationDaysMenu] = useState([]);
  const [PendingVarificationDaysMenu, setPendingVarificationDaysMenu] = useState([]);
  const [appUsers, setAppUsers] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [blockUserModalVisible, setBlockUserModalVisible] = useState(false);
  const [blockInputValue, setBlockInputValue] = useState('');
  const [blockModalData, setBlockModalData] = useState({ title: 'Block', id: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [sort, setSort] = useState(null);
  const [tableDataLength, setTableDataLength] = useState(0);
  const [csv, setCsv] = useState([]);
  const [FilterStartDate, setFilterStartDate] = useState('');
  const [FilterEndDate, setFilterEndDate] = useState('');
  const [FilterCountry, setFilterCountry] = useState('');
  const [FilterState, setFilterState] = useState('');
  const [FilterPlatform, setFilterPlatform] = useState('');
  const [FilterAgeGroup, setFilterAgeGroup] = useState('');
  const [isFilterShow, setIsFilterShow] = useState(false);
  const csvLink = useRef();
  const navigate = useNavigate();

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const fetchAppUsers = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    countryId = null,
    stateId = null,
    cityId = null,
    suburbs = null,
    ageGroupId = null,
    platform = null,
    startDate = null,
    endDate = null
  ) => {
    setTableLoading(true);
    try {
      const res = await APP_USERS.GET(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        countryId,
        stateId,
        cityId,
        suburbs,
        ageGroupId,
        platform,
        startDate,
        endDate
      );
      if (res.status === 1 || res.status === '1') {
        setAppUsers(res);
        setTableDataLength(res?.data && res?.data.length);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setTableLoading(false);
    }
  };

  const deleteAppUser = async () => {
    try {
      const res = await APP_USERS.DELETE(deleteId);

      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        toast.success(res?.message);
        //If this page only have one element so we need to reduce the page size
        let page = currentPage;
        if (appUsers?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          fetchAppUsers(page, pageSize, search, sort);
        }
      }
    } catch (err) {
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    }
  };

  const blockUnblockUser = async () => {
    const payload = {
      userBlock: !blockModalData.userBlock,
      blockReason: blockInputValue,
    };
    try {
      const res = await APP_USERS.BLOCK(blockModalData?.id, payload);
      if (res.status === 1) {
        toast.success(`user ${blockModalData?.title} successfully`);
        fetchAppUsers(currentPage, pageSize, search, sort);
      }
    } catch (err) {
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setBlockInputValue(null);
      setBlockUserModalVisible(false);
    }
  };

  const fetchCountries = async () => {
    const countriesData = await DROPDOWN.GET_COUNTRIES();
    if (countriesData.status && Array.isArray(countriesData.data)) {
      setCountriesMenu(countriesData.data);
    }
  };

  const fetchStates = async () => {
    const statesData = await DROPDOWN.GET_STATES();
    if (statesData.status && Array.isArray(statesData.data)) {
      setStateMenu(statesData.data);
    }
  };

  const fetchAgeGroups = async () => {
    const ageGroupData = await DROPDOWN.AGE_GROUPS();
    if (ageGroupData.status && Array.isArray(ageGroupData.data)) {
      setAgeCategoriesMenu(ageGroupData.data);
    }
  };

  const headers = [
    { label: 'id', key: 'id' },
    { label: 'name', key: 'name' },
    { label: 'phone', key: 'phone' },
    { label: 'email', key: 'email' }
  ];

  const handleDownloadCsv = async () => {
    const res = await APP_USERS.EXPORT();
    if (res.status === 1 || res.status === '1') {
      const temp = res?.data.map((item) => {
        return {
          id: item.id,
          name: item.firstname ? (item.firstname + item.lastname) : '',
          phone: item.phoneNumber,
          email: item?.email
        };
      });
      setCsv(temp);
    }
  };

  useEffect(() => {
    if (csv?.length)
      csvLink.current.link.click();
  }, [csv]);

  const generateExcel = async () => {
    const res = await APP_USERS.EXPORT();
    if (res.status === 1 || res.status === '1') {
      const temp = res?.data?.map((item) => {
        return {
          id: item.id,
          name: item.firstname ? (item.firstname + item.lastname) : '',
          phone: item.phoneNumber,
          email: item?.email
        };
      });
      ExportExcel(temp, 'userReport');
    }
  };

  const generatePDF = async () => {
    let res = await APP_USERS.EXPORT();
    const pdftableColumn = [
      'User Id',
      'Full Name',
      'Phone',
      'Email'
    ];
    const pdftableRows = [];
    res?.data.forEach((item) => {
      const usersReportsListData = [
        item.id,
        item.firstname ? (item.firstname + item.lastname) : '',
        item.phoneNumber,
        item?.email
      ];
      pdftableRows.push(usersReportsListData);
    });
    ExportPdf(pdftableColumn, pdftableRows, 'usersReport');
  };

  const PlatformsMenu = [
    { name: 'Android', value: 'android' },
    { name: 'iOS', value: 'ios' },
    { name: 'Web', value: 'web' }
  ];

  const clearAll = () => {
    form.resetFields();
    setFilterAgeGroup('');
    setFilterCountry('');
    setFilterEndDate('');
    setFilterPlatform('');
    setFilterStartDate('');
    setFilterState('');
  }

  useEffect(() => {
    fetchAppUsers(currentPage, pageSize, search, sort, FilterCountry, FilterState, null, null, FilterAgeGroup, FilterPlatform, FilterStartDate, FilterEndDate);
  }, [currentPage, pageSize, search, sort, FilterAgeGroup, FilterCountry, FilterState, FilterPlatform, FilterStartDate, FilterEndDate]);

  useEffect(() => {
    fetchCountries();
    fetchStates();
    fetchAgeGroups();
  }, []);

  const columns = [
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.USERS.TABLE.NAME}</span>, 'firstname', column),
      dataIndex: 'firstname',
      key: 'name',
      align: 'left',
      width: 250,
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? value.charAt(0).toUpperCase() + value.slice(1) : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.USERS.TABLE.EMAIL}</span>, 'email', column),
      key: 'email',
      dataIndex: 'email',
      align: 'left',
      width: 250,
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? value : '-'),
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.USERS.TABLE.CONTACT}</span>, 'phoneNumber', column),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      width: 250,
      render: (value) => (value ? value : '-'),
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.USERS.TABLE.INACTIVE_DAYS}</span>, 'inactive_days', column),
      dataIndex: 'inactive_days',
      key: 'inactive_days',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 250,
      render: (value) => (value !== null ? value + ' Days' : '0 Days'),
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.USERS.TABLE.PENDING_VALIDATE}</span>, 'verification_days', column),
      dataIndex: 'verification_days',
      key: 'verification_days',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value !== null ? value + ' Days' : '0 Days')
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      key: 'Actions',
      align: 'center',
      dataIndex: 'Actions',
      width: 250,
      render: (item, record) => {
        return (
          <div className='actionCol'>
            <Tooltip title='View User'>
              <InfoCircleIcon
                onClick={() => goUserDetails(record.id)}
                className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
            </Tooltip>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip
                title={record?.userBlock ? 'Unblock User' : ' Block User'}
              >
                {record?.userBlock ? (
                  <UnlockOutlined
                    className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                    onClick={() => {
                      setBlockModalData({
                        title: 'Unblock',
                        id: record?.id,
                        userBlock: record?.userBlock || false,
                        blockReason: record?.blockReason,
                      });
                      setBlockUserModalVisible(true);
                    }}
                  />
                ) : (
                  <LockOutlined
                    className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                    onClick={() => {
                      setBlockModalData({
                        title: 'Block',
                        id: record?.id,
                        userBlock: record?.userBlock || false,
                        blockReason: record?.blockReason,
                      });
                      setBlockUserModalVisible(true);
                    }}
                  />
                )}
              </Tooltip>
            )}
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete User'>
                <DeleteOutlined
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const goUserDetails = (userId) => {
    navigate('/users/details', {
      state: {
        userId: userId,
        permissions: permissions
      }
    });
  };

  if (permissions?.isUpdate === false && permissions?.isDelete === false) {
    delete columns[7];
  }

  return (
    <div className='userTabReportLayout'>
      <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} withmenu={true} />
      <Row className={isFilterShow ? 'filterBoxLayout mb-3' : 'filterBoxLayout d-none'}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form form={form}>
                <Form.Item
                  label={<span className="inputTitle">{FILTER_STRING.START_DATE}</span>}
                  name="startDate"
                  colon={false}>
                  <DatePicker
                    className='w-100 inputField'
                    placeholder=""
                    suffixIcon={<CalenderDarkIcon />}
                    allowClear
                    onChange={value => setFilterStartDate(value)}
                    disabledDate={d => !d || d.isAfter(FilterEndDate)}
                    picker="date" />
                </Form.Item>
                <Form.Item
                  label={<span className="inputTitle">{FILTER_STRING.END_DATE}</span>}
                  name="endDate"
                  colon={false}>
                  <DatePicker
                    className='w-100 inputField'
                    placeholder=""
                    suffixIcon={<CalenderDarkIcon />}
                    allowClear
                    onChange={value => setFilterEndDate(value)}
                    disabledDate={d => !d || d.isSameOrBefore(FilterStartDate)}
                    picker="date" />
                </Form.Item>
                <Form.Item
                  label={<span className="inputTitle">{FILTER_STRING.SEARCH}</span>}
                  name="searchInp"
                  className="searchInpUserTab"
                  colon={false}>
                  <Input placeholder={COMMON_STRING.SEARCH_STR + 'User'}
                    className='inputField searchField'
                    value={tempSearch}
                    onChange={(e) => setTempSearch(e.target.value)}
                    onKeyPress={(e) => {
                      if (e?.key === 'Enter' && e.target.value) {
                        setSearch(e.target.value);
                      }
                      else if (!e.target.value) {
                        setSearch(null);
                      }
                    }} />
                </Form.Item>
              </Form>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className={'dropdownBox'}>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.COUNTRY} value={FilterCountry} onChange={value => setFilterCountry(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value=''>Country</Select.Option>{CountriesMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.STATE} value={FilterState} onChange={value => setFilterState(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value=''>States</Select.Option>{StateMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={REPORTS_STRINGS.USERS.FILTER.PLATFORM} value={FilterPlatform} onChange={value => setFilterPlatform(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value=''>Platform</Select.Option>{PlatformsMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={REPORTS_STRINGS.USERS.FILTER.AGE_CATEGORY} value={FilterAgeGroup} onChange={value => setFilterAgeGroup(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value=''>Age Groups</Select.Option>{AgeCategoriesMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={REPORTS_STRINGS.USERS.FILTER.INACTIVE_DAYS} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value=''>Inactive Days</Select.Option>{InactiveDaysMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={REPORTS_STRINGS.USERS.FILTER.PENDING_VALIDATION} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value=''>Pending Property Validation Days</Select.Option>{PendingValidationDaysMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={REPORTS_STRINGS.USERS.FILTER.PENDING_ACTIVATION} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value=''>Pending Activation Email/SMS Days</Select.Option>{PendingVarificationDaysMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
              </div>
              <div className="exportOuterBoxFlex">
                <div className="exportBox">
                  <div className="exportTitle" >Export</div>
                  <div><div className="exportBtn" onClick={handleDownloadCsv}>{REPORTS_STRINGS.BUTTONS.EXPORT_CSV}</div>
                    <CSVLink
                      data={csv}
                      headers={headers}
                      filename={handleFileName('userspropertiesReport', 'csv')}
                      ref={csvLink}
                      className='text-black font-weight-600'
                    ></CSVLink>
                  </div>
                  <div className="exportBtn" onClick={generateExcel}>{REPORTS_STRINGS.BUTTONS.EXPORT_EXCEL}</div>
                  <div className="exportBtn" onClick={generatePDF}>{REPORTS_STRINGS.BUTTONS.EXPORT_PDF}</div>
                </div>
                <div className="filterSelect clearAll" onClick={() => clearAll()}>Clear all</div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            columns={columns}
            dataSource={appUsers?.data}
            pagination={false}
            rowKey='id'
            loading={tableLoading}
            onChange={onChange}
            scroll={{ x: 400 }}
          />
          {appUsers?.total > 10 && (
            <div className='text-end bg-white mt-7'>
              <PaginationCom
                onChange={onChangePage}
                showSizeChanger={true}
                current={currentPage}
                showLessItems={true}
                total={appUsers?.total}
              />
            </div>
          )}
        </Col>
      </Row>
      <Modal
        centered='true'
        okText={blockModalData?.title}
        cancelText='cancel'
        style={{ textAlign: 'center' }}
        title={blockModalData?.title}
        open={blockUserModalVisible}
        visible={blockUserModalVisible}
        onOk={blockUnblockUser}
        okButtonProps={{
          style: {
            backgroundColor: '#324666'
          }
        }}
        onCancel={() => setBlockUserModalVisible(false)}>
        <h3>Why want to {blockModalData?.title}</h3>
        {blockModalData?.blockReason && (
          <p>
            {blockModalData?.userBlock ? 'Block' : 'Unblock'} Reason :{' '}
            {blockModalData?.blockReason}
          </p>
        )}
        <Input
          placeholder={`Why want to ${blockModalData?.title}`}
          className='input-control'
          value={blockInputValue}
          onChange={(e) => setBlockInputValue(e?.target?.value)}
        ></Input>
      </Modal>
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteAppUser}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </div>
  );
};

UsersTabReports.propTypes = {
  permissions: PropTypes.any
};

export default UsersTabReports;
