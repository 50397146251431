import { methods, serviceMaker } from '../service';

const GET_PATCH_EXPENSESTYPE = (id) => serviceMaker(`expenses-type/${id}`, methods.GET);

const GET_EXPENSES_TYPES = (
  limit,
  skip,
  search = null,
  sort = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/expenses-type`, methods.GET, query);
  }

  return serviceMaker(`expenses-type`, methods.GET, query);
};

const DELETE_EXPENSESTYPE = (id) => serviceMaker(`expenses-type/${id}`, methods.DELETE);

const CREATE_EXPENSESTYPE = (payload) =>
  serviceMaker(`expenses-type`, methods.POST, null, payload);

const PATCH_EXPENSESTYPE = (id, payload) =>
  serviceMaker(`expenses-type/${id}`, methods.PATCH, null, payload);

const REPORT_EXPENSES = (search = null, propertyId = null, month = null, year = null, exp_type = null, startDate = null, endDate = null) => {
  const query = { params: { $limit: -1, $skip: 0 } };
  if (propertyId) {
    query['params']['propertyId'] = propertyId;
  }
  if (search) {
    query['params']['search'] = search;
  }
  if (month) {
    query['params']['month'] = month;
  }
  if (year) {
    query['params']['year'] = year;
  }
  if (exp_type) {
    query['params']['exp_type'] = exp_type;
  }
  if (startDate) {
    query['params']['startDate'] = new Date(startDate);
  }
  if (endDate) {
    query['params']['endDate'] = new Date(endDate);
  }
  return serviceMaker(`reports/expenses-type`, methods.GET, query);
}

const EXPENSES_TYPES = {
  FIND: GET_PATCH_EXPENSESTYPE,
  GET: GET_EXPENSES_TYPES,
  CREATE: CREATE_EXPENSESTYPE,
  PATCH: PATCH_EXPENSESTYPE,
  DELETE: DELETE_EXPENSESTYPE,
  REPORT: REPORT_EXPENSES
};

export { EXPENSES_TYPES };
