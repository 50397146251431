import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { AGENTS_STRINGS, COMMON_ALERTS, COMMON_STRING } from '../../services/strings';
import BackIcon from '../../components/Icon/BackIcon';
import FormItem from 'antd/es/form/FormItem';
import { useForm } from 'antd/lib/form/Form';
import SelectArrow from '../../components/Icon/SelectArrow';
import TextArea from 'antd/lib/input/TextArea';
import { isNumeric } from '../../services/helpers';
import CalenderDarkIcon from '../../components/Icon/CalenderDarkIcon';
import moment from 'moment';
import { toast } from 'react-toastify';
import { AGENTS } from '../../services/Agents/Agents';
import { Option } from 'antd/lib/mentions';
import countryCodes from '../../services/countryCodes.json';
import PropTypes from 'prop-types';

AddEditAgentPage.propTypes = {

};

function AddEditAgentPage(props) {
    const navigate = useNavigate();
    const [form] = useForm();
    const location = useLocation().state;
    const agentId = location?.agentId ?? null;
    const isView = location?.isView ?? null;

    const onSubmit = async (payload) => {
        delete payload.teamConfPassword;
        payload.agents_businesses.map(e => {
            e.businessContractEnd = moment(e.businessContractEnd).format('YYYY-MM-DD'),
                e.businessContractStart = moment(e.businessContractStart).format('YYYY-MM-DD'),
                e.businessContactCode = e.businessContactCode.replace('+', '')
        })
        const res = agentId ? await AGENTS.PATCH(agentId, payload) : await AGENTS.CREATE(payload);
        if (res?.status) {
            toast.success(agentId ? COMMON_ALERTS.UPDATED_SUCCESS(AGENTS_STRINGS.LABEL) : COMMON_ALERTS.ADDED_SUCCESS(AGENTS_STRINGS.LABEL));
            navigate(-1);
        }
        else {
            toast.error(res?.response?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
        }
    }

    const fetchAgent = async () => {
        const res = await AGENTS.FIND(agentId);
        if (res.status) {
            const data = res.data;
            data.agents_businesses.map(e => {
                e.businessContractEnd = moment(e.businessContractEnd),
                    e.businessContractStart = moment(e.businessContractStart),
                    e.businessContactCode = '+' + e.businessContactCode,
                    e.teamId = e.agent_teams[0].id,
                    e.teamName = e.agent_teams[0].teamName,
                    e.teamEmail = e.agent_teams[0].teamEmail
            })
            form.setFieldsValue(res.data);
        }
        else {
            toast.error(res?.response?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
        }
    }

    const countryCodesList = (
        <Select disabled={isView} defaultValue={countryCodes[0].dial_code} suffixIcon={<SelectArrow />}>
            {countryCodes.map((e, key) => <Option key={key} value={e.dial_code}>{e.dial_code}</Option>)}
        </Select>
    );

    useEffect(() => {
        // Add one default group when the component mounts
        if (agentId) fetchAgent();
        else {
            form.setFieldsValue({
                agents_businesses: [
                    {
                        businessName: '',
                        businessAddress: '',
                        businessContact: '',
                        businessEmail: '',
                        businessContractStart: '',
                        businessContractEnd: '',
                        businessNotes: '',
                        teamName: '',
                        teamPassword: '',
                        teamEmail: '',
                        teamConfPassword: '',
                        teamLicence: ''
                    }
                ],
            });
        }
    }, [form]);

    return (
        <div>
            <div className="commonHeader">
                <div className="commonHeaderLeft">
                    <button className='iconBtn' onClick={() => navigate(-1)}><BackIcon /></button>
                </div>
            </div>
            <div className="fWidthShowBox addNwContactLayout addNwDepScheduleContainer">
                <Row>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <h2>{agentId ? isView ? AGENTS_STRINGS.ADD_EDIT.VIEW_LABEL : AGENTS_STRINGS.ADD_EDIT.EDIT_LABEL : AGENTS_STRINGS.ADD_EDIT.ADD_LABEL}</h2>
                    </Col>
                </Row>
                <Form name="complex-form" className="addformLayout" form={form} onFinish={onSubmit} colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item name='businessType' label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.BUSINESS_TYPE}</span>} wrapperCol={{ span: 8 }} rules={[{ required: true, message: AGENTS_STRINGS.ADD_EDIT.BUSINESS_TYPE }]}>
                                <Select disabled={isView} className="textInput" suffixIcon={<SelectArrow />}>{AGENTS_STRINGS.ADD_EDIT.BUSINESS_TYPES.map((type, key) => <Select.Option key={key} value={type}>{type}</Select.Option>)}</Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} className='mt-2 mb-4'>
                            <h2>{AGENTS_STRINGS.ADD_EDIT.ORG_DETAIL}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FormItem
                                        name='orgName'
                                        required
                                        rules={[
                                            { required: true, message: COMMON_ALERTS.REQUIRED_INPUT(AGENTS_STRINGS.ADD_EDIT.ORG_NAME) },
                                            {
                                                validator: async (_, val) => {
                                                    const res = await AGENTS.VERIFY(val, agentId ?? undefined);
                                                    return res.data ? Promise.reject('This organisation name is already taken') : Promise.resolve();
                                                }
                                            }
                                        ]}
                                        label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.ORG_NAME}</span>}>
                                        <Input readOnly={isView} className="textInput" maxLength={100} />
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FormItem
                                        name='orgABM'
                                        required
                                        rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(AGENTS_STRINGS.ADD_EDIT.ORG_ABN) }]}
                                        label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.ORG_ABN}</span>}>
                                        <Input readOnly={isView} className="textInput" maxLength={100} />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FormItem
                                        name='orgAddress'
                                        required
                                        rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(AGENTS_STRINGS.ADD_EDIT.ORG_ADDRESS) }]}
                                        label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.ORG_ADDRESS}</span>}>
                                        <Input readOnly={isView} className="textInput" maxLength={100} />
                                    </FormItem>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FormItem
                                        name='orgContact'
                                        required
                                        rules={[
                                            { required: true, message: COMMON_ALERTS.REQUIRED_INPUT(AGENTS_STRINGS.ADD_EDIT.ORG_CONTACT) },
                                            {
                                                validator: (_, value) => {
                                                    if (value && ((value.toString().length === 0) || (!isNumeric(value)))) {
                                                        return Promise.reject(COMMON_ALERTS.INVALID_ERROR(AGENTS_STRINGS.ADD_EDIT.ORG_CONTACT))
                                                    }
                                                    return Promise.resolve();
                                                }
                                            }
                                        ]}
                                        label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.ORG_CONTACT}</span>}>
                                        <Input readOnly={isView} addonBefore={<FormItem name='orgContactCode' initialValue={'91'} readOnly={isView} noStyle>{countryCodesList}</FormItem>} className={isView ? "textInput readOnly" : "textInput"} maxLength={100} />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Form.List name="agents_businesses">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Row key={field.key} className='mb-2'>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            {index > 0 && !isView && <span className="removeBlockBtn" onClick={() => remove(field.name)}>- Remove</span>}
                                            <Row>
                                                <Col xs={24} sm={24} md={8} lg={8} xl={8} className='mt-2 mb-4'>
                                                    <h2>{AGENTS_STRINGS.ADD_EDIT.BUSN_UNT_DETAIL}</h2>
                                                </Col>
                                            </Row>
                                            <Form.Item name={[field.name, 'id']} label="id" hidden={true}><Input type="text" /></Form.Item>
                                            <Form.Item name={[field.name, 'teamId']} label="teamId" hidden={true}><Input type="text" /></Form.Item>
                                            <Row>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <Form.Item
                                                        name={[field.name, 'businessName']}
                                                        fieldKey={[field.fieldKey, 'businessName']}
                                                        required
                                                        rules={[
                                                            { required: true, message: COMMON_ALERTS.REQUIRED_INPUT(AGENTS_STRINGS.ADD_EDIT.BUSN_NAME) },
                                                            {
                                                                validator: (_, val) => {
                                                                    const res = form.getFieldValue('agents_businesses')?.filter((e, i) => (e.businessName === val) && (index !== i));
                                                                    if (res?.length) {
                                                                        return Promise.reject('Business name is already taken!')
                                                                    }
                                                                    else return Promise.resolve();
                                                                }
                                                            }
                                                        ]}
                                                        label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.BUSN_NAME}</span>}>
                                                        <Input readOnly={isView} className="textInput" maxLength={100} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <FormItem
                                                        name={[field.name, 'businessAddress']}
                                                        required
                                                        rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(AGENTS_STRINGS.ADD_EDIT.BUSN_ADDRESS) }]}
                                                        label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.BUSN_ADDRESS}</span>}>
                                                        <Input readOnly={isView} className="textInput" maxLength={100} />
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <FormItem
                                                        name={[field.name, 'businessContact']}
                                                        required
                                                        rules={[
                                                            { required: true, message: COMMON_ALERTS.REQUIRED_INPUT(AGENTS_STRINGS.ADD_EDIT.BUSN_CONTACT) },
                                                            {
                                                                validator: (_, value) => {
                                                                    if (value && ((value.toString().length === 0) || (!isNumeric(value)))) {
                                                                        return Promise.reject(COMMON_ALERTS.INVALID_ERROR(AGENTS_STRINGS.ADD_EDIT.BUSN_CONTACT))
                                                                    }
                                                                    return Promise.resolve();
                                                                }
                                                            }
                                                        ]}
                                                        label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.BUSN_CONTACT}</span>}>
                                                        <Input readOnly={isView} className={isView ? "textInput readOnly" : "textInput"} maxLength={100} addonBefore={<FormItem name={[field.name, 'businessContactCode']} noStyle initialValue={'91'}>{countryCodesList}</FormItem>} />
                                                    </FormItem>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <FormItem
                                                        name={[field.name, 'businessEmail']}
                                                        required
                                                        rules={[
                                                            { required: true, message: COMMON_ALERTS.REQUIRED_INPUT(AGENTS_STRINGS.ADD_EDIT.BUSN_EMAIL) },
                                                            { type: 'email', message: COMMON_ALERTS.VALID_EMAIL },
                                                        ]}
                                                        label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.BUSN_EMAIL}</span>}>
                                                        <Input readOnly={isView} className="textInput" maxLength={100} />
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <FormItem
                                                        name={[field.name, 'businessContractStart']}
                                                        required
                                                        rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(AGENTS_STRINGS.ADD_EDIT.BUSN_CONTRACT_START) }]}
                                                        label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.BUSN_CONTRACT_START}</span>}>
                                                        <DatePicker disabled={isView} className="textInput" suffixIcon={<CalenderDarkIcon />} disabledDate={d => !d || d.isAfter(form.getFieldValue('agents_businesses')[index]?.businessContractEnd || null)} />
                                                    </FormItem>
                                                    <FormItem
                                                        name={[field.name, 'businessContractEnd']}
                                                        required
                                                        rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(AGENTS_STRINGS.ADD_EDIT.BUSN_CONTRACT_END) }]}
                                                        label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.BUSN_CONTRACT_END}</span>}>
                                                        <DatePicker disabled={isView} className="textInput" suffixIcon={<CalenderDarkIcon />} disabledDate={d => !d || d.isSameOrBefore(form.getFieldValue('agents_businesses')[index]?.businessContractStart || null)} />
                                                    </FormItem>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <FormItem
                                                        name={[field.name, 'businessNotes']}
                                                        required
                                                        rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(AGENTS_STRINGS.ADD_EDIT.BUSN_NOTES) }]}
                                                        label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.BUSN_NOTES}</span>}>
                                                        <TextArea readOnly={isView} className="textInput textarea" rows={5} maxLength={100} />
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={24} sm={24} md={8} lg={8} xl={8} className='mt-2 mb-4'>
                                                    <h2>{AGENTS_STRINGS.ADD_EDIT.TEAM_DETAILS}</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Row>
                                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                            <FormItem
                                                                name={[field.name, 'teamName']}
                                                                required
                                                                rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(AGENTS_STRINGS.ADD_EDIT.TEAM_NAME) }]}
                                                                label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.TEAM_NAME}</span>}>
                                                                <Input readOnly={isView} className="textInput" maxLength={100} />
                                                            </FormItem>
                                                        </Col>
                                                        {isView ?? <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                            <FormItem
                                                                name={[field.name, 'teamPassword']}
                                                                required
                                                                rules={[{ required: !form.getFieldValue('agents_businesses')[index]?.teamId, message: COMMON_ALERTS.REQUIRED_INPUT(AGENTS_STRINGS.ADD_EDIT.TEAM_DEFAULT_PASS) }]}
                                                                label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.TEAM_DEFAULT_PASS}</span>}>
                                                                <Input readOnly={isView} className="textInput" maxLength={100} />
                                                            </FormItem>
                                                        </Col>}
                                                    </Row>
                                                    <Row>
                                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                            <FormItem
                                                                name={[field.name, 'teamEmail']}
                                                                required
                                                                rules={[
                                                                    { required: true, message: COMMON_ALERTS.REQUIRED_INPUT(AGENTS_STRINGS.ADD_EDIT.TEAM_EMAIL) },
                                                                    { type: 'email', message: COMMON_ALERTS.VALID_EMAIL },
                                                                    {
                                                                        validator: async (_, val) => {
                                                                            const res = form.getFieldValue('agents_businesses')?.filter((e, i) => (e.teamEmail === val) && (index !== i));
                                                                            if (res?.length) {
                                                                                return Promise.reject('This team email is already taken!')
                                                                            }
                                                                            else {
                                                                                const res = await AGENTS.VALIDATE_TEAM_EMAIL(val, form.getFieldValue('agents_businesses')[index]?.teamId ?? undefined);
                                                                                return res.data ? Promise.reject('This team email is already taken!') : Promise.resolve();
                                                                            }
                                                                        }
                                                                    }
                                                                ]}
                                                                label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.TEAM_EMAIL}</span>}>
                                                                <Input readOnly={isView} className="textInput" maxLength={100} />
                                                            </FormItem>
                                                        </Col>
                                                        {isView ?? <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                            <FormItem
                                                                name={[field.name, 'teamConfPassword']}
                                                                required
                                                                rules={[
                                                                    { required: !form.getFieldValue('agents_businesses')[index]?.teamId, message: COMMON_ALERTS.REQUIRED_INPUT(AGENTS_STRINGS.ADD_EDIT.TEAM_CONFIRM_PASS) },
                                                                    {
                                                                        validator: (_, value) => {
                                                                            const pass = form.getFieldValue('teamPassword');
                                                                            if (!agentId && pass && (pass !== value)) {
                                                                                return Promise.reject(COMMON_ALERTS.CONFIRM_PASS_NO_MATCH);
                                                                            }
                                                                            return Promise.resolve();
                                                                        }
                                                                    }
                                                                ]}
                                                                label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.TEAM_CONFIRM_PASS}</span>}>
                                                                <Input readOnly={isView} type='password' className="textInput" maxLength={100} />
                                                            </FormItem>
                                                        </Col>}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={24} sm={24} md={14} lg={18} xl={14}>
                                                    <FormItem
                                                        name={[field.name, 'teamLicence']}
                                                        required
                                                        labelCol={{ span: 12, lg: 12, md: 18, sm: 24 }}
                                                        wrapperCol={{ span: 6, lg: 8 }}
                                                        rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('licence') }]}
                                                        label={<span className='inputTitle'>{AGENTS_STRINGS.ADD_EDIT.TEAM_LICENSE}</span>}>
                                                        <Select disabled={isView} className="textInput" style={{ width: '100%' }} suffixIcon={<SelectArrow />}><Select.Option></Select.Option>{[...Array(20).keys()].map((n, key) => <Select.Option key={key} value={n + 1}>{n + 1}</Select.Option>)}</Select>
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                ))}
                                {isView ?? <span className="removeBlockBtn" onClick={() => add()}>{AGENTS_STRINGS.ADD_EDIT.ADD_MORE}</span>}
                            </>
                        )}
                    </Form.List>
                    <Row>
                        {isView ?? <Col xs={24} sm={24} md={{ span: 16, offset: 8 }} lg={{ span: 16, offset: 8 }} xl={{ span: 16, offset: 8 }} className="btnBox">
                            <button type="button" className='backBtn' onClick={() => navigate(-1)}>{COMMON_STRING.BACK_BTN}</button>
                            <Button type="submit" htmlType='submit' className='createBtn'>{agentId ? COMMON_STRING.UPDATE_BTN : COMMON_STRING.CREATE_BTN}</Button>
                        </Col>}
                    </Row>
                </Form>
            </div>
        </div>
    );
}

export default AddEditAgentPage;