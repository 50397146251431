 import React from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { COMMON_ALERTS } from '../../services/strings';

const LogOutModal = ({ visible, handleLogOut, setVisible }) => {
  return (
    <>
      <Modal
        centered='true'
        okText='Yes'
        cancelText='No'
        style={{ textAlign: 'center' }}
        title='Logout'
        open={visible}
        visible={visible}        
        onOk={handleLogOut}
        onCancel={() => setVisible(false)}
      >
        <p>{COMMON_ALERTS.ARE_YOU_WANT('logout')}</p>
      </Modal>
    </>
  );
};

LogOutModal.propTypes = {
  visible: PropTypes.any,
  handleLogOut: PropTypes.any,
  setVisible: PropTypes.any
}

export default LogOutModal;
