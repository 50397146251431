import { Button, Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { COMMON_ALERTS, COMMON_STRING, LENDERS_STRINGS } from '../../services/strings';
import { LENDERS } from '../../services/Lenders/Lenders';
import { toast } from 'react-toastify';

const AddEditLenders = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const lenderId = useLocation().state?.lenderId;

    useEffect(() => {
        if (lenderId !== null) {
            fetchPropertyType();
        }
    }, []);

    const fetchPropertyType = async () => {
        if (lenderId) {
            const res = await LENDERS.GET(lenderId);
            if (res.status) {
                form.setFieldValue('name', res.data.name);
            }
        }
    }

    const onFinish = async (form) => {
        const payload = {
            name: form.name
        }
        let res = null;
        try {
            res = lenderId? await LENDERS.PATCH(lenderId, payload): await LENDERS.CREATE(payload);
            if (res.status) {
                toast.success(!lenderId ? COMMON_ALERTS.ADDED_SUCCESS('Lender') : COMMON_ALERTS.UPDATED_SUCCESS('Lender'));
                navigate(-1);
            }
        }
        catch (e) {
            toast.error(e?.response?.data?.message?? COMMON_ALERTS.SOMETHING_WENT_WRONG);
        }
    }

    return (
        <div>
            <div className="fWidthShowBox addNwDepScheduleContainer">
                <Row>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <h2>{!lenderId ? LENDERS_STRINGS.ADDEDIT.ADD_LABEL : LENDERS_STRINGS.ADDEDIT.EDIT_LABEL}</h2>
                    </Col>
                </Row>
                <Form name="complex-form" className="addformLayout" form={form} onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} colon={false}>
                    <Form.Item
                        label={<span className='inputTitle'>{LENDERS_STRINGS.ADDEDIT.NAME}</span>}
                        name="name"
                        rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('lender') }]}
                    >
                        <Input className='textInput' />
                    </Form.Item>
                    <Row>
                        <Col xs={24} sm={24} md={{ span: 16, offset: 8 }} lg={{ span: 16, offset: 8 }} xl={{ span: 16, offset: 8 }} className="btnBox">
                            <Button className="cancelBtn" onClick={() => navigate(-1)}>{COMMON_STRING.BACK_BTN}</Button>
                            <Button type="submit" htmlType='submit' className="addBtn">{lenderId ? COMMON_STRING.UPDATE_BTN : COMMON_STRING.CREATE_BTN}</Button>
                        </Col>
                    </Row>
                </Form>
            </div>

        </div>
    );
}

export default AddEditLenders;
