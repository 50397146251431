import React from 'react';

function ServCategoryIcon(props) {
    return (
        <span className='anticon anticon-dashboard ant-menu-item-icon'>
            <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3327 17.5C12.8744 17.5 12.4819 17.3367 12.1552 17.01C11.8285 16.6833 11.6655 16.2911 11.666 15.8333V12.5C11.666 12.0417 11.8294 11.6492 12.156 11.3225C12.4827 10.9958 12.8749 10.8328 13.3327 10.8333H16.666C17.1244 10.8333 17.5169 10.9967 17.8435 11.3233C18.1702 11.65 18.3332 12.0422 18.3327 12.5V15.8333C18.3327 16.2917 18.1694 16.6842 17.8427 17.0108C17.516 17.3375 17.1238 17.5006 16.666 17.5H13.3327ZM2.49935 15C2.26324 15 2.06519 14.92 1.90519 14.76C1.74519 14.6 1.66546 14.4022 1.66602 14.1667C1.66602 13.9306 1.74602 13.7325 1.90602 13.5725C2.06602 13.4125 2.2638 13.3328 2.49935 13.3333H8.33269C8.5688 13.3333 8.76685 13.4133 8.92685 13.5733C9.08685 13.7333 9.16657 13.9311 9.16602 14.1667C9.16602 14.4028 9.08602 14.6008 8.92602 14.7608C8.76602 14.9208 8.56824 15.0006 8.33269 15H2.49935ZM13.3327 9.16667C12.8744 9.16667 12.4819 9.00333 12.1552 8.67667C11.8285 8.35 11.6655 7.95778 11.666 7.5V4.16667C11.666 3.70833 11.8294 3.31583 12.156 2.98917C12.4827 2.6625 12.8749 2.49945 13.3327 2.5H16.666C17.1244 2.5 17.5169 2.66333 17.8435 2.99C18.1702 3.31667 18.3332 3.70889 18.3327 4.16667V7.5C18.3327 7.95833 18.1694 8.35083 17.8427 8.6775C17.516 9.00417 17.1238 9.16722 16.666 9.16667H13.3327ZM2.49935 6.66667C2.26324 6.66667 2.06519 6.58667 1.90519 6.42667C1.74519 6.26667 1.66546 6.06889 1.66602 5.83333C1.66602 5.59722 1.74602 5.39917 1.90602 5.23917C2.06602 5.07917 2.2638 4.99945 2.49935 5H8.33269C8.5688 5 8.76685 5.08 8.92685 5.24C9.08685 5.4 9.16657 5.59778 9.16602 5.83333C9.16602 6.06945 9.08602 6.2675 8.92602 6.4275C8.76602 6.5875 8.56824 6.66722 8.33269 6.66667H2.49935Z" fill="#3E567E" />
            </svg>
        </span>
    );
}

export default ServCategoryIcon;