import { Checkbox, Image } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {  PROPERTIES_CARD_STRINGS } from '../../../services/strings';
import FallbackImage from '../../../components/Icon/FallbackImage';
import { BASE_URL } from '../../../services/constant';

function PropertyCard({ property }) {
    return <div className='property_card'>
        <div className="imageFixDIv">
            <Image className="propertyCardImage" fallback={FallbackImage} src={BASE_URL + property?.propertyImage?.image}></Image>
        </div>
        <div className="properties_card_boxes">
            <table className="property_details_table" cellPadding={0} cellSpacing={0} width="100%" border="0">
                <tbody>
                    <tr>
                        <td><span className="label_title">{PROPERTIES_CARD_STRINGS.PROPERTY_NAME}</span></td>
                        <td><span className="label_value"> - {property?.name}</span></td>
                    </tr>
                    <tr>
                        <td><span className="label_title">{PROPERTIES_CARD_STRINGS.PROPERTY_TYPE} </span></td>
                        <td><span className="label_value"> - {property?.property_type?.name}</span></td>
                    </tr>
                    <tr>
                        <td><span className="label_title">{PROPERTIES_CARD_STRINGS.VALIDATE}</span></td>
                        <td><span className="label_value"> - <Checkbox checked={property?.isValidate} /></span></td>
                    </tr>
                    <tr>
                        <td><span className="label_title">{PROPERTIES_CARD_STRINGS.PURCHASE_DATE}</span></td>
                        <td><span className="label_value"> - {moment(property?.purchaseDate).format('DD/MM/YYYY')}</span></td>
                    </tr>
                    <tr>
                        <td><span className="label_title">{PROPERTIES_CARD_STRINGS.NO_OF_BED}</span></td>
                        <td><span className="label_value"> - {property?.bedroom}</span></td>
                    </tr>
                    <tr>
                        <td><span className="label_title">{PROPERTIES_CARD_STRINGS.NO_OF_BATH}</span></td>
                        <td><span className="label_value"> - {property?.bathroom}</span></td>
                    </tr>
                    <tr>
                        <td><span className="label_title">{PROPERTIES_CARD_STRINGS.COUNCIL}</span></td>
                        <td><span className="label_value"> - {property?.council?.name}</span></td>
                    </tr>
                    <tr>
                        <td><span className="label_title">{PROPERTIES_CARD_STRINGS.STATUS}</span></td>
                        <td><span className="label_value"> - {property?.propertyStatus.charAt(0).toUpperCase() + property?.propertyStatus.slice(1)}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>;
}

PropertyCard.propTypes = {
    property: PropTypes.object
}

export default PropertyCard;