 import React from 'react';

const SelectArrow = (props) => {
    return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0002 12.7101L8.46019 9.17006C8.27283 8.98381 8.01938 8.87926 7.75519 8.87926C7.49101 8.87926 7.23756 8.98381 7.05019 9.17006C6.95646 9.26302 6.88207 9.37362 6.8313 9.49548C6.78053 9.61734 6.75439 9.74804 6.75439 9.88006C6.75439 10.0121 6.78053 10.1428 6.8313 10.2646C6.88207 10.3865 6.95646 10.4971 7.05019 10.5901L11.2902 14.8301C11.3832 14.9238 11.4938 14.9982 11.6156 15.0489C11.7375 15.0997 11.8682 15.1259 12.0002 15.1259C12.1322 15.1259 12.2629 15.0997 12.3848 15.0489C12.5066 14.9982 12.6172 14.9238 12.7102 14.8301L17.0002 10.5901C17.0929 10.4966 17.1662 10.3858 17.216 10.264C17.2657 10.1421 17.291 10.0117 17.2902 9.88006C17.291 9.74845 17.2657 9.61799 17.216 9.49615C17.1662 9.37431 17.0929 9.2635 17.0002 9.17006C16.8128 8.98381 16.5594 8.87926 16.2952 8.87926C16.031 8.87926 15.7776 8.98381 15.5902 9.17006L12.0002 12.7101Z" fill="#324666"/>
    </svg>
    );
}

export default SelectArrow;
