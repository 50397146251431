import { Col, Empty, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DeleteModel from '../../components/DeleteModel';
import NewAndArticles from '../../components/NewsAndArticles/NewAndArticles';
import PaginationCom from '../../components/PaginationCom';
import { NEWSARTICLES } from '../../services/NewsDetails/newsDetailsService';
import PropTypes from 'prop-types';
import { COMMON_ALERTS } from '../../services/strings';

const ArticlesListPage = ({ permissions }) => {
    const [mode, setMode] = useState('news');
    const [NewsArticles, setNewsArticles] = useState(null);
    const [currentPage, setcurrentPage] = useState(1);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const handleModeChange = (e) => {
        setcurrentPage(1);
        setMode(e.target.value);
    };

    const fetchNewsArticles = async (currpg) => {
        const newsArticles = await NEWSARTICLES.GET(null, ((currpg - 1) * 10), null, null, 'article');
        if (Array.isArray(newsArticles.data)) {
            setNewsArticles(newsArticles);
            setIsLoaded(true);
        }
    }

    useEffect(() => {
        fetchNewsArticles(1);
    }, [mode]);

    const onChangePage = (e) => {
        setcurrentPage(e);
        fetchNewsArticles(e);
    }

    const deleteNewsArticle = async () => {
        const res = await NEWSARTICLES.DELETE(deleteId);
        if (res.status) {
            toast.success(COMMON_ALERTS.DELETED_SUCCESS('article'));
            fetchNewsArticles(currentPage);
            setIsDeleteModalVisible(false);
        }
        else {
            toast.error('Something wrong happened.');
        }
    }

    return (
        <div className='commonWrapperLayout'>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='newsArticleListWrapper'>
                    {
                        isLoaded && NewsArticles?.data?.length?
                        NewsArticles?.data.map((article, key) => <NewAndArticles key={key} data={article} setIsDeleteModalVisible={setIsDeleteModalVisible} setDeleteId={setDeleteId} permissions={permissions} />):
                        <Empty description={"No single "+mode+" found"}/>
                    }
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {NewsArticles?.total > 10 && (
                        <div className='text-end bg-white'>
                            <PaginationCom
                                onChange={onChangePage}
                                showSizeChanger={false}
                                current={currentPage}
                                showLessItems={true}
                                total={NewsArticles?.total}
                            />
                        </div>
                    )}
                </Col>
            </Row>

            <DeleteModel
                isDeleteModalVisible={isDeleteModalVisible}
                deleteModel={deleteNewsArticle}
                setIsDeleteModalVisible={setIsDeleteModalVisible}
            />
        </div>
    );
}

ArticlesListPage.propTypes = {
    permissions: PropTypes.any
};

export default ArticlesListPage;
