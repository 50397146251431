import { Col, DatePicker, Form, Input, Row, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import CalenderDarkIcon from '../../../components/Icon/CalenderDarkIcon';
import SelectArrow from '../../../components/Icon/SelectArrow';
import PaginationCom from '../../../components/PaginationCom';
import { tableSortIconToggle } from '../../../utility/tableSorting';
import PropTypes from 'prop-types';
import FilterToogleBtn from '../../../components/FilterToogleBtn';
import { SCHEDULED_SERVICES } from '../../../services/ScheduledServices/scheduledServices';
import moment from 'moment';
import { SERVICES_TYPES } from '../../../services/Services/ServicesTypes';
import { APP_USERS } from '../../../services';
import { PROPERTIES } from '../../../services/Property/Properties';
import { COMMON_STRING, FILTER_STRING, REPORTS_STRINGS } from '../../../services/strings';

const ServicesTabReports = ({ permissions }) => {
    const [StateMenu, setStateMenu] = useState([]);
    const [services, setServices] = useState(null);
    const [tableLoading, setTableLoading] = useState(null);
    const [sort, setSort] = useState(null);
    const [tableDataLength, setTableDataLength] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState(null);
    const [tempSearch, setTempSearch] = useState(null);
    const [filterValue, setFilterValue] = useState(null);
    const [isFilterShow, setIsFilterShow] = useState(false);
    const [FilterStartDate, setFilterStartDate] = useState(null);
    const [FilterEndDate, setFilterEndDate] = useState(null);
    const [ServicesTypes, setServiceTypes] = useState([]);
    const [Appusers, setAppusers] = useState([]);
    const [Properties, setProperties] = useState([]);

    const fetchAppUsers = async () => {
        const res = await APP_USERS.GET(-1);
        if (res.status && Array.isArray(res.data)) {
            setAppusers([{ id: null, name: 'Select User' }, ...res.data]);
        }
    };

    const fetchProperties = async (userId) => {
        if (userId) {
            const res = await PROPERTIES.GETBYUSERID(-1, 0, null, null, userId);
            if (res.status) {
                setProperties([{ id: '', name: 'Select Properties' }, ...res.data]);
            }
        }
    };

    useEffect(() => {
        fetchAppUsers();
        fetchProperties();
    }, []);

    const onChangePage = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const onChange = (pagination, filters, sorter) => {
        if (sorter?.order === 'ascend') {
            setSort({ [sorter.field]: 1 });
        } else if (sorter?.order === 'descend') {
            setSort({ [sorter.field]: -1 });
        } else {
            setSort({ createdAt: -1 });
        }
    };

    const fetchScheduleServices = async () => {
        setTableLoading(true);
        const res = await SCHEDULED_SERVICES.REPORT(pageSize, (currentPage - 1) * pageSize, search, sort, FilterStartDate, FilterEndDate);
        if (res.status && Array.isArray(res.data)) {
            setServices(res);
        }
        setTableLoading(false);
    };

    const fetchServiceTypes = async () => {
        const res = await SERVICES_TYPES.GET();
        if (res.status && Array.isArray(res.data)) {
            setServiceTypes(res.data);
        }
    };

    const handleChangeOfSearch = (e) => {
        setTempSearch(e.target.value);
        if (!e.target.value) {
            setCurrentPage(1);
            setSearch(null);
            setTempSearch(null);
        }
    };

    const handleSearch = () => {
        setCurrentPage(1);
        setSearch(tempSearch);
    };

    useEffect(() => {
        fetchScheduleServices();
        fetchServiceTypes();
    }, [currentPage, filterValue?.value, pageSize, search, sort, FilterStartDate, FilterEndDate]);

    const columns = [
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.SERVICES.TABLE.USER_ID}</span>, 'user_id', column),
            dataIndex: 'property',
            key: 'user_id',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value?.appuserId ? <span className="tabletext">{value?.appuserId}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.SERVICES.TABLE.NAME}</span>, 'user_name', column),
            dataIndex: 'property',
            key: 'user_name',
            align: 'center',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value?.appuser?.name ? <span className="tabletext">{value?.appuser?.name.charAt(0).toUpperCase() + value?.appuser?.name.slice(1)}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.SERVICES.TABLE.PROPERTY}</span>, 'property_name', column),
            dataIndex: 'property',
            key: 'property_name',
            align: 'center',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{value.name.charAt(0).toUpperCase() + value.name.slice(1)}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.SERVICES.TABLE.LOCATION}</span>, 'address', column),
            dataIndex: 'property',
            key: 'address',
            align: 'center',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{value.address}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.SERVICES.TABLE.Date}</span>, 'createdOn', column),
            key: 'createdOn',
            width: 250,
            dataIndex: 'createdOn',
            align: 'center',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (value ? <span className="tabletext">{moment(value).format('D MMM, Y HH:MM A')}</span> : '-'),
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.SERVICES.TABLE.SERVICE_TYPE}</span>, 'service_type', column),
            key: 'service_type',
            dataIndex: 'service_type',
            align: 'center',
            width: 200,
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (value ? <span className="tabletext">{value.name.charAt(0).toUpperCase() + value.name.slice(1)}</span> : '-'),
        }
    ];

    return (
        <div className='userTabReportLayout'>
            <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} withmenu={true} />
            <Row className={isFilterShow ? 'filterBoxLayout mb-3' : 'filterBoxLayout d-none'}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Form.Item
                                label={<span className="inputTitle">{FILTER_STRING.START_DATE}</span>}
                                colon={false}
                                wrapperCol={24}
                            >
                                <DatePicker className='selectDateInput'
                                    placeholder=""
                                    suffixIcon={<CalenderDarkIcon />}
                                    allowClear
                                    onChange={value => setFilterStartDate(value)}
                                    disabledDate={d => !d || d.isAfter(FilterEndDate)}
                                    picker="date" />
                            </Form.Item>
                            <Form.Item
                                label={<span className="inputTitle">{FILTER_STRING.END_DATE}</span>}
                                colon={false}
                                wrapperCol={24}
                            >
                                <DatePicker className='selectDateInput'
                                    placeholder=""
                                    suffixIcon={<CalenderDarkIcon />}
                                    allowClear
                                    onChange={value => setFilterEndDate(value)}
                                    disabledDate={d => !d || d.isSameOrBefore(FilterStartDate)}
                                    picker="date" />
                            </Form.Item>
                            <Form.Item
                                label={<span className="inputTitle">{FILTER_STRING.SEARCH}</span>}
                                className="searchInpUserTab"
                                colon={false}
                            >
                                <Input
                                    placeholder={COMMON_STRING.SEARCH_STR + 'Service'}
                                    className='inputField searchField'
                                    value={tempSearch}
                                    allowClear
                                    onChange={(e) => handleChangeOfSearch(e)}
                                    onKeyPress={(e) => {
                                        if (e?.key === 'Enter' && e.target.value) {
                                            handleSearch();
                                        }
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16} className={'dropdownBox'}>
                            <div className="filterSelect">
                                <Select defaultValue={FILTER_STRING.USER} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{Appusers?.map((appuser, key) => <Select.Option key={key} value={appuser.id}>{appuser.name}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={FILTER_STRING.PROPERTIES} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{Properties?.map((property, key) => <Select.Option key={key} value={property.id}>{property.name}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={FILTER_STRING.STATE} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{StateMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={FILTER_STRING.YEAR} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{ServicesTypes.map((ServicesType, key) => <Select.Option key={key} value={ServicesType.id}>{ServicesType.name}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={REPORTS_STRINGS.SERVICES.FILTER.SERVICE_TYPE} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{ServicesTypes.map((service_type, key) => <Select.Option key={key} value={service_type.id}>{service_type.name}</Select.Option>)}</Select>
                            </div>
                            <div className="exportBox">
                                <div className="exportTitle">Export</div>
                                <div className="exportBtn">{REPORTS_STRINGS.BUTTONS.EXPORT_CSV}</div>
                                <div className="exportBtn">{REPORTS_STRINGS.BUTTONS.EXPORT_EXCEL}</div>
                                <div className="exportBtn">{REPORTS_STRINGS.BUTTONS.EXPORT_PDF}</div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table
                        columns={columns}
                        dataSource={services?.data}
                        pagination={false}
                        rowKey='id'
                        loading={tableLoading}
                        onChange={onChange}
                        scroll={{ x: 400 }}
                    />
                    {services?.total > 10 && (
                        <div className='text-end bg-white mt-7'>
                            <PaginationCom
                                onChange={onChangePage}
                                showSizeChanger={true}
                                current={currentPage}
                                showLessItems={true}
                                total={services?.total}
                            />
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );
};

ServicesTabReports.propTypes = {
    permissions: PropTypes.any
};

export default ServicesTabReports;
