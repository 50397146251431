import { Col, Row, Table, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import DeleteModel from '../../../../components/DeleteModel';
import PaginationCom from '../../../../components/PaginationCom';
import DeleteIcon from '../../../../components/Icon/DeleteIcon';
import PencileIcon from '../../../../components/Icon/PencileIcon';
import { tableSortIconToggle } from '../../../../utility/tableSorting';
import PropTypes from 'prop-types';
import { COUNCILES } from '../../../../services/Locations/Council';
import { CITY_STATE_STRINGS, COMMON_ALERTS, TABLE_STRING } from '../../../../services/strings';

const CouncilsTabList = ({ permissions }) => {
  const navigate = useNavigate();
  const [Councils, setCouncils] = useState(null);
  const [tableLoading, setTableLoading] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [sort, setSort] = useState(null);
  const [tableDataLength, setTableDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const deleteCity = async () => {
    try {
      const res = await COUNCILES.DELETE(deleteId);
      if (res.status) {
        toast.success(COMMON_ALERTS.DELETED_SUCCESS('Council'));
        setIsDeleteModalVisible(false);
        fetchCities(currentPage, pageSize, search, sort);
      }
    }
    catch (e) {
      // console.log(e);
    }
  }

  const fetchCities = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null
  ) => {
    setTableLoading(true);
    try {
      const res = await COUNCILES.GET(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort
      );
      if (res.status === 1 || res.status === '1') {
        setCouncils(res);
        setTableDataLength(res?.data && res?.data.length);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    fetchCities(currentPage, pageSize, search, sort);
  }, [currentPage, pageSize, search, sort]);

  const columns = [
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{CITY_STATE_STRINGS.Table.COUNCIL}</span>, 'name', column),
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      width: 300,
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{CITY_STATE_STRINGS.Table.SUBURBS}</span>, 'suburb', column),
      dataIndex: 'suburb',
      key: 'suburb',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.name.charAt(0).toUpperCase() + value.name.slice(1)}</span> : '-',
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      width: 150,
      key: 'Actions',
      align: 'center',
      dataIndex: 'Actions',
      render: (item, record) => {
        return (
          <div className='actionCol'>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit Council'>
                <PencileIcon
                  onClick={() => navigate('/city_state/council/edit', { state: { dcouncil: record } })}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
              </Tooltip>
            )}
            {/* {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Council'>
                <DeleteIcon
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )} */}
          </div>
        );
      },
    },
  ];

  return (
    <div className='commonWrapperLayout'>
      <div className="descheduleBtnBox withmenu">
        <button onClick={() => navigate('/city_state/council/add')} className="addDepScheduleBtn">{CITY_STATE_STRINGS.BUTTONS.ADD_COUNCIL}</button>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            columns={columns}
            dataSource={Councils?.data}
            pagination={false}
            rowKey='id'
            loading={tableLoading}
            onChange={onChange}
            scroll={{ x: 400 }}
          />

          {Councils?.total > 10 && (
            <div className='text-end bg-white mt-7'>
              <PaginationCom
                onChange={onChangePage}
                showSizeChanger={true}
                current={currentPage}
                showLessItems={true}
                total={Councils?.total}
              />
            </div>
          )}
        </Col>
      </Row>
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteCity}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </div>
  );
}

CouncilsTabList.propTypes = {
  permissions: PropTypes.any
}

export default CouncilsTabList;
