import React, { useEffect } from 'react';
import FormItem from 'antd/es/form/FormItem';
import AppInput from '../../components/AppInput';
import { Col, Form, Row } from 'antd';
import { COMMON_ALERTS, COMMON_STRING, PD_REFERRING } from '../../services/strings';
import { useNavigate } from 'react-router-dom';
import { GLOBAL_VALUES } from '../../services/GlobalValues/GlobalValues';
import { toast } from 'react-toastify';

const PdReferralPage = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onSubmit = async (form) => {
        const payload = [
            {
                name: 'pdReferror',
                value: form.pdReferror
            },
            {
                name: 'pdReferee',
                value: form.pdReferee
            },
            {
                name: 'referPdPeriod',
                value: form.referPdPeriod
            }
        ];
        const res = await GLOBAL_VALUES.POST(payload);
        if(res.status){
            toast.success('Property dollar amount updated successfully');
        }
    }

    const getData = async () =>{
        const res = await GLOBAL_VALUES.ID('pdReferrals');
        if(res.status){
            res.data.map(e =>{
                if(e.name === 'pdReferror')
                    form.setFieldValue('pdReferror', e.value);
                else if(e.name === 'pdReferee')
                    form.setFieldValue('pdReferee', e.value);
                else if(e.name === 'referPdPeriod')
                    form.setFieldValue('referPdPeriod', e.value);
            });
        }
    }

    useEffect(()=>{
        getData();
    }, []);

    return (
        <div className='pagelayout'>
            <div className="cardBox">
                <h1 className='cardTitle'>Set PD Referring Amount</h1>
                <Form name="complex-form" className='mt-3' form={form} onFinish={onSubmit} labelCol={{ span: 8 }} colon={false} wrapperCol={{ span: 8 }}>
                    <FormItem
                        name='pdReferror'
                        required
                        rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(PD_REFERRING.PD_REFERRAL) }]}
                        label={<span className='inputTitle'>{PD_REFERRING.PD_REFERRAL}</span>}>
                        <AppInput className="input-control" />
                    </FormItem>
                    <FormItem
                        name='pdReferee'
                        required
                        rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(PD_REFERRING.PD_REFEREE) }]}
                        label={<span className='inputTitle'>{PD_REFERRING.PD_REFEREE}</span>}>
                        <AppInput className="input-control" />
                    </FormItem>
                    <FormItem
                        name='referPdPeriod'
                        required
                        rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(PD_REFERRING.EXPIRY_PERIOD) }]}
                        label={<span className='inputTitle'>{PD_REFERRING.EXPIRY_PERIOD}</span>}>
                        <AppInput className="input-control" />
                    </FormItem>
                    <Row justify='center'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="btnBoxMulti">
                            <button type='button' className="cancelBtnMulti" onClick={() => navigate('/referrals')}>{COMMON_STRING.BACK_BTN}</button>
                            <button type='submit' className="addBtnMulti">{location?.offerId? COMMON_STRING.UPDATE_BTN :COMMON_STRING.SET_BTN}</button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
}

export default PdReferralPage;