import { Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js'
import { convertToHTML } from 'draft-convert';
import { FAQ } from '../../services/FAQ/faqService';
import { toast } from 'react-toastify';
import { COMMON_ALERTS, COMMON_STRING, FAQ_STRINGS } from '../../services/strings';

const EditAddFAQPage = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const dFaq = useLocation().state?.dFaq;
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState(null);
    const [initialValue, setinitialValue] = useState({});
    const [isDataLoaded, setisDataLoaded] = useState(false);

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }
    const changeEditTExt = (e) => {
        setEditorState(e);
        convertContentToHTML();
    }

    const onFinish = async (e) => {
        let payload = {
            question: e.question,
            desc: convertedContent
        }
        let res;
        let msg = '';
        if (!dFaq) {
            res = await FAQ.CREATE(payload);
            msg = COMMON_ALERTS.ADDED_SUCCESS('FAQ');
        }
        else {
            res = await FAQ.PATCH(dFaq.id, payload);
            msg = COMMON_ALERTS.UPDATED_SUCCESS('FAQ');
        }

        if (res.status) {
            toast.success(msg);
            navigate('/faqs');
        }
    }

    useEffect(() => {
        if (dFaq) {
            setinitialValue(Object.assign(initialValue, { question: dFaq?.question }));
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(dFaq?.desc))));
            setConvertedContent(dFaq?.desc);
        }
        setisDataLoaded(true);
    }, []);

    if (isDataLoaded)
        return (
            <div>
                <div className="fWidthShowBox addNewsArticleLayout">
                    <h2>{dFaq ? FAQ_STRINGS.ADD_EDIT.EDIT_LABEL : FAQ_STRINGS.ADD_EDIT.ADD_LABEL}</h2>
                    <Form form={form} initialValues={initialValue} colon={false} onFinish={onFinish} requiredMark={false}>
                        <Row className='inputsBox'>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="inpBx">
                                <Form.Item className='inputBox' name="question" label={<div className="inputTitleBx"><span className="inputTitle">{dFaq ? FAQ_STRINGS.ADD_EDIT.EDIT_QUESTION : FAQ_STRINGS.ADD_EDIT.ADD_QUESTION}</span></div>} rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('question') }]}>
                                    <Input className='inputC' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="inpBx inpDescBx">
                                <Form.Item wrapperCol={24} required label={<div className="inputTitleBx">
                                    <span className="inputTitle">{dFaq ? FAQ_STRINGS.ADD_EDIT.EDIT_ANSWER : FAQ_STRINGS.ADD_EDIT.TYPE_ANSWER}</span></div>}
                                    name="description"
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                const stateBlocks = convertToRaw(editorState.getCurrentContent()).blocks;
                                                if (Array.isArray(stateBlocks) && stateBlocks.length) {
                                                    const text = stateBlocks[0].text;
                                                    if (!text.length) return Promise.reject("Please enter description!");
                                                    return Promise.resolve();
                                                }
                                                else Promise.reject("Please enter description!");
                                            }
                                        }
                                    ]}
                                >
                                    <div className="inputDescBox">
                                        <Editor
                                            onEditorStateChange={(e) => changeEditTExt(e)}
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                        />
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='adCancelBtnBx'>
                                <div className="cancleBtn" onClick={() => navigate('/faqs')}>{COMMON_STRING.BACK_BTN}</div>
                                <button type='submit' className="addBtn">{dFaq ? COMMON_STRING.UPDATE_BTN : COMMON_STRING.CREATE_BTN}</button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        );
}

export default EditAddFAQPage;
