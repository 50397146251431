import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { CartesianGrid, Dot, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import PropTypes from 'prop-types';
import { DASHBOARD_STRING } from '../../services/strings';

const PropertyCountChart = (props) => {
  const [data, setData] = useState([]);
  const MonthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const fetchPropertyData = async () => {
    const finaldata = [];
    if (props.data) {
      MonthsList.map(month => {
        const arr = props.data.filter(pdata => pdata.months === month);
        finaldata.push(
          {
            'name': month,
            'count': arr.length ? arr[0].total_properties : 0,
          }
        );
      });
      setData(finaldata);
    }
  };

  useEffect(() => {
    fetchPropertyData();
  }, [props.data]);

  return (<div className='bg-white box-shadow-3 border-radius-10 p-2 dashboardCards propertyCount_card'>
    <h2 className='font-weight-700 mb-0'>{DASHBOARD_STRING.STATEWISE_PROPERTIES}</h2>
    <div className='total-media d-flex align-items-center justify-content-center mb-4'></div>
    <ResponsiveContainer width="100%" height="80%">
      <LineChart width={730} height={260} data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="0" vertical={false} />
        <XAxis stroke="0" dataKey="name" />
        <YAxis stroke="0" />
        <Tooltip />
        <Dot height={14} width={14} />
        <Line type="monotone" strokeWidth={2} dataKey="count" stroke="#F44840" color={'#F44840'} fill="red" />
      </LineChart>
    </ResponsiveContainer>
  </div>);
};

PropertyCountChart.propTypes = {
  'data': PropTypes.any
};

export default PropertyCountChart;