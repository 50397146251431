import { methods, serviceMaker } from '../service';

const GET_PROPERTIES_BY_USER = (
  limit,
  skip,
  search = null,
  sort = null,
  appuserId
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }

  if (appuserId) {
    query['params']['appuserId'] = appuserId;
  }

  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/property`, methods.GET, query);
  }

  return serviceMaker(`property`, methods.GET, query);
};

const GET_PROPERTY = (id) => {
  return serviceMaker(`property/${id}`, methods.GET);
}

const GET_PROPERTIES = (limit,
  skip,
  search = null,
  sort = null,
  startdate = null,
  enddate = null,
  countryId = null,
  stateId = null,
  cityId = null,
  suburbsId = null,
  councilId = null,
  bedroom = null,
  bathroom = null,
  propertyType = null,
  carSpaces = null,
  landsize = null,
  isValidate = null,
  propertyStatus = null,
  validateCheckbox = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }

  if (startdate) {
    query['params']['startDate'] = new Date(startdate);
  }
  if (enddate) {
    query['params']['endDate'] = new Date(enddate);
  }
  if (countryId) {
    query['params']['countryId'] = countryId;
  }
  if (stateId) {
    query['params']['stateId'] = stateId;
  }
  if (cityId) {
    query['params']['cityId'] = cityId;
  }
  if (suburbsId) {
    query['params']['suburbsId'] = suburbsId;
  }
  if (councilId) {
    query['params']['councilId'] = councilId;
  }
  if (propertyType) {
    query['params']['propertyTypeId'] = propertyType;
  }
  if (bedroom) {
    query['params']['bedroom'] = bedroom;
  }
  if (bathroom) {
    query['params']['bathroom'] = bathroom;
  }
  if (carSpaces && carSpaces !== '0-0') {
    query['params']['carSpaces'] = carSpaces;
  }
  if (landsize && carSpaces !== '0-0') {
    query['params']['landsize'] = landsize;
  }
  if (isValidate) {
    query['params']['isValidate'] = false;
  }
  if (isValidate === 'validated') {
    query['params']['isValidate'] = true;
  }
  if (isValidate === 'validated') {
    query['params']['isValidate'] = true;
  }
  if (validateCheckbox) {
    query['params']['isValidate'] = true;
  }

  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/property`, methods.GET, query);
  }
  return serviceMaker(`property`, methods.GET, query);
};

const GET_BY_CONTACT = (limit, skip, search = null, sort = null, contactDetailId) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  }
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }

  if (contactDetailId) {
    query['params']['contactDetailId'] = contactDetailId;
    query['params']['isLinked'] = true;
  }

  return serviceMaker(`property`, methods.GET, query);
}

const DELETE_PROPERTIES = (id) => serviceMaker(`property/${id}`, methods.DELETE);

const VALIDATE_PROPERTIES = (id, payload) =>
  serviceMaker(`property/${id}`, methods.PATCH, null, payload);

const EXPORT_PROPERTIES = () => serviceMaker(`search/property`, methods.GET, {
  params: {
    $limit: -1,
    $skip: 0
  },
});

const PROPERTY_REPORT = (startDate = null, endDate = null, countryId = null, propertyType = null, propertyStatus = null) => {
  const query = {
    params: {
      $limit: -1,
      $skip: 0
    },
  }
  query['params']['$sort[createdAt]'] = -1;
  if (startDate) {
    query['params']['startDate'] = new Date(startDate);
  }
  if (endDate) {
    query['params']['endDate'] = new Date(endDate);
  }
  if (countryId) {
    query['params']['countryId'] = countryId;
  }
  if (propertyType) {
    query['params']['propertyTypeId'] = propertyType;
  }
  if (propertyStatus) {
    query['params']['propertyStatus'] = propertyStatus;
  }
  return serviceMaker(`reports/properties`, methods.GET, query);
};

const PROPERTIES = {
  GETBYUSERID: GET_PROPERTIES_BY_USER,
  GET_BY_CONTACT: GET_BY_CONTACT,
  SINGLE: GET_PROPERTY,
  GET: GET_PROPERTIES,
  DELETE: DELETE_PROPERTIES,
  VALIDATE: VALIDATE_PROPERTIES,
  EXPORT: EXPORT_PROPERTIES,
  REPORT: PROPERTY_REPORT
};
export { PROPERTIES };
