import { Button, Col, Form, Input, InputNumber, Row, Select, Switch, Table, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PencileIcon from '../../components/Icon/PencileIcon';
import { COMMON_ALERTS, COMMON_STRING, EXTERNAL_SERVICE_STRINGS } from '../../services/strings';
import { EXTERNAL_SERVICE } from '../../services/ExternalServices/ExternalServices';
import { SERVICES_TYPES } from '../../services/Services/ServicesTypes';
import SelectArrow from '../../components/Icon/SelectArrow';
import { getLSItem } from '../../services/auth/encryptLogin';
import { BASE_URL } from '../../services/constant';
import { isNumeric } from '../../services/helpers';
import { DROPDOWN } from '../../services/Dropdown/dropdowns';
import { SUBURBS } from '../../services/Locations/Suburbs';

const AddEditExternalService = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const serviceId = useLocation().state?.serviceId;
  const [Service, setService] = useState(null);
  const [vProfilefileList, setVProfilefileList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [Countries, setCountries] = useState([]);
  const [States, setStates] = useState([]);
  const [Suburbs, setSuburbs] = useState([]);
  const [Cities, setCities] = useState([]);
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [ServiceTypes, setServiceTypes] = useState([]);
  const accessToken = getLSItem("pdollar-token");
  const [VenProfileId, setVenProfileId] = useState(null); //Vendor Profile Image
  const [BusinessProfileIds, setBusinessProfileIds] = useState([]); //Business Profile Image
  const [isAvailability, setisAvailability] = useState(true);

  const fetchServiceTypes = async () => {
    const countriesData = await SERVICES_TYPES.GET(-1, 0, null, null);
    if (countriesData.status && Array.isArray(countriesData.data)) {
      setServiceTypes([...ServiceTypes, ...countriesData.data]);
    }
  }

  const fetchCountries = async () => {
    const countries = await DROPDOWN.GET_COUNTRIES();
    if (countries.status)
      setCountries(countries.data)
  }

  const changeCountry = async (countryId) => {
    const states = await DROPDOWN.GET_STATES(countryId);
    if (Array.isArray(states.data)) {
      setStates(states.data);
    }
  }

  const changeStates = async (stateID) => {
    const cities = await DROPDOWN.GET_CITY(stateID);
    const suburbs = await DROPDOWN.GET_SUBURBS(null, stateID);
    form.setFieldValue('cityId', null);
    form.setFieldValue('suburbId', null);
    if (Array.isArray(cities.data)) {
      setCities(cities.data);
    }
    if (Array.isArray(suburbs.data)) {
      setSuburbs(suburbs.data);
    }
  }

  const changeCities = async (cityId) => {
    const suburbs = await DROPDOWN.GET_SUBURBS(cityId);
    if (Array.isArray(suburbs.data)) {
      setSuburbs(suburbs.data);
    }
  }

  useEffect(() => {
    fetchCountries();
    if (serviceId !== null) {
      fetchServiceTypes();
      fetchExternalService();
    }

  }, []);

  const fetchExternalService = async () => {
    if (serviceId) {
      const res = await EXTERNAL_SERVICE.FIND(serviceId);
      if (res.status) {
        setService(res.data);
        setisDataLoaded(true);
        setVenProfileId(res?.data?.vendorProfileId);
        const base_url = BASE_URL.substring(0, BASE_URL.length - 1);
        const rows = res?.data?.businessImages.map((image, key) => ({
          id: image?.businessImage?.id,
          key,
          uid: key,
          name: 'cover image',
          status: 'done',
          url: base_url + image?.businessImage?.source + image?.businessImage?.path,
          thubmUrl: base_url + image?.businessImage?.source + image?.businessImage?.path,

        }));
        setFileList(rows);

        form.setFieldsValue({
          parameter: res?.data?.perameters,
          minValue1: res?.data?.external_service_values[0]?.min,
          maxValue1: res?.data?.external_service_values[0]?.max,
          rateValue1: res?.data?.external_service_values[0]?.rate,
          minValue2: res?.data?.external_service_values[1]?.min,
          maxValue2: res?.data?.external_service_values[1]?.max,
          rateValue2: res?.data?.external_service_values[1]?.rate,
          minValue3: res?.data?.external_service_values[2]?.min,
          maxValue3: res?.data?.external_service_values[2]?.max,
          rateValue3: res?.data?.external_service_values[2]?.rate,
          vProfilefileList: {fileList: [res?.data?.vendorProfile]},
          businessImageFiles: {fileList: [res?.data?.businessImages]},
          availability: res?.data?.availability
        });
        setVProfilefileList([
          {
            uid: '-1',
            name: 'cover image',
            status: 'done',
            url: base_url + res?.data?.vendorProfile?.image,
            thubmUrl: base_url + res?.data?.vendorProfile?.image,
          }
        ]);

        setisAvailability(res?.data?.availability);
        changeCountry(res?.data.suburb.cityId ? res?.data.suburb.city.state.countryId : res?.data.suburb.state.countryId);
        const stateId = res?.data.suburb.cityId ? res?.data.suburb.city.stateId : res?.data.suburb.stateId;
        const cities = await DROPDOWN.GET_CITY(stateId);
        const suburbs = await DROPDOWN.GET_SUBURBS(null, stateId);
        if (Array.isArray(cities.data)) {
          setCities(cities.data);
        }
        if (Array.isArray(suburbs.data)) {
          setSuburbs(suburbs.data);
        }
        if (res?.data.suburb.cityId) {
          changeCities(res?.data.suburb.cityId);
        }
        form.setFieldsValue({
          country: res?.data.suburb.cityId ? res?.data.suburb.city.state.countryId : res?.data.suburb.state.countryId,
          stateId: res?.data.suburb.cityId ? res?.data.suburb.city.stateId : res?.data.suburb.stateId,
          cityId: res?.data.suburb.cityId || null,
          suburbId: res?.data.suburbId || null
        });
        setBusinessProfileIds(res?.data?.businessImages.map(image => image.businessImage.id));
      }
    }
  }

  const onFinish = async (form) => {
    const payload = {
      "venderName": form.vendorname,
      "businessName": form.business_name,
      "phoneNumber": form.contact,
      "perameters": form.parameter,
      "serviceTypeId": form.typeservice,
      "suburbId": form.suburbId,
      "availability": isAvailability,
      "addedBy": "admin",
      "businessImages": BusinessProfileIds,
      "vendorProfileId": VenProfileId,
      "avgCharge": form.avgCharge,
      "external_service_values": [
        {
          "min": form.minValue1,
          "max": form.maxValue1,
          "rate": form.rateValue1
        },
        {
          "min": form.minValue2,
          "max": form.maxValue2,
          "rate": form.rateValue2
        },
        {
          "min": form.minValue3,
          "max": form.maxValue3,
          "rate": form.rateValue3
        }
      ]
    };

    let res = null;
    if (!serviceId)
      res = await EXTERNAL_SERVICE.CREATE(payload);
    else {
      payload.external_service_values.map((elem, index) => {
        elem['id'] = Service.external_service_values[index].id;
      });
      res = await EXTERNAL_SERVICE.PATCH(serviceId, payload);
    }
    try {
      if (res.status) {
        toast.success(!serviceId ? COMMON_ALERTS.ADDED_SUCCESS('Scheduled service') : COMMON_ALERTS.UPDATED_SUCCESS('Scheduled service'));
        navigate('/external_Service', { replace: true });
      }
    }
    catch (e) {
      console.log(e);
    }
  }
  const columns = [
    {
      title: <span className="tableTitle">{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.MIN_VAL}</span>,
      dataIndex: 'minValue',
      key: 'min_value',
    },
    {
      title: <span className="tableTitle">{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.MAX_VAL}</span>,
      dataIndex: 'maxValue',
      key: 'max_value',
    },
    {
      title: <span className="tableTitle">{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.RATE}</span>,
      dataIndex: 'rate',
      key: 'rate',
    }
  ];

  const parameters = [
    {
      minValue: <Form.Item name="minValue1" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(EXTERNAL_SERVICE_STRINGS.ADD_EDIT.MIN_VAL) }, { validator: (_, value) => { const maxValue = form.getFieldValue('maxValue1'); if (maxValue && (maxValue < value)) { return Promise.reject('Min value cannot be more than max value!') } return Promise.resolve('') } }]}><Input className='parameterInput' type={'number'} /></Form.Item>,
      maxValue: <Form.Item name="maxValue1" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(EXTERNAL_SERVICE_STRINGS.ADD_EDIT.MAX_VAL) }]}><Input className='parameterInput' type={'number'} /></Form.Item>,
      rate: <Form.Item name="rateValue1" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(EXTERNAL_SERVICE_STRINGS.ADD_EDIT.RATE) }]}><Input className='parameterInput' type={'number'} /></Form.Item>
    },
    {
      minValue: <Form.Item name="minValue2" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(EXTERNAL_SERVICE_STRINGS.ADD_EDIT.MIN_VAL) }, { validator: (_, value) => { const maxValue = form.getFieldValue('maxValue2'); if (maxValue && (maxValue < value)) { return Promise.reject('Min value cannot be more than max value!') } return Promise.resolve('') } }]}><Input className='parameterInput' type={'number'} /></Form.Item>,
      maxValue: <Form.Item name="maxValue2" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(EXTERNAL_SERVICE_STRINGS.ADD_EDIT.MAX_VAL) }]}><Input className='parameterInput' type={'number'} /></Form.Item>,
      rate: <Form.Item name="rateValue2" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(EXTERNAL_SERVICE_STRINGS.ADD_EDIT.RATE) }]}><Input className='parameterInput' type={'number'} /></Form.Item>
    },
    {
      minValue: <Form.Item name="minValue3" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(EXTERNAL_SERVICE_STRINGS.ADD_EDIT.MIN_VAL) }, { validator: (_, value) => { const maxValue = form.getFieldValue('maxValue3'); if (maxValue && (maxValue < value)) { return Promise.reject('Min value cannot be more than max value!') } return Promise.resolve('') } }]}><Input className='parameterInput' type={'number'} /></Form.Item>,
      maxValue: <Form.Item name="maxValue3" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(EXTERNAL_SERVICE_STRINGS.ADD_EDIT.MAX_VAL) }]}><Input className='parameterInput' type={'number'} /></Form.Item>,
      rate: <Form.Item name="rateValue3" rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(EXTERNAL_SERVICE_STRINGS.ADD_EDIT.RATE) }]}><Input className='parameterInput' type={'number'} /></Form.Item>
    }
  ];


  const vendorProfileImageProps = {
    name: "file",
    multiple: false,
    action: `${BASE_URL}upload-image`,
    style: {
      width: "127px",
      height: "127px"
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
    defaultFileList: vProfilefileList,
    beforeUpload(file) {
      if (vProfilefileList.length > 1 || vProfilefileList.length === 1) {
        toast.warn(COMMON_ALERTS.FILE_EXISTS);
        return false;
      }
      const type =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!type) {
        toast.error(COMMON_ALERTS.ONLY_ALLOWED_ERROR('.jpeg, .png, .jpg'));
      }
      return type || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.fileList.length === 1) {
        setVProfilefileList([...info.fileList]);
        form.setFieldValue("vendorProfile", "something");
      }
      if (info.file.status === "done") {
        setVenProfileId(info.file.response?.data?.media.id);
      } else if (info.file.status === "error") {
        toast.error(COMMON_ALERTS.FILE_UPLOAD_FAILED(info.file.name));
      }
    },
    async onRemove() {
      setVProfilefileList([]);
      setVenProfileId(null);
      form.setFieldValue("vendorProfile", null);
    },
  };

  const businessProfileImageProps = {
    name: 'file',
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
    action: `${BASE_URL}upload-image`,
    defaultFileList: fileList
  };

  const handleUpload = ({ fileList }) => {
    setFileList(fileList);
    setBusinessProfileIds((prev) => {
      return fileList.map(obj => {
        if (obj.id) {
          return obj.id;
        }
        else if (obj.status === 'done') {
          return obj.response?.data?.media?.id;
        }
      });
    });
  };

  const beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      console.error('You can only upload JPG/PNG file!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      console.error('Image must smaller than 2MB!');
      return false;
    }
    return true;
  };

  const alterBussinessImage = (data) => {
    setBusinessProfileIds(BusinessProfileIds.filter(e => e !== data.id));
  }

  const uploadButton = (
    <div className='uploadImageBtn'>
      <div>
        <PencileIcon />
      </div>
      <span className='noFileText'>{COMMON_STRING.NO_FILE_CHOOSEN}</span>
    </div>
  );

  if ((!serviceId) || isDataLoaded)
    return (
      <div>
        <div className="fWidthShowBox addNwDepScheduleContainer nationLayout">
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <h2>{!serviceId ? EXTERNAL_SERVICE_STRINGS.ADD_EDIT.ADD_SERVICE_LABEL : EXTERNAL_SERVICE_STRINGS.ADD_EDIT.EDIT_SERVICE_LABEL}</h2>
            </Col>
          </Row>
          <Form name="complex-form"
            className="addformLayout"
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 8 }}
            initialValues={{
              vProfilefileList: vProfilefileList // set the initial value for the fileList
            }}
            wrapperCol={{ span: 16 }}>
            <Form.Item
              label={<span className='inputTitle'>{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.VENDOR_NAME}</span>}
              name="vendorname"
              colon={false}
              initialValue={Service?.venderName}
              rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('vendor name') }]}
            >
              <Input className='textInput' />
            </Form.Item>
            <Form.Item
              label={<span className='inputTitle'>{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.BUSINESS_NAME}</span>}
              name="business_name"
              colon={false}
              initialValue={Service?.businessName}
              rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('business name') }]}
            >
              <Input className='textInput' />
            </Form.Item>
            <Form.Item
              label={<span className='inputTitle'>{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.CONTACT_NO}</span>}
              name="contact"
              colon={false}
              initialValue={Service?.phoneNumber}
              placeholder="Enter 12 digit mobile number"
              required
              rules={[
                {
                  validator: (_, value) => {
                    if (!value) return Promise.reject(COMMON_ALERTS.REQUIRED_INPUT('contact no.'));
                    if ((value.toString().length != 12) || (!isNumeric(value))) {
                      return Promise.reject("Please enter valid contact no.!")
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input maxLength={12} className='textInput' />
            </Form.Item>
            <Form.Item
              label={<span className='inputTitle'>{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.SERVICE_TYPE}</span>}
              name="typeservice"
              colon={false}
              initialValue={Service?.service_type?.id}
              rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('type of service') }]}
            >
              <Select className='selectInputLayout' name="typeService" suffixIcon={<SelectArrow />}>{ServiceTypes.map((type, index) => <Select.Option key={index} value={type.id}>{type.name}</Select.Option>)}</Select>
            </Form.Item>
            <Form.Item
              label={<span className='inputTitle'>{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.COUNTRY}</span>}
              name="country"
              colon={false}
              initialValue={Service?.name}
              rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('country') }]}
            >
              <Select className='selectInputLayout' onChange={(e) => changeCountry(e)} name="typeService" suffixIcon={<SelectArrow />}>{Countries.map((type, index) => <Select.Option key={index} value={type.id}>{type.name}</Select.Option>)}</Select>
            </Form.Item>
            <Form.Item
              label={<span className='inputTitle'>{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.STATE}</span>}
              name="stateId"
              colon={false}
              initialValue={Service?.name}
              rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('state') }]}>
              <Select className='selectInputLayout' onChange={(e) => changeStates(e)} name="typeService" notFoundContent={COMMON_ALERTS.DATA_NOT_FOUND('states', 'country')} suffixIcon={<SelectArrow />}>{States.map((type, index) => <Select.Option key={index} value={type.id}>{type.name}</Select.Option>)}</Select>
            </Form.Item>
            <Form.Item
              label={<span className='inputTitle'>{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.CITY}</span>}
              name="cityId"
              colon={false}
              initialValue={Service?.name}>
              <Select className='selectInputLayout' onChange={(e) => changeCities(e)} name="typeService" notFoundContent={COMMON_ALERTS.DATA_NOT_FOUND('cities', 'state')} suffixIcon={<SelectArrow />}>{Cities.map((type, index) => <Select.Option key={index} value={type.id}>{type.name}</Select.Option>)}</Select>
            </Form.Item>
            <Form.Item
              label={<span className='inputTitle'>{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.SUBURBS}</span>}
              name="suburbId"
              colon={false}
              initialValue={Service?.name}
              rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('suburbs') }]}>
              <Select className='selectInputLayout' dname="typeService" suffixIcon={<SelectArrow />} notFoundContent={COMMON_ALERTS.DATA_NOT_FOUND('suburbs', 'city')}>{Suburbs.map((type, index) => <Select.Option key={index} value={type.id}>{type.name}</Select.Option>)}</Select>
            </Form.Item>
            <Form.Item
              label={<span className='inputTitle'>{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.VENDOR_PROFILE}</span>}
              name="vProfilefileList"
              colon={false}
              requiredMark={true}
              rules={[{
                required: true,
                validator: (_, value) => {
                  if (value?.fileList?.length) {
                    return Promise.resolve();
                  }
                  else {
                    return Promise.reject(COMMON_ALERTS.REQUIRED_SELECT('vendor profile'))
                  }
                }
              }]}>
              <Upload accept=".png, .jpg, .jpeg" listType="picture" className='imageUploadInput'{...vendorProfileImageProps} >
                {vProfilefileList.length >= 1 ? null : uploadButton}
              </Upload>
            </Form.Item>
            <Form.Item
              name="businessImageFiles"
              label={<span className='inputTitle'>{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.UPLOAD_IMAGES}</span>}
              colon={false}
              valuePropName="businessImageList"
              requiredMark={true}
              rules={[{
                required: true,
                validator: (_, value) => {
                  if (value?.fileList?.length) {
                    return Promise.resolve();
                  }
                  else {
                    return Promise.reject(COMMON_ALERTS.REQUIRED_SELECT('business profile'))
                  }
                }
              }]}
            >
              <Upload
                name="businessProfiles"
                accept=".png, .jpg, .jpeg"
                listType="picture"
                className="imageUploadInput"
                showUploadList={true}
                beforeUpload={beforeUpload}
                onChange={handleUpload}
                multiple={true}
                maxCount={3}
                onRemove={alterBussinessImage}
                {...businessProfileImageProps}>
                {fileList.length >= 3 ? null : uploadButton}
              </Upload>
            </Form.Item>
            <Form.Item
              label={<span className='inputTitle'>{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.SELECT_PARAMTER}</span>}
              name="parameter"
              colon={false}
              rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('parameter') }]}
            >
              <Select className='selectInputLayout' suffixIcon={<SelectArrow />}>
                {EXTERNAL_SERVICE_STRINGS.PARAMETERS.map((param, key) => <Select.Option key={key} value={param}>{param}</Select.Option>)}
              </Select>
            </Form.Item>
            <Form.Item
              label={<span className='inputTitle'>{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.AVG_RATE}</span>}
              name="avgCharge"
              colon={false}
              initialValue={Service?.avgCharge}
              rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT(EXTERNAL_SERVICE_STRINGS.ADD_EDIT.AVG_RATE) }]}
            >
              <Input className='textInput' type='number' />
            </Form.Item>
            <Row>
              <Col xs={24} sm={24} md={{ span: 10, offset: 6 }} lg={{ span: 10, offset: 6 }} xl={{ span: 10, offset: 6 }}>
                <span className='parameterTitle'>{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.PARAMS_VALUES}</span>
                <Table
                  tableLayout='auto'
                  columns={columns}
                  dataSource={parameters}
                  pagination={false}
                  scroll={{ x: 400 }}
                  className="external_service_paramters"
                  rowKey='paramtersId' />
              </Col>
            </Row>
            <Form.Item
              label={<span className='inputTitle'>{EXTERNAL_SERVICE_STRINGS.ADD_EDIT.AVAILABILITY}</span>}
              name="availability"
              colon={false}
            >
              <Switch checked={isAvailability} onChange={(isChecked) => setisAvailability(isChecked)} className='greenSwitch' />
            </Form.Item>
            <Row>
              <Col xs={24} sm={24} md={{ span: 16, offset: 8 }} lg={{ span: 16, offset: 8 }} xl={{ span: 16, offset: 8 }} className="btnBox">
                <Button className="cancelBtn" onClick={() => navigate('/external_Service', { replace: true })}>{COMMON_STRING.BACK_BTN}</Button>
                <Button type="submit" htmlType='submit' className="addBtn">{!serviceId ? COMMON_STRING.CREATE_BTN : COMMON_STRING.UPDATE_BTN}</Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
}

export default AddEditExternalService;
