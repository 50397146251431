import React, { useState } from "react";
import PropTypes from 'prop-types';

const TabSwitch = ({ tabStates, children, selected }) => {
    const [selectedTab, setSelectedTab] = useState(selected ? selected : 0);
    return <>
        <div className="tabSwitch">
            <div className="tabHeader">
                <div className="tabSwitches">
                    {
                        tabStates.map((tab, i) => (<div key={i} className={"tabbox " + (selectedTab === i ? "selected" : "")} onClick={() => setSelectedTab(i)}>
                            <span className="tabText">{tab.name}</span>
                        </div>))
                    }
                </div>
                {children}
            </div>
            <div className="tabBody">
                {tabStates[selectedTab].component}
            </div>
        </div>
    </>;
}

TabSwitch.propTypes = {
    tabStates: PropTypes.array.isRequired,
    width: PropTypes.number,
    children: PropTypes.any,
    selected: PropTypes.number
};


export default TabSwitch;