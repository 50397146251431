import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Col, Form, Input, Row } from 'antd';
import { ADD_TOKEN, ADD_USER } from '../../redux/features/userSlice';
import { LOGIN } from '../../services/index';
import { toast } from 'react-toastify';
import { COMMON_ALERTS, LOGINPAGE_STRINGS } from '../../services/strings';
import '../../assets/css/authstyles.scss';
import { decrypt, encrypt, setLSItem } from '../../services/auth/encryptLogin';

function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const onSubmit = async (value) => {
        var pass=encrypt(value.password, 'ADMIN_PASS');
        // console.log(decrypt(pass, 'ADMIN_PASS'));
        try {
            const login = {
                username: value.username,
                password: pass,
                strategy: 'adminlogin',
                platform: navigator.platform,
                userAgent: navigator.userAgent
            };
            const res = await LOGIN(login);
            if (res.status === '1' || res.status === 1) {
                setLSItem('user', JSON.stringify(res.data.user));
                setLSItem('pdollar-token', res.data.accessToken);
                dispatch(ADD_USER(res.data.user));
                dispatch(ADD_TOKEN(res.data.accessToken));
                navigate(
                    routingPath(res?.data?.user?.role?.permissions[0]?.page?.value)
                );
                toast.success(COMMON_ALERTS.LOGIN_SUCCESS);
            } else if (res.status === '0' || res.status === 0) {
                toast.error(res.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
            }
        } catch (err) {
            toast.error(err.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
        }
    };

    const routingPath = (path) => {
        if (path) {
            if (path === 'app_users') {
                return '/app-users';
            } else if (path === 'admin_users') {
                return '/admin-users';
            } else {
                return `/${path}`;
            }
        } else {
            return '/dashboard';
        }
    };

    return (
        <div>
            <div className="login_header"><h1 className="header_title">Property Dollar</h1></div>
            <div className='login'>
                <div className='container'>
                    <Row>
                        <Col xxl={{ span: 8, offset: 8 }} xl={{ span: 12, offset: 6 }} lg={{ span: 16, offset: 4 }} md={{ span: 20, offset: 2 }} sm={{}} xs={{}}>
                            <div className="loginPage_box">
                                <span className="login_title">{LOGINPAGE_STRINGS.TITLE}</span>
                                <Form form={form} onFinish={onSubmit} colon={false} labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
                                    <Form.Item
                                        name='username'
                                        rules={[
                                            { required: true, message: COMMON_ALERTS.REQUIRED_INPUT('username') },
                                        ]}
                                        label={<label className="input_title">{LOGINPAGE_STRINGS.USERNAME_LABEL}</label>}>
                                        <Input
                                            placeholder='Username'
                                            className='input-control input_field'
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name='password'
                                        rules={[
                                            { required: true, message: COMMON_ALERTS.REQUIRED_INPUT('password') },
                                        ]}
                                        label={<label className="input_title">{LOGINPAGE_STRINGS.PASSWORD_LABEL}</label>}
                                    >
                                        <Input
                                            type="password"
                                            placeholder='Password'
                                            className='input-control input_field'
                                        />
                                    </Form.Item>
                                    <Form.Item wrapperCol={12} className='loginBtnDiv'>
                                        <button className='btn' type='submit'>{LOGINPAGE_STRINGS.LOGIN_BTN}</button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default Login;