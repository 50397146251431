import { Col, Empty, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteModel from '../../components/DeleteModel';
import NewAndArticles from '../../components/NewsAndArticles/NewAndArticles';
import { NEWSARTICLES } from '../../services/NewsDetails/newsDetailsService';
import PropTypes from 'prop-types';
import { COMMON_ALERTS, NEWS_ARTICLES_STRING } from '../../services/strings';
import LeftNavIcon from '../../components/Icon/LeftNavIcon';
import AppButton from '../../components/AppButton';

const TopNewsPage = ({ permissions }) => {
    const navigate = useNavigate();
    const [NewsArticles, setNewsArticles] = useState(null);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const fetchTopNews = async () => {
        const newsArticles = await NEWSARTICLES.GET(-1, 0, null, -1, 'news', true);
        if (Array.isArray(newsArticles.data)) {
            setNewsArticles(newsArticles);
            setIsLoaded(true);
        }
    }

    const deleteTopNews = async () => {
        const res = await NEWSARTICLES.PATCH('removeTopNews', {id: deleteId});
        if (res.status) {
            toast.success(COMMON_ALERTS.DELETED_SUCCESS('Top News'));
            setIsDeleteModalVisible(false);
            fetchTopNews();
        }
        else {
            toast.error('Something wrong happened.');
        }
    }

    useEffect(() => { fetchTopNews(); }, []);

    return (
        <div className='commonWrapperLayout'>
            <div className="backNavHeader">
                <div className="backNavText" onClick={() => navigate(-1)}>
                    <LeftNavIcon />
                    <span>{NEWS_ARTICLES_STRING.LABEL.TOP_NEWS_LABEL}</span>
                </div>
                <AppButton text='Edit' onClick={() => navigate('/news')} />
            </div>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='newsArticleListWrapper innerScrollNews'>
                    {
                        isLoaded && NewsArticles?.data?.length ?
                            NewsArticles?.data.map((news, key) =>
                                <NewAndArticles
                                    key={key}
                                    data={news}
                                    setIsDeleteModalVisible={setIsDeleteModalVisible}
                                    isTopNews={true}
                                    setDeleteId={setDeleteId}
                                    permissions={permissions} />) :
                            <Empty description={NEWS_ARTICLES_STRING.ERROR.NOT_FOUND} />
                    }
                </Col>
            </Row>
            <DeleteModel
                isDeleteModalVisible={isDeleteModalVisible}
                deleteModel={deleteTopNews}
                setIsDeleteModalVisible={setIsDeleteModalVisible}
            />
        </div>
    );
}

TopNewsPage.propTypes = {
    permissions: PropTypes.any
};

export default TopNewsPage;
