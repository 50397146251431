import React, { useEffect, useState } from 'react';

import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Layout } from 'antd';

import { LOGOUT } from '../redux/features/userSlice';
import Sidebar from '../components/Sidebar/Sidebar';
import Header from '../components/Header/Header';
import { getLSItem } from '../services/auth/encryptLogin';
import { LOGOUT_API } from '../services/login/login';

const { Content } = Layout;

const AdminLayout = () => {
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = getLSItem('pdollar-token');
  const user = JSON.parse(getLSItem('user'));

  const logoutMe = async () => {
    const res = await LOGOUT_API();
    if(res.status){
      localStorage.clear();
      dispatch(LOGOUT());
      navigate('/login');
    }
  }

  useEffect(() => {
    if (!user || !token) {
      logoutMe();
      toast.error('User is not authenticated !');
    }
  }, [dispatch, location.pathname, navigate, token, user]);

  useEffect(() => {
    setTimeout(() => {
      logoutMe();
      toast.error('User is not authenticated !');
    }, 1000 * 60 * 60 * 24);
  }, [dispatch, navigate]);

  return (
    <>
      {token ? (
        <Layout style={{ minHeight: '100vh' }}>
          <Header />
          <Layout>
            <Sidebar setIsShowSidebar={setIsShowSidebar} />
            <Content
              style={{
                marginLeft: isShowSidebar ? '80px' : '256px',
                transition: 'all 0.4s',
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      ) : (
        <Navigate to='/login' />
      )}
    </>
  );
};

export default AdminLayout;
