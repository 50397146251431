import React from "react";
import { Pagination } from "antd";
import PaginationLeftIcon from "./Icon/PaginationLeftIcon";
import PaginationRightIcon from "./Icon/PaginationRightIcon";

const PaginationCom = (props) => {
  // pagination
  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <div className="pagination-left-icon pagination-icon"><PaginationLeftIcon /></div>;
    }
    if (type === "next") {
      return <div className="pagination-right-icon pagination-icon"><PaginationRightIcon /></div>;
    }
    return originalElement;
  }
  return (
    <>
      <Pagination showTotal={(total, range)=><span className="paginationShowTotalTxt">10 of {total} Results</span>}  {...props} itemRender={itemRender} />
    </>
  );
};

export default PaginationCom;
