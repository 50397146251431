import { Col, Radio, Row, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '../../../components/Icon/DeleteIcon';
import PencileIcon from '../../../components/Icon/PencileIcon';
import PropTypes from 'prop-types';
import { STAMPDUTYS } from '../../../services/Calculators/StampDuty';
import DeleteModel from '../../../components/DeleteModel';
import { toast } from 'react-toastify';
import { CALCULATOR_INFO_STRING, COMMON_ALERTS, TABLE_STRING } from '../../../services/strings';
import { formatter } from '../../../services/helpers';

const StampDutyManagement = ({ permissions }) => {
  const navigate = useNavigate();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteStampDId, setDeleteStampDId] = useState(null);
  const [StampDuty, setStampDuty] = useState([]);
  const [States, setStates] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchStampDuty = async () => {
    const res = await STAMPDUTYS.GET();
    if (res.status || Array.isArray(res.data)) {
      const data = [];
      res?.data?.map((elem, i) => {
        data.push({
          key: i.toString(),
          state: <span className="titleStateStamp">{elem?.state?.name}</span>,
          actions: <span className="titleStateStamp">iOS</span>
        });
      });
      setStampDuty(res.data);
      setStates(data);
    }
    setIsLoaded(true);
  };

  const deleteStampDuty = async () => {
    try {
      const res = await STAMPDUTYS.DELETE_STATE(deleteStampDId);
      if (res.status) {
        toast.success(COMMON_ALERTS.DELETED_SUCCESS('Stamp duty'));
        setIsDeleteModalVisible(false);
        fetchStampDuty();
      }
    }
    catch (e) {
      console.log(e);
    }
  };

  const expandedRowRender = (e) => {
    const columns = [
      {
        title: <span className="tableTitle">{CALCULATOR_INFO_STRING.STAMP_DUTY.P_VALUE}</span>,
        dataIndex: 'pvalue',
        key: 'pvalue',
        width: '30%'
      },
      {
        title: <span className="tableTitle">{CALCULATOR_INFO_STRING.STAMP_DUTY.PAYBLE_DUTY}</span>,
        dataIndex: 'payDuty',
        key: 'payDuty',
      }
    ];
    const data = [];
    if (StampDuty.length) {
      StampDuty[e.key]?.state?.stamp_duties?.map((elem, i) => {
        let tText = '';
        if ((parseInt(elem.minAmt) === 0) && (parseInt(elem.maxAmt) === 0))
          tText = tText.concat('$0 ');
        else if (parseInt(elem.minAmt) === 0)
          tText = tText.concat(`Not More than ${formatter.format(elem.maxAmt)} `);
        else if (parseInt(elem.maxAmt) === 0)
          tText = tText.concat(`More than ${formatter.format(elem.minAmt)} `);
        else
          tText = tText.concat(`${formatter.format(elem.minAmt)} - ${formatter.format(elem.maxAmt)} `);

        let dutyString = '';
        dutyString = dutyString.concat(`${formatter.format(elem.fixedAmt)} `);
        if (parseInt(elem.percentage) !== 0)
          dutyString = dutyString.concat(`+ ${elem.percentage}% `);
        dutyString = dutyString.concat('of dutiable value ');
        if (parseInt(elem.dutiableValue) !== 0) {
          dutyString = dutyString.concat(`value over ${formatter.format(elem.dutiableValue)}`);
        }

        data.push({
          key: i.toString(),
          pvalue: <span className="tabletext">{tText}</span>,
          payDuty: <span className="tabletext">{dutyString}</span>
        });
      });
    }
    return <Table className='stampDutyExpanded' id="stampDutyExpanded" columns={columns} dataSource={data} pagination={false} />;
  };

  useEffect(() => {
    fetchStampDuty();
  }, []);

  const columns = [
    {
      title: () => <span className="tableTitle">{CALCULATOR_INFO_STRING.STAMP_DUTY.STATE}</span>,
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      key: 'Actions',
      align: 'left',
      dataIndex: 'Actions',
      width: 150,
      render: (item, record) => {
        return (
          <div className='actionCol'>
            <Tooltip title='Edit Stampp Duty'>
              <PencileIcon
                onClick={() => navigate('/calculator_info/stamp_duty/edit', { state: { dstate: StampDuty[record.key] } })}
                className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
            </Tooltip>
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Stamp Duty'>
                <DeleteIcon
                  onClick={() => {
                    console.log(StampDuty[record.key]?.id);
                    setIsDeleteModalVisible(true);
                    // setDeleteKeyIndex(record.key);
                    setDeleteStampDId(StampDuty[record.key]?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  if (isLoaded)
    return (
      <div className='calculator_detailsLayout commonLayout'>
        <Row className='stampDutyTabDiv'>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} >
            <Radio.Group
              value={'management'}
              optionType="button"
              buttonStyle="solid">
              <Radio value={'detail'} onClick={() => navigate('/calculator_info/stamp_duty')}>{CALCULATOR_INFO_STRING.STAMP_DUTY.TABS.CALCULATOR}</Radio>
              <Radio value={'management'}>{CALCULATOR_INFO_STRING.STAMP_DUTY.TABS.MANAGEMENT}</Radio>
            </Radio.Group>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} >
            <button onClick={() => navigate('/calculator_info/stamp_duty/add')} className="createStampBtn">{CALCULATOR_INFO_STRING.BUTTONS.ADD_NEW_STAMP}</button>
          </Col>
        </Row>
        <h2 className='h2Title'>{CALCULATOR_INFO_STRING.STAMP_DUTY.STAMP_DUTIES_LABEL}</h2>
        <Row className='mt-3'>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              columns={columns}
              expandable={{
                expandedRowRender,
                defaultExpandAllRows: true,
                showExpandColumn: false,
                rowExpandable: false,
                bordered: false,
              }}
              dataSource={States}
              scroll={{ x: 400 }}
              pagination={false}
            />
          </Col>
        </Row>
        <DeleteModel
          isDeleteModalVisible={isDeleteModalVisible}
          deleteModel={deleteStampDuty}
          setIsDeleteModalVisible={setIsDeleteModalVisible}
        />
      </div>
    );
};

StampDutyManagement.propTypes = {
  permissions: PropTypes.any
};


export default StampDutyManagement;
