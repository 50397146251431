import { methods, serviceMaker } from '../service';

const FIND_PAGE_MODULES = (id) => serviceMaker(`page-modules/${id}`, methods.GET);

const GET_PAGE_MODULES = (
  limit,
  skip,
  search = null,
  sort = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/page-modules`, methods.GET, query);
  }

  return serviceMaker(`page-modules`, methods.GET, query);
};

const DELETE_PAGE_MODULES = (id) => serviceMaker(`page-modules/${id}`, methods.DELETE);

const CREATE_PAGE_MODULES = (payload) =>
  serviceMaker(`page-modules`, methods.POST, null, payload);

const PATCH_PAGE_MODULES = (id, payload) =>
  serviceMaker(`page-modules/${id}`, methods.PATCH, null, payload);

const PAGE_MODULES = {
  FIND: FIND_PAGE_MODULES,
  GET: GET_PAGE_MODULES,
  CREATE: CREATE_PAGE_MODULES,
  PATCH: PATCH_PAGE_MODULES,
  DELETE: DELETE_PAGE_MODULES
};

export { PAGE_MODULES };
