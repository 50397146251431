import { Col, Image, Input, Row, Select, Table, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import PaginationCom from '../../components/PaginationCom';
import { tableSortIconToggle } from '../../utility/tableSorting';
import DeleteModel from '../../components/DeleteModel';
import { toast } from 'react-toastify';
import PencileIcon from '../../components/Icon/PencileIcon';
import DeleteIcon from '../../components/Icon/DeleteIcon';
import { useNavigate } from 'react-router-dom';
import { EXTERNAL_SERVICE } from '../../services/ExternalServices/ExternalServices';
import { COMMON_ALERTS, COMMON_STRING, EXTERNAL_SERVICE_STRINGS, FILTER_STRING, TABLE_STRING } from '../../services/strings';
import SelectArrow from '../../components/Icon/SelectArrow';
import PropTypes from 'prop-types';
import FallbackImage from '../../components/Icon/FallbackImage';
import FilterToogleBtn from '../../components/FilterToogleBtn';
import { BASE_URL } from '../../services/constant';
import { DROPDOWN } from '../../services/Dropdown/dropdowns';

const ExternalService = ({ permissions }) => {
  const navigate = useNavigate();
  const searchInp = useRef();
  const [Services, setService] = useState(null);
  const [tableLoading, setTableLoading] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [sort, setSort] = useState(null);
  const [tableDataLength, setTableDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [CountriesMenu, setCountriesMenu] = useState([{ id: "", name: "Country" }]);
  const [StatesMenu, setStatesMenu] = useState([{ id: "", name: "State" }]);
  const [SuburbsMenu, setSuburbsMenu] = useState([{ id: "", name: "Suburbs" }]);
  const [CitiesMenu, setCitiesMenu] = useState([{ id: "", name: "City" }]);
  const [ServicesTypeMenu, setServicesTypeMenu] = useState([]);
  const [filterCountry, setFilterCountry] = useState(null);
  const [filterState, setFilterState] = useState(null);
  const [filterCity, setFilterCity] = useState(null);
  const [filterSuburbs, setFilterSuburbs] = useState(null);
  const [filterServiceType, setFilterServiceType] = useState(null);
  const [filtePlatform, setFiltePlatform] = useState(null);
  const [filterAvailability, setFilterAvailability] = useState(null);
  const [isFilterShow, setIsFilterShow] = useState(false);

  const fetchCountries = async () => {
    const countriesData = await DROPDOWN.GET_COUNTRIES();
    if (countriesData.status && Array.isArray(countriesData.data)) {
      setCountriesMenu([...CountriesMenu, ...countriesData.data]);
    }
  }

  const fetchStates = async () => {
    const statesData = await DROPDOWN.GET_STATES();
    if (statesData.status && Array.isArray(statesData.data)) {
      setStatesMenu(statesData.data);
    }
  }

  const fetchSuburbs = async () => {
    const suburbsData = await DROPDOWN.GET_SUBURBS();
    if (suburbsData.status && Array.isArray(suburbsData.data)) {
      setSuburbsMenu(suburbsData.data);
    }
  }

  const fetchCities = async () => {
    const citiesData = await DROPDOWN.GET_CITY();
    if (citiesData.status && Array.isArray(citiesData.data)) {
      setCitiesMenu(citiesData.data);
    }
  }

  const fetchServicesTypes = async () => {
    const servicesTypes = await DROPDOWN.GET_SERVICE_TYPES();
    if (servicesTypes.status && Array.isArray(servicesTypes.data)) {
      setServicesTypeMenu(servicesTypes.data);
    }
  }

  const PlatformMenu = ['Android', 'iOS', 'Web'];

  useEffect(() => {
    fetchCountries();
    fetchStates();
    fetchSuburbs();
    fetchCities();
    fetchServicesTypes();
  }, []);

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const deleteService = async () => {
    try {
      const res = await EXTERNAL_SERVICE.DELETE(deleteId);
      if (res.status) {
        toast.success(COMMON_ALERTS.DELETED_SUCCESS('Service'));
        setIsDeleteModalVisible(false);
        fetchService(currentPage, pageSize, search, sort);
      }
    }
    catch (e) {
      // console.log(e);
    }
  }

  const fetchService = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    countryId = null,
    stateId = null,
    cityId = null,
    suburbsId = null,
    serviceType = null,
    platform = null,
    availability = null,
  ) => {
    setTableLoading(true);
    try {
      const res = await EXTERNAL_SERVICE.GET(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        countryId,
        stateId,
        suburbsId,
        cityId,
        serviceType,
        platform,
        availability
      );
      if (res.status === 1 || res.status === '1') {
        setService(res);
        setTableDataLength(res?.data && res?.data.length);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setTableLoading(false);
    }
  };

  const handleChangeOfSearch = (e) => {
    setTempSearch(e.target.value);
    if (!e.target.value) {
      setCurrentPage(1);
      setSearch(null);
      setTempSearch(null);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  const ProfileImg = (props) => {
    return (<div className='profileImgInList'>
      <Image fallback={FallbackImage} src={props.src} />
    </div>);
  }

  ProfileImg.propTypes = {
    src: PropTypes.string
  }

  useEffect(() => {
    fetchService(currentPage, pageSize, search, sort, filterCountry, filterState, filterCity, filterSuburbs, filterServiceType, filtePlatform, filterAvailability);
  }, [currentPage, pageSize, search, sort, filterCountry, filterState, filterCity, filterSuburbs, filterServiceType, filtePlatform, filterAvailability]);

  const columns = [
    {
      title: (column) => <span className="tableTitle">{EXTERNAL_SERVICE_STRINGS.TABLE.VENDOR_PROFILE}</span>,
      dataIndex: 'vendorProfile',
      width: 140,
      key: 'name',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => value ? <ProfileImg src={BASE_URL + value?.image?.slice(1)} /> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{EXTERNAL_SERVICE_STRINGS.TABLE.VENDOR_NAME}</span>, 'name', column),
      dataIndex: 'venderName',
      key: 'name',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{EXTERNAL_SERVICE_STRINGS.TABLE.BUSINESS_NAME}</span>, 'businessName', column),
      dataIndex: 'businessName',
      key: 'businessName',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{EXTERNAL_SERVICE_STRINGS.TABLE.SERVICE_TYPE}</span>, 'service_type', column),
      dataIndex: 'service_type',
      key: 'service_type',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.name.charAt(0).toUpperCase() + value.name.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{EXTERNAL_SERVICE_STRINGS.TABLE.CONTACT}</span>, 'contactno', column),
      dataIndex: 'phoneNumber',
      key: 'contactno',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">+{value}</span> : '-',
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      width: 150,
      key: 'Actions',
      align: 'center',
      dataIndex: 'Actions',
      render: (item, record) => {
        return (
          <div className='actionCol'>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit External Service'>
                <PencileIcon
                  onClick={() => navigate('/external_Service/edit', { state: { serviceId: record.id } })}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
              </Tooltip>
            )}
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete External Service'>
                <DeleteIcon
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className='commonWrapperLayout'>
      <div className="headerContain">
        <div className="searchBox">
          <label className='searchInpLabel' htmlFor={searchInp}>{FILTER_STRING.SEARCH}</label>
          <Input
            ref={searchInp}
            type='search'
            placeholder={COMMON_STRING.SEARCH_STR + 'Service'}
            className='input-control searchInpExpense'
            value={tempSearch}
            allowClear
            onChange={(e) => handleChangeOfSearch(e)}
            onKeyPress={(e) => {
              if (e?.key === 'Enter' && e.target.value) {
                handleSearch();
              }
            }}
          />
        </div>
        <div className="descheduleBtnBox">
          <button onClick={() => navigate('/external_Service/add')} className="addDepScheduleBtn">{EXTERNAL_SERVICE_STRINGS.ADD_BTN}</button>
          <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} />
        </div>
      </div>
      <Row className={isFilterShow ? 'mt-3' : 'd-none'}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="filterSelectBox filterCard">
          <div className="filterSelect">
            <Select defaultValue={FILTER_STRING.COUNTRY} onChange={value => setFilterCountry(value)} suffixIcon={<SelectArrow style={{ color: "#324666" }} />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{CountriesMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
          </div>
          <div className="filterSelect">
            <Select defaultValue={FILTER_STRING.STATE} onChange={value => setFilterState(value)} suffixIcon={<SelectArrow style={{ color: "#324666" }} />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{StatesMenu.map((state, key) => <Select.Option key={key} value={state.id}>{state.name}</Select.Option>)}</Select>
          </div>
          <div className="filterSelect">
            <Select defaultValue={FILTER_STRING.SUBURBS} onChange={value => setFilterSuburbs(value)} suffixIcon={<SelectArrow style={{ color: "#324666" }} />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{SuburbsMenu.map((suburb, key) => <Select.Option key={key} value={suburb.id}>{suburb.name}</Select.Option>)}</Select>
          </div>
          <div className="filterSelect">
            <Select defaultValue={FILTER_STRING.CITY} onChange={value => setFilterCity(value)} suffixIcon={<SelectArrow style={{ color: "#324666" }} />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{CitiesMenu.map((city, key) => <Select.Option key={key} value={city.id}>{city.name}</Select.Option>)}</Select>
          </div>
          <div className="filterSelect">
            <Select defaultValue={EXTERNAL_SERVICE_STRINGS.FILTER.PLATFORM} onChange={value => setFiltePlatform(value)} suffixIcon={<SelectArrow style={{ color: "#324666" }} />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option key={'default'} value="">Platform</Select.Option>{PlatformMenu.map((platform, key) => <Select.Option key={key} value={platform}>{platform}</Select.Option>)}</Select>
          </div>
          <div className="filterSelect">
            <Select defaultValue={EXTERNAL_SERVICE_STRINGS.FILTER.SERVICE_TYPE} onChange={value => setFilterServiceType(value)} suffixIcon={<SelectArrow style={{ color: "#324666" }} />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option key={'default'} value="">{EXTERNAL_SERVICE_STRINGS.FILTER.SERVICE_TYPE}</Select.Option>{ServicesTypeMenu.map((service, key) => <Select.Option key={key} value={service.id}>{service.name}</Select.Option>)}</Select>
          </div>
          <div className="filterSelect">
            <Select defaultValue={EXTERNAL_SERVICE_STRINGS.FILTER.AVAILABLITY} onChange={value => setFilterAvailability(value)} suffixIcon={<SelectArrow style={{ color: "#324666" }} />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><option value={null}>Availidity</option><option value={true}>Availible</option><option value={false}>Unavailable</option></Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-3">
          <Table
            columns={columns}
            dataSource={Services?.data}
            pagination={false}
            rowKey='id'
            loading={tableLoading}
            onChange={onChange}
            scroll={{ x: 400 }}
          />

          {Services?.total > 10 && (
            <div className='text-end bg-white mt-7'>
              <PaginationCom
                onChange={onChangePage}
                showSizeChanger={true}
                current={currentPage}
                showLessItems={true}
                total={Services?.total}
              />
            </div>
          )}
        </Col>
      </Row>
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteService}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </div>
  );
}

ExternalService.propTypes = {
  permissions: PropTypes.any,
}

export default ExternalService;
