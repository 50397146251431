import React, { useEffect, useState } from 'react';
import { Col, Row, Table, Tooltip } from 'antd';
import PaginationCom from '../../components/PaginationCom';
import { tableSortIconToggle } from '../../utility/tableSorting';
import { REDEEM_REWARDS_STRINGS, TABLE_STRING } from '../../services/strings';
import { REDEEM_HISTORY } from '../../services/RedeemRewards/RedeemHistory';
import moment from 'moment';
import { toast } from 'react-toastify';
import ApproveIcon from '../../components/Icon/ApproveIcon';
import DiscardIcon from '../../components/Icon/DiscardIcon';
import ApprovedIcon from '../../components/Icon/ApprovedIcon';
import DiscardedIcon from '../../components/Icon/DiscardedIcon';
import CommentModal from '../../components/Modal/CommentModal';

function RedeemHistoryPage() {
    const [redeemHistories, setRedeemHistories] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [tableLoading, setTableLoading] = useState(true)
    const [pageSize, setPageSize] = useState(10);
    const [sort, setSort] = useState(null);
    const [isCommentModelOpen, setIsCommentModalOpen] = useState(false);
    const [confirmId, setconfirmId] = useState(null);
    const [CancelId, setCancelId] = useState(null);

    const onChangePage = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const approveRedeemed = (id) => { setconfirmId(id); setIsCommentModalOpen(true); };
    const discardRedeemed = (id) => { setCancelId(id); setIsCommentModalOpen(true); };

    const confirmCancelAvail = async (type, id, comment) => {
        const res = await REDEEM_HISTORY.PATCH(id, {
            progressStatus: type,
            comment
        });
        if (res.status) { 
            toast.success(`Redeemed service has been successfully ${type}!`);
            fetchHistory(currentPage, pageSize, null, sort);
        }
        else {
            toast.error(res.message);
        }
    }

    const onChange = (pagination, filters, sorter, extra) => {
        if (sorter?.order === 'ascend') {
            setSort({ [sorter.field]: 1 });
        } else if (sorter?.order === 'descend') {
            setSort({ [sorter.field]: -1 });
        } else {
            setSort({ createdAt: -1 });
        }
    };

    const fetchHistory = async (page, pageSize, search, sort) => {
        setTableLoading(true);
        const histories = await REDEEM_HISTORY.FIND(
            pageSize,
            (page - 1) * pageSize,
            search,
            sort
        );
        if (histories.status && Array.isArray(histories.data)) {
            setRedeemHistories(histories);
        }
        setTableLoading(false);
    }

    const onSubmitComment = (comment) => {
        if (confirmId) {
            confirmCancelAvail('completed', confirmId, comment);
        }
        else if (CancelId) {
            confirmCancelAvail('discarded', CancelId, comment);
        }
    }

    useEffect(() => {
        fetchHistory(currentPage, pageSize, null, sort);
    }, [currentPage, pageSize, null, sort]);

    const columns = [
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REDEEM_REWARDS_STRINGS.REDEEMED.TABLE.USERNAME}</span>, 'username', column),
            dataIndex: 'appuser',
            key: 'appuser',
            align: 'left',
            sorter: redeemHistories?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            width: 200,
            render: (value) =>
                value ? <span className="tabletext">{value.name}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REDEEM_REWARDS_STRINGS.REDEEMED.TABLE.UID}</span>, 'userid', column),
            dataIndex: 'appuser',
            key: 'userid',
            align: 'left',
            sorter: redeemHistories?.total > 1 ? true : false,
            width: 200,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{'+' + value?.countryCode + value?.phoneNumber}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REDEEM_REWARDS_STRINGS.REDEEMED.TABLE.PROPERTY_LIST}</span>, 'property', column),
            dataIndex: 'property',
            key: 'property',
            align: 'left',
            width: 200,
            sorter: redeemHistories?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{value?.name}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REDEEM_REWARDS_STRINGS.REDEEMED.TABLE.SERVICE_CATEGORY}</span>, 'service_category', column),
            dataIndex: 'redeem_service',
            key: 'service_category',
            align: 'left',
            width: 300,
            sorter: redeemHistories?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{value?.category?.name}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REDEEM_REWARDS_STRINGS.REDEEMED.TABLE.SERVICE_NAME}</span>, 'redeem_service', column),
            dataIndex: 'redeem_service',
            key: 'redeem_service',
            align: 'left',
            width: 400,
            sorter: redeemHistories?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{value?.name}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REDEEM_REWARDS_STRINGS.REDEEMED.TABLE.QTY}</span>, 'quantity', column),
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'left',
            width: 100,
            sorter: redeemHistories?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => <span className="tabletext">{value}</span>,
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REDEEM_REWARDS_STRINGS.REDEEMED.TABLE.PD_AMOUNT}</span>, 'username', column),
            dataIndex: 'pdAmount',
            key: 'pdAmount',
            align: 'left',
            width: 170,
            sorter: redeemHistories?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => <span className="tabletext">{value}</span>,
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REDEEM_REWARDS_STRINGS.REDEEMED.TABLE.REDEEM_DATE}</span>, 'redeem_date', column),
            dataIndex: 'redeemedAt',
            key: 'redeem_date',
            align: 'left',
            width: 200,
            sorter: redeemHistories?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{moment(value).format('DD/MM/YYYY')}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REDEEM_REWARDS_STRINGS.REDEEMED.TABLE.EXPIRY_DATE}</span>, 'expiry_date', column),
            dataIndex: 'redeem_service',
            key: 'expiry_date',
            align: 'left',
            width: 200,
            sorter: redeemHistories?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value?.expiryDate ? <span className="tabletext">{moment(value?.expiryDate).format('DD/MM/YYYY')}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REDEEM_REWARDS_STRINGS.REDEEMED.TABLE.ACTION_BY}</span>, 'actionby', column),
            dataIndex: 'actionBy',
            key: 'actionBy',
            align: 'center',
            width: 200,
            sorter: redeemHistories?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{value?.firstname + ' ' + value?.lastname}</span> : '--',
        },
        {
            title: (column) => <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            sorter: false,
            fixed: 'right',
            width: 100,
            render: (value, record) => {
                return (
                    <div className='actionCol'>
                        {record.progressStatus === 'pending' &&
                            (<>
                                <ApproveIcon onClick={() => approveRedeemed(record.id)} />
                                <DiscardIcon onClick={() => discardRedeemed(record.id)} />
                            </>)
                        }
                        {record.progressStatus === 'completed' && <Tooltip title='Approved'><ApprovedIcon /></Tooltip>}
                        {record.progressStatus === 'discarded' && <Tooltip title='Discarded'><DiscardedIcon /></Tooltip>}
                    </div>
                );
            }
        }
    ];

    return (
        <div>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table
                        columns={columns}
                        dataSource={redeemHistories?.data}
                        pagination={false}
                        rowKey='id'
                        loading={tableLoading}
                        onChange={onChange}
                        scroll={{ x: 2000 }}
                    />

                    {redeemHistories?.total > 10 && (
                        <div className='text-end bg-white mt-5'>
                            <PaginationCom
                                onChange={onChangePage}
                                showSizeChanger={true}
                                current={currentPage}
                                showLessItems={true}
                                total={redeemHistories?.total}
                            />
                        </div>
                    )}
                </Col>
            </Row>
            <CommentModal isOpen={isCommentModelOpen} setIsOpen={setIsCommentModalOpen} onSubmit={onSubmitComment} />
        </div>
    );
}

export default RedeemHistoryPage;