import { Col, Row, Tooltip } from 'antd';
import React, { useState } from 'react';
import LinkIcon from '../Icon/LinkIcon';
import PencileIcon from '../Icon/PencileIcon';
import DeleteIcon from '../Icon/DeleteIcon';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import BulletIcon from '../Icon/BulletIcon';

const StoryTile = ({ data, setIsDeleteModalVisible, setDeleteId, permissions}) => {
    const navigate = useNavigate();
    const deleteLis = () => {  //Delete Listener
        setIsDeleteModalVisible(true);
        setDeleteId(data.id);
    }

    return (
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className='newsArticleItemLayout'>
                    <div className="bulletBox">
                        <BulletIcon />
                    </div>
                    <div className="titleNDscRow">
                        <div className='fColumn'>
                            <div>
                                <div className="titleBox">
                                    <span className="title">{data.headline}</span>
                                </div>
                                <div className="details" dangerouslySetInnerHTML={{ __html: data.desc }} />
                            </div>
                            <div className="actionBtnBox">
                                {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
                                    <div className="editBtn" onClick={() => navigate('/top-stories/edit', { state: { dStory: data } })}><Tooltip title={'Edit ' + data.type}><PencileIcon /></Tooltip></div>
                                )}
                                {(permissions?.isDelete || permissions?.isDelete === undefined) && (
                                    <div className="deleteBtn" onClick={() => deleteLis()}><Tooltip title={'Delete ' + data.type}><DeleteIcon /></Tooltip></div>
                                )}
                            </div>
                        </div>
                        <div className="dateLinkBox">
                            <div className="dateTimeText">{moment(data.addedOn).format('DD/MM/YYYY hh:mm A')}</div>
                            <div className="linkText" onClick={() => window.open(data.link, '_blank')}><LinkIcon /> <span>{data.link}</span></div>
                        </div>
                    </div>

                </div>
            </Col>
        </Row>
    );
}

StoryTile.propTypes = {
    data: PropTypes.object,
    setIsDeleteModalVisible: PropTypes.func,
    setDeleteId: PropTypes.func,
    permissions: PropTypes.any
};

export default StoryTile;
