import { Col, Empty, Radio, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteModel from '../../components/DeleteModel';
import NewAndArticles from '../../components/NewsAndArticles/NewAndArticles';
import PaginationCom from '../../components/PaginationCom';
import { NEWSARTICLES } from '../../services/NewsDetails/newsDetailsService';
import PropTypes from 'prop-types';
import { COMMON_ALERTS, NEWS_ARTICLES_STRING } from '../../services/strings';
import LeftNavIcon from '../../components/Icon/LeftNavIcon';
import AppButton from '../../components/AppButton';
import CheckBoxFilled from '../../components/Icon/CheckBoxFilled';

const NewsListPage = ({ permissions }) => {
    const navigate = useNavigate();
    const [NewsArticles, setNewsArticles] = useState(null);
    const [currentPage, setcurrentPage] = useState(1);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSelectable, setIsSelectable] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const maxSelectionLimit = 10;
    // const selectedAuto = useLocation().state;

    const fetchNewsArticles = async (currpg) => {
        const newsArticles = await NEWSARTICLES.GET(null, ((currpg - 1) * 10), null, null, 'news');
        if (Array.isArray(newsArticles.data)) {
            setNewsArticles(newsArticles);
            setIsLoaded(true);
        }
    }

    const onChangePage = (e) => {
        setcurrentPage(e);
        fetchNewsArticles(e);
    }

    const deleteNewsArticle = async () => {
        const res = await NEWSARTICLES.DELETE(deleteId);
        if (res.status) {
            toast.success(COMMON_ALERTS.DELETED_SUCCESS('News'));
            fetchNewsArticles(currentPage);
            setIsDeleteModalVisible(false);
        }
        else {
            toast.error('Something wrong happened.');
        }
    }

    const setSelection = (id) => {
        const Ids = selectedIds;
        if (Ids.includes(id)) {
            Ids.splice(Ids.indexOf(id), 1);
        }
        else {
            if (Ids.length < maxSelectionLimit) {
                Ids.push(id);
            }
            else {
                toast.error(`You cannot select more than ${maxSelectionLimit} news`);
            }
        }
        setSelectedIds([...Ids]);
    }

    const bulkAction = async (action) => {
        if(selectedIds.length){
            const res = await NEWSARTICLES.PATCH(action, {ids: selectedIds});
            if (res.status) {
                if(action === 'topNews'){
                    toast.success(COMMON_ALERTS.ADDED_SUCCESS('Top news'));
                }
                else{
                    fetchNewsArticles(1);
                    toast.success(COMMON_ALERTS.DELETED_SUCCESS('News'));
                }
                setSelectedIds([]);
            }
        }
        else{
            toast.error('Please select atleast one news');
        }
    }

    useEffect(() => { fetchNewsArticles(1); }, []);

    return (
        <div className='commonWrapperLayout'>
            <div className="backNavHeader">
                <div className="backNavText" onClick={() => navigate(-1)}>
                    <LeftNavIcon />
                    <span>News</span>
                </div>
                <AppButton text='Add New News/Article' onClick={() => navigate('/news_articles/add')} />
            </div>
            <div className="newsSelectionBlock">
                <div className="newsSelectionChbx">
                    {isSelectable && <><CheckBoxFilled /><span>{selectedIds.length} Selected</span></>}
                </div>
                <div className="actionBtn">
                    {isSelectable ? <span onClick={() => setIsSelectable(false)}>Cancel</span> : <span onClick={() => setIsSelectable(true)}>Bulk Action</span>}
                </div>
            </div>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='newsArticleListWrapper innerScrollNews'>
                    {
                        isLoaded && NewsArticles?.data?.length ?
                            NewsArticles?.data.map((news, key) =>
                                <NewAndArticles
                                    key={key}
                                    data={news}
                                    isSelected={selectedIds.includes(news.id)}
                                    setSelection={setSelection}
                                    showCheck={isSelectable}
                                    setIsDeleteModalVisible={setIsDeleteModalVisible}
                                    setDeleteId={setDeleteId}
                                    permissions={permissions} />) :
                            <Empty description={"No single news found"} />
                    }
                </Col>
            </Row>
            {isSelectable  && <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='align_flex_right'>
                    <div className="newsActionBtnBox">
                        <AppButton text='Add to Top 10 News of The Day' onClick={()=> bulkAction('topNews')}/>
                        <AppButton text='Delete' style={{backgroundColor: '#F44840'}}  onClick={()=> bulkAction('bulkDelete')}/>
                    </div>
                </Col>
            </Row>}
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {NewsArticles?.total > 10 && (
                        <div className='text-end bg-white mt-7'>
                            <PaginationCom
                                onChange={onChangePage}
                                showSizeChanger={false}
                                current={currentPage}
                                showLessItems={true}
                                total={NewsArticles?.total}
                            />
                        </div>
                    )}
                </Col>
            </Row>

            <DeleteModel
                isDeleteModalVisible={isDeleteModalVisible}
                deleteModel={deleteNewsArticle}
                setIsDeleteModalVisible={setIsDeleteModalVisible}
            />
        </div>
    );
}

NewsListPage.propTypes = {
    permissions: PropTypes.any
};

export default NewsListPage;
