import React from 'react';
import PropTypes from 'prop-types';
import FilterIcon from './Icon/FilterIcon';
import { COMMON_STRING } from '../services/strings';

const FilterToogleBtn = (props) => {
    return (<button {...props} className={props?.withmenu?'filterToogleBtn withmenu':'filterToogleBtn'}><FilterIcon/> {COMMON_STRING.FILTER_BTN}</button>);
}

FilterToogleBtn.propTypes = {
    withmenu: PropTypes.bool
}

export default FilterToogleBtn;