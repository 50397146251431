import { methods, serviceMaker } from '../service';

const FIND_AVM = (id) => serviceMaker(`avm/${id}`, methods.GET);

const GET_AVM = ({
  $limit,
  $skip,
  search,
  sort,
  country,
  state,
  suburb,
  propertyType,
  propertyValue
}) => {
  const query = { params: {} };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
} else {
    query['params']['$sort[createdAt]'] = -1;
}
query['params'] = {
    ...query['params'],
    $limit,
    $skip,
    search,
    country,
    state,
    suburb,
    propertyType
}

  return serviceMaker(`avm`, methods.GET, query);
};

const DELETE_AVM = (id) => serviceMaker(`avm/${id}`, methods.DELETE);

const CREATE_AVM = (payload) =>
  serviceMaker(`avm`, methods.POST, null, payload);

const PATCH_AVM = (id, payload) =>
  serviceMaker(`avm/${id}`, methods.PATCH, null, payload);

const AVM = {
  FIND: FIND_AVM,
  GET: GET_AVM,
  CREATE: CREATE_AVM,
  PATCH: PATCH_AVM,
  DELETE: DELETE_AVM
};

export { AVM };
