import { Col, Input, Row, Table, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import PaginationCom from '../../components/PaginationCom';
import { tableSortIconToggle } from '../../utility/tableSorting';
import DeleteModel from '../../components/DeleteModel';
import { toast } from 'react-toastify';
import PencileIcon from '../../components/Icon/PencileIcon';
import DeleteIcon from '../../components/Icon/DeleteIcon';
import { useNavigate } from 'react-router-dom';
import { ROLES } from '../../services/Roles/Roles';
import PropTypes from 'prop-types';
import { ADMIN_USER_STRINGS, COMMON_ALERTS, COMMON_STRING, FILTER_STRING, TABLE_STRING } from '../../services/strings';

const RolesPage = ({ permissions }) => {
  const navigate = useNavigate();
  const searchInp = useRef();
  const [Roles, setRoles] = useState(null);
  const [tableLoading, setTableLoading] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [sort, setSort] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      // setSort({ createdAt: -1 });
    }
  };

  const deleteRole = async () => {
    try {
      const res = await ROLES.DELETE(deleteId);
      if (res.status) {
        toast.success(COMMON_ALERTS.DELETED_SUCCESS('Role'));
        setIsDeleteModalVisible(false);
        fetchRoles(currentPage, pageSize, search, sort);
      }
    }
    catch (e) {
      // console.log(e);
    }
  }

  const fetchRoles = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null
  ) => {

    setTableLoading(true);
    try {
      const res = await ROLES.GET(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort
      );
      if (res.status === 1 || res.status === '1') {
        setRoles(res);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setTableLoading(false);
    }
  };

  const handleChangeOfSearch = (e) => {
    setTempSearch(e.target.value);
    if (!e.target.value) {
      setCurrentPage(1);
      setSearch(null);
      setTempSearch(null);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  useEffect(() => {
    fetchRoles(currentPage, pageSize, search, sort);
  }, [currentPage, pageSize, search, sort]);

  const columns = [
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{ADMIN_USER_STRINGS.TABLE.ROLE}</span>, 'name', column),
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{ADMIN_USER_STRINGS.TABLE.USERS}</span>, 'users_count', column),
      dataIndex: 'users_count',
      key: 'users_count',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{ADMIN_USER_STRINGS.TABLE.DESCRIPTION}</span>, 'desc', column),
      dataIndex: 'desc',
      key: 'desc',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      width: 150,
      key: 'Actions',
      align: 'center',
      dataIndex: 'Actions',
      render: (item, record) => {
        return (
          <div className='actionCol'>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit Role'>
                <PencileIcon
                  onClick={() => navigate('/admin-users/roles/edit', { state: { roleId: record.id } })}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
              </Tooltip>
            )}
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Role'>
                <DeleteIcon
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className='commonWrapperLayout'>
      <div className="headerContain">
        <div className="searchBox">
          <label className='searchInpLabel' htmlFor={searchInp}>{FILTER_STRING.SEARCH}</label>
          <Input
            ref={searchInp}
            type='search'
            placeholder={COMMON_STRING.SEARCH_STR + 'Role'}
            className='input-control searchInpExpense'
            value={tempSearch}
            allowClear
            onChange={(e) => handleChangeOfSearch(e)}
            onKeyPress={(e) => {
              if (e?.key === 'Enter' && e.target.value) {
                handleSearch();
              }
            }}
          />
        </div>
        <div className="descheduleBtnBox">
          <button onClick={() => navigate('/admin-users/roles/add')} className="addDepScheduleBtn">{ADMIN_USER_STRINGS.BUTTONS.CREATE_ROLE_BTN}</button>
        </div>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-3">
          <Table
            columns={columns}
            dataSource={Roles?.data}
            pagination={false}
            rowKey='id'
            loading={tableLoading}
            onChange={onChange}
            scroll={{ x: 400 }}
          />

          {Roles?.total > 10 && (
            <div className='text-end bg-white mt-7'>
              <PaginationCom
                onChange={onChangePage}
                showSizeChanger={true}
                current={currentPage}
                showLessItems={true}
                total={Roles?.total}
              />
            </div>
          )}
        </Col>
      </Row>
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteRole}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </div>
  );
}

RolesPage.propTypes = {
  permissions: PropTypes.any
}

export default RolesPage;
