import { methods, serviceMaker } from '../service';

const GET_ROLE = (id) => serviceMaker(`roles/${id}`, methods.GET);

const GET_ROLES = (
  limit,
  skip,
  search = null,
  sort = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    //   query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/roles`, methods.GET, query);
  }

  return serviceMaker(`roles`, methods.GET, query);
};

const DELETE_ROLE = (id) => serviceMaker(`roles/${id}`, methods.DELETE);

const CREATE_ROLE = (payload) =>
  serviceMaker(`roles`, methods.POST, null, payload);

const PATCH_ROLE = (id, payload) =>
  serviceMaker(`roles/${id}`, methods.PATCH, null, payload);

const ROLES = {
  FIND: GET_ROLE,
  GET: GET_ROLES,
  CREATE: CREATE_ROLE,
  PATCH: PATCH_ROLE,
  DELETE: DELETE_ROLE
};

export { ROLES };
