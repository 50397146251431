import React, { useEffect, useRef, useState } from 'react'
// import CKEditor from "react-ckeditor-component";
import PropTypes from 'prop-types';

// const heightVar = window.innerHeight - 190;
const toolbarConfig = {
  // height: heightVar,
  toolbar: 'Full',
  allowedContent: true,
  startupFocus: false,
  toolbarGroups: [
    // { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
    // { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
    // { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
    { name: 'forms', groups: ['forms'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
    { name: 'links', groups: ['links'] },
    { name: 'insert', groups: ['insert'] },
    { name: 'styles', groups: ['styles'] },
    { name: 'colors', groups: ['colors'] },
    { name: 'tools', groups: [ 'tools' ] },
    { name: 'others', groups: [ 'others' ] },
    // { name: 'about', groups: [ 'about' ] }
  ],
  // removeButtons: 'PasteFromWord,Image,Source,Save,NewPage,Preview,Templates,Cut,Copy,Paste,PasteText,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,Blockquote,CreateDiv,BidiLtr,BidiRtl,Link,Unlink,Anchor,Flash,Smiley,SpecialChar,PageBreak,Iframe,Styles,Maximize,ShowBlocks,About,Language',
  // removePlugins: 'elementspath',
};

CkEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any
};

function CkEditor({ onChange, value }) {
  const [content, setContent] = useState('');
  const editorRef = useRef()
  const [editorLoaded, setEditorLoaded] = useState(false)
  const { CKEditor, ClassicEditor } = editorRef.current || {}

  useEffect(() => {
    if (value !== content)
      setContent(value);
  }, [value]);

  useEffect(() => {
    editorRef.current = {
      // eslint-disable-next-line no-undef
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
      // eslint-disable-next-line no-undef
      ClassicEditor: require('@ckeditor/ckeditor5-build-classic')
    }
    setEditorLoaded(true);
  }, [])

  return editorLoaded ? (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={content}
        onChange={(_, editor) => {
          const data = editor.getData();
          onChange(data);
          setContent(data);
        }}
      />
    </div>
  ): (
    <div>Editor loading</div>
  )
}

export default CkEditor;



// export default class CkEditor extends Component {
//   constructor(props) {
//   super(props);
//   this.onChange = this.onChange.bind(this);
//   this.state = {
//     content: this.props.value,
//   }
// }

// onChange(evt) {
//   var newContent = evt.editor.getData();
//   this.props.onChange(newContent);
//   this.setState({
//     content: newContent
//   })
// }

//   render() {
//     return (
//       <div>
//         <CKEditor
//           activeClass="p10"
//           config={toolbarConfig}
//           content={this.state.content}
//           events={{
//             "blur": this.onBlur,
//             "afterPaste": this.afterPaste,
//             "change": this.onChange
//           }}
//         />
//       </div>
//     )
//   }
// }