import { Button, Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { COMMON_ALERTS, COMMON_STRING, SERVICE_SCHEDULE_STRING } from '../../services/strings';
import { SERVICES_TYPES } from '../../services/Services/ServicesTypes';

const AddEditServicesSchedule = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const serviceId = useLocation().state?.serviceId;
    const [Service, setService] = useState(null);
    const [isDataLoaded, setisDataLoaded] = useState(false);

    useEffect(() => {
        if (serviceId !== null) {
            fetchService();
        }
    }, []);

    async function fetchService() {
        if (serviceId) {
            const res = await SERVICES_TYPES.FIND(serviceId);
            if (res.status) {
                setService(res.data);
                setisDataLoaded(true);
            }
        }
    }

    const onFinish = async (form) => {
        const payload = {
            name: form.name,
            percentage: form.percentage
        }
        let res = null;
        if (!serviceId) res = await SERVICES_TYPES.CREATE(payload);
        else res = await SERVICES_TYPES.PATCH(serviceId, payload);

        try {
            if (res.status) {
                toast.success(!serviceId ? COMMON_ALERTS.ADDED_SUCCESS('Service type') : COMMON_ALERTS.UPDATED_SUCCESS('Service type'));
                navigate('/service_schedule', { replace: true });
            }
        }
        catch (e) {
            // console.log(e);
        }
    }

    if ((!serviceId) || isDataLoaded)
        return (
            <div>
                <div className="fWidthShowBox addNwDepScheduleContainer nationLayout">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <h2>{!serviceId ? SERVICE_SCHEDULE_STRING.ADD_NEW_SERVICE : SERVICE_SCHEDULE_STRING.EDIT_SERVICE}</h2>
                        </Col>
                    </Row>
                    <Form name="complex-form" className="addformLayout" form={form} onFinish={onFinish} labelCol={{ md: 10, lg: 8 }} wrapperCol={{ md: 14, lg: 16 }}>
                        <Form.Item
                            label={<span className='inputTitle'>{SERVICE_SCHEDULE_STRING.SERVICE_TYPE_NAME}</span>}
                            name="name"
                            colon={false}
                            initialValue={Service?.name}
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('scheduled service name') }]}>
                            <Input className='textInput' />
                        </Form.Item>
                        <Row>
                            <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} md={{ span: 16, offset: 4 }} lg={{ span: 16, offset: 8 }} xl={{ span: 16, offset: 8 }} className="btnBox">
                                <Button className="cancelBtn" onClick={() => navigate('/service_schedule', { replace: true })}>{COMMON_STRING.BACK_BTN}</Button>
                                <Button type="submit" htmlType='submit' className="addBtn">{!serviceId ? COMMON_STRING.CREATE_BTN : COMMON_STRING.UPDATE_BTN}</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>

            </div>
        );
}

export default AddEditServicesSchedule;
