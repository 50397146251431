import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppButton from '../AppButton';

CommentModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

function CommentModal({ isOpen, setIsOpen, onSubmit }) {
    const [isShowErr, setIsShowErr] = useState(false);
    const [comment, setComment] = useState('');
    const submitComment = () => {
        if (!comment) {
            setIsShowErr(true);
        }
        else {
            setComment('');
            setIsShowErr(false);
            setIsOpen(false);
            onSubmit(comment);
        }
    }
    const closeModal = (e) => {
        e.stopPropagation();
        setComment(''); 
        setIsShowErr(false);
        setIsOpen(false);
    }
    return (
        <div>
            <div className={`commentModalBackdrop ${isOpen && 'show'}`} onClick={closeModal}></div>
                <div className={`commentModal ${isOpen && 'show'}`}>
                    <div className="inputArea">
                        <label htmlFor="commentInput" className="inputTitle">Comment</label>
                        <div className='errInput'>
                            <input type="text" id="commentInput" className="inputField" value={comment} onChange={(e) => setComment(e.target.value)} />
                            <span className='err'>{isShowErr && "Comment is required !"}</span>
                        </div>
                    </div>

                    <div className="btnArea">
                        <button className="cancelBtnMulti" onClick={closeModal}>Cancel</button>
                        <AppButton text='Submit' onClick={submitComment} />
                    </div>
                </div>
            
        </div>
    );
}

export default CommentModal;