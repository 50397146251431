import { Col, Form, Input, Row, Select, Spin, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PencileIcon from '../../../../components/Icon/PencileIcon';
import SelectArrow from '../../../../components/Icon/SelectArrow';
import { CITY_STATE_STRINGS, COMMON_ALERTS, COMMON_STRING } from '../../../../services/strings';
import { CITIES } from '../../../../services/Locations/Cities';
import { COUNCILES } from '../../../../services/Locations/Council';
import { COUNTRIES } from '../../../../services/Locations/Countries';
import { STATES } from '../../../../services/Locations/States';
import { SUBURBS } from '../../../../services/Locations/Suburbs';
import { getLSItem } from '../../../../services/auth/encryptLogin';
import { BASE_URL } from '../../../../services/constant';

const AddEditCouncile = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const dcouncile = useLocation().state?.dcouncil;
  const [fileList, setFileList] = useState([]);
  const [uploadLink, setuploadLink] = useState('');
  const [MediaItemId, setMediaItemId] = useState(null);
  const [MediaType, setMediaType] = useState('');
  const [councilInpVal, setCouncilInpVal] = useState('');
  const accessToken = getLSItem("pdollar-token");
  const [Countries, setCountries] = useState([]);
  const [States, setStates] = useState([]);
  const [Cities, setCities] = useState([]);
  const [Suburbs, setSuburbs] = useState([]);

  const onFinish = async (e) => {
    let res;
    let msg = '';
    let payload = {
      suburbId: e.suburbId
    };

    if (MediaItemId) {
      if (MediaType === 'excel') payload["excelFileId"] = MediaItemId;
      if (MediaType === 'csv') payload["csvFileId"] = MediaItemId;
    }
    else payload["name"] = e.name;

    if (!dcouncile) {
      res = await COUNCILES.CREATE(payload);
      msg = COMMON_ALERTS.ADDED_SUCCESS('Council')
    }
    else {
      res = await COUNCILES.PATCH(dcouncile.id, payload);
      msg = COMMON_ALERTS.UPDATED_SUCCESS('Council');
    }
    if (res.status) {
      toast.success(msg);
      navigate('/city_state', { state: { tabState: 'Council' } })
    }
  }

  const uploadButton = (
    <div className='uploadImageBtn'>
      <div>
        <PencileIcon />
      </div>
      <span className='noFileText'>{COMMON_STRING.NO_FILE_CHOOSEN}</span>
    </div>
  );

  const fileProps = {
    name: "file",
    multiple: false,
    fileList: fileList,
    action: `${BASE_URL + uploadLink}`,
    style: {
      width: "127px",
      height: "127px"
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
    beforeUpload(file) {
      if (fileList.length > 1 || fileList.length === 1) {
        toast.warn(COMMON_ALERTS.FILE_EXISTS);
        return false;
      }
      const type =
        file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "text/csv";
      if (!type) {
        toast.error(COMMON_ALERTS.ONLY_ALLOWED_ERROR('CSV or Excel'));
      }

      if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        setMediaType('excel');
        setuploadLink('upload-excel')
      }
      else if (file.type === "text/csv") {
        setMediaType('csv');
        setuploadLink('upload-csv')
      }
      form.setFieldValue("name", null);
      return type || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.fileList.length === 1) {
        setFileList([...info.fileList]);
      }
      if (info.file.status === "done") {
        setMediaItemId(info.file.response?.data?.media.id);
      } else if (info.file.status === "error") {
        toast.error(COMMON_ALERTS.FILE_UPLOAD_FAILED(info.file.name));
      }
    },
    async onRemove() {
      setFileList([]);
      setMediaItemId(null);
    },
  };

  const fetchCountries = async () => {
    const countries = await COUNTRIES.GET();
    if (countries.status) {
      setCountries(countries.data);
      if (dcouncile && countries.data.length) {
        const states = await STATES.GET(-1, 0, null, null, dcouncile ? (dcouncile?.city?.state?.countryId) : (countries.data[0]?.id));
        if (states.status) {
          setStates(states.data);
          if (dcouncile && states.data.length) {
            const cities = await CITIES.GET(-1, 0, null, null, dcouncile ? dcouncile?.city?.stateId : states.data[0]?.id);
            if (cities.status)
              setCities(cities.data);
            if (dcouncile && cities.data.length) {
              const suburbs = await SUBURBS.GET(-1, 0, null, null, dcouncile ? dcouncile?.city?.state?.suburbId : cities.data[0]?.id);
              if (suburbs.status)
                setSuburbs(suburbs.data);
            }
            else {
              const suburbs = await SUBURBS.GET(-1, 0, null, null, null, dcouncile ? dcouncile?.city?.stateId : states.data[0]?.id);
              if (suburbs.status)
                setSuburbs(suburbs.data);
            }
          }
        }
      }
    }
    setIsLoading(false);
  }

  const changeCountry = async (countryID) => {
    form.setFieldsValue({ stateId: '' });
    form.setFieldsValue({ cityId: '' });
    form.setFieldsValue({ suburbId: '' });
    const states = await STATES.GET(-1, 0, null, null, countryID);
    if (states.status) {
      setStates(states.data);
    }
  }

  const changeState = async (stateID) => {
    form.setFieldsValue({ cityId: '' });
    form.setFieldsValue({ suburbId: '' });
    const cities = await CITIES.GET(-1, 0, null, null, stateID);
    if (cities.status && cities.data.length)
      setCities(cities.data);
    const suburbs = await SUBURBS.GET(-1, 0, null, null, null, stateID);
    if (suburbs.status && suburbs.data.length)
      setSuburbs(suburbs.data);
  }

  const changeCity = async (cityId) => {
    form.setFieldsValue({ suburbId: '' });
    const suburbs = await SUBURBS.GET(-1, 0, null, null, cityId);
    if (suburbs.status && suburbs.data.length)
      setSuburbs(suburbs.data);
  }

  const changeCouncilInp = (value) => {
    setCouncilInpVal(value);
    if (value && fileList.length) {
      setFileList([]);
      setMediaItemId(null);
    }
  }

  useEffect(() => {
    fetchCountries();
  }, []);

  const formInitialValue =
  {
    countryId: dcouncile ? dcouncile?.suburb?.city ? dcouncile?.suburb?.city?.state?.countryId : dcouncile?.suburb?.state?.countryId : null,
    stateId: dcouncile ? dcouncile?.suburb?.city ? dcouncile?.suburb?.city?.stateId : dcouncile?.suburb?.stateId : null,
    cityId: dcouncile ? dcouncile?.suburb?.cityId : null,
    suburbId: dcouncile ? dcouncile?.suburbId : '',
    name: dcouncile ? dcouncile?.name : ''
  };

  return isLoading ? (
    <div className="loader-container">
      <Spin size="large" />
    </div>
  ) : (
    <div className='addCityStateLayout'>
      <Row className='commonLayout'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="">
          <Form form={form} onFinish={onFinish} initialValues={formInitialValue} labelCol={{ span: 8 }} wrapperCol={{ span: 6 }} colon={false}>
            <Row className='fWidthShowBox' >
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name='countryId' label={<span className='input-label'>{CITY_STATE_STRINGS.SELECT_COUNTRY}</span>} rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('country') }]}>
                  <Select className="input-control" onChange={(e) => changeCountry(e)} suffixIcon={<SelectArrow />}>{Countries.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
                </Form.Item>
                <Form.Item name='stateId' label={<span className='input-label'>{CITY_STATE_STRINGS.SELECT_STATE}</span>} rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('state') }]}>
                  <Select className="input-control" onChange={e => changeState(e)} notFoundContent={COMMON_ALERTS.DATA_NOT_FOUND('states', 'country')} suffixIcon={<SelectArrow />}>{States.map((state, key) => <Select.Option key={key} value={state.id}>{state.name}</Select.Option>)}</Select>
                </Form.Item>
                <Form.Item name='cityId' label={<span className='input-label'>{CITY_STATE_STRINGS.SELECT_CITY}</span>}>
                  <Select className="input-control" onChange={e => changeCity(e)} notFoundContent={COMMON_ALERTS.DATA_NOT_FOUND('cities', 'state')} suffixIcon={<SelectArrow />}>{Cities.map((city, key) => <Select.Option key={key} value={city.id}>{city.name}</Select.Option>)}</Select>
                </Form.Item>
                <Form.Item name='suburbId' label={<span className='input-label'>{CITY_STATE_STRINGS.SELECT_SUBURBS}</span>} rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('suburb') }]}>
                  <Select className="input-control" notFoundContent={COMMON_ALERTS.DATA_NOT_FOUND('suburbs', 'city')} suffixIcon={<SelectArrow />}>{Suburbs.map((suburb, key) => <Select.Option key={key} value={suburb.id}>{suburb.name}</Select.Option>)}</Select>
                </Form.Item>
              </Col>
            </Row>
            <h2 className='h2title addStateTitle'>{dcouncile ? CITY_STATE_STRINGS.COUNCIL_PAGE.EDIT_LBL : CITY_STATE_STRINGS.COUNCIL_PAGE.ADD_LBL}</h2>
            <Row className='fWidthShowBox'>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="name" label={<span className='input-label'>{CITY_STATE_STRINGS.COUNCIL_PAGE.COUNCIL_NAME}</span>} rules={[{ required: !fileList.length, message: COMMON_ALERTS.REQUIRED_INPUT('council name') }]}>
                  <Input className="input-control" placeholder='Enter Manually Council' onChange={e => changeCouncilInp(e.target.value)} />
                </Form.Item>
                {!dcouncile && <><span className="orText">Or</span>
                  <Form.Item d label={<span className='input-label'>{CITY_STATE_STRINGS.UPLOAD_FILE}</span>}>
                    <Upload disabled={councilInpVal ? true : false} className='imageUploadInput' {...fileProps} >
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  </Form.Item></>}
                <Row>
                  <Col xs={24} sm={24} md={{ offset: 8, span: 16 }} lg={{ offset: 8, span: 16 }} xl={{ offset: 8, span: 16 }} className="btnBox">
                    <button type='button' className="backBtn" onClick={() => navigate('/city_state', { state: { tabState: 'Council' } })}>{COMMON_STRING.BACK_BTN}</button>
                    <button type='submit' className="createBtn">{dcouncile ? COMMON_STRING.UPDATE_BTN : COMMON_STRING.CREATE_BTN}</button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default AddEditCouncile;
