import { serviceMaker, methods } from '../service';

export const LOGIN = (payload) =>{
  payload['platform'] = navigator.platform;
  payload['userAgent'] = navigator.userAgent;
  return serviceMaker(`/authentication`, methods.POST, null, payload, {});
}
  

export const LOGOUT_API = () =>
  serviceMaker(`/logout/me`, methods.GET, null);
