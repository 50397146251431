import { Col, DatePicker, Form, Input, Row, Select, Table } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import FilterToogleBtn from '../../../components/FilterToogleBtn';
import CalenderDarkIcon from '../../../components/Icon/CalenderDarkIcon';
import SelectArrow from '../../../components/Icon/SelectArrow';
import { COMMON_STRING, FILTER_STRING, REPORTS_STRINGS } from '../../../services/strings';

const TaxLodgementsTabReports = () => {
  const [CountriesMenu, setCountriesMenu] = useState([]);
  const [isFilterShow, setIsFilterShow] = useState(false);

  const columns = [
    {
      title: <span className="tableTitle">Details</span>,
      dataIndex: 'name',
      key: 'name',
      render: text => <span className="tabletext">{text}</span>,
      fixed: 'left',
      width: 300,
    },
    {
      title: <span className="tableTitle">Property 1</span>,
      dataIndex: 'property1',
      key: 'property1',
      width: 150,
      render: text => <span className="tabletext">{text}</span>,
    },
    {
      title: <span className="tableTitle">Property 2</span>,
      dataIndex: 'otherP',
      key: 'property2',
      width: 150,
      render: text => <span className="tabletext">{text}</span>,
    },
    {
      title: <span className="tableTitle">Property 3</span>,
      dataIndex: 'otherP',
      key: 'property3',
      width: 150,
      render: text => <span className="tabletext">{text}</span>,
    },
    {
      title: <span className="tableTitle">Property 4</span>,
      dataIndex: 'otherP',
      key: 'property4',
      width: 150,
      render: text => <span className="tabletext">{text}</span>,
    },
    {
      title: <span className="tableTitle">Property 5</span>,
      dataIndex: 'otherP',
      key: 'property5',
      width: 150,
      render: text => <span className="tabletext">{text}</span>,
    },
    {
      title: <span className="tableTitle">Property 6</span>,
      dataIndex: 'otherP',
      key: 'property6',
      width: 150,
      render: text => <span className="tabletext">{text}</span>,
    },
    {
      title: <span className="tableTitle">Property 7</span>,
      dataIndex: 'otherP',
      key: 'property7',
      width: 150,
      render: text => <span className="tabletext">{text}</span>,
    },
    {
      title: <span className="tableTitle">Property 8</span>,
      dataIndex: 'otherP',
      key: 'property8',
      width: 150,
      render: text => <span className="tabletext">{text}</span>,
    },
    {
      title: <span className="tableTitle">Property 9</span>,
      dataIndex: 'otherP',
      key: 'property9',
      width: 150,
      render: text => <span className="tabletext">{text}</span>,
    },
    {
      title: <span className="tableTitle">Property 10</span>,
      dataIndex: 'otherP',
      key: 'property10',
      width: 150,
      render: text => <span className="tabletext">{text}</span>,
    },
  ];

  const data = [
    {
      key: '1',
      name: 'Purchase Price',
      property1: '$9625.05',
      otherP: ''
    },
    {
      key: '2',
      name: 'Percentage Owned',
      property1: '$395.05',
      otherP: ''
    },
    {
      key: '3',
      name: 'Date Purchased',
      property1: '$0.00',
      otherP: ''
    },
    {
      key: '4',
      name: 'Date Property started earning Rental income ',
      property1: '$10,992.10',
      otherP: ''
    },
    {
      key: '5',
      name: 'Number of days rented this year',
      property1: '$10,99.',
      otherP: ''
    },
    {
      key: '6',
      name: 'Council Rates',
      property1: '$10,992.10',
      otherP: ''
    },
    {
      key: '7',
      name: 'Water Rates',
      property1: '$10,992.10',
      otherP: ''
    },
    {
      key: '8',
      name: 'Body corporate/strata levies',
      property1: '$10,992.10',
      otherP: ''
    },
    {
      key: '9',
      name: 'Land Tax',
      property1: '$10,992.10',
      otherP: ''
    },
    {
      key: 'overralTotal',
      name: 'Total',
      property1: '$23,992.10',
      otherP: '$0.00',
    },
  ];

 
  return (
    <div className='userTabReportLayout'>
      <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} withmenu={true} />
      <Row className={isFilterShow ? 'filterBoxLayout mb-3' : 'filterBoxLayout d-none'}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label={<span className="inputTitle">{FILTER_STRING.START_DATE}</span>}
                colon={false}
                initialValue={moment().date()}
              >
                <DatePicker className='w-100 inputField' placeholder="" suffixIcon={<CalenderDarkIcon />} />
              </Form.Item>
              <Form.Item
                label={<span className="inputTitle">{FILTER_STRING.END_DATE}</span>}
                colon={false}
              >
                <DatePicker className='w-100 inputField' placeholder="" suffixIcon={<CalenderDarkIcon />} />
              </Form.Item>
              <Form.Item
                label={<span className="inputTitle">{FILTER_STRING.SEARCH}</span>}
                colon={false}
              >
                <Input placeholder={COMMON_STRING.SEARCH_STR + 'Tax Lodgement'} className='inputField searchField' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className={'dropdownBox'}>
              <div className="filterSelect">
                <Select defaultValue="Select Property" suffixIcon={<SelectArrow/>} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{CountriesMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue="Year" suffixIcon={<SelectArrow/>} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{CountriesMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
              </div>
              <div className="exportBox">
                <div className="exportTitle">Export</div>
                <div className="exportBtn">{REPORTS_STRINGS.BUTTONS.EXPORT_CSV}</div>
                <div className="exportBtn">{REPORTS_STRINGS.BUTTONS.EXPORT_EXCEL}</div>
                <div className="exportBtn">{REPORTS_STRINGS.BUTTONS.EXPORT_PDF}</div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            columns={columns}
            dataSource={data}
            scroll={{ x: 1500 }}
            pagination={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TaxLodgementsTabReports;
