import { methods, serviceMaker } from '../service';

const GET_EXTERNALSERVICE = (id) => serviceMaker(`external-services/${id}`, methods.GET);

const GET_EXTERNALSERVICES = (
  limit,
  skip,
  search = null,
  sort = null,
  countryId = null,
  stateId = null,
  suburbId = null,
  cityId = null,
  serviceType = null,
  platform = null,
  availability = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }

  if (countryId) {
    query['params']['countryId'] = countryId;
  }

  if (stateId) {
    query['params']['stateId'] = stateId;
  }

  if (suburbId) {
    query['params']['suburbId'] = suburbId;
  }

  if (cityId) {
    query['params']['cityId'] = cityId;
  }

  if (platform) {
    query['params']['platform'] = platform;
  }

  if (serviceType) {
    query['params']['serviceTypeId'] = serviceType;
  }

  if (availability != null) {
    query['params']['availability'] = availability;
  }

  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/external-services`, methods.GET, query);
  }

  return serviceMaker(`external-services`, methods.GET, query);
};

const DELETE_EXTERNALSERVICE = (id) => serviceMaker(`external-services/${id}`, methods.DELETE);

const CREATE_EXTERNALSERVICE = (payload) =>
  serviceMaker(`external-services`, methods.POST, null, payload);

const PATCH_EXTERNALSERVICE = (id, payload) =>
  serviceMaker(`external-services/${id}`, methods.PATCH, null, payload);

const EXTERNAL_SERVICE = {
  FIND: GET_EXTERNALSERVICE,
  GET: GET_EXTERNALSERVICES,
  CREATE: CREATE_EXTERNALSERVICE,
  PATCH: PATCH_EXTERNALSERVICE,
  DELETE: DELETE_EXTERNALSERVICE
};

export { EXTERNAL_SERVICE };
