import { Button, Col, Form, Input, InputNumber, Row, Select, Space, Switch, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PencileIcon from '../../components/Icon/PencileIcon';
import SelectArrow from '../../components/Icon/SelectArrow';
import {  COMMON_ALERTS, COMMON_STRING, CONTACT_DETAILS_STRINGS, USER_DETAILS_STRINGS } from '../../services/strings';
import { CONTACT_DETAILS } from '../../services/ContactDetails/ContactDetails';
import { getLSItem } from '../../services/auth/encryptLogin';
import { BASE_URL } from '../../services/constant';
import { SERVICES_TYPES } from '../../services/Services/ServicesTypes';

const AddEditContactDetails = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [MediaItemId, setMediaItemId] = useState(null);
  const accessToken = getLSItem("pdollar-token");
  const contactItemId = useLocation().state?.contactItemId;
  const [isChecked, setisChecked] = useState(false);
  const [Professions, setProfessions] = useState([]);
  const [isSending, setIsSending] = useState(false);

  const onFinish = async (e) => {
    // setIsSending(true);
    if (MediaItemId === null)
      toast.error(COMMON_ALERTS.SELECT_IMAGE_FIRST);
    const payload = {
      name: e.cname,
      email: e.email,
      contact_mobile: e.Contactno,
      professionTypeId: e.Profession,
      mediaItemId: MediaItemId,
      status: e?.activeStatus ? 'active' : 'inactive',
      addedBy: 'admin'
    };
    let res = null;
    let msg = '';
    if (!contactItemId) {
      res = await CONTACT_DETAILS.CREATE(payload);
      msg = COMMON_ALERTS.ADDED_SUCCESS('Contact');
    }
    else {
      res = await CONTACT_DETAILS.PATCH(contactItemId, payload);
      msg = COMMON_ALERTS.UPDATED_SUCCESS('Contact');
    }
    if (res.status) {
      toast.success(msg)
      navigate('/contacts');
    }
    else{
      toast.error(res?.message);
    }
    // setIsSending(false);
  }


  const uploadButton = (
    <div className='uploadImageBtn'>
      <div>
        <PencileIcon />
      </div>
      <span className='noFileText'>{COMMON_STRING.NO_FILE_CHOOSEN}</span>
    </div>
  );

  const imageProps = {
    name: "file",
    multiple: false,
    fileList: fileList,
    action: `${BASE_URL}upload-image`,
    style: {
      width: "127px",
      height: "127px"
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
    beforeUpload(file) {
      if (fileList.length > 1 || fileList.length === 1) {
        toast.warn(COMMON_ALERTS.FILE_EXISTS);
        return false;
      }
      const type =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!type) {
        toast.error(COMMON_ALERTS.ONLY_ALLOWED_ERROR('.jpeg, .png, .jpg'));
      }
      return type || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.fileList.length === 1) {
        setFileList([...info.fileList]);
      }
      if (info.file.status === "done") {
        form.setFieldsValue({ profileImage: info.file.response?.data?.media.id });
        setMediaItemId(info.file.response?.data?.media.id);
      } else if (info.file.status === "error") {
        toast.error(COMMON_ALERTS.FILE_UPLOAD_FAILED(info.file.name));
      }
    },
    async onRemove() {
      setFileList([]);
      setMediaItemId(null);
    },
  };

  const fetchContact = async () => {
    const res = await CONTACT_DETAILS.SINGLE(contactItemId);
    if (res?.status) {
      setFileList([
        {
          uid: '-1',
          name: 'cover image',
          status: 'done',
          url: BASE_URL + res?.data?.media_item?.image,
          thubmUrl: BASE_URL + res?.data?.media_item?.image,
        },
      ]);

      form.setFieldsValue({
        cname: res?.data?.name,
        email: res?.data?.email,
        Contactno: res?.data?.contact_mobile,
        Profession: res?.data?.professionTypeId,
        activeStatus: res?.data?.statuss === 'active' ? true : false
      });
      setisChecked(res?.data?.statuss === 'active' ? true : false);
      setMediaItemId(res?.data?.mediaItemId);
    }
  }

  const fetchProfessions = async () =>{
    const res = await SERVICES_TYPES.GET(-1);
    if(res?.status && Array.isArray(res.data)){
      setProfessions(res.data);
    }
  }

  useEffect(() => {
    if (contactItemId) {
      fetchContact();
    }
    fetchProfessions();
  }, []);

  return (
    <div className='addNwContactLayout'>
      <div className="fWidthShowBox addNwContactContainer">
        <h2>{contactItemId ? CONTACT_DETAILS_STRINGS.ADD_EDIT.EDIT_CONTACT : CONTACT_DETAILS_STRINGS.ADD_EDIT.ADD_CONTACT}</h2>
        <Form className='mt-3' form={form} onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} colon={false}>
          <Form.Item
            name="cname"
            label={<span className="inputTitle">{CONTACT_DETAILS_STRINGS.ADD_EDIT.CONTACT_NAME}</span>}
            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('contact name') }]}>
            <Input className='textInput' placeholder={COMMON_ALERTS.PLACEHOLDER_INPUT("Contact Name")} />
          </Form.Item>

          <Form.Item
            name="email"
            label={<span className="inputTitle">{CONTACT_DETAILS_STRINGS.ADD_EDIT.EMAIL}</span>}
            rules={[
              {
                type: 'email',
                message: COMMON_ALERTS.VALID_EMAIL,
              },
              {
                required: true,
                message: COMMON_ALERTS.REQUIRED_INPUT('email'),
              },
            ]}>
            <Input className='textInput' placeholder={COMMON_ALERTS.PLACEHOLDER_INPUT("Email Id")} />
          </Form.Item>
          <Form.Item
            name="Contactno"
            label={<span className="inputTitle">{CONTACT_DETAILS_STRINGS.ADD_EDIT.CONTACT_NO}</span>}
            rules={[
              {
                required: true,
                message: COMMON_ALERTS.REQUIRED_INPUT('contact no.'),
              },
              {
                validator: async (rule, value) => {
                  const fieldValues = form.getFieldsValue();
                  const Contactno = fieldValues.Contactno;
                  if (Contactno) {
                    if (Contactno.toString().length != 10) {
                      throw new Error(COMMON_ALERTS.INVALID_ERROR('contact no.'));
                    }
                  }
                },
              },
            ]}>
            <Input type='number' className='textInput' controls={false} maxLength={10} placeholder={COMMON_ALERTS.PLACEHOLDER_INPUT("Contact No.")} />
          </Form.Item>
          <Form.Item
            name="Profession"
            label={<span className="inputTitle">{CONTACT_DETAILS_STRINGS.ADD_EDIT.PROFESSION}</span>}
            rules={[{ required: true, message: 'Profession is required' }]}>
            <Select className='textInput' suffixIcon={<SelectArrow/>}>
              <Select.Option key={-1} value=""></Select.Option>
              {Professions.map((service, key) => <Select.Option key={key} value={service.id}>{service.name}</Select.Option>)}
            </Select>
          </Form.Item>
          {contactItemId && (<Form.Item label={<span className="inputTitle">{CONTACT_DETAILS_STRINGS.ADD_EDIT.FURTHER_USE}</span>} name="activeStatus"><Switch className='toogleSwitch' checked={isChecked} onChange={value => setisChecked(value)} /></Form.Item>)}
          <Form.Item required label={<span className="inputTitle">{CONTACT_DETAILS_STRINGS.ADD_EDIT.PROFILE_IMAGE}</span>} name="profileImage" rules={[{ validator: (_, value) => { if (!MediaItemId) { return Promise.reject(COMMON_ALERTS.REQUIRED_CHOOSE('image')); } return Promise.resolve(); } }]}>
            <Upload className='imageUploadInput' accept=".png, .jpg, .jpeg" {...imageProps} >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>

          </Form.Item>
          <Row className='mt-4'>
            <Col xs={24} sm={24} md={{ span: 16, offset: 8 }} lg={{ span: 16, offset: 8 }} xl={{ span: 16, offset: 8 }} className="btnBox">
              <button className='backBtn' onClick={() => navigate('/contacts',)}>{COMMON_STRING.BACK_BTN}</button>
              <Button type="submit" htmlType='submit' className='createBtn' disabled={isSending}>{contactItemId ? COMMON_STRING.UPDATE_BTN : COMMON_STRING.CREATE_BTN}</Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default AddEditContactDetails;
