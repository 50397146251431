import { methods, serviceMaker } from '../service';

const BUDGETING_CASH = (propertyId = null, appuserId = null, startDate = null, endDate = null, year = null) => {
    const query = { params: {} };
    if (startDate) {
        startDate = new Date(startDate);
        query['params']['startDate'] = startDate;
    }

    if (endDate) {
        endDate = new Date(endDate)
        query['params']['endDate'] = endDate;
    }
    if (year) {
        query['params']['year'] = year;
    }

    if (propertyId) {
        query['params']['propertyId'] = propertyId;
    }
    else if (appuserId) {
        query['params']['userId'] = appuserId;
    }

    else return { status: false };
    return serviceMaker(`reports/budgeting-cash`, methods.GET, query);
}

const FINANCIAL_REPORT = (
    limit,
    skip,
    search = null,
    sort = null,
    appuserId = null,
    propertyId = null,
    stateId = null,
    rate = null,
    osLoan = null,//Outstanding Loan
    startDate = null,
    endDate = null) => {

    const query = {
        params: {
            $limit: limit,
            $skip: skip
        }
    };
    if (sort) {
        query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
    } else {
        query['params']['$sort[createdAt]'] = -1;
    }

    if (propertyId) {
        query['params']['propertyId'] = propertyId;
    }
    else if (appuserId) {
        query['params']['appuserId'] = appuserId;
    }

    if (stateId) {
        query['params']['stateId'] = stateId;
    }

    if (rate) {
        query['params']['rate'] = rate;
    }

    if (osLoan) {
        query['params']['osLoan'] = osLoan;
    }

    if (startDate) {
        query['params']['startDate'] = new Date(startDate);
    }
    if (endDate) {
        query['params']['endDate'] = new Date(endDate);
    }
    if (search) {
        query['params']['search'] = search;
    }
    console.log('search : ', search);
    return serviceMaker(`reports/finance`, methods.GET, query);
}

const COMMON_RATINGS = () => serviceMaker(`search/common-ratings`, methods.GET);

const EXPENSES_YEARS = () => serviceMaker(`search/expenses-years`, methods.GET);

const REWARDS = ({
    $limit,
    $skip,
    search,
    sort,
    startDate,
    endDate,
    country,
    state,
    suburb,
    rewardType,
    status,
    total_pending,
    total_redeemed,
    total_discard,
    total_expired
}) => {
    const query = { params: {} };
    if (startDate) {
        startDate = new Date(startDate);
        query['params']['startDate'] = startDate;
    }

    if (endDate) {
        endDate = new Date(endDate)
        query['params']['endDate'] = endDate;
    }
    if (sort) {
        query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
    } else {
        query['params']['$sort[createdAt]'] = -1;
    }
    query['params'] = {
        ...query['params'],
        $limit,
        $skip,
        search,
        country,
        state,
        suburb,
        rewardType,
        status,
        total_pending: total_pending.join('_'),
        total_redeemed: total_redeemed.join('_'),
        total_discard: total_discard.join('_'),
        total_expired: total_expired.join('_')
    }
    
    return serviceMaker(`reports/rewards`, methods.GET, query);
}

const REFERRALS = ({
    $limit,
    $skip,
    search,
    sort,
    startDate,
    endDate,
    country,
    state,
    suburb,
    referType,
    category,
    pd_referror,
    pd_referee,
    type
}) => {
    const query = { params: {} };
    if (startDate) {
        startDate = new Date(startDate);
        query['params']['startDate'] = startDate;
    }

    if (endDate) {
        endDate = new Date(endDate)
        query['params']['endDate'] = endDate;
    }
    if (sort) {
        query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
    } else {
        query['params']['$sort[completedAt]'] = -1;
    }
    query['params'] = {
        ...query['params'],
        $limit,
        $skip,
        search,
        country,
        state,
        suburb,
        referType,
        category,
        pd_referror: pd_referror.join('_'),
        pd_referee: pd_referee.join('_'),
        type
    }
    
    return serviceMaker(`reports/refers`, methods.GET, query);
}

const REPORTS = {
    BUDGETING_CASH,
    FINANCIAL_REPORT,
    COMMON_RATINGS,
    EXPENSES_YEARS,
    REWARDS,
    REFERRALS
};

export { REPORTS };