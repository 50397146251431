import { Button, Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { COMMON_ALERTS, COMMON_STRING, REDEEM_CATEGORIES_STRING } from '../../../services/strings';
import { REDEEM_CATEGORIES } from '../../../services/RedeemRewards/RedeemCategories';

const AddEditRedeemCategory = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const categoryId = useLocation().state?.categoryId;
    const [isDataLoaded, setisDataLoaded] = useState(false);
    const [isPopularChecked, setIsPopularChecked] = useState(false);

    useEffect(() => {
        if (categoryId !== null) {
            fetchCategory();
        }
    }, []);

    const fetchCategory = async () => {
        if (categoryId) {
            const res = await REDEEM_CATEGORIES.FIND(categoryId);
            if (res.status) {
                form.setFieldValue('name', res.data.name);
                setIsPopularChecked(res?.data?.popular || false);
                setisDataLoaded(true);
            }
        }
    }

    const onFinish = async (form) => {
        const payload = { name: form.name, popular: isPopularChecked };
        let res = null;
        if (!categoryId)
            res = await REDEEM_CATEGORIES.CREATE(payload);
        else
            res = await REDEEM_CATEGORIES.PATCH(categoryId, payload);
        try {
            if (res.status) {
                toast.success(!categoryId ? COMMON_ALERTS.ADDED_SUCCESS('Expenses') : COMMON_ALERTS.UPDATED_SUCCESS('Expenses'));
                navigate(-1);
            }
        }
        catch (e) {
            // console.log(e);
        }
    }

    if ((!categoryId) || isDataLoaded)
        return (
            <div>
                <div className="fWidthShowBox addNwDepScheduleContainer">
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <h2>{!categoryId ? REDEEM_CATEGORIES_STRING.ADD_CATEGORY : REDEEM_CATEGORIES_STRING.EDIT_CATEGORY}</h2>
                        </Col>
                    </Row>
                    <Form name="complex-form" className="addformLayout" form={form} onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} colon={false}>
                        <Form.Item
                            label={<span className='inputTitle'>{REDEEM_CATEGORIES_STRING.CATEGORY_NAME}</span>}
                            name="name"
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('category name') }]}
                        >
                            <Input className='textInput' />
                        </Form.Item>
                        <Row>
                            <Col xs={24} sm={24} md={{ span: 16, offset: 8 }} lg={{ span: 16, offset: 8 }} xl={{ span: 16, offset: 8 }} className="btnBox">
                                <Button className="cancelBtn" onClick={() => navigate(-1)}>{COMMON_STRING.BACK_BTN}</Button>
                                <Button type="submit" htmlType='submit' className="addBtn">{!categoryId ? COMMON_STRING.CREATE_BTN : COMMON_STRING.UPDATE_BTN}</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>

            </div>
        );
}

export default AddEditRedeemCategory;
