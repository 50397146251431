import moment from 'moment';
import { methods, serviceMaker } from '../service';

const GET_NOTIFICATION = (id) => serviceMaker(`notifications/${id}`, methods.GET);

const GET_NOTIFICATIONS = (
  limit,
  skip,
  search = null,
  sort = null,
  FilterStartDate = null,
  FilterEndDate = null,
  FilterScheduled = null,
  FIlterPublished = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/notifications`, methods.GET, query);
  }

  if (FilterStartDate) {
    query['params'][`startDate`] = moment(FilterStartDate).toDate();
  }

  if (FilterEndDate) {
    query['params'][`endDate`] = moment(FilterEndDate).toDate();
  }

  if (FilterScheduled) {
    query['params'][`isPublished`] = false;
  }

  if (FIlterPublished) {
    query['params'][`isPublished`] = true;
  }

  return serviceMaker(`notifications`, methods.GET, query);
};

const DELETE_NOTIFICATION = (id) => serviceMaker(`notifications/${id}`, methods.DELETE);

const CREATE_NOTIFICATION = (payload) =>
  serviceMaker(`notifications`, methods.POST, null, payload);

const PATCH_NOTIFICATION = (id, payload) =>
  serviceMaker(`notifications/${id}`, methods.PATCH, null, payload);

const NOTIFICATIONS = {
  FIND: GET_NOTIFICATION,
  GET: GET_NOTIFICATIONS,
  CREATE: CREATE_NOTIFICATION,
  PATCH: PATCH_NOTIFICATION,
  DELETE: DELETE_NOTIFICATION
};

export { NOTIFICATIONS };
