import React from "react";
import { Chart } from "react-google-charts";
import PropTypes from 'prop-types';

ExpensesChart.propTypes = {
  expensesChartData: PropTypes.array
}

export function ExpensesChart(props) {
  const data = [
    props.expensesChartData['columns'],
    props.expensesChartData['values']
  ];

  const options = {
    chart: {
      title: "Expense List",
      subtitle: "Show Data : 2022",
    }
  };
  
  return (
    <Chart
      chartType="Bar"
      width="100%"
      height="250px"
      data={data}
      options={options}
      chartWrapperParams
    />
  );
}
