import { Col, DatePicker, Form, Input, Row, Select, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import CalenderDarkIcon from '../Icon/CalenderDarkIcon';
import { tableSortIconToggle } from '../../utility/tableSorting';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';
import DeleteModel from '../DeleteModel';
import PaginationCom from '../PaginationCom';
import { RENTAL_INCOMES } from '../../services/RentalIncomes/RentalIncome';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import SelectArrow from '../Icon/SelectArrow';
import FilterToogleBtn from '../FilterToogleBtn';
import { FILTER_STRING, PROPERTIES_STRING, TABLE_STRING } from '../../services/strings';

const PRentalIncome = ({ permissions }) => {
    const Years = [2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015];
    const [deleteId, setDeleteId] = useState(null);
    const [sort, setSort] = useState(null);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [rentalIncomes, setrentalIncomes] = useState(null);
    const [FilterStartDate, setFilterStartDate] = useState(null);
    const [FilterEndDate, setFilterEndDate] = useState(null);
    const [FilterYear, setFilterYear] = useState('');
    const [tableLoading, setTableLoading] = useState(null);
    const [tableDataLength, setTableDataLength] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState(null);
    const [tempSearch, setTempSearch] = useState(null);
    const location = useLocation();
    const propertyId = location.state?.propertyId;
    const [isFilterShow, setIsFilterShow] = useState(false);
    const [form] = Form.useForm();

    if (!propertyId) {
        <Navigate to="/properties" />;
    }

    const deleteProperties = async () => {
        const res = await RENTAL_INCOMES.DELETE(deleteId);
        setTableLoading(null);
        if (res.status) {
            fetchRentalIncomes();
            setIsDeleteModalVisible();
            setTableLoading(false);
        }
    };

    const fetchRentalIncomes = async () => {
        setTableLoading(false);
        const rentalIncomeData = await RENTAL_INCOMES.GET(pageSize,
            (currentPage - 1) * pageSize,
            propertyId,
            search,
            sort,
            FilterStartDate,
            FilterEndDate,
            FilterYear);
        if (rentalIncomeData.status && Array.isArray(rentalIncomeData.data)) {
            setTableDataLength(rentalIncomeData.total);
            setrentalIncomes(rentalIncomeData);
        }
    };

    const onChangePage = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const handleChangeOfSearch = (e) => {
        setTempSearch(e.target.value);
        if (!e.target.value) {
            setCurrentPage(1);
            setSearch(null);
            setTempSearch(null);
        }
    };

    const handleSearch = () => {
        setCurrentPage(1);
        setSearch(tempSearch);
    };

    const onChange = (pagination, filters, sorter) => {
        if (sorter?.order === 'ascend') {
            setSort({ [sorter.field]: 1 });
        } else if (sorter?.order === 'descend') {
            setSort({ [sorter.field]: -1 });
        } else {
            setSort({ createdAt: -1 });
        }
    };

    useEffect(() => {
        fetchRentalIncomes();
    }, [currentPage, pageSize, search, sort, FilterStartDate, FilterEndDate, FilterYear]);

    const columns = [
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{PROPERTIES_STRING.RENTAL_INCOME.TOTAL_RENT_RECIEVED}</span>, 'totalRentRecieved', column),
            dataIndex: 'amount',
            key: 'amount',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? '$' + value : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{PROPERTIES_STRING.RENTAL_INCOME.FREQUENCY}</span>, 'frequency', column),
            key: 'frequency',
            dataIndex: 'frequency',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (value ? value.charAt(0).toUpperCase() + value.slice(1) : '-'),
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{PROPERTIES_STRING.RENTAL_INCOME.START_DATE}</span>, 'startDate', column),
            key: 'startDate',
            dataIndex: 'startDate',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (value ? <span className="tabletext">{moment(new Date(value)).format('DD/MM/YYYY')}</span> : '-'),
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{PROPERTIES_STRING.RENTAL_INCOME.END_DATE}</span>, 'endDate', column),
            key: 'endDate',
            dataIndex: 'endDate',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (value ? <span className="tabletext">{moment(new Date(value)).format('DD/MM/YYYY')}</span> : '-'),
        },
        {
            title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
            key: 'Actions',
            align: 'center',
            dataIndex: 'Actions',
            width: 80,
            render: (item, record) => {
                return (
                    <div className='actionCol justify-center'>
                        {(permissions?.isDelete || permissions?.isDelete === undefined) && (
                            <Tooltip title='Delete Rental Income'>
                                <DeleteOutlined
                                    onClick={() => {
                                        setIsDeleteModalVisible(true);
                                        setDeleteId(record?.id);
                                    }}
                                    className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                                />
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            <FilterToogleBtn withmenu={true} onClick={() => setIsFilterShow(!isFilterShow)} />
            <div className={isFilterShow ? 'fWidthShowBox pRentalIncomePage mb-3' : 'fWidthShowBox pRentalIncomePage d-none  mb-3'}>
                <Form form={form} labelCol={8} wrapperCol={16} colon={false} labelAlign={'right'}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                            <Form.Item labelCol={8} wrapperCol={16} label={<span className="detailTitle">{FILTER_STRING.START_DATE}</span>} >
                            <DatePicker className='selectDateInput'
                                    placeholder=""
                                    suffixIcon={<CalenderDarkIcon />}
                                    allowClear
                                    onChange={value => setFilterStartDate(value)}
                                    disabledDate={d => !d || d.isAfter(FilterEndDate)}
                                    picker="date" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={{ span: 11, offset: 1 }} xl={{ span: 11, offset: 1 }}>
                            <Form.Item label={<span className="detailTitle">{FILTER_STRING.END_DATE}</span>}>
                                <DatePicker className='selectDateInput'
                                    placeholder=""
                                    suffixIcon={<CalenderDarkIcon />}
                                    allowClear
                                    onChange={value => setFilterEndDate(value)}
                                    disabledDate={d => !d || d.isSameOrBefore(FilterStartDate)}
                                    picker="date" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className='prentalRow'>
                        <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                            <Form.Item
                                labelAlign='right'
                                label={<span className="detailTitle">{FILTER_STRING.SEARCH}</span>} >
                                <Input className='selectDateInput'
                                    placeholder="Start Typing To Search Expenses"
                                    value={tempSearch}
                                    allowClear
                                    onChange={(e) => handleChangeOfSearch(e)}
                                    onKeyPress={(e) => {
                                        if (e?.key === 'Enter' && e.target.value) {
                                            handleSearch();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={{ span: 11, offset: 1 }} xl={{ span: 11, offset: 1 }}>
                            <Select defaultValue={FILTER_STRING.YEAR} value={FilterYear} onChange={(value)=>setFilterYear(value)} suffixIcon={<SelectArrow/>} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false} >
                                <Select.Option value=''>{FILTER_STRING.YEAR}</Select.Option>
                                {Years.map((arr, key) => <Select.Option key={key} value={arr + 1}>{arr + 1}</Select.Option>)}
                            </Select>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <span className="clearAll">Clear All</span>
                    </Col>
                </Row>
            </div>
            <div className='rentalTableBox'>
                <Table
                    columns={columns}
                    dataSource={rentalIncomes?.data}
                    pagination={false}
                    rowKey='id'
                    loading={tableLoading}
                    onChange={onChange}
                    scroll={{ x: 400 }}
                />
            </div>
            {rentalIncomes?.total > 10 && (
                <div className='text-end bg-white mt-7'>
                    <PaginationCom
                        onChange={onChangePage}
                        showSizeChanger={true}
                        current={currentPage}
                        showLessItems={true}
                        total={rentalIncomes?.total}
                    />
                </div>
            )}
            <DeleteModel
                isDeleteModalVisible={isDeleteModalVisible}
                deleteModel={deleteProperties}
                setIsDeleteModalVisible={setIsDeleteModalVisible}
            />
        </div>
    );
};

PRentalIncome.propTypes = {
    permissions: PropTypes.any
};

export default PRentalIncome;
