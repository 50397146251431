import { Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { COMMON_ALERTS, COMMON_STRING, FHBG_STRING, PROPERTY_GOALS_STRINGS, SIDEBAR_STRINGS } from '../../services/strings';
import { useNavigate } from 'react-router-dom';
import FormItem from 'antd/es/form/FormItem';
import { PROPERTY_GOALS } from '../../services/PropertyGoals/PropertyGoals';
import { toast } from 'react-toastify';

const PropertyGoalsPage = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [pgId, setPgId] = useState(null);

    const fetchData = async () => {
        const res = await PROPERTY_GOALS.GET();
        if (res.status && res.data.length) {
            const [pg] = res.data;
            setPgId(pg.id);
            form.setFieldsValue({
                stampDutyRate: pg.stampDutyRate,
                savingRate: pg.savingRate,
                repaymentRate: pg.repaymentRate,
                rentRate: pg.rentRate
            });
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const onFinish = async (data) => {
        const payload = {
            stampDutyRate: data.stampDutyRate,
            savingRate: data.savingRate,
            repaymentRate: data.repaymentRate,
            rentRate: data.rentRate
        }
        const res = pgId ? await PROPERTY_GOALS.PATCH(pgId, payload) : await PROPERTY_GOALS.CREATE(payload);
        if (res.status) {
            setPgId(res.data.id);
            toast.success(pgId ? COMMON_ALERTS.UPDATED_SUCCESS('Property goal') : COMMON_ALERTS.ADDED_SUCCESS('Property goal'));
        }
    }

    const InputRules = (text) => [
        { required: true, message: COMMON_ALERTS.REQUIRED_INPUT(text) },
        {
            validator: (_, val) => {
                if (val && ((parseInt(val) > 100) || (parseInt(val) < 0))) {
                    return Promise.reject('Invalid percentage');
                }
                else {
                    return Promise.resolve();
                }
            }
        }]

    return (
        <div className='fWidthShowBox commonLayout'>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                    <h2 className="h2title">{SIDEBAR_STRINGS.PROPERTY_GOALS}</h2>
                </Col>
            </Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                <Form className="addformLayout" name="complex-form" form={form} onFinish={onFinish} labelCol={{ span: 9 }} colon={false} wrapperCol={{ span: 8 }}>
                    <FormItem
                        label={<span className='inputTitle'>{PROPERTY_GOALS_STRINGS.STAMP_DUTY_PERCENTAGE}</span>} name="stampDutyRate" rules={InputRules('Stamp duty rate')}>
                        <Input type='number' className="input-control" />
                    </FormItem>
                    <FormItem
                        label={<span className='inputTitle'>{PROPERTY_GOALS_STRINGS.REPAYMENT_PERCENTAGE}</span>} name="repaymentRate" rules={InputRules('Repayment rate')}>
                        <Input type='number' className="input-control" />
                    </FormItem>
                    <FormItem
                        label={<span className='inputTitle'>{PROPERTY_GOALS_STRINGS.ESTIMATE_RENT_COVER_RATE}</span>} name="rentRate" rules={InputRules('rent cover rate')}>
                        <Input type='number' className="input-control" />
                    </FormItem>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="btnBoxMulti">
                            <button className="cancelBtnMulti" onClick={() => navigate(-1)}>{COMMON_STRING.CANCEL_BTN}</button>
                            <button className="addBtnMulti">{pgId? COMMON_STRING.SAVE_BTN: COMMON_STRING.ADD_BTN}</button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </div>
    );
}

export default PropertyGoalsPage;
