import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useLocation, useNavigate } from 'react-router-dom';
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { convertToHTML } from 'draft-convert';
import { toast } from 'react-toastify';
import { FHBGOALS } from '../../services/FirstHomeBuyerGoal/fhbgoals';
import { COMMON_ALERTS, COMMON_STRING, FHBG_STRING } from '../../services/strings';

const EditFHBGoalPage = () => {
    const navigate = useNavigate();
    const [Description, setDescription] = useState(null);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
    const [convertedContent, setConvertedContent] = useState(null);
    const [errorText, setErrorText] = useState("");
    const location = useLocation().state;

    const fetchCalculatorDetails = async () => {
        const data = await FHBGOALS.GET(location.stateId);
        if (data.status) {
            setDescription(data.data);
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(data.data))));
        }
    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }

    useEffect(() => {
        fetchCalculatorDetails();
    }, []);

    useEffect(() => {
        convertContentToHTML();
    }, [editorState]);

    const changeEditTExt = (e) => {
        setEditorState(e);

    }

    const updateFhbgoal = async () => {
        if (convertedContent == "<p></p>") { setErrorText("Please enter description!"); return false; }
        else setErrorText("");

        const res = await FHBGOALS.CREATE({
            stateId: location.stateId,
            description: convertedContent
        });

        if (res.status) {
            toast.success(COMMON_ALERTS.UPDATED_SUCCESS('State data'));
            navigate('/fhbgoal');
        }
    }

    return (
        <div className='calculator_detailsLayout'>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                    <h2 className="h2Title">{location.state_code}</h2>
                </Col>
            </Row>
            <Row className='fWidthShowBox calculator_detail_box'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                    <span className="detailBxTitle">{FHBG_STRING.DETAILS.TITLE}</span>
                    <Editor
                        onEditorStateChange={(e) => changeEditTExt(e)}
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="calculator_detail_inputArea"
                        editorClassName="calculator_detail_editInput" />
                    <span className="errorTxt">{errorText}</span>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="btnBoxMulti">
                            <button className="cancelBtnMulti" onClick={() => navigate('/fhbgoal')}>{COMMON_STRING.CANCEL_BTN}</button>
                            <button className="addBtnMulti" onClick={() => updateFhbgoal()}>{COMMON_STRING.SAVE_BTN}</button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default EditFHBGoalPage;
