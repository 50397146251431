import { Button, Checkbox, Col, Form, Input, Row, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PencileIcon from '../../components/Icon/PencileIcon';
import { ADMINUSERS } from '../../services/AdminUsers/AdminUsers';
import { ADMIN_USER_STRINGS, COMMON_ALERTS, COMMON_STRING } from '../../services/strings';
import { ROLES } from '../../services/Roles/Roles';
import { getLSItem } from '../../services/auth/encryptLogin';
import { BASE_URL } from '../../services/constant';

const AddEditAdminuser = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const userId = useLocation().state?.userId;
    const [Adminuser, setAdminuser] = useState(null);
    const [isDataLoaded, setisDataLoaded] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [MediaItemId, setMediaItemId] = useState(null);
    const accessToken = getLSItem("pdollar-token");
    const [Roles, setRoles] = useState([]);
    const [Checked, setchecked] = useState([]);

    useEffect(() => {
        if (userId !== null) {
            fetchAppuser();
        }
        fetchRoles()
    }, []);

    const fetchAppuser = async () => {
        if (userId) {
            const res = await ADMINUSERS.FIND(userId);
            if (res.status) {
                let userData = [];
                userData = res.data;
                setAdminuser(userData);
                setMediaItemId(userData?.profileImageId);
                setFileList([
                    {
                        uid: '-1',
                        name: 'profile.png',
                        status: 'done',
                        url: BASE_URL.slice(0, -1) + userData?.profileImage?.image
                    }
                ]);
                setisDataLoaded(true);
                setchecked([userData?.role?.id]);
                form.setFieldsValue({
                    firstname: userData.firstname,
                    lastname: userData.lastname,
                    email: userData.email,
                    profile: BASE_URL + userData?.profileImage?.image,
                    role: [userData?.role?.id]
                })
            }
        }
    }

    const fetchRoles = async () => {
        const res = await ROLES.GET();
        if (res.status) {
            setRoles(res.data);
        }
    }

    const onFinish = async (e) => {
        const payload = {
            firstname: e.firstname,
            lastname: e.lastname,
            username: e.username,
            password: e.password,
            roleId: e.role[0],
            email: e.email
        };
        if(MediaItemId || userId){
            Object.assign(payload, {profileImageId: MediaItemId});
        }
        var res = null;
        if (!userId)
            res = await ADMINUSERS.CREATE(payload);
        else
            res = await ADMINUSERS.PATCH(userId, payload);

        if (res.status) {
            toast.success(!userId ? COMMON_ALERTS.ADDED_SUCCESS('User') : COMMON_ALERTS.UPDATED_SUCCESS('User'));
            navigate('/admin-users', { replace: true });
        }
    }

    const uploadButton = (
        <div className='uploadImageBtn'>
            <div>
                <PencileIcon />
            </div>
            <span className='noFileText'>
                {COMMON_STRING.NO_FILE_CHOOSEN}
            </span>
        </div>
    );

    const imageProps = {
        name: 'file',
        multiple: false,
        fileList: fileList,
        action: `${BASE_URL}upload-image`,
        headers: {
            authorization: accessToken,
        },
        beforeUpload() {
            if (fileList.length > 1 || fileList.length === 1) {
                toast.warn(COMMON_ALERTS.FILE_EXISTS);
                return false;
            }
        },
        onChange(info) {
            if (info.fileList.length === 1) {
                setFileList([...info.fileList]);
            }
            if (info.file.status === 'done') {
                setMediaItemId(info.file.response.data.media.id);
            } else if (info.file.status === 'error') {
                toast.error(COMMON_ALERTS.FILE_UPLOAD_FAILED(info.file.name));
            }
        },
        async onRemove(info) {
            form.setFieldValue('profile', null);
            setFileList([]);
            setMediaItemId(null);
        },
    };

    const onChangeCheck = checkedValues => {
        if (Checked.length) {
            var nArr = [];
            if (checkedValues.length)
                nArr = checkedValues.filter(e => e !== Checked[0]);
            form.setFieldValue('role', nArr);
            setchecked(nArr);
        }
        else setchecked(checkedValues);
    };


    if ((!userId) || isDataLoaded)
        return (
            <div>
                <div className="fWidthShowBox addNwDepScheduleContainer">
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <h2>{!userId ? ADMIN_USER_STRINGS.ADD_EDIT.ADD_USER_LABEL : ADMIN_USER_STRINGS.ADD_EDIT.EDIT_USER_LABEL}</h2>
                        </Col>
                    </Row>
                    <Form name="complex-form" className="addformLayout" onFinish={onFinish} form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 10 }} colon={false}>
                        <Form.Item
                            label={<span className='inputTitle'>{ADMIN_USER_STRINGS.ADD_EDIT.FIRST_NAME}</span>}
                            name="firstname"
                            initialValue={Adminuser?.firstname}
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('first name') }]}
                        >
                            <Input className='textInput' />
                        </Form.Item>
                        <Form.Item
                            label={<span className='inputTitle'>{ADMIN_USER_STRINGS.ADD_EDIT.LAST_NAME}</span>}
                            name="lastname"
                            initialValue={Adminuser?.lastname}
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('last name') }]}
                        >
                            <Input className='textInput' />
                        </Form.Item>
                        <Form.Item
                            label={<span className='inputTitle'>{ADMIN_USER_STRINGS.ADD_EDIT.USERNAME}</span>}
                            name="username"
                            initialValue={Adminuser?.username}
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('username') },
                            {
                                validator: async (_, value) => {
                                    if (value) {
                                        if (value.indexOf(' ') > 0)
                                            return Promise.reject(COMMON_ALERTS.USERNAME_NO_SPACE);
                                        else{
                                            const res = await ADMINUSERS.CHECKUSER(value, userId?? undefined);
                                            return res.data? Promise.reject(COMMON_ALERTS.ALREADY_TOKEN_USERNAME) : Promise.resolve();
                                        }
                                    } return Promise.resolve('');
                                }
                            }]}
                        >
                            <Input className='textInput' />
                        </Form.Item>
                        <Form.Item
                            label={<span className='inputTitle'>{ADMIN_USER_STRINGS.ADD_EDIT.EMAIL}</span>}
                            name="email"
                            initialValue={Adminuser?.email}
                            rules={[
                                { required: true, message: COMMON_ALERTS.REQUIRED_INPUT('email') }, 
                                { type: 'email', message: COMMON_ALERTS.VALID_EMAIL },
                                {
                                    validator: async (_, val)=>{
                                        const res = await ADMINUSERS.CHECKEMAIL(val, userId?? undefined);
                                        return res.data? Promise.reject(COMMON_ALERTS.ALREADY_TOKEN_EMAIL) : Promise.resolve();
                                    }
                                }
                            ]}
                        >
                            <Input className='textInput' />
                        </Form.Item>
                        <Form.Item
                            label={<span className='inputTitle'>{ADMIN_USER_STRINGS.ADD_EDIT.NEW_PASSWORD}</span>}
                            name="password"
                            rules={[{ required: !Adminuser ? true : false, message: COMMON_ALERTS.REQUIRED_INPUT('password') }]}>
                            <Input className='textInput' />
                        </Form.Item>
                        <Form.Item
                            label={<span className='inputTitle'> {ADMIN_USER_STRINGS.ADD_EDIT.CONFIRM_PASSWORD}</span>}
                            name="cpassword"
                            require={!Adminuser ? true : false}
                            rules={[
                                ({ getFieldValue }) => (
                                    {
                                        validator(_, value) {
                                            const passField = getFieldValue('password');
                                            if ((!passField) || (passField == undefined)) return Promise.resolve();
                                            if (passField && !value) {
                                                return Promise.reject(new Error(COMMON_ALERTS.REQUIRED_INPUT('confirm password')));
                                            }
                                            if (!value || !passField || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(COMMON_ALERTS.CONFIRM_PASS_NO_MATCH));
                                        },
                                    })]}
                        >
                            <Input type={'password'} className='textInput' />
                        </Form.Item>
                        <Form.Item
                            label={<span className='inputTitle'>{ADMIN_USER_STRINGS.ADD_EDIT.PROFILE_IMG}</span>}
                            name="profile"
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_CHOOSE('profile image') }]}
                        >
                            <div className="imagePickerDiv imageUploadInput">
                                <Upload accept=".png, .jpg, .jpeg" listType="picture" {...imageProps}>
                                    {fileList.length >= 1 ? null : uploadButton}
                                </Upload>
                            </div>
                        </Form.Item>
                        <Form.Item
                            label={<span className='inputTitle'> {ADMIN_USER_STRINGS.ADD_EDIT.SELECT_ROLE}</span>}
                            name="role"
                            initialValue={Adminuser?.firstname}
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('role') }]}>
                            <Checkbox.Group className='w-100' onChange={onChangeCheck}>{Roles.map((role, i) => <Checkbox key={i} value={role.id} style={{ lineHeight: '32px' }}>{role.name}</Checkbox>)}</Checkbox.Group>
                        </Form.Item>
                        <Row>
                            <Col xs={24} sm={24} md={{ span: 16, offset: 8 }} lg={{ span: 16, offset: 8 }} xl={{ span: 16, offset: 8 }} className="btnBox">
                                <Button className="cancelBtn" onClick={() => navigate('/admin-users', { replace: true })}>{COMMON_STRING.BACK_BTN}</Button>
                                <button type="submit" className="addBtn">{!userId ? COMMON_STRING.CREATE_BTN : COMMON_STRING.UPDATE_BTN}</button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        );
}

export default AddEditAdminuser;
