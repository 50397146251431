 import React from 'react';

const EllipseIcon = () => {
    return (
        <div>   
        <svg width="8" height="8" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6" cy="6" r="6" fill="#324666"/>
        </svg>
        </div>
    );
}

export default EllipseIcon;
