import React, { useState } from "react";
import PropTypes from 'prop-types';

const AppButton = ({ text, onClick, withMenu, className, style }) => {
    return <button style={style} className={`appBtn ${className} ${withMenu && "withMenu"}`} onClick={onClick}>{text}</button>;
}

AppButton.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    withMenu: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.any
};


export default AppButton;