import { methods, serviceMaker } from '../service';

const GET_ADVERTISEMENT = (id) => serviceMaker(`advertises/${id}`, methods.GET);
const GET_ADVERTISEMENT_BY_NAME = (name) => serviceMaker(`advertises?name=${name}`, methods.GET);

const GET_ADVERTISEMENTS = (
  limit,
  skip,
  search = null,
  sort = null,
  iOS = null,
  android = null,
  web = null,
  active = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }

  if (iOS) {
    query['params']['isIOS'] = true;
  }

  if (android) {
    query['params']['isAndroid'] = true;
  }

  if (web) {
    query['params']['isWeb'] = true;
  }

  if (active) {
    query['params']['status'] = 'active';
  }

  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/advertises`, methods.GET, query);
  }

  return serviceMaker(`advertises`, methods.GET, query);
};

const DELETE_ADVERTISEMENT = (id) => serviceMaker(`advertises/${id}`, methods.DELETE);

const CREATE_ADVERTISEMENT = (payload) =>
  serviceMaker(`advertises`, methods.POST, null, payload);

const PATCH_ADVERTISEMENT = (id, payload) =>
  serviceMaker(`advertises/${id}`, methods.PATCH, null, payload);

const GET_TABS = (adType=null) => {
  const query = {
    params: {
      $limit: -1,
      $skip: 0
    },
  };
  if (adType) {
    query['params']['adType'] = adType;
  }
  return serviceMaker(`tabs`, methods.GET, query);
};

const GET_SUBTABS = (tabId) => serviceMaker(`subtabs?tabId=${tabId}`, methods.GET);

const GET_SUBCATEGORIES = (subTabsId) => serviceMaker(`subcategory?subtabId=${subTabsId}`, methods.GET);

const ADVERTISEMENT = {
  FIND: GET_ADVERTISEMENT,
  GET: GET_ADVERTISEMENTS,
  CREATE: CREATE_ADVERTISEMENT,
  PATCH: PATCH_ADVERTISEMENT,
  DELETE: DELETE_ADVERTISEMENT,
  BYNAME: GET_ADVERTISEMENT_BY_NAME,
  TABS: GET_TABS,
  SUBTABS: GET_SUBTABS,
  SUBCATEGORIES: GET_SUBCATEGORIES
};

export { ADVERTISEMENT };
