import { methods, serviceMaker } from '../service';

const GET_STATE = (id) => serviceMaker(`states/${id}`, methods.GET);

const GET_STATES = (
  limit,
  skip,
  search = null,
  sort = null,
  countryId = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/states`, methods.GET, query);
  }

  if (countryId) {
    query['params']['countryId'] = countryId;
  }

  return serviceMaker(`states`, methods.GET, query);
};

const DELETE_STATE = (id) => serviceMaker(`states/${id}`, methods.DELETE);

const CREATE_STATE = (payload) =>
  serviceMaker(`states`, methods.POST, null, payload);

const PATCH_STATE = (id, payload) =>
  serviceMaker(`states/${id}`, methods.PATCH, null, payload);

const STATES = {
  FIND: GET_STATE,
  GET: GET_STATES,
  CREATE: CREATE_STATE,
  PATCH: PATCH_STATE,
  DELETE: DELETE_STATE
};

export { STATES };
