import React from 'react';
import PropTypes from 'prop-types';
import NewsCategoryList from '../../components/NewsAndArticles/NewsCategoryList';
import { useNavigate } from 'react-router-dom';

function NewsArticleMenuPage() {
  const navigate = useNavigate();
  return (
    <div className='commonWrapperLayout'>
      <div className="NewsCategoryList">
        <NewsCategoryList text="All News"  onClick={()=>navigate('/news')}/>
        <NewsCategoryList text="Top 10 News of The Day"  onClick={()=>navigate('/top-news')}/>
        <NewsCategoryList text="Top Story" onClick={()=>navigate('/top-stories')}/>
      </div>
    </div>
  );
}

export default NewsArticleMenuPage;