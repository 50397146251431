import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row, Table, Tooltip } from 'antd';
import PaginationCom from '../../components/PaginationCom';
import Modal from 'antd/lib/modal/Modal';
import { tableSortIconToggle } from '../../utility/tableSorting';
import LogOutIcon from '../../components/Icon/LogOutIcon';
import { TABLE_STRING } from '../../services/strings';
import { ADMINLOGINS } from '../../services/LoginSessions/AdminLogins';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getLSItem } from '../../services/auth/encryptLogin';
import LogOutModal from '../../components/Modal/LogOutModal';

function LoginSessions({ permissions }) {
    const [logoutModalVisible, setLogoutModalVisible] = useState(false);
    const [logoutModalData, setLogoutModalData] = useState();
    const [tableLoading, setTableLoading] = useState(true);
    const [logSessions, setLogSessions] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sort, setSort] = useState(null);
    const myToken = getLSItem('pdollar-token');

    const onChangePage = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const onChange = (_pagination, _filters, sorter, _extra) => {
        if (sorter?.order === 'ascend') {
            setSort({ [sorter.field]: 1 });
        } else if (sorter?.order === 'descend') {
            setSort({ [sorter.field]: -1 });
        } else {
            setSort({ createdAt: -1 });
        }
    };

    const logoutUser = async () => {
        const res = await ADMINLOGINS.LOGOUT(logoutModalData.token);
        if (res.status) {
            toast.success('User logout successfully.')
        }
        setLogoutModalVisible(false);
        fetchLoginSessions(currentPage, pageSize, null, sort);
    }

    const fetchLoginSessions = async (
        page = 1,
        pageSize = 10,
        search = null,
        sort = null,
    ) => {
        const res = await ADMINLOGINS.GET(
            pageSize,
            (page - 1) * pageSize,
            search,
            sort
        );
        if (res.status && Array.isArray(res.data)) {
            setLogSessions(res);
        }
        setTableLoading(false);
    }

    function fnBrowserDetect(userAgent) {
        if (userAgent.match(/edg/i)) {
            return "edge";
        } else if (userAgent.match(/chrome|chromium|crios/i)) {
            return "chrome";
        } else if (userAgent.match(/firefox|fxios/i)) {
            return "firefox";
        } else if (userAgent.match(/safari/i)) {
            return "safari";
        } else if (userAgent.match(/opr\//i)) {
            return "opera";
        } else {
            return "-";
        }
    }


    useEffect(() => {
        fetchLoginSessions(currentPage, pageSize, null, sort);
    }, [currentPage, pageSize, sort]);

    const columns = [
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">Platform</span>, 'platform', column),
            dataIndex: 'platform',
            key: 'platform',
            align: 'left',
            width: 200,
            sorter: logSessions ? (logSessions.total > 1 ? true : false) : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">Browser</span>, 'userAgent', column),
            dataIndex: 'userAgent',
            key: 'userAgent',
            align: 'left',
            width: 150,
            sorter: logSessions ? (logSessions.total > 1 ? true : false) : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{fnBrowserDetect(value).charAt(0).toUpperCase() + fnBrowserDetect(value).slice(1)}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">Login IP</span>, 'loginIp', column),
            dataIndex: 'loginIp',
            key: 'loginIp',
            align: 'left',
            width: 170,
            sorter: logSessions ? (logSessions.total > 1 ? true : false) : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{value}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">Login At</span>, 'loginTime', column),
            key: 'loginTime',
            dataIndex: 'loginTime',
            align: 'left',
            width: 500,
            sorter: logSessions ? (logSessions.total > 1 ? true : false) : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (value ? <span className="tabletext">{moment(value).format('MMM DD, YYYY HH:mm A')}</span> : '-'),
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">Last Used IP</span>, 'lastUsedIp', column),
            dataIndex: 'lastUsedIp',
            key: 'lastUsedIp',
            align: 'left',
            width: 170,
            sorter: logSessions ? (logSessions.total > 1 ? true : false) : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{value}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">Last Used At</span>, 'lastUsedTime', column),
            key: 'lastUsedTime',
            dataIndex: 'lastUsedTime',
            align: 'left',
            width: 500,
            sorter: logSessions ? (logSessions.total > 1 ? true : false) : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (value ? <span className="tabletext">{moment(value).format('MMM DD, YYYY HH:mm A')}</span> : '-'),
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">Logout IP</span>, 'logoutIp', column),
            key: 'logoutIp',
            dataIndex: 'logoutIp',
            align: 'left',
            width: 170,
            sorter: logSessions ? (logSessions.total > 1 ? true : false) : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (value ? <span className="tabletext">{value}</span> : '-'),
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">Logout At</span>, 'logoutTime', column),
            key: 'logoutTime',
            dataIndex: 'logoutTime',
            align: 'left',
            width: 500,
            sorter: logSessions ? (logSessions.total > 1 ? true : false) : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (value ? <span className="tabletext">{moment(value).format('MMM DD, YYYY HH:mm A')}</span> : '-'),
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">Expire At</span>, 'expiresOn', column),
            key: 'expiresOn',
            dataIndex: 'expiresOn',
            align: 'left',
            width: 500,
            sorter: logSessions ? (logSessions.total > 1 ? true : false) : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (value ? <span className="tabletext">{moment(value).format('MMM DD, YYYY HH:mm A')}</span> : '-'),
        },
        {
            title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
            key: 'Actions',
            align: 'center',
            dataIndex: 'Actions',
            width: 200,
            render: (item, record) => {
                if (record.token === myToken) return (<>Self</>);
                return (
                    <div className='actionCol'>
                        {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
                            <Tooltip
                                title="Logout"
                            >
                                {!record?.logoutIp
                                    ? (
                                        (new Date(record?.expiresOn) > new Date()) ?
                                            <LogOutIcon
                                                className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                                                onClick={() => {
                                                    setLogoutModalData(record);
                                                    setLogoutModalVisible(true);
                                                }}
                                            /> :
                                            "Expired"
                                    ) : ("Logged Out")}
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <div className='appuserLayout'>
            {/* Table  */}
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table
                        columns={columns}
                        dataSource={logSessions?.data}
                        pagination={false}
                        rowKey='id'
                        loading={tableLoading}
                        onChange={onChange}
                        scroll={{ x: 2000 }}

                    />

                    {logSessions?.total > 10 && (
                        <div className='text-end bg-white mt-7'>
                            <PaginationCom
                                onChange={onChangePage}
                                showSizeChanger={true}
                                current={currentPage}
                                showLessItems={true}
                                total={logSessions?.total}
                            />
                        </div>
                    )}
                </Col>
            </Row>
            {/* LogOut Modal */}
            <LogOutModal
                visible={logoutModalVisible}
                setVisible={setLogoutModalVisible}
                handleLogOut={logoutUser}
            />
        </div>
    );
}

LoginSessions.propTypes = {
    permissions: PropTypes.any
};

export default LoginSessions;