 import { Tabs } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CITY_STATE_STRINGS } from '../../services/strings';
import CityTabList from './Tabs/City/CityTabList';
import CouncilesTabList from './Tabs/Councile/CouncilesTabList';
import CountryTabList from './Tabs/Country/CountryTabList';
import StateTabList from './Tabs/State/StateTabList';
import SuburbsTabList from './Tabs/Suburbs/SuburbsTabList';
import PropTypes from 'prop-types';
const { TabPane } = Tabs;

const CityStatePage = ({ permissions }) => {
    const  tabState = useLocation().state?.tabState;
    return (
        <div>
            <Tabs className='propertyDetailsTabs cityStateTabs' defaultActiveKey={tabState}  defaultValue={tabState} color="#324666">
                <TabPane tab={CITY_STATE_STRINGS.TABS.COUNTRY} key="Country" >
                    <CountryTabList permissions={permissions}/>
                </TabPane>
                <TabPane tab={CITY_STATE_STRINGS.TABS.STATE} key="State" >
                    <StateTabList permissions={permissions}/>
                </TabPane>
                <TabPane tab={CITY_STATE_STRINGS.TABS.CITY} key="City" >
                    <CityTabList permissions={permissions}/>
                </TabPane>
                <TabPane tab={CITY_STATE_STRINGS.TABS.SUBURBS} key="Suburbs" >
                    <SuburbsTabList permissions={permissions}/>
                </TabPane>
                <TabPane tab={CITY_STATE_STRINGS.TABS.COUNCIL} key="Council" >
                    <CouncilesTabList permissions={permissions}/>
                </TabPane>
            </Tabs>
        </div>
    );
}

CityStatePage.propTypes = {
    permissions: PropTypes.any
}
  
export default CityStatePage;
