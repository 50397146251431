import { methods, serviceMaker } from '../service';

const GET_COUNTRY = (id) => serviceMaker(`countries/${id}`, methods.GET);

const GET_COUNTRIES = (
  limit,
  skip,
  search = null,
  sort = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/countries`, methods.GET, query);
  }

  return serviceMaker(`countries`, methods.GET, query);
};

const DELETE_COUNTRY = (id) => serviceMaker(`countries/${id}`, methods.DELETE);

const CREATE_COUNTRY = (payload) =>
  serviceMaker(`countries`, methods.POST, null, payload);

const PATCH_COUNTRY = (id, payload) =>
  serviceMaker(`countries/${id}`, methods.PATCH, null, payload);

const COUNTRIES = {
  FIND: GET_COUNTRY,
  GET: GET_COUNTRIES,
  CREATE: CREATE_COUNTRY,
  PATCH: PATCH_COUNTRY,
  DELETE: DELETE_COUNTRY
};

export { COUNTRIES };
