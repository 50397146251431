import { methods, serviceMaker } from '../service';

const GET_REDEEM_CATEGORIES = (id) => serviceMaker(`redeem-categories/${id}`, methods.GET);

const GET_REDEEM_CATEGORIESS = (
  limit,
  skip,
  search = null,
  sort = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }

  if (search) {
    query['params']['search'] = search;
  }

  return serviceMaker(`redeem-categories`, methods.GET, query);
};

const DELETE_REDEEM_CATEGORIESS = (id) => serviceMaker(`redeem-categories/${id}`, methods.DELETE);

const CREATE_REDEEM_CATEGORIESS = (payload) =>
  serviceMaker(`redeem-categories`, methods.POST, null, payload);

const PATCH_REDEEM_CATEGORIESS = (id, payload) =>
  serviceMaker(`redeem-categories/${id}`, methods.PATCH, null, payload);

const REDEEM_CATEGORIES = {
  FIND: GET_REDEEM_CATEGORIES,
  GET: GET_REDEEM_CATEGORIESS,
  CREATE: CREATE_REDEEM_CATEGORIESS,
  PATCH: PATCH_REDEEM_CATEGORIESS,
  DELETE: DELETE_REDEEM_CATEGORIESS
};

export { REDEEM_CATEGORIES };
