import { Checkbox, Col, Drawer, Image, Input, Row, Table, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import PaginationCom from '../../components/PaginationCom';
import { tableSortIconToggle } from '../../utility/tableSorting';
import DeleteModel from '../../components/DeleteModel';
import { toast } from 'react-toastify';
import PencileIcon from '../../components/Icon/PencileIcon';
import DeleteIcon from '../../components/Icon/DeleteIcon';
import { useNavigate } from 'react-router-dom';
import { ADVERTISEMENT } from '../../services/Advertisement/Advertisement';
import InfoCircleIcon from '../../components/Icon/InfoCircleIcon';
import { ADVERTISEMENTS_STRINGS, COMMON_ALERTS, COMMON_STRING, FILTER_STRING, TABLE_STRING } from '../../services/strings';
import PropTypes from 'prop-types';
import FallbackImage from '../../components/Icon/FallbackImage';
import { BASE_URL } from '../../services/constant';
import moment from 'moment';

const Advertisements = ({ permissions }) => {
  const navigate = useNavigate();
  const searchInp = useRef();
  const [Advertisements, setAdvertisements] = useState(null);
  const [tableLoading, setTableLoading] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [sort, setSort] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [iOSCheckBx, setiOSCheckBx] = useState(false);
  const [AndroidCheckBx, setAndroidCheckBx] = useState(false);
  const [WebCheckBx, setWebCheckBx] = useState(false);
  const [ActiveCheckBx, setActiveCheckBx] = useState(false);
  const [viewContractVisible, setviewContractVisible] = useState(false);
  const [AdvertisementDetails, setAdvertisementDetails] = useState(null);

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      // setSort({ createdAt: -1 });
    }
  };


  const deleteAdvertisement = async () => {
    try {
      const res = await ADVERTISEMENT.DELETE(deleteId);
      if (res.status) {
        toast.success(COMMON_ALERTS.DELETED_SUCCESS('Advertisement'));
        setIsDeleteModalVisible(false);
        fetchAdvertisements(currentPage, pageSize, search, sort);
      }
    }
    catch (e) {
      // console.log(e);
    }
  }

  const fetchAdvertisements = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null,
    isIos = null,
    isAndroid = null,
    isWeb = null,
    isActive = null
  ) => {
    setTableLoading(true);
    try {
      const res = await ADVERTISEMENT.GET(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        isIos,
        isAndroid,
        isWeb,
        isActive
      );
      if (res.status === 1 || res.status === '1') {
        setAdvertisements(res);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setTableLoading(false);
    }
  };

  const handleChangeOfSearch = (e) => {
    setTempSearch(e.target.value);
    if (!e.target.value) {
      setCurrentPage(1);
      setSearch(null);
      setTempSearch(null);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  const onAdDetails = (ad) => {
    setAdvertisementDetails(ad);
    setviewContractVisible(true);
  }

  useEffect(() => {
    fetchAdvertisements(currentPage, pageSize, search, sort, iOSCheckBx, AndroidCheckBx, WebCheckBx, ActiveCheckBx);
  }, [currentPage, pageSize, search, sort, iOSCheckBx, AndroidCheckBx, WebCheckBx, ActiveCheckBx]);

  const columns = [
    {
      title: (column) => <span className="tableTitle">{ADVERTISEMENTS_STRINGS.TABLE.COVER_IMAGE}</span>,
      dataIndex: 'media_item',
      key: 'media_item',
      align: 'left',
      width: 140,
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => <div className="adImageInRow"><Image
        fallback={FallbackImage}
        src={BASE_URL + value?.image?.slice(1)} /></div>,
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{ADVERTISEMENTS_STRINGS.TABLE.TITLE}</span>, 'title', column),
      dataIndex: 'title',
      key: 'title',
      align: 'left',

      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{ADVERTISEMENTS_STRINGS.TABLE.CLIENT_URL}</span>, 'redirect_url', column),
      dataIndex: 'redirect_url',
      key: 'redirect_url',
      align: 'left',
      sorter: true,
      width: 220,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext"><a href={value}>{value}</a></span> : '-',
    },
    {
      title: (column) => <span className="tableTitle" onClick={() => setiOSCheckBx(!iOSCheckBx)} >{ADVERTISEMENTS_STRINGS.TABLE.iOS} <Checkbox checked={iOSCheckBx} /></span>,
      dataIndex: 'isIOS',
      key: 'isIOS',
      align: 'center',
      sorter: false,
      width: 90,
      sortDirections: ['ascend', 'descend'],
      render: (value) => <Checkbox checked={value ? true : false} />,
    },
    {
      title: (column) => <span className="tableTitle" onClick={() => setAndroidCheckBx(!AndroidCheckBx)}>{ADVERTISEMENTS_STRINGS.TABLE.ANDROID} <Checkbox checked={AndroidCheckBx} /></span>,
      dataIndex: 'isAndroid',
      key: 'isAndroid',
      align: 'center',
      width: 130,
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => <Checkbox checked={value ? true : false} />,
    },
    {
      title: (column) => <span className="tableTitle" onClick={() => setWebCheckBx(!WebCheckBx)}>{ADVERTISEMENTS_STRINGS.TABLE.WEB} <Checkbox checked={WebCheckBx} /></span>,
      dataIndex: 'isWeb',
      key: 'isWeb',
      align: 'left',
      width: 120,
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => <Checkbox checked={value ? true : false} />,
    },
    {
      title: (column) => <span className="tableTitle" onClick={() => setActiveCheckBx(!ActiveCheckBx)}>{ADVERTISEMENTS_STRINGS.TABLE.ACTIVE} <Checkbox checked={ActiveCheckBx} /></span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'left',
      width: 140,
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => <Checkbox checked={value} />,
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{ADVERTISEMENTS_STRINGS.TABLE.DISPLAYED}</span>, 'displayed_count', column),
      dataIndex: 'displayed_count',
      key: 'displayed_count',
      align: 'left',
      width: 60,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) => <span className="tabletext">{value}</span>,
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{ADVERTISEMENTS_STRINGS.TABLE.CLICKED}</span>, 'clicked_count', column),
      dataIndex: 'clicked_count',
      key: 'clicked_count',
      align: 'center',
      width: 60,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (value) => <span className="tabletext">{value}</span>,
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      width: 130,
      key: 'Actions',
      align: 'center',
      dataIndex: 'Actions',
      render: (item, record) => {
        return (
          <div className='actionCol'>
            <Tooltip title='View Advertisement'>
              <InfoCircleIcon
                onClick={() => onAdDetails(record)}
                className='delete-btn-hover cursor-pointer mx-1' />
            </Tooltip>
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title='Edit Advertisement'>
                <PencileIcon
                  onClick={() => navigate('/advertisement/edit', { state: { advetisementId: record.id } })}
                  className='delete-btn-hover cursor-pointer mx-1' />
              </Tooltip>
            )}
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Advertisement'>
                <DeleteIcon
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className='commonWrapperLayout'>
      <div className="headerContain">
        <div className="searchBox">
          <label className='searchInpLabel' htmlFor={searchInp}>{FILTER_STRING.SEARCH}</label>
          <Input
            ref={searchInp}
            type='search'
            placeholder={COMMON_STRING.SEARCH_STR + 'Advertisement'}
            className='input-control searchInpExpense'
            value={tempSearch}
            allowClear
            onChange={(e) => handleChangeOfSearch(e)}
            onKeyPress={(e) => {
              if (e?.key === 'Enter' && e.target.value) {
                handleSearch();
              }
            }}
          />
        </div>
        <div className="descheduleBtnBox">
          <button onClick={() => navigate('/advertisements/add')} className="addDepScheduleBtn">{ADVERTISEMENTS_STRINGS.BUTTON.CREATE_NEW_BTN}</button>
        </div>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-3">
          <Table
            columns={columns}
            dataSource={Advertisements?.data}
            pagination={false}
            rowKey='id'
            loading={tableLoading}
            onChange={onChange}
            scroll={{ x: 1500 }}
          />

          {Advertisements?.total > 10 && (
            <div className='text-end bg-white mt-7'>
              <PaginationCom
                onChange={onChangePage}
                showSizeChanger={true}
                current={currentPage}
                showLessItems={true}
                total={Advertisements?.total}
              />
            </div>
          )}
        </Col>
      </Row>
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteAdvertisement}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
      <Drawer
        width={400}
        title="Advertisement Info"
        placement="right"
        onClose={
          () => setviewContractVisible(false)
        }
        visible={viewContractVisible}
        open={viewContractVisible}
      >
        <div className="drawerProfileImg">
          <Image crossOrigin="anonymous" src={BASE_URL + AdvertisementDetails?.media_item?.image} alt="Advertisement Image" fallback={FallbackImage} />
        </div>
        <table className="userDetailDrawerTable">
          <tr>
            <td><span className="sideDrawDetailsTitle">{ADVERTISEMENTS_STRINGS.VIEW.ADVERTISE_TITLE}</span></td><td>{AdvertisementDetails?.title}</td>
          </tr>
          <tr>
            <td className="sideDrawDetailsTitle">{ADVERTISEMENTS_STRINGS.VIEW.REDIRECT_URL}</td><td>{AdvertisementDetails?.redirect_url}</td>
          </tr>
          <tr>
            <td className="sideDrawDetailsTitle">{ADVERTISEMENTS_STRINGS.VIEW.ADVERTISEMENT_TYPE}</td><td>{AdvertisementDetails?.ad_type}</td>
          </tr>
          <tr>
            <td className="sideDrawDetailsTitle">{ADVERTISEMENTS_STRINGS.VIEW.TAB}</td><td>{AdvertisementDetails?.subcategory?.subtab?.tab?.name ? AdvertisementDetails?.subcategory.subtab.tab.name : '-'}</td>
          </tr>
          <tr>
            <td className="sideDrawDetailsTitle">{ADVERTISEMENTS_STRINGS.VIEW.SUB_TAB}</td><td>{AdvertisementDetails?.subcategory?.subtab?.name ? AdvertisementDetails?.subcategory.subtab.name : '-'}</td>
          </tr>
          <tr>
            <td className="sideDrawDetailsTitle">{ADVERTISEMENTS_STRINGS.VIEW.SECTION}</td><td>{AdvertisementDetails?.subcategory?.name ? AdvertisementDetails?.subcategory.name : '-'}</td>
          </tr>
          <tr>
            <td className="sideDrawDetailsTitle">{ADVERTISEMENTS_STRINGS.VIEW.IMPRESSION_COUNT}</td><td>{AdvertisementDetails?.impression_count ? AdvertisementDetails?.impression_count : '-'}</td>
          </tr>
          <tr>
            <td className="sideDrawDetailsTitle">{ADVERTISEMENTS_STRINGS.VIEW.START_DATE}</td><td>{AdvertisementDetails?.startDate ? moment(AdvertisementDetails?.startDate).format('DD MMM, YYYY') : '-'}</td>
          </tr>
          <tr>
            <td className="sideDrawDetailsTitle">{ADVERTISEMENTS_STRINGS.VIEW.END_DATE}</td><td>{AdvertisementDetails?.endDate ? moment(AdvertisementDetails?.endDate).format('DD MMM, YYYY') : '-'}</td>
          </tr>
        </table>
      </Drawer>
    </div>
  );
}

Advertisements.propTypes = {
  permissions: PropTypes.any
}

export default Advertisements;
