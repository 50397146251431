 import React from 'react';

const AdvertiseIcon = () => {
  return (
    <>
      <span className='anticon anticon-advertisement ant-menu-item-icon'>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect className='extraRect' x="1" y="1" width="18" height="18" rx="4" fill="#B7C5DC"/>
        <path className='extrapath' d="M7.79297 8.07031L6.09375 13H5.06641L7.20703 7.3125H7.86328L7.79297 8.07031ZM9.21484 13L7.51172 8.07031L7.4375 7.3125H8.09766L10.2461 13H9.21484ZM9.13281 10.8906V11.668H6.03906V10.8906H9.13281ZM12.5352 13H11.3203L11.3281 12.2227H12.5352C12.8867 12.2227 13.181 12.1458 13.418 11.9922C13.6576 11.8385 13.8372 11.6185 13.957 11.332C14.0794 11.0456 14.1406 10.7044 14.1406 10.3086V10C14.1406 9.69271 14.1055 9.42057 14.0352 9.18359C13.9674 8.94661 13.8659 8.7474 13.7305 8.58594C13.5977 8.42448 13.4336 8.30208 13.2383 8.21875C13.0456 8.13542 12.8229 8.09375 12.5703 8.09375H11.2969V7.3125H12.5703C12.9479 7.3125 13.293 7.3763 13.6055 7.50391C13.918 7.62891 14.1875 7.8099 14.4141 8.04688C14.6432 8.28385 14.819 8.56771 14.9414 8.89844C15.0638 9.22917 15.125 9.59896 15.125 10.0078V10.3086C15.125 10.7174 15.0638 11.0872 14.9414 11.418C14.819 11.7487 14.6432 12.0326 14.4141 12.2695C14.1849 12.5039 13.9115 12.6849 13.5938 12.8125C13.2786 12.9375 12.9258 13 12.5352 13ZM11.8633 7.3125V13H10.8828V7.3125H11.8633Z" fill="#324666"/>
        </svg>
      </span>
    </>
  );
};

export default AdvertiseIcon;
