 import React from "react";
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import TableSortIcon from "../components/Icon/TableSortIcon";

const tableSortIconToggle = (title, key, column) => {
  if (column?.sortColumn?.key === key) {
    return (
      <span className="thead-tr-sorting">
        {title}
        {column.sortOrder === undefined ? (
          <TableSortIcon
            className="ml-1"
            style={{ transform: "rotate(90deg)" }}
          />
        ) : column.sortOrder === "ascend" ? (
          <TableSortIcon className="ml-1" />
        ) : (
          <TableSortIcon className="ml-1" />
        )}
      </span>
    );
  } else {
    return (
      <span className="thead-tr-sorting">
        {title}
        <TableSortIcon className="ml-1" style={{ transform: "rotate(90deg)" }} />
      </span>
    );
  }
};
export { tableSortIconToggle };
