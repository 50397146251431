import { methods, serviceMaker } from '../service';

const GET_COUNCIL = (id) => serviceMaker(`council/${id}`, methods.GET);

const GET_COUNCILS = (
  limit,
  skip,
  search = null,
  sort = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/council`, methods.GET, query);
  }

  return serviceMaker(`council`, methods.GET, query);
};

const DELETE_COUNCIL = (id) => serviceMaker(`council/${id}`, methods.DELETE);

const CREATE_COUNCIL = (payload) =>
  serviceMaker(`council`, methods.POST, null, payload);

const PATCH_COUNCIL = (id, payload) =>
  serviceMaker(`council/${id}`, methods.PATCH, null, payload);

const COUNCILES = {
  FIND: GET_COUNCIL,
  GET: GET_COUNCILS,
  CREATE: CREATE_COUNCIL,
  PATCH: PATCH_COUNCIL,
  DELETE: DELETE_COUNCIL
};

export { COUNCILES };
