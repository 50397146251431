import moment from 'moment';
import { methods, serviceMaker } from '../service';

const GET_REDEEM_SERVICE = (id) => serviceMaker(`redeem-services/${id}`, methods.GET);

const GET_REDEEM_SERVICES = (
  limit,
  skip,
  search = null,
  sort = null,
  filterStartDate = null,
  filterEndDate = null,
  countryId = null,
  stateId = null,
  cityId = null,
  suburbId = null,
  pdAmount = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }

  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/redeem-services`, methods.GET, query);
  }

  if (filterStartDate) {
    query['params']['startDate'] = moment(filterStartDate).toDate();
  }

  if (filterEndDate) {
    query['params']['endDate'] = moment(filterEndDate).toDate();
  }

  if (countryId) {
    query['params']['countryId'] = countryId;
  }

  if (stateId) {
    query['params']['stateId'] = stateId;
  }

  if (cityId) {
    query['params']['cityId'] = cityId;
  }

  if (suburbId) {
    query['params']['suburbId'] = suburbId;
  }

  if (pdAmount) {
    query['params']['pdAmount'] = pdAmount;
  }

  return serviceMaker(`redeem-services`, methods.GET, query);
};

const DELETE_REDEEM_SERVICES = (id) => serviceMaker(`redeem-services/${id}`, methods.DELETE);

const CREATE_REDEEM_SERVICES = (payload) =>
  serviceMaker(`redeem-services`, methods.POST, null, payload);

const PATCH_REDEEM_SERVICES = (id, payload) =>
  serviceMaker(`redeem-services/${id}`, methods.PATCH, null, payload);

const DISTCOLS_REDEEM_SERVICES = (column_name) =>
  serviceMaker(`redeem-services?distict=${column_name}`, methods.GET);

const REDEEM_SERVICES = {
  FIND: GET_REDEEM_SERVICE,
  GET: GET_REDEEM_SERVICES,
  CREATE: CREATE_REDEEM_SERVICES,
  PATCH: PATCH_REDEEM_SERVICES,
  DELETE: DELETE_REDEEM_SERVICES,
  DISTCOLS: DISTCOLS_REDEEM_SERVICES
};

export { REDEEM_SERVICES };
