import { Col, Form, Radio, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import propTypes from 'prop-types';
import { COMMON_ALERTS, COMMON_STRING, NOTIFICATIONS_STRINGS } from '../../../../services/strings';
import { getLSItem, setLSItem } from '../../../../services/auth/encryptLogin';

const NotificationType = ({ changeTab, notification }) => {
    const [NotificationTypeValue, setNotificationTypeValue] = useState(null);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onChange = (e) => {
        setNotificationTypeValue(e.target.value);
    }

    useEffect(() => {
        const ex_data_str = getLSItem('notification-step-1');
        if (ex_data_str !== null) {
            const ex_data = JSON.parse(ex_data_str);
            form.setFieldsValue(ex_data);
        }
    }, []);

    useEffect(() => {
        const ex_data_str = getLSItem('notification-step-1');
        if (ex_data_str !== null) {
            const ex_data = JSON.parse(ex_data_str);
            form.setFieldsValue(ex_data);
        }
    }, [notification]);

    const onFinish = (formdata) => {
        setLSItem('notification-step-1', JSON.stringify(formdata));
        changeTab(1);
    }

    return (
        <>

            <Form form={form} onFinish={onFinish} colon={false}>
                <Row>
                    <span className="titleText">{NOTIFICATIONS_STRINGS.STEP1.SELECT_NOTIFICATION_TYPE}</span>
                </Row>
                <Row className='notfication_type_box'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item name='notification_type' label={<span className="inputTitle nTypeLbel">{NOTIFICATIONS_STRINGS.STEP1.SELECT_NOTIFICATION_TYPE}</span>} rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('notification type') }]}>
                            <Radio.Group onChange={onChange} value={NotificationTypeValue}>
                                <Radio value={'general'} className="radioBtn">{NOTIFICATIONS_STRINGS.STEP1.GENERAL}</Radio>
                                <Radio value={'property_update'} className="radioBtn">{NOTIFICATIONS_STRINGS.STEP1.PROPERTY_UPDATE}</Radio>
                                <Radio value={'service_update'} className="radioBtn">{NOTIFICATIONS_STRINGS.STEP1.SERVICE_UPDATE}</Radio>
                                <Radio value={'goal_reminder'} className="radioBtn">{NOTIFICATIONS_STRINGS.STEP1.GOAL_REMINDER}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="btnBox">
                        <div className="backBtn" onClick={() => navigate('/notifications')}>{COMMON_STRING.BACK_BTN}</div>
                        <button type='submit' className="createBtn">{COMMON_STRING.NEXT_BTN}</button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

NotificationType.propTypes = {
    changeTab: propTypes.func,
    notification: propTypes.object
}

export default NotificationType;
