import { Col, Row, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PencileIcon from '../../components/Icon/PencileIcon';
import ValidateIcon from '../../components/Icon/ValidateIcon';
import { CALCULATOR } from '../../services/Calculators/Calculators';
import { CALCULATOR_INFO_STRING, TABLE_STRING } from '../../services/strings';
import PropTypes from 'prop-types';

const CalculatorsInfo = ({ permissions }) => {
  const navigate = useNavigate();
  const [CalculatorsData, setCalculatorsData] = useState([]);

  const fetchCalculators = async () => {
    const calculators = await CALCULATOR.GET();
    if (calculators?.status) {
      setCalculatorsData(calculators.data);
    }
  }

  useEffect(() => {
    fetchCalculators();
  }, []);

  const isNameExists = (name) => {
    const res = CalculatorsData.filter(elem => (elem.name == name));
    return res.length;
  }

  const InfoCheckBtn = ({ name, navlink }) => {
    const isExists = isNameExists(name);
    if (permissions?.isUpdate || permissions?.isUpdate === undefined) {
      if (isExists) {
        return (<ValidateIcon />);
      }
      else {
        return (<span className="addInfoBtn" onClick={() => navigate(navlink)}>{CALCULATOR_INFO_STRING.BUTTONS.ADD_INFO}</span>);
      }
    }
    else {
      return (<></>);
    }
  }

  InfoCheckBtn.propTypes = {
    name: PropTypes.string,
    navlink: PropTypes.string
  }

  const columns = [
    {
      title: <span className="tableTitle">{CALCULATOR_INFO_STRING.TABLE.COLUMNS.CALCULATOR_NAME}</span>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <span className="tableTitle">{CALCULATOR_INFO_STRING.TABLE.COLUMNS.INFO_ADDED}</span>,
      dataIndex: 'info_check',
      align: 'center',
      key: 'info_check',
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 200
    },
  ];

  const Calculators = [
    {
      name: <span className="tabletext">{CALCULATOR_INFO_STRING.TABLE.ROWS.STAMP_DUTY}</span>,
      info_check: <InfoCheckBtn name="Stamp Duty Calculator" navlink="/calculator_info/stamp_duty" />,
      actions: <div className='actionCol'>
        {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
          <Tooltip title='Edit Stamp Duty Calculator'>
            <PencileIcon onClick={() => navigate('/calculator_info/stamp_duty', { state: { Calculator: { title: "Stamp Duty Calculator" } } })} className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
          </Tooltip>
        )}
      </div>
    },
    {
      name: <span className="tabletext">{CALCULATOR_INFO_STRING.TABLE.ROWS.BORROWING_CAPACITY}</span>,
      info_check: <InfoCheckBtn name="Borrowing Capacity" navlink="/calculator_info/borrowing_capacity" />,
      actions: <div className='actionCol'>
        {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
          <Tooltip title='Edit Borrowing Capacity'>
            <PencileIcon onClick={() => navigate('/calculator_info/borrowing_capacity', { state: { Calculator: { title: "Borrowing Capacity" } } })} className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
          </Tooltip>
        )}
      </div>
    },
    {
      name: <span className="tabletext">{CALCULATOR_INFO_STRING.TABLE.ROWS.REPAYMENT}</span>,
      info_check: <InfoCheckBtn name="Repayment Calculator" navlink="/calculator_info/repayment_calc" />,
      actions: <div className='actionCol'>
        {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
          <Tooltip title='Edit Repayment Calculator'>
            <PencileIcon onClick={() => navigate('/calculator_info/repayment_calc', { state: { Calculator: { title: "Repayment Calculator" } } })} className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
          </Tooltip>
        )}
      </div>
    },
    {
      name: <span className="tabletext">{CALCULATOR_INFO_STRING.TABLE.ROWS.EARLY_REPAYMENT}</span>,
      info_check: <InfoCheckBtn name="Early Repayment Calculator" navlink="/calculator_info/early_payment_calc" />,
      actions: <div className='actionCol'>
        {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
          <Tooltip title='Edit Early Repayment Calculator'>
            <PencileIcon onClick={() => navigate('/calculator_info/early_payment_calc', { state: { Calculator: { title: "Early Repayment Calculator" } } })} className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
          </Tooltip>
        )}
      </div>
    }
  ];

  return (
    <div className='calculatorInfoPage'>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            columns={columns}
            dataSource={Calculators}
            pagination={false}
            rowKey='id'
            scroll={{ x: 400 }}
          />
        </Col>
      </Row>
    </div>
  );
}

CalculatorsInfo.propTypes = {
  permissions: PropTypes.any
}


export default CalculatorsInfo;
