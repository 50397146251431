import { methods, serviceMaker } from '../service';

const GET_LENDER = (id) => serviceMaker(`lenders/${id}`, methods.GET);

const GET_LENDERS = (
  limit,
  skip,
  search = null,
  sort = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
  }

  return serviceMaker(`lenders`, methods.GET, query);
};

const DELETE_LENDERS = (id) => serviceMaker(`lenders/${id}`, methods.DELETE);

const CREATE_LENDERS = (payload) =>
  serviceMaker(`lenders`, methods.POST, null, payload);

const PATCH_LENDERS = (id, payload) =>
  serviceMaker(`lenders/${id}`, methods.PATCH, null, payload);

const LENDERS = {
  FIND: GET_LENDERS,
  GET: GET_LENDER,
  CREATE: CREATE_LENDERS,
  PATCH: PATCH_LENDERS,
  DELETE: DELETE_LENDERS
};

export { LENDERS };
