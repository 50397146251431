import React, { useEffect, useRef, useState } from 'react';
import { Col, Input, Row, Select, Table, Tooltip } from 'antd';
import PaginationCom from '../../components/PaginationCom';
import { tableSortIconToggle } from '../../utility/tableSorting';
import DeleteModel from '../../components/DeleteModel';
import PencileIcon from '../../components/Icon/PencileIcon';
import DeleteIcon from '../../components/Icon/DeleteIcon';
import { useNavigate } from 'react-router-dom';
import { AGENTS_STRINGS, COMMON_ALERTS, COMMON_STRING, FILTER_STRING, TABLE_STRING } from '../../services/strings';
import FilterToogleBtn from '../../components/FilterToogleBtn';
import InfoCircleIcon from '../../components/Icon/InfoCircleIcon';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { AGENTS } from '../../services/Agents/Agents';
import SelectArrow from '../../components/Icon/SelectArrow';

const AgentsPage = ({ permissions }) => {
  const navigate = useNavigate();
  const searchInp = useRef();
  const [Agents, setAgents] = useState(null);
  const [tableLoading, setTableLoading] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [sort, setSort] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [filterBusinessType, setFilterBusinessType]= useState('');

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const deleteAgent = async () => {
    try {
      const res = await AGENTS.DELETE(deleteId);
      if (res.status) {
        toast.success(COMMON_ALERTS.DELETED_SUCCESS('Agent'));
        setIsDeleteModalVisible(false);
        fetchService(currentPage, pageSize, search, sort);
      }
    }
    catch (e) {
      // console.log(e);
    }
  }

  const fetchService = async (
    page = 1,
    pageSize = 10,
    search = null,
    sort = null
  ) => {
    setTableLoading(true);
    try {
      const res = await AGENTS.GET(
        pageSize,
        (page - 1) * pageSize,
        search,
        sort,
        filterBusinessType
      );
      if (res.status === 1 || res.status === '1') {
        setAgents(res);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setTableLoading(false);
    }
  };

  const handleChangeOfSearch = (e) => {
    setTempSearch(e.target.value);
    if (!e.target.value) {
      setCurrentPage(1);
      setSearch(null);
      setTempSearch(null);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setSearch(tempSearch);
  };

  useEffect(() => {
    fetchService(currentPage, pageSize, search, sort);
  }, [currentPage, pageSize, search, sort, filterBusinessType]);

  const columns = [
    {
      title: (column) => <span className="tableTitle">{AGENTS_STRINGS.TABLE.BUSINESS_TYPE}</span>,
      dataIndex: 'businessType',
      key: 'businessType',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{AGENTS_STRINGS.TABLE.ORGANIZATION_NAME}</span>, 'name', column),
      dataIndex: 'orgName',
      key: 'orgName',
      align: 'left',
      sorter: Agents?.data?.length > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{AGENTS_STRINGS.TABLE.ADDRESS}</span>, 'address', column),
      dataIndex: 'agents_businesses.businessAddress',
      key: 'agents_businesses.businessAddress',
      align: 'left',
      sorter: Agents?.data?.length > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{AGENTS_STRINGS.TABLE.CONTACT}</span>, 'contact', column),
      dataIndex: 'agents_businesses.businessContact',
      key: 'agents_businesses.businessContact',
      align: 'left',
      sorter: Agents?.data?.length > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) =>
        value ? <span className="tabletext">+{record['agents_businesses.businessContactCode']} {value}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{AGENTS_STRINGS.TABLE.BUSINESS_UNIT_NAME}</span>, 'contactno', column),
      dataIndex: 'agents_businesses.businessName',
      key: 'agents_businesses.businessName',
      align: 'left',
      sorter: Agents?.data?.length > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value}</span> : '-',
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      width: 150,
      key: 'Actions',
      align: 'center',
      dataIndex: 'Actions',
      render: (item, record) => {
        return (
          <div className='actionCol'>
            {(permissions?.isView || permissions?.isView === undefined) && (
              <Tooltip title={AGENTS_STRINGS.BTN.VIEW_AGENT}>
                <InfoCircleIcon
                  onClick={() => navigate('/agents/view', { state: { agentId: record.id, isView: true } })}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
              </Tooltip>
            )}
            {(permissions?.isUpdate || permissions?.isUpdate === undefined) && (
              <Tooltip title={AGENTS_STRINGS.BTN.EDIT_AGENT}>
                <PencileIcon
                  onClick={() => navigate('/agents/edit', { state: { agentId: record.id } })}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
              </Tooltip>
            )}
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title={AGENTS_STRINGS.BTN.DELETE_AGENT}>
                <DeleteIcon
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record['agents_businesses.id']);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1'
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className='commonWrapperLayout'>
      <div className="headerContain">
        <div className="searchBox">
          {/* <label className='searchInpLabel' htmlFor={searchInp}>{FILTER_STRING.SEARCH}</label>
          <Input
            ref={searchInp}
            type='search'
            placeholder={COMMON_STRING.SEARCH_STR + AGENTS_STRINGS.LABEL}
            className='input-control searchInpExpense'
            value={tempSearch}
            allowClear
            onChange={(e) => handleChangeOfSearch(e)}
            onKeyPress={(e) => {
              if (e?.key === 'Enter' && e.target.value) {
                handleSearch();
              }
            }}
          /> */}
        </div>
        <div className="descheduleBtnBox">
          <button onClick={() => navigate('/agents/add')} className="addDepScheduleBtn">{AGENTS_STRINGS.BTN.ADD_AGENT}</button>
          <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} />
        </div>
      </div>
      <Row className={isFilterShow ? 'mt-3' : 'd-none'}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="filterSelectBox filterCard">
          <div className="searchBox">
            <label className='searchInpLabel' htmlFor={searchInp}>{FILTER_STRING.SEARCH}</label>
            <Input
              ref={searchInp}
              type='search'
              placeholder={COMMON_STRING.SEARCH_STR + AGENTS_STRINGS.LABEL}
              className='input-control searchInpExpense'
              value={tempSearch}
              allowClear
              onChange={(e) => handleChangeOfSearch(e)}
              onKeyPress={(e) => {
                if (e?.key === 'Enter' && e.target.value) {
                  handleSearch();
                }
              }}
            />
          </div>
          <div className="selectBoxWithSearch ml-2">
            <div className="filterSelect">
              <Select defaultValue={AGENTS_STRINGS.FILTER.BUSINESS_TYPE} suffixIcon={<SelectArrow />} className="m-auto cursor-pointer filterDropdown" bordered={false} onChange={e => { setCurrentPage(1); setFilterBusinessType(e); }}><Select.Option value=''>{AGENTS_STRINGS.FILTER.BUSINESS_TYPE}</Select.Option>{AGENTS_STRINGS.BUSINESS_TYPES.map((name, key) => <Select.Option key={key} value={name}>{name}</Select.Option>)}</Select>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-3">
          <Table
            columns={columns}
            dataSource={Agents?.data}
            pagination={false}
            rowKey='id'
            loading={tableLoading}
            onChange={onChange}
            scroll={{ x: 400 }}
          />

          {Agents?.total > 10 && (
            <div className='text-end bg-white mt-7'>
              <PaginationCom
                onChange={onChangePage}
                showSizeChanger={true}
                current={currentPage}
                showLessItems={true}
                total={Agents?.total}
              />
            </div>
          )}
        </Col>
      </Row>
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteAgent}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    </div>
  );
}

AgentsPage.propTypes = {
  permissions: PropTypes.any,
}

export default AgentsPage;
