import { methods, serviceMaker } from '../service';

const GET_DEPRECIATION_SCHEDULES = (
  limit,
  skip,
  search = null,
  sort = null,
  propertyId
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip,
      propertyId: propertyId
    },
  };
  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/depreciation-schedules`, methods.GET, query);
  }
  return serviceMaker(`depreciation-schedules`, methods.GET, query);
};

const GET_DEPRECIATION_SCHEDULE = (id) => {
  return serviceMaker(`depreciation-schedules/${id}`, methods.GET);
}

const DELETE_DEPRECIATION_SCHEDULE = (id) => serviceMaker(`depreciation-schedules/${id}`, methods.DELETE);

const CREATE_CONTACT = (payload) =>
  serviceMaker(`depreciation-schedules`, methods.POST, null, payload);

const PATCH_CONTACT = (id, payload) =>
  serviceMaker(`depreciation-schedules/${id}`, methods.PATCH, null, payload);

const DEPSCHEDULES = { //Depreciation Schedules
  ALL: GET_DEPRECIATION_SCHEDULES,
  GET: GET_DEPRECIATION_SCHEDULE,
  DELETE: DELETE_DEPRECIATION_SCHEDULE,
  CREATE: CREATE_CONTACT,
  PATCH: PATCH_CONTACT
};
export { DEPSCHEDULES };
