import { Checkbox, Col, DatePicker, Form, Input, Row, Select, Slider, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import CalenderDarkIcon from '../../../components/Icon/CalenderDarkIcon';
import SelectArrow from '../../../components/Icon/SelectArrow';
import PaginationCom from '../../../components/PaginationCom';
import { tableSortIconToggle } from '../../../utility/tableSorting';
import PropTypes from 'prop-types';
import FilterToogleBtn from '../../../components/FilterToogleBtn';
import { APP_USERS } from '../../../services';
import { PROPERTIES } from '../../../services/Property/Properties';
import { REPORTS } from '../../../services/Reports/ReportsService';
import { CSVLink } from 'react-csv';
import { ExportExcel, ExportPdf, handleFileName } from '../../../utility/Export/Export';
import { FILTER_STRING, REPORTS_STRINGS } from '../../../services/strings';
import { DROPDOWN } from '../../../services/Dropdown/dropdowns';

const FinanceTabReports = () => {
    const [FilterStartDate, setFilterStartDate] = useState(null);
    const [FilterEndDate, setFilterEndDate] = useState(null);
    const [StateMenu, setStateMenu] = useState([]);
    const [services, setServices] = useState(null);
    const [tableLoading, setTableLoading] = useState(null);
    const [sort, setSort] = useState(null);
    const [tableDataLength, setTableDataLength] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [isFilterShow, setIsFilterShow] = useState(false);
    const [Appusers, setAppusers] = useState([]);
    const [Properties, setProperties] = useState([]);
    const [IntRates, setIntRates] = useState([]);
    const [SelectedAppuser, setSelectedAppusers] = useState(null);
    const [SelectedProperty, setSelectedProperty] = useState(null);
    const [SelectedState, setSelectedState] = useState(null);
    const [SelectedRate, setSelectedRate] = useState(null);
    const [FilterOutstanding, setFilterOutstanding] = useState(null);
    const [search, setSearch] = useState(null);
    const [tempSearch, setTempSearch] = useState(null);
    const [csv, setCsv] = useState([]);
    const csvLink = useRef();

    const fetchAppUsers = async () => {
        const res = await APP_USERS.GET(-1);
        if (res.status && Array.isArray(res.data)) {
            setAppusers([{ id: null, name: 'Select User' }, ...res.data]);
        }
    };

    const fetchProperties = async (userId) => {
        if (userId) {
            const res = await PROPERTIES.GETBYUSERID(-1, 0, null, null, userId);
            if (res.status) {
                setProperties([{ id: '', name: 'Select Properties' }, ...res.data]);
            }
        }
    };

    const fetchStates = async () => {
        const res = await DROPDOWN.GET_STATES();
        if (res.status) {
            setStateMenu([{ name: 'State', id: '' }, ...res.data]);
        }
    };

    const fetchRatings = async () => {
        const res = await REPORTS.COMMON_RATINGS();
        if (res.status) {
            setIntRates(res.data);
        }
    };

    const changeUser = (value) => {
        setSelectedAppusers(value);
        fetchProperties(value);
    };

    const onChangePage = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const onChange = (pagination, filters, sorter) => {
        if (sorter?.order === 'ascend') {
            setSort({ [sorter.field]: 1 });
        } else if (sorter?.order === 'descend') {
            setSort({ [sorter.field]: -1 });
        } else {
            setSort({ createdAt: -1 });
        }
    };

    const fetchFinancialData = async () => {
        setTableLoading(true);
        const res = await REPORTS.FINANCIAL_REPORT(pageSize, (currentPage - 1) * pageSize, search, sort, SelectedAppuser, SelectedProperty, SelectedState, SelectedRate, FilterOutstanding, FilterStartDate, FilterEndDate);
        if (res.status) {
            setServices(res);
            setTableDataLength(res.total);
        }
        setTableLoading(false);
    };

    const headers = [
        { label: 'User Name', key: 'username' },
        { label: 'Property Name', key: 'name' },
        { label: 'Outstanding Loan Amount', key: 'osloan' },
        { label: 'Equity Value', key: 'equity' },
        { label: 'State', key: 'state' },
        { label: 'Tax Report Feature Availing', key: 'taxReport' },
        { label: 'Interest Rate', key: 'interest_rate' },
    ];

    const handleDownloadCsv = async () => {
        const temp = services?.data.map((item) => ({
            username: item['appuser.firstname'] + ' ' + item['appuser.lastname'],
            name: item['name'],
            osloan: item['mortagage.outstandingAmount'],
            equity: item['equity_value'],
            state: item['council.suburb.city.state.name'],
            taxReport: item['taxReportFeature'] ? 'Availed' : 'Not Availed',
            interest_rate: item['mortagage.rate'] + '% p. a.'
        }));
        setCsv([...temp]);
        csvLink.current.link.click();
    };

    const generateExcel = async () => {
        const temp = services?.data?.map((item) => {
            return {
                username: item['appuser.firstname'] + ' ' + item['appuser.lastname'],
                name: item['name'],
                osloan: item['mortagage.outstandingAmount'],
                equity: item['equity_value'],
                state: item['council.suburb.city.state.name'],
                taxReport: item['taxReportFeature'] ? 'Availed' : 'Not Availed',
                interest_rate: item['mortagage.rate'] + '% p. a.'
            };
        });
        ExportExcel(temp, 'financeReport');
    };

    const generatePDF = async () => {
        const pdftableColumn = [
            'User Name',
            'Property Name',
            'Outstanding Loan Amount',
            'Equity Value',
            'State',
            'Tax Report Feature Availing',
            'Interest Rate',
        ];
        const pdftableRows = [];
        services?.data.forEach((item) => {
            const ReportsListData = [
                item['appuser.firstname'] + ' ' + item['appuser.lastname'],
                item['name'],
                item['mortagage.outstandingAmount'],
                item['equity_value'],
                item['council.suburb.city.state.name'],
                item['taxReportFeature'] ? 'Availed' : 'Not Availed',
                item['mortagage.rate'] + '% p. a.'
            ];
            pdftableRows.push(ReportsListData);
        });
        ExportPdf(pdftableColumn, pdftableRows, 'FinanceReport');
    };

    useEffect(() => {
        fetchFinancialData();
    }, [currentPage,
        pageSize,
        search,
        sort,
        FilterStartDate,
        FilterEndDate,
        SelectedAppuser,
        SelectedProperty,
        SelectedState,
        SelectedRate,
        FilterOutstanding]);

    const marks = {
        0: '$0',
        1000000: '$1000000',
    };

    useEffect(() => {
        fetchAppUsers();
        fetchStates();
        fetchRatings();
    }, []);

    const handleChangeOfSearch = (e) => {
        setTempSearch(e.target.value);
        if (!e.target.value) {
            setCurrentPage(1);
            setSearch(null);
            setTempSearch(null);
        }
    };

    const handleSearch = () => {
        setCurrentPage(1);
        setSearch(tempSearch);
    };

    const columns = [
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.FINANCE.TABLE.USERNAME}</span>, 'name', column),
            dataIndex: 'appuser.firstname',
            key: 'username',
            align: 'left',
            width: 180,
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.FINANCE.TABLE.PROPERTY_NAME}</span>, 'property_name', column),
            dataIndex: 'name',
            key: 'property_name',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.FINANCE.TABLE.OS_LOAN_AMT}</span>, 'outstanding_load', column),
            dataIndex: 'mortagage.outstandingAmount',
            key: 'outstanding_load',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">${value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
        },
        {
            title: (column) => <span className="tableTitle">{REPORTS_STRINGS.FINANCE.TABLE.EQTY_VALUE_AMT}</span>,
            dataIndex: 'equity_value',
            key: 'equity_value',
            align: 'left',
            sorter: false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">${value}</span> : '-',
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.FINANCE.TABLE.STATE}</span>, 'state', column),
            dataIndex: 'council.suburb.city.state.name',
            key: 'state',
            align: 'left',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{value?.charAt(0).toUpperCase() + value?.slice(1)}</span> : '-',
        },
        {
            title: (column) => <span className="tableTitle">{REPORTS_STRINGS.FINANCE.TABLE.TAX_REPORT_AVAILAING}</span>,
            key: 'appuser.taxReportFeature',
            width: 290,
            dataIndex: 'appuser.taxReportFeature',
            align: 'center',
            sorter: false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (<Checkbox checked={value} />),
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{REPORTS_STRINGS.FINANCE.TABLE.INT_RATE}</span>, 'depSchedule', column),
            key: 'iRate',
            width: 180,
            dataIndex: 'mortagage.rate',
            align: 'center',
            sorter: tableDataLength > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) => (value ? <span className="tabletext">{value}% p. a.</span> : '-'),
        }
    ];

    return (
        <div className='userTabReportLayout'>
            <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} withmenu={true} />
            <Row className={isFilterShow ? 'filterBoxLayout mb-3' : 'filterBoxLayout d-none'}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Form.Item
                                label={<span className="inputTitle">{FILTER_STRING.START_DATE}</span>}
                                colon={false}>
                                <DatePicker
                                    className='w-100 inputField'
                                    placeholder=""
onChange={value => setFilterStartDate(value)}
                                    disabledDate={d => !d || d.isAfter(FilterEndDate)}
                                    suffixIcon={<CalenderDarkIcon />} />
                            </Form.Item>
                            <Form.Item
                                label={<span className="inputTitle">{FILTER_STRING.END_DATE}</span>}
                                colon={false}>
                                <DatePicker
                                    className='w-100 inputField'
                                    placeholder=""
onChange={value => setFilterEndDate(value)}
                                    disabledDate={d => !d || d.isSameOrBefore(FilterStartDate)}
                                    suffixIcon={<CalenderDarkIcon />} />
                            </Form.Item>
                            <Form.Item
                                label={<span className="inputTitle">{FILTER_STRING.SEARCH}</span>}
                                className='searchInpUserTab'
                                colon={false}>
                                <Input className='inputField searchField'
                                    placeholder="Start Typing To Search Finance Info."
                                    value={tempSearch}
                                    allowClear
                                    onChange={(e) => handleChangeOfSearch(e)}
                                    onKeyPress={(e) => {
                                        if (e?.key === 'Enter' && e.target.value) {
                                            handleSearch();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16} className={'dropdownBox'}>
                            <div className="filterSelect">
                                <Select defaultValue={FILTER_STRING.USER} onChange={value => changeUser(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{Appusers?.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={FILTER_STRING.PROPERTIES} onChange={value => setSelectedProperty(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{Properties?.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={FILTER_STRING.STATE} onChange={value => setSelectedState(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}>{StateMenu.map((country, key) => <Select.Option key={key} value={country.id}>{country.name}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSlider">
                                <div className="sliderTitle">{REPORTS_STRINGS.FINANCE.FILTER.OS_LOAN_AMT}</div>
                                <Slider trackStyle={{background: "#879EC4"}} range marks={marks} onAfterChange={value => setFilterOutstanding(value[0] + '-' + value[1])} max={1000000} defaultValue={[0, 100000]} />
                            </div>
                            <div className="filterSelect">
                                <Select defaultValue={REPORTS_STRINGS.FINANCE.FILTER.INT_RATE} onChange={value => setSelectedRate(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value=''>Interest Rate</Select.Option>{IntRates.map((rate, key) => <Select.Option key={key} value={rate.rate}>{rate.rate}</Select.Option>)}</Select>
                            </div>
                            <div className="filterSlider">
                                <div className="sliderTitle">{REPORTS_STRINGS.FINANCE.FILTER.EQTY_VALUE_AMT}</div>
                                <Slider trackStyle={{background: "#879EC4"}} range marks={marks} max={1000000} defaultValue={[1000, 100000]} />
                            </div>
                            <div className="exportBox">
                                <div className="exportTitle">Export</div>
                                <div><div className="exportBtn" onClick={handleDownloadCsv}>{REPORTS_STRINGS.BUTTONS.EXPORT_CSV}</div>
                                    <CSVLink
                                        data={csv}
                                        headers={headers}
                                        filename={handleFileName('FinanceReport', 'csv')}
                                        ref={csvLink}
                                        className='text-black font-weight-600'
                                    ></CSVLink>
                                </div>
                                <div className="exportBtn" onClick={generateExcel}>{REPORTS_STRINGS.BUTTONS.EXPORT_EXCEL}</div>
                                <div className="exportBtn" onClick={generatePDF}>{REPORTS_STRINGS.BUTTONS.EXPORT_PDF}</div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table
                        columns={columns}
                        dataSource={services?.data}
                        pagination={false}
                        rowKey='id'
                        loading={tableLoading}
                        onChange={onChange}
                        scroll={{ x: 400 }}
                    />
                    {services?.total > 10 && (
                        <div className='text-end bg-white mt-7'>
                            <PaginationCom
                                onChange={onChangePage}
                                showSizeChanger={true}
                                current={currentPage}
                                showLessItems={true}
                                total={services?.total}
                            />
                        </div>)}
                </Col>
            </Row>
        </div>
    );
};

FinanceTabReports.propTypes = {
    permissions: PropTypes.any
};

export default FinanceTabReports;
