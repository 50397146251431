import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Table, Tooltip } from 'antd';
import PaginationCom from '../../components/PaginationCom';
import { tableSortIconToggle } from '../../utility/tableSorting';
import ValidateIcon from '../../components/Icon/ValidateIcon';
import { COMMON_ALERTS, FHBG_STRING, TABLE_STRING } from '../../services/strings';
import { FHBGOALS } from '../../services/FirstHomeBuyerGoal/fhbgoals';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PencileIcon from '../../components/Icon/PencileIcon';

FHBGoalPage.propTypes = {
    permissions: PropTypes.any
};

function FHBGoalPage({ permissions }) {
    const navigate = useNavigate();
    const [fhbgStates, setFhbgStates] = useState([]);
    const [sort, setSort] = useState({ isAdded: -1 });
    const [tableLoading, setTableLoading] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const onChangePage = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const onChange = (pagination, filters, sorter, extra) => {
        if (sorter?.order === 'ascend') {
            setSort({ [sorter.field]: 1 });
        } else if (sorter?.order === 'descend') {
            setSort({ [sorter.field]: -1 });
        } else {
            setSort({ isAdded: -1 });
        }
    };

    const fetchFHBGoals = async (page, pageSize, search, sort, contactId) => {
        setTableLoading(true);
        const res = await FHBGOALS.ALL(pageSize,
            (page - 1) * pageSize,
            search,
            sort, location.contactId);
        try {
            if (res.status === 1 || res.status === '1') {
                setFhbgStates(res);
            }
        } catch (err) {
            setTableLoading(false);
            toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
        } finally {
            setTableLoading(false);
        }
    }

    useEffect(() => {
        fetchFHBGoals(currentPage, pageSize, null, sort);
    }, [currentPage, pageSize, null, sort]);

    const InfoCheckBtn = (isExists, stateId, state_code) => {
        if (permissions?.isUpdate || permissions?.isUpdate === undefined) {
            if (isExists) {
                return (<ValidateIcon />);
            }
            else {
                return (<span className="addInfoBtn" onClick={() => navigate('/fhbgoal/edit', {state: {stateId, state_code}})}>{FHBG_STRING.TABLE.ADD_INFO}</span>);
            }
        }
        else {
            return (<></>);
        }
    }

    const columns = [
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{FHBG_STRING.TABLE.STATE}</span>, 'state', column),
            dataIndex: 'state_code',
            key: 'state_code',
            align: 'left',
            sorter: fhbgStates?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
            width: 300,
        },
        {
            title: (column) => tableSortIconToggle(<span className="tableTitle">{FHBG_STRING.TABLE.INFO}</span>, 'info', column),
            key: 'isAdded',
            dataIndex: 'isAdded',
            align: 'center',
            sorter: fhbgStates?.total > 1 ? true : false,
            sortDirections: ['ascend', 'descend'],
            render: (isAdded, record) => InfoCheckBtn(isAdded, record.stateId, record.state_code),
        },
        {
            title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            width: 200,
            render: (item, record) => {
                if(permissions?.isUpdate || permissions?.isUpdate === undefined){
                    return <Tooltip title='Edit Borrowing Capacity'>
                    <PencileIcon onClick={() => navigate('/fhbgoal/edit', { state: { stateId: record.stateId, state_code: record.state_code} })} className='font-size-20 delete-btn-hover cursor-pointer mx-1' />
                  </Tooltip>
                }
            },
        },
    ];

    return (
        <div className='propertiesLayout'>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-3">
                    <Table
                        columns={columns}
                        dataSource={fhbgStates?.data}
                        pagination={false}
                        rowKey='id'
                        loading={tableLoading}
                        onChange={onChange}
                        scroll={{ x: 400 }}
                    />

                    {fhbgStates?.total > 10 && (
                        <div className='text-end bg-white mt-7'>
                            <PaginationCom
                                onChange={onChangePage}
                                showSizeChanger={true}
                                current={currentPage}
                                showLessItems={true}
                                total={fhbgStates?.total}
                            />
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default FHBGoalPage;