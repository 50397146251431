  import { methods, serviceMaker } from '../service';

  const GET_PATCH_SERVICETYPE = (id) => serviceMaker(`service-types/${id}`, methods.GET);
  
  const GET_SERVICE_TYPES = (
      limit,
      skip,
      search = null,
      sort = null
    ) => {
      const query = {
        params: {
          $limit: limit,
          $skip: skip
        },
      };
  
      if (sort) {
        query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
      } else {
        query['params']['$sort[createdAt]'] = -1;
      }
      if (search) {
        query['params']['search'] = search;
        return serviceMaker(`search/service-types`, methods.GET, query);
      }
  
        return serviceMaker(`service-types`, methods.GET, query);
    };
  
  const DELETE_SERVICETYPE = (id) => serviceMaker(`service-types/${id}`, methods.DELETE);
  
  const CREATE_SERVICETYPE = (payload) =>
    serviceMaker(`service-types`, methods.POST, null, payload);
  
  const PATCH_SERVICETYPE = (id, payload) =>
    serviceMaker(`service-types/${id}`, methods.PATCH, null, payload);
  
  const SERVICES_TYPES = {
      FIND: GET_PATCH_SERVICETYPE,
      GET: GET_SERVICE_TYPES,
      CREATE: CREATE_SERVICETYPE,
      PATCH: PATCH_SERVICETYPE,
      DELETE: DELETE_SERVICETYPE
    };
  
  export { SERVICES_TYPES };
    