import React from 'react';

function LendersIcon(props) {
    return (
        <span {...props} className='anticon anticon-lenders ant-menu-item-icon'>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.66602 6.66797V10.0021H2.49935V15.0013H1.66602V17.5013H14.9993L17.4993 17.5021L18.3327 17.5013V15.0013H17.4993V10.0021H18.3327V6.66797L9.99935 1.66797L1.66602 6.66797ZM4.99935 15.0013V10.0021H6.66602V15.0013H4.99935ZM9.16602 15.0013V10.0021H10.8327V15.0013H9.16602ZM14.9993 15.0013H13.3327V10.0021H14.9993V15.0013ZM11.666 6.66797C11.666 6.88689 11.6228 7.10366 11.539 7.3059C11.4551 7.50814 11.3323 7.69189 11.1774 7.84665C11.0226 8.00142 10.8388 8.12417 10.6365 8.2079C10.4342 8.29162 10.2174 8.33469 9.99852 8.33464C9.77959 8.33458 9.56282 8.29141 9.36058 8.20758C9.15834 8.12375 8.9746 8.00091 8.81983 7.84606C8.66507 7.69122 8.54232 7.50741 8.45859 7.30513C8.37486 7.10285 8.33179 6.88606 8.33185 6.66714C8.33196 6.225 8.5077 5.80101 8.82042 5.48845C9.13314 5.17589 9.55721 5.00036 9.99935 5.00047C10.4415 5.00058 10.8655 5.17632 11.178 5.48904C11.4906 5.80176 11.6661 6.22583 11.666 6.66797Z" fill="#3E567E"/>
            </svg>
        </span>
    );
}

export default LendersIcon;