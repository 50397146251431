import React from 'react';
import AppReferrals from '../../components/Referrals/AppReferrals';
import CurrentOffer from '../../components/Referrals/CurrentOffer';
import { REFERRALS_STRINGS } from '../../services/strings';
import TabSwitch from '../../components/TabSwitch';
import OfferAvailsListPage from './OfferAvailsListPage';

const ReferalsPage = () => {
    const tabs = [
        {
            name: REFERRALS_STRINGS.TABS.APP_REFERRAL,
            component: <AppReferrals/>
        },
        {
            name: REFERRALS_STRINGS.TABS.USER_OFFER_LIST,
            component: <OfferAvailsListPage/>
        }
    ];
    return (
        <div className='pagelayout'>
            <TabSwitch tabStates={tabs}/>
        </div>
    );
}

export default ReferalsPage;
