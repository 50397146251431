import { DatePicker, Select } from 'antd';
import React from 'react';
import CalenderIcon from './Icon/CalenderIcon';
import PropTypes from 'prop-types';


function SelectDate(props) {
    return (
        <div>
            <Select className='select_dashboard_period' defaultValue={''} suffixIcon={<CalenderIcon />} placeholder="-- Select Once --" onChange={(value)=>props.onChange(value)}>
                <Select.Option value="">-- Select Once --</Select.Option>
                <Select.Option value='today'>Today</Select.Option>
                <Select.Option value='yesterday'>Yesterday</Select.Option>
                <Select.Option value='this_week'>This Week</Select.Option>
                <Select.Option value='previous_week'>Previous Week</Select.Option>
                <Select.Option value='this_month'>This Month</Select.Option>
                <Select.Option value='previous_month'>Previous Month</Select.Option>
                <Select.Option value='this_year'>This Year</Select.Option>
                <Select.Option value='previous_year'>Previous Year</Select.Option>
            </Select>
        </div>
    );
}

SelectDate.propTypes = {
    onChange: PropTypes.func
}

export default SelectDate;