import { Col, DatePicker, Form, Input, Radio, Row, Select, Table, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import BlockIcon from '../../../components/Icon/BlockIcon';
import CalenderDarkIcon from '../../../components/Icon/CalenderDarkIcon';
import DeleteIcon from '../../../components/Icon/DeleteIcon';
import PencileIcon from '../../../components/Icon/PencileIcon';
import SelectArrow from '../../../components/Icon/SelectArrow';
import UnBlockIcon from '../../../components/Icon/UnBlockIcon';
import PaginationCom from '../../../components/PaginationCom';
import { CONTACT_DETAILS } from '../../../services/ContactDetails/ContactDetails';
import { tableSortIconToggle } from '../../../utility/tableSorting';
import PropTypes from 'prop-types';
import { ExportExcel, ExportPdf, handleFileName } from '../../../utility/Export/Export';
import { CSVLink } from 'react-csv';
import FilterToogleBtn from '../../../components/FilterToogleBtn';
import { COMMON_STRING, FILTER_STRING, TABLE_STRING, COMMON_ALERTS, USER_DETAILS_STRINGS, REPORTS_STRINGS, CONTACT_DETAILS_STRINGS } from '../../../services/strings';
import { DROPDOWN } from '../../../services/Dropdown/dropdowns';

const ContactTabReports = ({ permissions }) => {
  const navigate = useNavigate();
  const [CountriesMenu, setCountriesMenu] = useState([]);
  const [StateMenu, setStateMenu] = useState([]);
  const [tabState, settabState] = useState('admin');
  const [contacts, setContacts] = useState(null);
  const [tableLoading, setTableLoading] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [sort, setSort] = useState(null);
  const [tableDataLength, setTableDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState(null);
  const [BlockId, setBlockId] = useState(null);
  const [tempSearch, setTempSearch] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [blockUserModalVisible, setBlockUserModalVisible] = useState(false);
  const [BlockStatus, setBlockStatus] = useState('active');
  const [FilterStartDate, setFilterStartDate] = useState(null);
  const [FilterEndDate, setFilterEndDate] = useState(null);
  const [FilterCountry, setFilterCountry] = useState(null);
  const [FilterState, setFilterState] = useState(null);
  const [isFilterShow, setIsFilterShow] = useState(false);
  const csvLink = useRef();
  const [csv, setCsv] = useState([]);

  const fetchContact = async (page, pageSize, search, sort, tabState, FilterStartDate = null, FilterEndDate = null, FilterCountry = null, FilterState = null) => {
    const res = await CONTACT_DETAILS.GET(pageSize,
      (page - 1) * pageSize,
      search,
      sort,
      tabState,
      FilterStartDate,
      FilterEndDate,
      FilterCountry,
      FilterState);
    try {
      if (res.status === 1 || res.status === '1') {
        setContacts(res);
        setTableDataLength(res?.data && res?.data.length);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setTableLoading(false);
    }
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const blockContact = async () => {
    try {
      const res = await CONTACT_DETAILS.PATCH(BlockId, { status: BlockStatus });
      if (res?.status) {
        setBlockUserModalVisible(false);
        fetchContact(currentPage, pageSize, search, sort, filterValue?.value);
      }
    } catch (err) {
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    }
  };

  const fetchCountries = async () => {
    const countriesData = await DROPDOWN.GET_COUNTRIES();
    if (countriesData.status && Array.isArray(countriesData.data)) {
      setCountriesMenu(countriesData.data);
    }
  };

  const fetchStates = async () => {
    const statesData = await DROPDOWN.GET_STATES();
    if (statesData.status && Array.isArray(statesData.data)) {
      setStateMenu(statesData.data);
    }
  };

  useEffect(() => {
    fetchCountries();
    fetchStates();
  }, []);

  useEffect(() => {
    fetchContact(currentPage, pageSize, search, sort, tabState, FilterStartDate, FilterEndDate, FilterCountry, FilterState);
  }, [currentPage, pageSize, search, sort, tabState, FilterStartDate, FilterEndDate, FilterCountry, FilterState]);

  const changeTab = (value) => {
    setTableLoading(null);
    setCurrentPage(1);
    settabState(value);
  };

  const clearAll = () => {

  }

  const headers = [
    { label: 'User Id', key: 'id' },
    { label: 'Full Name', key: 'name' },
    { label: 'Phone No.', key: 'phone' },
    { label: 'Email', key: 'email' }
  ];

  const handleDownloadCsv = async () => {
    let res = await CONTACT_DETAILS.GET(-1, 0, null, null, tabState);
    if (res.status === 1 || res.status === '1') {
      const temp = res?.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          phone: item.phoneNumber,
          email: item?.email,
          addedBy: item?.addedBy
        };
      });
      setCsv(temp);
    }
  };

  useEffect(() => {
    if (csv?.length)
      csvLink.current.link.click();
  }, [csv]);

  const generateExcel = async () => {
    let res = await CONTACT_DETAILS.GET(-1, 0, null, null, tabState);
    if (res.status === 1 || res.status === '1') {
      const temp = res?.data?.map((item) => {
        return {
          id: item.id,
          name: item.name,
          phone: item.phoneNumber,
          email: item?.email,
          addedBy: item?.addedBy
        };
      });
      ExportExcel(temp, 'userReport');
    }
  };

  const generatePDF = async () => {
    let res = await CONTACT_DETAILS.GET(-1, 0, null, null, tabState);
    const pdftableColumn = [
      'Contact Id',
      'Full Name',
      'Contact No.',
      'Email',
      'Added By'
    ];
    const pdftableRows = [];
    res?.data.forEach((item) => {
      const usersReportsListData = [
        item.id,
        (item.name),
        item.phoneNumber,
        item?.email,
        item?.addedBy
      ];
      pdftableRows.push(usersReportsListData);
    });
    ExportPdf(pdftableColumn, pdftableRows, 'contactReport');
  };

  const columns = [
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{CONTACT_DETAILS_STRINGS.TABLE.CONTACT_NAME}</span>, 'name', column),
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],

      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{CONTACT_DETAILS_STRINGS.TABLE.CONTACT_NO}</span>, 'phoneNumber', column),
      key: 'phoneNumber',
      width: 150,
      dataIndex: 'phoneNumber',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? <span className="tabletext">{value}</span> : '-'),
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{CONTACT_DETAILS_STRINGS.TABLE.EMAIl}</span>, 'email', column),
      key: 'email',
      dataIndex: 'email',
      align: 'left',
      width: 300,
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? <span className="tabletext">{value}</span> : '-'),
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      key: 'Actions',
      width: 100,
      align: 'left',
      dataIndex: 'Actions',
      render: (item, record) => {
        return (
          <div className='actionCol'>
            {tabState === 'admin' && <Tooltip title='Edit Contact'>
              <PencileIcon onClick={() => navigate('/contacts/edit', { state: { contactItemId: record?.id } })} className='font-size-20 delete-btn-hover cursor-pointer mx-1 editBtnRow' />
            </Tooltip>}
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Contact'>
                <DeleteIcon
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1 deleteBtnRow'
                />
              </Tooltip>
            )}
            {tabState === 'user' && <Tooltip title='Block Contact'>
              {record.statuss === 'active' ? <BlockIcon onClick={() => {
                setBlockUserModalVisible(true);
                setBlockStatus('inactive');
                setBlockId(record.id);
              }} className='font-size-20 delete-btn-hover cursor-pointer mx-1 blockBtnRow' /> : <UnBlockIcon onClick={() => {
                setBlockStatus('active');
                setBlockId(record.id);
                blockContact();
              }} className='font-size-20 delete-btn-hover cursor-pointer mx-1 blockBtnRow' />}
            </Tooltip>}
          </div>
        );
      },
    },
  ];

  if (tabState === 'user') {
    columns.splice(3, 0, {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{CONTACT_DETAILS_STRINGS.TABLE.LINKED_PROPERTY}</span>, 'linkedPRoperty', column),
      key: 'linkedPRoperty',
      dataIndex: 'linkedPRoperty',
      align: 'left',
      width: 180,
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => <span className="tabletext"><u>View</u></span>
    });
  }

  return (
    <div className='userTabReportLayout'>
      <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} withmenu={true} />
      <Row className={isFilterShow ? 'filterBoxLayout mb-3' : 'filterBoxLayout d-none'}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label={<span className="inputTitle">{FILTER_STRING.START_DATE}</span>}
                colon={false}>
                <DatePicker
                  className='w-100 inputField'
                  placeholder=""
                  suffixIcon={<CalenderDarkIcon />}
                  allowClear
                  onChange={value => setFilterStartDate(value)}
                  disabledDate={d => !d || d.isAfter(FilterEndDate)}
                  picker="date" />
              </Form.Item>
              <Form.Item
                label={<span className="inputTitle">{FILTER_STRING.END_DATE}</span>}
                colon={false}>
                <DatePicker
                  className='w-100 inputField'
                  placeholder=""
                  suffixIcon={<CalenderDarkIcon />}
                  allowClear
                  onChange={value => setFilterEndDate(value)}
                  disabledDate={d => !d || d.isSameOrBefore(FilterStartDate)}
                  picker="date" />
              </Form.Item>
              <Form.Item
                label={<span className="inputTitle">{FILTER_STRING.SEARCH}</span>}
                className="searchInpUserTab"
                colon={false}>
                <Input placeholder={COMMON_STRING.SEARCH_STR + 'Contact'}
                  className='inputField searchField'
                  value={tempSearch}
                  onChange={(e) => setTempSearch(e.target.value)}
                  onKeyPress={(e) => {
                    if (e?.key === 'Enter' && e.target.value) {
                      setSearch(e.target.value);
                    }
                    else if (!e.target.value) {
                      setSearch(null);
                    }
                  }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className={'dropdownBox'}>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.COUNTRY} onChange={value => setFilterCountry(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value="">Country</Select.Option>{CountriesMenu.map((country, key) => <Select.Option key={key} value={country.name}>{country.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.STATE} onChange={value => setFilterState(value)} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false}><Select.Option value="">State</Select.Option>{StateMenu.map((country, key) => <Select.Option key={key} value={country.name}>{country.name}</Select.Option>)}</Select>
              </div>
              <div className="slideToogleBtnBx">
                <Radio.Group
                  onChange={(e) => changeTab(e.target.value)}
                  value={tabState}
                  optionType="button"
                  buttonStyle="solid">
                  <Radio value={'admin'}>{USER_DETAILS_STRINGS.TABS.ADMIN_ADDED}</Radio>
                  <Radio value={'user'}>{USER_DETAILS_STRINGS.TABS.USER_ADDED}</Radio>
                </Radio.Group>
              </div>
              <div className='exportOuterBoxFlex'>
                <div className="exportBox">
                  <div className="exportTitle">Export</div>
                  <div>
                    <div className="exportBtn" onClick={handleDownloadCsv}>{REPORTS_STRINGS.BUTTONS.EXPORT_CSV}</div>
                    <CSVLink
                      data={csv}
                      headers={headers}
                      filename={handleFileName('ContactsReport', 'csv')}
                      ref={csvLink}
                      className='text-black font-weight-600'
                    ></CSVLink></div>
                  <div className="exportBtn" onClick={generateExcel}>{REPORTS_STRINGS.BUTTONS.EXPORT_EXCEL}</div>
                  <div className="exportBtn" onClick={generatePDF}>{REPORTS_STRINGS.BUTTONS.EXPORT_PDF}</div>

                </div>
                <div className="filterSelect clearAll" onClick={() => clearAll()}>Clear all</div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            columns={columns}
            dataSource={contacts?.data}
            pagination={false}
            rowKey='id'
            loading={tableLoading}
            onChange={onChange}
            scroll={{ x: 400 }}
          />
          {contacts?.total > 10 && (
            <div className='text-end bg-white mt-7'>
              <PaginationCom
                onChange={onChangePage}
                showSizeChanger={true}
                current={currentPage}
                showLessItems={true}
                total={contacts?.total}
              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

ContactTabReports.propTypes = {
  permissions: PropTypes.any
};

export default ContactTabReports;
