import { Button, Checkbox, Col, Form, Image, Input, Row, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ArrowDown from '../../../../components/Icon/ArrowDown';
import NotificationSupTitle from '../../../../components/Icon/NotificationSupTitle';
import NotificationTextSeprator from '../../../../components/Icon/NotificationTextSeprator';
import PencileIcon from '../../../../components/Icon/PencileIcon';
import NotificationImage from '../../../../components/Image/MobileNotification.png'
import { COMMON_ALERTS, COMMON_STRING, NOTIFICATIONS_STRINGS } from '../../../../services/strings';
import PropTypes from 'prop-types';
import FallbackImage from '../../../../components/Icon/FallbackImage';
import { getLSItem, setLSItem } from '../../../../services/auth/encryptLogin';
import { BASE_URL } from '../../../../services/constant';

const EnterDetailNotification = ({ changeTab }) => {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [MediaItemId, setMediaItemId] = useState(null);
    const accessToken = getLSItem("pdollar-token");
    const [TitleText, setTitleText] = useState('');
    const [DetailText, setDetailText] = useState('');

    const onFinish = (e) => {
        delete e.coverImage;
        Object.assign(e, {
            fileList: fileList,
            mediaItemId: MediaItemId
        });
        setLSItem('notification-step-2', JSON.stringify(e));
        changeTab(2);
    }

    useEffect(() => {
        const ex_data_str = getLSItem('notification-step-2');
        if (ex_data_str !== null) {
            const ex_data = JSON.parse(ex_data_str);
            setMediaItemId(ex_data.mediaItemId);
            setFileList(ex_data.fileList);
            setTitleText(ex_data.title);
            setDetailText(ex_data.text);
            form.setFieldValue('coverImage', ex_data.mediaItemId);
            delete ex_data.fileList;
            delete ex_data.mediaItemId;
            form.setFieldsValue(ex_data);
            
        }
    }, []);

    const imageProps = {
        name: 'file',
        multiple: false,
        fileList: fileList,
        action: `${BASE_URL}upload-image`,
        headers: {
            authorization: accessToken,
        },
        beforeUpload() {
            if (fileList.length > 1 || fileList.length === 1) {
                toast.warn(COMMON_ALERTS.FILE_EXISTS);
                return false;
            }
        },
        onChange(info) {
            if (info.fileList.length === 1) {
                setFileList([...info.fileList]);
            }
            if (info.file.status === 'done') {
                setMediaItemId(info.file.response.data.media.id);
                form.setFieldValue('coverImage', info.file.response.data.media.id);
            } else if (info.file.status === 'error') {
                toast.error(COMMON_ALERTS.FILE_UPLOAD_FAILED(info.file.name));
            }
        },
        async onRemove() {
            setFileList([]);
            setMediaItemId(null);
            form.setFieldValue('coverImage', null);
        },
    };

    const uploadButton = (<div className="uploadImageBx">
        <div className="editIconBx"><PencileIcon /></div>
    </div>);

    return (
        <div>
            <Row>
                <span className="titleText">{NOTIFICATIONS_STRINGS.STEP2.TITLE_LABEL}</span>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                    <Form form={form} onFinish={onFinish} colon={false} labelCol={12} wrapperCol={20}>
                        <Form.Item
                            name='title'
                            label={NOTIFICATIONS_STRINGS.STEP2.NOTIFICATION_TITLE}
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('title') }]}>
                            <Input onChange={e => setTitleText(e.target.value)} className='input-control' autoFocus allowClear />
                        </Form.Item>
                        <Form.Item
                            name='text'
                            label={NOTIFICATIONS_STRINGS.STEP2.NOTIFICATION_TEXT}
                            labelCol={7}
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_INPUT('notification text') }]}>
                            <Input.TextArea className='input-textarea' onChange={e => setDetailText(e.target.value)} rows={4} allowClear />
                        </Form.Item>
                        <Form.Item
                            valuePropName='filelist'
                            name='coverImage'
                            label='Notification Image'
                            required={true}
                            rules={[{
                                required: true,
                                message: COMMON_ALERTS.REQUIRED_CHOOSE('image')}]}>
                            <div className="imagePickerDiv" style={{ width: '100%' }}><Upload accept=".png, .jpg, .jpeg" listType="picture" {...imageProps}>
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                            </div>
                        </Form.Item>
                        <Form.Item
                            name='platform'
                            label={NOTIFICATIONS_STRINGS.STEP2.WHICH_PLATEFORM}
                            rules={[{ required: true, message: COMMON_ALERTS.REQUIRED_SELECT('platform') }]}>
                            <Checkbox.Group>
                                <Checkbox value='android'>{COMMON_STRING.ANDROID}</Checkbox>
                                <Checkbox value='ios'>{COMMON_STRING.IOS}</Checkbox>
                                <Checkbox value='web'>{COMMON_STRING.WEB}</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                        <div className='form-btn-group'>
                            <Button className='mr-2 width-180 backBtn' onClick={() => changeTab(0)}>{COMMON_STRING.BACK_BTN}</Button>
                            <Button htmlType='submit' className='width-180 createBtn'>{COMMON_STRING.NEXT_BTN}</Button>
                        </div>
                    </Form>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xl={9} className="previewImageBx">
                    <Image preview={false} src={NotificationImage}></Image>
                    <div className="notificationImageCardBox">
                        <div className="notificationSupTitle"><NotificationSupTitle /><span>Property Dollar</span> <NotificationTextSeprator /> <span>now</span> <ArrowDown /> <span>Chrome</span> <NotificationTextSeprator /> <span>fig</span> </div>
                        <div className="notificationTitle">{TitleText}</div>
                        <div className="notificationDetailTxt">{DetailText}</div>
                        <div className="notificationImage">
                            {MediaItemId && <Image src={BASE_URL + fileList[0]?.response?.data?.media?.file?.slice(1)} alt="Notification Image" fallback={FallbackImage} />}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

EnterDetailNotification.propTypes = {
    changeTab: PropTypes.func
}

export default EnterDetailNotification;
