import { Radio, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import PaginationCom from '../../components/PaginationCom';
import DeleteIcon from '../../components/Icon/DeleteIcon';
import { tableSortIconToggle } from '../../utility/tableSorting';
import { toast } from 'react-toastify';
import { CONTACT_DETAILS } from '../../services/ContactDetails/ContactDetails';
import BlockModel from '../../components/BlockModel';
import DeleteModel from '../../components/DeleteModel';
import { useNavigate } from 'react-router-dom';
import PencileIcon from '../../components/Icon/PencileIcon'
import BlockIcon from '../../components/Icon/BlockIcon'; import UnBlockIcon from '../../components/Icon/UnBlockIcon';
import PropTypes from 'prop-types';
import { COMMON_ALERTS, CONTACT_DETAILS_STRINGS, TABLE_STRING } from '../../services/strings';

const ContactDetails = ({ permissions }) => {
  const navigate = useNavigate();
  const [tabState, settabState] = useState('admin');
  const [contacts, setcontacts] = useState(null);
  const [tableLoading, setTableLoading] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [sort, setSort] = useState(null);
  const [tableDataLength, setTableDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [BlockId, setBlockId] = useState(null);
  const [blockUserModalVisible, setBlockUserModalVisible] = useState(false);
  const [BlockStatus, setBlockStatus] = useState('active');

  const goPropertyDetails = (id) => {
    navigate('/properties/details', {
      state: {
        propertyId: id
      }
    });
  }

  const columns = [
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{CONTACT_DETAILS_STRINGS.TABLE.CONTACT_NAME}</span>, 'name', column),
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 300,
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value.charAt(0).toUpperCase() + value.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{CONTACT_DETAILS_STRINGS.TABLE.CONTACT_NO}</span>, 'contact_mobile', column),
      key: 'phoneNumber',
      width: 200,
      dataIndex: 'contact_mobile',
      align: 'left',
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? <span className="tabletext">{value}</span> : '-'),
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{CONTACT_DETAILS_STRINGS.TABLE.EMAIl}</span>, 'email', column),
      key: 'email',
      dataIndex: 'email',
      align: 'left',
      width: 400,
      sorter: tableDataLength > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => (value ? <span className="tabletext">{value}</span> : '-'),
    },
    {
      title: <span className="tableTitle">{TABLE_STRING.ACTION}</span>,
      key: 'Actions',
      width: 100,
      align: 'left',
      dataIndex: 'Actions',
      render: (item, record) => {
        return (
          <div className='actionCol'>
            {tabState === 'admin' && (<>{(permissions?.isUpdate || permissions?.isUpdate === undefined) && (<Tooltip title='Edit Contact'>
              <PencileIcon onClick={() => navigate('/contacts/edit', { state: { contactItemId: record?.id } })} className='font-size-20 delete-btn-hover cursor-pointer mx-1 editBtnRow' />
            </Tooltip>)}</>)}
            {(permissions?.isDelete || permissions?.isDelete === undefined) && (
              <Tooltip title='Delete Contact'>
                <DeleteIcon
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                    setDeleteId(record?.id);
                  }}
                  className='font-size-20 delete-btn-hover cursor-pointer mx-1 deleteBtnRow'
                />
              </Tooltip>
            )}
            {tabState === 'user' && <Tooltip title='Block Contact'>
              {record.statuss === 'active' ? <BlockIcon onClick={() => {
                setBlockUserModalVisible(true);
                setBlockStatus('inactive');
                setBlockId(record.id)
              }} className='font-size-20 delete-btn-hover cursor-pointer mx-1 blockBtnRow' /> : <UnBlockIcon onClick={() => {
                setBlockStatus('active');
                setBlockId(record.id)
                blockContact();
              }} className='font-size-20 delete-btn-hover cursor-pointer mx-1 blockBtnRow' />}
            </Tooltip>}
          </div>
        );
      },
    },
  ];

  if (tabState === 'user') {
    columns.splice(3, 0, {
      title: (column) => <span className="tableTitle">Linked Property</span>,
      key: 'linkedPRoperty',
      dataIndex: 'linkedPRoperty',
      align: 'left',
      width: 230,
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) => (record?.propertyId !== null) ? <span className="tabletext" onClick={() => navigate('/contacts/properties', {state: {contactId: record?.id}})}><u>View</u></span>
        : <span className="tabletext">Not Linked</span>
    });
  }

  const fetchContact = async (page, pageSize, search, sort) => {
    const res = await CONTACT_DETAILS.GETADDEDBY(pageSize,
      (page - 1) * pageSize,
      search,
      sort,
      tabState);
    try {
      if (res.status === 1 || res.status === '1') {
        setcontacts(res);
        setTableDataLength(res?.data && res?.data.length);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setTableLoading(false);
    }
  }

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    if (sorter?.order === 'ascend') {
      setSort({ [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      setSort({ [sorter.field]: -1 });
    } else {
      setSort({ createdAt: -1 });
    }
  };

  const deleteContact = async () => {
    try {
      const res = await CONTACT_DETAILS.DELETE(deleteId);

      if (res.status === 1 || res.status === '1') {
        setDeleteId(null);
        setIsDeleteModalVisible(false);
        toast.success(res?.message);
        //If this page only have one element so we need to reduce the page size
        let page = currentPage;
        if (contacts?.data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
          page = page - 1;
        } else {
          fetchContact(page, pageSize, null, sort);
        }
      }
    } catch (err) {
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    }
  };

  const blockContact = async () => {
    try {
      const res = await CONTACT_DETAILS.PATCH(BlockId, { status: BlockStatus });
      if (res?.status) {
        setBlockUserModalVisible(false);
        fetchContact(currentPage, pageSize, null, sort);
      }
    } catch (err) {
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    }
  }

  useEffect(() => {
    fetchContact(currentPage, pageSize, null, sort);
  }, [currentPage, pageSize, sort, tabState]);

  const changeTab = (value) => {
    setTableLoading(null);
    setCurrentPage(1);
    settabState(value);
  }

  return (
    <div className='contact_details_container'>
      <div className="contact_btn_box">
        <Radio.Group
          onChange={(e) => changeTab(e.target.value)}
          value={tabState}
          optionType="button"
          buttonStyle="solid">
          <Radio value={'admin'}>{CONTACT_DETAILS_STRINGS.TOOGLE.ADMIN_ADDED}</Radio>
          <Radio value={'user'}>{CONTACT_DETAILS_STRINGS.TOOGLE.USER_ADDED}</Radio>
        </Radio.Group>
        <div className="add_new_btn" onClick={() => navigate('/contacts/add')}>
          <span>{CONTACT_DETAILS_STRINGS.ADD_EDIT.ADD_CONTACT}</span>
        </div>
      </div>

      <div className="contacts_table_container">
        <Table
          columns={columns}
          dataSource={contacts?.data}
          pagination={false}
          rowKey='id'
          loading={tableLoading}
          onChange={onChange}
          scroll={{ x: 400 }}
        />

        {contacts?.total > 10 && (
          <div className='text-end bg-white mt-7'>
            <PaginationCom
              onChange={onChangePage}
              showSizeChanger={true}
              current={currentPage}
              showLessItems={true}
              total={contacts?.total}
            />
          </div>
        )}
      </div>
      <DeleteModel
        isDeleteModalVisible={isDeleteModalVisible}
        deleteModel={deleteContact}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
      <BlockModel
        isDeleteModalVisible={blockUserModalVisible}
        deleteModel={blockContact}
        setIsDeleteModalVisible={setBlockUserModalVisible}
      />
    </div>
  );
}

ContactDetails.propTypes = {
  permissions: PropTypes.any
}

export default ContactDetails;
