 import React from "react";
const ValidateIcon = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 0H15C16.1046 0 17 0.89543 17 2V15C17 16.1046 16.1046 17 15 17H2C0.89543 17 0 16.1046 0 15V2C0 0.89543 0.89543 0 2 0Z" fill="#2EAE01"/>
        <path d="M3 9.07788L7.16162 12.0007L13.2515 5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        </svg>
        );
}

export default ValidateIcon;