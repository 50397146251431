import { Col, DatePicker, Image, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CalenderDarkIcon from '../../components/Icon/CalenderDarkIcon';
import { COMMON_STRING, PROPERTIES_STRING } from '../../services/strings';
import { PROPERTIES } from '../../services/Property/Properties';
import { BASE_URL } from '../../services/constant';

const PropertiesValidate = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const propertyId = location.state?.propertyId;
    const [Property, setProperty] = useState(null);

    if (!propertyId) {
        <Navigate to="/properties" />
    }

    const fetchProperty = async () => {

        try {
            const property = await PROPERTIES.SINGLE(propertyId);
            if (property.status) setProperty(property.data);
            else navigate('/properties');
        }
        catch (e) {
            navigate('/properties');
        }
    }

    const cancelBtn = () => {
        navigate('/properties');
    }

    const saveBtn = async () => {
        const res = await PROPERTIES.VALIDATE(propertyId, {
            isValidate: true
        });
        if (res.status) {
            toast.success('Property verification done successfully.');
            navigate('/properties', { replace: true });
        }
    }

    useEffect(() => {
        fetchProperty();
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <div className="fWidthShowBox propertyValidationBox">
                <Row>
                    <Col xs={24} sm={24} md={{ offset: 2 }} lg={{ offset: 2 }} xl={{ offset: 2 }}>
                        <h2>{PROPERTIES_STRING.PROPERTY_VALIDATION.TITLE}</h2>
                    </Col>
                </Row>

                <Row className='propertyRow'>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <span className="inpTitle">{PROPERTIES_STRING.PROPERTY_VALIDATION.PROPERTY_NAME}</span>
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                        <input type="text" className='inputText' placeholder="Property Name" value={Property?.name} disabled />
                    </Col>
                </Row>
                <Row className='propertyRow'>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <span className="inpTitle">{PROPERTIES_STRING.PROPERTY_VALIDATION.PROPERTY_TYPE}</span>
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                        <input type="text" className='inputText' placeholder="Property Type" value={Property?.property_type?.name} disabled />
                    </Col>
                </Row>
                <Row className='propertyRow'>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <span className="inpTitle">{PROPERTIES_STRING.PROPERTY_VALIDATION.PURCHASE_DATE}</span>
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                        <DatePicker className='selectDateInput'
                            placeholder="Purchase date"
                            suffixIcon={<CalenderDarkIcon />}
                            allowClear={false}
                            inputReadOnly={true}
                            popupStyle={{display: "none"}}
                            value={moment(Property?.purchaseDate)}
                        />
                    </Col>
                </Row>
                <Row className='propertyRow'>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <span className="inpTitle">{PROPERTIES_STRING.PROPERTY_VALIDATION.DOCUMENT_PREVIEW}</span>
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                        <div className="documentPreviewContainer">
                            {Property?.documentImage && <div className="previewBox">
                                <Image crossOrigin="anonymous" src={BASE_URL.slice(0, -1) + Property?.documentImage.image} />
                            </div>}
                        </div>
                    </Col>
                </Row>
                <Row className='propertyRow '>
                    <Col className="btnBox" xs={24} sm={24} md={24} lg={24} xl={24}>
                        <button onClick={() => cancelBtn()} className="cancelBtn">{COMMON_STRING.BACK_BTN}</button>
                        <button onClick={() => saveBtn()} className="saveBtn">{COMMON_STRING.VALIDATE_BTN}</button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default PropertiesValidate;
