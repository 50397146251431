import { Tabs } from 'antd';
import React from 'react';
import PContacts from '../../components/properties/PContacts';
import PExpenses from '../../components/properties/PExpenses';
import PRentalIncome from '../../components/properties/PRentalIncome';
import PropertyDetail from '../../components/properties/PropertyDetail';
import PServices from '../../components/properties/PServices';
import PropTypes from 'prop-types';
import PDepSchedule from '../../components/properties/PDepSchedule';
import { PROPERTIES_STRING } from '../../services/strings';
const { TabPane } = Tabs;

const PropertyDetails = ({ permissions }) => {
    return (
        <div>
            <Tabs className='propertyDetailsTabs' defaultActiveKey="1" color="#324666">
                <TabPane tab={PROPERTIES_STRING.TABS.DETAILS} key="details" >
                    <PropertyDetail />
                </TabPane>
                <TabPane tab={PROPERTIES_STRING.TABS.DEP_SCHEDULE} key="depreciation">
                    <PDepSchedule />
                </TabPane>
                <TabPane tab={PROPERTIES_STRING.TABS.RENTAL_INCOME} key="rental">
                    <PRentalIncome permissions={permissions} />
                </TabPane>
                <TabPane tab={PROPERTIES_STRING.TABS.EXPENSES} key="expenses">
                    <PExpenses permissions={permissions} />
                </TabPane>
                <TabPane tab={PROPERTIES_STRING.TABS.CONTACTS} key="contacts">
                    <PContacts permissions={permissions} />
                </TabPane>
                <TabPane tab={PROPERTIES_STRING.TABS.SERVICES} key="services">
                    <PServices />
                </TabPane>
            </Tabs>

        </div>
    );
};

PropertyDetails.propTypes = {
    permissions: PropTypes.any
};

export default PropertyDetails;
