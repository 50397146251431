import { methods, serviceMaker } from '../service';

const GET_PROPERTYPE = (id) => serviceMaker(`property-types/${id}`, methods.GET);

const GET_PROPERTYPES = (
  limit,
  skip,
  search = null,
  sort = null
) => {
  const query = {
    params: {
      $limit: limit,
      $skip: skip
    },
  };

  if (sort) {
    query['params'][`$sort[${Object.keys(sort)[0]}]`] = Object.values(sort)[0];
  } else {
    query['params']['$sort[createdAt]'] = -1;
  }
  if (search) {
    query['params']['search'] = search;
    return serviceMaker(`search/property-types`, methods.GET, query);
  }

  return serviceMaker(`property-types`, methods.GET, query);
};

const DELETE_PROPERTYPES = (id) => serviceMaker(`property-types/${id}`, methods.DELETE);

const CREATE_PROPERTYPES = (payload) =>
  serviceMaker(`property-types`, methods.POST, null, payload);

const PATCH_PROPERTYPES = (id, payload) =>
  serviceMaker(`property-types/${id}`, methods.PATCH, null, payload);

const PROPERTYPES = {
  FIND: GET_PROPERTYPE,
  GET: GET_PROPERTYPES,
  CREATE: CREATE_PROPERTYPES,
  PATCH: PATCH_PROPERTYPES,
  DELETE: DELETE_PROPERTYPES
};

export { PROPERTYPES };
