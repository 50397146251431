import { methods, serviceMaker } from '../service';

const GET_PROPERTIES = (
  appuserId = null,
  propertyId = null
) => {
  const query = {
    params: {

    }
  };
  if(appuserId){
    query['params']['appuserId'] = appuserId;
  }
  if(propertyId){
    query['params']['id'] = propertyId;
  }
  return serviceMaker(`dropdown/properties`, methods.GET, query);
}

const GET_COUNTRIES = (params) => {
  return serviceMaker(`dropdown/countries`, methods.GET, {});
}

const GET_STATES = (
  countryId = null
) => {
  const query = { params: {}};
  if(countryId){
    query['params']['countryId'] = countryId;
  }
  return serviceMaker(`dropdown/states`, methods.GET, query);
}

const GET_CITIES = (
    stateId = null
) => {
  const query = { params: {}};
  if(stateId){
    query['params']['stateId'] = stateId;
  }
  return serviceMaker(`dropdown/cities`, methods.GET, query);
}

const GET_SUBURBS = (
  cityId = null,
  stateId = null
) => {
  const query = { params: {}};
  if(cityId){
    query['params']['cityId'] = cityId;
  }
  if(stateId){
    query['params']['stateId'] = stateId;
  }
  return serviceMaker(`dropdown/suburbs`, methods.GET, query);
}

const GET_COUNCILS = (
  suburbId = null
) => {
  const query = { params: {}};
  if(suburbId){
    query['params']['suburbId'] = suburbId;
  }
  return serviceMaker(`dropdown/councils`, methods.GET, query);
}

const GET_PROPERTY_TYPES = (params) => {
  return serviceMaker(`dropdown/pTypes`, methods.GET, {});
}

const GET_SERVICE_TYPES = (params) => {
  return serviceMaker(`dropdown/serviceTypes`, methods.GET, {});
}

const GET_FEEDBACK_FACTORS = (params) => {
  return serviceMaker(`dropdown/feedbackFactor`, methods.GET, {});
}

const GET_APPUSERS = (params) => {
  return serviceMaker(`dropdown/appusers`, methods.GET, params);
}

const GET_EXPENSES_TYPES = (params) => {
  return serviceMaker(`dropdown/expensesTypes`, methods.GET, {});
}

const GET_AGEGROUP= (params) => {
  return serviceMaker(`dropdown/ageGroups`, methods.GET, {});
}

const DROPDOWN = {
    GET_PROPERTIES: GET_PROPERTIES,
    GET_COUNTRIES: GET_COUNTRIES,
    GET_STATES: GET_STATES,
    GET_CITY: GET_CITIES,
    GET_SUBURBS: GET_SUBURBS,
    GET_COUNCILS: GET_COUNCILS,
    GET_PROPERTY_TYPES: GET_PROPERTY_TYPES,
    GET_SERVICE_TYPES: GET_SERVICE_TYPES,
    GET_FEEDBACK_FACTORS: GET_FEEDBACK_FACTORS,
    GET_APPUSERS: GET_APPUSERS,
    EXPENSES_TYPES: GET_EXPENSES_TYPES,
    AGE_GROUPS: GET_AGEGROUP
  };
  export { DROPDOWN };