import React, { useState } from 'react';
import { Col, Row, Table } from 'antd';
import { DEPSCHEDULES } from '../../services/Depreciation/DepreciationSchedule';
import { useLocation } from 'react-router-dom';
import { PROPERTIES } from '../../services/Property/Properties';
import { PROPERTIES_STRING } from '../../services/strings';
import { formatter } from '../../services/helpers';
import moment from 'moment';
// import { formatter } from '../../services/helpers';

function PDepSchedule() {
    const [depSchedules, setDepSchedules] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const propertyId = useLocation().state.propertyId;

    const columns = [
        {
            title: (column) => (<span className="tableTitle">{PROPERTIES_STRING.DEPRECIATION_SCHEDULE.YEAR}</span>),
            dataIndex: 'year',
            key: 'year',
            align: 'left',
            sorter: false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{value}</span> : '',
        },
        {
            title: (column) => (<span className="tableTitle">{PROPERTIES_STRING.DEPRECIATION_SCHEDULE.PLANT_AND_EQUIP}</span>),
            dataIndex: 'planAndEquipment',
            key: 'Plant and Equipment',
            align: 'left',
            sorter: false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                <span className="tabletext">{formatter.format(value)}</span>,
        },
        {
            title: (column) => (<span className="tableTitle">{PROPERTIES_STRING.DEPRECIATION_SCHEDULE.DIVISION}</span>),
            dataIndex: 'division',
            key: 'division',
            align: 'left',
            sorter: false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                <span className="tabletext">{formatter.format(value)}</span>,
        },
        {
            title: (column) => (<span className="tableTitle">{PROPERTIES_STRING.DEPRECIATION_SCHEDULE.TOTAL}</span>),
            dataIndex: 'total',
            key: 'total',
            align: 'left',
            sorter: false,
            sortDirections: ['ascend', 'descend'],
            render: (value) =>
                value ? <span className="tabletext">{formatter.format(value)}</span> : '',
        }
    ];

    const fetchDepSchedules = async () => {
        const property = await PROPERTIES.SINGLE(propertyId);
        if (property?.data?.depreciationScheduleId) {
            const schedules = await DEPSCHEDULES.GET(property?.data?.depreciationScheduleId);
            if (schedules.status) {
                const data = [];
                const dep43s = schedules.data.dep43;
                const dep40s = schedules.data.dep40;
                let startYear = moment(schedules.data.startDate).year();
                const endYear = moment(schedules.data.endDate).year();
                if(startYear < endYear){
                    for (; startYear <= endYear; startYear++) {
                        const dep40 = dep40s.find(dep40 => dep40.depYear === startYear);
                        const dep40Value = dep40 ? parseInt(dep40.amount) : 0;
                        const dep43 = dep43s.find(dep43 => dep43.depYear === startYear);
                        const dep43Value = dep43 ? parseInt(dep43.amount) : 0;
                        data.push({
                            year: startYear,
                            planAndEquipment: dep40Value,
                            division: dep43Value,
                            total: dep40Value + dep43Value
                        });
                    }
                }
                setDepSchedules(data);
            }
        }
        setTableLoading(false);
    }

    useState(() => {
        fetchDepSchedules();
    }, [])

    return (
        <div>
            <Row className='dataTableBox'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table
                        columns={columns}
                        dataSource={depSchedules}
                        pagination={false}
                        loading={tableLoading}
                        scroll={{ x: 400 }}
                        bordered={true} />
                </Col>
            </Row>
        </div>
    );
}

export default PDepSchedule;