import { Col, DatePicker, Form, Input, Row, Select, Slider, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import PaginationCom from '../../components/PaginationCom';
import { tableSortIconToggle } from '../../utility/tableSorting';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import FormItem from 'antd/es/form/FormItem';
import CalenderDarkIcon from '../Icon/CalenderDarkIcon';
import PropTypes from 'prop-types';
import FilterToogleBtn from '../FilterToogleBtn';
import { COMMON_ALERTS, FILTER_STRING, REFERRALS_STRINGS } from '../../services/strings';
import AppButton from '../AppButton';
import { APP_REFERRAL } from '../../services/offers/appreferrals';
import moment from 'moment';
import SearchInput from '../Inputs/SearchInput';
import SelectArrow from '../Icon/SelectArrow';
import { DROPDOWN } from '../../services/Dropdown/dropdowns';

const AppReferrals = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [Countries, setCountries] = useState([]);
  const [States, setStates] = useState([]);
  const [Suburbs, setSuburbs] = useState([]);
  const [AppAvails, setAppAvails] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [Filter, setFilter] = useState({ $limit: 10, $skip: 0, page: 1, search: null, sort: null, sDate: null, eDate: null, platform: null, country: null, state: null, suburb: null, referralName: null, referBy: null, pd_referral: [0, 500], pd_referee: [0, 500], pd_expired: [0, 500] });
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [Referees, setReferees] = useState([]);
  const [Referors, setReferors] = useState([]);

  const marks = {
    0: '$0',
    500: '$500'
  };

  const fetchDropDowns = async () => {
    const countriesRes = await DROPDOWN.GET_COUNTRIES();
    if (countriesRes.status) {
      setCountries(countriesRes.data);
    }
    const statesRes = await DROPDOWN.GET_STATES();
    if (statesRes.status) {
      setStates(statesRes.data);
    }
    const suburbRes = await DROPDOWN.GET_SUBURBS();
    if (suburbRes.status) {
      setSuburbs(suburbRes.data);
    }
  }

  const handleReferorChange = async (val) => {
    const res = await DROPDOWN.GET_APPUSERS({ params: { search: val, $limit: 10 } });
    if (Array.isArray(res?.data)) {
      res.data = res.data.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.place === value.place && t.name === value.name
        ))
      )
      setReferors(res.data);
    }
  }

  const handleRefereeChange = async (val) => {
    const res = await DROPDOWN.GET_APPUSERS({ params: { search: val, $limit: 10 } });
    if (Array.isArray(res?.data)) {
      res.data = res.data.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.place === value.place && t.name === value.name
        ))
      )
      setReferees(res.data);
    }
  }

  const onChangePage = (page, pageSize) => {
    setFilter(prev => ({
      ...prev,
      $limit: pageSize,
      $skip: (page - 1) * pageSize,
      page
    }));
  };

  const onFilterChange = (name, val) => {
    setFilter(prev => ({
      ...prev,
      [name]: val === '' ? null : val
    }));
  }

  const onChange = (pagination, filters, sorter, extra) => {
    const sort = {};
    if (sorter?.order === 'ascend') {
      Object.assign(sort, { [sorter.field]: 1 });
    } else if (sorter?.order === 'descend') {
      Object.assign(sort, { [sorter.field]: -1 });
    } else {
      Object.assign(sort, { createdAt: 1 });
    }
    onFilterChange("sort", sort)
  };

  const fetchService = async () => {
    setTableLoading(true);
    try {
      const res = await APP_REFERRAL.FIND(Filter);
      if (res.status === 1 || res.status === '1') {
        setAppAvails(res);
      }
    } catch (err) {
      setTableLoading(false);
      toast.error(err?.message || COMMON_ALERTS.SOMETHING_WENT_WRONG);
    } finally {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    fetchService();
  }, [Filter]);

  useEffect(() => {
    fetchDropDowns();
  }, []);

  const platfromCheck = (rc) => {
    if (rc.isIos) return 'iOS';
    if (rc.isAndroid) return 'Android';
    if (rc.isWeb) return 'Web';
  }

  const columns = [
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.APP_REFERRAL.TABLE.REFERRAL_NAME}</span>, 'referrer', column),
      dataIndex: 'refereeName',
      key: 'referee',
      align: 'left',
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) =>
      record?.referee ? <span className="tabletext">{record?.referee?.name.charAt(0).toUpperCase() + record?.referee?.name.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.APP_REFERRAL.TABLE.REFERRED_BY}</span>, 'referred_by', column),
      dataIndex: 'referorName',
      key: 'referrer',
      align: 'left',
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) =>
        record ? <span className="tabletext">{record?.referrer?.name.charAt(0).toUpperCase() + record?.referrer?.name.slice(1)}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<><span className="tableTitle">{REFERRALS_STRINGS.APP_REFERRAL.TABLE.CODE}</span></>, 'isWeb', column),
      dataIndex: 'referorCode',
      key: 'referorCode',
      align: 'center',
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) => <span className="tabletext">{record?.referrer?.referCode}</span>,
    },
    {
      title: (column) => tableSortIconToggle(<><span className="tableTitle">{REFERRALS_STRINGS.APP_REFERRAL.TABLE.REDEEM_DATE}</span></>, 'isWeb', column),
      dataIndex: 'redeemedAt',
      key: 'redeemedAt',
      align: 'center',
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) => <span className="tabletext">{moment(value).format('DD/MM/YYYY')}</span>,
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.APP_REFERRAL.TABLE.REFERRAL_PD}</span>, 'PdReferrer', column),
      dataIndex: 'PdReferrer',
      key: 'PdReferrer',
      align: 'left',
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<span className="tableTitle">{REFERRALS_STRINGS.APP_REFERRAL.TABLE.REFERREE_PD}</span>, 'PdReferee', column),
      dataIndex: 'PdReferee',
      key: 'PdReferee',
      align: 'left',
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value) =>
        value ? <span className="tabletext">{value}</span> : '-',
    },
    {
      title: (column) => tableSortIconToggle(<><span className="tableTitle">{REFERRALS_STRINGS.APP_REFERRAL.TABLE.PLATFORM}</span></>, 'isWeb', column),
      dataIndex: 'platform',
      key: 'platform',
      align: 'center',
      sorter: AppAvails?.total > 1 ? true : false,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) => <span className="tabletext">{platfromCheck(record)}</span>,
    }
  ];

  const clearAll = () => {
    setFilter({ $limit: 10, $skip: 0, page: 1, search: null, sort: null, sDate: null, eDate: null, platform: null, country: null, state: null, suburb: null, referralName: null, referBy: null, pd_referral: [0, 500], pd_referee: [0, 500], pd_expired: [0, 500] })
  }

  return (
    <div className='commonWrapperLayout'>
      <FilterToogleBtn onClick={() => setIsFilterShow(!isFilterShow)} withmenu={true} />
      <AppButton text={REFERRALS_STRINGS.APP_REFERRAL.PD_AMOUNT_BTN} className='pdAmountBtn' withMenu={true} onClick={() => navigate('/referrals/referralpd')} />
      <div className={isFilterShow ? 'fWidthShowBox pRentalIncomePage mb-3' : 'fWidthShowBox pRentalIncomePage d-none'}>
        <Form name="complex-form" form={form} labelCol={{ span: 6 }} colon={false} wrapperCol={{ span: 18 }}>
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem
                label={<span className='inputTitle'>{FILTER_STRING.START_DATE}</span>}>
                <DatePicker className='searchInpExpense' suffixIcon={<CalenderDarkIcon />} value={Filter?.sDate || null} onChange={value => onFilterChange('sDate', value)} disabledDate={d => !d || d.isAfter(Filter?.eDate)} />
              </FormItem>
              <FormItem
                label={<span className='inputTitle'>{REFERRALS_STRINGS.APP_REFERRAL.FILTER.REFERRAL_NAME}</span>}>
                <SearchInput
                  value={Filter.referralName}
                  className='searchInpExpense'
                  placeholder={'Start Typing To Search Referal name'}
                  onChange={value => onFilterChange('referralName', value)}
                  allowClear={true}
                  data={Referors}
                  onSearch={handleReferorChange}
                  label={'name'}
                  valueData={'name'}
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem
                label={<span className='inputTitle'>{FILTER_STRING.END_DATE}</span>}>
                <DatePicker className='searchInpExpense' suffixIcon={<CalenderDarkIcon />} value={Filter?.eDate || null} onChange={value => onFilterChange('eDate', value)} disabledDate={d => !d || d.isSameOrBefore(Filter?.sDate)} />
              </FormItem>
              <FormItem
                label={<span className='inputTitle'>{REFERRALS_STRINGS.APP_REFERRAL.FILTER.REFERRAL_BY}</span>}>
                <SearchInput
                  value={Filter.referBy}
                  className='searchInpExpense'
                  placeholder={'Start Typing To Search Referee name'}
                  onChange={value => onFilterChange('referBy', value)}
                  allowClear={true}
                  data={Referees}
                  onSearch={handleRefereeChange}
                  label={'name'}
                  valueData={'name'}
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="d-flex ml-3">
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.PLATFORM} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false} value={Filter.platform} onChange={(value) => onFilterChange('platform', value)}><Select.Option>{FILTER_STRING.PLATFORM}</Select.Option>{['Android', 'Web', 'iOS'].map((platform, key) => <Select.Option key={key} value={platform}>{platform}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.COUNTRY} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false} value={Filter.country} onChange={(value) => onFilterChange('country', value)}><Select.Option>{FILTER_STRING.COUNTRY}</Select.Option>{Countries.map((country, key) => <Select.Option key={key} value={country.name}>{country.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.STATE} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false} value={Filter.state} onChange={(value) => onFilterChange('state', value)}><Select.Option>{FILTER_STRING.STATE}</Select.Option>{States.map((state, key) => <Select.Option key={key} value={state.name}>{state.name}</Select.Option>)}</Select>
              </div>
              <div className="filterSelect">
                <Select defaultValue={FILTER_STRING.SUBURBS} suffixIcon={<SelectArrow />} className="mr-2 m-auto cursor-pointer filterDropdown" bordered={false} value={Filter.suburb} onChange={(value) => onFilterChange('suburb', value)}><Select.Option>{FILTER_STRING.SUBURBS}</Select.Option>{Suburbs.map((suburb, key) => <Select.Option key={key} value={suburb.name}>{suburb.name}</Select.Option>)}</Select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="d-flex mt-2">
              <div className="filterSlider">
                <div className="sliderTitle">{REFERRALS_STRINGS.APP_REFERRAL.FILTER.PD_REFERRAL}</div>
                <Slider trackStyle={{ background: "#879EC4" }} range marks={marks} onChange={value => onFilterChange('pd_referral', value)} value={Filter.pd_referral} max={600} defaultValue={[0, 500]} />
              </div>
              <div className="filterSlider">
                <div className="sliderTitle">{REFERRALS_STRINGS.APP_REFERRAL.FILTER.PD_REFEREEE}</div>
                <Slider trackStyle={{ background: "#879EC4" }} range marks={marks} onChange={value => onFilterChange('pd_referee', value)} value={Filter.pd_referee} max={600} defaultValue={[0, 500]} />
              </div>
              <div className="filterSelect clearAll" onClick={() => clearAll()}>Clear all</div>
            </Col>
          </Row>
        </Form>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            columns={columns}
            dataSource={AppAvails?.data}
            pagination={false}
            rowKey='id'
            loading={tableLoading}
            onChange={onChange}
            scroll={{ x: 400 }}
          />

          {AppAvails?.total > 10 && (
            <div className='text-end bg-white mt-7'>
              <PaginationCom
                onChange={onChangePage}
                showSizeChanger={true}
                current={Filter.page}
                showLessItems={true}
                total={AppAvails?.total}
              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

AppReferrals.propTypes = {
  permissions: PropTypes.any
}

export default AppReferrals;
