import { methods, serviceMaker } from '../service';

const GET_APP_REFERRAL = (Filters) => {  
    const payload = {
      ...Filters,
      pd_referral: Filters.pd_referral.join('_'),
      pd_referee: Filters.pd_referee.join('_'),
      sDate: Filters.sDate? new Date(Filters.sDate): null,
      eDate: Filters.eDate? new Date(Filters.eDate): null
    }
    delete payload.page;

    const query = {
      params: payload
    };
  
    if (Filters.sort) {
      query['params'][`$sort[${Object.keys(Filters.sort)[0]}]`] = Object.values(Filters.sort)[0];
    } else {
      query['params']['$sort[createdAt]'] = -1;
    }
    return serviceMaker(`app-user-referrals`, methods.GET, query);
  };

const APP_REFERRAL = {
    FIND: GET_APP_REFERRAL
  };
  
export { APP_REFERRAL };