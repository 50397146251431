import { methods, serviceMaker } from "../service";

const GET_GLOBAL_VALUES = (name) => serviceMaker(`globalvalues?name=${name}`, methods.GET);
const GET_BY_ID = (id) => serviceMaker(`globalvalues/${id}`, methods.GET);

const POST_DATA = (payload) => serviceMaker(`globalvalues`, methods.POST, null, payload);

const GLOBAL_VALUES = {
    GET: GET_GLOBAL_VALUES,
    ID: GET_BY_ID,
    POST: POST_DATA
};
  
export { GLOBAL_VALUES };